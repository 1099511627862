import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { reset } from "../../../features/multimediaSlice"
import { useAddSocketEvent } from "../../../components/SocketProvider"
import Loader from "../../../components/Loader"
import CreateRoom from "./CreateRoom"
import { getRooms } from "../../../services/room"
import { addRoom, removeRoom, setRooms, updateLastMessageRoom, updatePositionList } from "../../../features/roomListSlice"
import Room from "../../../components/Room"
import FindRooms from "./FindRooms"
import Text from "../../../components/Text"
import langs from "../../../lang/langs"

const RoomPage = () => {
    const { token, roomList, lang } = useSelector(state => state)

    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    useAddSocketEvent('room:added', (group) => {
        dispatch(addRoom(group))
    })

    useAddSocketEvent('room:update', ({ memberId, message }) => {
        dispatch(updateLastMessageRoom({ memberId, message }))
        dispatch(updatePositionList({ memberId }))

    })

    useAddSocketEvent('room:member:expulse', member => {
        dispatch(removeRoom(member.id))
    })

    useEffect(() => {
        getRooms({ token }).then(chats => {
            dispatch(setRooms(chats.data.payload.rooms))
            setLoading(false)
        })
        dispatch(reset())
    }, [dispatch, token])

    if (loading) return <Loader />

    return (
        <div className="flex flex-col gap-5 p-5 bg-white rounded">
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
                <CreateRoom />
                <FindRooms />
            </div>

            <div className="flex flex-col gap-5">
                {roomList.length > 0 ?
                    roomList.map(member => <Room key={member.id} member={member} />)
                    :
                    <Text>{langs[lang]['dont_belong_to_anyone_room']}</Text>
                }
            </div>
        </div>
    )
}

export default RoomPage