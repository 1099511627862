import axios from "axios"
import { useEffect, useState } from "react"

const useOnline = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine)

    useEffect(() => {
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine)
        }

        window.addEventListener('online', handleStatusChange)

        window.addEventListener('offline', handleStatusChange)

        return () => {
            window.removeEventListener('online', handleStatusChange)
            window.removeEventListener('offline', handleStatusChange)
            // clearInterval(reqInterval)}
        }
    }, [isOnline])

    return isOnline
}

export default useOnline