import "react-range-slider-input/dist/style.css"
import "./style.css"

import { useState } from "react"
import RangeSlider from "react-range-slider-input"

const RangeVideoSlider = ({ values, onChange = () => { } }) => {
    const [value, setValue] = useState([values[0], values[1]])
    return (
        <RangeSlider
            id="range-slider-yellow"
            step={0.001}
            defaultValue={value}
            value={values}
            onInput={(values) => {
                setValue(values)
                onChange({ start: values[0], end: values[1] })
            }}
        />
    )
}

export default RangeVideoSlider
