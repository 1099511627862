import { useEffect, useRef, useState } from "react"
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg"
import { BiBrightness } from "react-icons/bi"
import { MdContrast, MdExposure, MdOutlineFilterBAndW, MdOutlineRotateLeft, MdOutlineStraighten, MdPalette } from 'react-icons/md'

import { toTimeString } from "../../utils"
import RangeVideoSlider from "./RangeVideoSlider"
import Loader from "../Loader"
import OptionButton from "./OptionButton"
import Button from "../Button"
import RotateOption from "./RotateOption"
import { toast } from "react-hot-toast"
import langs from "../../lang/langs"
import { useSelector } from "react-redux"

const defaultOptions = {
    trim: false,
    filters: false,
    rotate: false
}

const defaultFilterValues = {
    contrast: 1,
    brightness: 0,
    saturation: 1,
    gamma: {
        red: 1,
        green: 1,
        blue: 1
    }
}
const defaultFilterOptions = {
    contrast: false,
    brightness: false,
    saturation: false,
    gamma: false
}

const defaultRotateOptions = {
    normal: false,
    rotate90: false,
    rotate180: false,
    rotate270: false
}

const VideoEditor = ({ video, videoSrc, onVideoLoaded = () => { }, onVideoProcessEnd = () => { } }) => {
    const { lang } = useSelector(state => state)

    const ffmpeg = useRef()

    const videoPreview = useRef()
    const editor = useRef()

    const [ffmpegLoaded, setFfmpegLoaded] = useState(false)
    const [workProgress, setWorkProgress] = useState(0.0)
    const [processingVideo, setProcessingVideo] = useState(false)
    // Trim values
    const [rangeValues, setRangeValues] = useState([0, 100])
    const [trimData, setTrimData] = useState({ start: 0, end: 0 })

    // Filter values
    const [filterValues, setFilterValues] = useState(defaultFilterValues)
    const [filterOptions, setFilterOptions] = useState(defaultFilterOptions)

    // Rotate values
    const [rotateValue, setRotateValue] = useState(0)
    const [rotateOptions, setRotateOptions] = useState({ ...defaultRotateOptions, normal: true })

    const [options, setOptions] = useState(defaultOptions)

    const [playingData, setPlayingData] = useState({ start: 0, end: 0 })
    const [data, setData] = useState({
        duration: 0
    })

    const [loadingThumbnails, setLoadingThumbnails] = useState(false)
    const [thumbnails, setThumbnails] = useState([])

    // Preview
    const [previewBase, setPreviewBase] = useState(undefined)
    const [previewBaseSimulated, setPreviewBaseSimulated] = useState(undefined)

    // Filters states
    const [apllyingFilter, setApplyingFilter] = useState(false)

    const [videoEditedBlob, setVideoEditedBlob] = useState(null)
    const [videoEditedSrc, setVideoEditedSrc] = useState(undefined)

    const handleSelection = (option) =>
        setOptions({ ...defaultOptions, [option]: !options[option] })

    const videoData = async (event) => {
        setData({ duration: event.target.duration })
        setRangeValues([0, 100])
        setTrimData({ ...trimData, start: 0, end: event.target.duration })
        setPlayingData({ ...playingData, end: event.target.duration })
        setThumbnails(await getThumbnails({ duration: event.target.duration }))
    }


    const revertAllChanges = () => {
        setVideoEditedBlob(null)
        setPreviewBaseSimulated(undefined)
        setFilterOptions(defaultFilterOptions)
        setFilterValues(defaultFilterValues)
        setVideoEditedSrc(undefined)
    }

    const getThumbnails = async ({ duration }) => {
        setLoadingThumbnails(true)
        if (!ffmpeg.current.isLoaded()) await ffmpeg.current.load()

        const rangeWidth = editor.current.clientWidth
        const thumbnailQuantity = Math.floor(rangeWidth / 50)

        const arrayOfImageURIs = []

        ffmpeg.current.FS("writeFile", video.name, await fetchFile(videoSrc))

        for (let i = 0; i < thumbnailQuantity; i++) {
            const time = duration * ((i / thumbnailQuantity * 100) / 100)

            const startTimeInSecs = toTimeString(time)

            try {
                await ffmpeg.current.run(
                    "-ss",
                    startTimeInSecs,
                    "-i",
                    video.name,
                    "-t",
                    "00:00:1.000",
                    "-vf",
                    `scale=${rangeWidth}:-1`,
                    `img_${i}.png`
                )

                const data = ffmpeg.current.FS("readFile", `img_${i}.png`)

                const blob = new Blob([data.buffer], { type: "image/png" })
                const dataURI = URL.createObjectURL(blob)

                ffmpeg.current.FS("unlink", `img_${i}.png`)

                if (i === 0) {
                    setPreviewBase(dataURI)
                }

                arrayOfImageURIs.push(dataURI)
            } catch (error) {
                console.log({ message: error })
            }
        }
        setLoadingThumbnails(false)
        return arrayOfImageURIs
    }

    const handleProcessVideo = async () => {
        setProcessingVideo(true)
        try {
            ffmpeg.current.FS("writeFile", video.name, await fetchFile(videoSrc))

            ffmpeg.current.run(
                "-ss",
                toTimeString(trimData.start),
                "-i",
                video.name,
                "-t",
                toTimeString(trimData.end),
                '-vf',
                `eq=brightness=${filterValues.brightness}:contrast=${filterValues.contrast}:saturation=${filterValues.saturation}:gamma_r=${filterValues.gamma.red}:gamma_b=${filterValues.gamma.blue}:gamma_g=${filterValues.gamma.green},rotate=${rotateValue * (Math.PI / 180)}`,
                "-c:a",
                "copy",
                `new_${video.name}`
            ).then(() => {
                const data = ffmpeg.current.FS("readFile", `new_${video.name}`)
                const videoBlob = new Blob([data.buffer], { type: video.type })
                const dataURL = URL.createObjectURL(videoBlob)

                setProcessingVideo(false)
                setVideoEditedBlob(videoBlob)
                setVideoEditedSrc(dataURL)
                setPreviewBaseSimulated(undefined)
                onVideoProcessEnd({ video: new File([data.buffer], video.name, { type: video.type }), videoUrl: dataURL })
            })

            ffmpeg.current.setProgress(({ ratio }) => setWorkProgress((ratio * 100).toFixed(2)))
        } catch (error) {
            console.log(error)
        }
    }

    const handleTimeUpdate = (event) => {
        if (event.target.currentTime >= trimData.end) {
            event.target.currentTime = trimData.start
        }
    }

    const handleChangeRange = ({ start, end }) => {
        videoPreview.current.currentTime = (start * data.duration) / 100

        setRangeValues([start, end])
        // Start and end sedonds trimmed for video player
        setPlayingData({
            ...playingData,
            end: (data.duration * (end - start)) / 100
        })

        // Start and end seconds to trim
        setTrimData({
            start: (start * data.duration) / 100,
            end: (end * data.duration) / 100
        })
    }

    const handleSelectFilterOption = (option) => {
        setFilterOptions({ ...defaultFilterOptions, [option]: !filterOptions[option] })
    }

    const handleSelectRotateOption = (option) => {
        setRotateOptions({ ...defaultRotateOptions, [option]: true })
    }

    const handleFilterValueChange = async (event) => {
        setFilterValues({ ...filterValues, [event.target.name]: event.target.value })
    }

    const handleGammaValueChange = async (event) => {
        setFilterValues({ ...filterValues, gamma: { ...filterValues.gamma, [event.target.name]: event.target.value } })
    }

    const applyFilterSimulation = async ({
        brightness = false,
        contrast = false,
        saturation = false,
        red = false,
        blue = false,
        green = false
    }) => {
        if (!ffmpeg.current.isLoaded()) await ffmpeg.current.load()
        try {
            setApplyingFilter(true)

            const fileName = 'previewBase.png'

            ffmpeg.current.FS("writeFile", fileName, await fetchFile(previewBase))

            ffmpeg.current.run(
                "-i",
                fileName,
                '-vf',
                `eq=brightness=${brightness || filterValues.brightness}:contrast=${contrast || filterValues.contrast}:saturation=${saturation || filterValues.saturation}:gamma_r=${red || filterValues.gamma.red}:gamma_b=${blue || filterValues.gamma.blue}:gamma_g=${green || filterValues.gamma.green}`,
                "-c:a",
                "copy",
                `new_${fileName}`
            ).then(() => {
                const data = ffmpeg.current.FS("readFile", `new_${fileName}`)
                const image = new Blob([data.buffer], { type: 'image/png' })
                const dataURL = URL.createObjectURL(image)

                ffmpeg.current.FS("unlink", `new_${fileName}`)

                setApplyingFilter(false)
                setPreviewBaseSimulated(dataURL)
            }).catch(error => {
                setApplyingFilter(false)
                console.error(error)
            })
        } catch (error) {
            setApplyingFilter(false)
            console.error(error)
        }
    }

    useEffect(() => {
        ffmpeg.current = createFFmpeg({
            // log: true
        })
        ffmpeg.current.load().then(() => {
            setFfmpegLoaded(true)
            ffmpeg.current.setProgress(({ ratio }) => setWorkProgress((ratio * 100).toFixed(2)))
        }).catch((error) => {
            toast.error(error.message)
        })
    }, [])

    useEffect(() => {
        setOptions(defaultOptions)
        setPreviewBaseSimulated(undefined)
        setApplyingFilter(false)
        revertAllChanges()
    }, [video])

    useEffect(() => {
        if (ffmpeg.current.isLoaded()) {
            const delayDebounceFn = setTimeout(() => {
                applyFilterSimulation({})
            }, 500)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [filterValues])

    if (!ffmpegLoaded)
        return <Loader />

    return (
        <section ref={editor} className="flex flex-col relative gap-2 max-w-[600px]">
            {processingVideo &&
                <div className="absolute top-0 left-0 w-full h-full bg-[#0009] flex justify-center items-center z-50">
                    <Loader text={`Procesando edición (${workProgress}%)`} textColor="text-white" />
                </div>
            }
            <div className="flex gap-5">
                <Button height="sm" className="text-blue-500" onClick={handleProcessVideo}>{langs[lang]['save']}</Button>
            </div>

            <div className="relative">
                {apllyingFilter &&
                    <div className="absolute flex justify-center items-center bg-[#0009] top-0 left-0 w-full h-full">
                        <Loader text={`${langs[lang]['applying']} ${workProgress}%`} />
                    </div>
                }
                <img crossOrigin="anonymous" className={`${options.filters || options.rotate ? 'visible' : 'hidden'}`} src={previewBaseSimulated || previewBase} alt="previewBase.png" />
            </div>
            <div className="relative">
                <video
                    ref={videoPreview}
                    className={`${(options.trim || (!options.filters && !options.rotate)) ? 'visible' : 'hidden'}`}
                    src={videoEditedSrc || videoSrc}
                    onLoadedData={(event) => {
                        videoData(event)
                        onVideoLoaded(event)
                    }}
                    controls
                    onTimeUpdate={handleTimeUpdate}
                    muted
                ></video>
            </div>

            <div className="flex justify-between">
                <p className="text-gray-500">{toTimeString(playingData.start, false)}</p>
                <p className="text-gray-500">{toTimeString(playingData.end, false)}</p>
            </div>

            <div className="flex gap-1">
                <OptionButton
                    icon={<MdOutlineStraighten className="text-lg" />}
                    optionName={langs[lang]['time']}
                    active={options.trim}
                    onActive={() => handleSelection("trim")}
                />
                <OptionButton
                    icon={<MdOutlineFilterBAndW className="text-lg" />}
                    optionName={langs[lang]['adjust']}
                    active={options.filters}
                    onActive={() => handleSelection("filters")}
                />
                <OptionButton
                    icon={<MdOutlineRotateLeft className="text-lg" />}
                    optionName={langs[lang]['rotate']}
                    active={options.rotate}
                    onActive={() => handleSelection("rotate")}
                />
            </div>
            {options.trim && (
                <>
                    <div className="flex justify-between">
                        <div className="p-2 rounded bg-gray-50">
                            <p className="text-center text-gray-500">{langs[lang]['start']}</p>
                            <p className="text-center text-gray-500">{toTimeString(trimData.start)}</p>
                        </div>

                        <div className="p-2 rounded bg-gray-50">
                            <p className="text-center text-gray-500">{langs[lang]['end']}</p>
                            <p className="text-center text-gray-500">{toTimeString(trimData.end)}</p>
                        </div>
                    </div>

                    {loadingThumbnails ?
                        <Loader text="Cargando timeline" />
                        :
                        <>
                            <div className="relative">
                                <div className="flex">
                                    {thumbnails.map((img, key) => <img
                                        style={{
                                            height: 50,
                                            width: editor.current.clientWidth / Math.floor(editor.current.clientWidth / 50),
                                            objectFit: 'cover',
                                            objectPosition: 'center'
                                        }}
                                        key={key}
                                        src={img}
                                        alt="Esto es un thumbnail"
                                    />)}
                                </div>

                                <RangeVideoSlider
                                    values={rangeValues}
                                    onChange={handleChangeRange}
                                />
                            </div>
                        </>
                    }
                </>
            )}


            {options.filters &&
                <div className="flex flex-col gap-2">
                    <div className="flex gap-1">
                        <OptionButton
                            optionName="Contraste"
                            icon={<MdContrast className="text-lg" />}
                            active={filterOptions.contrast}
                            onActive={() => handleSelectFilterOption('contrast')}
                        />
                        <OptionButton
                            optionName="Brillo"
                            icon={<BiBrightness className="text-lg" />}
                            active={filterOptions.brightness}
                            onActive={() => handleSelectFilterOption('brightness')}
                        />
                        <OptionButton
                            optionName="Saturación"
                            icon={<MdExposure className="text-lg" />}
                            active={filterOptions.saturation}
                            onActive={() => handleSelectFilterOption('saturation')}
                        />
                        <OptionButton
                            optionName="Colores"
                            icon={<MdPalette className="text-lg" />}
                            active={filterOptions.gamma}
                            onActive={() => handleSelectFilterOption('gamma')}
                        />
                    </div>

                    {
                        filterOptions.contrast &&
                        <div className="flex flex-col">
                            <p className="text-gray-500 text-center">{filterValues.contrast}</p>
                            <input
                                name="contrast"
                                type="range"
                                step={0.01}
                                min={-5}
                                max={5}
                                value={filterValues.contrast}
                                onChange={handleFilterValueChange}
                                onBlur={async event => await applyFilterSimulation({ [event.target.name]: event.target.value })}
                            />
                        </div>
                    }
                    {
                        filterOptions.brightness &&
                        <div className="flex flex-col">
                            <p className="text-gray-500 text-center">{filterValues.brightness}</p>
                            <input name="brightness" type="range" step={0.01} min={-1} max={1} value={filterValues.brightness} onChange={handleFilterValueChange} />
                        </div>
                    }
                    {
                        filterOptions.saturation &&
                        <div className="flex flex-col">
                            <p className="text-gray-500 text-center">{filterValues.saturation}</p>
                            <input name="saturation" type="range" step={0.01} min={0} max={3} value={filterValues.saturation} onChange={handleFilterValueChange} />
                        </div>
                    }

                    {
                        filterOptions.gamma &&
                        <div className="flex gap-5">
                            <div className="flex flex-auto flex-col gap-2 justify-center items-center">
                                <label className="text-gray-500">Rojo</label>
                                <p className="text-gray-500">{filterValues.gamma.red}</p>
                                <input name="red" type="range" step={0.01} min={0.1} max={10} value={filterValues.gamma.red} onChange={handleGammaValueChange} />
                            </div>
                            <div className="flex flex-auto flex-col gap-2 justify-center items-center">
                                <label className="text-gray-500">Verde</label>
                                <p className="text-gray-500">{filterValues.gamma.green}</p>
                                <input name="green" type="range" step={0.01} min={0.1} max={10} value={filterValues.gamma.green} onChange={handleGammaValueChange} />
                            </div>
                            <div className="flex flex-auto flex-col gap-2 justify-center items-center">
                                <label className="text-gray-500">Azul</label>
                                <p className="text-gray-500">{filterValues.gamma.blue}</p>
                                <input name="blue" type="range" step={0.01} min={0.1} max={10} value={filterValues.gamma.blue} onChange={handleGammaValueChange} />
                            </div>
                        </div>
                    }
                </div>
            }

            {options.rotate &&
                <div className="flex gap-2">
                    <RotateOption
                        active={rotateOptions.normal}
                        imgSrc={previewBaseSimulated || previewBase}
                        optionName="Original"
                        degrees="rotate-[0deg]"
                        onActive={() => {
                            handleSelectRotateOption('normal')
                            setRotateValue(0)
                        }}
                    />
                    <RotateOption
                        active={rotateOptions.rotate90}
                        imgSrc={previewBaseSimulated || previewBase}
                        optionName="90°"
                        degrees="rotate-[90deg]"
                        onActive={() => {
                            handleSelectRotateOption('rotate90')
                            setRotateValue(90)
                        }}
                    />
                    <RotateOption
                        active={rotateOptions.rotate180}
                        imgSrc={previewBaseSimulated || previewBase}
                        optionName="180°"
                        degrees="rotate-[180deg]"
                        onActive={() => {
                            handleSelectRotateOption('rotate180')
                            setRotateValue(180)
                        }}
                    />
                    <RotateOption
                        active={rotateOptions.rotate270}
                        imgSrc={previewBaseSimulated || previewBase}
                        optionName="270°"
                        degrees="rotate-[270deg]"
                        onActive={() => {
                            handleSelectRotateOption('rotate270')
                            setRotateValue(270)
                        }}
                    />
                </div>
            }
        </section>
    )
}

export default VideoEditor
