import { useState } from "react"
import Input from "../../../components/Input"
import Button from "../../../components/Button"
import { RiSearch2Line } from "react-icons/ri"
import { toast } from "react-hot-toast"
import { searchRoom } from "../../../services/room"
import RoomItem from "../../../components/RoomItem"
import { useSelector } from "react-redux"
import langs from "../../../lang/langs"

const SearchRoom = () => {
    const [lastRoomName, setLastRoomName] = useState("")
    const [roomName, setRoomName] = useState("")
    const [requesting, setRequesting] = useState(false)
    const [rooms, setRooms] = useState([])

    const { token, lang } = useSelector(state => state)

    const handleSearchRoom = (event) => {
        event.preventDefault()

        if (roomName.trim().length === 0 || roomName === lastRoomName) return

        setLastRoomName(roomName)
        setRequesting(true)
        searchRoom({ token, name: roomName }).then(res => {
            setRooms(res.data.payload.rooms)
            setRequesting(false)
        }).catch(({ response: res }) => {
            toast.error(res.data.message)
        })
    }

    return (
        <div className="flex flex-col gap-5">
            <form className="flex gap-2" onSubmit={handleSearchRoom}>
                <Input type="text" placeholder={langs[lang]['room_name']} onChange={({ target: { value } }) => setRoomName(value)} value={roomName} />
                <Button spinner={requesting} disabled={requesting}>{!requesting && <RiSearch2Line />}</Button>
            </form>
            {rooms.length > 0 &&
                <div className="flex flex-col gap-5">
                    <p className="text-gray-500">{rooms.length} {langs[lang]['results']}:</p>
                    <div className="flex flex-col gap-2">
                        {rooms.map(room => (
                            <RoomItem key={room.id} room={room} />
                        ))}
                    </div>
                </div>
            }
        </div>
    )
}

export default SearchRoom