import { RiArrowLeftSLine, RiIndeterminateCircleLine, RiMic2Fill, RiPhoneFill, RiPhoneLine, RiVidiconFill, RiVidiconLine } from "react-icons/ri"
import { ImPhone, ImPhoneHangUp } from 'react-icons/im'
import { BsCameraVideoOffFill } from 'react-icons/bs'
import { Link, useNavigate, useParams } from "react-router-dom"
import SendMessageBox from "./SendMessageBox"
import { useDispatch, useSelector } from "react-redux"
import { useContext, useEffect, useState } from "react"
import { API_URL } from "../../../config"
import { acceptChatRequest, cancelChatRequest, getChat, rejectChatRequest } from "../../../services/chat"

import {
    addMessage,
    deleteMessage,
    editMessage,
    resetChat,
    setChat,
    setLoading,
    setMessageAsSeen,
    setUserBlocked,
    toggleBlock
} from "../../../features/chatSlice"

import MessageList from "./MessageList"
import { SocketContext, useAddSocketEvent } from "../../../components/SocketProvider"
import { blockUser } from "../../../services/blockUser"
import { toast } from "react-hot-toast"
import Button from "../../../components/Button"
import Loader from "../../../components/Loader"
import theme from "../../../theme"
import { callUser } from "../../../services/call"
import { setCall, setCallId, setCallModal, setInCall, setOutgoingCall } from "../../../features/callSlice"
import Notification from "../../../components/Notification"
import langs from "../../../lang/langs"

const PrivateChat = () => {
    const { userId } = useParams()

    const { socket, peer } = useContext(SocketContext)


    const { token, session, privateChat, lang } = useSelector(state => state)
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const toggleBlockUser = () => {
        dispatch(toggleBlock(!privateChat.isBlocked))
        blockUser({
            token,
            userId: privateChat.user.id === session.user.id ? privateChat.chatUser.id : privateChat.user.id,
            socketId: socket.id,
            chatId: privateChat.id
        })
    }

    useAddSocketEvent('edit-message', ({ message }) => {
        dispatch(editMessage(message))
    })

    useAddSocketEvent('message', ({ message }) => {
        dispatch(addMessage(message))
        socket.emit('message:seen', message)
    })

    useAddSocketEvent('messages:seen', messageIds => {
        dispatch(setMessageAsSeen(messageIds))
    })

    useAddSocketEvent('unblock:user', () => {
        dispatch(setUserBlocked(null))
        dispatch(toggleBlock(false))
    })

    useAddSocketEvent('block:user', userBlocked => {
        dispatch(setUserBlocked(userBlocked))
        dispatch(toggleBlock(true))
    })

    useAddSocketEvent('chat:message:delete', ({ messageId }) => {
        dispatch(deleteMessage(Number(messageId)))
    })

    useAddSocketEvent('chat:cancel', () => {
        navigate('/chat/private')
        toast.error('Chat cancelado.')
    })

    useAddSocketEvent('chat:reject', () => {
        navigate('/chat/private')
        toast.error('Chat rechazado.')
    })

    const handleCancelChat = () => {
        cancelChatRequest({ token, userId: privateChat.chatUser.id, socketId: socket.id })
            .then(chat => {
                navigate('/chat/private')
                toast.success(chat.data.message)
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
            })
    }

    const handleAcceptChat = () => {
        acceptChatRequest({ token, userId: privateChat.user.id, socketId: socket.id })
            .catch(({ response }) => {
                toast.error(response.data.message)
            })
    }

    const handleRejectChat = () => {
        rejectChatRequest({ token, userId: privateChat.user.id })
            .then(chat => {
                navigate('/chat/private')
                toast.success(chat.data.message)
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
            })
    }

    const handleVideoCall = () => {
        dispatch(setCallModal(true))
        callUser({
            token,
            userId: session.user.id === privateChat.user.id ? privateChat.chatUser.id : privateChat.user.id,
            chatId: privateChat.id,
            socketId: socket.id,
            peerId: peer.id,
            sessionId: session.id,
            media: { video: true, audio: true },
            videoCall: true
        })
            .then(res => {
                socket.emit('call:start:room', { callId: res.data.payload.id })
                dispatch(setCallId(res.data.payload.id))
                dispatch(setInCall(true))
            })
            .catch(({ response }) => {
                toast.error(`${response.data.message}`)
                dispatch(setCallModal(false))
                dispatch(setOutgoingCall(false))
            })
    }

    const handleAudioCall = () => {
        dispatch(setCallModal(true))
        callUser({
            token,
            userId: session.user.id === privateChat.user.id ? privateChat.chatUser.id : privateChat.user.id,
            chatId: privateChat.id,
            socketId: socket.id,
            peerId: peer.id,
            sessionId: session.id,
            media: { video: false, audio: true },
            videoCall: false
        })
            .then(res => {
                socket.emit('call:start:room', { callId: res.data.payload.id })
                dispatch(setCallId(res.data.payload.id))
                dispatch(setInCall(true))
            })
            .catch(({ response }) => {
                toast.error(`${response.data.message}`)
                dispatch(setCallModal(false))
                dispatch(setOutgoingCall(false))
            })
    }

    useEffect(() => {
        socket?.emit('join-private-chat', { chatId: privateChat.id })
    }, [privateChat.id, socket])

    useEffect(() => {
        dispatch(resetChat())
        getChat({ token, userId, socketId: socket.id })
            .then(res => {
                console.log(res.data.payload.chat)
                dispatch(setChat(res.data.payload.chat))
                dispatch(setLoading(false))
            })
            .catch(({ response }) => {
                if (response.status === 404) {
                    navigate('/chat/private')
                }
            })
    }, [token])
    return (
        <div className="flex-auto flex rounded overflow-hidden">
            {privateChat.loading ?
                <Loader />
                :
                <div className="flex w-full flex-col bg-white">
                    {/* Header chat */}
                    <div className="flex gap-5 items-center border-b border-gray-200 px-5 py-3">
                        <Link to="/chat" className="text-blue-500"><RiArrowLeftSLine /></Link>
                        {
                            privateChat.user.id === session.user.id ?
                                <img crossOrigin="anonymous" className="w-[40px] h-[40px] rounded-full" src={privateChat.chatUser.profilePicture ? `${API_URL}/users/${privateChat.chatUser.id}/profile/picture` : '/pp.png'} alt={privateChat.chatUser.username} />
                                :
                                <img crossOrigin="anonymous" className="w-[40px] h-[40px] rounded-full" src={privateChat.user.profilePicture ? `${API_URL}/users/${privateChat.user.id}/profile/picture` : '/pp.png'} alt={privateChat.user.username} />
                        }
                        <div className="flex flex-col overflow-hidden">
                            <p className="text-gray-500 truncate">{session.user.id === privateChat.user.id ? privateChat.chatUser.username : privateChat.user.username}</p>
                            {(!privateChat.userBlocked || privateChat.userBlocked.blockedUserId !== session.user.id) ?
                                <button className="flex items-center gap-1 text-red-500 font-semibold text-xs uppercase" onClick={toggleBlockUser}>
                                    <RiIndeterminateCircleLine /> {privateChat.isBlocked ? langs[lang]['unblock'] : langs[lang]['block']}
                                </button>
                                :
                                <p className="text-gray-500">{langs[lang]['has_blocked_you']}</p>
                            }
                        </div>
                        <div className="flex gap-4 ml-auto">
                            <button
                                className={`${theme.primaryColor.text} text-xl`} title={langs[lang]['start_videocall']}
                                onClick={handleVideoCall}
                            ><RiVidiconLine /></button>
                            <button
                                className={`${theme.primaryColor.text} text-xl`} title={langs[lang]['start_call']}
                                onClick={handleAudioCall}
                            ><RiPhoneLine /></button>
                        </div>
                    </div>

                    <MessageList />

                    {(!privateChat.isBlocked && privateChat.accepted) ?
                        <SendMessageBox /> :
                        <div className="flex gap-5 bg-gray-100 p-5">
                            {privateChat.user.id !== session.user.id && !privateChat.accepted && !privateChat.isBlocked &&
                                <>
                                    <div className="flex flex-auto gap-2">
                                        <Button className="flex-auto bg-green-500" height="sm" onClick={handleAcceptChat}>{langs[lang]['accept']}</Button>
                                        <Button className="flex-auto bg-red-500" height="sm" onClick={handleRejectChat}>{langs[lang]['decline']}</Button>
                                    </div>
                                </>
                            }
                            {privateChat.user.id === session.user.id && !privateChat.accepted && !privateChat.isBlocked && <Button className="flex-auto bg-red-500" height="sm" onClick={handleCancelChat}>{langs[lang]['cancel_request']}</Button>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default PrivateChat