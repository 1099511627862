import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: null,
    chatId: null,
    groupId: null,
    members: [],
    modal: false,
    outgoingCall: false,
    incomingCall: false,
    inCall: false,
    callFinished: false,
    videoCall: false
}

export const callSlice = createSlice({
    name: 'call',
    initialState,
    reducers: {
        setCallId: (state, { payload: callId }) => {
            state.id = callId
        },
        setCallData: (state, { payload: call }) => {
            state.id = call.id
            state.chatId = call.chatId
            state.groupId = call.groupId
            state.closed = call.closed
            state.videoCall = call.videoCall
        },
        setCallMembers: (state, { payload: { members, myUserId, myPeerId, videoCall } }) => {
            state.members = members.map(member => {
                member.peerId = member.userId === myUserId ? myPeerId : null
                member.peerCall = null

                member.mediaStream = null
                member.media = { video: videoCall, audio: true }

                return member
            })
        },
        setCall: (state, { payload: { call, media, videoCall } }) => {
            state.call = call
            state.members = call.CallMembers.map(member => {
                return { ...member, media }
            })
            state.videoCall = videoCall
        },
        setCallModal: (state, { payload: visibility }) => {
            state.modal = visibility
        },
        setOutgoingCall: (state, { payload }) => {
            state.outgoingCall = payload
        },
        setIncomingCall: (state, { payload }) => {
            state.incomingCall = payload
        },
        setInCall: (state, { payload }) => {
            state.inCall = payload
        },
        setCallFinished: (state, { payload }) => {
            state.callFinished = payload
        },
        setPeerIdToMember: (state, { payload: { userId, peerId } }) => {
            const memberListUpdated = state.members.map(member => {
                if (member.userId === userId) {
                    member.peerId = peerId
                }

                return member
            })
            state.members = memberListUpdated
        },
        setUserAcceptCall: (state, { payload: { userId } }) => {
            const memberListUpdated = state.members.map(member => {
                if (member.userId === userId) {
                    member.accepted = true
                }

                return member
            })
            state.members = memberListUpdated
        },
        setMemberCallData: (state, { payload: { userId, call } }) => {
            const memberListUpdated = state.members.map(member => {
                if (member.userId === userId) {
                    member.peerCall = call
                }

                return member
            })
            state.members = memberListUpdated
        },
        setMemberCallDataByPeerId: (state, { payload: { peerId, call } }) => {
            const memberListUpdated = state.members.map(member => {
                if (member.peerId === peerId) {
                    member.peerCall = call
                }

                return member
            })
            state.members = memberListUpdated
        },
        setMemberMediaStream: (state, { payload: { userId, mediaStream } }) => {
            const memberListUpdated = state.members.map(member => {
                if (member.userId === userId) {
                    member.mediaStream = mediaStream
                }

                return member
            })
            state.members = memberListUpdated
        },
        setMemberMediaStreamByPeerId: (state, { payload: { peerId, mediaStream } }) => {
            const memberListUpdated = state.members.map(member => {
                if (member.peerId === peerId) {
                    member.mediaStream = mediaStream
                }

                return member
            })
            state.members = memberListUpdated
        },
        setMemberLeaveCall: (state, { payload: { userId } }) => {
            const memberListUpdated = state.members.filter(member => member.userId !== userId)
            state.members = memberListUpdated
        },
        setMemberLeaveCallByPeerId: (state, { payload: { peerId } }) => {
            const memberListUpdated = state.members.filter(member => member.peerId !== peerId)
            state.members = memberListUpdated
        },
        setMemberMediaVideo: (state, { payload: { userId, video } }) => {
            const memberListUpdated = state.members.map(member => {
                if (member.userId === userId) {
                    member.media.video = video
                }

                return member
            })
            state.members = memberListUpdated
        },
        setMemberMediaAudio: (state, { payload: { userId, audio } }) => {
            const memberListUpdated = state.members.map(member => {
                if (member.userId === userId) {
                    member.media.audio = audio
                }

                return member
            })
            state.members = memberListUpdated
        },
        updateMemberCallConnection: (state, { payload: { userId, audioTrack, videoTrack } }) => {
            const memberListUpdated = state.members.map(member => {
                if (member.userId === userId) {
                    member.call.peerConnection.getSenders()[0].replaceTrack(audioTrack)
                    member.call.peerConnection.getSenders()[1].replaceTrack(videoTrack)
                }

                return member
            })
            state.members = memberListUpdated
        },
        resetCall: () => initialState
    }

})
export const {
    setCallId,
    setCallData,
    setCallMembers,
    setCall,
    setCallModal,
    setOutgoingCall,
    setIncomingCall,
    setInCall,
    setCallFinished,
    resetCall,
    setPeerIdToMember,
    setUserAcceptCall,
    setMemberCallData,
    setMemberCallDataByPeerId,
    setMemberMediaStream,
    setMemberMediaStreamByPeerId,
    setMemberLeaveCall,
    setMemberLeaveCallByPeerId,
    setMemberMediaVideo,
    setMemberMediaAudio,
    updateMemberCallConnection
} = callSlice.actions
export default callSlice.reducer