import axios from "axios";
import { API_URL } from "../config";

export const getGroup = ({ token, groupId }) => axios.get(`${API_URL}/groups/${groupId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const getGroups = ({ token }) => axios.get(`${API_URL}/groups`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const createGroup = ({ token, name, membersIds }) => axios.post(`${API_URL}/groups`, { name, membersIds }, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})