import axios from "axios";
import { API_URL } from "../config";

export const leaveRoom = ({ token, roomId }) => axios.delete(`${API_URL}/rooms/members/${roomId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const expulseRoomMember = ({ token, memberId }) => axios.delete(`${API_URL}/rooms/members/expulse/${memberId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})