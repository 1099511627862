import { ImPhoneHangUp } from "react-icons/im"
import { FaVideo, FaVideoSlash } from "react-icons/fa";
import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import CallMember from "./CallMember";
import { useContext, useEffect, useRef } from "react";
import {
    resetCall,
    setCallData,
    setCallFinished,
    setCallMembers,
    setInCall,
    setMemberCallData,
    setMemberCallDataByPeerId,
    setMemberLeaveCall,
    setMemberLeaveCallByPeerId,
    setMemberMediaAudio,
    setMemberMediaStream,
    setMemberMediaStreamByPeerId,
    setMemberMediaVideo,
    setPeerIdToMember,
} from "../../features/callSlice";
import { SocketContext, useAddSocketEvent } from "../SocketProvider";
import { getCallData, hangUpCall, rejectCall } from "../../services/call";
import { toast } from "react-hot-toast";
import Notification from "../Notification";
import { useState } from "react";
import langs from "../../lang/langs";

const InCall = () => {
    const { call, session, token, lang } = useSelector(state => state)
    const { socket, peer } = useContext(SocketContext)

    const [media, setMedia] = useState({})
    const [callDataGetted, setCallDataGetted] = useState(false)
    const [readyToCall, setReadyToCall] = useState(false)
    const myStream = useRef()

    const dispatch = useDispatch()

    const handleHangUp = () => {
        myStream.current.getTracks().forEach(track => track.stop())
        myStream.current = null

        hangUpCall({ token, callId: call.id, sessionId: session.id }).then(() => {
            call.members.forEach(member => {
                if (member.userId === session.userId) {
                    return
                }

                member?.call?.close()
            })
            socket.emit('call:hangup', { callId: call.id, userId: session.userId })
            setMedia(false)
            dispatch(setInCall(false))
            dispatch(setCallFinished(true))
        }).catch(() => {
            dispatch(setInCall(false))
            dispatch(setCallFinished(true))
        })
    }

    const handleToggleVideo = () => {
        socket.emit('call:toggle:video', { callId: call.id, userId: session.userId, video: !media.video })
        setMedia({ ...media, video: !media.video })
        dispatch(setMemberMediaVideo({ userId: session.userId, video: !media.video }))
    }

    const handleToggleAudio = () => {
        socket.emit('call:toggle:audio', { callId: call.id, userId: session.userId, audio: !media.audio })
        setMedia({ ...media, audio: !media.audio })
        dispatch(setMemberMediaAudio({ userId: session.userId, audio: !media.audio }))
    }

    useAddSocketEvent('call:user:ready', ({ userId, peerId, callId }) => {
        if (!readyToCall || callId !== call.id) return

        dispatch(setPeerIdToMember({ userId, peerId }))

        socket.emit('call:exchange:data', { userId: session.userId, peerId: peer.id, callId: call.id, toUserId: userId })

    }, [readyToCall])

    useAddSocketEvent('call:exchange:data', ({ userId, peerId, toUserId, callId }) => {
        if (toUserId !== session.userId || callId !== call.id) return

        const peerCall = peer.call(peerId, myStream.current)

        dispatch(setMemberCallData({ userId, call: peerCall }))

        peerCall.on('stream', (mediaStream) => {
            dispatch(setMemberMediaStream({ userId, mediaStream }))
        })

        peerCall.on('close', () => dispatch(setMemberLeaveCall({ userId })))

        dispatch(setPeerIdToMember({ userId, peerId }))
    })

    useAddSocketEvent('call:reject', ({ userId, callId }) => {
        if (callId !== call.id) return
        dispatch(setMemberLeaveCall({ userId }))
    })

    useAddSocketEvent('call:toggle:video', ({ userId, video }) => {
        dispatch(setMemberMediaVideo({ userId, video }))
    })

    useAddSocketEvent('call:toggle:audio', ({ userId, audio }) => {
        dispatch(setMemberMediaAudio({ userId, audio }))
    })

    // Pasos
    // 1. Obtener información de la base de datos
    useEffect(() => {
        getCallData({ token, callId: call.id }).then((res) => {
            dispatch(setCallData(res.data.payload))

            dispatch(setCallMembers({
                members: res.data.payload.CallMembers,
                myPeerId: peer.id,
                myUserId: session.userId,
                videoCall: res.data.payload.videoCall
            }))

            setCallDataGetted(true)
        })
    }, [])

    // 2. Obtener cámara y micrófono
    useEffect(() => {
        if (callDataGetted) {
            console.log({ video: call.videoCall, audio: true })
            navigator.mediaDevices
                .getUserMedia({ video: call.videoCall, audio: true })
                .then((mediaStream) => {
                    myStream.current = mediaStream
                    setMedia({ video: call.videoCall, audio: true })
                    socket.emit('call:user:ready', { callId: call.id, userId: session.userId, peerId: peer.id })

                    dispatch(setMemberMediaStream({ userId: session.userId, mediaStream }))

                    setReadyToCall(true)
                })
                .catch((error) => {
                    console.log(error)
                    const userIncoming = call.members.find(member => Number(member.userId) !== session.userId)

                    rejectCall({ callId: call.id, token, sessionId: session.id, userId: userIncoming.userId })
                    dispatch(resetCall())

                    toast.custom(t => <Notification text={langs[lang]['request_camera_and_mic']} />)
                })
        }
    }, [callDataGetted])


    // 3. Esperar llamados
    useEffect(() => {
        peer.on('call', (call) => {
            dispatch(setMemberCallDataByPeerId({ peerId: call.peer, call }))
            call.answer(myStream.current)

            call.on('stream', mediaStream => {
                dispatch(setMemberMediaStreamByPeerId({ peerId: call.peer, mediaStream }))
            })

            call.on('close', () => dispatch(setMemberLeaveCallByPeerId({ peerId: call.peer })))
        })
    }, [])


    return (
        <div className="w-full h-full max-h-full flex flex-col justify-between bg-[#0009] p-5">
            {/* info */}
            <p className="text-white text-center">{langs[lang]['call_in_progress']}</p>
            <div className={`grid ${call.members.length > 2 ? 'grid-cols-2' : 'grid-cols-1'} md:${call.members.length > 2 ? 'grid-cols-4' : 'grid-cols-1'} gap-2 max-h-full overflow-y-auto`}>
                {call.members.map(member => (
                    <CallMember key={member.id} member={member} />
                ))}
            </div>
            {/* Opciones */}
            <div className="flex justify-center gap-2">
                {call.videoCall && <button
                    className="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-white text-black"
                    onClick={handleToggleVideo}
                >
                    {media.video ? <FaVideo /> : <FaVideoSlash />}
                </button>}
                <button
                    className="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-white text-black"
                    onClick={handleToggleAudio}
                >
                    {media.audio ? <BsFillMicFill /> : <BsFillMicMuteFill />}
                </button>
                <button
                    className="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-red-500 text-white"
                    onClick={handleHangUp}
                >
                    <ImPhoneHangUp />
                </button>
            </div>
        </div>
    )
}

export default InCall