import { useState } from "react"
import { toast } from "react-hot-toast"
import { Link, useNavigate } from "react-router-dom"
import Button from "../../components/Button"
import Input from "../../components/Input"
import VerificationForm from "../../components/VerificationForm"
import { createUser } from "../../services/user"
import langs from "../../lang/langs"
import { useSelector } from "react-redux"
import SelectLenguage from "../../components/SelectLanguage"

const RegisterPage = () => {
    const navigate = useNavigate()
    const [requesting, setRequesting] = useState(false)
    const [verificationStep, setVerificationStep] = useState(false)

    const { lang } = useSelector(state => state)

    const [userId, setUserId] = useState(null)
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: ''
    })
    const handleInputChange = ({ target: { name, value } }) => setForm({ ...form, [name]: value })

    const handleSubmit = event => {
        event.preventDefault()
        setRequesting(true)
        createUser({ data: form })
            .then((response) => {
                toast.success(response.data.message)
                setRequesting(false)
                setVerificationStep(true)
                setUserId(response.data.payload.user.id)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setRequesting(false)
            })
    }
    if (verificationStep) return (
        <div className="bg-gray-300 flex min-h-screen items-center justify-center">
            <VerificationForm userId={userId} callback={() => {
                navigate('/')
            }} />
        </div>
    )
    return (
        <div className="bg-gray-300 flex min-h-screen items-center justify-center">
            <div className="flex flex-col w-[400px] gap-5 bg-white border border-1 border-gray-100 px-5 py-10 rounded">
                <img crossOrigin="anonymous" src="/logo-app.png" className="w-[200px] mx-auto" alt='your world logo' />
                <p className="text-md text-center text-gray-500">{langs[lang]['register_subtitle']}</p>
                <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                    <div className="flex gap-5">
                        <Input
                            label={langs[lang]['name']}
                            type="text"
                            name="firstName"
                            placeholder={langs[lang]['name_example']}
                            onChange={handleInputChange}
                            value={form.firstName}
                        />
                        <Input
                            label={langs[lang]['last_name']}
                            type="text"
                            name="lastName"
                            placeholder={langs[lang]['last_name_example']}
                            onChange={handleInputChange}
                            value={form.lastName}
                        />
                    </div>
                    <Input
                        label={langs[lang]['username']}
                        type="text"
                        name="username"
                        placeholder={langs[lang]['username_example']}
                        onChange={handleInputChange}
                        value={form.username}
                    />
                    <Input
                        label={langs[lang]['email']}
                        type="email"
                        name="email"
                        placeholder={langs[lang]['email_example']}
                        onChange={handleInputChange}
                        value={form.email}
                    />
                    <Input
                        label={langs[lang]['password']}
                        type="password"
                        name="password"
                        placeholder={langs[lang]['password_example']}
                        onChange={handleInputChange}
                        value={form.password}
                    />
                    <Link className="text-xs text-gray-500 text-center" to="/terms-and-conditions">Terms and conditions</Link>
                    <Button spinner={+requesting} disabled={requesting}>{!requesting && langs[lang]['register']}</Button>
                    <Link to="/" className="text-gray-500 text-center">{langs[lang]['login_link']}</Link>
                </form>
                <SelectLenguage />
            </div>
        </div>
    )
}

export default RegisterPage