import { useDispatch, useSelector } from "react-redux"
import langs from '../../lang/langs'
import Modal from '../Modal'
import { useState } from "react"
import {changeLang} from '../../features/languageSlice'

const SelectLenguage = () => {
    const { lang } = useSelector(state => state)
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()

    const handleChangeLang = (langCode) => {
        dispatch(changeLang(langCode))
    }


    return (
        <div className="mx-auto">
            <button
                onClick={() => setOpen(true)}
                className="border rounded py-1 px-5 text-sm text-gray-500 h-[30px]"
            >{langs[lang]['lang_name']}</button>
            <Modal modalOpen={open} set={setOpen}>
                <div className="flex flex-col bg-white rounded w-[320px] max-h-[450px] overflow-auto">
                    <button
                        className={`text-center text-red-500 min-h-[50px] uppercase hover:bg-gray-100`}
                        onClick={() => setOpen(false)}
                    >{langs[lang]['close']}</button>
                    {
                        Object.values(langs).map((langData, index) => {
                            return (
                                <button
                                    key={index}
                                    className={`text-center ${langData['lang_name'] === langs[lang]['lang_name'] ? "text-green-500" : "text-gray-500"} min-h-[50px] uppercase hover:bg-gray-100`}
                                    onClick={() => handleChangeLang(Object.keys(langs)[index])}
                                >{langData['lang_name']}</button>
                            )
                        })
                    }
                </div>
            </Modal>
        </div>
    )
}

export default SelectLenguage