import { RiArrowLeftSLine, RiInformationFill } from "react-icons/ri"
import { Link, useNavigate, useParams } from "react-router-dom"
import SendMessageBox from "./SendMessageBox"
import { useDispatch, useSelector } from "react-redux"
import { useContext, useEffect, useState } from "react"
import MessageList from "./MessageList"
import { SocketContext, useAddSocketEvent } from "../../../components/SocketProvider"
import Loader from "../../../components/Loader"
import Modal from "../../../components/Modal"
import { API_URL } from "../../../config"
import { addMember, addMessage, deleteMessage, editMessage, removeRoomMember, resetRoom, setLoading, setRoom } from "../../../features/roomSlice"
import { getRoom } from "../../../services/room"
import LeaveRoomButton from "../../../components/LeaveRoomButton"
import MembersList from "../RoomPage/MemberList"
import { toast } from "react-hot-toast"
import langs from "../../../lang/langs"

const RoomChat = () => {
    const { roomId } = useParams()

    const { socket } = useContext(SocketContext)

    const [membersModal, setMembersModal] = useState(false)

    const { token, roomChat, session, lang } = useSelector(state => state)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    useAddSocketEvent('room:message:edit', ({ message }) => {
        dispatch(editMessage(message))
    })

    useAddSocketEvent('message:room:new', (message) => {
        dispatch(addMessage(message))
    })

    useAddSocketEvent('room:member:join', (roomMember) => {
        dispatch(addMember(roomMember))
    })

    useAddSocketEvent('room:member:leave', (roomMemberId) => {
        dispatch(removeRoomMember(roomMemberId))
    })

    useAddSocketEvent('room:message:delete', ({ messageId }) => {
        dispatch(deleteMessage(Number(messageId)))
    })

    useAddSocketEvent('room:member:expulse', (member) => {
        if (member.user.id === session.user.id) {
            navigate('/chat/rooms')
            return
        }
        toast(() => (
            <>{member.user.username} {langs[lang]['has_been_expulsed']}.</>
        ))
        dispatch(removeRoomMember(member.id))
    })


    useEffect(() => {
        socket?.emit('room:join', { roomId: roomChat.id })
    }, [roomChat.id, socket])

    useEffect(() => {
        dispatch(resetRoom())
        getRoom({ token, roomId })
            .then(res => {
                dispatch(setRoom(res.data.payload.room))
                dispatch(setLoading(false))
            })
            .catch(({ response }) => {
                if (response.status === 404) {
                    navigate('/chat/rooms')
                    toast(() => (
                        <div>
                            <p className="text-gray-500">{langs[lang]['room_doesnt_exists']}</p>
                        </div>
                    ))
                } else if (response.status === 409 && response.data.payload.notMember) {
                    navigate('/chat/rooms')
                    toast(() => (
                        <div>
                            <p className="text-gray-500">{langs[lang]['you_arent_room_member']}</p>
                        </div>
                    ))
                }
                else if (response.status === 500) {
                    navigate('/chat/rooms')
                    toast.error(response.data.message)
                }
            })
    }, [token])

    return (
        <div className="flex-auto flex rounded overflow-hidden">
            {
                roomChat.loading ?
                    <Loader />
                    :
                    <div className="flex w-full flex-col bg-white">
                        {/* Header chat */}
                        <div className="flex gap-5 items-center border-b border-gray-200 px-5 py-3">
                            <Link to="/chat/rooms" className="text-blue-500"><RiArrowLeftSLine /></Link>

                            <div className="flex flex-col gap-2">
                                <p className="text-gray-500">{roomChat.name}</p>
                                <button className="flex items-center gap-1 text-gray-500 px-2 rounded-full border self-start" onClick={() => setMembersModal(true)}><RiInformationFill /> Info</button>
                                <Modal modalOpen={membersModal} set={setMembersModal}>
                                    <MembersList />
                                </Modal>
                            </div>
                            <LeaveRoomButton />
                        </div>
                        <MessageList />
                        <SendMessageBox />
                    </div>
            }
        </div>
    )
}

export default RoomChat