import { createSlice } from '@reduxjs/toolkit'
import { contentCategoryEnums } from '../enums'

const initialState = {
    tag: {
        parent: null,
        name: ''
    },
    contentCategory: {},
    contentCategoryId: null,
    tags: [],
    files: [],
    loading: true
}

export const multimediaSlice = createSlice({
    name: 'multimedia',
    initialState,
    reducers: {
        setTag: (state, { payload }) => {
            state.tag = payload
        },
        setContent: (state, { payload }) => {
            state.tags = payload.tags
            state.files = payload.files
        },
        setContentCategory: (state, { payload }) => {
            state.contentCategory = contentCategoryEnums[payload.name] || {}
            state.contentCategoryId = payload.id
        },
        addTag: (state, { payload }) => {
            state.tags.unshift(payload)
        },
        renameTag: (state, { payload: { id, name } }) => {
            const newTags = state.tags.map(tag => {
                if (tag.id === id) {
                    tag.name = name
                }
                return tag
            })
            state.tags = newTags
        },
        removeTag: (state, { payload }) => {
            const newTags = state.tags.filter(tag => tag.id !== payload)
            state.tags = newTags
        },
        removeFile: (state, { payload }) => {
            const newFiles = state.files.filter(file => file.id !== payload)
            state.files = newFiles
        },
        renameFile: (state, { payload: { id, name } }) => {
            const newFiles = state.files.map(file => {
                if (file.id === id) {
                    file.name = name
                }
                return file
            })
            state.files = newFiles
        },
        setLoading: (state, { payload }) => {
            state.loading = payload
        },
        reset: () => initialState
    }

})
export const {
    setTag,
    setLoading,
    setContent,
    addTag,
    setContentCategory,
    removeTag,
    renameTag,
    removeFile,
    renameFile,
    reset
} = multimediaSlice.actions
export default multimediaSlice.reducer