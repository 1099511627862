import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import File from "../File"
import Tag from "../Tag"
import { getContentCategory } from "../../services/contentCategory"
import { addTag, setContent, setContentCategory, setLoading } from "../../features/multimediaSlice"
import { RiAddFill } from "react-icons/ri"
import Input from "../Input"
import Button from "../Button"
import { createTag } from "../../services/tag"
import { toast } from "react-hot-toast"
import Loader from "../Loader"
import useOnline from "../../hooks/useOnline"
import langs from "../../lang/langs"

const MultimediaManager = ({ contentCategoryName }) => {
    const online = useOnline()
    const [tagName, setTagName] = useState("")
    const [displayCreateTag, setDisplayCreateTag] = useState(false)
    const { token, multimedia, lang } = useSelector(state => state)
    const dispatch = useDispatch()

    const handleChange = ({ target: { value } }) => setTagName(value)

    const handleSubmit = (event) => {
        event.preventDefault()
        createTag({ token, contentCategoryId: multimedia.contentCategoryId, tagName }).then(res => {
            toast.success('Tag creado.')
            dispatch(addTag(res.data.payload))
            setDisplayCreateTag(false)
            setTagName("")
        }).catch(({ response }) => {
            toast.error(response.data.message)
        })
    }
    useEffect(() => {
        if (online) {
            dispatch(setLoading(true))
            getContentCategory({ token, name: contentCategoryName })
                .then((response) => {
                    dispatch(setContent({
                        tags: response.data.payload.tags, files: response.data.payload.files
                    }))
                    dispatch(setContentCategory(response.data.payload.contentCategory))
                    dispatch(setLoading(false))
                })
                .catch(({ response }) => {
                    toast.error(response.data.message)
                })
        } else {
            dispatch(setLoading(false))
        }

    }, [token, contentCategoryName, dispatch, online])

    if (multimedia.loading) return <Loader />

    return (
        <div className="flex flex-col gap-2 bg-white p-5 rounded-lg">
            {online ?
                <>
                    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
                        <button className="flex items-center justify-center gap-2 text-gray-500 px-5 py-2 border rounded-lg" onClick={() => setDisplayCreateTag(!displayCreateTag)}><RiAddFill /> {langs[lang]["new_tag"]}</button>
                    </div>
                    {displayCreateTag &&
                        <form className="flex gap-2" onSubmit={handleSubmit}>
                            <Input className="flex-auto" onChange={handleChange} placeholder={langs[lang]['name']} />
                            <Button>{langs[lang]['create']}</Button>
                        </form>
                    }
                    {multimedia.tags.length === 0 && multimedia.files.length === 0 ?
                        <div className="flex flex-col gap-2 bg-white p-5 rounded-lg">
                            <p className="text-gray-500">{langs[lang]['no_content_uploaded']}</p>
                        </div>
                        :
                        <>
                            {multimedia.tags.length > 0 &&
                                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
                                    {multimedia.tags.map(tag => <Tag key={`tag_${tag.id}`} tag={tag} />)}
                                </div>
                            }
                            {multimedia.files.length > 0 &&
                                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
                                    {multimedia.files.map(file => <File key={`file_${file.id}`} file={file} />)}
                                </div>
                            }
                        </>
                    }
                </>
                : <p className="text-center text-gray-500">{langs[lang]['offline']}</p>
            }
        </div>
    )
}

export default MultimediaManager