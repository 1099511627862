import { useState } from "react"
import { toast } from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { renameFile as renameFileAction } from "../../features/multimediaSlice"
import { renameFile } from "../../services/file"
import Button from "../Button"
import Input from "../Input"
import langs from "../../lang/langs"

const RenameFile = ({ file: { id, name }, setRenameModalOpen }) => {
    const parts = name.split('.')
    const [fileName, setFileName] = useState(parts[0])

    const { token, lang } = useSelector(state => state)
    const dispatch = useDispatch()

    const handleChange = ({ target: { value } }) => setFileName(value)

    const handleSubmit = event => {
        event.preventDefault()
        renameFile({ fileName, fileId: id, token }).then(() => {
            toast.success("Tag renombrado.")
            dispatch(renameFileAction({ id, name: fileName + '.' + parts[parts.length - 1] }))
            setRenameModalOpen(false)
        }).catch(({ response }) => {
            toast.error(response.data.message)
        })
    }

    return (
        <div className="flex flex-col bg-white rounded w-[310px] overflow-hidden">
            <p className="text-center px-5 py-2 font-semibold">{name}</p>
            <form className="flex flex-col gap-2 p-2" onSubmit={handleSubmit}>
                <Input type="text" value={fileName} onChange={handleChange} />
                <Button>{langs[lang]['rename']}</Button>
            </form>
        </div>
    )
}

export default RenameFile