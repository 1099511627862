import { useState } from "react"
import { toast } from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { renameTag as renameTagAction } from "../../features/multimediaSlice"
import { renameTag } from "../../services/tag"
import Button from "../Button"
import Input from "../Input"
import langs from "../../lang/langs"

const RenameTag = ({ tag: { id, name }, setRenameModalOpen }) => {
    const [tagName, setTagName] = useState(name)

    const { token, lang } = useSelector(state => state)
    const dispatch = useDispatch()

    const handleChange = ({ target: { value } }) => setTagName(value)

    const handleSubmit = event => {
        event.preventDefault()
        renameTag({ tagName, tagId: id, token })
            .then(() => {
                dispatch(renameTagAction({ id, name: tagName }))
                setRenameModalOpen(false)
                toast.success('Tag renombrado.')
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
            })
    }

    return (
        <div className="flex flex-col bg-white rounded w-[310px] overflow-hidden">
            <p className="text-center px-5 py-2 font-semibold">{name}</p>
            <form className="flex flex-col gap-2 p-2" onSubmit={handleSubmit}>
                <Input type="text" value={tagName} onChange={handleChange} />
                <Button>{langs[lang]['rename']}</Button>
            </form>
        </div>
    )
}

export default RenameTag