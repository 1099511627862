import { useEffect, useRef } from "react"

const Modal = ({ modalOpen, set, onClose, children, bgColor = "bg-[#0000007d]" }) => {
    const modal = useRef({})

    useEffect(() => {
        modal.current.addEventListener('click', (event) => {
            if (!modal.current.children[0].contains(event.target)) {
                if (onClose) onClose()
                else set(false)
                document.body.style.overflow = 'auto'
            }
        })
    }, [set, onClose])
    return (
        <div ref={modal} className={`${!modalOpen ? 'invisible' : 'flex'} flex-col z-10 fixed left-0 top-0 w-full h-full ${bgColor} items-center justify-center`}>
            {children}
        </div>
    )
}

export default Modal