import { NavLink, Outlet } from "react-router-dom"
import langs from "../../../lang/langs"
import { useSelector } from "react-redux"

const ChatTemplate = () => {
    const { lang } = useSelector(state => state)
    return (
        <div className="flex-auto flex flex-col gap-2">
            <div className="flex gap-1 flex-wrap overflow-hidden">
                <NavLink
                    to="/chat/private"
                    className={({ isActive }) => `truncate border flex text-sm items-center flex-auto justify-center gap-1 p-1 rounded-lg ${isActive ? 'border-[#4da2d9] text-[#4da2d9]' : 'border-white text-gray-500'} bg-white`}>
                    {langs[lang]['chats']}
                </NavLink>
                <NavLink
                    to="/chat/groups"
                    className={({ isActive }) => `truncate border flex text-sm items-center flex-auto justify-center gap-1 p-1 rounded-lg ${isActive ? 'border-[#4da2d9] text-[#4da2d9]' : 'border-white text-gray-500'} bg-white`}>
                    {langs[lang]['groups']}
                </NavLink>
                <NavLink
                    to="/chat/rooms"
                    className={({ isActive }) => `truncate border flex text-sm items-center flex-auto justify-center gap-1 p-1 rounded-lg ${isActive ? 'border-[#4da2d9] text-[#4da2d9]' : 'border-white text-gray-500'} bg-white`}>
                    {langs[lang]['rooms']}
                </NavLink>
            </div>
            <Outlet />
        </div>
    )
}

export default ChatTemplate