import { useState } from "react"
import { toast } from "react-hot-toast"
import { RiArrowRightSLine, RiCloseFill } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { removeTag } from "../../features/multimediaSlice"
import { deleteTag, getAvailableTags } from "../../services/tag"
import Text from "../Text"
import langs from "../../lang/langs"

const DeleteTag = ({ tag: { id, name } }) => {
    const [keepContent, setKeepContent] = useState(false)
    const [showAvailableTags, setShowAvailableTags] = useState(false)
    const [availableTags, setAvailableTags] = useState([])
    const [pathTag, setPathTag] = useState([])

    const { multimedia, token, lang } = useSelector(state => state)
    const dispatch = useDispatch()

    const loadTags = ({ target: { checked } }) => {
        setKeepContent(checked)
        if (checked) {
            getAvailableTags({ tagId: id, contentCategoryId: multimedia.contentCategoryId, token })
                .then(response => {
                    setAvailableTags(response.data.payload)
                    setShowAvailableTags(true)
                })
                .catch(({ response }) => {
                    toast.error(response.data.message)
                })
            return
        }
        setShowAvailableTags(false)
    }

    const deletePathTag = () => {
        setPathTag([])
        setShowAvailableTags(false)
        getAvailableTags({ tagId: id, contentCategoryId: multimedia.contentCategoryId, token }).then(response => {
            setAvailableTags(response.data.payload)
            setShowAvailableTags(true)
        })
    }

    const selectTag = (tag) => {
        setShowAvailableTags(false)
        let newList = [...pathTag]
        newList.push(tag)
        setPathTag(newList)
        getAvailableTags({ tagId: id, contentCategoryId: multimedia.contentCategoryId, token, actualTagId: tag.id }).then(response => {
            setAvailableTags(response.data.payload)
            setShowAvailableTags(true)
        })
    }

    const handleDeleteTag = () => {
        deleteTag({ tagId: id, keepContent, destTagId: pathTag[pathTag.length - 1]?.id, token })
            .then((response) => {
                toast.success(response.data.message)
                dispatch(removeTag(id))
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
            })
    }

    return (
        <div className="flex flex-col gap-2 bg-white rounded w-[310px] overflow-hidden">
            <div className="flex flex-col gap-2">
                <p className="text-center px-5 py-2 font-semibold">{name}</p>
                <p className="text-center text-gray-500">{langs[lang]['keep_tag_content']}</p>
                <label className="flex item-center gap-2 justify-center text-center text-gray-500">
                    <input type="checkbox" onChange={loadTags} /> {langs[lang]['keep']}
                </label>
                <div className="px-5">
                    {showAvailableTags &&
                        <div className="flex flex-col gap-2 text-gray-500">
                            <Text>{langs[lang]['move_content_to']}</Text>
                            <div className="flex flex-wrap gap-2 items-center">
                                {pathTag.length > 0 && <button onClick={deletePathTag}><RiCloseFill /></button>}
                                {pathTag.length === 0 && <Text>{multimedia.contentCategory.name}</Text>}
                                {
                                    pathTag.map((tag, index) => {
                                        return (
                                            <div key={'path_' + tag.id} className="flex gap-2 items-center">
                                                {index !== 0 && <RiArrowRightSLine className="text-md" />}
                                                <Text>
                                                    {tag.name}
                                                </Text>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="flex flex-col gap-2">
                                {availableTags.length > 0 &&
                                    availableTags.map(tag =>
                                        <button key={tag.id} className="px-5 py-2 border rounded-lg" onClick={() => selectTag(tag)}>{tag.name}</button>
                                    )
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
            <button className="px-5 py-3 text-red-500 uppercase text-sm hover:bg-gray-100" onClick={handleDeleteTag}>{langs[lang]['delete']}</button>
        </div>
    )
}

export default DeleteTag