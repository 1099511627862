import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import ProfilePicture from "../../../components/ProfilePicture"
import { reset } from "../../../features/multimediaSlice"
import { setSessionData } from "../../../features/sessionSlice"
import { deleteToken } from "../../../features/tokenSlice"
import { getSession } from "../../../services/session"
import { deleteUser, updateEmail, updatePassword, updateUser } from "../../../services/user"
import Loader from "../../../components/Loader"
import langs from "../../../lang/langs"
import Modal from "../../../components/Modal"

const UpdateProfile = () => {
    const [loading, setLoading] = useState(true)
    const [requesting, setRequesting] = useState(false)
    const [requestingEmailForm, setRequestingEmailForm] = useState(false)
    const [requestingPasswordForm, setRequestingPasswordForm] = useState(false)
    const { token, session, lang } = useSelector(state => state)

    const [deleteUserModal, setDeleteUserModal] = useState(false)

    const [user, setUser] = useState({
        firstName: session.user.firstName,
        lastName: session.user.lastName,
        username: session.user.username,
    })
    const [emailForm, setEmailForm] = useState({
        email: session.user.email,
        password: ''
    })
    const [passwordForm, setPasswordForm] = useState({
        password: '',
        newPassword: ''
    })

    const dispatch = useDispatch()

    const handleChange = ({ target: { name, value } }) => setUser({ ...user, [name]: value })
    const handleChangeEmailForm = ({ target: { name, value } }) => setEmailForm({ ...emailForm, [name]: value })
    const handleChangePasswordForm = ({ target: { name, value } }) => setPasswordForm({ ...passwordForm, [name]: value })

    const handleSubmit = (event) => {
        event.preventDefault()
        setRequesting(true)
        updateUser({ token, data: user }).then((response) => {
            toast.success(response.data.message)
            setRequesting(false)
        }).catch(({ response }) => {
            toast.error(response.data.message)
            setRequesting(false)
        })
    }

    const handleSubmitEmailForm = (event) => {
        event.preventDefault()
        setRequestingEmailForm(true)
        updateEmail({ token, data: emailForm }).then((response) => {
            toast.success(response.data.message)
            setRequestingEmailForm(false)
        }).catch(({ response }) => {
            toast.error(response.data.message)
            setRequestingEmailForm(false)
        })
    }

    const handleSubmitPasswordForm = (event) => {
        event.preventDefault()
        setRequestingPasswordForm(true)
        updatePassword({ token, data: passwordForm }).then((response) => {
            toast.success(response.data.message)
            setRequestingPasswordForm(false)
        }).catch(({ response }) => {
            toast.error(response.data.message)
            setRequestingPasswordForm(false)
        })
    }

    const handleDelete = () => {
        deleteUser({ token }).then(() => {
            toast.success("Account deleted")
            dispatch(deleteToken())
        })
        .catch(() => toast.error("An error occurred deleting your account ;("))
    }

    useEffect(() => {
        dispatch(reset())
        getSession({ token })
            .then((response) => {
                dispatch(setSessionData(response.data.payload))

                setLoading(false)
            })
            .catch(({ response }) => {
                if (response.status === 404) {
                    dispatch(deleteToken())
                }
            })
    }, [token, dispatch])

    if (loading) return <Loader />

    return (
        <div className="flex flex-col gap-2 bg-white p-5 rounded-lg">
            <div className="flex items-center justify-center">
                <ProfilePicture userId={session.user.id} allowUpdate={true} size='lg' />
            </div>
            <div className="p-5">
                <form className="flex flex-col gap-5 md:w-[450px] md:mx-auto" onSubmit={handleSubmit}>
                    <p className="text-gray-500">{langs[lang]['update_info']}</p>
                    <div className="flex gap-5">
                        <Input label={langs[lang]['name']} type="text" name="firstName" value={user.firstName} onChange={handleChange} />
                        <Input label={langs[lang]['last_name']} type="text" name="lastName" value={user.lastName} onChange={handleChange} />
                    </div>
                    <Input label={langs[lang]['username']} type="text" name="username" value={user.username} onChange={handleChange} />
                    <Button disabled={requesting}>{requesting ? langs[lang]['updating'] : langs[lang]['update']}</Button>
                </form>
            </div>

            <div className="p-5">
                <form className="flex flex-col gap-5 md:w-[450px] md:mx-auto" onSubmit={handleSubmitEmailForm}>
                    <p className="text-gray-500">{langs[lang]['update_email']}</p>
                    <Input label={langs[lang]['email']} type="text" name="email" value={emailForm.email} onChange={handleChangeEmailForm} />
                    <Input label={langs[lang]['password']} type="password" name="password" value={emailForm.password} onChange={handleChangeEmailForm} />
                    <Button disabled={requestingEmailForm}>{requestingEmailForm ? langs[lang]['updating'] : langs[lang]['update']}</Button>
                </form>
            </div>

            <div className="p-5">
                <form className="flex flex-col gap-5 md:w-[450px] md:mx-auto" onSubmit={handleSubmitPasswordForm}>
                    <p className="text-gray-500">{langs[lang]['change_password']}</p>
                    <Input label={langs[lang]['current_password']} type="password" name="password" value={passwordForm.password} onChange={handleChangePasswordForm} />
                    <Input label={langs[lang]['new_password']} type="password" name="newPassword" value={passwordForm.newPassword} onChange={handleChangePasswordForm} />
                    <Button disabled={requestingPasswordForm}>{requestingPasswordForm ? langs[lang]['updating'] : langs[lang]['update']}</Button>
                </form>
            </div>

            <Button className="bg-red-500 md:w-[450px] md:mx-auto" onClick={() => setDeleteUserModal(true)}>{langs[lang]["delete"]}</Button>
            <Modal modalOpen={deleteUserModal} set={setDeleteUserModal}>
                <div className="flex flex-col gap-5 max-w-[350px] w-full p-5 rounded bg-white">
                    <p className="text-zinc-500 text-center">Are you sure that you want <b>delete</b> your YourWorld account?</p>
                    <p className="text-zinc-500 text-center text-sm"><b>All</b> your uploaded <b>files</b> will be deleted and all your <b>chats</b> will be converted to anonymous.</p>
                    <Button className="bg-red-500" onClick={handleDelete}>Yes, i want delete my account</Button>
                </div>
            </Modal>
        </div>
    )
}

export default UpdateProfile