import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { RiAddFill, RiArrowRightSLine } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import Button from "../../../components/Button"
import File from "../../../components/File"
import Input from "../../../components/Input"
import Tag from "../../../components/Tag"
import { addTag, setContent, setContentCategory, setLoading, setTag } from "../../../features/multimediaSlice"
import { createSubtag, getTag } from "../../../services/tag"
import Loader from "../../../components/Loader"
import Text from "../../../components/Text"
import langs from "../../../lang/langs"

const TagPage = () => {
    const [displayCreateSubtag, setDisplayCreateSubtag] = useState(false)
    const [tagName, setTagName] = useState("")
    const { tagId } = useParams()
    const { token, multimedia, lang } = useSelector(state => state)
    const dispatch = useDispatch()

    const handleChange = ({ target: { value } }) => setTagName(value)

    const handleSubmit = (event) => {
        event.preventDefault()
        createSubtag({ token, tagName, tagId }).then(res => {
            toast.success('Tag creado.')
            dispatch(addTag(res.data.payload))
            setDisplayCreateSubtag(false)
            setTagName("")
        }).catch(({ response }) => {
            toast.error(response.data.message)
        })
    }

    useEffect(() => {
        dispatch(setLoading(true))
        getTag({ tagId, token }).then(tag => {
            dispatch(setTag({
                name: tag.data.payload.name,
                parent: tag.data.payload.parent
            }))
            dispatch(setContent({
                tags: tag.data.payload.subTags,
                files: tag.data.payload.files
            }))
            dispatch(setContentCategory(tag.data.payload.ContentCategory))
            dispatch(setLoading(false))
        })
    }, [tagId, token, dispatch])

    if (multimedia.loading) return <Loader />

    return (
        <div className="flex flex-col gap-2 bg-white p-5 rounded-lg">
            <div className="flex gap-2 items-center text-gray-500">
                {!multimedia.tag.parent && <Link to={`/${multimedia.contentCategory.route}`}>{langs[lang][multimedia.contentCategory.route]}</Link>}
                {multimedia.tag.parent && <Link to={`/tags/${multimedia.tag.parent.id}`}>{multimedia.tag.parent.name}</Link>}
                <RiArrowRightSLine className="text-md" />
                <Text>{multimedia.tag.name}</Text>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
                <button className="flex items-center justify-center gap-2 text-gray-500 px-5 py-2 border rounded-lg" onClick={() => setDisplayCreateSubtag(!displayCreateSubtag)}><RiAddFill /> {langs[lang]['new_subtag']}</button>
            </div>
            {displayCreateSubtag && 
                <form className="flex gap-2" onSubmit={handleSubmit}>
                    <Input className="flex-auto" placeholder={langs[lang]['name']} onChange={handleChange} />
                    <Button>{langs[lang]['create']}</Button>
                </form>
            }
            {multimedia.tags.length === 0 && multimedia.files.length === 0 ?
                <div className="flex flex-col gap-2 bg-white p-5 rounded-lg">
                    <p className="text-gray-500">{langs[lang]['no_content_uploaded']}</p>
                </div>
                :
                <>
                    {multimedia.tags.length > 0 &&
                        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
                            {multimedia.tags.map(tag => <Tag key={tag.id} tag={tag} />)}
                        </div>
                    }
                    {multimedia.files.length > 0 &&
                        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
                            {multimedia.files.map(file => <File key={file.id} file={file} />)}
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default TagPage