import { useContext, useEffect, useReducer, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { reset } from "../../../features/multimediaSlice"
import { getChats } from "../../../services/chat"
import Chat from "../../../components/Chat"
import { SocketContext, useAddSocketEvent } from "../../../components/SocketProvider"
import { acceptChat, addChat, removeChat, setChats } from "../../../features/chatListSlice"
import Loader from "../../../components/Loader"
import Text from "../../../components/Text"
import langs from "../../../lang/langs"

const ChatPage = () => {
    const { socket } = useContext(SocketContext)

    const [loading, setLoading] = useState(true)
    const { token, chatList, lang } = useSelector(state => state)
    const dispatch = useDispatch()

    useAddSocketEvent('new-individual-chat', chat => {
        dispatch(addChat(chat))
    }, [chatList])

    useAddSocketEvent('chat:update', chat => {
        dispatch(addChat(chat))
    }, [chatList])

    useEffect(() => {

        socket?.on('delete-chat', chatId => {
            dispatch(removeChat(chatId))
        })

        socket?.on('accept-chat', chatId => {
            dispatch(acceptChat(chatId))
        })

        return () => {
            socket?.off('delete-chat')
            socket?.off('accept-chat')
        }
    }, [socket, chatList, setChats])


    useEffect(() => {
        getChats({ token }).then(chats => {
            dispatch(setChats(chats.data.payload))
            setLoading(false)
        })
        dispatch(reset())
    }, [dispatch, token])

    if (loading) return <Loader />

        return (
            <div className="p-5 bg-white rounded">
                <div className="flex flex-col gap-5">
                    {chatList.length > 0 ?
                        chatList.map(chat => <Chat key={chat.id} chat={chat} />)
                        :
                        <Text>{langs[lang]['no_chats']}</Text>
                    }
                </div>
            </div>
        )
}

export default ChatPage