import { useEffect, useState } from "react"
import Loader from "../../../components/Loader"
import PhotoEditorPro from "../../../components/PhotoEditorPro"
import axios from "axios"
import { API_URL } from "../../../config"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { getFileData, uploadImageEdited } from "../../../services/file"
import { toast } from "react-hot-toast"
import Button from "../../../components/Button"
import Modal from "../../../components/Modal"
import VideoEditor from "../../../components/VideoEditor"
import langs from "../../../lang/langs"

const EditVideo = () => {
    const { videoId } = useParams()
    const [videoDownloaded, setVideoDownloaded] = useState(false)
    const [progressPercentage, setProgressPercentage] = useState(0.0)
    const [uploading, setUploading] = useState(false)
    const [progressPercentageUploading, setProgressPercentageUploading] = useState(0.0)
    const [editing, setEditing] = useState(true)
    const [file, setFile] = useState({})
    const [video, setVideo] = useState({})
    const [videoEdited, setVideoEdited] = useState({})
    const [error, setError] = useState(false)
    const [modalUploadOpen, setModalUploadOpen] = useState(false)
    const [conserve, setConserve] = useState(false)
    const navigate = useNavigate()

    const { token, lang } = useSelector(state => state)
    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const handleUpload = () => {
        setUploading(true)
        uploadImageEdited({
            file: videoEdited.blob,
            fileId: videoId,
            conserve,
            token,
            callback: (event) => setProgressPercentageUploading(event.progress * 100)
        }).then(() => {
            navigate('/videos')
            toast.success(langs[lang]['changes_saved'])
        })
    }

    useEffect(() => {
        setVideoDownloaded(false)

        getFileData({ token, fileId: videoId }).then(res => {
            setFile(res.data.payload.file)
            axios.get(`${API_URL}/files/${videoId}`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                onDownloadProgress: ({ progress }) => setProgressPercentage(progress * 100)
            })
                .then((response) => {
                    const url = URL.createObjectURL(response.data)
                    setVideo({ src: url })

                    setVideoDownloaded(true)
                    setProgressPercentage(0.0)
                }).catch((error) => {
                    console.log(error)
                })
        }).catch((error) => {
            setError(true)
            console.log(error)
        })

    }, [videoId, token])

    if (!videoDownloaded && !error) return <Loader text={`${langs[lang]['downloading_video']} ${progressPercentage.toFixed(2)}%`} />

    if (error) return <div className="flex flex-auto justify-center items-center">
        <p className="text-gray-500">{langs[lang]['an_error_has_ocurred']}</p>
    </div>

    if (uploading) return <Loader text={`${langs[lang]['saving_changes']} ${progressPercentageUploading.toFixed(2)}%`} />

    return (
        <div className="flex bg-white rounded p-5 flex-col gap-5">
            <div className="flex gap-5 justify-between items-center">
                <p className="text-gray-500">{file.name}</p>
                {!editing && <Button height="sm" onClick={() => setEditing(true)}>{langs[lang]['go_back_to_edit']}</Button>}
            </div>
            {editing &&
                <VideoEditor
                    video={file}
                    videoSrc={video.src}
                />
            }
            {!editing && <div className="w-[420px] self-center">
                <img crossOrigin="anonymous" src={videoEdited.src} alt={file.name} />
            </div>}
            <Modal modalOpen={modalUploadOpen} set={setModalUploadOpen}>
                <div className="w-[320px] bg-white flex flex-col gap-5 rounded p-5">
                    <p className="text-gray-500 text-center">{langs[lang]['video_upload_warning_modal']}</p>
                    <p className="text-gray-500 text-center">{langs[lang]['upload_question_modal']}</p>
                    <label className="text-gray-500 text-center">
                        <input type="checkbox" onChange={() => setConserve(!conserve)} /> {langs[lang]['create_copy_and_keep']}
                    </label>
                    <Button onClick={handleUpload}>{langs[lang]['upload']}</Button>
                </div>
            </Modal>
            {!editing && <Button onClick={() => setModalUploadOpen(true)}>{langs[lang]['upload_changes']}</Button>}
        </div>

    )
}

export default EditVideo