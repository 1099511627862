export const toTimeString = (sec, showMilliSeconds = true) => {
    sec = parseFloat(sec)
    let hours = Math.floor(sec / 3600)
    let minutes = Math.floor((sec - hours * 3600) / 60)
    let seconds = sec - hours * 3600 - minutes * 60

    if (hours < 10) {
        hours = "0" + hours
    }
    if (minutes < 10) {
        minutes = "0" + minutes
    }
    if (seconds < 10) {
        seconds = "0" + seconds
    }


    let maltissaRegex = /\..*$/
    let millisec = String(seconds).split(".")

    return (
        hours +
        ":" +
        minutes +
        ":" +
        String(seconds).replace(maltissaRegex, "") +
        (showMilliSeconds
            ? millisec[1]
                ? `.${millisec[1].slice(0, 3)}`
                : ".000"
            : "")
    )
}


export const readFileAsBase64 = async file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
            resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
    })
}