import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: 0,
    user: null,
    chatUser: null,
    accepted: false,
    isBlocked: false,
    userBlocked: null,

    loading: true,
    messages: [],
    uploadProgress: {}
}

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setChat: (state, { payload }) => {
            state.id = payload.id
            state.user = payload.user
            state.chatUser = payload.chatUser
            state.accepted = payload.accepted
            state.isBlocked = payload.isBlocked
            state.userBlocked = payload.UserBlocked
        },
        setMessages: (state, { payload }) => {
            state.messages = payload
        },
        addNewMessageBlock: (state, { payload }) => {
            const newList = payload.concat(state.messages)
            state.messages = newList
        },
        addMessage: (state, { payload }) => {
            const find = state.messages.find(message => message.id === payload.id)
            if (!find) {
                state.messages.push(payload)
            }
        },
        editMessage: (state, { payload: { id, text } }) => {
            const newMessages = state.messages.map(message => {
                if (message.id === id) {
                    message.text = text
                    message.updated = true
                }
                return message
            })
            state.messages = newMessages
        },
        setMessageAsSeen: (state, { payload: messageIds }) => {
            for (const messageId of messageIds) {
                for (const message of state.messages) {
                    if (messageId === message.id) {
                        message.seen = true
                    }
                }
            }
        },
        deleteMessage: (state, { payload: messageId }) => {
            const newMessages = state.messages.filter(message => message.id !== messageId)
            state.messages = newMessages
        },
        addUploadProgress: (state, { payload: { id } }) => {
            state.uploadProgress[id] = 0
        },
        updateUploadProgress: (state, { payload: { id, progress } }) => {
            state.uploadProgress[id] = progress
        },
        resolveMessage: (state, { payload: { id, newId, files } }) => {
            const newMessages = state.messages.map(message => {
                if (message.id === id) {
                    message.id = newId
                    message.MessageFiles = files
                }
                return message
            })
            state.messages = newMessages
        },
        setLoading: (state, { payload }) => {
            state.loading = payload
        },
        setUserBlocked: (state, { payload: userBlocked }) => {
            state.userBlocked = userBlocked
        },
        toggleBlock: (state, { payload: isBlocked }) => {
            state.isBlocked = isBlocked
        },
        resetChat: () => initialState
    }

})
export const {
    setChat,
    setMessages,
    addNewMessageBlock,
    addMessage,
    setMessageAsSeen,
    addUploadProgress,
    updateUploadProgress,
    editMessage,
    deleteMessage,
    setLoading,
    resetChat,
    setUserBlocked,
    toggleBlock,
    resolveMessage
} = chatSlice.actions
export default chatSlice.reducer