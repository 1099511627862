import { useContext, useState } from "react"
import { RiCheckFill, RiCloseFill, RiIndeterminateCircleLine, RiSendPlane2Fill } from "react-icons/ri"
import { useSelector } from "react-redux"
import { API_URL } from "../../config"
import { acceptChatRequest, cancelChatRequest, sendChatRequest } from "../../services/chat"
import { toast } from "react-hot-toast"
import { Link } from "react-router-dom"
import { blockUser } from "../../services/blockUser"
import { SocketContext } from "../SocketProvider"
import langs from "../../lang/langs"

const UserResult = ({ user }) => {
    const [chat, setChat] = useState(user.chat)
    const [requesting, setRequesting] = useState(false)
    const [isBlocked, setIsBlocked] = useState(user.isBlocked)
    const { session, token, lang } = useSelector(state => state)

    const { socket } = useContext(SocketContext)

    const handleSendChat = () => {
        setRequesting(true)
        sendChatRequest({ token, userId: user.id })
            .then(chat => {
                setRequesting(false)
                setChat(chat.data.payload)
                toast.success(chat.data.message)
            })
            .catch(({ response }) => {
                setRequesting(false)
                toast.error(response.data.message)
            })
    }

    const handleCancelChat = () => {
        setRequesting(true)
        cancelChatRequest({ token, userId: user.id })
            .then(chat => {
                setRequesting(false)
                setChat(null)
                toast.success(chat.data.message)
            })
            .catch(({ response }) => {
                setRequesting(false)
                toast.error(response.data.message)
            })
    }

    const handleAcceptChat = () => {
        setRequesting(true)
        acceptChatRequest({ token, userId: user.id })
            .then(chat => {
                setRequesting(false)
                setChat({ ...chat, accepted: true })
                toast.success(chat.data.message)
            })
            .catch(({ response }) => {
                setRequesting(false)
                toast.error(response.data.message)
            })
    }


    const toggleBlockUser = () => {
        setIsBlocked(!isBlocked)
        blockUser({ token, userId: user.id, socketId: socket.id })
    }

    return (
        <div className="flex gap-5 items-center">
            <img crossOrigin="anonymous" className="w-[40px] h-[40px] rounded-full" src={user.profilePicture ? `${API_URL}/users/${user.id}/profile/picture` : '/pp.png'} alt={user.username} />
            <div className="flex flex-col gap-1">
                <p className="text-gray-500 capitalize">{user.firstName} {user.lastName}</p>
                <p className="text-gray-500">@{user.username}</p>
                {
                    !requesting ?
                        !chat
                            ?
                            <button className="flex items-center gap-1 text-[#4da2d9] font-semibold text-xs uppercase" onClick={handleSendChat}><RiSendPlane2Fill /> {langs[lang]['chat_request']}</button>
                            : chat.userId === session.user.id && !chat.accepted ?
                                <button className="flex items-center gap-1 text-[#4da2d9] font-semibold text-xs uppercase" onClick={handleCancelChat}><RiCloseFill /> {langs[lang]['cancel_request']}</button>
                                : chat.userId !== session.user.id && !chat.accepted ?
                                    <button className="flex items-center gap-1 text-[#4da2d9] font-semibold text-xs uppercase" onClick={handleAcceptChat}><RiCheckFill /> {langs[lang]['accept_request']}</button>
                                    : <Link to={`/private/chat/${user.id}`} className="flex items-center gap-1 text-[#4da2d9] font-semibold text-xs uppercase">{langs[lang]['go_to_chat']}</Link>
                        : <button className="flex items-center gap-1 text-[#4da2d9] font-semibold text-xs uppercase">{langs[lang]['please_wait']}</button>
                }
                <button className="flex items-center gap-1 text-red-500 font-semibold text-xs uppercase" onClick={toggleBlockUser}>
                    <RiIndeterminateCircleLine /> {isBlocked ? langs[lang]['unblock'] : langs[lang]['block']}
                </button>
            </div>
        </div>
    )
}

export default UserResult