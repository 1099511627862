import { createSlice } from '@reduxjs/toolkit'

const initialState = null

export const profilePictureSlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        changeLang: (state, { payload }) => payload,
        resetLang: () => initialState
    }

})
export const { changeLang, resetLang } = profilePictureSlice.actions
export default profilePictureSlice.reducer