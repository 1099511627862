import { useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { leaveRoom } from "../../services/roomMember"
import langs from "../../lang/langs"

const LeaveRoomButton = () => {
    const [requesting, setRequesting] = useState(false)
    const { token, roomChat, lang } = useSelector(state => state)
    const navigate = useNavigate()
    const handleLeaveGroup = () => {
        setRequesting(true)
        leaveRoom({ token, roomId: roomChat.id })
            .then(() => {
                toast.success(langs[lang]['you_have_left_the_room'])
                navigate('/chat/rooms')
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
                setRequesting(false)
            })
    }
    return (
        <button
            className="ml-auto text-red-500"
            onClick={handleLeaveGroup}
            disabled={requesting}
        >{requesting ? langs[lang]['coming_out'] : langs[lang]['leave_room']}</button>
    )
}

export default LeaveRoomButton