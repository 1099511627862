import { useContext, useEffect, useRef, useState } from "react"
import { RiCheckDoubleFill, RiCheckFill, RiErrorWarningFill, RiFileFill, RiMicFill, RiMore2Fill, RiPencilFill, RiSendPlane2Fill, RiTimeLine, RiVideoFill, RiVidiconFill } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { deleteMessage as deleteMessageChat, resolveMessage as resolveChatMessage } from "../../features/chatSlice"
import { deleteMessage as deleteMessageGroup } from '../../features/groupSlice'
import { deleteMessage as deleteMessageRoom } from '../../features/roomSlice'
import { resolveMessage as resolveGroupMessage } from "../../features/groupSlice"
import { resolveMessage as resolveRoomMessage } from "../../features/roomSlice"
import { deleteMessage as deleteMessageService, editMessage as editMessageService } from "../../services/message"
import { SocketContext } from "../SocketProvider"
import useAutosizeTextArea from "../../hooks/useAutosizeTextArea"
import { API_URL } from "../../config"
import axios from "axios"
import moment from "moment/moment"
import Modal from "../Modal"
import AudioPlayer from "../AudioPlayer"
import Text from "../Text"
import toast from "react-hot-toast"
import langs from "../../lang/langs"

const Message = ({
    message,
    editMessageBehavior = null,
    deleteMessageBehavior = null,
    showUsername = false
}) => {
    const messageRef = useRef()
    const textArea = useRef()
    const textAreaEditMessage = useRef()
    const multimedia = useRef()

    const [openFileModal, setOpenFileModal] = useState(false)

    const type = message?.MessageFiles[0]?.mimetype ? message?.MessageFiles[0]?.mimetype.split('/')[0] : undefined

    const { socket } = useContext(SocketContext)

    const [downloading, setDownloading] = useState(false)
    const [downloadProgress, setDownloadProgress] = useState(0.0)

    const [fileBuffer, setFileBuffer] = useState(null)
    const [pending, setPending] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [state, setState] = useState({
        text: message.text,
        edit: false,
        updated: message.updated,
        pending: true,
    })
    const [openMessageOptions, setOpenMessageOptions] = useState(false)
    const [openEditMessage, setOpenEditMessage] = useState(false)
    const [openDeleteMessage, setOpenDeleteMessage] = useState(false)
    const [deleting, setDeleting] = useState(false)


    useAutosizeTextArea(textAreaEditMessage.current, state.text)
    useAutosizeTextArea(textArea.current, state.text)

    const { session, token, privateChat, groupChat, roomChat, lang } = useSelector(state => state)
    const dispatch = useDispatch()

    const handleDownloadFile = () => {
        if (downloading) return
        if (!fileBuffer) {
            setDownloading(true)
            axios.get(`${API_URL}/messages/files/${message.MessageFiles[0].id}`, {
                responseType: 'blob',
                onDownloadProgress: ({ progress }) => setDownloadProgress(progress * 100),
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                const url = URL.createObjectURL(res.data)
                setFileBuffer(url)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', message.MessageFiles[0].name)

                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                setDownloading(false)
            })
        } else {
            const link = document.createElement('a')
            link.href = fileBuffer
            link.setAttribute('download', message.MessageFiles[0].name)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }

    }

    const handleEditMessage = (event) => {
        event.preventDefault()
        if (state.text.trim().length === 0) {
            setState({ ...state, text: message.text })
            setOpenEditMessage(false)
            return
        }

        if (state.text.trim() === message.text) {
            setOpenEditMessage(false)
            return
        }

        setOpenEditMessage(false)
        setState({ ...state, edit: false, updated: true })
        if (!editMessageBehavior) {
            editMessageService({ token, messageId: message.id, text: state.text, socketId: socket.id })
        } else {
            editMessageBehavior({ text: state.text, messageId: message.id })
        }
    }

    const handleOnDbClick = () => {
        setOpenEditMessage(true)
    }

    const handleTextChange = ({ target: { value } }) => {
        setState({ ...state, text: value })
    }


    const handleSkipLine = (event) => {
        if (event.which === 13 && !event.shiftKey) {
            handleEditMessage(event)
        }
    }

    const handleCloseFileModal = () => {
        if (type === 'video' || type === 'audio') {
            multimedia.current.pause()
        }
        setOpenFileModal(false)
    }

    const handleEditMessageOption = () => {
        setOpenMessageOptions(false)
        setOpenEditMessage(true)
    }

    const handleDeleteMessageOption = () => {
        setOpenMessageOptions(false)
        setOpenDeleteMessage(true)
    }

    const handleDeleteMessage = () => {
        setDeleting(true)
        deleteMessageService({ token, messageId: message.id, socketId: socket.id }).then(() => {
            setOpenDeleteMessage(false)
            setDeleting(false)
            toast.success(langs[lang]['message_deleted'])
            if (message.chatId) {
                dispatch(deleteMessageChat(message.id))
            } else if (message.groupId) {
                dispatch(deleteMessageGroup(message.id))
            } else {
                dispatch(deleteMessageRoom(message.id))
            }
        }).catch(() => {
            toast.error(langs[lang]['has_been_error_deleting_message'])
        })
    }

    useEffect(() => {
        if (message.promise) {
            setPending(true)
            message.promise
                .then((res) => {
                    if (res.data.payload.message.groupId) {
                        dispatch(resolveGroupMessage({ id: message.id, newId: res.data.payload.message.id, files: res.data.payload.message.MessageFiles }))
                    } else if (res.data.payload.message.roomId) {
                        dispatch(resolveRoomMessage({ id: message.id, newId: res.data.payload.message.id, files: res.data.payload.message.MessageFiles }))
                    } else {
                        dispatch(resolveChatMessage({ id: message.id, newId: res.data.payload.message.id, files: res.data.payload.message.MessageFiles }))
                    }
                    setPending(false)
                    socket.emit('send-private-message', { chatId: res.data.payload.message.chatId, message: res.data.payload.message })
                })
                .catch(({ response }) => {
                    setPending(false)
                    setError(true)
                    setErrorMessage(response.data.message)
                })
        }
    }, [])

    useEffect(() => {
        setState({ ...state, text: message.text, updated: message.updated })
    }, [message.text])


    return (
        <div
            ref={messageRef}
            className={`${session.user.id === message.User.id ? 'self-end' : 'self-start'} flex rounded-xl gap-1 overflow-hidden`}
        >

            <Modal modalOpen={openEditMessage} set={setOpenEditMessage}>
                <div className="flex flex-col bg-white rounded w-[310px] p-4 overflow-hidden">
                    <form className="flex items-center gap-2" onSubmit={handleEditMessage}>
                        <textarea
                            ref={textArea}
                            className="w-full min-h-[40px] h-auto resize-none px-5 py-2 rounded bg-gray-100 text-gray-500 outline-none"
                            type="text"
                            placeholder={langs[lang]['message_placeholder']}
                            onChange={handleTextChange}
                            rows={1}
                            onKeyDown={handleSkipLine}
                            value={state.text}
                        ></textarea>
                        <button className={`flex items-center justify-center min-w-[40px] min-h-[40px] text-blue-500`}>
                            <RiSendPlane2Fill />
                        </button>
                    </form>
                </div>
            </Modal>

            <Modal modalOpen={openDeleteMessage} set={setOpenDeleteMessage}>
                <div className="flex flex-col bg-white rounded w-[310px] overflow-hidden">
                    <div className="p-2">
                        <Text align="text-center">¿Quieres borrar este mensaje?</Text>
                    </div>
                    <button className="px-5 py-3 text-center text-red-500 uppercase text-sm hover:bg-gray-100" onClick={handleDeleteMessage}>
                        {deleting ? langs[lang]['deleting'] : langs[lang]['delete']}
                    </button>
                </div>
            </Modal>

            <Modal modalOpen={openMessageOptions} set={setOpenMessageOptions}>
                <div className="flex flex-col bg-white rounded w-[310px] overflow-hidden">
                    <div className="p-2 overflow-hidden">
                        {message.MessageFiles.length > 0 && <Text align="text-center" truncate>{message.MessageFiles[0].name}</Text>}
                        {message.text.length > 0 && <Text align="text-center" truncate>{message.text}</Text>}
                    </div>
                    <button className="px-5 py-3 text-center text-gray-500 uppercase text-sm hover:bg-gray-100" onClick={handleEditMessageOption}>{langs[lang]['edit']}</button>
                    <button className="px-5 py-3 text-center text-red-500 uppercase text-sm hover:bg-gray-100" onClick={handleDeleteMessageOption}>{langs[lang]['delete']}</button>
                </div>
            </Modal>

            {session.user.id === message.User.id ?

                <button className="text-gray-500" onClick={() => setOpenMessageOptions(true)}><RiMore2Fill /></button>
                :
                <img crossOrigin="anonymous" className="w-[30px] h-[30px] rounded-full border self-center" src={message.User.profilePicture ? `${API_URL}/users/${message.User.id}/profile/picture` : '/pp.png'} alt={message.User.username} />
            }

            <div className={`flex flex-col min-w-[180px] rounded-xl gap-1 overflow-hidden`}>
                {/* Modal multimedia */}
                {
                    message.MessageFiles.length > 0 && (type === 'image' || type === 'video' || type === 'audio') &&
                    <Modal modalOpen={openFileModal} set={setOpenFileModal} onClose={handleCloseFileModal}>
                        <div className="flex justify-center items-center relative w-full h-full max-h-full">
                            <div className="flex justify-between p-5 bg-[#00000070] absolute w-full top-0">
                                <p className="text-white">{message.MessageFiles[0].name}</p>
                                <button className="text-red-300 font-semibold uppercase text-sm z-50" onClick={handleCloseFileModal}>{langs[lang]['close']}</button>
                            </div>
                            {
                                type === 'image' ?
                                    <img crossOrigin="anonymous" className="max-h-full" src={`${API_URL}/messages/files/${message.MessageFiles[0].id}`} alt={message.MessageFiles[0].name} />
                                    : type === 'video' ?
                                        <video crossOrigin="anonymous" className="max-h-full" ref={multimedia} src={`${API_URL}/messages/files/${message.MessageFiles[0].id}`} controls></video>
                                        : <audio crossOrigin="anonymous" className="max-h-full" ref={multimedia} src={`${API_URL}/messages/files/${message.MessageFiles[0].id}`} controls></audio>
                            }

                        </div>
                    </Modal>
                }

                {/* Texto que muestra el nombre del usuario */}
                {showUsername && session.user.id !== message.User.id &&
                    <div className="flex gap-1 items-center p-1 self-start rounded-full">
                        <p className="text-gray-500">{message.User.username}</p>
                    </div>
                }

                {/* Preview sí el archivo se trata de una imágen */}
                {
                    type === 'image' &&
                    <div className={`${session.user.id === message.User.id ? 'self-end' : 'self-start'} w-[120px] h-[120px] relative rounded-2xl overflow-hidden`}>
                        {downloading &&
                            <div className="absolute flex justify-center items-center left-0 w-full h-full bg-[#00000060]">
                                {downloadProgress.toFixed(2)}%
                            </div>
                        }
                        <img crossOrigin="anonymous" className={`${session.user.id === message.User.id ? 'self-end' : 'self-start'}  w-[120px] h-[120px] rounded-2xl cursor-pointer`} src={`${API_URL}/messages/files/image/${message.MessageFiles[0].id}/preview`} alt={message.MessageFiles[0].name} onClick={() => setOpenFileModal(true)} />
                    </div>
                }
                {/* Preview sí el archivo se trata de un vídeo */}
                {
                    type === 'video' &&
                    <div className={`${session.user.id === message.User.id ? 'self-end' : 'self-start'} w-[120px] h-[120px] relative rounded-2xl overflow-hidden`}>
                        {downloading &&
                            <div className="absolute flex justify-center items-center left-0 w-full h-full bg-[#00000060]">
                                {downloadProgress.toFixed(2)}%
                            </div>
                        }
                        <div className="flex items-center justify-center absolute left-1 top-1 bg-[#00000060] w-[30px] h-[30px] rounded-full text-white">
                            <RiVideoFill />
                        </div>
                        <img crossOrigin="anonymous" className={`${session.user.id === message.User.id ? 'self-end' : 'self-start'}  w-[120px] h-[120px] rounded-2xl cursor-pointer`} src={`${API_URL}/messages/files/video/${message.MessageFiles[0].id}/preview`} alt={message.MessageFiles[0].name} onClick={() => setOpenFileModal(true)} />
                    </div>
                }
                <div className={`flex flex-col min-w-[180px] rounded-xl gap-1 overflow-hidden ${session.user.id === message.User.id ? 'bg-white text-gray-500' : 'bg-blue-500 text-white'}`}>
                    {
                        ((type && (type !== 'image' && type !== 'video')) || (pending || message.text.length > 0)) &&
                        <div className={`${session.user.id === message.User.id ? 'self-start bg-white text-gray-500' : 'self-start bg-blue-500 text-white'} flex flex-col rounded-2xl overflow-hidden`}>
                            {(type !== 'image' && type !== 'video' && type !== 'audio') && message.MessageFiles.length > 0 &&
                                <p className={`flex items-center gap-1 mx-2 px-2 py-1 rounded-full`}>
                                    {type === 'audio' && <RiMicFill />}
                                    {(type === 'application' || type === 'text' || type === 'font') && <RiFileFill />}
                                    {(type === 'video') && <RiVideoFill />}
                                    <button
                                        onClick={(type === 'application' || type === 'text' || type === 'font' || type === 'audio') ? handleDownloadFile : () => setOpenFileModal(true)}>{downloading && <span>({downloadProgress.toFixed(2)}%)</span>} {message.MessageFiles[0].name}</button>
                                </p>
                            }

                            {pending && message.MessageFiles.length > 0 &&
                                <div className="h-[20px] rounded-lg bg-gray-100 overflow-hidden mx-5">
                                    {/* #4da2d9 */}
                                    {message.chatId && <div className="flex items-center justify-center bg-[#4da2d9] text-xs font-medium text-white text-center leading-none h-full" style={{ width: `${privateChat?.uploadProgress[message.id]?.toFixed(2)}%` }}>{privateChat?.uploadProgress[message.id]?.toFixed(2)}%</div>}
                                    {message.groupId && <div className="flex items-center justify-center bg-[#4da2d9] text-xs font-medium text-white text-center leading-none h-full" style={{ width: `${groupChat?.uploadProgress[message.id]?.toFixed(2)}%` }}>{groupChat?.uploadProgress[message.id]?.toFixed(2)}%</div>}
                                    {message.roomId && <div className="flex items-center justify-center bg-[#4da2d9] text-xs font-medium text-white text-center leading-none h-full" style={{ width: `${roomChat?.uploadProgress[message.id]?.toFixed(2)}%` }}>{roomChat?.uploadProgress[message.id]?.toFixed(2)}%</div>}
                                </div>
                            }

                            {type === 'audio' &&
                                <div className={`${session.user.id === message.User.id ? 'self-end' : 'self-start'} min-w-[180px] relative rounded-2xl p-2`}>
                                    {!message.voice && <Text color={session.user.id === message.User.id ? 'text-gray-500' : 'text-white'} size="text-xs truncate">{message.MessageFiles[0].name}</Text>}
                                    <AudioPlayer src={`${API_URL}/messages/files/${message.MessageFiles[0].id}`} dark={session.user.id !== message.User.id} />
                                </div>
                            }

                            {state.edit ?
                                <form onSubmit={handleEditMessage}>
                                    <textarea
                                        ref={textArea}
                                        style={{ width: messageRef.current?.clientWidth || 'auto', height: messageRef.current?.clientHeight || 'auto' }}
                                        className="outline-none resize-none px-5"
                                        type="text"
                                        placeholder={langs[lang]['message_placeholder']}
                                        onChange={handleTextChange}
                                        onKeyDown={handleSkipLine}
                                        rows={1}
                                        value={state.text}
                                    ></textarea>
                                </form>
                                :
                                <>
                                    {message.text.length > 0 && <p className={"break-all whitespace-pre-wrap px-5 py-2"} onDoubleClick={!pending && session.user.id === message.User.id ? handleOnDbClick : undefined}>{state.text}</p>}
                                </>
                            }
                            {!state.edit && error && <p className="flex gap-1 items-center text-red-500 pb-2 text-xs px-5"><RiErrorWarningFill /> {errorMessage}</p>}
                        </div>
                    }
                    <div className="flex flex-auto items-center gap-1 px-5 py-2">
                        <div className="flex gap-1 items-center">
                            {message.User.id === session.user.id && !state.edit && pending && <p className="text-xs"><RiTimeLine /></p>}
                            {message.User.id === session.user.id && !state.edit && !pending && !message.seen && <p className="text-xs"><RiCheckFill /></p>}
                            {message.User.id === session.user.id && !state.edit && !pending && message.seen && <p className="text-xs"><RiCheckDoubleFill /></p>}
                            {!state.edit && state.updated && <p className={`text-[10px] flex gap-1 items-center`}><RiPencilFill /></p>}
                        </div>
                        <p className="uppercase text-xs">
                            {moment(message.createdAt).format("HH:mm")}
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Message