import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const incomingCallList = createSlice({
    name: 'incomingCallList',
    initialState,
    reducers: {
        setIncomingCalls: (state, { payload: calls }) => {
            return calls
        },
        addIncomingCall: (state, { payload: newCall }) => {
            let newList = [...state]
            newList.push(newCall)
            return newList
        },
        deleteIncomingCall: (state, { payload: callId }) => {
            return state.filter(call => call.Call.id !== callId)
        },
        resetIncomingCallList: () => initialState
    }

})

export const {
    setIncomingCalls,
    addIncomingCall,
    deleteIncomingCall,
    resetIncomingCallList,
} = incomingCallList.actions

export default incomingCallList.reducer