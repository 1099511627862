import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Session from "../../../components/Session"
import { reset } from "../../../features/multimediaSlice"
import { setSessionData } from "../../../features/sessionSlice"
import { setSessions } from "../../../features/sessionsSlice"
import { deleteToken } from "../../../features/tokenSlice"
import { getSession, getSessions } from "../../../services/session"
import Loader from "../../../components/Loader"

const SessionsPage = () => {
    const [loading, setLoading] = useState(true)
    const { token, sessions } = useSelector((state) => state)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(reset())
        getSession({ token })
            .then((response) => {
                dispatch(setSessionData(response.data.payload))
                setLoading(false)
            })
            .catch(({ response }) => {
                if (response.status === 404) {
                    dispatch(deleteToken())
                }
            })
        getSessions({ token }).then((response) => {
            dispatch(setSessions(response.data.payload))
        })
    }, [token, dispatch])

    if (loading) return <Loader />
    
    return (
        <div className="flex flex-col gap-5">
            {sessions.map((session) => <Session key={session.id} session={session} />)}
        </div>
    )
}

export default SessionsPage