import { useEffect } from "react"
import DownloadManager from "../../../components/DownloadsManager"
import { useDispatch } from "react-redux"
import { reset } from "../../../features/multimediaSlice"

const DownloadsPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(reset())
    }, [dispatch])

    return <DownloadManager />
}

export default DownloadsPage