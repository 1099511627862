import { useState } from "react"
import Modal from "../../../components/Modal"
import { RiAddFill } from "react-icons/ri"
import LabelTitle from "../../../components/LabelTitle"
import Input from "../../../components/Input"
import Button from "../../../components/Button"
import { createRoom } from "../../../services/room"
import { useSelector } from "react-redux"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import langs from "../../../lang/langs"

const CreateRoom = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const [roomName, setRoomName] = useState("")
    const [requesting, setRequesting] = useState(false)

    const navigate = useNavigate()
    const { token, lang } = useSelector(state => state)

    const handleCreateRoom = (event) => {
        event.preventDefault()

        setRequesting(true)
        createRoom({ token, roomName })
            .then(res => {
                navigate(`/chat/rooms/${res.data.payload.roomMember.roomId}`)
            })
            .catch((error) => {
                console.log(error)
                toast.error(error.response.data.message)

                setRequesting(false)
            })
    }

    return (
        <div className="flex flex-col">
            <button className="flex items-center justify-center gap-2 text-gray-500 px-5 py-2 border rounded-lg" onClick={() => setModalOpen(true)}><RiAddFill /> {langs[lang]['create_room']}</button>
            <Modal modalOpen={modalOpen} set={setModalOpen}>
                <div className="bg-white flex flex-col gap-5 rounded p-5">
                    <LabelTitle>{langs[lang]['create_room']}</LabelTitle>
                    <form className="flex flex-col gap-2" onSubmit={handleCreateRoom}>
                        <Input label={langs[lang]['room_name']} type="text" onChange={({ target: { value } }) => setRoomName(value)} value={roomName} />
                        <Button spinner={requesting} disabled={requesting}>{!requesting && langs[lang]['create_room']}</Button>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default CreateRoom