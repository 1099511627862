const langs = {
    "af": {
        "name": "Naam",
        "name_example": "Tjaart",
        "last_name": "Van",
        "last_name_example": "Pretorius",
        "username": "Gebruiker",
        "username_example": "tjaart_pretorius",
        "email": "E-pos",
        "email_example": "gebruiker@domein.com",
        "password": "Wagwoord",
        "new_password": "Nuwe Wagwoord",
        "password_example": "*****************",
        "login": "Teken aan",
        "register": "Registreer",
        "verification_code": "Verifikasiekode",
        "verification_code_example": "xxx",
        "verify": "Verifieer",
        "loading": "Laai…",
        "please_wait": "Wag asseblief",
        "go_back": "Gaan terug",
        "recovery": "Herstel",
        "login_subtitle": "Voer jou besonderhede in en kry toegang tot jou rekening.",
        "forgot_password_link": "Het jy jou wagwoord vergeet? Herstel.",
        "register_account_link": "Het jy nie 'n rekening nie? Registreer!",
        "register_subtitle": "Voer jou besonderhede in en registreer jou rekening.",
        "login_link": "Het jy reeds 'n rekening? Teken aan!",
        "verification_code_instruction": "Gaan jou e-pos na en voer die kode wat gestuur is in.",
        "forgot_password_subtitle": "Voer jou e-posadres in om jou wagwoord te herwin.",
        "change_password_subtitle": "Sleutel 'n nuwe wagwoord in.",
        "change_password_code_used": "Die kode om jou wagwoord terug te stel is ongeldig of is reeds gebruik.",
        "no_chats": "Jy het nie gesprekke nie.",
        "chats": "Gesprekke",
        "groups": "Groepe",
        "rooms": "Kamers",
        "save": "Hou",
        "download": "Aflaai",
        "downloading_image": "Laai tans prent af",
        "an_error_has_ocurred": "N fout het voorgekom",
        "saving_changes": "Stoor tans veranderinge",
        "go_back_to_edit": "Redigeer weer",
        "upload_warning_modal": "Die oplaai van die wysiging sal die oorspronklike prent vervang.",
        "upload_question_modal": "Wil jy die oorspronklike behou en 'n kopie skep?",
        "create_copy_and_keep": "Skep kopie en hou",
        "upload": "Laai op",
        "upload_changes": "Laai veranderinge op",
        "changes_saved": "Veranderinge is gestoor",
        "downloading_video": "Laai tans video af",
        "video_upload_warning_modal": "Die oplaai van die wysiging sal die oorspronklike video vervang.",
        "results": "Resultate",
        "members": "Lede",
        "cancel": "Kanselleer",
        "add_user": "Voeg gebruiker by",
        "no_messages": "Geen boodskappe nie",
        "message_placeholder": "Boodskap…",
        "group_doesnt_exists": "Die groep bestaan ​​nie",
        "you_arent_group_member": "Jy is nie 'n lid van die groep nie",
        "touch_to_see_more_info": "Tik om meer inligting te sien.",
        "start_videocall": "Begin video-oproep",
        "start_call": "Begin oproep",
        "create_group": "Skep groep",
        "group_name": "Groepnaam",
        "add_members_to_create_group": "Voeg lede by om die groep te skep",
        "search": "Soek vir",
        "creating": "Skep…",
        "dont_belong_anyone_group": "Jy behoort aan geen groep nie.",
        "already_add_this_hashtag": "Jy het reeds daardie hutsmerk bygevoeg",
        "search_files_by_hashtags": "Soek lêers deur hutsmerke",
        "enter_to_add": "Voer in om by te voeg",
        "hashtags_most_used": "Mees gebruikte hashtags",
        "can_add_it_giving_click": "Jy kan dit by jou soektog voeg deur daarop te klik",
        "searching_files": "Soek tans lêers",
        "no_files_found": "Geen lêers gevind nie",
        "image": "Beeld",
        "video": "Video",
        "audio": "Oudio",
        "library": "Boekwinkel",
        "home": "Tuisblad",
        "block": "Blok",
        "unblock": "Om te ontsluit",
        "has_blocked_you": "Hy het jou geblokkeer",
        "accept": "Aanvaar",
        "decline": "Weier",
        "cancel_request": "Kanselleer versoek",
        "has_been_expulsed": "geskors is",
        "room_doesnt_exists": "Die kamer bestaan ​​nie",
        "you_arent_room_member": "Jy is nie 'n lid van die kamer nie",
        "create_room": "Skep kamer",
        "room_name": "Kamernaam",
        "no_rooms_created": "Daar is geen kamers geskep nie.",
        "dont_belong_to_anyone_room": "Jy hoort in geen vertrek nie.",
        "enter_a_username": "Skryf 'n gebruiker",
        "no_results": "Geen resultate",
        "create": "Skep",
        "no_content_uploaded": "Geen inhoud opgelaai nie",
        "new_subtag": "Nuwe subtag",
        "update_info": "Dateer inligting op",
        "updating": "Dateer tans op",
        "update": "Opdateer",
        "update_email": "Dateer e-pos op",
        "change_password": "Verander wagwoord",
        "current_password": "Huidige Sleutelwoord",
        "select": "Kies",
        "tap_to_upload_a_file": "Tik om 'n lêer op te laai",
        "edit_photo": "Redigeer foto",
        "preview": "Voorskou",
        "edit_video": "Redigeer video",
        "delete_changes": "Vee veranderinge uit",
        "uploading": "Laai tans op",
        "session_deleted_or_expired": "Sessie is uitgevee of verval",
        "edit_profile": "Wysig profiel",
        "devices": "Toestelle",
        "downloads": "Aflaaie",
        "logout": "Teken af",
        "offline": "Sonder verbinding",
        "images": "Beelde",
        "videos": "Video's",
        "audios": "Oudio's",
        "chat": "Gesels",
        "session_unauthorized": "Jou sessie is nie gemagtig nie.",
        "authorize_session": "Om toegang tot hierdie sessie te verkry, moet jy jou toestel magtig.",
        "authorize_instructions": "Gaan jou e-pos na vir die magtigingskakel of gaan na 'n aktiewe sessie en kies die drie kolletjies in die regter boonste hoek, gaan dan na \"Toestelle\" en kies \"Magtig\".",
        "refresh_page_after_authorize": "Sodra jy die magtigingsproses voltooi het, verfris die bladsy om toegang tot die sessie te verkry.",
        "check_your_inbox": "Kyk na jou eposse",
        "send_authorization_email": "Stuur magtiging-e-pos",
        "call_finished": "Oproep beëindig",
        "close": "Gesluit",
        "waiting_stream": "Wag vir uitsending",
        "request_camera_and_mic": "Ons benodig toegang tot jou kamera en mikrofoon sodat jy oproepe kan maak.",
        "call_in_progress": "Oproep aan die gang",
        "group": "Groepering",
        "videocall": "Video-oproep",
        "call": "Bel",
        "connecting": "Verbind",
        "call_cancelled": "Oproep gekanselleer",
        "calling": "Roep",
        "hangup": "Hang",
        "reject": "Weier",
        "has_sent_a_file": "het 'n lêer gestuur.",
        "keep": "Hou",
        "keep_file": "As jy die lêer geaktiveer hou, sal dit in die toepassing gestoor word. Dit sal die toeganklikheid daarvan moontlik maak sonder dat 'n internetverbinding nodig is.",
        "not_keep_file": "As jy die stoor van die lêer afskakel, sal dit uitgevee word en jy sal nie toegang daartoe kan kry sonder 'n internetverbinding nie.",
        "file_stored_instruction": "Alle gestoorde lêers sal met 'n groen rand in die algemene lêerlys verskyn. Jy kan hulle ook in die afdeling sien",
        "stored": "Geberg",
        "not_stored": "Dit word nie gestoor nie",
        "file_not_stored_instruction": "Jy sal nie toegang tot hierdie lêer kan kry sonder 'n internetverbinding nie.'",
        "device": "Toestel",
        "file_visibility": "Lêersigbaarheid",
        "visible_for_everyone": "Sigbaar vir almal",
        "shared_link": "Gedeelde skakel",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Hashtags help jou om jou lêers te vind.",
        "edit": "Wysig",
        "move": "Beweeg",
        "rename": "Hernoem",
        "visibility": "Sigbaarheid",
        "delete": "Elimineer",
        "file_warn_delete": "Is jy seker jy wil die lêer uitvee?",
        "no_subtags_availables": "Geen submerkers beskikbaar nie",
        "owner": "Skepper",
        "expulsing": "verdryf",
        "expulse": "Skors",
        "you_have_left_the_group": "Jy het die groep verlaat",
        "coming_out": "Kom uit",
        "leave_group": "Los die groep",
        "you_have_left_the_room": "Jy het die kamer verlaat.",
        "leave_room": "Verlaat die kamer",
        "message_deleted": "Geskrap boodskap",
        "has_been_error_deleting_message": "Kon nie die boodskap uitvee nie",
        "deleting": "Verwydering",
        "new_tag": "Nuwe tag",
        "photoEditor": {
            "name": "Naam",
            "save": "Voltooi",
            "saveAs": "Stoor as",
            "back": "Terug",
            "loading": "Laai…",
            "resetOperations": "Herbegin/vee alle bewerkings uit.",
            "changesLoseConfirmation": "Alle veranderinge sal verwyder word.",
            "changesLoseConfirmationHint": "Is jy seker jy wil voortgaan?",
            "cancel": "Kanselleer",
            "continue": "Aanhou",
            "undoTitle": "Ontdoen laaste bewerking",
            "redoTitle": "Herhaal laaste operasie",
            "showImageTitle": "Wys oorspronklike prent",
            "zoomInTitle": "Zoem in",
            "zoomOutTitle": "Zoem uit",
            "toggleZoomMenuTitle": "Wissel zoemkieslys",
            "adjustTab": "Pas aan",
            "finetuneTab": "Kleure",
            "filtersTab": "Filters",
            "watermarkTab": "Beelde",
            "annotateTab": "Teken",
            "resize": "Verander grootte",
            "resizeTab": "Grootte",
            "invalidImageError": "Ongeldige prent",
            "uploadImageError": "'n Fout het voorgekom tydens die laai van die prent.",
            "areNotImages": "Hulle is nie beelde nie",
            "isNotImage": "dit is nie 'n beeld nie",
            "toBeUploaded": "opgelaai te word",
            "cropTool": "Sny",
            "original": "Oorspronklik",
            "custom": "Persoonlik",
            "square": "Vierkantig",
            "landscape": "Horisontaal",
            "portrait": "Vertikaal",
            "ellipse": "Sirkel",
            "classicTv": "klassieke TV",
            "cinemascope": "Bioskoop",
            "arrowTool": "Pyltjie",
            "blurTool": "Vervaag",
            "brightnessTool": "Gloei",
            "contrastTool": "Kontras",
            "ellipseTool": "Sirkel",
            "unFlipX": "Ontdraai X",
            "flipX": "Flip X",
            "unFlipY": "Ontdraai Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "Tint",
            "saturation": "Versadiging",
            "value": "Waarde",
            "imageTool": "Beeld",
            "importing": "Invoer…",
            "addImage": "+ Voeg prent by",
            "uploadImage": "Laai prent op",
            "fromGallery": "Plakkers",
            "lineTool": "Lyn",
            "penTool": "Potlood",
            "polygonTool": "Veelhoek",
            "sides": "Kante",
            "rectangleTool": "Reghoek",
            "cornerRadius": "Rand Radius",
            "resizeWidthTitle": "Breedte in pixels",
            "resizeHeightTitle": "Pixel hoogte",
            "toggleRatioLockTitle": "Wissel Dimension Lock",
            "reset": "Begin oor",
            "resetSize": "Herstel die oorspronklike afmetings van die prent",
            "rotateTool": "Draai",
            "textTool": "Teks",
            "textSpacings": "Teksspasiëring",
            "textAlignment": "Teksbelyning",
            "fontFamily": "Font familie",
            "size": "Grootte",
            "letterSpacing": "Letterspasiëring",
            "lineHeight": "Lyn hoogte",
            "warmthTool": "Hitte",
            "addWatermark": "+ Voeg prent by",
            "addWatermarkTitle": "Kies die tipe watermerk",
            "uploadWatermark": "Kies prent",
            "addWatermarkAsText": "Voeg teks by",
            "padding": "Gevul",
            "shadow": "Skaduwee",
            "horizontal": "Horisontaal",
            "vertical": "Vertikaal",
            "blur": "Vervaag",
            "opacity": "Ondeursigtigheid",
            "position": "Posisie",
            "stroke": "Dikte",
            "saveAsModalLabel": "Besonderhede",
            "extension": "Uitbreiding",
            "nameIsRequired": "Die naam word vereis.",
            "quality": "Gehalte",
            "imageDimensionsHoverTitle": "Gestoorde prentafmetings (breedte x hoogte)",
            "cropSizeLowerThanResizedWarning": "Neem asseblief kennis dat die geselekteerde gewasoppervlakte kleiner is as die grootteverandering wat toegepas is, wat 'n afname in kwaliteit kan veroorsaak.",
            "actualSize": "Huidige grootte (100%)",
            "fitSize": "Pas dimensie aan",
            "addImageTitle": "Kies 'n prent om by te voeg…"
        },
        "changing": "Verander",
        "profile_picture_changed": "Profielfoto verander",
        "loading_profile_picture": "Laai tans profielfoto",
        "change": "Verander",
        "session": "Sessie",
        "system_operative": "OS",
        "navigator": "Blaaier",
        "authorize": "Magtig",
        "deauthorize": "Verwerp",
        "keep_tag_content": "Wil jy die inhoud van die merker bewaar?",
        "move_content_to": "Skuif inhoud na",
        "move_to": "Skuif na",
        "chat_request": "Kletsversoek",
        "accept_request": "Aanvaar versoek",
        "go_to_chat": "Gaan na gesels",
        "applying": "Aansoek doen",
        "time": "Tyd",
        "adjust": "Pas aan",
        "rotate": "Draai",
        "start": "Begin",
        "end": "Einde",
        "lang_name": "Afrikaans"
    },
    "sq": {
        "name": "Emri",
        "name_example": "Arben",
        "last_name": "Mbiemri",
        "last_name_example": "Hoxha",
        "username": "Përdoruesi",
        "username_example": "arben_hoxha",
        "email": "Email",
        "email_example": "përdoruesi@domain.com",
        "password": "Fjalëkalimi",
        "new_password": "Fjalëkalim i ri",
        "password_example": "*****************",
        "login": "Identifikohu",
        "register": "Regjistrohu",
        "verification_code": "Kodi i Verifikimit",
        "verification_code_example": "xxx",
        "verify": "Verifiko",
        "loading": "Ngarkimi…",
        "please_wait": "Te lutem prit",
        "go_back": "Kthehem",
        "recovery": "Shërohen",
        "login_subtitle": "Futni të dhënat tuaja dhe hyni në llogarinë tuaj.",
        "forgot_password_link": "Keni harruar fjalëkalimin tuaj? Shërohen.",
        "register_account_link": "Nuk keni një llogari? Regjistrohu!",
        "register_subtitle": "Futni të dhënat tuaja dhe regjistroni llogarinë tuaj.",
        "login_link": "A keni tashmë një llogari? Identifikohu!",
        "verification_code_instruction": "Kontrolloni emailin tuaj dhe shkruani kodin e dërguar.",
        "forgot_password_subtitle": "Shkruani emailin tuaj për të rikuperuar fjalëkalimin tuaj.",
        "change_password_subtitle": "Shtypni nje fjalekalim te ri.",
        "change_password_code_used": "Kodi juaj i rikuperimit të fjalëkalimit është i pavlefshëm ose është përdorur tashmë.",
        "no_chats": "Ju nuk keni biseda.",
        "chats": "Bisedat",
        "groups": "Grupet",
        "rooms": "Dhomat",
        "save": "Ruaj",
        "download": "Shkarko",
        "downloading_image": "Po shkarkon imazhin",
        "an_error_has_ocurred": "Një gabim ka ndodhur",
        "saving_changes": "Ruajtja e ndryshimeve",
        "go_back_to_edit": "Redakto përsëri",
        "upload_warning_modal": "Ngarkimi i modifikimit do të zëvendësojë imazhin origjinal.",
        "upload_question_modal": "Dëshironi të ruani origjinalin dhe të bëni një kopje?",
        "create_copy_and_keep": "Krijo kopje dhe mbaj",
        "upload": "Ngarkoni",
        "upload_changes": "Ngarko ndryshime",
        "changes_saved": "Ndryshimet u ruajtën",
        "downloading_video": "Po shkarkohet video",
        "video_upload_warning_modal": "Ngarkimi i modifikimit do të zëvendësojë videon origjinale.",
        "results": "Rezultatet",
        "members": "Anëtarët",
        "cancel": "Anulo",
        "add_user": "Shto përdorues",
        "no_messages": "Asnjë mesazh",
        "message_placeholder": "Mesazh…",
        "group_doesnt_exists": "Grupi nuk ekziston",
        "you_arent_group_member": "Ju nuk jeni anëtar i grupit",
        "touch_to_see_more_info": "Prekni për të parë më shumë informacion.",
        "start_videocall": "Filloni një videotelefonatë",
        "start_call": "Filloni thirrjen",
        "create_group": "Krijo grup",
        "group_name": "Emri i grupit",
        "add_members_to_create_group": "Shtoni anëtarë për të krijuar grupin",
        "search": "Kërkoni",
        "creating": "Duke krijuar…",
        "dont_belong_anyone_group": "Ju nuk i përkisni asnjë grupi.",
        "already_add_this_hashtag": "Tashmë e keni shtuar atë hashtag",
        "search_files_by_hashtags": "Kërkoni skedarë sipas hashtags",
        "enter_to_add": "Hyni për të shtuar",
        "hashtags_most_used": "Hashtags më të përdorur",
        "can_add_it_giving_click": "Ju mund t'i shtoni ato në kërkimin tuaj duke klikuar mbi to.",
        "searching_files": "Kërkimi i skedarëve",
        "no_files_found": "Nuk u gjet asnjë skedar",
        "image": "Imazhi",
        "video": "Video",
        "audio": "Audio",
        "library": "Librari",
        "home": "Faqja kryesore",
        "block": "Blloko",
        "unblock": "Për të zhbllokuar",
        "has_blocked_you": "Ai ju ka bllokuar",
        "accept": "Pranoje",
        "decline": "Rënia",
        "cancel_request": "Anulo kerkesen",
        "has_been_expulsed": "është dëbuar",
        "room_doesnt_exists": "Dhoma nuk ekziston",
        "you_arent_room_member": "Ju nuk jeni anëtar i dhomës",
        "create_room": "Krijo dhomë",
        "room_name": "Emri i dhomës",
        "no_rooms_created": "Nuk ka dhoma të krijuara.",
        "dont_belong_to_anyone_room": "Ju nuk bëni pjesë në asnjë dhomë.",
        "enter_a_username": "Shkruani një përdorues",
        "no_results": "Nuk ka rezultate",
        "create": "Krijo",
        "no_content_uploaded": "Asnjë përmbajtje nuk u ngarkua",
        "new_subtag": "Nën-etiketë e re",
        "update_info": "Përditëso informacionin",
        "updating": "Po përditësohet",
        "update": "Përditëso",
        "update_email": "Përditëso emailin",
        "change_password": "Ndrysho fjalekalimin",
        "current_password": "Fjalëkalimi aktual",
        "select": "Zgjidhni",
        "tap_to_upload_a_file": "Prekni për të ngarkuar një skedar",
        "edit_photo": "Redakto foton",
        "preview": "Parapamje",
        "edit_video": "Redakto videon",
        "delete_changes": "Fshi ndryshimet",
        "uploading": "Ngarkimi",
        "session_deleted_or_expired": "Sesioni u fshi ose skadoi",
        "edit_profile": "Redakto profilin",
        "devices": "Pajisjet",
        "downloads": "Shkarkime",
        "logout": "Dilni",
        "offline": "Jashtë linje",
        "images": "Imazhet",
        "videos": "Videot",
        "audios": "Audio",
        "chat": "Bisedë",
        "session_unauthorized": "Sesioni juaj nuk është i autorizuar.",
        "authorize_session": "Për të hyrë në këtë sesion, duhet të autorizoni pajisjen tuaj.",
        "authorize_instructions": "Kontrolloni emailin tuaj për lidhjen e autorizimit ose shkoni në një seancë aktive dhe zgjidhni tre pikat në këndin e sipërm djathtas, më pas shkoni te \"Pajisjet\" dhe zgjidhni \"Autorize\".",
        "refresh_page_after_authorize": "Pas përfundimit të procesit të autorizimit, rifreskoni faqen për t'u identifikuar.",
        "check_your_inbox": "Kontrolloni emailin tuaj",
        "send_authorization_email": "Dërgo email autorizimi",
        "call_finished": "Telefonata përfundoi",
        "close": "Mbylle",
        "waiting_stream": "Në pritje të transmetimit",
        "request_camera_and_mic": "Na duhet qasje te kamera dhe mikrofoni yt për të kryer telefonata.",
        "call_in_progress": "Telefonata në vazhdim",
        "group": "Grupimi",
        "videocall": "Video thirrje",
        "call": "Thirrni",
        "connecting": "Duke u lidhur",
        "call_cancelled": "Telefonata u anulua",
        "calling": "Duke thirrur",
        "hangup": "Var",
        "reject": "Rënia",
        "has_sent_a_file": "ka dërguar një dosje.",
        "keep": "Mbaj",
        "keep_file": "Nëse aktivizoni ruajtjen e skedarit, ai do të ruhet në aplikacion. Kjo do të lejojë aksesin pa pasur nevojë për një lidhje interneti.",
        "not_keep_file": "Nëse çaktivizon mbajtjen e skedarit, ai do të fshihet dhe nuk do të mund t'i qaseni pa një lidhje interneti.",
        "file_stored_instruction": "Të gjithë skedarët e ruajtur do të shfaqen me një kufi të gjelbër në listën e skedarëve të përbashkët. Ju gjithashtu mund t'i shihni ato në seksion",
        "stored": "Të ruajtura",
        "not_stored": "Nuk ruhet",
        "file_not_stored_instruction": "Nuk do të mund të hyni në këtë skedar pa një lidhje interneti.",
        "device": "Pajisja",
        "file_visibility": "Dukshmëria e skedarit",
        "visible_for_everyone": "E dukshme për të gjithë",
        "shared_link": "Lidhje e përbashkët",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Hashtags ju ndihmojnë të gjeni skedarët tuaj.",
        "edit": "Redakto",
        "move": "Lëvizni",
        "rename": "Riemërto",
        "visibility": "Dukshmëria",
        "delete": "Eliminoni",
        "file_warn_delete": "Jeni i sigurt që dëshironi të fshini skedarin?",
        "no_subtags_availables": "Nuk ofrohen nën-etiketa",
        "owner": "Krijuesi",
        "expulsing": "duke dëbuar",
        "expulse": "Përzënë",
        "you_have_left_the_group": "Ju jeni larguar nga grupi",
        "coming_out": "Duke dalë",
        "leave_group": "Largohu nga grupi",
        "you_have_left_the_room": "Ju keni dalë nga dhoma.",
        "leave_room": "Largohu nga dhoma",
        "message_deleted": "Mesazh i fshirë",
        "has_been_error_deleting_message": "Ndodhi një gabim gjatë fshirjes së mesazhit",
        "deleting": "Po fshihet",
        "new_tag": "Etiketë e re",
        "photoEditor": {
            "name": "Emri",
            "save": "Ruaj",
            "saveAs": "Ruaj si",
            "back": "Mbrapa",
            "loading": "Po ngarkohet…",
            "resetOperations": "Rinis/Fshi të gjitha operacionet.",
            "changesLoseConfirmation": "Të gjitha ndryshimet do të hiqen.",
            "changesLoseConfirmationHint": "Je i sigurt që dëshiron të vazhdosh?",
            "cancel": "Anulo",
            "continue": "Vazhdoni",
            "undoTitle": "Zhbër operacionin e fundit",
            "redoTitle": "Ribëni operacionin e fundit",
            "showImageTitle": "Shfaq imazhin origjinal",
            "zoomInTitle": "Zmadhoni",
            "zoomOutTitle": "Largohuni",
            "toggleZoomMenuTitle": "Ndrysho menynë e zmadhimit",
            "adjustTab": "Rregullo",
            "finetuneTab": "Ngjyrat",
            "filtersTab": "Filtrat",
            "watermarkTab": "Imazhet",
            "annotateTab": "Vizatoni",
            "resize": "Ndryshimi i madhësisë",
            "resizeTab": "Ndryshimi i madhësisë",
            "invalidImageError": "Imazhi i pavlefshëm",
            "uploadImageError": "Ndodhi një gabim gjatë ngarkimit të imazhit.",
            "areNotImages": "Ato nuk janë imazhe",
            "isNotImage": "nuk eshte imazh",
            "toBeUploaded": "për t'u ngarkuar",
            "cropTool": "Prerje",
            "original": "Origjinale",
            "custom": "Personalizuar",
            "square": "Sheshi",
            "landscape": "Horizontale",
            "portrait": "Vertikale",
            "ellipse": "Rretho",
            "classicTv": "tv klasik",
            "cinemascope": "Kinemaskopi",
            "arrowTool": "Shigjeta",
            "blurTool": "E turbullt",
            "brightnessTool": "Shkëlqim",
            "contrastTool": "Kontrasti",
            "ellipseTool": "Rretho",
            "unFlipX": "Hap X",
            "flipX": "Flip X",
            "unFlipY": "Hape Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "Hue",
            "saturation": "Ngopja",
            "value": "Vlera",
            "imageTool": "Imazhi",
            "importing": "Importimi…",
            "addImage": "+ Shto imazh",
            "uploadImage": "Ngarko imazh",
            "fromGallery": "Ngjitëse",
            "lineTool": "Linjë",
            "penTool": "Laps",
            "polygonTool": "Shumëkëndëshi",
            "sides": "Anët",
            "rectangleTool": "Drejtkëndësh",
            "cornerRadius": "Rrezja e skajit",
            "resizeWidthTitle": "Gjerësia në pixel",
            "resizeHeightTitle": "Lartësia e pikselit",
            "toggleRatioLockTitle": "Ndrysho bllokimin e dimensionit",
            "reset": "Rivë",
            "resetSize": "Rikthe në dimensionet origjinale të imazhit.",
            "rotateTool": "Rrotullimi",
            "textTool": "Teksti",
            "textSpacings": "Hapësira e tekstit",
            "textAlignment": "Rreshtimi i tekstit",
            "fontFamily": "Familja e shkronjave",
            "size": "Përmasa",
            "letterSpacing": "Hapësira e shkronjave",
            "lineHeight": "Vija e gjatesise",
            "warmthTool": "Nxehtësia",
            "addWatermark": "+ Shto imazh",
            "addWatermarkTitle": "Zgjidhni llojin e filigranit",
            "uploadWatermark": "Zgjidhni imazhin",
            "addWatermarkAsText": "Shto tekst",
            "padding": "Mbushje",
            "shadow": "Hije",
            "horizontal": "Horizontale",
            "vertical": "Vertikale",
            "blur": "E turbullt",
            "opacity": "Opaciteti",
            "position": "Pozicioni",
            "stroke": "Trashësia",
            "saveAsModalLabel": "Detajet",
            "extension": "Zgjerim",
            "nameIsRequired": "Kërkohet emri.",
            "quality": "Cilësia",
            "imageDimensionsHoverTitle": "Dimensionet e imazhit të ruajtura (gjerësia x lartësi)",
            "cropSizeLowerThanResizedWarning": "Ju lutemi, vini re se zona e përzgjedhur e korrjes është më e vogël se ndryshimi i madhësisë së aplikuar, gjë që mund të shkaktojë ulje të cilësisë.",
            "actualSize": "Madhësia aktuale (100%)",
            "fitSize": "Përshtat dimensionin",
            "addImageTitle": "Zgjidhni një imazh për të shtuar…"
        },
        "changing": "Duke ndryshuar",
        "profile_picture_changed": "Fotografia e profilit ndryshoi",
        "loading_profile_picture": "Po ngarkohet fotografia e profilit",
        "change": "Ndryshimi",
        "session": "Sesioni",
        "system_operative": "OS",
        "navigator": "Shfletuesi",
        "authorize": "Autorizoj",
        "deauthorize": "Refuzoj",
        "keep_tag_content": "Dëshironi të ruani përmbajtjen e etiketës?",
        "move_content_to": "Zhvendosni përmbajtjen në",
        "move_to": "Leviz ne",
        "chat_request": "Kërkesë për bisedë",
        "accept_request": "Prano kërkesën",
        "go_to_chat": "Shkoni në bisedë",
        "applying": "Duke aplikuar",
        "time": "Koha",
        "adjust": "Rregullo",
        "rotate": "Rrotulloje",
        "start": "Filloni",
        "end": "Fund",
        "lang_name": "Albanian"
    },
    "am": {
        "name": "ስም",
        "name_example": "Seble",
        "last_name": "የአያት ስም",
        "last_name_example": "Tesfaye",
        "username": "ተጠቃሚ",
        "username_example": "seble_tesfaye",
        "email": "ኢሜይል",
        "email_example": "ተጠቃሚ@ጎራ.com",
        "password": "የይለፍ ቃል",
        "new_password": "አዲስ የይለፍ ቃል",
        "password_example": "*****************",
        "login": "ግባ",
        "register": "ይመዝገቡ",
        "verification_code": "የማረጋገጫ የሚስጥር ቁጥር",
        "verification_code_example": "xxx",
        "verify": "አረጋግጥ",
        "loading": "በመሙላት ላይ…",
        "please_wait": "ቆይ በናተህ",
        "go_back": "ተመለስ",
        "recovery": "ማገገም",
        "login_subtitle": "ዝርዝሮችዎን ያስገቡ እና መለያዎን ይድረሱ።",
        "forgot_password_link": "የይለፍ ቃልህን ረሳኸው? ማገገም",
        "register_account_link": "መለያ የለህም? ይመዝገቡ!",
        "register_subtitle": "ዝርዝሮችዎን ያስገቡ እና መለያዎን ያስመዝግቡ።",
        "login_link": "አስቀድሞ መለያ አለህ? ለመድረስ!",
        "verification_code_instruction": "ኢሜልዎን ያረጋግጡ እና የተላከውን ኮድ ያስገቡ።",
        "forgot_password_subtitle": "የይለፍ ቃልዎን ለማግኘት ኢሜልዎን ያስገቡ።",
        "change_password_subtitle": "አዲስ የይለፍ ቃል ያስገቡ።",
        "change_password_code_used": "የይለፍ ቃልዎ መልሶ ማግኛ ኮድ ልክ ያልሆነ ነው ወይም አስቀድሞ ጥቅም ላይ ውሏል።",
        "no_chats": "ውይይቶች የሉዎትም።",
        "chats": "ውይይቶች",
        "groups": "ቡድኖች",
        "rooms": "ክፍሎች",
        "save": "አቆይ",
        "download": "መፍሰስ",
        "downloading_image": "ምስል በማውረድ ላይ",
        "an_error_has_ocurred": "ስህተት ተፈጥሯል",
        "saving_changes": "ለውጦችን በማስቀመጥ ላይ",
        "go_back_to_edit": "እንደገና አርትዕ",
        "upload_warning_modal": "አርትዖቱን መስቀል ዋናውን ምስል ይተካል።",
        "upload_question_modal": "ዋናውን ማስቀመጥ እና ቅጂ መስራት ይፈልጋሉ?",
        "create_copy_and_keep": "ቅጂ ይስሩ እና ያስቀምጡ",
        "upload": "ስቀል",
        "upload_changes": "ለውጦችን ይስቀሉ",
        "changes_saved": "ለውጦች ተቀምጠዋል",
        "downloading_video": "ቪዲዮ በማውረድ ላይ",
        "video_upload_warning_modal": "አርትዖቱን መስቀል ዋናውን ቪዲዮ ይተካል።",
        "results": "ውጤቶች",
        "members": "አባላት",
        "cancel": "ሰርዝ",
        "add_user": "ተጠቃሚ አክል",
        "no_messages": "ምንም መልዕክቶች የሉም",
        "message_placeholder": "መልእክት…",
        "group_doesnt_exists": "ቡድኑ የለም።",
        "you_arent_group_member": "እርስዎ የቡድኑ አባል አይደሉም",
        "touch_to_see_more_info": "ተጨማሪ መረጃ ለማየት መታ ያድርጉ።",
        "start_videocall": "የቪዲዮ ጥሪ ጀምር",
        "start_call": "ጥሪ ጀምር",
        "create_group": "ቡድን ይፍጠሩ",
        "group_name": "የቡድን ስም",
        "add_members_to_create_group": "ቡድኑን ለመፍጠር አባላትን ያክሉ",
        "search": "መፈለግ",
        "creating": "መፍጠር…",
        "dont_belong_anyone_group": "የማንኛውም ቡድን አባል አይደሉም።",
        "already_add_this_hashtag": "ያንን ሃሽታግ አስቀድመህ አክለዋል።",
        "search_files_by_hashtags": "ፋይሎችን በሃሽታግ ይፈልጉ",
        "enter_to_add": "ለመጨመር አስገባ",
        "hashtags_most_used": "በብዛት ጥቅም ላይ የዋሉ ሃሽታጎች",
        "can_add_it_giving_click": "እነሱን ጠቅ በማድረግ ወደ ፍለጋዎ ማከል ይችላሉ።",
        "searching_files": "ፋይሎችን መፈለግ",
        "no_files_found": "ምንም ፋይሎች አልተገኙም።",
        "image": "ምስል",
        "video": "ቪዲዮ",
        "audio": "ኦዲዮ",
        "library": "የመጽሐፍ መሸጫ",
        "home": "መነሻ ገጽ",
        "block": "አግድ",
        "unblock": "ለመክፈት",
        "has_blocked_you": "ብሎክ አድርጎሃል",
        "accept": "ተቀበል",
        "decline": "አትቀበል",
        "cancel_request": "ጥያቄ ሰርዝ",
        "has_been_expulsed": "ተባረረ",
        "room_doesnt_exists": "ክፍሉ የለም።",
        "you_arent_room_member": "እርስዎ የክፍሉ አባል አይደሉም",
        "create_room": "ክፍል ይፍጠሩ",
        "room_name": "የክፍል ስም",
        "no_rooms_created": "ምንም የተፈጠሩ ክፍሎች የሉም።",
        "dont_belong_to_anyone_room": "በማንኛውም ክፍል ውስጥ አይደሉም።",
        "enter_a_username": "ተጠቃሚ ጻፍ",
        "no_results": "ምንም ውጤት የለም።",
        "create": "ፍጠር",
        "no_content_uploaded": "ምንም ይዘት አልተጫነም።",
        "new_subtag": "አዲስ ንዑስ መለያ",
        "update_info": "መረጃን አዘምን",
        "updating": "በማዘመን ላይ",
        "update": "አዘምን",
        "update_email": "ኢሜል ያዘምኑ",
        "change_password": "የሚስጥር ቁልፍ ይቀይሩ",
        "current_password": "የአሁኑ ሚስጥራዊ ማለፊያ ቁልፍ",
        "select": "ይምረጡ",
        "tap_to_upload_a_file": "ፋይል ለመስቀል ይንኩ።",
        "edit_photo": "ፎቶ አርትዕ",
        "preview": "ቅድመ እይታ",
        "edit_video": "ቪዲዮን ያርትዑ",
        "delete_changes": "ለውጦችን ሰርዝ",
        "uploading": "ይወጡ",
        "session_deleted_or_expired": "ክፍለ ጊዜ ተሰርዟል ወይም ጊዜው አልፎበታል።",
        "edit_profile": "መገለጫ አርትዕ",
        "devices": "መሳሪያዎች",
        "downloads": "ውርዶች",
        "logout": "ውጣ",
        "offline": "ከመስመር ውጭ",
        "images": "ምስሎች",
        "videos": "ቪዲዮዎች",
        "audios": "ኦዲዮዎች",
        "chat": "ተወያይ",
        "session_unauthorized": "ክፍለ ጊዜህ አልተፈቀደለትም።",
        "authorize_session": "መሣሪያዎ ይህን ክፍለ ጊዜ እንዲደርስበት መፍቀድ አለብዎት።",
        "authorize_instructions": "የፈቃድ ማገናኛውን ለማግኘት ኢሜልዎን ያረጋግጡ ወይም ወደ ንቁ ክፍለ ጊዜ ይሂዱ እና በላይኛው ቀኝ ጥግ ላይ ያሉትን ሶስት ነጥቦችን ይምረጡ እና ከዚያ ወደ \"መሳሪያዎች\" ይሂዱ እና \"ፍቃድ\" ን ይምረጡ።",
        "refresh_page_after_authorize": "የፈቃድ ሂደቱን እንደጨረሱ፣ ክፍለ-ጊዜውን ለመድረስ ገጹን ያድሱ።",
        "check_your_inbox": "ኢሜልዎን ያረጋግጡ",
        "send_authorization_email": "የፈቃድ ኢሜይል ላክ",
        "call_finished": "ጥሪው አልቋል",
        "close": "ገጠመ",
        "waiting_stream": "ስርጭትን በመጠባበቅ ላይ",
        "request_camera_and_mic": "ጥሪ ማድረግ እንድትችል የካሜራህን እና ማይክሮፎንህን መድረስ እንፈልጋለን።",
        "call_in_progress": "ጥሪ በሂደት ላይ",
        "group": "ክላስተር",
        "videocall": "የምስል ጥሪ",
        "call": "ይደውሉ",
        "connecting": "በማገናኘት ላይ",
        "call_cancelled": "ጥሪ ተሰርዟል።",
        "calling": "በመደወል ላይ",
        "hangup": "አንጠልጥለው",
        "reject": "አትቀበል",
        "has_sent_a_file": "ፋይል ልኳል።",
        "keep": "አቆይ",
        "keep_file": "የፋይል ማቆየትን ካነቁ በመተግበሪያው ውስጥ ይቀመጣል። ይህ የበይነመረብ ግንኙነት ሳያስፈልግ መዳረሻን ይፈቅዳል።",
        "not_keep_file": "የፋይል ማቆየትን ካጠፉት ይሰረዛሉ እና ያለበይነመረብ ግንኙነት ሊደርሱባቸው አይችሉም።",
        "file_stored_instruction": "ሁሉም የተከማቹ ፋይሎች በጋራ የፋይል ዝርዝር ውስጥ ከአረንጓዴ ድንበር ጋር ይታያሉ። እንዲሁም በክፍሉ ውስጥ ሊያዩዋቸው ይችላሉ",
        "stored": "ተከማችቷል።",
        "not_stored": "አልተቀመጠም",
        "file_not_stored_instruction": "ይህን ፋይል ያለበይነመረብ ግንኙነት መድረስ አይችሉም።",
        "device": "መሳሪያ",
        "file_visibility": "የፋይል ታይነት",
        "visible_for_everyone": "ለሁሉም የሚታይ",
        "shared_link": "የተጋራ አገናኝ",
        "hashtags": "ሃሽታጎች",
        "hashtags_helps_to_search_your_file": "ሃሽታጎች የእርስዎን ፋይሎች እንዲያገኙ ያግዝዎታል።",
        "edit": "አርትዕ",
        "move": "አንቀሳቅስ",
        "rename": "እንደገና ይሰይሙ",
        "visibility": "ታይነት",
        "delete": "ማስወገድ",
        "file_warn_delete": "እርግጠኛ ነዎት ፋይሉን መሰረዝ ይፈልጋሉ?",
        "no_subtags_availables": "ምንም ንዑስ መለያዎች የሉም",
        "owner": "ፈጣሪ",
        "expulsing": "ማባረር",
        "expulse": "ማባረር",
        "you_have_left_the_group": "ቡድኑን ለቀው ወጥተዋል።",
        "coming_out": "እየወጣ ነው።",
        "leave_group": "ቡድኑን ለቀው ይውጡ",
        "you_have_left_the_room": "ክፍሉን ለቀው ወጥተዋል።",
        "leave_room": "ክፍሉን ለቀው ይውጡ",
        "message_deleted": "መልእክት ተሰርዟል።",
        "has_been_error_deleting_message": "መልእክቱን በመሰረዝ ላይ ስህተት አጋጥሟል።",
        "deleting": "በማስወገድ ላይ",
        "new_tag": "አዲስ መለያ",
        "photoEditor": {
            "name": "ስም",
            "save": "ጨርስ",
            "saveAs": "አስቀምጥ እንደ",
            "back": "ተመለስ",
            "loading": "በመሙላት ላይ…",
            "resetOperations": "ሁሉንም ስራዎች እንደገና ያስጀምሩ/ሰርዝ።",
            "changesLoseConfirmation": "ሁሉም ለውጦች ይወገዳሉ.",
            "changesLoseConfirmationHint": "እርግጠኛ ነህ መቀጠል ትፈልጋለህ?",
            "cancel": "ሰርዝ",
            "continue": "ቀጥል",
            "undoTitle": "የመጨረሻውን ቀዶ ጥገና ቀልብስ",
            "redoTitle": "የመጨረሻውን ቀዶ ጥገና ድገም",
            "showImageTitle": "የመጀመሪያውን ምስል አሳይ",
            "zoomInTitle": "አቅርብ",
            "zoomOutTitle": "አሳንስ",
            "toggleZoomMenuTitle": "የማጉላት ሜኑ ቀያይር",
            "adjustTab": "አስተካክል።",
            "finetuneTab": "ቀለሞች",
            "filtersTab": "ማጣሪያዎች",
            "watermarkTab": "ምስሎች",
            "annotateTab": "ይሳሉ",
            "resize": "መጠን ቀይር",
            "resizeTab": "መጠን",
            "invalidImageError": "ልክ ያልሆነ ምስል",
            "uploadImageError": "ምስሉን በመጫን ጊዜ ስህተት ተፈጥሯል።",
            "areNotImages": "ምስሎች አይደሉም",
            "isNotImage": "ምስል አይደለም",
            "toBeUploaded": "የሚሰቀል",
            "cropTool": "ቁረጥ",
            "original": "ኦሪጅናል",
            "custom": "ለግል የተበጀ",
            "square": "ካሬ",
            "landscape": "አግድም",
            "portrait": "አቀባዊ",
            "ellipse": "ክብ",
            "classicTv": "ክላሲክ ቲቪ",
            "cinemascope": "ሲኒማስኮፕ",
            "arrowTool": "ቀስት",
            "blurTool": "ብዥታ",
            "brightnessTool": "ፍካት",
            "contrastTool": "ንፅፅር",
            "ellipseTool": "ክብ",
            "unFlipX": "X ንቀል",
            "flipX": "X ገልብጥ",
            "unFlipY": "Y ንቀል",
            "flipY": "Y ገልብጥ",
            "hsvTool": "MSV",
            "hue": "ሁ",
            "saturation": "ሙሌት",
            "value": "ዋጋ",
            "imageTool": "ምስል",
            "importing": "በማስመጣት ላይ…",
            "addImage": "+ ምስል ያክሉ",
            "uploadImage": "ምስል ስቀል",
            "fromGallery": "ተለጣፊዎች",
            "lineTool": "መስመር",
            "penTool": "እርሳስ",
            "polygonTool": "ፖሊጎን",
            "sides": "ጎኖች",
            "rectangleTool": "አራት ማዕዘን",
            "cornerRadius": "የጠርዝ ራዲየስ",
            "resizeWidthTitle": "ስፋት በፒክሰል",
            "resizeHeightTitle": "የፒክሰል ቁመት",
            "toggleRatioLockTitle": "የልኬት መቆለፊያን ቀያይር",
            "reset": "ዳግም አስጀምር",
            "resetSize": "የምስሉን የመጀመሪያ ልኬቶች ወደነበሩበት ይመልሱ",
            "rotateTool": "ስፒን",
            "textTool": "ጽሑፍ",
            "textSpacings": "የጽሑፍ ክፍተት",
            "textAlignment": "የጽሑፍ አሰላለፍ",
            "fontFamily": "የቅርጸ-ቁምፊ ቤተሰብ",
            "size": "መጠን",
            "letterSpacing": "የደብዳቤ ክፍተት",
            "lineHeight": "የመስመር ቁመት",
            "warmthTool": "ሙቀት",
            "addWatermark": "+ ምስል ያክሉ",
            "addWatermarkTitle": "የውሃ ምልክት ዓይነት ይምረጡ",
            "uploadWatermark": "ምስል ይምረጡ",
            "addWatermarkAsText": "ጽሑፍ ጨምር",
            "padding": "የታሸገ",
            "shadow": "ጥላ",
            "horizontal": "አግድም",
            "vertical": "አቀባዊ",
            "blur": "ብዥታ",
            "opacity": "ግልጽነት",
            "position": "አቀማመጥ",
            "stroke": "ውፍረት",
            "saveAsModalLabel": "ዝርዝሮች",
            "extension": "ቅጥያ",
            "nameIsRequired": "ስሙ ያስፈልጋል።",
            "quality": "ጥራት",
            "imageDimensionsHoverTitle": "የተቀመጡ የምስል ልኬቶች (ስፋት x ቁመት)",
            "cropSizeLowerThanResizedWarning": "እባክዎን የተመረጠው የሰብል ቦታ ከተተገበረው መጠን ያነሰ ነው, ይህም የጥራት መቀነስ ሊያስከትል ይችላል.",
            "actualSize": "የአሁኑ መጠን (100%)",
            "fitSize": "ልኬትን አስማሚ",
            "addImageTitle": "ለማከል ምስል ይምረጡ…"
        },
        "changing": "መቀየር",
        "profile_picture_changed": "የመገለጫ ፎቶ ተቀይሯል።",
        "loading_profile_picture": "የመገለጫ ፎቶን በመጫን ላይ",
        "change": "ለውጥ",
        "session": "ክፍለ ጊዜ",
        "system_operative": "ስርዓተ ክወና",
        "navigator": "አሳሽ",
        "authorize": "ፍቀድ",
        "deauthorize": "ውድቅ አድርግ",
        "keep_tag_content": "የመለያውን ይዘት ማቆየት ይፈልጋሉ?",
        "move_content_to": "ይዘቱን ወደ ውሰድ",
        "move_to": "አንቀሳቅስ ወደ",
        "chat_request": "የውይይት ጥያቄ",
        "accept_request": "ጥያቄ ተቀበል",
        "go_to_chat": "ወደ ውይይት ሂድ",
        "applying": "በማመልከት ላይ",
        "time": "ጊዜ",
        "adjust": "አስተካክል።",
        "rotate": "ስፒን",
        "start": "ጀምር",
        "end": "የመጨረሻ",
        "lang_name": "Amharic"
    },
    "ar": {
        "name": "اسم",
        "name_example": "Omar",
        "last_name": "اسم العائلة",
        "last_name_example": "Abadi",
        "username": "مستخدم",
        "username_example": "omar_abadi",
        "email": "بريد الكتروني",
        "email_example": "مستخدم@اِختِصاص.com",
        "password": "كلمة المرور",
        "new_password": "كلمة المرور الجديدة",
        "password_example": "*****************",
        "login": "تسجيل الدخول",
        "register": "يسجل",
        "verification_code": "رمز التحقق",
        "verification_code_example": "xxx",
        "verify": "يؤكد",
        "loading": "الشحن…",
        "please_wait": "انتظر من فضلك",
        "go_back": "يعود",
        "recovery": "استعادة",
        "login_subtitle": "أدخل التفاصيل الخاصة بك والوصول إلى حسابك.",
        "forgot_password_link": "هل نسيت كلمة المرور الخاصة بك؟ استعادة.",
        "register_account_link": "لا يوجد لديك حساب؟ اشتراك!",
        "register_subtitle": "أدخل التفاصيل الخاصة بك وسجل حسابك.",
        "login_link": "هل لديك حساب بالفعل؟ تسجيل الدخول!",
        "verification_code_instruction": "تحقق من بريدك الإلكتروني وأدخل الرمز المرسل.",
        "forgot_password_subtitle": "أدخل بريدك الإلكتروني لاستعادة كلمة المرور الخاصة بك.",
        "change_password_subtitle": "أدخل كلمة مرور جديدة.",
        "change_password_code_used": "رمز استعادة كلمة المرور غير صالح أو تم استخدامه بالفعل.",
        "no_chats": "ليس لديك محادثات.",
        "chats": "المحادثات",
        "groups": "مجموعات",
        "rooms": "غرف",
        "save": "يحفظ",
        "download": "تسريح",
        "downloading_image": "جارٍ تنزيل الصورة",
        "an_error_has_ocurred": "حدث خطأ",
        "saving_changes": "حفظ التغييرات",
        "go_back_to_edit": "تحرير مرة أخرى",
        "upload_warning_modal": "سيؤدي تحميل التعديل إلى استبدال الصورة الأصلية.",
        "upload_question_modal": "هل تريد الاحتفاظ بالأصل وعمل نسخة؟",
        "create_copy_and_keep": "إنشاء نسخة والاحتفاظ بها",
        "upload": "رفع",
        "upload_changes": "تحميل التغييرات",
        "changes_saved": "تم حفظ التغييرات",
        "downloading_video": "جارٍ تنزيل الفيديو",
        "video_upload_warning_modal": "سيؤدي تحميل التعديل إلى استبدال الفيديو الأصلي.",
        "results": "نتائج",
        "members": "أعضاء",
        "cancel": "يلغي",
        "add_user": "إضافة مستخدم",
        "no_messages": "لا توجد رسائل",
        "message_placeholder": "رسالة…",
        "group_doesnt_exists": "المجموعة غير موجودة",
        "you_arent_group_member": "أنت لست عضوا في المجموعة",
        "touch_to_see_more_info": "انقر لرؤية المزيد من المعلومات.",
        "start_videocall": "ابدأ مكالمة فيديو",
        "start_call": "ابدأ المكالمة",
        "create_group": "إنشاء مجموعة",
        "group_name": "أسم المجموعة",
        "add_members_to_create_group": "أضف أعضاء لإنشاء المجموعة",
        "search": "بحث",
        "creating": "خلق…",
        "dont_belong_anyone_group": "أنت لا تنتمي إلى أي مجموعة.",
        "already_add_this_hashtag": "لقد قمت بالفعل بإضافة هذا الهاشتاج",
        "search_files_by_hashtags": "البحث عن الملفات عن طريق الهاشتاج",
        "enter_to_add": "أدخل للإضافة",
        "hashtags_most_used": "الهاشتاجات الأكثر استخداماً",
        "can_add_it_giving_click": "يمكنك إضافتها إلى بحثك من خلال النقر عليها",
        "searching_files": "البحث في الملفات",
        "no_files_found": "لا توجد ملفات",
        "image": "صورة",
        "video": "فيديو",
        "audio": "سمعي",
        "library": "مكتبة",
        "home": "الصفحة الرئيسية",
        "block": "حاجز",
        "unblock": "لفتح",
        "has_blocked_you": "لقد قام بحظرك",
        "accept": "يقبل",
        "decline": "يرفض",
        "cancel_request": "إلغاء الطلب",
        "has_been_expulsed": "تم طرده",
        "room_doesnt_exists": "الغرفة غير موجودة",
        "you_arent_room_member": "أنت لست عضوا في الغرفة",
        "create_room": "إنشاء غرفة",
        "room_name": "اسم الغرفة",
        "no_rooms_created": "لم يتم إنشاء أي غرف.",
        "dont_belong_to_anyone_room": "أنت لا تنتمي إلى أي غرفة.",
        "enter_a_username": "اكتب مستخدمًا",
        "no_results": "لا نتائج",
        "create": "يخلق",
        "no_content_uploaded": "لم يتم تحميل أي محتوى",
        "new_subtag": "علامة فرعية جديدة",
        "update_info": "تحديث المعلومات",
        "updating": "تحديث",
        "update": "تحديث",
        "update_email": "تحديث البريد الإلكتروني",
        "change_password": "تغيير كلمة المرور",
        "current_password": "كلمة السر الحالية",
        "select": "Seleccionar",
        "tap_to_upload_a_file": "انقر لتحميل ملف",
        "edit_photo": "تعديل الصورة",
        "preview": "معاينة",
        "edit_video": "تحرير الفيديو",
        "delete_changes": "حذف التغييرات",
        "uploading": "جارٍ التحميل",
        "session_deleted_or_expired": "تم حذف الجلسة أو انتهت صلاحيتها",
        "edit_profile": "تعديل الملف الشخصي",
        "devices": "الأجهزة",
        "downloads": "التحميلات",
        "logout": "تسجيل خروج",
        "offline": "بدون اتصال",
        "images": "الصور",
        "videos": "أشرطة فيديو",
        "audios": "صوتيات",
        "chat": "محادثة",
        "session_unauthorized": "جلستك غير مصرح بها.",
        "authorize_session": "للوصول إلى هذه الجلسة، تحتاج إلى ترخيص جهازك.",
        "authorize_instructions": "تحقق من بريدك الإلكتروني بحثًا عن رابط التفويض أو انتقل إلى جلسة نشطة وحدد النقاط الثلاث في الزاوية اليمنى العليا، ثم انتقل إلى \"الأجهزة\" واختر \"التفويض\".",
        "refresh_page_after_authorize": "بمجرد الانتهاء من عملية الترخيص، قم بتحديث الصفحة للوصول إلى الجلسة.",
        "check_your_inbox": "تحقق من بريدك الالكتروني",
        "send_authorization_email": "أرسل بريدًا إلكترونيًا للتفويض",
        "call_finished": "المكالمة انتهت",
        "close": "يغلق",
        "waiting_stream": "في انتظار الإرسال",
        "request_camera_and_mic": "نحتاج إلى الوصول إلى الكاميرا والميكروفون الخاصين بك حتى تتمكن من إجراء المكالمات.",
        "call_in_progress": "مكالمتك تحت الإجراء",
        "group": "تَجَمَّع",
        "videocall": "مكالمة فيديو",
        "call": "يتصل",
        "connecting": "توصيل",
        "call_cancelled": "تم إلغاء المكالمة",
        "calling": "الاتصال",
        "hangup": "يشنق",
        "reject": "انخفاض",
        "has_sent_a_file": "لقد أرسل ملفا.",
        "keep": "يحفظ",
        "keep_file": "Si deshabilitas conservar el archivo, este se eliminará y no podrás acceder a él sin conexión a internet.",
        "not_keep_file": "إذا قمت بإيقاف تشغيل الاحتفاظ بالملفات، فسيتم حذفه ولن تتمكن من الوصول إليه دون الاتصال بالإنترنت.",
        "file_stored_instruction": "ستظهر جميع الملفات المخزنة بحد أخضر في قائمة الملفات المشتركة. يمكنك أيضًا رؤيتهم في القسم",
        "stored": "مخزن",
        "not_stored": "لا يتم تخزينه",
        "file_not_stored_instruction": "لن تتمكن من الوصول إلى هذا الملف دون الاتصال بالإنترنت.",
        "device": "جهاز",
        "file_visibility": "رؤية الملف",
        "visible_for_everyone": "مرئية للجميع",
        "shared_link": "الرابط المشترك",
        "hashtags": "علامات التصنيف",
        "hashtags_helps_to_search_your_file": "تساعدك علامات التصنيف في العثور على ملفاتك.",
        "edit": "يحرر",
        "move": "يتحرك",
        "rename": "إعادة تسمية",
        "visibility": "الرؤية",
        "delete": "اِسْتَبْعَد",
        "file_warn_delete": "هل أنت متأكد أنك تريد حذف الملف؟",
        "no_subtags_availables": "لا توجد علامات فرعية متاحة",
        "owner": "المنشئ",
        "expulsing": "طرد",
        "expulse": "يطرد",
        "you_have_left_the_group": "لقد تركت المجموعة",
        "coming_out": "يخرج",
        "leave_group": "اترك المجموعة",
        "you_have_left_the_room": "لقد غادرت الغرفة.",
        "leave_room": "غادر الغرفة",
        "message_deleted": "الرسالة المحذوفة",
        "has_been_error_deleting_message": "حدث خطأ أثناء حذف الرسالة",
        "deleting": "إزالة",
        "new_tag": "علامة جديدة",
        "photoEditor": {
            "name": "اسم",
            "save": "ينهي",
            "saveAs": "حفظ باسم",
            "back": "خلف",
            "loading": "الشحن…",
            "resetOperations": "إعادة تشغيل/حذف كافة العمليات.",
            "changesLoseConfirmation": "ستتم إزالة كافة التغييرات.",
            "changesLoseConfirmationHint": "هل أنت متأكد أنك تريد الاستمرار؟",
            "cancel": "يلغي",
            "continue": "يكمل",
            "undoTitle": "التراجع عن العملية الأخيرة",
            "redoTitle": "إعادة العملية الأخيرة",
            "showImageTitle": "إظهار الصورة الأصلية",
            "zoomInTitle": "تكبير",
            "zoomOutTitle": "تصغير",
            "toggleZoomMenuTitle": "تبديل قائمة التكبير/التصغير",
            "adjustTab": "يُعدِّل",
            "finetuneTab": "الألوان",
            "filtersTab": "المرشحات",
            "watermarkTab": "الصور",
            "annotateTab": "يرسم",
            "resize": "تغيير الحجم",
            "resizeTab": "مقاس",
            "invalidImageError": "صورة غير صالحة",
            "uploadImageError": "حدث خطأ أثناء تحميل الصورة.",
            "areNotImages": "إنها ليست صوراً",
            "isNotImage": "انها ليست صورة",
            "toBeUploaded": "ليتم تحميلها",
            "cropTool": "يقطع",
            "original": "إبداعي",
            "custom": "شخصية",
            "square": "مربع",
            "landscape": "أفقي",
            "portrait": "رَأسِيّ",
            "ellipse": "دائرة",
            "classicTv": "تلفزيون كلاسيكي",
            "cinemascope": "سينما سكوب",
            "arrowTool": "سهم",
            "blurTool": "طمس",
            "brightnessTool": "يشع",
            "contrastTool": "مقابلة",
            "ellipseTool": "دائرة",
            "unFlipX": "افتح X",
            "flipX": "الوجه العاشر",
            "unFlipY": "افتح Y",
            "flipY": "فليب واي",
            "hsvTool": "MSV",
            "hue": "مسحة",
            "saturation": "التشبع",
            "value": "قيمة",
            "imageTool": "صورة",
            "importing": "استيراد…",
            "addImage": "+ إضافة صورة",
            "uploadImage": "تحميل الصور",
            "fromGallery": "ملصقات",
            "lineTool": "خط",
            "penTool": "قلم",
            "polygonTool": "مضلع",
            "sides": "الجانبين",
            "rectangleTool": "مستطيل",
            "cornerRadius": "نصف قطر الحافة",
            "resizeWidthTitle": "العرض بالبكسل",
            "resizeHeightTitle": "ارتفاع البكسل",
            "toggleRatioLockTitle": "تبديل قفل البعد",
            "reset": "إعادة ضبط",
            "resetSize": "استعادة الأبعاد الأصلية للصورة",
            "rotateTool": "استدارة",
            "textTool": "نص",
            "textSpacings": "تباعد النص",
            "textAlignment": "محاذاة النص",
            "fontFamily": "خط العائلة",
            "size": "مقاس",
            "letterSpacing": "تباعد الحروف",
            "lineHeight": "ارتفاع خط",
            "warmthTool": "حرارة",
            "addWatermark": "+ إضافة صورة",
            "addWatermarkTitle": "اختر نوع العلامة المائية",
            "uploadWatermark": "اختر صورة",
            "addWatermarkAsText": "أضف نصًا",
            "padding": "محشو",
            "shadow": "الظل",
            "horizontal": "أفقي",
            "vertical": "رَأسِيّ",
            "blur": "طمس",
            "opacity": "العتامة",
            "position": "موضع",
            "stroke": "سماكة",
            "saveAsModalLabel": "تفاصيل",
            "extension": "امتداد",
            "nameIsRequired": "الاسم مطلوب.",
            "quality": "جودة",
            "imageDimensionsHoverTitle": "أبعاد الصورة المحفوظة (العرض × الارتفاع)",
            "cropSizeLowerThanResizedWarning": "يرجى ملاحظة أن مساحة الاقتصاص المحددة أصغر من الحجم المطبق، مما قد يؤدي إلى انخفاض الجودة.",
            "actualSize": "الحجم الحالي (100%)",
            "fitSize": "التكيف البعد",
            "addImageTitle": "حدد صورة لإضافتها…"
        },
        "changing": "تغيير",
        "profile_picture_changed": "تم تغيير صورة الملف الشخصي",
        "loading_profile_picture": "جارٍ تحميل الصورة الشخصية",
        "change": "يتغير",
        "session": "حصة",
        "system_operative": "نظام التشغيل",
        "navigator": "المتصفح",
        "authorize": "يأذن",
        "deauthorize": "التنصل",
        "keep_tag_content": "هل تريد الحفاظ على محتوى العلامة؟",
        "move_content_to": "نقل المحتوى إلى",
        "move_to": "الانتقال إلى",
        "chat_request": "طلب الدردشة",
        "accept_request": "قبول الطلب",
        "go_to_chat": "اذهب إلى الدردشة",
        "applying": "التقديم",
        "time": "وقت",
        "adjust": "يُعدِّل",
        "rotate": "استدارة",
        "start": "يبدأ",
        "end": "نهاية",
        "lang_name": "Arabian"
    },
    "az": {
        "name": "ad",
        "name_example": "Sevda",
        "last_name": "Soyad",
        "last_name_example": "Mammadova",
        "username": "İstifadəçi",
        "username_example": "sevda_mammadova",
        "email": "E-poçt",
        "email_example": "istifadəçi@domen.com",
        "password": "parol",
        "new_password": "yeni şifrə",
        "password_example": "*****************",
        "login": "Daxil ol",
        "register": "Qeydiyyatdan keçin",
        "verification_code": "Yoxlama kodu",
        "verification_code_example": "xxx",
        "verify": "Doğrulayın",
        "loading": "Yüklənir…",
        "please_wait": "Zəhmət olmasa, gözləyin",
        "go_back": "Qayıt",
        "recovery": "bərpa edin",
        "login_subtitle": "Məlumatlarınızı daxil edin və hesabınıza daxil olun.",
        "forgot_password_link": "Parolu unutmusunuz? bərpa edin.",
        "register_account_link": "Sizin hesabınız yoxdur? Abunə olun!",
        "register_subtitle": "Məlumatlarınızı daxil edin və hesabınızı qeydiyyatdan keçirin.",
        "login_link": "Artıq hesabınız var? Daxil ol!",
        "verification_code_instruction": "E-poçtunuzu yoxlayın və göndərilən kodu daxil edin.",
        "forgot_password_subtitle": "Parolunuzu bərpa etmək üçün e-poçtunuzu daxil edin.",
        "change_password_subtitle": "Yeni Şifrəni daxil et.",
        "change_password_code_used": "Parolunuzu sıfırlamaq üçün kod etibarsızdır və ya artıq istifadə olunub.",
        "no_chats": "Söhbətləriniz yoxdur.",
        "chats": "Söhbətlər",
        "groups": "Qruplar",
        "rooms": "Otaqlar",
        "save": "Saxla",
        "download": "Yüklə",
        "downloading_image": "Şəkil yüklənir",
        "an_error_has_ocurred": "Bir səhv baş verdi",
        "saving_changes": "Dəyişikliklər yadda saxlanılır",
        "go_back_to_edit": "Yenidən redaktə edin",
        "upload_warning_modal": "Redaktəni yükləmək orijinal şəkli əvəz edəcək.",
        "upload_question_modal": "Orijinalı saxlamaq və surətini çıxarmaq istəyirsiniz?",
        "create_copy_and_keep": "Kopya yaradın və saxlayın",
        "upload": "Yükləmək",
        "upload_changes": "Dəyişiklikləri yükləyin",
        "changes_saved": "Dəyişikliklər yadda saxlanıldı",
        "downloading_video": "Video yüklənir",
        "video_upload_warning_modal": "Redaktənin yüklənməsi orijinal videonu əvəz edəcək.",
        "results": "Nəticələr",
        "members": "Üzvlər",
        "cancel": "Ləğv et",
        "add_user": "İstifadəçi əlavə edin",
        "no_messages": "Mesaj yoxdur",
        "message_placeholder": "Mesaj…",
        "group_doesnt_exists": "Qrup mövcud deyil",
        "you_arent_group_member": "Siz qrupun üzvü deyilsiniz",
        "touch_to_see_more_info": "Ətraflı məlumatı görmək üçün toxunun.",
        "start_videocall": "Video zəngə başlayın",
        "start_call": "Zəngə başlayın",
        "create_group": "Qrup yaradın",
        "group_name": "Qrup adı",
        "add_members_to_create_group": "Qrup yaratmaq üçün üzvlər əlavə edin",
        "search": "Axtarmaq",
        "creating": "Yaradılır…",
        "dont_belong_anyone_group": "Siz heç bir qrupa aid deyilsiniz.",
        "already_add_this_hashtag": "Siz artıq həmin hashtag əlavə etmisiniz",
        "search_files_by_hashtags": "Faylları hashtaglarla axtarın",
        "enter_to_add": "Əlavə etmək üçün daxil olun",
        "hashtags_most_used": "Ən çox istifadə olunan hashtaglar",
        "can_add_it_giving_click": "Onları klikləməklə axtarışınıza əlavə edə bilərsiniz",
        "searching_files": "Fayllar axtarılır",
        "no_files_found": "Heç bir fayl tapılmadı",
        "image": "Şəkil",
        "video": "Video",
        "audio": "Audio",
        "library": "Kitab mağazası",
        "home": "Əsas səhifə",
        "block": "Blok",
        "unblock": "Kilidini açmaq üçün",
        "has_blocked_you": "O sizi bloklayıb",
        "accept": "Qəbul edin",
        "decline": "Rədd olun",
        "cancel_request": "Sorğunu ləğv edin",
        "has_been_expulsed": "xaric edilib",
        "room_doesnt_exists": "Otaq yoxdur",
        "you_arent_room_member": "Siz otağın üzvü deyilsiniz",
        "create_room": "Otaq yaradın",
        "room_name": "Otaq adı",
        "no_rooms_created": "Heç bir otaq yaradılmayıb.",
        "dont_belong_to_anyone_room": "Siz heç bir otağa aid deyilsiniz.",
        "enter_a_username": "İstifadəçi yazın",
        "no_results": "Nəticə yoxdur",
        "create": "Yaradın",
        "no_content_uploaded": "Heç bir məzmun yüklənməyib",
        "new_subtag": "Yeni alt etiket",
        "update_info": "Məlumatı yeniləyin",
        "updating": "Yenilənir",
        "update": "Yeniləyin",
        "update_email": "E-poçtu yeniləyin",
        "change_password": "Parolun dəyişdirilməsi",
        "current_password": "Hazırkı şifrə",
        "select": "seçin",
        "tap_to_upload_a_file": "Fayl yükləmək üçün toxunun",
        "edit_photo": "Fotonu redaktə edin",
        "preview": "Önizləmə",
        "edit_video": "Videonu redaktə edin",
        "delete_changes": "Dəyişiklikləri silin",
        "uploading": "Yüklənir",
        "session_deleted_or_expired": "Sessiya silindi və ya vaxtı bitdi",
        "edit_profile": "Profilə düzəliş et",
        "devices": "Cihazlar",
        "downloads": "endirmələr",
        "logout": "Çıxın",
        "offline": "Oflayn",
        "images": "Şəkillər",
        "videos": "Videolar",
        "audios": "Audiolar",
        "chat": "Söhbət",
        "session_unauthorized": "Sessiyanıza icazə verilməyib.",
        "authorize_session": "Bu seansa daxil olmaq üçün cihazınıza icazə verməlisiniz.",
        "authorize_instructions": "E-poçtunuzu avtorizasiya linki üçün yoxlayın və ya aktiv sessiyaya keçin və yuxarı sağ küncdəki üç nöqtəni seçin, sonra “Cihazlar”a keçin və “İcazə verin” seçin.",
        "refresh_page_after_authorize": "Avtorizasiya prosesini tamamladıqdan sonra daxil olmaq üçün səhifəni yeniləyin.",
        "check_your_inbox": "E-poçtunuzu yoxlayın",
        "send_authorization_email": "Avtorizasiya e-poçtu göndərin",
        "call_finished": "Zəng bitdi",
        "close": "Yaxın",
        "waiting_stream": "Ötürülməsi gözlənilir",
        "request_camera_and_mic": "Zəng etmək üçün kameranıza və mikrofonunuza giriş tələb olunur.",
        "call_in_progress": "Zəng davam edir",
        "group": "Klaster",
        "videocall": "Video zəng",
        "call": "Zəng edin",
        "connecting": "Qoşulur",
        "call_cancelled": "Zəng ləğv edildi",
        "calling": "Zəng edir",
        "hangup": "Asmaq",
        "reject": "Rədd olun",
        "has_sent_a_file": "fayl göndərdi.",
        "keep": "Saxla",
        "keep_file": "Faylın saxlanmasını aktiv etsəniz, o, proqramda yadda saxlanılacaq. Bu, İnternet bağlantısına ehtiyac olmadan onun əlçatanlığına imkan verəcəkdir.",
        "not_keep_file": "Fayl yaddaşını söndürsəniz, o silinəcək və İnternet bağlantısı olmadan ona daxil ola bilməyəcəksiniz.",
        "file_stored_instruction": "Bütün saxlanmış fayllar ümumi fayl siyahısında yaşıl haşiyə ilə görünəcək. Onlara bölmədə də baxa bilərsiniz",
        "stored": "Saxlanılır",
        "not_stored": "Saxlanılmır",
        "file_not_stored_instruction": "İnternet bağlantısı olmadan bu fayla daxil ola bilməyəcəksiniz.",
        "device": "Qurğu",
        "file_visibility": "Fayl Görünüşü",
        "visible_for_everyone": "Hər kəs üçün görünür",
        "shared_link": "Paylaşılan keçid",
        "hashtags": "Hashtaglar",
        "hashtags_helps_to_search_your_file": "Hashtaglar fayllarınızı tapmağa kömək edir.",
        "edit": "Redaktə et",
        "move": "Hərəkət edin",
        "rename": "Adını dəyişdirin",
        "visibility": "Görünüş",
        "delete": "aradan qaldırmaq",
        "file_warn_delete": "Faylı silmək istədiyinizə əminsiniz?",
        "no_subtags_availables": "Heç bir alt teq yoxdur",
        "owner": "Yaradan",
        "expulsing": "qovmaq",
        "expulse": "qovmaq",
        "you_have_left_the_group": "Siz qrupdan çıxdınız",
        "coming_out": "Çıxır",
        "leave_group": "Qrupu tərk edin",
        "you_have_left_the_room": "Sən otağı tərk etmisən.",
        "leave_room": "Otağı tərk edin",
        "message_deleted": "Silinmiş mesaj",
        "has_been_error_deleting_message": "Mesajı silərkən xəta baş verdi",
        "deleting": "Silinir",
        "new_tag": "Yeni etiket",
        "photoEditor": {
            "name": "ad",
            "save": "Bitir",
            "saveAs": "kimi yadda saxlayın",
            "back": "Geri",
            "loading": "Doldurulur…",
            "resetOperations": "Bütün əməliyyatları yenidən başladın/Silin.",
            "changesLoseConfirmation": "Bütün dəyişikliklər silinəcək.",
            "changesLoseConfirmationHint": "Davam etmək istədiyinizə əminsiniz?",
            "cancel": "Ləğv et",
            "continue": "Davam et",
            "undoTitle": "Son əməliyyatı geri qaytarın",
            "redoTitle": "Son əməliyyatı təkrarlayın",
            "showImageTitle": "Orijinal şəkli göstərin",
            "zoomInTitle": "Yaxınlaşdırma",
            "zoomOutTitle": "Küçült",
            "toggleZoomMenuTitle": "Böyütmə menyusunu dəyişin",
            "adjustTab": "Tənzimləyin",
            "finetuneTab": "Rənglər",
            "filtersTab": "Filtrlər",
            "watermarkTab": "Şəkillər",
            "annotateTab": "çəkmək",
            "resize": "Ölçü dəyişdirin",
            "resizeTab": "Ölçü",
            "invalidImageError": "Yanlış şəkil",
            "uploadImageError": "Şəkli yükləyərkən xəta baş verdi.",
            "areNotImages": "Onlar görüntü deyil",
            "isNotImage": "görüntü deyil",
            "toBeUploaded": "yüklənməlidir",
            "cropTool": "kəsmək",
            "original": "Orijinal",
            "custom": "Fərdiləşdirilmiş",
            "square": "Kvadrat",
            "landscape": "Üfüqi",
            "portrait": "Şaquli",
            "ellipse": "Dairə",
            "classicTv": "klassik tv",
            "cinemascope": "Kinemaskop",
            "arrowTool": "Ox",
            "blurTool": "Bulanıq",
            "brightnessTool": "Parıltı",
            "contrastTool": "Kontrast",
            "ellipseTool": "Dairə",
            "unFlipX": "X-i açın",
            "flipX": "X çevirin",
            "unFlipY": "Y-ni açın",
            "flipY": "Y çevirin",
            "hsvTool": "MSV",
            "hue": "Hue",
            "saturation": "Doyma",
            "value": "Dəyər",
            "imageTool": "Şəkil",
            "importing": "İdxal olunur…",
            "addImage": "+ Şəkil əlavə edin",
            "uploadImage": "Şəkil yükləyin",
            "fromGallery": "Stikerlər",
            "lineTool": "Xətt",
            "penTool": "Qələm",
            "polygonTool": "Poliqon",
            "sides": "Tərəflər",
            "rectangleTool": "Düzbucaqlı",
            "cornerRadius": "Kənar radiusu",
            "resizeWidthTitle": "Piksellə eni",
            "resizeHeightTitle": "Piksel hündürlüyü",
            "toggleRatioLockTitle": "Ölçü Kilidini dəyişdirin",
            "reset": "Sıfırlayın",
            "resetSize": "Şəklin orijinal ölçülərini bərpa edin",
            "rotateTool": "Döndürün",
            "textTool": "Mətn",
            "textSpacings": "Mətn aralığı",
            "textAlignment": "Mətnin Hizalanması",
            "fontFamily": "Şrift ailəsi",
            "size": "Ölçü",
            "letterSpacing": "Hərf aralığı",
            "lineHeight": "xəttin hündürlüyü",
            "warmthTool": "İstilik",
            "addWatermark": "+ Şəkil əlavə edin",
            "addWatermarkTitle": "Su nişanının növünü seçin",
            "uploadWatermark": "Şəkil seçin",
            "addWatermarkAsText": "Mətn əlavə edin",
            "padding": "Doldurma",
            "shadow": "Kölgə",
            "horizontal": "Üfüqi",
            "vertical": "Şaquli",
            "blur": "Bulanıq",
            "opacity": "Qeyri-şəffaflıq",
            "position": "Vəzifə",
            "stroke": "Qalınlıq",
            "saveAsModalLabel": "Təfərrüatlar",
            "extension": "Uzatma",
            "nameIsRequired": "Ad tələb olunur.",
            "quality": "Keyfiyyət",
            "imageDimensionsHoverTitle": "Saxlanan şəkil ölçüləri (en x hündürlük)",
            "cropSizeLowerThanResizedWarning": "Nəzərə alın ki, seçilmiş məhsul sahəsi tətbiq olunan ölçüdən kiçikdir və bu, keyfiyyətin aşağı düşməsinə səbəb ola bilər.",
            "actualSize": "Cari ölçü (100%)",
            "fitSize": "Ölçü uyğunlaşdırın",
            "addImageTitle": "Əlavə etmək üçün şəkil seçin…"
        },
        "changing": "Dəyişən",
        "profile_picture_changed": "Profil şəkli dəyişdirildi",
        "loading_profile_picture": "Profil şəkli yüklənir",
        "change": "Dəyişmək",
        "session": "Sessiya",
        "system_operative": "ƏS",
        "navigator": "Brauzer",
        "authorize": "Səlahiyyət verin",
        "deauthorize": "İnkar et",
        "keep_tag_content": "Etiketin məzmununu qorumaq istəyirsiniz?",
        "move_content_to": "Məzmunu bura köçürün",
        "move_to": "Köçmək",
        "chat_request": "Çat sorğusu",
        "accept_request": "Müraciəti qəbul edin",
        "go_to_chat": "Söhbətə gedin",
        "applying": "Müraciət edir",
        "time": "Vaxt",
        "adjust": "Tənzimləyin",
        "rotate": "Döndürün",
        "start": "Başlamaq",
        "end": "Son",
        "lang_name": "Azerbaijan"
    },
    "bn": {
        "name": "নাম",
        "name_example": "Mohammed",
        "last_name": "নামের শেষাংশ",
        "last_name_example": "Akter",
        "username": "ব্যবহারকারী",
        "username_example": "mohammed_akter",
        "email": "ইমেইল",
        "email_example": "ব্যবহারকারী@ডোমেইন.com",
        "password": "পাসওয়ার্ড",
        "new_password": "নতুন পাসওয়ার্ড",
        "password_example": "*****************",
        "login": "প্রবেশ করুন",
        "register": "নিবন্ধন",
        "verification_code": "যাচাইকরণ কোড",
        "verification_code_example": "xxx",
        "verify": "যাচাই করুন",
        "loading": "চার্জিং…",
        "please_wait": "অনুগ্রহপূর্বক অপেক্ষা করুন",
        "go_back": "প্রত্যাবর্তন",
        "recovery": "পুনরুদ্ধার করুন",
        "login_subtitle": "আপনার বিবরণ লিখুন এবং আপনার অ্যাকাউন্ট অ্যাক্সেস করুন.",
        "forgot_password_link": "আপনি আপনার পাসওয়ার্ড ভুলে গেছেন? পুনরুদ্ধার করুন।",
        "register_account_link": "আপনার কোন একাউন্ট নেই? নিবন্ধন করুন!",
        "register_subtitle": "আপনার বিবরণ লিখুন এবং আপনার অ্যাকাউন্ট নিবন্ধন.",
        "login_link": "আপনার কি ইতিমধ্যে একটি সদস্যপদ আছে? প্রবেশ করুন!",
        "verification_code_instruction": "আপনার ইমেল চেক করুন এবং প্রেরিত কোড লিখুন.",
        "forgot_password_subtitle": "আপনার পাসওয়ার্ড পুনরুদ্ধার করতে আপনার ইমেল লিখুন.",
        "change_password_subtitle": "একটি নতুন পাসওয়ার্ড লিখুন.",
        "change_password_code_used": "আপনার পাসওয়ার্ড পুনরুদ্ধার কোড অবৈধ বা ইতিমধ্যে ব্যবহার করা হয়েছে",
        "no_chats": "আপনি কথোপকথন নেই.",
        "chats": "কথোপকথন",
        "groups": "গোষ্ঠী",
        "rooms": "রুম",
        "save": "রাখা",
        "download": "স্রাব",
        "downloading_image": "ছবি ডাউনলোড করা হচ্ছে",
        "an_error_has_ocurred": "একটি ত্রুটি উৎপন্ন হয়েছে",
        "saving_changes": "পরিবর্তন সংরক্ষণ করা হচ্ছে",
        "go_back_to_edit": "আবার সম্পাদনা করুন",
        "upload_warning_modal": "এডিট আপলোড করলে আসল ইমেজ বদলে যাবে।",
        "upload_question_modal": "আপনি মূল রাখা এবং একটি কপি করতে চান?",
        "create_copy_and_keep": "কপি তৈরি করুন এবং রাখুন",
        "upload": "আপলোড করুন",
        "upload_changes": "আপলোড পরিবর্তন",
        "changes_saved": "পরিবর্তনগুলি সংরক্ষিত",
        "downloading_video": "ভিডিও ডাউনলোড করা হচ্ছে",
        "video_upload_warning_modal": "সম্পাদনা আপলোড করলে মূল ভিডিওটি প্রতিস্থাপিত হবে।",
        "results": "ফলাফল",
        "members": "সদস্যরা",
        "cancel": "বাতিল করুন",
        "add_user": "ব্যবহারকারী যোগ করুন",
        "no_messages": "কোন বার্তা নেই",
        "message_placeholder": "বার্তা…",
        "group_doesnt_exists": "দলটির অস্তিত্ব নেই",
        "you_arent_group_member": "আপনি গ্রুপের সদস্য নন",
        "touch_to_see_more_info": "আরও তথ্য দেখতে আলতো চাপুন।",
        "start_videocall": "ভিডিও কল শুরু করুন",
        "start_call": "কল শুরু করুন",
        "create_group": "গ্রুপ তৈরি করুন",
        "group_name": "দলের নাম",
        "add_members_to_create_group": "গ্রুপ তৈরি করতে সদস্য যোগ করুন",
        "search": "খোঁজা",
        "creating": "তৈরি করছে…",
        "dont_belong_anyone_group": "আপনি কোন দলের অন্তর্গত না.",
        "already_add_this_hashtag": "আপনি ইতিমধ্যে হ্যাশট্যাগ যোগ করেছেন",
        "search_files_by_hashtags": "হ্যাশট্যাগ দ্বারা ফাইল অনুসন্ধান করুন",
        "enter_to_add": "যোগ করতে প্রবেশ করুন",
        "hashtags_most_used": "সর্বাধিক ব্যবহৃত হ্যাশট্যাগ",
        "can_add_it_giving_click": "আপনি তাদের উপর ক্লিক করে আপনার অনুসন্ধানে যোগ করতে পারেন.",
        "searching_files": "ফাইল অনুসন্ধান করা হচ্ছে",
        "no_files_found": "কোন ফাইল পাওয়া যায়নি",
        "image": "ছবি",
        "video": "ভিডিও",
        "audio": "শ্রুতি",
        "library": "বইয়ের দোকান",
        "home": "হোম পেজ",
        "block": "ব্লক",
        "unblock": "তালা খুলতে",
        "has_blocked_you": "সে তোমাকে ব্লক করেছে",
        "accept": "গ্রহণ করুন",
        "decline": "প্রত্যাখ্যান",
        "cancel_request": "অনুরোধ বাতিল",
        "has_been_expulsed": "বহিষ্কার করা হয়েছে",
        "room_doesnt_exists": "ঘরের অস্তিত্ব নেই",
        "you_arent_room_member": "আপনি রুমের একজন সদস্য নন",
        "create_room": "রুম তৈরি করুন",
        "room_name": "রুমের নাম",
        "no_rooms_created": "কোন রুম তৈরি করা হয় না.",
        "dont_belong_to_anyone_room": "আপনি কোন রুমে অন্তর্গত না.",
        "enter_a_username": "একটি ব্যবহারকারী লিখুন",
        "no_results": "কোন ফলাফল নেই",
        "create": "সৃষ্টি",
        "no_content_uploaded": "কোন কন্টেন্ট আপলোড",
        "new_subtag": "নতুন সাবট্যাগ",
        "update_info": "হালনাগাদ তথ্য",
        "updating": "আপডেট করা হচ্ছে",
        "update": "হালনাগাদ",
        "update_email": "আপডেট ইমেল",
        "change_password": "পাসওয়ার্ড পরিবর্তন করুন",
        "current_password": "বর্তমান পাসওয়ার্ড",
        "select": "নির্বাচন করুন",
        "tap_to_upload_a_file": "একটি ফাইল আপলোড করতে আলতো চাপুন৷",
        "edit_photo": "ছবি সংস্কার",
        "preview": "পূর্বরূপ",
        "edit_video": "চলচ্চিত্র সম্পাদন",
        "delete_changes": "পরিবর্তনগুলি মুছুন",
        "uploading": "উপরে যাচ্ছে",
        "session_deleted_or_expired": "অধিবেশন মুছে ফেলা বা মেয়াদ শেষ হয়েছে",
        "edit_profile": "জীবন বৃত্তান্ত সম্পাদনা",
        "devices": "ডিভাইস",
        "downloads": "ডাউনলোড",
        "logout": "প্রস্থান",
        "offline": "অফলাইন",
        "images": "ছবি",
        "videos": "ভিডিও",
        "audios": "অডিও",
        "chat": "চ্যাট",
        "session_unauthorized": "আপনার অধিবেশন অনুমোদিত নয়.",
        "authorize_session": "এই অধিবেশন অ্যাক্সেস করতে, আপনাকে অবশ্যই আপনার ডিভাইস অনুমোদন করতে হবে৷",
        "authorize_instructions": "অনুমোদন লিঙ্কের জন্য আপনার ইমেল চেক করুন বা একটি সক্রিয় অধিবেশনে যান এবং উপরের ডানদিকে তিনটি বিন্দু নির্বাচন করুন, তারপর \"ডিভাইস\" এ যান এবং \"অনুমোদিত করুন\" নির্বাচন করুন।",
        "refresh_page_after_authorize": "অনুমোদন প্রক্রিয়া সম্পন্ন করার পরে, অধিবেশন অ্যাক্সেস করতে পৃষ্ঠাটি রিফ্রেশ করুন।",
        "check_your_inbox": "আপনার ইমেইল চেক করুন",
        "send_authorization_email": "অনুমোদন ইমেল পাঠান",
        "call_finished": "কল শেষ",
        "close": "বন্ধ",
        "waiting_stream": "ট্রান্সমিশনের জন্য অপেক্ষা করছে",
        "request_camera_and_mic": "আমাদের আপনার ক্যামেরা এবং মাইক্রোফোনে অ্যাক্সেস দরকার যাতে আপনি কল করতে পারেন।",
        "call_in_progress": "কল চলছে",
        "group": "ক্লাস্টার",
        "videocall": "ভিডিও কল",
        "call": "কল",
        "connecting": "সংযোগ করা হচ্ছে",
        "call_cancelled": "কল বাতিল করা হয়েছে",
        "calling": "কলিং",
        "hangup": "টাঙ্গান",
        "reject": "প্রত্যাখ্যান",
        "has_sent_a_file": "একটি ফাইল পাঠিয়েছে।",
        "keep": "রাখা",
        "keep_file": "আপনি ফাইল ধারণ সক্ষম করলে, এটি অ্যাপে সংরক্ষণ করা হবে। এটি ইন্টারনেট সংযোগের প্রয়োজন ছাড়াই এর অ্যাক্সেসযোগ্যতার অনুমতি দেবে।",
        "not_keep_file": "আপনি ফাইল সংরক্ষণ বন্ধ করলে, এটি মুছে ফেলা হবে এবং আপনি ইন্টারনেট সংযোগ ছাড়া এটি অ্যাক্সেস করতে সক্ষম হবেন না।",
        "file_stored_instruction": "সমস্ত সংরক্ষিত ফাইলগুলি একটি সবুজ বর্ডার সহ সাধারণ ফাইল তালিকায় উপস্থিত হবে। আপনি বিভাগে তাদের দেখতে পারেন",
        "stored": "সঞ্চিত",
        "not_stored": "এটা সংরক্ষণ করা হয় না",
        "file_not_stored_instruction": "আপনি একটি ইন্টারনেট সংযোগ ছাড়া এই ফাইল অ্যাক্সেস করতে সক্ষম হবে না.",
        "device": "যন্ত্র",
        "file_visibility": "ফাইল দৃশ্যমানতা",
        "visible_for_everyone": "সবার জন্য দৃশ্যমান",
        "shared_link": "শেয়ার করা লিঙ্ক",
        "hashtags": "হ্যাশট্যাগ",
        "hashtags_helps_to_search_your_file": "হ্যাশট্যাগ আপনাকে আপনার ফাইল খুঁজে পেতে সাহায্য করে।",
        "edit": "সম্পাদনা করুন",
        "move": "অবস্থানের পরিবর্তন",
        "rename": "নাম পরিবর্তন করুন",
        "visibility": "দৃশ্যমানতা",
        "delete": "মুছে ফেলা",
        "file_warn_delete": "আপনি কি নিশ্চিত যে আপনি ফাইলটি মুছে ফেলতে চান?",
        "no_subtags_availables": "কোন সাবট্যাগ উপলব্ধ",
        "owner": "সৃষ্টিকর্তা",
        "expulsing": "বহিষ্কার",
        "expulse": "বহিষ্কার করা",
        "you_have_left_the_group": "আপনি দল ছেড়ে চলে গেছেন",
        "coming_out": "বাইরে আসা",
        "leave_group": "দল ছেড়ে দাও",
        "you_have_left_the_room": "আপনি রুম ছেড়ে চলে গেছেন.",
        "leave_room": "রুম ত্যাগ কর",
        "message_deleted": "মুছে ফেলা বার্তা",
        "has_been_error_deleting_message": "বার্তাটি মুছে ফেলার সময় একটি ত্রুটি ঘটেছে৷",
        "deleting": "অপসারণ করা হচ্ছে",
        "new_tag": "নতুন ট্যাগ",
        "photoEditor": {
            "name": "নাম",
            "save": "শেষ করুন",
            "saveAs": "সংরক্ষণ করুন",
            "back": "পেছনে",
            "loading": "চার্জিং…",
            "resetOperations": "সমস্ত ক্রিয়াকলাপ পুনরায় চালু/মুছুন।",
            "changesLoseConfirmation": "সব পরিবর্তন মুছে ফেলা হবে.",
            "changesLoseConfirmationHint": "আপনি কি চালিয়ে যেতে চান?",
            "cancel": "বাতিল করুন",
            "continue": "চালিয়ে যান",
            "undoTitle": "শেষ অপারেশন পূর্বাবস্থায় ফেরান",
            "redoTitle": "শেষ অপারেশন পুনরায় করুন",
            "showImageTitle": "আসল ছবি দেখান",
            "zoomInTitle": "প্রসারিত করো",
            "zoomOutTitle": "ছোট করা",
            "toggleZoomMenuTitle": "জুম মেনু টগল করুন",
            "adjustTab": "সামঞ্জস্য করুন",
            "finetuneTab": "রং",
            "filtersTab": "ফিল্টার",
            "watermarkTab": "ছবি",
            "annotateTab": "আঁকা",
            "resize": "আকার পরিবর্তন করুন",
            "resizeTab": "আকার",
            "invalidImageError": "অবৈধ ছবি",
            "uploadImageError": "ছবিটি লোড করার সময় একটি ত্রুটি ঘটেছে৷",
            "areNotImages": "তারা ইমেজ নয়",
            "isNotImage": "এটা একটি ইমেজ না",
            "toBeUploaded": "আপলোড করা",
            "cropTool": "কাটা",
            "original": "আসল",
            "custom": "ব্যক্তিগতকৃত",
            "square": "বর্গক্ষেত্র",
            "landscape": "অনুভূমিক",
            "portrait": "উল্লম্ব",
            "ellipse": "বৃত্ত",
            "classicTv": "ক্লাসিক টিভি",
            "cinemascope": "সিনেমাস্কোপ",
            "arrowTool": "তীর",
            "blurTool": "ঝাপসা",
            "brightnessTool": "দীপ্তি",
            "contrastTool": "বৈপরীত্য",
            "ellipseTool": "বৃত্ত",
            "unFlipX": "X আনফ্লিপ করুন",
            "flipX": "ফ্লিপ X",
            "unFlipY": "Y আনফ্লিপ করুন",
            "flipY": "ফ্লিপ Y",
            "hsvTool": "MSV",
            "hue": "হিউ",
            "saturation": "স্যাচুরেশন",
            "value": "মান",
            "imageTool": "ছবি",
            "importing": "আমদানি করা হচ্ছে…",
            "addImage": "+ ছবি যোগ করুন",
            "uploadImage": "চিত্র আপলোড",
            "fromGallery": "স্টিকার",
            "lineTool": "লাইন",
            "penTool": "পেন্সিল",
            "polygonTool": "বহুভুজ",
            "sides": "পক্ষই",
            "rectangleTool": "আয়তক্ষেত্র",
            "cornerRadius": "প্রান্ত ব্যাসার্ধ",
            "resizeWidthTitle": "পিক্সেলে প্রস্থ",
            "resizeHeightTitle": "পিক্সেল উচ্চতা",
            "toggleRatioLockTitle": "ডাইমেনশন লক টগল করুন",
            "reset": "রিসেট",
            "resetSize": "আসল ছবির মাত্রায় রিসেট করুন",
            "rotateTool": "আবর্তিত",
            "textTool": "পাঠ্য",
            "textSpacings": "পাঠ্য ব্যবধান",
            "textAlignment": "লিখার বিন্যাস",
            "fontFamily": "ফন্ট পরিবার",
            "size": "আকার",
            "letterSpacing": "অক্ষরের ব্যবধান",
            "lineHeight": "লাইনের উচ্চতা",
            "warmthTool": "তাপ",
            "addWatermark": "+ ছবি যোগ করুন",
            "addWatermarkTitle": "ওয়াটারমার্কের ধরন নির্বাচন করুন",
            "uploadWatermark": "ছবি নির্বাচন করুন",
            "addWatermarkAsText": "টেক্সট যোগ করুন",
            "padding": "স্টাফড",
            "shadow": "ছায়া",
            "horizontal": "অনুভূমিক",
            "vertical": "উল্লম্ব",
            "blur": "ঝাপসা",
            "opacity": "অস্বচ্ছতা",
            "position": "অবস্থান",
            "stroke": "পুরুত্ব",
            "saveAsModalLabel": "বিস্তারিত",
            "extension": "এক্সটেনশন",
            "nameIsRequired": "নাম আবশ্যক.",
            "quality": "গুণমান",
            "imageDimensionsHoverTitle": "সংরক্ষিত ছবির মাত্রা (প্রস্থ x উচ্চতা)",
            "cropSizeLowerThanResizedWarning": "অনুগ্রহ করে মনে রাখবেন যে নির্বাচিত ফসল এলাকাটি আকার পরিবর্তনের চেয়ে ছোট, যা গুণমান হ্রাস করতে পারে।",
            "actualSize": "বর্তমান আকার (100%)",
            "fitSize": "মাত্রা মানিয়ে নিন",
            "addImageTitle": "যোগ করার জন্য একটি ছবি নির্বাচন করুন…"
        },
        "changing": "পরিবর্তন হচ্ছে",
        "profile_picture_changed": "প্রোফাইল ফটো পরিবর্তন করা হয়েছে",
        "loading_profile_picture": "প্রোফাইল ফটো লোড হচ্ছে",
        "change": "পরিবর্তন",
        "session": "সেশন",
        "system_operative": "ওএস",
        "navigator": "ব্রাউজার",
        "authorize": "অনুমোদন করা",
        "deauthorize": "অস্বীকার করুন",
        "keep_tag_content": "আপনি ট্যাগের বিষয়বস্তু সংরক্ষণ করতে চান?",
        "move_content_to": "বিষয়বস্তু সরান",
        "move_to": "চলো",
        "chat_request": "চ্যাট অনুরোধ",
        "accept_request": "অনুরোধ গ্রহন কর",
        "go_to_chat": "চ্যাটে যান",
        "applying": "আবেদন করা হচ্ছে",
        "time": "সময়",
        "adjust": "সামঞ্জস্য করুন",
        "rotate": "আবর্তিত",
        "start": "শুরু",
        "end": "ফাইনাল",
        "lang_name": "Bengali"
    },
    "bg": {
        "name": "Име",
        "name_example": "Georgi",
        "last_name": "Фамилно име",
        "last_name_example": "Ivanov",
        "username": "Потребител",
        "username_example": "georgi_ivanov",
        "email": "Адрес на електронна поща",
        "email_example": "потребител@домейн.com",
        "password": "Парола",
        "new_password": "Нова парола",
        "password_example": "*****************",
        "login": "Влизане",
        "register": "Регистър",
        "verification_code": "Код за проверка",
        "verification_code_example": "xxx",
        "verify": "Проверка на",
        "loading": "Зареждане на…",
        "please_wait": "Моля, изчакайте",
        "go_back": "Обратно към",
        "recovery": "Възстановяване",
        "login_subtitle": "Въведете данните си и влезте в профила си.",
        "forgot_password_link": "Забравили сте паролата си? Възстановете я.",
        "register_account_link": "Нямате акаунт? Регистрирайте се!",
        "register_subtitle": "Въведете данните си и регистрирайте акаунта си.",
        "login_link": "Вече имате акаунт? Влезте!",
        "verification_code_instruction": "Проверете имейла си и въведете изпратения код.",
        "forgot_password_subtitle": "Въведете имейл адреса си, за да изтеглите паролата си.",
        "change_password_subtitle": "Въведете нова парола.",
        "change_password_code_used": "Кодът за възстановяване на паролата ви е невалиден или вече е използван.",
        "no_chats": "Не водите разговори.",
        "chats": "Разговори",
        "groups": "Групи",
        "rooms": "Стаи",
        "save": "Запазете",
        "download": "Изтегляне",
        "downloading_image": "Изтегляне на изображение",
        "an_error_has_ocurred": "Възникнала е грешка",
        "saving_changes": "Запазване на промените",
        "go_back_to_edit": "Обратно към редактиране",
        "upload_warning_modal": "Оригиналното изображение ще бъде заменено, когато редакцията бъде качена.",
        "upload_question_modal": "Искате ли да запазите оригинала и да създадете копие?",
        "create_copy_and_keep": "Създайте копие и го запазете",
        "upload": "Качване на",
        "upload_changes": "Качване на промени",
        "changes_saved": "Запазени промени",
        "downloading_video": "Изтегляне на видео",
        "video_upload_warning_modal": "Оригиналният видеоклип ще бъде заменен при качването на редакцията.",
        "results": "Резултати",
        "members": "Членове",
        "cancel": "Отмени",
        "add_user": "Добавяне на потребител",
        "no_messages": "Няма съобщения",
        "message_placeholder": "Съобщение…",
        "group_doesnt_exists": "Групата не съществува",
        "you_arent_group_member": "Не сте член на групата",
        "touch_to_see_more_info": "Докоснете, за да видите повече информация.",
        "start_videocall": "Стартиране на видеоразговор",
        "start_call": "Започнете повикване",
        "create_group": "Създаване на група",
        "group_name": "Име на групата",
        "add_members_to_create_group": "Добавяне на членове за създаване на групата",
        "search": "Търсене",
        "creating": "Създаване на…",
        "dont_belong_anyone_group": "Вие не принадлежите към никоя група.",
        "already_add_this_hashtag": "Вече сте добавили този хаштаг",
        "search_files_by_hashtags": "Търсене на файлове по хаштагове",
        "enter_to_add": "Въведете, за да добавите",
        "hashtags_most_used": "Най-използвани хаштагове",
        "can_add_it_giving_click": "Можете да ги добавите към търсенето, като кликнете върху тях.",
        "searching_files": "Търсене на файлове",
        "no_files_found": "Не са намерени файлове",
        "image": "Изображение",
        "video": "Видео",
        "audio": "Аудио",
        "library": "Книжарница",
        "home": "Начална страница",
        "block": "Блокирайте",
        "unblock": "Отблокирайте",
        "has_blocked_you": "Той ви е блокирал",
        "accept": "Приемете",
        "decline": "Отхвърлете",
        "cancel_request": "Отмяна на заявката",
        "has_been_expulsed": "е експулсиран",
        "room_doesnt_exists": "Стаята не съществува",
        "you_arent_room_member": "Не сте член на стаята",
        "create_room": "Създаване на стая",
        "room_name": "Име на стаята",
        "no_rooms_created": "Не са създадени никакви стаи.",
        "dont_belong_to_anyone_room": "Не принадлежите към никоя стая.",
        "enter_a_username": "Написване на потребител",
        "no_results": "Няма резултати",
        "create": "Създайте",
        "no_content_uploaded": "Няма качено съдържание",
        "new_subtag": "Нов подтаг",
        "update_info": "Актуализиране на информацията",
        "updating": "Актуализиране на",
        "update": "Актуализация",
        "update_email": "Актуализиране на имейл",
        "change_password": "Промяна на паролата",
        "current_password": "Текуща парола",
        "select": "Изберете",
        "tap_to_upload_a_file": "Докоснете, за да качите файл",
        "edit_photo": "Редактиране на снимката",
        "preview": "Предварителен преглед",
        "edit_video": "Редактиране на видео",
        "delete_changes": "Изтриване на промените",
        "uploading": "Качване на",
        "session_deleted_or_expired": "Изтрита или изтекла сесия",
        "edit_profile": "Редактиране на профила",
        "devices": "Устройства",
        "downloads": "Изтегляния",
        "logout": "Излизане от системата",
        "offline": "Офлайн",
        "images": "Изображения",
        "videos": "Видеоклипове",
        "audios": "Audios",
        "chat": "Чат",
        "session_unauthorized": "Сесията ви не е разрешена.",
        "authorize_session": "За да получите достъп до тази сесия, трябва да оторизирате устройството си.",
        "authorize_instructions": "Моля, проверете имейла си за връзка за оторизация или отидете на активна сесия и изберете трите точки в горния десен ъгъл, след което отидете на \"Devices\" и изберете \"Authorise\".",
        "refresh_page_after_authorize": "След като завършите процеса на оторизация, опреснете страницата за вход.",
        "check_your_inbox": "Проверете електронната си поща",
        "send_authorization_email": "Изпращане на имейл за разрешение",
        "call_finished": "Обаждането е приключило",
        "close": "Затвори",
        "waiting_stream": "Очакваме предаване",
        "request_camera_and_mic": "Нуждаем се от достъп до камерата и микрофона ви, за да можете да провеждате разговори.",
        "call_in_progress": "Текущо повикване",
        "group": "Група",
        "videocall": "Видеоповикване",
        "call": "Обадете се на",
        "connecting": "Свързване на",
        "call_cancelled": "Отменено повикване",
        "calling": "Повикване на",
        "hangup": "Сложете слушалката",
        "reject": "Отхвърлете",
        "has_sent_a_file": "е изпратил файл.",
        "keep": "Запазете",
        "keep_file": "Ако разрешите файлът да бъде запазен, той ще бъде запазен в приложението. Това ще позволи достъпност без необходимост от интернет връзка.",
        "not_keep_file": "Ако деактивирате опцията за запазване на файлове, те ще бъдат изтрити и няма да имате достъп до тях без интернет връзка.",
        "file_stored_instruction": "Всички запазени файлове ще се появят със зелена рамка в общия списък с файлове. Можете също така да ги видите в",
        "stored": "Съхранява се",
        "not_stored": "Не се съхранява",
        "file_not_stored_instruction": "Няма да имате достъп до този файл без връзка с интернет.",
        "device": "Устройство",
        "file_visibility": "Видимост на архива",
        "visible_for_everyone": "Видими за всички",
        "shared_link": "Споделената връзка",
        "hashtags": "Хаштагове",
        "hashtags_helps_to_search_your_file": "Хаштаговете помагат при търсенето на вашите файлове.",
        "edit": "Редактиране",
        "move": "Премести",
        "rename": "Преименувайте",
        "visibility": "Видимост",
        "delete": "Изтриване на",
        "file_warn_delete": "Сигурни ли сте, че искате да изтриете файла?",
        "no_subtags_availables": "Няма налични подтегове",
        "owner": "Създател",
        "expulsing": "Изхвърляне на",
        "expulse": "Изгони",
        "you_have_left_the_group": "Напуснали сте групата",
        "coming_out": "Напускане на",
        "leave_group": "Напускане на групата",
        "you_have_left_the_room": "Излязъл си от стаята.",
        "leave_room": "Напускане на стаята",
        "message_deleted": "Изтрито съобщение",
        "has_been_error_deleting_message": "Възникна грешка при изтриването на съобщението",
        "deleting": "Премахване на",
        "new_tag": "Нов етикет",
        "photoEditor": {
            "name": "Име",
            "save": "Запазете",
            "saveAs": "Запазване като",
            "back": "Обратно",
            "loading": "Зареждане на…",
            "resetOperations": "Нулиране/изтриване на всички операции.",
            "changesLoseConfirmation": "Всички промени ще бъдат изтрити.",
            "changesLoseConfirmationHint": "Сигурни ли сте, че искате да продължите?",
            "cancel": "Отмяна",
            "continue": "Продължете",
            "undoTitle": "Отмяна на последната операция",
            "redoTitle": "Повторно извършване на последната операция",
            "showImageTitle": "Показване на оригиналното изображение",
            "zoomInTitle": "Увеличение на мащаба",
            "zoomOutTitle": "Намаляване на мащаба",
            "toggleZoomMenuTitle": "Превключване на менюто за мащабиране",
            "adjustTab": "Регулиране",
            "finetuneTab": "Цветове",
            "filtersTab": "Филтри",
            "watermarkTab": "Изображения",
            "annotateTab": "Рисуване",
            "resize": "Промяна на размера",
            "resizeTab": "Размер",
            "invalidImageError": "Невалидно изображение",
            "uploadImageError": "Възникна грешка при зареждането на изображението.",
            "areNotImages": "те не са изображения",
            "isNotImage": "не е изображение",
            "toBeUploaded": "да бъдат качени",
            "cropTool": "Изрежете",
            "original": "Оригинал",
            "custom": "Персонализиран",
            "square": "Квадрат",
            "landscape": "Хоризонтален",
            "portrait": "Вертикален",
            "ellipse": "Кръг",
            "classicTv": "Класическа телевизия",
            "cinemascope": "Синемаскоп",
            "arrowTool": "Стрелка",
            "blurTool": "Размазване",
            "brightnessTool": "Яркост",
            "contrastTool": "Контраст",
            "ellipseTool": "Кръг",
            "unFlipX": "Разгръщане на X",
            "flipX": "Флип X",
            "unFlipY": "Разгръщане на Y",
            "flipY": "Флип Y",
            "hsvTool": "MSV",
            "hue": "Nuance",
            "saturation": "Насищане",
            "value": "Стойност",
            "imageTool": "Изображение",
            "importing": "Внос на…",
            "addImage": "+ Добавяне на изображение",
            "uploadImage": "Качване на изображение",
            "fromGallery": "Стикери",
            "lineTool": "Линия",
            "penTool": "Молив",
            "polygonTool": "Полигон",
            "sides": "Странични",
            "rectangleTool": "Правоъгълник",
            "cornerRadius": "Радиус на ръба",
            "resizeWidthTitle": "Ширина в пиксели",
            "resizeHeightTitle": "Височина в пиксели",
            "toggleRatioLockTitle": "Превключване на заключването на размерите",
            "reset": "Рестартиране на",
            "resetSize": "Възстановяване на оригиналните размери на изображението",
            "rotateTool": "Въртене",
            "textTool": "Текст",
            "textSpacings": "Разстояние между текста",
            "textAlignment": "Подравняване на текста",
            "fontFamily": "Семейство шрифтове",
            "size": "Размер",
            "letterSpacing": "Разстояния между буквите",
            "lineHeight": "Височина на линията",
            "warmthTool": "Топлина",
            "addWatermark": "+ Добавяне на изображение",
            "addWatermarkTitle": "Изберете типа на водния знак",
            "uploadWatermark": "Изберете изображение",
            "addWatermarkAsText": "Добавяне на текст",
            "padding": "Пълнене",
            "shadow": "Сянка",
            "horizontal": "Хоризонтален",
            "vertical": "Вертикален",
            "blur": "Размазване",
            "opacity": "Непрозрачност",
            "position": "Позиция",
            "stroke": "Плътност",
            "saveAsModalLabel": "Подробности",
            "extension": "Удължение",
            "nameIsRequired": "Името е задължително.",
            "quality": "Качество",
            "imageDimensionsHoverTitle": "Запазени размери на изображението (ширина x височина)",
            "cropSizeLowerThanResizedWarning": "Имайте предвид, че избраната област на изрязване е по-малка от приложената промяна на размера, което може да доведе до намаляване на качеството.",
            "actualSize": "Текущ размер (100%)",
            "fitSize": "Адаптиране на размера",
            "addImageTitle": "Изберете изображение, което да добавите…"
        },
        "changing": "Промяна на",
        "profile_picture_changed": "Променена профилна снимка",
        "loading_profile_picture": "Зареждане на профилна снимка",
        "change": "Промяна",
        "session": "Сесия",
        "system_operative": "Операционна система",
        "navigator": "Браузър",
        "authorize": "Упълномощаване",
        "deauthorize": "Отмяна на разрешението",
        "keep_tag_content": "Искате ли да запазите съдържанието на тага?",
        "move_content_to": "Преместване на съдържанието в",
        "move_to": "Преместване към",
        "chat_request": "Заявка за чат",
        "accept_request": "Приемане на заявлението",
        "go_to_chat": "Отидете в чата",
        "applying": "Прилагане на",
        "time": "Време",
        "adjust": "Регулиране",
        "rotate": "Въртене",
        "start": "Старт",
        "end": "Край",
        "lang_name": "Bulgarian"
    },
    "ZHH": {
        "name": "名字",
        "name_example": "Chi",
        "last_name": "姓",
        "last_name_example": "Chan",
        "username": "用戶",
        "username_example": "chi_chan",
        "email": "電子郵件",
        "email_example": "用戶@域.com",
        "password": "密碼",
        "new_password": "新密碼",
        "password_example": "*****************",
        "login": "登錄",
        "register": "註冊",
        "verification_code": "驗證碼",
        "verification_code_example": "xxx",
        "verify": "驗證",
        "loading": "裝載…",
        "please_wait": "請稍候",
        "go_back": "返去",
        "recovery": "取回",
        "login_subtitle": "輸入您的詳細信息並登錄您的帳戶。",
        "forgot_password_link": "忘記密碼？ 檢索它。",
        "register_account_link": "冇账户？ 註冊！",
        "register_subtitle": "輸入您的詳細信息並註冊您的帳戶。",
        "login_link": "已經有賬戶了？ 日誌！",
        "verification_code_instruction": "檢查您的電子郵件並輸入您發送的驗證碼。",
        "forgot_password_subtitle": "輸入您的電子郵件以檢索您的密碼。",
        "change_password_subtitle": "輸入新密碼。",
        "change_password_code_used": "密碼恢復代碼無效或已被使用。",
        "no_chats": "你冇對話。",
        "chats": "聊天",
        "groups": "組",
        "rooms": "房間",
        "save": "救",
        "download": "下載",
        "downloading_image": "下載圖片",
        "an_error_has_ocurred": "發生錯誤",
        "saving_changes": "保存更改",
        "go_back_to_edit": "重新編輯",
        "upload_warning_modal": "上傳編輯內容將替換原始圖像。",
        "upload_question_modal": "你想保留原件並創建副本啊？",
        "create_copy_and_keep": "創建副本並保留",
        "upload": "上傳",
        "upload_changes": "上傳更改",
        "changes_saved": "保存嘅更改",
        "downloading_video": "下載視頻",
        "video_upload_warning_modal": "上傳編輯內容將替換原始視頻。",
        "results": "結果",
        "members": "四肢",
        "cancel": "取消",
        "add_user": "添加用戶",
        "no_messages": "暫無消息",
        "message_placeholder": "消息…",
        "group_doesnt_exists": "該組唔存在",
        "you_arent_group_member": "你唔係該組嘅成員",
        "touch_to_see_more_info": "點擊以查看更多信息。",
        "start_videocall": "發起視頻通話",
        "start_call": "開始通話",
        "create_group": "創建組",
        "group_name": "組名稱",
        "add_members_to_create_group": "添加成員以創建組",
        "search": "搜尋",
        "creating": "創建…",
        "dont_belong_anyone_group": "你唔屬於任何團體。",
        "already_add_this_hashtag": "你有添加該主題標籤",
        "search_files_by_hashtags": "按主題標籤搜索文件",
        "enter_to_add": "輸入以添加",
        "hashtags_most_used": "最常用嘅主題標籤",
        "can_add_it_giving_click": "你可以透過單擊它們把它們添加到您的搜索中",
        "searching_files": "搜索文件",
        "no_files_found": "未搵到文件",
        "image": "圖像",
        "video": "視頻",
        "audio": "音頻",
        "library": "書店",
        "home": "主頁",
        "block": "蚊 ",
        "unblock": "疏通",
        "has_blocked_you": "佢屙黑咗你",
        "accept": "接受",
        "decline": "拒絕",
        "cancel_request": "取消請求",
        "has_been_expulsed": "已被開除",
        "room_doesnt_exists": "房間唔存在",
        "you_arent_room_member": "你唔係聊天室嘅成員",
        "create_room": "創建房間",
        "room_name": "房間名稱",
        "no_rooms_created": "冇創建任何聊天室。",
        "dont_belong_to_anyone_room": "你唔屬於任何房間。",
        "enter_a_username": "鍵入用戶",
        "no_results": "沒有結果",
        "create": "創造",
        "no_content_uploaded": "未上傳內容",
        "new_subtag": "新建子標籤",
        "update_info": "更新信息",
        "updating": "更新",
        "update": "更新",
        "update_email": "更新電子郵件",
        "change_password": "更改密碼",
        "current_password": "當前密碼",
        "select": "選擇",
        "tap_to_upload_a_file": "點擊上傳文件",
        "edit_photo": "編輯照片",
        "preview": "預覽",
        "edit_video": "編輯視頻",
        "delete_changes": "刪除更改",
        "uploading": "上傳",
        "session_deleted_or_expired": "已刪除或過期的會話",
        "edit_profile": "編輯個人資料",
        "devices": "設備",
        "downloads": "下載",
        "logout": "登出",
        "offline": "離線",
        "images": "成像",
        "videos": "視頻",
        "audios": "音頻",
        "chat": "聊天",
        "session_unauthorized": "您的會話未獲得授權。",
        "authorize_session": "要訪問此會話，您需要授權您的設備。",
        "authorize_instructions": "檢查電子郵件中嘅授權連結或轉到活動會話並選擇右上角嘅三個啲，然後轉到“設備”並選擇“授權”。",
        "refresh_page_after_authorize": "完成授權過程之後，請刷新頁面以訪問會話。",
        "check_your_inbox": "檢查您的電子郵件",
        "send_authorization_email": "發送授權電子郵件",
        "call_finished": "通話結束",
        "close": "關閉",
        "waiting_stream": "等待傳輸",
        "request_camera_and_mic": "我們需要訪問您的攝像頭和麥克風，以便您可以撥打電話。",
        "call_in_progress": "通話進行中",
        "group": "群",
        "videocall": "視頻通話",
        "call": "叫",
        "connecting": "連接",
        "call_cancelled": "通話已取消",
        "calling": "叫",
        "hangup": "掛",
        "reject": "拒絕",
        "has_sent_a_file": "你已提交文件。",
        "keep": "保持",
        "keep_file": "如果啟用保留文件，它們將保存喺應用程序中。 將允許你喺唔需要互聯網連接嘅情況下訪問它。",
        "not_keep_file": "如果您禁用文件維護，它將被刪除，並且你將無法喺冇Internet連接嘅情況下訪問它。",
        "file_stored_instruction": "所有存儲嘅文件喺通用文件列表中都會有一個綠色邊框。 您也可以在：",
        "stored": "數據處理",
        "not_stored": "不在存儲中",
        "file_not_stored_instruction": "如果冇Internet連接，你將無法訪問此文件。",
        "device": "裝置",
        "file_visibility": "文件可見性",
        "visible_for_everyone": "人人可見",
        "shared_link": "共享連結",
        "hashtags": "主題標籤",
        "hashtags_helps_to_search_your_file": "主題標籤可幫助你搜索文件。",
        "edit": "編輯",
        "move": "移動",
        "rename": "重命名",
        "visibility": "能見度",
        "delete": "消除",
        "file_warn_delete": "是否確實要刪除該文件？",
        "no_subtags_availables": "沒有可用的子標籤",
        "owner": "造物主",
        "expulsing": "驅逐",
        "expulse": "開除",
        "you_have_left_the_group": "你已退出群組",
        "coming_out": "離開",
        "leave_group": "退出群組",
        "you_have_left_the_room": "你有離開房間。",
        "leave_room": "離開房間",
        "message_deleted": "已刪除嘅郵件",
        "has_been_error_deleting_message": "刪除郵件時出錯",
        "deleting": "消除",
        "new_tag": "新標籤",
        "photoEditor": {
            "name": "名字",
            "save": "完",
            "saveAs": "另存為",
            "back": "返回",
            "loading": "裝載…",
            "resetOperations": "重新啟動/刪除所有操作。",
            "changesLoseConfirmation": "所有更改都將被刪除。",
            "changesLoseConfirmationHint": "你確定要繼續啊？",
            "cancel": "取消",
            "continue": "繼續",
            "undoTitle": "撤消上一個操作",
            "redoTitle": "重做上一個操作",
            "showImageTitle": "顯示原始圖像",
            "zoomInTitle": "放大",
            "zoomOutTitle": "縮小",
            "toggleZoomMenuTitle": "切換縮放餐牌",
            "adjustTab": "調整",
            "finetuneTab": "顏色",
            "filtersTab": "過濾器",
            "watermarkTab": "成像",
            "annotateTab": "畫",
            "resize": "調整",
            "resizeTab": "大小",
            "invalidImageError": "無效圖像",
            "uploadImageError": "加載圖像時發生錯誤。",
            "areNotImages": "它們唔係圖像",
            "isNotImage": "它唔係圖像",
            "toBeUploaded": "待上傳",
            "cropTool": "切",
            "original": "源語言",
            "custom": "習慣",
            "square": "廣場",
            "landscape": "水平",
            "portrait": "垂直",
            "ellipse": "圈",
            "classicTv": "經典電視",
            "cinemascope": "電影鏡",
            "arrowTool": "箭",
            "blurTool": "模糊",
            "brightnessTool": "亮度",
            "contrastTool": "反差",
            "ellipseTool": "圈",
            "unFlipX": "取消翻轉X",
            "flipX": "翻轉X",
            "unFlipY": "取消翻轉Y",
            "flipY": "翻轉Y",
            "hsvTool": "MSV",
            "hue": "色調",
            "saturation": "飽和",
            "value": "價值",
            "imageTool": "圖像",
            "importing": "進口…",
            "addImage": "+添加圖",
            "uploadImage": "上傳圖片",
            "fromGallery": "貼 紙 ",
            "lineTool": "線",
            "penTool": "鉛筆",
            "polygonTool": "多邊形",
            "sides": "雙方",
            "rectangleTool": "矩形",
            "cornerRadius": "圓角半徑",
            "resizeWidthTitle": "像素寬度",
            "resizeHeightTitle": "像素高度",
            "toggleRatioLockTitle": "切換維度鎖定",
            "reset": "重新啟動",
            "resetSize": "把圖像重置為原始大小",
            "rotateTool": "旋轉",
            "textTool": "發短信",
            "textSpacings": "文本間距",
            "textAlignment": "文本對正方式",
            "fontFamily": "字體家族",
            "size": "大小",
            "letterSpacing": "字母間距",
            "lineHeight": "行高",
            "warmthTool": "熱",
            "addWatermark": "+添加圖",
            "addWatermarkTitle": "選擇水印類型",
            "uploadWatermark": "選擇圖片",
            "addWatermarkAsText": "添加文本",
            "padding": "填充",
            "shadow": "影子",
            "horizontal": "水平",
            "vertical": "垂直",
            "blur": "模糊",
            "opacity": "唔透明度",
            "position": "位置",
            "stroke": "厚度",
            "saveAsModalLabel": "詳",
            "extension": "外延",
            "nameIsRequired": "姓名為必填項。",
            "quality": "質量",
            "imageDimensionsHoverTitle": "保存嘅圖像尺寸（寬x高）",
            "cropSizeLowerThanResizedWarning": "請注意，所選裁剪面積小於應用嘅調整大小，可能會導致質量損失。",
            "actualSize": "當前大小（100%）",
            "fitSize": "調整尺寸",
            "addImageTitle": "選擇要添加嘅圖…"
        },
        "changing": "改變",
        "profile_picture_changed": "更改了個人資料圖片",
        "loading_profile_picture": "上傳頭像",
        "change": "改變",
        "session": "會期",
        "system_operative": "操作系統",
        "navigator": "瀏覽器",
        "authorize": "授權",
        "deauthorize": "拒絕",
        "keep_tag_content": "是否要保留標籤嘅内容？",
        "move_content_to": "將內容移動到",
        "move_to": "移至",
        "chat_request": "聊天請求",
        "accept_request": "接受請求",
        "go_to_chat": "去傾偈",
        "applying": "應用",
        "time": "時間",
        "adjust": "調整",
        "rotate": "旋轉",
        "start": "開始",
        "end": "最後",
        "lang_name": "Cantonese"
    },
    "ca": {
        "name": "Nom",
        "name_example": "Antonio",
        "last_name": "Cognom",
        "last_name_example": "Vila",
        "username": "Usuari",
        "username_example": "antonio_vila",
        "email": "Correu electrònic",
        "email_example": "usuari@domini.com",
        "password": "Contrasenya",
        "new_password": "Nova contrasenya",
        "password_example": "*****************",
        "login": "Iniciar Sessió",
        "register": "Registrar-te",
        "verification_code": "Codi de verificació",
        "verification_code_example": "xxx",
        "verify": "Verificar",
        "loading": "Carregant…",
        "please_wait": "Espereu si us plau",
        "go_back": "Tornar",
        "recovery": "Recuperar",
        "login_subtitle": "Introdueix les teves dades i accedeix al teu compte.",
        "forgot_password_link": "Has oblidat la contrasenya? Recuperar.",
        "register_account_link": "No tens un compte? Registra't!",
        "register_subtitle": "Introdueix les teves dades i registra el teu compte.",
        "login_link": "Ja tens un compte? Inicia sessió!",
        "verification_code_instruction": "Revisa el teu correu electrònic i introdueix el codi enviat.",
        "forgot_password_subtitle": "Introdueix el teu correu electrònic per recuperar la contrasenya.",
        "change_password_subtitle": "Introduïu una contrasenya nova.",
        "change_password_code_used": "El codi per recuperar la contrasenya és invàlid o ja va ser usat.",
        "no_chats": "No tens converses.",
        "chats": "Conversaciones",
        "groups": "Grups",
        "rooms": "Sales",
        "save": "Desa",
        "download": "Descarregar",
        "downloading_image": "Descarregant imatge",
        "an_error_has_ocurred": "Ha passat un error",
        "saving_changes": "Desant canvis",
        "go_back_to_edit": "Tornar a editar",
        "upload_warning_modal": "En pujar l'edició es reemplaçarà la imatge original.",
        "upload_question_modal": "Voleu conservar l'original i crear-ne una còpia?",
        "create_copy_and_keep": "Crear còpia i conservar",
        "upload": "Pujar",
        "upload_changes": "Pujar canvis",
        "changes_saved": "Canvis desats",
        "downloading_video": "Descarregant vídeo",
        "video_upload_warning_modal": "En pujar l'edició es reemplaçarà el vídeo original.",
        "results": "Resultats",
        "members": "Membres",
        "cancel": "Cancel·la",
        "add_user": "Afegir usuari",
        "no_messages": "No hi ha missatges",
        "message_placeholder": "Missatge…",
        "group_doesnt_exists": "El grup no existeix",
        "you_arent_group_member": "No ets membre del grup",
        "touch_to_see_more_info": "Toca per veure més info.",
        "start_videocall": "Iniciar videotrucada",
        "start_call": "Iniciar trucada",
        "create_group": "Crear grup",
        "group_name": "Nom del grup",
        "add_members_to_create_group": "Afegeix membres per crear el grup",
        "search": "Cerca",
        "creating": "Creant…",
        "dont_belong_anyone_group": "No pertanys a cap grup.",
        "already_add_this_hashtag": "Ja vas afegir aquest hashtag",
        "search_files_by_hashtags": "Buscar fitxers per hashtags",
        "enter_to_add": "Enter per afegir",
        "hashtags_most_used": "Hashtags més usats",
        "can_add_it_giving_click": "Pots afegir aquests a la teva cerca fent-los click",
        "searching_files": "Cercant fitxers",
        "no_files_found": "No s'han trobat fitxers",
        "image": "Imatge",
        "video": "Vídeo",
        "audio": "Àudio",
        "library": "Llibreria",
        "home": "Inici",
        "block": "Bloquejar",
        "unblock": "Desbloquejar",
        "has_blocked_you": "T'ha bloquejat",
        "accept": "Acceptar",
        "decline": "Rebutjar",
        "cancel_request": "Cancel·lar sol·licitud",
        "has_been_expulsed": "ha estat expulsat",
        "room_doesnt_exists": "La sala no existeix",
        "you_arent_room_member": "No ets membre de la sala",
        "create_room": "Crear sala",
        "room_name": "Nom de la sala",
        "no_rooms_created": "No hi ha sales creades.",
        "dont_belong_to_anyone_room": "No pertanys a cap sala.",
        "enter_a_username": "Escriu un usuari",
        "no_results": "No hi ha resultats",
        "create": "Crear",
        "no_content_uploaded": "No hi ha contingut pujat",
        "new_subtag": "Nou subtag",
        "update_info": "Actualitzar informació",
        "updating": "Actualitzant",
        "update": "Actualitzar",
        "update_email": "Actualitzar email",
        "change_password": "Canvia contrasenya",
        "current_password": "Contrasenya actual",
        "select": "Seleccionar",
        "tap_to_upload_a_file": "Toca per carregar un fitxer",
        "edit_photo": "Editar foto",
        "preview": "Vista prèvia",
        "edit_video": "Editar vídeo",
        "delete_changes": "Eliminar canvis",
        "uploading": "Pujant",
        "session_deleted_or_expired": "Sessió eliminada o expirada",
        "edit_profile": "Editar perfil",
        "devices": "Dispositius",
        "downloads": "Descàrregues",
        "logout": "Tancar sessió",
        "offline": "Sense connexió",
        "images": "Imatges",
        "videos": "Vídeos",
        "audios": "Àudios",
        "chat": "Xat",
        "session_unauthorized": "La vostra sessió no està autoritzada.",
        "authorize_session": "Per accedir a aquesta sessió, cal autoritzar el vostre dispositiu.",
        "authorize_instructions": "Si us plau, revisa el teu correu electrònic per trobar l'enllaç d'autorització o dirigeix-te a una sessió activa i selecciona els tres punts a la cantonada superior dreta, després vés a \"Dispositius\" i escull \"Autoritzar\".",
        "refresh_page_after_authorize": "Quan hagueu completat el procés d'autorització, si us plau refresca la pàgina per accedir a la sessió.",
        "check_your_inbox": "Revisa el teu correu electrònic",
        "send_authorization_email": "Enviar correu d'autorització",
        "call_finished": "Trucada finalitzada",
        "close": "Tancar",
        "waiting_stream": "Esperant transmissió",
        "request_camera_and_mic": "Necessitem accedir a la teva càmera i micròfon perquè pugui fer trucades.",
        "call_in_progress": "Trucada en curs",
        "group": "Grup",
        "videocall": "Videotrucada",
        "call": "Trucada",
        "connecting": "Connectant",
        "call_cancelled": "Trucada cancel·lada",
        "calling": "Trucant",
        "hangup": "Penjar",
        "reject": "Rebutjar",
        "has_sent_a_file": "ha enviat un fitxer.",
        "keep": "Conservar",
        "keep_file": "Si habiliteu conservar el fitxer, aquest es desarà a l'aplicació. Això permetrà la seva accessibilitat sense que necessitis connexió a internet.",
        "not_keep_file": "Si desactiveu conservar el fitxer, aquest s'eliminarà i no podreu accedir-hi sense connexió a internet.",
        "file_stored_instruction": "Tots els fitxers emmagatzemats apareixeran amb una vora verda a la llista comuna de fitxers. També pots veure'ls a la secció de",
        "stored": "Emmagatzemat",
        "not_stored": "No està emmagatzemat",
        "file_not_stored_instruction": "No podràs accedir a aquest fitxer sense connexió a internet.",
        "device": "Dispositiu",
        "file_visibility": "Visibilitat de l'arxiu",
        "visible_for_everyone": "Visible per a tothom",
        "shared_link": "Enllaç compartit",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Els hashtags ajuden a la cerca dels teus fitxers.",
        "edit": "Edita",
        "move": "Moure",
        "rename": "Reanomenar",
        "visibility": "Visibilitat",
        "delete": "Eliminar",
        "file_warn_delete": "Esteu segur que voleu suprimir el fitxer?",
        "no_subtags_availables": "No hi ha subtags disponibles",
        "owner": "Creador",
        "expulsing": "Expulsant",
        "expulse": "Expulsar",
        "you_have_left_the_group": "Has sortit del grup",
        "coming_out": "Sortint",
        "leave_group": "Sortir del grup",
        "you_have_left_the_room": "Has sortit de la sala.",
        "leave_room": "Sortir de la sala",
        "message_deleted": "Missatge eliminat",
        "has_been_error_deleting_message": "S'ha produït un error eliminant el missatge",
        "deleting": "Eliminant",
        "new_tag": "Nou tag",
        "photoEditor": {
            "name": "Nom",
            "save": "Finalitzar",
            "saveAs": "Desa com",
            "back": "Enrere",
            "loading": "Carregant…",
            "resetOperations": "Reiniciar/Eliminar totes les operacions.",
            "changesLoseConfirmation": "Tots els canvis seran eliminats.",
            "changesLoseConfirmationHint": "Estàs segur que vols continuar?",
            "cancel": "Cancel·la",
            "continue": "Continuar",
            "undoTitle": "Desfer darrera operació",
            "redoTitle": "Refer darrera operació",
            "showImageTitle": "Mostra imatge original",
            "zoomInTitle": "Apropar",
            "zoomOutTitle": "Allunya",
            "toggleZoomMenuTitle": "Alternar menú de zoom",
            "adjustTab": "Ajustar",
            "finetuneTab": "Colors",
            "filtersTab": "Filtres",
            "watermarkTab": "Imatges",
            "annotateTab": "Dibuixar",
            "resize": "Redimensionar",
            "resizeTab": "Grandària",
            "invalidImageError": "Imatge invàlida",
            "uploadImageError": "S'ha produït un error mentre es carregava la imatge.",
            "areNotImages": "no són imatges",
            "isNotImage": "no és una imatge",
            "toBeUploaded": "per ser pujat",
            "cropTool": "Tallar",
            "original": "Original",
            "custom": "Personalitzat",
            "square": "Quadrat",
            "landscape": "Horitzontal",
            "portrait": "Vertical",
            "ellipse": "Cercle",
            "classicTv": "Tv clàssica",
            "cinemascope": "Cinemascopi",
            "arrowTool": "Fletxa",
            "blurTool": "Desenfocament",
            "brightnessTool": "Brillantor",
            "contrastTool": "Contrast",
            "ellipseTool": "Cercle",
            "unFlipX": "Desvoltejar X",
            "flipX": "Voltejar X",
            "unFlipY": "Desvoltejar Y",
            "flipY": "Voltejar Y",
            "hsvTool": "MSV",
            "hue": "Matís",
            "saturation": "Saturació",
            "value": "Valor",
            "imageTool": "Imatge",
            "importing": "Important…",
            "addImage": "+ Afegeix imatge",
            "uploadImage": "Pujar imatge",
            "fromGallery": "Stickers",
            "lineTool": "Línia",
            "penTool": "Llapis",
            "polygonTool": "Polígon",
            "sides": "Costats",
            "rectangleTool": "Rectangle",
            "cornerRadius": "Ràdio de les vores",
            "resizeWidthTitle": "Ample en píxels",
            "resizeHeightTitle": "Alt en píxels",
            "toggleRatioLockTitle": "Alternar bloqueig de dimensió",
            "reset": "Reiniciar",
            "resetSize": "Reiniciar les dimensions originals de la imatge",
            "rotateTool": "Rotar",
            "textTool": "Text",
            "textSpacings": "Espaiat de text",
            "textAlignment": "Alineació del text",
            "fontFamily": "Font",
            "size": "Grandària",
            "letterSpacing": "Espaiat de lletres",
            "lineHeight": "Alçada de la línia",
            "warmthTool": "Calor",
            "addWatermark": "+ Afegir imatge",
            "addWatermarkTitle": "Trieu el tipus de marca d'aigua",
            "uploadWatermark": "Seleccionar imatge",
            "addWatermarkAsText": "Afegir text",
            "padding": "Encoixinat",
            "shadow": "Ombra",
            "horizontal": "Horitzontal",
            "vertical": "Vertical",
            "blur": "Desenfocament",
            "opacity": "Opacitat",
            "position": "Posició",
            "stroke": "Gruix",
            "saveAsModalLabel": "Detalls",
            "extension": "Extensió",
            "nameIsRequired": "El nom és requerit.",
            "quality": "Qualitat",
            "imageDimensionsHoverTitle": "Dimensions de la imatge guardades (ample x alt)",
            "cropSizeLowerThanResizedWarning": "Tingueu en compte que l'àrea de retallada seleccionada és menor que el canvi de mida aplicada, cosa que podria causar una disminució de la qualitat.",
            "actualSize": "Grandària actual (100%)",
            "fitSize": "Adaptar dimensió",
            "addImageTitle": "Seleccioneu una imatge per afegir…"
        },
        "changing": "Canviant",
        "profile_picture_changed": "Foto de perfil canviada",
        "loading_profile_picture": "Carregant foto de perfil",
        "change": "Canviar",
        "session": "Sessió",
        "system_operative": "Sistema operatiu",
        "navigator": "Navegador",
        "authorize": "Autoritzar",
        "deauthorize": "Desautoritzar",
        "keep_tag_content": "Vols conservar el contingut del tag?",
        "move_content_to": "Moure contingut a",
        "move_to": "Moure a",
        "chat_request": "Sol·licitud de xat",
        "accept_request": "Acceptar sol·licitud",
        "go_to_chat": "Anar al xat",
        "applying": "Aplicant",
        "time": "Temps",
        "adjust": "Ajustar",
        "rotate": "Rotar",
        "start": "Inici",
        "end": "Final",
        "lang_name": "Catalan"
    },
    "zh": {
        "name": "姓名",
        "name_example": "Shuzhen",
        "last_name": "姓",
        "last_name_example": "Chen",
        "username": "使用者",
        "username_example": "shuzhen_chen",
        "email": "電子郵件",
        "email_example": "使用者@領域.com",
        "password": "密碼",
        "new_password": "新密碼",
        "password_example": "*****************",
        "login": "登入",
        "register": "登記",
        "verification_code": "驗證碼",
        "verification_code_example": "xxx",
        "verify": "核實",
        "loading": "載入中…",
        "please_wait": "請稍等",
        "go_back": "回去",
        "recovery": "恢復",
        "login_subtitle": "輸入您的詳細資訊並存取您的帳戶。",
        "forgot_password_link": "你忘了你的密碼？恢復。",
        "register_account_link": "您還沒有帳戶嗎？登記！",
        "register_subtitle": "輸入您的詳細資料並註冊您的帳戶。",
        "login_link": "你是不是已經有帳號？登入!",
        "verification_code_instruction": "檢查您的電子郵件並輸入發送的代碼。",
        "forgot_password_subtitle": "輸入您的電子郵件以恢復您的密碼。",
        "change_password_subtitle": "輸入新的密碼。",
        "change_password_code_used": "用於恢復密碼的代碼無效或已被使用。",
        "no_chats": "你們沒有對話。",
        "chats": "交談",
        "groups": "團體",
        "rooms": "客房",
        "save": "保持",
        "download": "下載",
        "downloading_image": "下載圖片",
        "an_error_has_ocurred": "發生了錯誤",
        "saving_changes": "儲存變更",
        "go_back_to_edit": "再次編輯",
        "upload_warning_modal": "上傳編輯後的圖像將替換原始圖像。",
        "upload_question_modal": "您想保留原件並建立副本嗎？",
        "create_copy_and_keep": "建立副本並保留",
        "upload": "上傳",
        "upload_changes": "上傳更改",
        "changes_saved": "更改已儲存",
        "downloading_video": "下載影片",
        "video_upload_warning_modal": "上傳編輯後的影片將替換原始影片。",
        "results": "結果",
        "members": "會員",
        "cancel": "取消",
        "add_user": "新增用戶",
        "no_messages": "沒有消息",
        "message_placeholder": "訊息…",
        "group_doesnt_exists": "該群組不存在",
        "you_arent_group_member": "您不是該群組的成員",
        "touch_to_see_more_info": "點按即可查看更多資訊。",
        "start_videocall": "開始視訊通話",
        "start_call": "開始通話",
        "create_group": "建立群組",
        "group_name": "團隊名字",
        "add_members_to_create_group": "新增成員以建立群組",
        "search": "尋找",
        "creating": "創造…",
        "dont_belong_anyone_group": "你不屬於任何團體。",
        "already_add_this_hashtag": "您已經新增了該主題標籤",
        "search_files_by_hashtags": "透過主題標籤搜尋文件",
        "enter_to_add": "輸入新增",
        "hashtags_most_used": "最常用的主題標籤",
        "can_add_it_giving_click": "您可以透過點擊將它們添加到您的搜尋中",
        "searching_files": "搜尋文件",
        "no_files_found": "沒有找到文件",
        "image": "影像",
        "video": "影片",
        "audio": "聲音的",
        "library": "書店",
        "home": "首頁",
        "block": "堵塞",
        "unblock": "開鎖",
        "has_blocked_you": "他已經屏蔽你了",
        "accept": "接受",
        "decline": "衰退",
        "cancel_request": "取消請求",
        "has_been_expulsed": "已被開除",
        "room_doesnt_exists": "房間不存在",
        "you_arent_room_member": "您不是該房間的成員",
        "create_room": "創建房間",
        "room_name": "房間名稱",
        "no_rooms_created": "沒有創建任何房間。",
        "dont_belong_to_anyone_room": "你不屬於任何房間。",
        "enter_a_username": "寫一個用戶",
        "no_results": "沒有結果",
        "create": "創造",
        "no_content_uploaded": "沒有上傳內容",
        "new_subtag": "新子標籤",
        "update_info": "更新訊息",
        "updating": "更新中",
        "update": "更新",
        "update_email": "更新電子郵件",
        "change_password": "更改密碼",
        "current_password": "目前密碼",
        "select": "選擇",
        "tap_to_upload_a_file": "點擊上傳文件",
        "edit_photo": "修改照片",
        "preview": "預覽",
        "edit_video": "編輯影片",
        "delete_changes": "刪除更改",
        "uploading": "上傳中",
        "session_deleted_or_expired": "會話已刪除或過期",
        "edit_profile": "編輯個人資料",
        "devices": "裝置",
        "downloads": "下載",
        "logout": "擱筆",
        "offline": "無連接",
        "images": "圖片",
        "videos": "影片",
        "audios": "聲音的",
        "chat": "聊天",
        "session_unauthorized": "您的會話未獲得授權。",
        "authorize_session": "要存取此會話，您需要授權您的裝置。",
        "authorize_instructions": "檢查您的電子郵件中的授權鏈接，或轉到活動會話並選擇右上角的三個點，然後轉到“設備”並選擇“授權”。",
        "refresh_page_after_authorize": "完成授權流程後，刷新頁面即可存取會話。",
        "check_your_inbox": "查看你的電子郵件",
        "send_authorization_email": "發送授權郵件",
        "call_finished": "通話結束",
        "close": "關閉",
        "waiting_stream": "等待傳輸",
        "request_camera_and_mic": "我們需要存取您的攝影機和麥克風，以便您可以撥打電話。",
        "call_in_progress": "通話正在進行中",
        "group": "簇",
        "videocall": "視訊通話",
        "call": "稱呼",
        "connecting": "正在連接",
        "call_cancelled": "通話已取消",
        "calling": "呼喚",
        "hangup": "掛斷",
        "reject": "衰退",
        "has_sent_a_file": "已發送文件。",
        "keep": "保持",
        "keep_file": "如果您啟用檔案保留，它將保存在應用程式中。這將允許您無需互聯網連接即可訪問它。",
        "not_keep_file": "如果您關閉檔案保留功能，該檔案將會被刪除，並且您將無法在沒有 Internet 連線的情況下存取該檔案。",
        "file_stored_instruction": "所有儲存的文件將在公共文件清單中顯示為帶有綠色邊框。您也可以在 部分中看到它們",
        "stored": "已儲存",
        "not_stored": "未儲存",
        "file_not_stored_instruction": "如果沒有網路連接，您將無法存取此文件。",
        "device": "裝置",
        "file_visibility": "文件可見性",
        "visible_for_everyone": "所有人可見",
        "shared_link": "共享連結",
        "hashtags": "標籤",
        "hashtags_helps_to_search_your_file": "主題標籤可協助您尋找文件。",
        "edit": "編輯",
        "move": "移動",
        "rename": "改名",
        "visibility": "能見度",
        "delete": "刪除",
        "file_warn_delete": "您確定要刪除該檔案嗎？",
        "no_subtags_availables": "沒有可用的子標籤",
        "owner": "創作者",
        "expulsing": "驅逐",
        "expulse": "驅逐",
        "you_have_left_the_group": "您已退出群組",
        "coming_out": "出來",
        "leave_group": "離開群組",
        "you_have_left_the_room": "你已經離開房間了。",
        "leave_room": "離開這個房間",
        "message_deleted": "已刪除的訊息",
        "has_been_error_deleting_message": "刪除訊息時出錯",
        "deleting": "去除",
        "new_tag": "新標籤",
        "photoEditor": {
            "name": "姓名",
            "save": "結束",
            "saveAs": "另存為",
            "back": "後退",
            "loading": "載入中…",
            "resetOperations": "重新啟動/刪除所有操作。",
            "changesLoseConfirmation": "所有更改都將被刪除。",
            "changesLoseConfirmationHint": "你確定你要繼續嗎？",
            "cancel": "取消",
            "continue": "繼續",
            "undoTitle": "撤銷上次操作",
            "redoTitle": "重做上次操作",
            "showImageTitle": "顯示原始影像",
            "zoomInTitle": "放大",
            "zoomOutTitle": "縮小",
            "toggleZoomMenuTitle": "切換縮放選單",
            "adjustTab": "調整",
            "finetuneTab": "顏色",
            "filtersTab": "過濾器",
            "watermarkTab": "圖片",
            "annotateTab": "畫",
            "resize": "調整大小",
            "resizeTab": "尺寸",
            "invalidImageError": "圖片無效",
            "uploadImageError": "載入圖片時發生錯誤。",
            "areNotImages": "它們不是圖像",
            "isNotImage": "這不是圖像",
            "toBeUploaded": "待上傳",
            "cropTool": "切",
            "original": "原來的",
            "custom": "風俗",
            "square": "方塊",
            "landscape": "水平的",
            "portrait": "垂直的",
            "ellipse": "圓圈",
            "classicTv": "經典電視",
            "cinemascope": "寬銀幕電影",
            "arrowTool": "箭",
            "blurTool": "模糊",
            "brightnessTool": "輝光",
            "contrastTool": "對比",
            "ellipseTool": "圓圈",
            "unFlipX": "展開 X",
            "flipX": "翻轉X",
            "unFlipY": "展開 Y",
            "flipY": "翻轉 Y",
            "hsvTool": "MSV",
            "hue": "色調",
            "saturation": "飽和",
            "value": "值得",
            "imageTool": "影像",
            "importing": "輸入…",
            "addImage": "+ 新增圖片",
            "uploadImage": "上傳圖片",
            "fromGallery": "貼紙",
            "lineTool": "線",
            "penTool": "鉛筆",
            "polygonTool": "多邊形",
            "sides": "側面",
            "rectangleTool": "長方形",
            "cornerRadius": "邊緣半徑",
            "resizeWidthTitle": "像素寬度",
            "resizeHeightTitle": "像素高度",
            "toggleRatioLockTitle": "切換尺寸鎖定",
            "reset": "重新開始",
            "resetSize": "重置為原始影像尺寸",
            "rotateTool": "旋轉",
            "textTool": "文字",
            "textSpacings": "文字間距",
            "textAlignment": "文字對齊",
            "fontFamily": "字體系列",
            "size": "尺寸",
            "letterSpacing": "字母間距",
            "lineHeight": "線高",
            "warmthTool": "溫暖",
            "addWatermark": "+ 新增圖片",
            "addWatermarkTitle": "選擇浮水印類型",
            "uploadWatermark": "選擇圖片",
            "addWatermarkAsText": "新增文字",
            "padding": "填充",
            "shadow": "遮蔭",
            "horizontal": "水平的",
            "vertical": "垂直的",
            "blur": "模糊",
            "opacity": "不透明度",
            "position": "位置",
            "stroke": "厚度",
            "saveAsModalLabel": "細節",
            "extension": "擴大",
            "nameIsRequired": "名稱為必填項。",
            "quality": "品質",
            "imageDimensionsHoverTitle": "已儲存的影像尺寸（寬 x 高）",
            "cropSizeLowerThanResizedWarning": "請注意，所選的裁剪區域小於應用的調整大小，這可能會導致品質下降。",
            "actualSize": "目前尺寸（100%）",
            "fitSize": "適應尺寸",
            "addImageTitle": "選擇要新增的圖像…"
        },
        "changing": "改變",
        "profile_picture_changed": "個人資料照片已更改",
        "loading_profile_picture": "正在載入個人資料照片",
        "change": "改變",
        "session": "會議",
        "system_operative": "作業系統",
        "navigator": "瀏覽器",
        "authorize": "授權",
        "deauthorize": "否認",
        "keep_tag_content": "您想保留標籤的內容嗎？",
        "move_content_to": "將內容移至",
        "move_to": "搬去",
        "chat_request": "聊天請求",
        "accept_request": "接受請求",
        "go_to_chat": "去聊天",
        "applying": "正在申請",
        "time": "時間",
        "adjust": "調整",
        "rotate": "旋轉",
        "start": "開始",
        "end": "結尾",
        "lang_name": "Taiwanese"
    },
    "hr": {
        "name": "Ime",
        "name_example": "Marija",
        "last_name": "Prezime",
        "last_name_example": "Knežević",
        "username": "Korisnik",
        "username_example": "Marija_knezevic",
        "email": "E-mail",
        "email_example": "korisnik@domena.com",
        "password": "Lozinka",
        "new_password": "Nova lozinka",
        "password_example": "*****************",
        "login": "Prijaviti se",
        "register": "Registar",
        "verification_code": "Kontrolni kod",
        "verification_code_example": "xxx",
        "verify": "Potvrdi",
        "loading": "Punjenje…",
        "please_wait": "Molimo pričekajte",
        "go_back": "Povratak",
        "recovery": "Oporavak",
        "login_subtitle": "Unesite svoje podatke i pristupite svom računu.",
        "forgot_password_link": "zaboravili ste lozinku? Oporavak.",
        "register_account_link": "nemate račun? Registar!",
        "register_subtitle": "Unesite svoje podatke i registrirajte svoj račun.",
        "login_link": "Već imate račun? Prijaviti se!",
        "verification_code_instruction": "Provjerite svoju e-poštu i unesite poslani kod.",
        "forgot_password_subtitle": "Unesite svoju e-poštu za oporavak lozinke.",
        "change_password_subtitle": "Upiši novu lozinku.",
        "change_password_code_used": "Vaš kod za oporavak lozinke nije valjan ili je već korišten.",
        "no_chats": "Nemaš razgovora.",
        "chats": "Razgovori",
        "groups": "grupe",
        "rooms": "Sobe",
        "save": "Zadržati",
        "download": "Pražnjenje",
        "downloading_image": "Preuzimanje slike",
        "an_error_has_ocurred": "Došlo je do pogreške",
        "saving_changes": "Spremanje promjena",
        "go_back_to_edit": "Ponovo uredite",
        "upload_warning_modal": "Prijenos promjene zamijenit će izvornu sliku.",
        "upload_question_modal": "Želite li zadržati izvornik i stvoriti kopiju?",
        "create_copy_and_keep": "Stvorite kopiju i zadržite",
        "upload": "Učitaj",
        "upload_changes": "Učitaj promjene",
        "changes_saved": "Promjene spremljene",
        "downloading_video": "Preuzimanje videa",
        "video_upload_warning_modal": "Uređeni prijenos zamijenit će izvorni videozapis.",
        "results": "Rezultati",
        "members": "članovi",
        "cancel": "Otkazati",
        "add_user": "Dodaj korisnika",
        "no_messages": "Nema poruka",
        "message_placeholder": "Poruka…",
        "group_doesnt_exists": "Grupa ne postoji",
        "you_arent_group_member": "Niste član grupe",
        "touch_to_see_more_info": "Dodirnite za više informacija.",
        "start_videocall": "Započnite video poziv",
        "start_call": "Započni poziv",
        "create_group": "Napraviti grupu",
        "group_name": "Grupno ime",
        "add_members_to_create_group": "Dodajte članove za stvaranje grupe",
        "search": "Tražiti",
        "creating": "Stvaranje…",
        "dont_belong_anyone_group": "Ne pripadate nijednoj grupi.",
        "already_add_this_hashtag": "Već ste dodali taj hashtag",
        "search_files_by_hashtags": "Pretražujte datoteke po hashtagovima",
        "enter_to_add": "Unesite za dodavanje",
        "hashtags_most_used": "Najčešće korišteni hashtagovi",
        "can_add_it_giving_click": "Možete ih dodati u svoju pretragu klikom na njih",
        "searching_files": "Pretraživanje datoteka",
        "no_files_found": "Nije pronađena nijedna datoteka",
        "image": "Slika",
        "video": "Video",
        "audio": "Audio",
        "library": "Knjižara",
        "home": "Početna stranica",
        "block": "Blok",
        "unblock": "Otključati",
        "has_blocked_you": "On vas je blokirao",
        "accept": "Prihvatiti",
        "decline": "Odbiti",
        "cancel_request": "Otkaži zahtjev",
        "has_been_expulsed": "je protjeran",
        "room_doesnt_exists": "Soba ne postoji",
        "you_arent_room_member": "Niste član sobe",
        "create_room": "Stvori sobu",
        "room_name": "Ime sobe",
        "no_rooms_created": "Nema kreiranih soba.",
        "dont_belong_to_anyone_room": "Ne pripadaš ni u jednu sobu.",
        "enter_a_username": "Napiši korisnika",
        "no_results": "Nema rezultata",
        "create": "Stvoriti",
        "no_content_uploaded": "Nema učitanog sadržaja",
        "new_subtag": "Nova podoznaka",
        "update_info": "Ažurirajte informacije",
        "updating": "Ažuriranje",
        "update": "Ažuriraj",
        "update_email": "Ažurirajte e-poštu",
        "change_password": "Promjena lozinke",
        "current_password": "Trenutna lozinka",
        "select": "Izaberi",
        "tap_to_upload_a_file": "Dodirnite za prijenos datoteke",
        "edit_photo": "Editar la foto",
        "preview": "Pregled",
        "edit_video": "Uredi video",
        "delete_changes": "Brisanje promjena",
        "uploading": "Prijenos",
        "session_deleted_or_expired": "Sesija je izbrisana ili istekla",
        "edit_profile": "Uredi profil",
        "devices": "Uređaji",
        "downloads": "Preuzimanja",
        "logout": "Odjavi se",
        "offline": "Offline",
        "images": "Slike",
        "videos": "Video zapisi",
        "audios": "audio zapisi",
        "chat": "razgovor",
        "session_unauthorized": "Vaša sesija nije autorizirana.",
        "authorize_session": "Morate autorizirati svoj uređaj za pristup ovoj sesiji.",
        "authorize_instructions": "Provjerite svoju e-poštu za vezu za autorizaciju ili idite na aktivnu sesiju i odaberite tri točkice u gornjem desnom kutu, zatim idite na \"Uređaji\" i odaberite \"Autoriziraj\".",
        "refresh_page_after_authorize": "Nakon dovršetka procesa autorizacije, osvježite stranicu za pristup sesiji.",
        "check_your_inbox": "Provjerite e-poštu",
        "send_authorization_email": "Pošalji e-poruku za autorizaciju",
        "call_finished": "Poziv završio",
        "close": "Zatvoriti",
        "waiting_stream": "Čeka prijenos",
        "request_camera_and_mic": "Trebamo pristup vašoj kameri i mikrofonu za upućivanje poziva.",
        "call_in_progress": "Poziv u tijeku",
        "group": "Klastera",
        "videocall": "Video poziv",
        "call": "Poziv",
        "connecting": "Povezivanje",
        "call_cancelled": "Poziv otkazan",
        "calling": "zovem",
        "hangup": "Objesiti",
        "reject": "Odbiti",
        "has_sent_a_file": "je poslao datoteku.",
        "keep": "Zadržati",
        "keep_file": "Ako omogućite spremanje datoteke, ona će biti spremljena u aplikaciji. To će omogućiti njegovu dostupnost bez potrebe za internetskom vezom.",
        "not_keep_file": "Ako onemogućite spremanje datoteke, ona će biti izbrisana i nećete joj moći pristupiti bez internetske veze.",
        "file_stored_instruction": "Sve pohranjene datoteke pojavit će se sa zelenim rubom na zajedničkom popisu datoteka. Također ih možete vidjeti u odjeljku",
        "stored": "Pohranjeno",
        "not_stored": "Nije pohranjeno",
        "file_not_stored_instruction": "Nećete moći pristupiti ovoj datoteci bez internetske veze.",
        "device": "Uređaj",
        "file_visibility": "Vidljivost datoteke",
        "visible_for_everyone": "Vidljivo za sve",
        "shared_link": "Podijeljena veza",
        "hashtags": "Hashtagovi",
        "hashtags_helps_to_search_your_file": "Hashtagovi vam pomažu pronaći svoje datoteke.",
        "edit": "Uredi",
        "move": "Potez",
        "rename": "Preimenovati",
        "visibility": "Vidljivost",
        "delete": "Eliminirati",
        "file_warn_delete": "Jeste li sigurni da želite izbrisati datoteku?",
        "no_subtags_availables": "Nema dostupnih podoznaka",
        "owner": "Stvoritelj",
        "expulsing": "protjerujući",
        "expulse": "Protjerati",
        "you_have_left_the_group": "Napustili ste grupu",
        "coming_out": "Izlazak",
        "leave_group": "Napusti grupu",
        "you_have_left_the_room": "Napustili ste sobu.",
        "leave_room": "Napusti sobu",
        "message_deleted": "Izbrisana poruka",
        "has_been_error_deleting_message": "Došlo je do pogreške prilikom brisanja poruke",
        "deleting": "Uklanjanje",
        "new_tag": "Nova oznaka",
        "photoEditor": {
            "name": "Ime",
            "save": "Uštedjeti",
            "saveAs": "Spremi kao",
            "back": "leđa",
            "loading": "Punjenje…",
            "resetOperations": "Ponovno pokretanje/brisanje svih operacija.",
            "changesLoseConfirmation": "Sve promjene će biti uklonjene.",
            "changesLoseConfirmationHint": "Jeste li sigurni da želite nastaviti?",
            "cancel": "Otkazati",
            "continue": "Nastaviti",
            "undoTitle": "Poništavanje zadnje operacije",
            "redoTitle": "Ponovi zadnju operaciju",
            "showImageTitle": "Prikaži originalnu sliku",
            "zoomInTitle": "Povećaj",
            "zoomOutTitle": "Odbiti",
            "toggleZoomMenuTitle": "Uključi/isključi izbornik zumiranja",
            "adjustTab": "prilagoditi",
            "finetuneTab": "Boje",
            "filtersTab": "Filteri",
            "watermarkTab": "Slike",
            "annotateTab": "crtati",
            "resize": "Promjena veličine",
            "resizeTab": "Veličina",
            "invalidImageError": "Nevažeća slika",
            "uploadImageError": "Došlo je do pogreške prilikom učitavanja slike.",
            "areNotImages": "Oni nisu slike",
            "isNotImage": "to nije slika",
            "toBeUploaded": "biti učitan",
            "cropTool": "ošišati",
            "original": "Izvornik",
            "custom": "Personalizirano",
            "square": "Kvadrat",
            "landscape": "Horizontalno",
            "portrait": "Okomito",
            "ellipse": "Krug",
            "classicTv": "klasični tv",
            "cinemascope": "Sinemaskop",
            "arrowTool": "Strijela",
            "blurTool": "Zamutiti",
            "brightnessTool": "Sjaj",
            "contrastTool": "Kontrast",
            "ellipseTool": "Krug",
            "unFlipX": "Odvrni X",
            "flipX": "Okreni X",
            "unFlipY": "Odvrni Y",
            "flipY": "Okreni Y",
            "hsvTool": "MSV",
            "hue": "Nijansa",
            "saturation": "Zasićenost",
            "value": "Vrijedan",
            "imageTool": "Slika",
            "importing": "Importando…",
            "addImage": "+ Dodaj sliku",
            "uploadImage": "Učitaj sliku",
            "fromGallery": "Naljepnice",
            "lineTool": "Crta",
            "penTool": "Olovka",
            "polygonTool": "Poligon",
            "sides": "strane",
            "rectangleTool": "Pravokutnik",
            "cornerRadius": "Polumjer ruba",
            "resizeWidthTitle": "Širina u pikselima",
            "resizeHeightTitle": "Visina piksela",
            "toggleRatioLockTitle": "Uključi/isključi zaključavanje dimenzija",
            "reset": "Resetiraj",
            "resetSize": "Vrati na izvorne dimenzije slike",
            "rotateTool": "Rotirati",
            "textTool": "Tekst",
            "textSpacings": "Razmak između teksta",
            "textAlignment": "Poravnanje teksta",
            "fontFamily": "Obitelj fontova",
            "size": "Veličina",
            "letterSpacing": "Razmak između slova",
            "lineHeight": "Visina linije",
            "warmthTool": "toplina",
            "addWatermark": "+ Dodaj sliku",
            "addWatermarkTitle": "Odaberite vrstu vodenog žiga",
            "uploadWatermark": "Odaberite sliku",
            "addWatermarkAsText": "Dodaj tekst",
            "padding": "Punjena",
            "shadow": "Hlad",
            "horizontal": "Horizontalno",
            "vertical": "Okomito",
            "blur": "Zamutiti",
            "opacity": "Neprozirnost",
            "position": "Položaj",
            "stroke": "Debljina",
            "saveAsModalLabel": "pojedinosti",
            "extension": "Proširenje",
            "nameIsRequired": "Ime je obavezno.",
            "quality": "Kvaliteta",
            "imageDimensionsHoverTitle": "Spremljene dimenzije slike (širina x visina)",
            "cropSizeLowerThanResizedWarning": "Imajte na umu da je odabrano područje obrezivanja manje od primijenjene promjene veličine, što može uzrokovati degradaciju kvalitete.",
            "actualSize": "Trenutna veličina (100%)",
            "fitSize": "Prilagodite dimenziju",
            "addImageTitle": "Odaberite sliku za dodavanje…"
        },
        "changing": "Mijenjanje",
        "profile_picture_changed": "Profilna slika promijenjena",
        "loading_profile_picture": "učitavanje profilne slike",
        "change": "Promijeniti",
        "session": "Sjednica",
        "system_operative": "OS",
        "navigator": "preglednik",
        "authorize": "Odobriti",
        "deauthorize": "Odreći",
        "keep_tag_content": "Želite li spremiti sadržaj knjižne oznake?",
        "move_content_to": "Premjesti sadržaj na",
        "move_to": "Premjesti u",
        "chat_request": "Zahtjev za chat",
        "accept_request": "Prihvati zahtjev",
        "go_to_chat": "Idi na chat",
        "applying": "primjenom",
        "time": "vrijeme",
        "adjust": "prilagoditi",
        "rotate": "Rotirati",
        "start": "početak",
        "end": "kraj",
        "lang_name": "Croatian"
    },
    "cs": {
        "name": "Název",
        "name_example": "Jan",
        "last_name": "Příjmení",
        "last_name_example": "Nováková",
        "username": "Uživatel",
        "username_example": "jan_nováková",
        "email": "E-mailová adresa",
        "email_example": "uživatel@doména.com",
        "password": "Heslo",
        "new_password": "Nové heslo",
        "password_example": "*****************",
        "login": "Přihlášení",
        "register": "Registrace",
        "verification_code": "Ověřovací kód",
        "verification_code_example": "xxx",
        "verify": "Ověřit",
        "loading": "Načítání…",
        "please_wait": "Počkejte prosím",
        "go_back": "Zpět na",
        "recovery": "Zotavení",
        "login_subtitle": "Zadejte své údaje a přihlaste se ke svému účtu.",
        "forgot_password_link": "Zapomněli jste heslo? Vyhledat.",
        "register_account_link": "Nemáte účet? Zaregistrujte se!",
        "register_subtitle": "Zadejte své údaje a zaregistrujte si účet.",
        "login_link": "Už máte účet? Přihlaste se!",
        "verification_code_instruction": "Zkontrolujte svou e-mailovou adresu a zadejte zaslaný kód.",
        "forgot_password_subtitle": "Pro získání hesla zadejte svou e-mailovou adresu.",
        "change_password_subtitle": "Zadejte nové heslo.",
        "change_password_code_used": "Kód pro obnovení hesla je neplatný nebo již byl použit.",
        "no_chats": "Nevedete rozhovory.",
        "chats": "Konverzace",
        "groups": "Skupiny",
        "rooms": "Pokoje",
        "save": "Uložit",
        "download": "Stáhnout",
        "downloading_image": "Stahování obrázku",
        "an_error_has_ocurred": "Došlo k chybě",
        "saving_changes": "Ukládání změn",
        "go_back_to_edit": "Zpět na editaci",
        "upload_warning_modal": "Nahrání úpravy nahradí původní obrázek.",
        "upload_question_modal": "Chcete si ponechat originál a vytvořit kopii?",
        "create_copy_and_keep": "Vytvořit kopii a uchovávat",
        "upload": "Nahrát",
        "upload_changes": "Nahrání změn",
        "changes_saved": "Uložené změny",
        "downloading_video": "Stahování videa",
        "video_upload_warning_modal": "Nahrání střihu nahradí původní video.",
        "results": "Výsledky",
        "members": "Členové",
        "cancel": "Zrušit",
        "add_user": "Přidat uživatele",
        "no_messages": "Žádné vzkazy",
        "message_placeholder": "Vzkaz…",
        "group_doesnt_exists": "Skupina neexistuje",
        "you_arent_group_member": "Nejste členem skupiny",
        "touch_to_see_more_info": "Klepnutím na zobrazíte další informace.",
        "start_videocall": "Zahájení videohovoru",
        "start_call": "Zahájení hovoru",
        "create_group": "Vytvořit skupinu",
        "group_name": "Název skupiny",
        "add_members_to_create_group": "Přidání členů pro vytvoření skupiny",
        "search": "Vyhledávání",
        "creating": "Vytvoření…",
        "dont_belong_anyone_group": "Nepatříte do žádné skupiny.",
        "already_add_this_hashtag": "Tento hashtag jste již přidali",
        "search_files_by_hashtags": "Vyhledávání souborů podle hashtagů",
        "enter_to_add": "Zadejte pro přidání",
        "hashtags_most_used": "Nejpoužívanější hashtagy",
        "can_add_it_giving_click": "Kliknutím na ně je můžete přidat do vyhledávání.",
        "searching_files": "Vyhledávání souborů",
        "no_files_found": "Nebyly nalezeny žádné soubory",
        "image": "Obrázek",
        "video": "Video",
        "audio": "Audio",
        "library": "Knihkupectví",
        "home": "Domovská stránka",
        "block": "Block",
        "unblock": "Odblokování",
        "has_blocked_you": "Zablokoval vás",
        "accept": "Přijmout",
        "decline": "Odmítnout",
        "cancel_request": "Zrušit požadavek",
        "has_been_expulsed": "byl vyloučen",
        "room_doesnt_exists": "Místnost neexistuje",
        "you_arent_room_member": "Nejste členem místnosti",
        "create_room": "Vytvoření místnosti",
        "room_name": "Název místnosti",
        "no_rooms_created": "Nebyly vytvořeny žádné místnosti.",
        "dont_belong_to_anyone_room": "Nepatříte do žádné místnosti.",
        "enter_a_username": "Napište uživatele",
        "no_results": "Žádné výsledky",
        "create": "Vytvořit",
        "no_content_uploaded": "Žádný nahraný obsah",
        "new_subtag": "Nová podznačka",
        "update_info": "Aktualizovat informace",
        "updating": "Aktualizace",
        "update": "Aktualizace",
        "update_email": "Aktualizace e-mailu",
        "change_password": "Změna hesla",
        "current_password": "Aktuální heslo",
        "select": "Seleccionar",
        "tap_to_upload_a_file": "Klepnutím nahrajete soubor",
        "edit_photo": "Upravit fotografii",
        "preview": "Náhled",
        "edit_video": "Upravit video",
        "delete_changes": "Odstranění změn",
        "uploading": "Nahrávání",
        "session_deleted_or_expired": "Relace smazána nebo její platnost vypršela",
        "edit_profile": "Upravit profil",
        "devices": "Přístroje",
        "downloads": "Ke stažení",
        "logout": "Odhlášení",
        "offline": "Offline",
        "images": "Obrázky",
        "videos": "Videa",
        "audios": "Audios",
        "chat": "Chat",
        "session_unauthorized": "Vaše relace není autorizována.",
        "authorize_session": "Pro přístup k této relaci musíte zařízení autorizovat.",
        "authorize_instructions": "Najděte v e-mailu odkaz pro autorizaci nebo přejděte do aktivní relace a vyberte tři tečky v pravém horním rohu, poté přejděte do části \"Zařízení\" a vyberte možnost \"Autorizovat\".",
        "refresh_page_after_authorize": "Po dokončení autorizačního procesu obnovte stránku a přihlaste se.",
        "check_your_inbox": "Zkontrolujte svůj e-mail",
        "send_authorization_email": "Odeslat autorizační e-mail",
        "call_finished": "Hovor ukončen",
        "close": "Zavřít",
        "waiting_stream": "Čeká se na přenos",
        "request_camera_and_mic": "Potřebujeme přístup ke kameře a mikrofonu, abychom mohli uskutečňovat hovory.",
        "call_in_progress": "Právě probíhající hovor",
        "group": "Skupina",
        "videocall": "Video hovor",
        "call": "Volejte",
        "connecting": "Připojení",
        "call_cancelled": "Zrušený hovor",
        "calling": "Volání na",
        "hangup": "Zavěsit",
        "reject": "Odmítnout",
        "has_sent_a_file": "odeslal soubor.",
        "keep": "Zachovat",
        "keep_file": "Pokud aktivujete uchovávání souborů, soubor bude uložen v aplikaci. To umožní přístup k němu bez nutnosti připojení k internetu.",
        "not_keep_file": "Pokud uchovávání souborů zakážete, soubor bude odstraněn a bez připojení k internetu k němu nebudete mít přístup.",
        "file_stored_instruction": "Všechny uložené soubory se v seznamu společných souborů zobrazí se zeleným rámečkem. Můžete je také zobrazit v",
        "stored": "Uloženo",
        "not_stored": "Není uloženo",
        "file_not_stored_instruction": "K tomuto souboru nebudete mít přístup bez připojení k internetu.",
        "device": "Přístroj",
        "file_visibility": "Viditelnost archivu",
        "visible_for_everyone": "Viditelné pro všechny",
        "shared_link": "Sdílený odkaz",
        "hashtags": "Hashtagy",
        "hashtags_helps_to_search_your_file": "Hashtagy pomáhají při vyhledávání souborů.",
        "edit": "Upravit",
        "move": "Přesun",
        "rename": "Přejmenování",
        "visibility": "Viditelnost",
        "delete": "Odstranit",
        "file_warn_delete": "Opravdu chcete soubor odstranit?",
        "no_subtags_availables": "Nejsou k dispozici žádné dílčí značky",
        "owner": "Tvůrce",
        "expulsing": "Vysunutí",
        "expulse": "Vyhnat",
        "you_have_left_the_group": "Opustili jste skupinu",
        "coming_out": "Odchod",
        "leave_group": "Opustit skupinu",
        "you_have_left_the_room": "Opustili jste místnost.",
        "leave_room": "Opuštění místnosti",
        "message_deleted": "Zpráva smazána",
        "has_been_error_deleting_message": "Při mazání zprávy došlo k chybě",
        "deleting": "Odstranění",
        "new_tag": "Nový štítek",
        "photoEditor": {
            "name": "Název",
            "save": "Uložit",
            "saveAs": "Uložit jako",
            "back": "Zpět",
            "loading": "Načítání…",
            "resetOperations": "Obnovení/odstranění všech operací.",
            "changesLoseConfirmation": "Všechny změny budou odstraněny.",
            "changesLoseConfirmationHint": "Určitě chcete pokračovat?",
            "cancel": "Zrušit",
            "continue": "Pokračovat",
            "undoTitle": "Vrátit poslední operaci",
            "redoTitle": "Zopakovat poslední operaci",
            "showImageTitle": "Ukázat původní obrázek",
            "zoomInTitle": "Přiblížení",
            "zoomOutTitle": "Oddálit",
            "toggleZoomMenuTitle": "Přepnutí nabídky zoomu",
            "adjustTab": "Úprava",
            "finetuneTab": "Barvy",
            "filtersTab": "Filtry",
            "watermarkTab": "Obrázky",
            "annotateTab": "Kreslení",
            "resize": "Změna velikosti",
            "resizeTab": "Velikost",
            "invalidImageError": "Neplatný obrázek",
            "uploadImageError": "Při načítání obrázku došlo k chybě.",
            "areNotImages": "nejsou to obrázky",
            "isNotImage": "není obraz",
            "toBeUploaded": "k nahrání",
            "cropTool": "Střih",
            "original": "Originál",
            "custom": "Personalizované",
            "square": "Čtverec",
            "landscape": "Horizontální",
            "portrait": "Vertikální",
            "ellipse": "Kruh",
            "classicTv": "Klasická TV",
            "cinemascope": "Cinemascope",
            "arrowTool": "Šipka",
            "blurTool": "Rozmazat",
            "brightnessTool": "Jas",
            "contrastTool": "Kontrast",
            "ellipseTool": "Kruh",
            "unFlipX": "Unflip X",
            "flipX": "Flip X",
            "unFlipY": "Unflip Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "Nuance",
            "saturation": "Nasycení",
            "value": "Hodnota",
            "imageTool": "Obrázek",
            "importing": "Import…",
            "addImage": "+ Přidat obrázek",
            "uploadImage": "Nahrát obrázek",
            "fromGallery": "Nálepky",
            "lineTool": "Linka",
            "penTool": "Tužka",
            "polygonTool": "Polygon",
            "sides": "Boční strany",
            "rectangleTool": "Obdélník",
            "cornerRadius": "Poloměr hrany",
            "resizeWidthTitle": "Šířka v pixelech",
            "resizeHeightTitle": "Výška v pixelech",
            "toggleRatioLockTitle": "Přepnutí zámku rozměru",
            "reset": "Restart",
            "resetSize": "Obnovení původních rozměrů obrázku",
            "rotateTool": "Rotace",
            "textTool": "Text",
            "textSpacings": "Odstupy mezi textem",
            "textAlignment": "Zarovnání textu",
            "fontFamily": "Rodina písma",
            "size": "Velikost",
            "letterSpacing": "Rozteče písmen",
            "lineHeight": "Výška čáry",
            "warmthTool": "Teplo",
            "addWatermark": "+ Přidat obrázek",
            "addWatermarkTitle": "Výběr typu vodoznaku",
            "uploadWatermark": "Vybrat obrázek",
            "addWatermarkAsText": "Přidat text",
            "padding": "Vycpávky",
            "shadow": "Stín",
            "horizontal": "Horizontální",
            "vertical": "Vertikální",
            "blur": "Rozmazat",
            "opacity": "Neprůhlednost",
            "position": "Pozice",
            "stroke": "Tloušťka",
            "saveAsModalLabel": "Podrobnosti na",
            "extension": "Prodloužení",
            "nameIsRequired": "Název je povinný.",
            "quality": "Kvalita",
            "imageDimensionsHoverTitle": "Rozměry uloženého obrázku (šířka x výška)",
            "cropSizeLowerThanResizedWarning": "Všimněte si, že vybraná oblast ořezu je menší než použitá změna velikosti, což může vést ke snížení kvality.",
            "actualSize": "Aktuální velikost (100%)",
            "fitSize": "Přizpůsobení rozměru",
            "addImageTitle": "Vyberte obrázek, který chcete přidat…"
        },
        "changing": "Změna",
        "profile_picture_changed": "Profilový obrázek změněn",
        "loading_profile_picture": "Načítání profilového obrázku",
        "change": "Změna",
        "session": "Zasedání",
        "system_operative": "Operační systém",
        "navigator": "Prohlížeč",
        "authorize": "Autorizovat",
        "deauthorize": "Deautorizace",
        "keep_tag_content": "Chcete zachovat obsah tagu?",
        "move_content_to": "Přesunout obsah do",
        "move_to": "Přesun do",
        "chat_request": "Žádost o chat",
        "accept_request": "Přijmout žádost",
        "go_to_chat": "Přejít na chat",
        "applying": "Použití",
        "time": "Čas",
        "adjust": "Úprava",
        "rotate": "Rotace",
        "start": "Start",
        "end": "Konec",
        "lang_name": "Czech"
    },
    "da": {
        "name": "Navn",
        "name_example": "Peter",
        "last_name": "Efternavn",
        "last_name_example": "Jensen",
        "username": "Bruger",
        "username_example": "peter_jensen",
        "email": "E-mail-adresse",
        "email_example": "bruger@domæne.com",
        "password": "Kodeord",
        "new_password": "Nyt kodeord",
        "password_example": "*****************",
        "login": "Login",
        "register": "Tilmeld dig",
        "verification_code": "Verifikationskode",
        "verification_code_example": "xxx",
        "verify": "Bekræfte",
        "loading": "Indlæsning…",
        "please_wait": "Vent venligst",
        "go_back": "Tilbage til",
        "recovery": "Genopretning",
        "login_subtitle": "Indtast dine oplysninger, og log ind på din konto.",
        "forgot_password_link": "Har du glemt din adgangskode? Hent.",
        "register_account_link": "Har du ikke en konto? Registrer dig nu",
        "register_subtitle": "Indtast dine oplysninger, og registrer din konto.",
        "login_link": "Har du allerede en konto? Log ind nu",
        "verification_code_instruction": "Tjek din e-mail, og indtast den kode, vi har sendt dig.",
        "forgot_password_subtitle": "Indtast din e-mailadresse for at hente din adgangskode.",
        "change_password_subtitle": "Indtast en ny adgangskode.",
        "change_password_code_used": "Koden til at gendanne din adgangskode er ugyldig eller er allerede blevet brugt.",
        "no_chats": "Du har ikke samtaler.",
        "chats": "Samtaler",
        "groups": "Grupper",
        "rooms": "Værelser",
        "save": "Gem",
        "download": "Download",
        "downloading_image": "Downloading af billede",
        "an_error_has_ocurred": "Der er opstået en fejl",
        "saving_changes": "Gemme ændringer",
        "go_back_to_edit": "Tilbage til redigering",
        "upload_warning_modal": "Det originale billede vil blive erstattet, når redigeringen uploades.",
        "upload_question_modal": "Vil du beholde originalen og lave en kopi?",
        "create_copy_and_keep": "Opret kopi og behold",
        "upload": "Upload",
        "upload_changes": "Upload ændringer",
        "changes_saved": "Gemte ændringer",
        "downloading_video": "Downloading af video",
        "video_upload_warning_modal": "Den originale video vil blive erstattet, når redigeringen uploades.",
        "results": "Resultater",
        "members": "Medlemmer",
        "cancel": "Annuller",
        "add_user": "Tilføj bruger",
        "no_messages": "Ingen beskeder",
        "message_placeholder": "Besked…",
        "group_doesnt_exists": "Gruppen eksisterer ikke",
        "you_arent_group_member": "Du er ikke medlem af gruppen",
        "touch_to_see_more_info": "Tryk for at se mere info.",
        "start_videocall": "Start videosamtale",
        "start_call": "Start opkaldet",
        "create_group": "Opret gruppe",
        "group_name": "Navn på gruppen",
        "add_members_to_create_group": "Tilføj medlemmer for at oprette gruppen",
        "search": "Søgning",
        "creating": "At skabe…",
        "dont_belong_anyone_group": "Du tilhører ikke nogen gruppe.",
        "already_add_this_hashtag": "Du har allerede tilføjet det hashtag",
        "search_files_by_hashtags": "Søg efter filer med hashtags",
        "enter_to_add": "Enter for at tilføje",
        "hashtags_most_used": "Mest brugte hashtags",
        "can_add_it_giving_click": "Du kan tilføje disse til din søgning ved at klikke på dem.",
        "searching_files": "Søgning efter filer",
        "no_files_found": "Ingen filer fundet",
        "image": "Billede",
        "video": "Video",
        "audio": "Lyd",
        "library": "Boghandel",
        "home": "Hjemmeside",
        "block": "Blok",
        "unblock": "Fjern blokeringen",
        "has_blocked_you": "Han har blokeret dig",
        "accept": "Accepter",
        "decline": "Afvis",
        "cancel_request": "Annuller anmodning",
        "has_been_expulsed": "er blevet bortvist",
        "room_doesnt_exists": "Rummet eksisterer ikke",
        "you_arent_room_member": "Du er ikke medlem af rummet",
        "create_room": "Opret rum",
        "room_name": "Navn på rummet",
        "no_rooms_created": "Der er ikke oprettet nogen værelser.",
        "dont_belong_to_anyone_room": "Du hører ikke til i noget rum.",
        "enter_a_username": "Skriv en bruger",
        "no_results": "Ingen resultater",
        "create": "Opret",
        "no_content_uploaded": "Intet indhold uploadet",
        "new_subtag": "Nyt subtag",
        "update_info": "Opdater information",
        "updating": "Opdatering",
        "update": "Opdater",
        "update_email": "Opdater e-mail",
        "change_password": "Skift adgangskode",
        "current_password": "Nuværende adgangskode",
        "select": "Vælg",
        "tap_to_upload_a_file": "Tryk for at uploade en fil",
        "edit_photo": "Rediger foto",
        "preview": "Forhåndsvisning",
        "edit_video": "Rediger video",
        "delete_changes": "Slet ændringer",
        "uploading": "Uploading",
        "session_deleted_or_expired": "Session slettet eller udløbet",
        "edit_profile": "Rediger profil",
        "devices": "Enheder",
        "downloads": "Downloads",
        "logout": "Log ud",
        "offline": "Offline",
        "images": "Billeder",
        "videos": "Videoer",
        "audios": "Audios",
        "chat": "Chat",
        "session_unauthorized": "Din session er ikke godkendt.",
        "authorize_session": "For at få adgang til denne session skal du godkende din enhed.",
        "authorize_instructions": "Se efter godkendelseslinket i din e-mail, eller gå til en aktiv session, og vælg de tre prikker i øverste højre hjørne, gå derefter til \"Enheder\", og vælg \"Godkend\".",
        "refresh_page_after_authorize": "Når godkendelsesprocessen er afsluttet, skal du opdatere login-siden.",
        "check_your_inbox": "Tjek din e-mail",
        "send_authorization_email": "Send e-mail med godkendelse",
        "call_finished": "Opkald afsluttet",
        "close": "Luk",
        "waiting_stream": "Afventer transmission",
        "request_camera_and_mic": "Vi skal have adgang til dit kamera og din mikrofon, så du kan foretage opkald.",
        "call_in_progress": "Opkald i gang",
        "group": "Gruppe",
        "videocall": "Videoopkald",
        "call": "Ring op",
        "connecting": "Tilslutning",
        "call_cancelled": "Opkald aflyst",
        "calling": "Opkald",
        "hangup": "Læg på",
        "reject": "Afvis",
        "has_sent_a_file": "har sendt en fil.",
        "keep": "Bevar",
        "keep_file": "Hvis du aktiverer fillagring, bliver filerne gemt i programmet. Dette giver adgang uden behov for en internetforbindelse.",
        "not_keep_file": "Hvis du deaktiverer fillagring, vil dine filer blive slettet, og du vil ikke kunne få adgang til dem uden en internetforbindelse.",
        "file_stored_instruction": "Alle gemte filer vises med en grøn kant i den normale filliste. Du kan også se dem i",
        "stored": "Opbevaret",
        "not_stored": "Ikke opbevaret",
        "file_not_stored_instruction": "Du kan ikke få adgang til denne fil uden en internetforbindelse.",
        "device": "Enhed",
        "file_visibility": "Synlighed i arkivet",
        "visible_for_everyone": "Synlig for alle",
        "shared_link": "Delt link",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Hashtags hjælper med at søge efter dine filer.",
        "edit": "Rediger",
        "move": "Flyt",
        "rename": "Omdøb",
        "visibility": "Synlighed",
        "delete": "Slet",
        "file_warn_delete": "Er du sikker på, at du vil slette filen?",
        "no_subtags_availables": "Ingen subtags tilgængelige",
        "owner": "Skaber",
        "expulsing": "Udkastning",
        "expulse": "Udvis",
        "you_have_left_the_group": "Du har forladt gruppen",
        "coming_out": "Forlader",
        "leave_group": "Forlad gruppen",
        "you_have_left_the_room": "Du har forladt rummet.",
        "leave_room": "Forlader rummet",
        "message_deleted": "Besked slettet",
        "has_been_error_deleting_message": "Der opstod en fejl under sletning af meddelelsen",
        "deleting": "Eliminering",
        "new_tag": "Nyt tag",
        "photoEditor": {
            "name": "Navn",
            "save": "Gem",
            "saveAs": "Gem som",
            "back": "Tilbage",
            "loading": "Indlæsning…",
            "resetOperations": "Nulstil/slette alle operationer.",
            "changesLoseConfirmation": "Alle ændringer vil blive slettet.",
            "changesLoseConfirmationHint": "Er du sikker på, at du vil fortsætte?",
            "cancel": "Annuller",
            "continue": "Fortsætte",
            "undoTitle": "Fortryd sidste operation",
            "redoTitle": "Gentag sidste operation",
            "showImageTitle": "Vis det originale billede",
            "zoomInTitle": "Zoom ind",
            "zoomOutTitle": "Zoom ud",
            "toggleZoomMenuTitle": "Skift zoom-menu",
            "adjustTab": "Juster",
            "finetuneTab": "Farver",
            "filtersTab": "Filtre",
            "watermarkTab": "Billeder",
            "annotateTab": "Tegning",
            "resize": "Ændre størrelse",
            "resizeTab": "Størrelsen",
            "invalidImageError": "Ugyldigt billede",
            "uploadImageError": "Der opstod en fejl under indlæsning af billedet.",
            "areNotImages": "De er ikke billeder",
            "isNotImage": "er ikke et billede",
            "toBeUploaded": "der skal uploades",
            "cropTool": "Klip",
            "original": "Original",
            "custom": "Personlig",
            "square": "Firkantet",
            "landscape": "Vandret",
            "portrait": "Lodret",
            "ellipse": "Cirkel",
            "classicTv": "Klassisk tv",
            "cinemascope": "Cinemascope",
            "arrowTool": "Pil",
            "blurTool": "Sløring",
            "brightnessTool": "Lysstyrke",
            "contrastTool": "Kontrast",
            "ellipseTool": "Cirkel",
            "unFlipX": "Vendes om X",
            "flipX": "Flip X",
            "unFlipY": "Vendes om Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "Hue",
            "saturation": "Mætning",
            "value": "Værdi",
            "imageTool": "Billede",
            "importing": "Importering…",
            "addImage": "+ Tilføj billede",
            "uploadImage": "Upload billede",
            "fromGallery": "Klistermærker",
            "lineTool": "Linje",
            "penTool": "Blyant",
            "polygonTool": "Polygon",
            "sides": "Sides",
            "rectangleTool": "Rektangel",
            "cornerRadius": "Kantradius",
            "resizeWidthTitle": "Bredde i pixels",
            "resizeHeightTitle": "Højde i pixels",
            "toggleRatioLockTitle": "Slå dimensionslås til og fra",
            "reset": "Genstart",
            "resetSize": "Nulstil til originale billeddimensioner",
            "rotateTool": "Drej",
            "textTool": "Tekst",
            "textSpacings": "Tekstafstand",
            "textAlignment": "Tekstjustering",
            "fontFamily": "Skriftfamilie",
            "size": "Størrelsen",
            "letterSpacing": "Bogstavafstand",
            "lineHeight": "Linjehøjde",
            "warmthTool": "Varme",
            "addWatermark": "+ Tilføj billede",
            "addWatermarkTitle": "Vælg typen af vandmærke",
            "uploadWatermark": "Vælg billede",
            "addWatermarkAsText": "Tilføj tekst",
            "padding": "Fyldning",
            "shadow": "Skygge",
            "horizontal": "Vandret",
            "vertical": "Lodret",
            "blur": "Sløring",
            "opacity": "Opacitet",
            "position": "Position",
            "stroke": "Tykkelse",
            "saveAsModalLabel": "Detaljer",
            "extension": "Forlængelse",
            "nameIsRequired": "Navnet er påkrævet.",
            "quality": "Kvalitet",
            "imageDimensionsHoverTitle": "Gemte billeddimensioner (bredde x højde)",
            "cropSizeLowerThanResizedWarning": "Bemærk, at det valgte beskæringsområde er mindre end den anvendte størrelse, hvilket kan resultere i en forringelse af kvaliteten.",
            "actualSize": "Nuværende størrelse (100%)",
            "fitSize": "Tilpas dimension",
            "addImageTitle": "Vælg et billede, der skal tilføjes…"
        },
        "changing": "Forandring",
        "profile_picture_changed": "Profilbillede ændret",
        "loading_profile_picture": "Indlæser profilbillede",
        "change": "Forandre",
        "session": "Session",
        "system_operative": "Operativsystem",
        "navigator": "Browser",
        "authorize": "Autorisation",
        "deauthorize": "Fjern autorisation",
        "keep_tag_content": "Vil du beholde indholdet af tagget?",
        "move_content_to": "Flyt indhold til",
        "move_to": "Flyt til",
        "chat_request": "Chat-anmodning",
        "accept_request": "Accepter ansøgning",
        "go_to_chat": "Gå til chat",
        "applying": "Ansøgning",
        "time": "Tid",
        "adjust": "Juster",
        "rotate": "Drej",
        "start": "Start",
        "end": "Slut",
        "lang_name": "Danish"
    },
    "nl": {
        "name": "Naam",
        "name_example": "Jade",
        "last_name": "Achternaam",
        "last_name_example": "Jansen",
        "username": "Gebruiker",
        "username_example": "jade_jansen",
        "email": "E-mailadres",
        "email_example": "gebruiker@domein.com",
        "password": "Wachtwoord",
        "new_password": "Nieuw wachtwoord",
        "password_example": "*****************",
        "login": "Inloggen",
        "register": "Aanmelden",
        "verification_code": "Verificatiecode",
        "verification_code_example": "xxx",
        "verify": "Controleer",
        "loading": "Laden…",
        "please_wait": "Even geduld",
        "go_back": "Terug naar",
        "recovery": "Terugvinden",
        "login_subtitle": "Voer je gegevens in en log in op je account.",
        "forgot_password_link": "Wachtwoord vergeten? Ophalen.",
        "register_account_link": "Heb je nog geen account? Registreer",
        "register_subtitle": "Voer je gegevens in en registreer je account.",
        "login_link": "Heb je al een account? Aanmelden",
        "verification_code_instruction": "Controleer je e-mail en voer de verzonden code in.",
        "forgot_password_subtitle": "Voer je e-mailadres in om je wachtwoord op te vragen.",
        "change_password_subtitle": "Voer een nieuw wachtwoord in.",
        "change_password_code_used": "De code om je wachtwoord te herstellen is ongeldig of is al gebruikt.",
        "no_chats": "Je hebt geen gesprekken.",
        "chats": "Gesprekken",
        "groups": "Groepen",
        "rooms": "Kamers",
        "save": "Sla",
        "download": "Downloaden",
        "downloading_image": "Afbeelding downloaden",
        "an_error_has_ocurred": "Er is een fout opgetreden",
        "saving_changes": "Veranderingen opslaan",
        "go_back_to_edit": "Terug naar bewerken",
        "upload_warning_modal": "Wanneer de editie wordt geladen, wordt de originele afbeelding vervangen.",
        "upload_question_modal": "Wil je het origineel bewaren en een kopie maken?",
        "create_copy_and_keep": "Maak kopieën en bewaar ze",
        "upload": "Uploaden",
        "upload_changes": "Wijzigingen uploaden",
        "changes_saved": "Opgeslagen wijzigingen",
        "downloading_video": "Video downloaden",
        "video_upload_warning_modal": "Het uploaden van de bewerking vervangt de originele video.",
        "results": "Resultaten",
        "members": "Leden",
        "cancel": "Annuleren",
        "add_user": "Gebruiker toevoegen",
        "no_messages": "Geen berichten",
        "message_placeholder": "Bericht…",
        "group_doesnt_exists": "De groep bestaat niet",
        "you_arent_group_member": "Je bent geen lid van de groep",
        "touch_to_see_more_info": "Tik op om meer info te zien.",
        "start_videocall": "Videogesprek starten",
        "start_call": "Start oproep",
        "create_group": "Groep maken",
        "group_name": "Naam van de groep",
        "add_members_to_create_group": "Leden toevoegen om de groep te maken",
        "search": "Zoek",
        "creating": "Creëren…",
        "dont_belong_anyone_group": "Je behoort tot geen enkele groep.",
        "already_add_this_hashtag": "Je hebt die hashtag al toegevoegd",
        "search_files_by_hashtags": "Zoeken naar bestanden op hashtags",
        "enter_to_add": "Enter om toe te voegen",
        "hashtags_most_used": "Meest gebruikte hashtags",
        "can_add_it_giving_click": "Je kunt het toevoegen aan je zoekopdracht door erop te klikken.",
        "searching_files": "Zoeken naar bestanden",
        "no_files_found": "Geen bestanden gevonden",
        "image": "Afbeelding",
        "video": "Video",
        "audio": "Audio",
        "library": "Boekhandel",
        "home": "Thuis",
        "block": "Blokkeer",
        "unblock": "Deblokkeer",
        "has_blocked_you": "Hij heeft je geblokkeerd",
        "accept": "Accepteer",
        "decline": "Verwerpen",
        "cancel_request": "Aanvraag annuleren",
        "has_been_expulsed": "is uitgewezen",
        "room_doesnt_exists": "De kamer bestaat niet",
        "you_arent_room_member": "Je bent geen lid van de kamer",
        "create_room": "Ruimte maken",
        "room_name": "Naam van de kamer",
        "no_rooms_created": "Er zijn geen kamers gemaakt.",
        "dont_belong_to_anyone_room": "Je hoort in geen enkele kamer thuis.",
        "enter_a_username": "Schrijf een gebruiker",
        "no_results": "Geen resultaten",
        "create": "Creëer",
        "no_content_uploaded": "Geen inhoud geüpload",
        "new_subtag": "Nieuw subtag",
        "update_info": "Informatie bijwerken",
        "updating": "Bijwerken van",
        "update": "Bijwerken",
        "update_email": "E-mail bijwerken",
        "change_password": "Wachtwoord wijzigen",
        "current_password": "Huidig wachtwoord",
        "select": "Selecteer",
        "tap_to_upload_a_file": "Tik op om een bestand te uploaden",
        "edit_photo": "Foto bewerken",
        "preview": "Voorvertoning",
        "edit_video": "Bewerk video",
        "delete_changes": "Wijzigingen verwijderen",
        "uploading": "Het uploaden van",
        "session_deleted_or_expired": "Sessie beëindigd of verlopen",
        "edit_profile": "Profiel bewerken",
        "devices": "Apparaten",
        "downloads": "Downloads",
        "logout": "Uitloggen",
        "offline": "Offline",
        "images": "Afbeeldingen",
        "videos": "Video's",
        "audios": "Audios",
        "chat": "Chat",
        "session_unauthorized": "Uw sessie is niet geautoriseerd.",
        "authorize_session": "Om toegang te krijgen tot deze sessie, moet u uw apparaat autoriseren.",
        "authorize_instructions": "Controleer je e-mail voor de autorisatielink of ga naar een actieve sessie en selecteer de drie puntjes in de rechterbovenhoek, ga dan naar \"Apparaten\" en selecteer \"Autoriseren\".",
        "refresh_page_after_authorize": "Vernieuw de aanmeldingspagina zodra het autorisatieproces is voltooid.",
        "check_your_inbox": "Controleer je e-mail",
        "send_authorization_email": "E-mail met toestemming verzenden",
        "call_finished": "Gesprek beëindigd",
        "close": "Sluit",
        "waiting_stream": "In afwachting van uitzending",
        "request_camera_and_mic": "We hebben toegang nodig tot je camera en microfoon zodat je kunt bellen.",
        "call_in_progress": "Oproep in behandeling",
        "group": "Groep",
        "videocall": "Video-oproep",
        "call": "Bel",
        "connecting": "Aansluiten",
        "call_cancelled": "Oproep geannuleerd",
        "calling": "Oproep aan",
        "hangup": "Ophangen",
        "reject": "Weiger",
        "has_sent_a_file": "een bestand heeft verzonden.",
        "keep": "Behoud",
        "keep_file": "Als je bestandsretentie activeert, wordt het bestand opgeslagen in de applicatie. Hierdoor is het bestand toegankelijk zonder dat er een internetverbinding nodig is.",
        "not_keep_file": "Als je bestandsretentie uitschakelt, worden bestanden verwijderd en kun je ze niet openen zonder internetverbinding.",
        "file_stored_instruction": "Alle opgeslagen bestanden verschijnen met een groene rand in de lijst met algemene bestanden. U kunt ze ook zien in de",
        "stored": "Opgeslagen bij",
        "not_stored": "Niet opgeslagen",
        "file_not_stored_instruction": "Je hebt geen toegang tot dit bestand zonder internetverbinding.",
        "device": "Apparaat",
        "file_visibility": "Zichtbaarheid archief",
        "visible_for_everyone": "Zichtbaar voor iedereen",
        "shared_link": "Gedeelde link",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Hashtags helpen bij het zoeken naar je bestanden.",
        "edit": "Bewerk",
        "move": "Verplaats",
        "rename": "Hernoemen",
        "visibility": "Zichtbaarheid",
        "delete": "Verwijder",
        "file_warn_delete": "Weet je zeker dat je het bestand wilt verwijderen?",
        "no_subtags_availables": "Geen subtags beschikbaar",
        "owner": "Schepper",
        "expulsing": "Uitwerpen",
        "expulse": "Uitdrijven",
        "you_have_left_the_group": "Je hebt de groep verlaten",
        "coming_out": "Naar buiten komen",
        "leave_group": "Verlaat de groep",
        "you_have_left_the_room": "Je hebt de kamer verlaten.",
        "leave_room": "De kamer verlaten",
        "message_deleted": "Bericht verwijderd",
        "has_been_error_deleting_message": "Er is een fout opgetreden bij het verwijderen van het bericht",
        "deleting": "Elimineren van",
        "new_tag": "Nieuwe tag",
        "photoEditor": {
            "name": "Naam",
            "save": "Einde",
            "saveAs": "Opslaan als",
            "back": "Terug",
            "loading": "Laden…",
            "resetOperations": "Alle bewerkingen resetten/wissen.",
            "changesLoseConfirmation": "Alle wijzigingen worden verwijderd.",
            "changesLoseConfirmationHint": "Weet je zeker dat je wilt doorgaan?",
            "cancel": "Annuleren",
            "continue": "Ga verder",
            "undoTitle": "Laatste bewerking ongedaan maken",
            "redoTitle": "Laatste bewerking opnieuw uitvoeren",
            "showImageTitle": "Oorspronkelijke afbeelding tonen",
            "zoomInTitle": "Inzoomen",
            "zoomOutTitle": "Uitzoomen",
            "toggleZoomMenuTitle": "Zoommenu schakelen",
            "adjustTab": "Aanpassen",
            "finetuneTab": "Kleuren",
            "filtersTab": "Filters",
            "watermarkTab": "Afbeeldingen",
            "annotateTab": "Tekening",
            "resize": "Grootte aanpassen",
            "resizeTab": "Grootte",
            "invalidImageError": "Ongeldige afbeelding",
            "uploadImageError": "Er is een fout opgetreden tijdens het laden van de afbeelding.",
            "areNotImages": "het zijn geen beelden",
            "isNotImage": "is geen afbeelding",
            "toBeUploaded": "te uploaden",
            "cropTool": "Snijden",
            "original": "Origineel",
            "custom": "Gepersonaliseerde",
            "square": "Vierkant",
            "landscape": "Horizontaal",
            "portrait": "Verticaal",
            "ellipse": "Cirkel",
            "classicTv": "Klassieke tv",
            "cinemascope": "Cinemascope",
            "arrowTool": "Pijl",
            "blurTool": "Vervagen",
            "brightnessTool": "Helderheid",
            "contrastTool": "Contrast",
            "ellipseTool": "Cirkel",
            "unFlipX": "Terugspringen X",
            "flipX": "Omdraaien X",
            "unFlipY": "Terugspringen Y",
            "flipY": "Omdraaien Y",
            "hsvTool": "MSV",
            "hue": "Nuance",
            "saturation": "Verzadiging",
            "value": "Waarde",
            "imageTool": "Afbeelding",
            "importing": "Importeren van…",
            "addImage": "+ Afbeelding toevoegen",
            "uploadImage": "Afbeelding uploaden",
            "fromGallery": "Stickers",
            "lineTool": "Lijn",
            "penTool": "Potlood",
            "polygonTool": "Polygoon",
            "sides": "Zijkanten",
            "rectangleTool": "Rechthoek",
            "cornerRadius": "Randradius",
            "resizeWidthTitle": "Breedte in pixels",
            "resizeHeightTitle": "Hoogte in pixels",
            "toggleRatioLockTitle": "Dimensieslot schakelen",
            "reset": "Herstart",
            "resetSize": "Oorspronkelijke afbeeldingsafmetingen herstellen",
            "rotateTool": "Draaien",
            "textTool": "Tekst",
            "textSpacings": "Tekstafstand",
            "textAlignment": "Tekstuitlijning",
            "fontFamily": "Lettertype",
            "size": "Grootte",
            "letterSpacing": "Letterafstand",
            "lineHeight": "Lijnhoogte",
            "warmthTool": "Warmte",
            "addWatermark": "+ Afbeelding toevoegen",
            "addWatermarkTitle": "Kies het type watermerk",
            "uploadWatermark": "Afbeelding selecteren",
            "addWatermarkAsText": "Tekst toevoegen",
            "padding": "Vulling",
            "shadow": "Schaduw",
            "horizontal": "Horizontaal",
            "vertical": "Verticaal",
            "blur": "Vervagen",
            "opacity": "Opaciteit",
            "position": "Positie",
            "stroke": "Dikte",
            "saveAsModalLabel": "Details",
            "extension": "Uitbreiding",
            "nameIsRequired": "De naam is verplicht.",
            "quality": "Kwaliteit",
            "imageDimensionsHoverTitle": "Afmetingen van de opgeslagen afbeelding (B x H)",
            "cropSizeLowerThanResizedWarning": "Merk op dat het geselecteerde bijsnijdgebied kleiner is dan de toegepaste grootteverandering, wat de kwaliteit kan verminderen.",
            "actualSize": "Huidige grootte (100%)",
            "fitSize": "Dimensie aanpassen",
            "addImageTitle": "Selecteer een afbeelding om toe te voegen…"
        },
        "changing": "Veranderen",
        "profile_picture_changed": "Profielfoto gewijzigd",
        "loading_profile_picture": "Profielfoto laden",
        "change": "Verander",
        "session": "Sessie",
        "system_operative": "Besturingssysteem",
        "navigator": "Browser",
        "authorize": "Autoriseer",
        "deauthorize": "Deautoriseer",
        "keep_tag_content": "Wil je de inhoud van de tag behouden?",
        "move_content_to": "Inhoud verplaatsen naar",
        "move_to": "Doorschuiven naar",
        "chat_request": "Verzoek om chatten",
        "accept_request": "Aanvraag accepteren",
        "go_to_chat": "Ga naar chat",
        "applying": "Toepassing",
        "time": "Weer",
        "adjust": "Aanpassen",
        "rotate": "Draaien",
        "start": "Start",
        "end": "Einde",
        "lang_name": "Dutch"
    },
    "et": {
        "name": "Nimi",
        "name_example": "Olga",
        "last_name": "Perekonnanimi",
        "last_name_example": "Tamm",
        "username": "Kasutaja",
        "username_example": "olga_tamm",
        "email": "E-posti aadress",
        "email_example": "kasutaja@domeen.com",
        "password": "Parool",
        "new_password": "Uus parool",
        "password_example": "*****************",
        "login": "Logi sisse",
        "register": "Registreeri",
        "verification_code": "Kontrollimise kood",
        "verification_code_example": "xxx",
        "verify": "Kontrollida",
        "loading": "Laadimine…",
        "please_wait": "Palun oodake",
        "go_back": "Tagasi",
        "recovery": "Taastamine",
        "login_subtitle": "Sisestage oma andmed ja logige oma kontole sisse.",
        "forgot_password_link": "Unustasid oma salasõna? Taasta see.",
        "register_account_link": "Kas teil ei ole kontot? Registreeru",
        "register_subtitle": "Sisestage oma andmed ja registreerige oma konto.",
        "login_link": "Kas sul on juba konto? Logi sisse!",
        "verification_code_instruction": "Kontrollige oma e-kirja ja sisestage saadetud kood.",
        "forgot_password_subtitle": "Parooli saamiseks sisestage oma e-posti aadress.",
        "change_password_subtitle": "Sisestage uus parool.",
        "change_password_code_used": "Parooli taastamise kood on kehtetu või seda on juba kasutatud.",
        "no_chats": "Teil ei ole vestlusi.",
        "chats": "Vestlused",
        "groups": "Rühmad",
        "rooms": "Toad",
        "save": "Säästa",
        "download": "Lae alla",
        "downloading_image": "Kujutise allalaadimine",
        "an_error_has_ocurred": "Tekkinud on viga",
        "saving_changes": "Muudatuste salvestamine",
        "go_back_to_edit": "Tagasi redigeerimisele",
        "upload_warning_modal": "Redigeerimise üleslaadimine asendab originaalpildi.",
        "upload_question_modal": "Kas soovite säilitada originaali ja luua koopia?",
        "create_copy_and_keep": "Loo koopia ja hoia",
        "upload": "Laadige üles",
        "upload_changes": "Muudatuste üleslaadimine",
        "changes_saved": "Salvestatud muudatused",
        "downloading_video": "Video allalaadimine",
        "video_upload_warning_modal": "Redigeerimise üleslaadimine asendab algse video.",
        "results": "Tulemused",
        "members": "Liikmed",
        "cancel": "Tühista",
        "add_user": "Kasutaja lisamine",
        "no_messages": "Sõnumid puuduvad",
        "message_placeholder": "Sõnum…",
        "group_doesnt_exists": "Rühma ei ole olemas",
        "you_arent_group_member": "Te ei ole grupi liige",
        "touch_to_see_more_info": "Koputage, et näha lisainfot.",
        "start_videocall": "Alusta videokõnet",
        "start_call": "Alusta kõne",
        "create_group": "Loo grupp",
        "group_name": "Rühma nimi",
        "add_members_to_create_group": "Lisa liikmed, et luua grupp",
        "search": "Otsi",
        "creating": "Looming…",
        "dont_belong_anyone_group": "Te ei kuulu ühtegi rühma.",
        "already_add_this_hashtag": "Sa oled juba lisanud selle hashtagi",
        "search_files_by_hashtags": "Otsi faile hashtagide järgi",
        "enter_to_add": "Sisestage, et lisada",
        "hashtags_most_used": "Enim kasutatud hashtagid",
        "can_add_it_giving_click": "Saate need oma otsingusse lisada, klõpsates neil.",
        "searching_files": "Failide otsing",
        "no_files_found": "Ei leitud faile",
        "image": "Pildi",
        "video": "Video",
        "audio": "Audio",
        "library": "Raamatupood",
        "home": "Kodulehekülg",
        "block": "Plokk",
        "unblock": "Vabastage",
        "has_blocked_you": "Ta on teid blokeerinud",
        "accept": "Aktsepteeri",
        "decline": "Tagasilükkamine",
        "cancel_request": "Tühista taotlus",
        "has_been_expulsed": "on välja heidetud",
        "room_doesnt_exists": "Ruumi ei ole olemas",
        "you_arent_room_member": "Sa ei ole ruumi liige",
        "create_room": "Loo ruum",
        "room_name": "Ruumi nimi",
        "no_rooms_created": "Tubasid ei ole loodud.",
        "dont_belong_to_anyone_room": "Te ei kuulu ühtegi ruumi.",
        "enter_a_username": "Kirjuta kasutaja",
        "no_results": "Tulemusi ei ole",
        "create": "Loo",
        "no_content_uploaded": "Sisu ei ole üles laaditud",
        "new_subtag": "Uus alamsilt",
        "update_info": "Teabe ajakohastamine",
        "updating": "Ajakohastamine",
        "update": "Update",
        "update_email": "E-posti ajakohastamine",
        "change_password": "Muuda salasõna",
        "current_password": "Praegune parool",
        "select": "Valige",
        "tap_to_upload_a_file": "Koputage faili üleslaadimiseks",
        "edit_photo": "Redigeeri fotot",
        "preview": "Eelvaade",
        "edit_video": "Redigeeri video",
        "delete_changes": "Muudatuste kustutamine",
        "uploading": "Üleslaadimine",
        "session_deleted_or_expired": "Seanss kustutatud või aegunud",
        "edit_profile": "Muuda profiili",
        "devices": "Seadmed",
        "downloads": "Allalaadimised",
        "logout": "Logi välja",
        "offline": "Offline",
        "images": "Pildid",
        "videos": "Videod",
        "audios": "Audios",
        "chat": "Vestlus",
        "session_unauthorized": "Teie seanss ei ole lubatud.",
        "authorize_session": "Selle seansi kasutamiseks peate oma seadme autoriseerima.",
        "authorize_instructions": "Palun kontrollige oma e-kirjas olevat autoriseerimislinki või minge aktiivsesse seanssi ja valige paremas ülemises nurgas olevad kolm punkti, seejärel minge rubriiki \"Seadmed\" ja valige \"Autoriseeri\".",
        "refresh_page_after_authorize": "Kui autoriseerimisprotsess on lõpule viidud, värskendage sisselogimislehte.",
        "check_your_inbox": "Kontrollige oma e-posti aadressi",
        "send_authorization_email": "Saatke autoriseerimise e-kiri",
        "call_finished": "Kõne lõppenud",
        "close": "Sulge",
        "waiting_stream": "Ootab edastamist",
        "request_camera_and_mic": "Meil on vaja juurdepääsu teie kaamerale ja mikrofonile, et saaksite helistada.",
        "call_in_progress": "Kõne käimas",
        "group": "Rühm",
        "videocall": "Videokõne",
        "call": "Kõne",
        "connecting": "Ühendamine",
        "call_cancelled": "Tühistatud kõne",
        "calling": "Helistamine",
        "hangup": "Pane üles",
        "reject": "Tagasilükkamine",
        "has_sent_a_file": "on saatnud faili.",
        "keep": "Hoidke",
        "keep_file": "Kui lubate faili salvestada, salvestatakse see rakenduses. See võimaldab teile juurdepääsu sellele ilma internetiühenduseta.",
        "not_keep_file": "Kui keelate failide salvestamise, kustutatakse teie failid ja te ei saa neile ilma internetiühenduseta juurde pääseda.",
        "file_stored_instruction": "Kõik salvestatud failid ilmuvad ühise faililoendi rohelise raamiga. Samuti saate neid vaadata",
        "stored": "Säilitatakse aadressil",
        "not_stored": "Ei ole salvestatud",
        "file_not_stored_instruction": "Ilma internetiühenduseta ei ole teil võimalik sellele failile ligi pääseda.",
        "device": "Seade",
        "file_visibility": "Arhiivi nähtavus",
        "visible_for_everyone": "Kõigile nähtav",
        "shared_link": "Jagatud link",
        "hashtags": "Hashtagid",
        "hashtags_helps_to_search_your_file": "Hashtagid aitavad teie faile otsida.",
        "edit": "Redigeeri",
        "move": "Liikumine",
        "rename": "Nimetage ümber",
        "visibility": "Nähtavus",
        "delete": "Kustuta",
        "file_warn_delete": "Kas olete kindel, et soovite faili kustutada?",
        "no_subtags_availables": "Alamtagid puuduvad",
        "owner": "Looja",
        "expulsing": "Väljaheitmine",
        "expulse": "Ekspulss",
        "you_have_left_the_group": "Te olete grupist lahkunud",
        "coming_out": "Lahkumine",
        "leave_group": "Jäta grupp",
        "you_have_left_the_room": "Te olete ruumist lahkunud.",
        "leave_room": "Ruumist lahkumine",
        "message_deleted": "Sõnum kustutatud",
        "has_been_error_deleting_message": "Sõnumi kustutamisel tekkis viga",
        "deleting": "Kustutamine",
        "new_tag": "Uus tag",
        "photoEditor": {
            "name": "Nimi",
            "save": "Salvesta",
            "saveAs": "Salvesta kui",
            "back": "Tagasi",
            "loading": "Laadimine…",
            "resetOperations": "Nullida/kustutada kõik toimingud.",
            "changesLoseConfirmation": "Kõik muudatused kustutatakse.",
            "changesLoseConfirmationHint": "Kas olete kindel, et soovite jätkata?",
            "cancel": "Tühista",
            "continue": "Jätka",
            "undoTitle": "Viimase operatsiooni tühistamine",
            "redoTitle": "Viimase operatsiooni kordamine",
            "showImageTitle": "Näita originaalpilti",
            "zoomInTitle": "Suurenda",
            "zoomOutTitle": "Suurenda välja",
            "toggleZoomMenuTitle": "Suumi menüü vahetamine",
            "adjustTab": "Reguleeri",
            "finetuneTab": "Värvid",
            "filtersTab": "Filtrid",
            "watermarkTab": "Pildid",
            "annotateTab": "Joonistus",
            "resize": "Suuruse muutmine",
            "resizeTab": "Suurus",
            "invalidImageError": "Invaliidne pilt",
            "uploadImageError": "Pildi laadimisel tekkis viga.",
            "areNotImages": "need ei ole kujutised",
            "isNotImage": "ei ole kujutis",
            "toBeUploaded": "mis tuleb üles laadida",
            "cropTool": "Lõika",
            "original": "Originaal",
            "custom": "Isikupärastatud",
            "square": "Ruutu",
            "landscape": "Horisontaalne",
            "portrait": "Vertikaalne",
            "ellipse": "Circle",
            "classicTv": "Klassikaline TV",
            "cinemascope": "Cinemascope",
            "arrowTool": "Nool",
            "blurTool": "Blur",
            "brightnessTool": "Heledus",
            "contrastTool": "Kontrast",
            "ellipseTool": "Circle",
            "unFlipX": "Unflip X",
            "flipX": "Flip X",
            "unFlipY": "Unflip Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "Hue",
            "saturation": "Küllastumine",
            "value": "Väärtus",
            "imageTool": "Pildi",
            "importing": "Importimine…",
            "addImage": "+ Lisage pilt",
            "uploadImage": "Pildi üleslaadimine",
            "fromGallery": "Kleebised",
            "lineTool": "Line",
            "penTool": "Pliiats",
            "polygonTool": "Polygon",
            "sides": "Küljed",
            "rectangleTool": "Ristkülikukujuline",
            "cornerRadius": "Serva raadius",
            "resizeWidthTitle": "Laius pikslites",
            "resizeHeightTitle": "Kõrgus pikslites",
            "toggleRatioLockTitle": "Mõõtmete lukustuse vahetus",
            "reset": "Reset",
            "resetSize": "Pildi algsete mõõtmete lähtestamine",
            "rotateTool": "Rotatsioon",
            "textTool": "Tekst",
            "textSpacings": "Teksti vahekaugus",
            "textAlignment": "Teksti joondamine",
            "fontFamily": "Kirjastiili perekond",
            "size": "Suurus",
            "letterSpacing": "Kirjade vahekaugus",
            "lineHeight": "Joone kõrgus",
            "warmthTool": "Soojus",
            "addWatermark": "+ Lisa pilt",
            "addWatermarkTitle": "Vali vesimärgi tüüp",
            "uploadWatermark": "Valige pilt",
            "addWatermarkAsText": "Teksti lisamine",
            "padding": "Täites",
            "shadow": "Shadow",
            "horizontal": "Horisontaalne",
            "vertical": "Vertikaalne",
            "blur": "Blur",
            "opacity": "Läbipaistmatus",
            "position": "Positsioon",
            "stroke": "Paksus",
            "saveAsModalLabel": "Üksikasjad",
            "extension": "Laiendus",
            "nameIsRequired": "Nimi on kohustuslik.",
            "quality": "Kvaliteet",
            "imageDimensionsHoverTitle": "Salvestatud pildi mõõtmed (laius x kõrgus)",
            "cropSizeLowerThanResizedWarning": "Pange tähele, et valitud kärpimisala on väiksem kui rakendatud suuruse muutmine, mis võib põhjustada kvaliteedi halvenemist.",
            "actualSize": "Praegune suurus (100%)",
            "fitSize": "Kohandada mõõde",
            "addImageTitle": "Valige lisamiseks pilt…"
        },
        "changing": "Muutuv",
        "profile_picture_changed": "Profiili pilt muudetud",
        "loading_profile_picture": "Profiilifoto laadimine",
        "change": "Muuda",
        "session": "Sessioon",
        "system_operative": "Operatsioonisüsteem",
        "navigator": "Veebilehitseja",
        "authorize": "Autorizar",
        "deauthorize": "Deauthoriseeri",
        "keep_tag_content": "Kas soovite sildi sisu säilitada?",
        "move_content_to": "Liigutage sisu aadressile",
        "move_to": "Liigu",
        "chat_request": "Vestlus päring",
        "accept_request": "Võta taotlus vastu",
        "go_to_chat": "Mine vestlusele",
        "applying": "Rakendades",
        "time": "Aeg",
        "adjust": "Reguleeri",
        "rotate": "Rotatsioon",
        "start": "Start",
        "end": "End",
        "lang_name": "Estonian"
    },
    "fi": {
        "name": "Nimi",
        "name_example": "Matti",
        "last_name": "Sukunimi",
        "last_name_example": "Korhonen",
        "username": "Käyttäjä",
        "username_example": "matti_korhonen",
        "email": "Sähköpostiosoite",
        "email_example": "käyttäjä@verkkotunnus.com",
        "password": "Salasana",
        "new_password": "Uusi salasana",
        "password_example": "*****************",
        "login": "Kirjaudu sisään",
        "register": "Rekisteröi",
        "verification_code": "Tarkistuskoodi",
        "verification_code_example": "xxx",
        "verify": "Tarkista",
        "loading": "Ladataan…",
        "please_wait": "Ole hyvä ja odota",
        "go_back": "Backtracking",
        "recovery": "Hae",
        "login_subtitle": "Anna tietosi ja kirjaudu sisään tilillesi.",
        "forgot_password_link": "Unohditko salasanasi? Hae se.",
        "register_account_link": "Eikö sinulla ole tiliä? Rekisteröidy!",
        "register_subtitle": "Anna tietosi ja rekisteröi tilisi.",
        "login_link": "Onko sinulla jo tili? Kirjaudu sisään!",
        "verification_code_instruction": "Tarkista sähköpostisi ja syötä lähetetty koodi.",
        "forgot_password_subtitle": "Anna sähköpostiosoitteesi saadaksesi salasanasi.",
        "change_password_subtitle": "Anna uusi salasana.",
        "change_password_code_used": "Salasanan palautuskoodi on väärä tai se on jo käytetty.",
        "no_chats": "Sinulla ei ole keskusteluja.",
        "chats": "Conversations",
        "groups": "Ryhmät",
        "rooms": "Huoneet",
        "save": "Save",
        "download": "Lataa",
        "downloading_image": "Lataa kuva",
        "an_error_has_ocurred": "On tapahtunut virhe",
        "saving_changes": "Muutosten tallentaminen",
        "go_back_to_edit": "Takaisin painokseen",
        "upload_warning_modal": "Muokkauksen lataaminen korvaa alkuperäisen kuvan.",
        "upload_question_modal": "Haluatko säilyttää alkuperäisen kuvan ja luoda kopion?",
        "create_copy_and_keep": "Luo kopio ja säilytä",
        "upload": "Lataa",
        "upload_changes": "Lataa muutokset",
        "changes_saved": "Tallennetut muutokset",
        "downloading_video": "Videon lataaminen",
        "video_upload_warning_modal": "Muokkauksen lataaminen korvaa alkuperäisen videon.",
        "results": "Tulokset",
        "members": "Jäsenet",
        "cancel": "Peruuta",
        "add_user": "Lisää käyttäjä",
        "no_messages": "Ei viestejä",
        "message_placeholder": "Viesti…",
        "group_doesnt_exists": "Ryhmää ei ole olemassa",
        "you_arent_group_member": "Et ole ryhmän jäsen",
        "touch_to_see_more_info": "Napauta nähdäksesi lisätietoja.",
        "start_videocall": "Aloita videopuhelu",
        "start_call": "Aloita puhelu",
        "create_group": "Luo ryhmä",
        "group_name": "Ryhmän nimi",
        "add_members_to_create_group": "Lisää jäseniä ryhmän luomiseksi",
        "search": "Etsi",
        "creating": "Luominen…",
        "dont_belong_anyone_group": "Et kuulu mihinkään ryhmään.",
        "already_add_this_hashtag": "Olet jo lisännyt tämän hashtagin",
        "search_files_by_hashtags": "Etsi tiedostoja hashtagien avulla",
        "enter_to_add": "Enter lisätäksesi",
        "hashtags_most_used": "Käytetyimmät hashtagit",
        "can_add_it_giving_click": "Voit lisätä nämä hashtagit hakuusi klikkaamalla niitä.",
        "searching_files": "Tiedostojen etsiminen",
        "no_files_found": "Tiedostoja ei löytynyt",
        "image": "Kuva",
        "video": "Video",
        "audio": "Audio",
        "library": "Kirjakauppa",
        "home": "Kotisivu",
        "block": "Lohko",
        "unblock": "Poista esto",
        "has_blocked_you": "Hän on blokannut sinut",
        "accept": "Hyväksyn",
        "decline": "Hylkää",
        "cancel_request": "Peruuta hakemus",
        "has_been_expulsed": "on karkotettu",
        "room_doesnt_exists": "Huonetta ei ole olemassa",
        "you_arent_room_member": "Et ole huoneen jäsen",
        "create_room": "Luo huone",
        "room_name": "Huoneen nimi",
        "no_rooms_created": "Huoneita ei ole luotu.",
        "dont_belong_to_anyone_room": "Et kuulu mihinkään huoneeseen.",
        "enter_a_username": "Kirjoita käyttäjä",
        "no_results": "Ei tuloksia",
        "create": "Luoda",
        "no_content_uploaded": "Sisältöä ei ole ladattu",
        "new_subtag": "Uusi alaetiketti",
        "update_info": "Päivitä tiedot",
        "updating": "Päivittäminen",
        "update": "Päivitys",
        "update_email": "Päivitä sähköposti",
        "change_password": "Vaihda salasana",
        "current_password": "Nykyinen salasana",
        "select": "Valitse",
        "tap_to_upload_a_file": "Lähetä tiedosto napauttamalla",
        "edit_photo": "Muokkaa kuvaa",
        "preview": "Esikatselu",
        "edit_video": "Muokkaa videota",
        "delete_changes": "Poista muutokset",
        "uploading": "Lataus",
        "session_deleted_or_expired": "Istunto lopetettu tai päättynyt",
        "edit_profile": "Muokkaa profiilia",
        "devices": "Devices",
        "downloads": "Lataukset",
        "logout": "Kirjaudu ulos",
        "offline": "Offline",
        "images": "Kuvat",
        "videos": "Videot",
        "audios": "Audios",
        "chat": "Chat",
        "session_unauthorized": "Istuntoasi ei ole hyväksytty.",
        "authorize_session": "Jotta voit käyttää tätä istuntoa, sinun on valtuutettava laitteesi.",
        "authorize_instructions": "Etsi valtuutuslinkki sähköpostistasi tai siirry aktiiviseen istuntoon ja valitse oikeassa yläkulmassa olevat kolme pistettä, siirry sitten kohtaan \"Laitteet\" ja valitse \"Valtuutus\".",
        "refresh_page_after_authorize": "Kun olet suorittanut valtuutusprosessin, päivitä sivu ja kirjaudu sisään.",
        "check_your_inbox": "Tarkista sähköpostisi",
        "send_authorization_email": "Lähetä valtuutussähköposti",
        "call_finished": "Puhelu päättyi",
        "close": "Sulje",
        "waiting_stream": "Odotetaan lähetystä",
        "request_camera_and_mic": "Tarvitsemme pääsyn kameraan ja mikrofoniin, jotta voit soittaa puheluita.",
        "call_in_progress": "Puhelu käynnissä",
        "group": "Ryhmä",
        "videocall": "Videopuhelu",
        "call": "Soita",
        "connecting": "Liittäminen",
        "call_cancelled": "Puhelu peruutettu",
        "calling": "Kutsuminen",
        "hangup": "Lopeta puhelu",
        "reject": "Hylkää",
        "has_sent_a_file": "on lähettänyt tiedoston.",
        "keep": "Ylläpidä",
        "keep_file": "Jos aktivoit tiedoston tallentamisen, tiedosto tallennetaan sovellukseen. Näin tiedosto on käytettävissä ilman Internet-yhteyttä.",
        "not_keep_file": "Jos poistat tiedostojen tallennuksen käytöstä, tiedostot poistetaan, etkä voi käyttää niitä ilman Internet-yhteyttä.",
        "file_stored_instruction": "Kaikki tallennetut tiedostot näkyvät jaettujen tiedostojen luettelossa vihreällä reunuksella. Voit myös tarkastella niitä",
        "stored": "Tallennettu",
        "not_stored": "Ei tallennettu",
        "file_not_stored_instruction": "Et voi käyttää tätä tiedostoa ilman Internet-yhteyttä.",
        "device": "Laite",
        "file_visibility": "Arkiston näkyvyys",
        "visible_for_everyone": "Näkyy kaikille",
        "shared_link": "Jaettu linkki",
        "hashtags": "Hashtagit",
        "hashtags_helps_to_search_your_file": "Hashtagit auttavat sinua löytämään tiedostosi.",
        "edit": "Muokkaa",
        "move": "Siirrä",
        "rename": "Nimeä uudelleen",
        "visibility": "Näkyvyys",
        "delete": "Poista",
        "file_warn_delete": "Haluatko varmasti poistaa tiedoston?",
        "no_subtags_availables": "Alatunnisteita ei ole saatavilla",
        "owner": "Luoja",
        "expulsing": "Ejecting",
        "expulse": "Karkottaa",
        "you_have_left_the_group": "On jättänyt ryhmän",
        "coming_out": "Poistuminen",
        "leave_group": "Ryhmästä poistuminen",
        "you_have_left_the_room": "Olet poistunut huoneesta.",
        "leave_room": "Huoneesta poistuminen",
        "message_deleted": "Viesti poistettu",
        "has_been_error_deleting_message": "Viestin poistamisessa tapahtui virhe",
        "deleting": "Poistaminen",
        "new_tag": "Uusi tunniste",
        "photoEditor": {
            "name": "Nimi",
            "save": "Tallenna",
            "saveAs": "Tallenna nimellä",
            "back": "Takaisin",
            "loading": "Lastaus…",
            "resetOperations": "Nollaa/tyhjennä kaikki toiminnot.",
            "changesLoseConfirmation": "Kaikki muutokset poistetaan.",
            "changesLoseConfirmationHint": "Haluatko varmasti jatkaa?",
            "cancel": "Peruuta",
            "continue": "Jatka",
            "undoTitle": "Peruuta edellinen toiminto",
            "redoTitle": "Viimeisimmän toiminnon toistaminen",
            "showImageTitle": "Näytä alkuperäinen kuva",
            "zoomInTitle": "Lähestymistapa",
            "zoomOutTitle": "Zoomaa ulos",
            "toggleZoomMenuTitle": "Vaihda zoomausvalikko",
            "adjustTab": "Säädä",
            "finetuneTab": "Värit",
            "filtersTab": "Suodattimet",
            "watermarkTab": "Kuvat",
            "annotateTab": "Piirustus",
            "resize": "Koon muuttaminen",
            "resizeTab": "Koko",
            "invalidImageError": "Epäkelvo kuva",
            "uploadImageError": "Kuvan lataamisessa tapahtui virhe.",
            "areNotImages": "ne eivät ole kuvia",
            "isNotImage": "ei ole kuva",
            "toBeUploaded": "ladattavaksi",
            "cropTool": "Leikataan",
            "original": "Alkuperäinen",
            "custom": "Henkilökohtainen",
            "square": "Neliö",
            "landscape": "Vaakasuora",
            "portrait": "Pystysuora",
            "ellipse": "Circle",
            "classicTv": "Klassinen TV",
            "cinemascope": "Filmilinssi",
            "arrowTool": "Nuoli",
            "blurTool": "Suhahdus",
            "brightnessTool": "Kirkkaus",
            "contrastTool": "Kontrasti",
            "ellipseTool": "Circle",
            "unFlipX": "Unflip X",
            "flipX": "Flip X",
            "unFlipY": "Unflip Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "Sävyt",
            "saturation": "Saturaatio",
            "value": "Arvo",
            "imageTool": "Kuva",
            "importing": "Tuominen…",
            "addImage": "+ Kuvan lisääminen",
            "uploadImage": "Kuvan lataaminen",
            "fromGallery": "Tarrat",
            "lineTool": "Rivi",
            "penTool": "Lyijykynä",
            "polygonTool": "Polygon",
            "sides": "Sides",
            "rectangleTool": "Suorakulmio",
            "cornerRadius": "Reunan säde",
            "resizeWidthTitle": "Leveys pikseleinä",
            "resizeHeightTitle": "Korkeus pikseleinä",
            "toggleRatioLockTitle": "Mitan lukituksen muuttaminen",
            "reset": "Käynnistä uudelleen",
            "resetSize": "Palauta kuvan alkuperäiset mitat",
            "rotateTool": "Pyöritä",
            "textTool": "Teksti",
            "textSpacings": "Tekstiväli",
            "textAlignment": "Tekstin kohdistus",
            "fontFamily": "Fonttiperhe",
            "size": "Koko",
            "letterSpacing": "Välilyönti kirjainten välillä",
            "lineHeight": "Viivan korkeus",
            "warmthTool": "Lämpö",
            "addWatermark": "+ Kuvan lisääminen",
            "addWatermarkTitle": "Valitse vesileiman tyyppi",
            "uploadWatermark": "Valitse kuva",
            "addWatermarkAsText": "Tekstin lisääminen",
            "padding": "Täyttö",
            "shadow": "Varjo",
            "horizontal": "Vaakasuora",
            "vertical": "Pystysuora",
            "blur": "Suhahdus",
            "opacity": "Opasiteetti",
            "position": "Asema",
            "stroke": "Paksuus",
            "saveAsModalLabel": "Yksityiskohdat",
            "extension": "Laajennus",
            "nameIsRequired": "Nimi on pakollinen.",
            "quality": "Laatu",
            "imageDimensionsHoverTitle": "Tallennetun kuvan mitat (leveys x korkeus)",
            "cropSizeLowerThanResizedWarning": "Huomaa, että valittu rajausalue on pienempi kuin sovellettu koon muutos, mikä voi heikentää laatua.",
            "actualSize": "Nykyinen koko (100 %)",
            "fitSize": "Mukauta ulottuvuus",
            "addImageTitle": "Valitse lisättävä kuva…"
        },
        "changing": "Muuttuvat",
        "profile_picture_changed": "Profiilikuva muutettu",
        "loading_profile_picture": "Profiilikuvan lataus",
        "change": "Muuta",
        "session": "Istunto",
        "system_operative": "Käyttöjärjestelmä",
        "navigator": "Selain",
        "authorize": "Valtuutus",
        "deauthorize": "Deauthorise",
        "keep_tag_content": "Haluatko säilyttää etiketin sisällön?",
        "move_content_to": "Siirrä sisältö osoitteeseen",
        "move_to": "Siirry osoitteeseen",
        "chat_request": "Chat-pyyntö",
        "accept_request": "Hyväksy hakemus",
        "go_to_chat": "Siirry chattiin",
        "applying": "Soveltamalla",
        "time": "Sää",
        "adjust": "Säädä",
        "rotate": "Pyöritä",
        "start": "Koti",
        "end": "Lopulliset",
        "lang_name": "Finnish"
    },
    "fr": {
        "name": "Nom",
        "name_example": "Jean",
        "last_name": "Nom de famille",
        "last_name_example": "Martin",
        "username": "Utilisateur",
        "username_example": "jean_martin",
        "email": "Adresse électronique",
        "email_example": "utilisateur@domaine.com",
        "password": "Mot de passe",
        "new_password": "Nouveau mot de passe",
        "password_example": "*****************",
        "login": "Se connecter",
        "register": "Registre",
        "verification_code": "Code de vérification",
        "verification_code_example": "xxx",
        "verify": "Vérifier",
        "loading": "Chargement…",
        "please_wait": "Veuillez patienter",
        "go_back": "Retour à",
        "recovery": "Récupérer",
        "login_subtitle": "Saisissez vos coordonnées et connectez-vous à votre compte.",
        "forgot_password_link": "Vous avez oublié votre mot de passe ? Récupérer.",
        "register_account_link": "Vous n'avez pas de compte ? S'inscrire",
        "register_subtitle": "Saisissez vos coordonnées et enregistrez votre compte.",
        "login_link": "Vous avez déjà un compte ? Connectez-vous !",
        "verification_code_instruction": "Vérifiez votre courrier électronique et saisissez le code envoyé.",
        "forgot_password_subtitle": "Saisissez votre adresse électronique pour récupérer votre mot de passe.",
        "change_password_subtitle": "Entrez un nouveau mot de passe.",
        "change_password_code_used": "Le code pour récupérer votre mot de passe n'est pas valide ou a déjà été utilisé.",
        "no_chats": "Vous n'avez pas de conversations.",
        "chats": "Conversations",
        "groups": "Groupes",
        "rooms": "Chambres",
        "save": "Sauvegarder",
        "download": "Télécharger",
        "downloading_image": "Téléchargement de l'image",
        "an_error_has_ocurred": "Une erreur s'est produite",
        "saving_changes": "Sauvegarde des modifications",
        "go_back_to_edit": "Retour à l'édition",
        "upload_warning_modal": "Le téléchargement de la modification remplacera l'image originale.",
        "upload_question_modal": "Voulez-vous conserver l'original et créer une copie ?",
        "create_copy_and_keep": "Créer une copie et la conserver",
        "upload": "Charger",
        "upload_changes": "Télécharger les changements",
        "changes_saved": "Modifications sauvegardées",
        "downloading_video": "Téléchargement de vidéos",
        "video_upload_warning_modal": "Le téléchargement du montage remplacera la vidéo originale.",
        "results": "Résultats",
        "members": "Membres",
        "cancel": "Annuler",
        "add_user": "Ajouter un utilisateur",
        "no_messages": "Pas de messages",
        "message_placeholder": "Message…",
        "group_doesnt_exists": "Le groupe n'existe pas",
        "you_arent_group_member": "Vous n'êtes pas membre du groupe",
        "touch_to_see_more_info": "Touchez pour obtenir plus d'informations.",
        "start_videocall": "Démarrer l'appel vidéo",
        "start_call": "Démarrer l'appel",
        "create_group": "Créer un groupe",
        "group_name": "Nom du groupe",
        "add_members_to_create_group": "Ajouter des membres pour créer le groupe",
        "search": "Rechercher",
        "creating": "Créer…",
        "dont_belong_anyone_group": "Vous n'appartenez à aucun groupe.",
        "already_add_this_hashtag": "Vous avez déjà ajouté ce hashtag",
        "search_files_by_hashtags": "Recherche de fichiers à l'aide de hashtags",
        "enter_to_add": "Entrer pour ajouter",
        "hashtags_most_used": "Les hashtags les plus utilisés",
        "can_add_it_giving_click": "Vous pouvez les ajouter à votre recherche en cliquant dessus.",
        "searching_files": "Recherche de fichiers",
        "no_files_found": "Aucun fichier trouvé",
        "image": "Image",
        "video": "Vidéo",
        "audio": "Audio",
        "library": "Librairie",
        "home": "Accueil",
        "block": "Bloquer",
        "unblock": "Débloquer",
        "has_blocked_you": "Il vous a bloqué",
        "accept": "Accepter",
        "decline": "Rejeter",
        "cancel_request": "Annuler la demande",
        "has_been_expulsed": "a été expulsé",
        "room_doesnt_exists": "La pièce n'existe pas",
        "you_arent_room_member": "Vous n'êtes pas membre de la salle",
        "create_room": "Créer une pièce",
        "room_name": "Nom de la pièce",
        "no_rooms_created": "Aucune pièce n'est créée.",
        "dont_belong_to_anyone_room": "Vous n'appartenez à aucune pièce.",
        "enter_a_username": "Écrire à un utilisateur",
        "no_results": "Pas de résultats",
        "create": "Créer",
        "no_content_uploaded": "Pas de contenu téléchargé",
        "new_subtag": "Nouvelle sous-étiquette",
        "update_info": "Mise à jour des informations",
        "updating": "Mise à jour",
        "update": "Actualisation",
        "update_email": "Mise à jour de l'email",
        "change_password": "Modifier le mot de passe",
        "current_password": "Mot de passe actuel",
        "select": "Sélectionner",
        "tap_to_upload_a_file": "Pointez pour télécharger un fichier",
        "edit_photo": "Modifier la photo",
        "preview": "Avant-première",
        "edit_video": "Editer la vidéo",
        "delete_changes": "Supprimer les modifications",
        "uploading": "Chargement",
        "session_deleted_or_expired": "Session supprimée ou expirée",
        "edit_profile": "Modifier le profil",
        "devices": "Dispositifs",
        "downloads": "Téléchargements",
        "logout": "Déconnexion",
        "offline": "Hors ligne",
        "images": "Images",
        "videos": "Vidéos",
        "audios": "Audios",
        "chat": "Chat",
        "session_unauthorized": "Votre session n'est pas autorisée.",
        "authorize_session": "Pour accéder à cette session, vous devez autoriser votre appareil.",
        "authorize_instructions": "Cherchez le lien d'autorisation dans votre courrier électronique ou allez dans une session active et sélectionnez les trois points dans le coin supérieur droit, puis allez dans \"Devices\" et sélectionnez \"Authorise\".",
        "refresh_page_after_authorize": "Une fois la procédure d'autorisation terminée, rafraîchissez la page de connexion.",
        "check_your_inbox": "Vérifiez votre courrier électronique",
        "send_authorization_email": "Envoyer un courriel d'autorisation",
        "call_finished": "Appel terminé",
        "close": "Fermer",
        "waiting_stream": "En attente de transmission",
        "request_camera_and_mic": "Nous devons avoir accès à votre caméra et à votre microphone pour que vous puissiez passer des appels.",
        "call_in_progress": "Appel en cours",
        "group": "Groupe",
        "videocall": "Appel vidéo",
        "call": "Appel",
        "connecting": "Connexion",
        "call_cancelled": "Appel annulé",
        "calling": "Appeler",
        "hangup": "Raccrocher",
        "reject": "Rejeter",
        "has_sent_a_file": "a envoyé un fichier.",
        "keep": "Conserver",
        "keep_file": "Si vous activez la conservation des fichiers, le fichier sera sauvegardé dans l'application. Cela signifie qu'il est possible d'y accéder sans connexion internet.",
        "not_keep_file": "Si vous désactivez la conservation des fichiers, le fichier sera supprimé et vous ne pourrez pas y accéder sans connexion Internet.",
        "file_stored_instruction": "Tous les fichiers stockés apparaissent avec un cadre vert dans la liste des fichiers communs. Vous pouvez également les visualiser dans la fenêtre",
        "stored": "Stocké à",
        "not_stored": "Non stocké",
        "file_not_stored_instruction": "Vous ne pourrez pas accéder à ce fichier sans une connexion Internet.",
        "device": "Dispositif",
        "file_visibility": "Visibilité des archives",
        "visible_for_everyone": "Visible par tous",
        "shared_link": "Lien partagé",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Les hashtags facilitent la recherche de vos fichiers.",
        "edit": "Editer",
        "move": "Déplacer",
        "rename": "Renommer",
        "visibility": "Visibilité",
        "delete": "Supprimer",
        "file_warn_delete": "Êtes-vous sûr de vouloir supprimer le fichier ?",
        "no_subtags_availables": "Pas de sous-balises disponibles",
        "owner": "Créateur",
        "expulsing": "Ejection",
        "expulse": "Expulser",
        "you_have_left_the_group": "Vous avez quitté le groupe",
        "coming_out": "Départ",
        "leave_group": "Quitter le groupe",
        "you_have_left_the_room": "Vous avez quitté la pièce.",
        "leave_room": "Quitter la salle",
        "message_deleted": "Message supprimé",
        "has_been_error_deleting_message": "Une erreur s'est produite lors de la suppression du message",
        "deleting": "Élimination",
        "new_tag": "Nouveau tag",
        "photoEditor": {
            "name": "Nom",
            "save": "Terminer",
            "saveAs": "Sauvegarder sous",
            "back": "Retour",
            "loading": "Chargement…",
            "resetOperations": "Réinitialiser/supprimer toutes les opérations.",
            "changesLoseConfirmation": "Toutes les modifications seront supprimées.",
            "changesLoseConfirmationHint": "Êtes-vous sûr de vouloir continuer ?",
            "cancel": "Annuler",
            "continue": "Continuer",
            "undoTitle": "Annuler la dernière opération",
            "redoTitle": "Rétablir la dernière opération",
            "showImageTitle": "Afficher l'image originale",
            "zoomInTitle": "Zoomer",
            "zoomOutTitle": "Dézoomer",
            "toggleZoomMenuTitle": "Afficher le menu zoom",
            "adjustTab": "Ajuster",
            "finetuneTab": "Couleurs",
            "filtersTab": "Filtres",
            "watermarkTab": "Images",
            "annotateTab": "Dessin",
            "resize": "Redimensionner",
            "resizeTab": "Taille",
            "invalidImageError": "Image invalide",
            "uploadImageError": "Une erreur s'est produite lors du chargement de l'image.",
            "areNotImages": "ce ne sont pas des images",
            "isNotImage": "n'est pas une image",
            "toBeUploaded": "à charger",
            "cropTool": "Couper",
            "original": "Original",
            "custom": "Personnalisé",
            "square": "Carré",
            "landscape": "Horizontal",
            "portrait": "Vertical",
            "ellipse": "Cercle",
            "classicTv": "TV classique",
            "cinemascope": "Cinémascope",
            "arrowTool": "Flèche",
            "blurTool": "Flou",
            "brightnessTool": "Luminosité",
            "contrastTool": "Contraste",
            "ellipseTool": "Cercle",
            "unFlipX": "Déplacer X",
            "flipX": "Retournement X",
            "unFlipY": "Déplacer Y",
            "flipY": "Retournement Y",
            "hsvTool": "MSV",
            "hue": "Nuance",
            "saturation": "Saturation",
            "value": "Valeur",
            "imageTool": "Image",
            "importing": "Importation…",
            "addImage": "+ Ajouter une image",
            "uploadImage": "Charger une image",
            "fromGallery": "Autocollants",
            "lineTool": "Ligne",
            "penTool": "Crayon",
            "polygonTool": "Polygone",
            "sides": "Côtés",
            "rectangleTool": "Rectangle",
            "cornerRadius": "Rayon de l'arête",
            "resizeWidthTitle": "Largeur en pixels",
            "resizeHeightTitle": "Hauteur en pixels",
            "toggleRatioLockTitle": "Verrouillage des dimensions",
            "reset": "Redémarrage",
            "resetSize": "Rétablissement des dimensions originales de l'image",
            "rotateTool": "Rotation",
            "textTool": "Texte",
            "textSpacings": "Espacement du texte",
            "textAlignment": "Alignement du texte",
            "fontFamily": "Police",
            "size": "Taille",
            "letterSpacing": "Espacement des lettres",
            "lineHeight": "Hauteur de la ligne",
            "warmthTool": "Chaleur",
            "addWatermark": "+ Ajouter une image",
            "addWatermarkTitle": "Choisir le type de filigrane",
            "uploadWatermark": "Sélectionner l'image en filigrane",
            "addWatermarkAsText": "Ajouter un filigrane ou un texte",
            "padding": "Remplissage",
            "shadow": "Ombre",
            "horizontal": "Horizontal",
            "vertical": "Vertical",
            "blur": "Flou",
            "opacity": "Opacité",
            "position": "Position",
            "stroke": "Épaisseur",
            "saveAsModalLabel": "Détails",
            "extension": "Extension",
            "nameIsRequired": "Le nom est obligatoire.",
            "quality": "Qualité",
            "imageDimensionsHoverTitle": "Dimensions de l'image enregistrée (largeur x hauteur)",
            "cropSizeLowerThanResizedWarning": "Notez que la zone de recadrage sélectionnée est plus petite que le redimensionnement appliqué, ce qui peut entraîner une réduction de la qualité.",
            "actualSize": "Taille actuelle (100%)",
            "fitSize": "Adapter la dimension",
            "addImageTitle": "Sélectionner une image à ajouter…"
        },
        "changing": "Changer",
        "profile_picture_changed": "L'image du profil a été modifiée",
        "loading_profile_picture": "Chargement de l'image de profil",
        "change": "Changer",
        "session": "Session",
        "system_operative": "Système d'exploitation",
        "navigator": "Navigateur",
        "authorize": "Autoriser",
        "deauthorize": "Désautoriser",
        "keep_tag_content": "Voulez-vous conserver le contenu de la balise ?",
        "move_content_to": "Déplacer le contenu vers",
        "move_to": "Passer à",
        "chat_request": "Demande de chat",
        "accept_request": "Accepter la demande",
        "go_to_chat": "Aller au chat",
        "applying": "Application",
        "time": "Temps",
        "adjust": "Ajuster",
        "rotate": "Tourner",
        "start": "Accueil",
        "end": "Finale",
        "lang_name": "French"
    },
    "de": {
        "name": "Name",
        "name_example": "Peter",
        "last_name": "Nachname",
        "last_name_example": "Müller",
        "username": "Benutzer",
        "username_example": "peter_müller",
        "email": "E-Mail Adresse",
        "email_example": "benutzer@domain.com",
        "password": "Passwort",
        "new_password": "Neues Passwort",
        "password_example": "*****************",
        "login": "Anmeldung",
        "register": "Registrieren",
        "verification_code": "Verifizierungscode",
        "verification_code_example": "xxx",
        "verify": "Überprüfen Sie",
        "loading": "Laden…",
        "please_wait": "Bitte warten",
        "go_back": "Zurück zu",
        "recovery": "Abrufen",
        "login_subtitle": "Geben Sie Ihre Daten ein und melden Sie sich bei Ihrem Konto an.",
        "forgot_password_link": "Haben Sie Ihr Passwort vergessen? Wiederherstellen.",
        "register_account_link": "Sie haben noch kein Konto? Jetzt registrieren",
        "register_subtitle": "Geben Sie Ihre Daten ein und registrieren Sie Ihr Konto.",
        "login_link": "Sie haben bereits ein Konto? Jetzt anmelden",
        "verification_code_instruction": "Überprüfen Sie Ihre E-Mail-Adresse und geben Sie den Code ein, der Ihnen zugesandt wurde.",
        "forgot_password_subtitle": "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort abzurufen.",
        "change_password_subtitle": "Geben Sie ein neues Passwort ein.",
        "change_password_code_used": "Der Code zur Wiederherstellung Ihres Passworts ist ungültig oder wurde bereits verwendet.",
        "no_chats": "Ihr führt keine Gespräche.",
        "chats": "Konversationen",
        "groups": "Gruppen",
        "rooms": "Zimmer",
        "save": "Speichern Sie",
        "download": "Herunterladen",
        "downloading_image": "Herunterladen des Bildes",
        "an_error_has_ocurred": "Es ist ein Fehler aufgetreten",
        "saving_changes": "Speichern von Änderungen",
        "go_back_to_edit": "Zurück zu bearbeiten",
        "upload_warning_modal": "Das Hochladen des bearbeiteten Bildes ersetzt das Originalbild.",
        "upload_question_modal": "Wollen Sie das Original behalten und eine Kopie machen?",
        "create_copy_and_keep": "Kopie erstellen und aufbewahren",
        "upload": "Hochladen",
        "upload_changes": "Änderungen hochladen",
        "changes_saved": "Gespeicherte Änderungen",
        "downloading_video": "Herunterladen von Video",
        "video_upload_warning_modal": "Durch das Hochladen der Bearbeitung wird das Originalvideo ersetzt.",
        "results": "Ergebnisse",
        "members": "Mitglieder",
        "cancel": "Abbrechen",
        "add_user": "Benutzer hinzufügen",
        "no_messages": "Keine Meldungen",
        "message_placeholder": "Nachricht…",
        "group_doesnt_exists": "Die Gruppe existiert nicht",
        "you_arent_group_member": "Sie sind kein Mitglied der Gruppe",
        "touch_to_see_more_info": "Tippen Sie auf , um weitere Informationen zu erhalten.",
        "start_videocall": "Videoanruf starten",
        "start_call": "Anruf starten",
        "create_group": "Gruppe erstellen",
        "group_name": "Name der Gruppe",
        "add_members_to_create_group": "Mitglieder hinzufügen, um die Gruppe zu erstellen",
        "search": "Suche",
        "creating": "Erstellen von…",
        "dont_belong_anyone_group": "Sie gehören zu keiner Gruppe.",
        "already_add_this_hashtag": "Sie haben diesen Hashtag bereits hinzugefügt",
        "search_files_by_hashtags": "Suche nach Dateien anhand von Hashtags",
        "enter_to_add": "Eingabe zum Hinzufügen",
        "hashtags_most_used": "Meist verwendete Hashtags",
        "can_add_it_giving_click": "Sie können diese durch Anklicken zu Ihrer Suche hinzufügen.",
        "searching_files": "Suche nach Dateien",
        "no_files_found": "Keine Dateien gefunden",
        "image": "Bild",
        "video": "Video",
        "audio": "Audio",
        "library": "Buchhandlung",
        "home": "Hauptseite",
        "block": "Block",
        "unblock": "Entsperren",
        "has_blocked_you": "Er hat Sie blockiert",
        "accept": "Akzeptieren",
        "decline": "Ablehnen",
        "cancel_request": "Anfrage stornieren",
        "has_been_expulsed": "ist ausgeschlossen worden",
        "room_doesnt_exists": "Der Raum existiert nicht",
        "you_arent_room_member": "Sie sind kein Mitglied des Raumes",
        "create_room": "Raum erstellen",
        "room_name": "Name des Zimmers",
        "no_rooms_created": "Es wurden keine Räume geschaffen.",
        "dont_belong_to_anyone_room": "Sie gehören zu keinem Raum.",
        "enter_a_username": "Schreiben Sie einen Benutzer",
        "no_results": "Keine Ergebnisse",
        "create": "Erstellen",
        "no_content_uploaded": "Keine Inhalte hochgeladen",
        "new_subtag": "Neues Untertag",
        "update_info": "Informationen aktualisieren",
        "updating": "Aktualisierung von",
        "update": "Aktualisierung",
        "update_email": "E-Mail aktualisieren",
        "change_password": "Passwort ändern",
        "current_password": "Aktuelles Passwort",
        "select": "Wählen Sie",
        "tap_to_upload_a_file": "Tippen Sie auf , um eine Datei hochzuladen",
        "edit_photo": "Foto bearbeiten",
        "preview": "Vorschau",
        "edit_video": "Video bearbeiten",
        "delete_changes": "Änderungen löschen",
        "uploading": "Hochladen",
        "session_deleted_or_expired": "Sitzung gelöscht oder abgelaufen",
        "edit_profile": "Profil bearbeiten",
        "devices": "Geräte",
        "downloads": "Downloads",
        "logout": "Abmelden",
        "offline": "Offline",
        "images": "Bilder",
        "videos": "Videos",
        "audios": "Audios",
        "chat": "Chat",
        "session_unauthorized": "Ihre Sitzung ist nicht autorisiert.",
        "authorize_session": "Um auf diese Sitzung zuzugreifen, müssen Sie Ihr Gerät autorisieren.",
        "authorize_instructions": "Suchen Sie in Ihrer E-Mail nach dem Autorisierungslink oder gehen Sie zu einer aktiven Sitzung und wählen Sie die drei Punkte in der oberen rechten Ecke, dann gehen Sie zu \"Geräte\" und wählen \"Autorisieren\".",
        "refresh_page_after_authorize": "Wenn Sie den Autorisierungsprozess abgeschlossen haben, aktualisieren Sie die Anmeldeseite.",
        "check_your_inbox": "Prüfen Sie Ihre E-Mail",
        "send_authorization_email": "Autorisierungs-E-Mail senden",
        "call_finished": "Anruf beendet",
        "close": "Schließen Sie",
        "waiting_stream": "Warten auf Übermittlung",
        "request_camera_and_mic": "Wir benötigen Zugriff auf Ihre Kamera und Ihr Mikrofon, damit Sie Anrufe tätigen können.",
        "call_in_progress": "Anruf in Bearbeitung",
        "group": "Gruppe",
        "videocall": "Videoanruf",
        "call": "Aufrufen",
        "connecting": "Verbinden von",
        "call_cancelled": "Anruf abgebrochen",
        "calling": "Aufruf von",
        "hangup": "Auflegen",
        "reject": "Ablehnen",
        "has_sent_a_file": "hat eine Datei gesendet.",
        "keep": "Bewahren",
        "keep_file": "Wenn Sie die Dateispeicherung aktivieren, wird die Datei in der Anwendung gespeichert. So können Sie auf die Datei zugreifen, ohne dass eine Internetverbindung erforderlich ist.",
        "not_keep_file": "Wenn Sie die Dateispeicherung deaktivieren, wird die Datei gelöscht und Sie können nicht mehr ohne Internetverbindung darauf zugreifen.",
        "file_stored_instruction": "Alle gespeicherten Dateien werden in der allgemeinen Dateiliste mit einem grünen Rahmen angezeigt. Sie können sie auch im Fenster",
        "stored": "Gelagert",
        "not_stored": "Nicht gespeichert",
        "file_not_stored_instruction": "Ohne Internetverbindung können Sie auf diese Datei nicht zugreifen.",
        "device": "Gerät",
        "file_visibility": "Sichtbarkeit im Archiv",
        "visible_for_everyone": "Sichtbar für alle",
        "shared_link": "Geteilter Link",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Hashtags helfen bei der Suche nach Ihren Dateien.",
        "edit": "Bearbeiten",
        "move": "Verschieben",
        "rename": "Umbenennen",
        "visibility": "Sichtbarkeit",
        "delete": "Löschen",
        "file_warn_delete": "Sind Sie sicher, dass Sie die Datei löschen wollen?",
        "no_subtags_availables": "Keine Subtags verfügbar",
        "owner": "Schöpfer",
        "expulsing": "Auswerfen",
        "expulse": "Vertreiben",
        "you_have_left_the_group": "Sie haben die Gruppe verlassen",
        "coming_out": "Verlassen von",
        "leave_group": "Verlassen Sie die Gruppe",
        "you_have_left_the_room": "Sie haben den Raum verlassen.",
        "leave_room": "Verlassen des Raums",
        "message_deleted": "Nachricht gelöscht",
        "has_been_error_deleting_message": "Beim Löschen der Nachricht ist ein Fehler aufgetreten",
        "deleting": "Löschen",
        "new_tag": "Neues Etikett",
        "photoEditor": {
            "name": "Name",
            "save": "Speichern",
            "saveAs": "Speichern unter",
            "back": "Zurück",
            "loading": "Laden…",
            "resetOperations": "Alle Operationen zurücksetzen/löschen.",
            "changesLoseConfirmation": "Alle Änderungen werden gelöscht.",
            "changesLoseConfirmationHint": "Sind Sie sicher, dass Sie weitermachen wollen?",
            "cancel": "Abbrechen",
            "continue": "Fortfahren",
            "undoTitle": "Letzte Operation rückgängig machen",
            "redoTitle": "Letzte Operation wiederholen",
            "showImageTitle": "Originalbild anzeigen",
            "zoomInTitle": "Vergrößern",
            "zoomOutTitle": "Verkleinern",
            "toggleZoomMenuTitle": "Menü \"Zoom\" umschalten",
            "adjustTab": "Einstellen",
            "finetuneTab": "Farben",
            "filtersTab": "Filter",
            "watermarkTab": "Bilder",
            "annotateTab": "Zeichnung",
            "resize": "Größe ändern",
            "resizeTab": "Größe",
            "invalidImageError": "Ungültiges Bild",
            "uploadImageError": "Beim Laden des Bildes ist ein Fehler aufgetreten.",
            "areNotImages": "sie sind keine Bilder",
            "isNotImage": "ist kein Bild",
            "toBeUploaded": "zum Hochladen",
            "cropTool": "Schnitt",
            "original": "Original",
            "custom": "Personalisierte",
            "square": "Quadratisch",
            "landscape": "Horizontal",
            "portrait": "Vertikal",
            "ellipse": "Kreis",
            "classicTv": "Klassisches TV",
            "cinemascope": "Cinemascope",
            "arrowTool": "Pfeil",
            "blurTool": "Unschärfe",
            "brightnessTool": "Helligkeit",
            "contrastTool": "Kontrast",
            "ellipseTool": "Kreis",
            "unFlipX": "Aufklappen X",
            "flipX": "Flip X",
            "unFlipY": "Aufklappen Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "Nuance",
            "saturation": "Sättigung",
            "value": "Wert",
            "imageTool": "Bild",
            "importing": "Importieren von…",
            "addImage": "+ Bild hinzufügen",
            "uploadImage": "Bild hochladen",
            "fromGallery": "Aufkleber",
            "lineTool": "Linie",
            "penTool": "Bleistift",
            "polygonTool": "Polygon",
            "sides": "Seiten",
            "rectangleTool": "Rechteck",
            "cornerRadius": "Kantenradius",
            "resizeWidthTitle": "Breite in Pixel",
            "resizeHeightTitle": "Höhe in Pixel",
            "toggleRatioLockTitle": "Dimensionssperre umschalten",
            "reset": "Neustart",
            "resetSize": "Zurücksetzen auf die ursprünglichen Bildabmessungen",
            "rotateTool": "Drehen Sie",
            "textTool": "Text",
            "textSpacings": "Textabstände",
            "textAlignment": "Textausrichtung",
            "fontFamily": "Schriftfamilie",
            "size": "Größe",
            "letterSpacing": "Buchstabenabstände",
            "lineHeight": "Höhe der Linie",
            "warmthTool": "Wärme",
            "addWatermark": "+ Bild hinzufügen",
            "addWatermarkTitle": "Wählen Sie die Art des Wasserzeichens",
            "uploadWatermark": "Bild auswählen",
            "addWatermarkAsText": "Text hinzufügen",
            "padding": "Füllen",
            "shadow": "Schatten",
            "horizontal": "Horizontal",
            "vertical": "Vertikal",
            "blur": "Unschärfe",
            "opacity": "Deckkraft",
            "position": "Position",
            "stroke": "Dicke",
            "saveAsModalLabel": "Einzelheiten",
            "extension": "Erweiterung",
            "nameIsRequired": "Der Name ist erforderlich.",
            "quality": "Qualität",
            "imageDimensionsHoverTitle": "Gespeicherte Bildabmessungen (Breite x Höhe)",
            "cropSizeLowerThanResizedWarning": "Beachten Sie, dass der gewählte Beschnittbereich kleiner ist als die angewandte Größenänderung, was zu einem Qualitätsverlust führen kann.",
            "actualSize": "Aktuelle Größe (100%)",
            "fitSize": "Abmessungen anpassen",
            "addImageTitle": "Wählen Sie ein Bild zum Hinzufügen…"
        },
        "changing": "Ändern von",
        "profile_picture_changed": "Profilbild geändert",
        "loading_profile_picture": "Profilbild laden",
        "change": "Ändern Sie",
        "session": "Session",
        "system_operative": "Das Betriebssystem",
        "navigator": "Browser",
        "authorize": "Autorisieren Sie",
        "deauthorize": "Deautorisierung",
        "keep_tag_content": "Möchten Sie den Inhalt des Tags beibehalten?",
        "move_content_to": "Inhalt verschieben nach",
        "move_to": "Umzug nach",
        "chat_request": "Chat-Anfrage",
        "accept_request": "Bewerbung annehmen",
        "go_to_chat": "Zum Chat gehen",
        "applying": "Anwendung von",
        "time": "Zeit",
        "adjust": "Einstellen",
        "rotate": "Drehen Sie",
        "start": "Start",
        "end": "Ende",
        "lang_name": "German"
    },
    "el": {
        "name": "Όνομα",
        "name_example": "Elián",
        "last_name": "Επώνυμο",
        "last_name_example": "Georgiou",
        "username": "Χρήστης",
        "username_example": "elian_georgiou",
        "email": "Ηλεκτρονική διεύθυνση",
        "email_example": "χρήστης@τομέας.com",
        "password": "Κωδικός πρόσβασης",
        "new_password": "Νέος κωδικός πρόσβασης",
        "password_example": "*****************",
        "login": "Είσοδος",
        "register": "Εγγραφή",
        "verification_code": "Κωδικός επαλήθευσης",
        "verification_code_example": "xxx",
        "verify": "Έλεγχος",
        "loading": "Φόρτωση…",
        "please_wait": "Παρακαλώ περιμένετε",
        "go_back": "Πίσω στο",
        "recovery": "Ανακτήστε",
        "login_subtitle": "Εισάγετε τα στοιχεία σας και συνδεθείτε στο λογαριασμό σας.",
        "forgot_password_link": "Ξεχάσατε τον κωδικό πρόσβασής σας; Ανακτήστε τον.",
        "register_account_link": "Δεν έχετε λογαριασμό; Εγγραφείτε",
        "register_subtitle": "Εισάγετε τα στοιχεία σας και εγγραφείτε.",
        "login_link": "Έχετε ήδη λογαριασμό; Συνδεθείτε!",
        "verification_code_instruction": "Ελέγξτε το email σας και πληκτρολογήστε τον κωδικό που σας στάλθηκε.",
        "forgot_password_subtitle": "Εισάγετε τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας για να ανακτήσετε τον κωδικό πρόσβασής σας.",
        "change_password_subtitle": "Εισάγετε έναν νέο κωδικό πρόσβασης.",
        "change_password_code_used": "Ο κωδικός πρόσβασης για την ανάκτηση του κωδικού πρόσβασής σας είναι άκυρος ή έχει ήδη χρησιμοποιηθεί.",
        "no_chats": "Δεν κάνετε συζητήσεις.",
        "chats": "Συνομιλίες",
        "groups": "Ομάδες",
        "rooms": "Δωμάτια",
        "save": "Αποθήκευση",
        "download": "Λήψη",
        "downloading_image": "Λήψη εικόνας",
        "an_error_has_ocurred": "Εμφανίστηκε σφάλμα",
        "saving_changes": "Αποθήκευση αλλαγών",
        "go_back_to_edit": "Πίσω στην επεξεργασία",
        "upload_warning_modal": "Το ανέβασμα της επεξεργασίας θα αντικαταστήσει την αρχική εικόνα.",
        "upload_question_modal": "Θέλετε να κρατήσετε το πρωτότυπο και να φτιάξετε ένα αντίγραφο;",
        "create_copy_and_keep": "Δημιουργήστε ένα αντίγραφο και αποθηκεύστε το",
        "upload": "Ανέβασμα",
        "upload_changes": "Ανέβασμα αλλαγών",
        "changes_saved": "Αποθηκευμένες αλλαγές",
        "downloading_video": "Λήψη βίντεο",
        "video_upload_warning_modal": "Το ανέβασμα της επεξεργασίας θα αντικαταστήσει το αρχικό βίντεο.",
        "results": "Αποτελέσματα",
        "members": "Μέλη",
        "cancel": "Ακύρωση",
        "add_user": "Προσθήκη χρήστη",
        "no_messages": "Δεν υπάρχουν μηνύματα",
        "message_placeholder": "Μήνυμα…",
        "group_doesnt_exists": "Η ομάδα δεν υφίσταται",
        "you_arent_group_member": "Δεν είστε μέλος της ομάδας",
        "touch_to_see_more_info": "Πατήστε για να δείτε περισσότερες πληροφορίες.",
        "start_videocall": "Έναρξη βιντεοκλήσης",
        "start_call": "Έναρξη κλήσης",
        "create_group": "Δημιουργία ομάδας",
        "group_name": "Όνομα της ομάδας",
        "add_members_to_create_group": "Προσθέστε μέλη για να δημιουργήσετε την ομάδα",
        "search": "Αναζήτηση",
        "creating": "Δημιουργώντας…",
        "dont_belong_anyone_group": "Δεν ανήκετε σε καμία ομάδα.",
        "already_add_this_hashtag": "Έχετε ήδη προσθέσει αυτό το hashtag",
        "search_files_by_hashtags": "Αναζήτηση αρχείων με hashtags",
        "enter_to_add": "Πληκτρολογήστε για να προσθέσετε",
        "hashtags_most_used": "Τα πιο συχνά χρησιμοποιούμενα hashtags",
        "can_add_it_giving_click": "Μπορείτε να τα προσθέσετε στην αναζήτησή σας κάνοντας κλικ πάνω τους.",
        "searching_files": "Αναζήτηση αρχείων",
        "no_files_found": "Δεν βρέθηκαν αρχεία",
        "image": "Εικόνα",
        "video": "Βίντεο",
        "audio": "Ήχος",
        "library": "Βιβλιοπωλείο",
        "home": "Αρχική σελίδα",
        "block": "Μπλόκο",
        "unblock": "Ξεμπλοκάρισμα",
        "has_blocked_you": "Σας έχει μπλοκάρει",
        "accept": "Αποδοχή",
        "decline": "Απόρριψη",
        "cancel_request": "Ακύρωση αιτήματος",
        "has_been_expulsed": "έχει αποβληθεί",
        "room_doesnt_exists": "Το δωμάτιο δεν υπάρχει",
        "you_arent_room_member": "Δεν είστε μέλος της αίθουσας",
        "create_room": "Δημιουργήστε δωμάτιο",
        "room_name": "Όνομα του δωματίου",
        "no_rooms_created": "Δεν έχουν δημιουργηθεί δωμάτια.",
        "dont_belong_to_anyone_room": "Δεν ανήκετε σε κανένα δωμάτιο.",
        "enter_a_username": "Γράψτε έναν χρήστη",
        "no_results": "Δεν υπάρχουν αποτελέσματα",
        "create": "Δημιουργία",
        "no_content_uploaded": "Δεν έχει ανεβεί περιεχόμενο",
        "new_subtag": "Νέα υποετικέτα",
        "update_info": "Ενημέρωση πληροφοριών",
        "updating": "Ενημέρωση",
        "update": "Ενημέρωση",
        "update_email": "Ενημέρωση e-mail",
        "change_password": "Αλλαγή κωδικού πρόσβασης",
        "current_password": "Τρέχων κωδικός πρόσβασης",
        "select": "Επιλέξτε",
        "tap_to_upload_a_file": "Πατήστε για να ανεβάσετε ένα αρχείο",
        "edit_photo": "Επεξεργασία φωτογραφίας",
        "preview": "Προεπισκόπηση",
        "edit_video": "Επεξεργασία βίντεο",
        "delete_changes": "Διαγραφή αλλαγών",
        "uploading": "Φόρτωση",
        "session_deleted_or_expired": "Σύνοδος διαγράφηκε ή έληξε",
        "edit_profile": "Επεξεργασία προφίλ",
        "devices": "Συσκευές",
        "downloads": "Λήψεις",
        "logout": "Αποσύνδεση",
        "offline": "Χωρίς σύνδεση",
        "images": "Εικόνες",
        "videos": "Βίντεο",
        "audios": "Ακουστικά αρχεία",
        "chat": "Συνομιλία",
        "session_unauthorized": "Η συνεδρία σας δεν είναι εξουσιοδοτημένη.",
        "authorize_session": "Για να αποκτήσετε πρόσβαση σε αυτή τη συνεδρία, πρέπει να εξουσιοδοτήσετε τη συσκευή σας.",
        "authorize_instructions": "Ελέγξτε το email σας για το σύνδεσμο εξουσιοδότησης ή μεταβείτε σε μια ενεργή συνεδρία και επιλέξτε τις τρεις τελείες στην επάνω δεξιά γωνία, στη συνέχεια μεταβείτε στις \"Συσκευές\" και επιλέξτε \"Εξουσιοδότηση\".",
        "refresh_page_after_authorize": "Μόλις ολοκληρωθεί η διαδικασία εξουσιοδότησης, ανανεώστε τη σελίδα σύνδεσης.",
        "check_your_inbox": "Ελέγξτε το email σας",
        "send_authorization_email": "Αποστολή email εξουσιοδότησης",
        "call_finished": "Η κλήση τερματίστηκε",
        "close": "Κλείσιμο",
        "waiting_stream": "Εν αναμονή μετάδοσης",
        "request_camera_and_mic": "Χρειαζόμαστε πρόσβαση στην κάμερα και το μικρόφωνό σας για να μπορείτε να πραγματοποιείτε κλήσεις.",
        "call_in_progress": "Κλήση σε εξέλιξη",
        "group": "Ομάδα",
        "videocall": "Βιντεοκλήση",
        "call": "Καλέστε",
        "connecting": "Συνδέοντας",
        "call_cancelled": "Η κλήση ακυρώθηκε",
        "calling": "Καλώντας",
        "hangup": "Κλείσε το τηλέφωνο",
        "reject": "Απόρριψη",
        "has_sent_a_file": "έχει στείλει ένα αρχείο.",
        "keep": "Διατήρηση",
        "keep_file": "Εάν ενεργοποιήσετε τη διατήρηση αρχείων, το αρχείο θα αποθηκευτεί στην εφαρμογή. Αυτό θα επιτρέψει την προσβασιμότητα χωρίς την ανάγκη σύνδεσης στο διαδίκτυο.",
        "not_keep_file": "Εάν απενεργοποιήσετε τη διατήρηση αρχείων, τα αρχεία θα διαγραφούν και δεν θα μπορείτε να έχετε πρόσβαση σε αυτά χωρίς σύνδεση στο Internet.",
        "file_stored_instruction": "Όλα τα αποθηκευμένα αρχεία θα εμφανιστούν με ένα πράσινο πλαίσιο στη λίστα των κοινόχρηστων αρχείων. Μπορείτε επίσης να τα δείτε στο",
        "stored": "Αποθηκεύεται στο",
        "not_stored": "Δεν αποθηκεύεται",
        "file_not_stored_instruction": "Δεν θα μπορείτε να έχετε πρόσβαση σε αυτό το αρχείο χωρίς σύνδεση στο Διαδίκτυο.",
        "device": "Συσκευή",
        "file_visibility": "Ορατότητα αρχείου",
        "visible_for_everyone": "Ορατό σε όλους",
        "shared_link": "Κοινόχρηστος σύνδεσμος",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Τα hashtags σας βοηθούν να αναζητήσετε τα αρχεία σας.",
        "edit": "Επεξεργασία",
        "move": "Μετακίνηση",
        "rename": "Μετονομασία",
        "visibility": "Ορατότητα",
        "delete": "Διαγραφή",
        "file_warn_delete": "Είστε σίγουροι ότι θέλετε να διαγράψετε το αρχείο;",
        "no_subtags_availables": "Δεν υπάρχουν διαθέσιμες υποετικέτες",
        "owner": "Δημιουργός",
        "expulsing": "Εκτίναξη",
        "expulse": "Εκδιώξτε το",
        "you_have_left_the_group": "Έχετε εγκαταλείψει την ομάδα",
        "coming_out": "Φεύγοντας",
        "leave_group": "Αφήστε την ομάδα",
        "you_have_left_the_room": "Έφυγες από το δωμάτιο.",
        "leave_room": "Φεύγοντας από το δωμάτιο",
        "message_deleted": "Μήνυμα διαγράφηκε",
        "has_been_error_deleting_message": "Προέκυψε σφάλμα κατά τη διαγραφή του μηνύματος",
        "deleting": "Εξάλειψη του",
        "new_tag": "Νέα ετικέτα",
        "photoEditor": {
            "name": "Όνομα",
            "save": "Τέλος",
            "saveAs": "Αποθηκεύστε ως",
            "back": "Πίσω",
            "loading": "Φόρτωση…",
            "resetOperations": "Επαναφορά/καθαρισμός όλων των λειτουργιών.",
            "changesLoseConfirmation": "Όλες οι αλλαγές θα διαγραφούν.",
            "changesLoseConfirmationHint": "Είστε σίγουρος ότι θέλετε να συνεχίσετε;",
            "cancel": "Ακύρωση",
            "continue": "Συνεχίστε",
            "undoTitle": "Αναίρεση της τελευταίας λειτουργίας",
            "redoTitle": "Επανάληψη της τελευταίας λειτουργίας",
            "showImageTitle": "Εμφάνιση αρχικής εικόνας",
            "zoomInTitle": "Ζουμ σε",
            "zoomOutTitle": "Σμίκρυνση",
            "toggleZoomMenuTitle": "Αλλαγή μενού ζουμ",
            "adjustTab": "Προσαρμογή",
            "finetuneTab": "Χρώματα",
            "filtersTab": "Φίλτρα",
            "watermarkTab": "Εικόνες",
            "annotateTab": "Ζωγραφική",
            "resize": "Αλλαγή μεγέθους",
            "resizeTab": "Διαστάσεις",
            "invalidImageError": "Μη έγκυρη εικόνα",
            "uploadImageError": "Προέκυψε σφάλμα κατά τη φόρτωση της εικόνας.",
            "areNotImages": "δεν είναι εικόνες",
            "isNotImage": "δεν είναι εικόνα",
            "toBeUploaded": "για να φορτωθεί",
            "cropTool": "Κόψτε",
            "original": "Αρχικό",
            "custom": "Εξατομικευμένο",
            "square": "Τετράγωνο",
            "landscape": "Οριζόντια",
            "portrait": "Κατακόρυφο",
            "ellipse": "Κύκλος",
            "classicTv": "Κλασική τηλεόραση",
            "cinemascope": "Σινεμασκόπ",
            "arrowTool": "Βέλος",
            "blurTool": "Θολούρα",
            "brightnessTool": "Φωτεινότητα",
            "contrastTool": "Αντίθεση",
            "ellipseTool": "Κύκλος",
            "unFlipX": "Ξεκλείδωμα X",
            "flipX": "Φλιπ X",
            "unFlipY": "Ξεκλείδωμα Y",
            "flipY": "Φλιπ Y",
            "hsvTool": "MSV",
            "hue": "Nuance",
            "saturation": "Κορεσμός",
            "value": "Αξία",
            "imageTool": "Εικόνα",
            "importing": "Εισαγωγή…",
            "addImage": "+ Προσθήκη εικόνας",
            "uploadImage": "Ανέβασμα εικόνας",
            "fromGallery": "Αυτοκόλλητα",
            "lineTool": "Γραμμή",
            "penTool": "Μολύβι",
            "polygonTool": "Πολύγωνο",
            "sides": "Πλευρές",
            "rectangleTool": "Ορθογώνιο",
            "cornerRadius": "Ακτίνα ακμής",
            "resizeWidthTitle": "Πλάτος σε pixels",
            "resizeHeightTitle": "Ύψος σε pixels",
            "toggleRatioLockTitle": "Εναλλαγή κλειδώματος διαστάσεων",
            "reset": "Επανεκκίνηση",
            "resetSize": "Επαναφορά αρχικών διαστάσεων εικόνας",
            "rotateTool": "Περιστροφή",
            "textTool": "Κείμενο",
            "textSpacings": "Διαστήματα κειμένου",
            "textAlignment": "Ευθυγράμμιση κειμένου",
            "fontFamily": "Οικογένεια γραμματοσειρών",
            "size": "Διαστάσεις",
            "letterSpacing": "Απόσταση γραμμάτων",
            "lineHeight": "Ύψος γραμμής",
            "warmthTool": "Θερμότητα",
            "addWatermark": "+ Προσθήκη εικόνας",
            "addWatermarkTitle": "Επιλέξτε τον τύπο της εικόνας",
            "uploadWatermark": "Επιλέξτε εικόνα",
            "addWatermarkAsText": "Προσθήκη κειμένου",
            "padding": "Πλήρωση",
            "shadow": "Σκιά",
            "horizontal": "Οριζόντια",
            "vertical": "Κατακόρυφο",
            "blur": "Θολούρα",
            "opacity": "Αδιαφάνεια",
            "position": "Τοποθέτηση",
            "stroke": "Πάχος",
            "saveAsModalLabel": "Λεπτομέρειες",
            "extension": "Παράταση",
            "nameIsRequired": "Το όνομα είναι υποχρεωτικό.",
            "quality": "Ποιότητα",
            "imageDimensionsHoverTitle": "Διαστάσεις της αποθηκευμένης εικόνας (Π x Υ)",
            "cropSizeLowerThanResizedWarning": "Σημειώστε ότι η επιλεγμένη περιοχή περικοπής είναι μικρότερη από το εφαρμοσμένο μέγεθος, γεγονός που μπορεί να προκαλέσει μείωση της ποιότητας.",
            "actualSize": "Τρέχον μέγεθος (100%)",
            "fitSize": "Προσαρμογή της διάστασης",
            "addImageTitle": "Επιλέξτε μια εικόνα για να προσθέσετε…"
        },
        "changing": "Αλλαγή",
        "profile_picture_changed": "Η εικόνα προφίλ άλλαξε",
        "loading_profile_picture": "Φόρτωση εικόνας προφίλ",
        "change": "Αλλαγή",
        "session": "Σύνοδος",
        "system_operative": "Λειτουργικό σύστημα",
        "navigator": "Πρόγραμμα περιήγησης",
        "authorize": "Εξουσιοδότηση",
        "deauthorize": "Ακύρωση εξουσιοδότησης",
        "keep_tag_content": "Θέλετε να κρατήσετε το περιεχόμενο της ετικέτας;",
        "move_content_to": "Μετακινήστε το περιεχόμενο στο",
        "move_to": "Μετακίνηση σε",
        "chat_request": "Αίτημα συνομιλίας",
        "accept_request": "Αποδοχή αίτησης",
        "go_to_chat": "Πηγαίνετε στη συνομιλία",
        "applying": "Εφαρμογή",
        "time": "Καιρός",
        "adjust": "Ρύθμιση",
        "rotate": "Περιστροφή",
        "start": "Σπίτι",
        "end": "Τελικό",
        "lang_name": "Greek"
    },
    "gu": {
        "name": "નામ",
        "name_example": "Manju",
        "last_name": "છેલ્લું નામ",
        "last_name_example": "Devi",
        "username": "વપરાશકર્તા",
        "username_example": "manju_devi",
        "email": "ઈમેલ",
        "email_example": "વપરાશકર્તા@ડોમેન.com",
        "password": "પાસવર્ડ",
        "new_password": "નવો પાસવર્ડ",
        "password_example": "*****************",
        "login": "પ્રવેશ કરો",
        "register": "નોંધણી કરો",
        "verification_code": "ચકાસણી કોડ",
        "verification_code_example": "xxx",
        "verify": "ચકાસો",
        "loading": "ચાર્જિંગ...",
        "please_wait": "મહેરબાની કરી રાહ જુવો",
        "go_back": "પાછા જાવ",
        "recovery": "પુનઃપ્રાપ્ત",
        "login_subtitle": "તમારી વિગતો દાખલ કરો અને તમારું એકાઉન્ટ ઍક્સેસ કરો.",
        "forgot_password_link": "શું તમે તમારો પાસવર્ડ ભૂલી ગયા છો? તે પાછું મેળવો.",
        "register_account_link": "તમારી પાસે ખાતું નથી? રેકોર્ડ!",
        "register_subtitle": "તમારી વિગતો દાખલ કરો અને તમારું એકાઉન્ટ રજીસ્ટર કરો.",
        "login_link": "શું તમારી પાસે પહેલેથી જ એકાઉન્ટ છે? પ્રવેશે છે!",
        "verification_code_instruction": "તમારો ઈમેલ તપાસો અને મોકલેલ કોડ દાખલ કરો.",
        "forgot_password_subtitle": "તમારો પાસવર્ડ પુનઃપ્રાપ્ત કરવા માટે તમારું ઇમેઇલ દાખલ કરો.",
        "change_password_subtitle": "નવો પાસવર્ડ નાખો.",
        "change_password_code_used": "તમારો પાસવર્ડ પુનઃપ્રાપ્તિ કોડ અમાન્ય છે અથવા પહેલાથી જ ઉપયોગમાં લેવાયો છે.",
        "no_chats": "તમારી પાસે વાતચીત નથી.",
        "chats": "વાતચીતો",
        "groups": "જૂથો",
        "rooms": "રૂમ",
        "save": "સાચવો",
        "download": "ડિસ્ચાર્જ",
        "downloading_image": "છબી ડાઉનલોડ કરી રહ્યું છે",
        "an_error_has_ocurred": "એક ભૂલ આવી છે",
        "saving_changes": "ફેરફારો સાચવી રહ્યા છીએ",
        "go_back_to_edit": "ફરીથી સંપાદિત કરો",
        "upload_warning_modal": "સંપાદન અપલોડ કરવાથી મૂળ છબી બદલાશે.",
        "upload_question_modal": "શું તમે મૂળ રાખવા માંગો છો અને એક નકલ બનાવવા માંગો છો?",
        "create_copy_and_keep": "એક નકલ બનાવો અને સાચવો",
        "upload": "અપલોડ કરો",
        "upload_changes": "ફેરફારો અપલોડ કરો",
        "changes_saved": "ફેરફારો સાચવ્યા",
        "downloading_video": "વિડિઓ ડાઉનલોડ કરી રહ્યું છે",
        "video_upload_warning_modal": "સંપાદન અપલોડ કરવાથી મૂળ વિડિઓ બદલાશે.",
        "results": "પરિણામો",
        "members": "સભ્યો",
        "cancel": "રદ કરો",
        "add_user": "વપરાશકર્તા ઉમેરો",
        "no_messages": "કોઈ સંદેશા નથી",
        "message_placeholder": "સંદેશ…",
        "group_doesnt_exists": "જૂથ અસ્તિત્વમાં નથી",
        "you_arent_group_member": "તમે જૂથના સભ્ય નથી",
        "touch_to_see_more_info": "વધુ માહિતી જોવા માટે ટૅપ કરો.",
        "start_videocall": "વિડિઓ કૉલ શરૂ કરો",
        "start_call": "કૉલ શરૂ કરો",
        "create_group": "જૂથ બનાવો",
        "group_name": "જૂથનું નામ",
        "add_members_to_create_group": "જૂથ બનાવવા માટે સભ્યોને ઉમેરો",
        "search": "માટે જુઓ",
        "creating": "બનાવી રહ્યા છે…",
        "dont_belong_anyone_group": "તમે કોઈપણ જૂથના નથી.",
        "already_add_this_hashtag": "તમે પહેલેથી જ તે હેશટેગ ઉમેર્યું છે",
        "search_files_by_hashtags": "હેશટેગ્સ દ્વારા ફાઇલો શોધો",
        "enter_to_add": "ઉમેરવા માટે દાખલ કરો",
        "hashtags_most_used": "સૌથી વધુ ઉપયોગમાં લેવાતા હેશટેગ્સ",
        "can_add_it_giving_click": "તમે તેના પર ક્લિક કરીને તેને તમારી શોધમાં ઉમેરી શકો છો.",
        "searching_files": "ફાઈલો શોધી રહ્યા છીએ",
        "no_files_found": "કોઈ ફાઇલો મળી નથી",
        "image": "છબી",
        "video": "વિડિઓ",
        "audio": "ઓડિયો",
        "library": "બુકશોપ",
        "home": "હોમ પેજ",
        "block": "બ્લોક",
        "unblock": "અનાવરોધિત કરો",
        "has_blocked_you": "તેણે તમને બ્લોક કર્યા છે",
        "accept": "સ્વીકારો",
        "decline": "ઘટાડો",
        "cancel_request": "વિનંતી રદ કરો",
        "has_been_expulsed": "હાંકી કાઢવામાં આવ્યો છે",
        "room_doesnt_exists": "રૂમ અસ્તિત્વમાં નથી",
        "you_arent_room_member": "તમે રૂમના સભ્ય નથી",
        "create_room": "રૂમ બનાવો",
        "room_name": "રૂમનું નામ",
        "no_rooms_created": "ત્યાં કોઈ રૂમ બનાવવામાં આવ્યા નથી.",
        "dont_belong_to_anyone_room": "તમે કોઈપણ રૂમમાં નથી.",
        "enter_a_username": "વપરાશકર્તા લખો",
        "no_results": "પરિણામ નથી",
        "create": "બનાવો",
        "no_content_uploaded": "કોઈ સામગ્રી અપલોડ કરવામાં આવી નથી",
        "new_subtag": "નવો સબટેગ",
        "update_info": "માહિતી અપડેટ કરો",
        "updating": "અપડેટ કરી રહ્યું છે",
        "update": "અપડેટ કરો",
        "update_email": "ઇમેઇલ અપડેટ કરો",
        "change_password": "પાસવર્ડ બદલો",
        "current_password": "અત્યારનો પાસવર્ડ",
        "select": "પસંદ કરો",
        "tap_to_upload_a_file": "ફાઇલ અપલોડ કરવા માટે ટૅપ કરો",
        "edit_photo": "ફોટો એડિટ કરો",
        "preview": "પૂર્વાવલોકન",
        "edit_video": "વિડિઓ સંપાદિત કરો",
        "delete_changes": "ફેરફારો કાઢી નાખો",
        "uploading": "અપલોડ કરી રહ્યું છે",
        "session_deleted_or_expired": "સત્ર કાઢી નાખ્યું અથવા સમાપ્ત થયું",
        "edit_profile": "પ્રોફાઇલ સંપાદિત કરો",
        "devices": "ઉપકરણો",
        "downloads": "ડાઉનલોડ",
        "logout": "સાઇન ઓફ",
        "offline": "ઑફલાઇન",
        "images": "છબીઓ",
        "videos": "વિડિઓઝ",
        "audios": "ઓડિયો",
        "chat": "ચેટ",
        "session_unauthorized": "તમારું સત્ર અધિકૃત નથી.",
        "authorize_session": "આ સત્રને ઍક્સેસ કરવા માટે, તમારે તમારા ઉપકરણને અધિકૃત કરવાની જરૂર છે.",
        "authorize_instructions": "અધિકૃતતા લિંક માટે તમારો ઇમેઇલ તપાસો અથવા સક્રિય સત્ર પર જાઓ અને ઉપરના જમણા ખૂણામાં ત્રણ બિંદુઓ પસંદ કરો, પછી \"ઉપકરણો\" પર જાઓ અને \"અધિકૃત કરો\" પસંદ કરો.",
        "refresh_page_after_authorize": "એકવાર તમે અધિકૃતતા પ્રક્રિયા પૂર્ણ કરી લો, પછી સત્રને ઍક્સેસ કરવા માટે પૃષ્ઠને તાજું કરો.",
        "check_your_inbox": "તમારા ઇમેઇલ તપાસો",
        "send_authorization_email": "અધિકૃતતા ઇમેઇલ મોકલો",
        "call_finished": "કૉલ સમાપ્ત થયો",
        "close": "બંધ કરવું",
        "waiting_stream": "ટ્રાન્સમિશન માટે રાહ જોઈ રહ્યું છે",
        "request_camera_and_mic": "અમને તમારા કૅમેરા અને માઇક્રોફોનની ઍક્સેસની જરૂર છે જેથી કરીને તમે કૉલ કરી શકો.",
        "call_in_progress": "કૉલ ચાલુ છે",
        "group": "ક્લસ્ટર",
        "videocall": "વિડિઓ કૉલ",
        "call": "મળવું",
        "connecting": "કનેક્ટિંગ",
        "call_cancelled": "કૉલ રદ કર્યો",
        "calling": "કૉલિંગ",
        "hangup": "અટકી જવું",
        "reject": "ઘટાડો",
        "has_sent_a_file": "ફાઇલ મોકલી છે.",
        "keep": "રાખવું",
        "keep_file": "જો તમે ફાઇલ રીટેન્શનને સક્ષમ કરો છો, તો તે એપ્લિકેશનમાં સાચવવામાં આવશે. આ ઇન્ટરનેટ કનેક્શનની જરૂરિયાત વિના તેની ઍક્સેસિબિલિટીને મંજૂરી આપશે.",
        "not_keep_file": "જો તમે ફાઇલ રીટેન્શન બંધ કરો છો, તો તે કાઢી નાખવામાં આવશે અને તમે ઇન્ટરનેટ કનેક્શન વિના તેને ઍક્સેસ કરી શકશો નહીં.",
        "file_stored_instruction": "બધી સંગ્રહિત ફાઇલો લીલી કિનારી સાથે સામાન્ય ફાઇલ સૂચિમાં દેખાશે. તમે તેમને વિભાગમાં પણ જોઈ શકો છો",
        "stored": "સંગ્રહિત",
        "not_stored": "સંગ્રહિત નથી",
        "file_not_stored_instruction": "તમે ઇન્ટરનેટ કનેક્શન વિના આ ફાઇલને ઍક્સેસ કરી શકશો નહીં.",
        "device": "ઉપકરણ",
        "file_visibility": "ફાઇલ દૃશ્યતા",
        "visible_for_everyone": "દરેકને દૃશ્યક્ષમ",
        "shared_link": "શેર કરેલી લિંક",
        "hashtags": "હેશટેગ્સ",
        "hashtags_helps_to_search_your_file": "હેશટેગ્સ તમને તમારી ફાઇલો શોધવામાં મદદ કરે છે.",
        "edit": "સંપાદિત કરો",
        "move": "સ્ચળાંતર કરવું",
        "rename": "નામ બદલો",
        "visibility": "દૃશ્યતા",
        "delete": "નાબૂદ",
        "file_warn_delete": "શું તમે ખરેખર ફાઇલ કાઢી નાખવા માંગો છો?",
        "no_subtags_availables": "કોઈ સબટેગ્સ ઉપલબ્ધ નથી",
        "owner": "માલિક",
        "expulsing": "હાંકી કાઢવું",
        "expulse": "હાંકી કાઢો",
        "you_have_left_the_group": "તમે જૂથ છોડી દીધું છે",
        "coming_out": "બહાર આવવુ",
        "leave_group": "જૂથ છોડી દો",
        "you_have_left_the_room": "તમે રૂમ છોડી દીધી છે.",
        "leave_room": "રૂમ છોડી દો",
        "message_deleted": "કાઢી નાખેલ સંદેશ",
        "has_been_error_deleting_message": "સંદેશ કાઢી નાખવામાં ભૂલ આવી",
        "deleting": "દૂર કરી રહ્યા છીએ",
        "new_tag": "નવું લેબલ",
        "photoEditor": {
            "name": "નામ",
            "save": "સમાપ્ત કરો",
            "saveAs": "તરીકે જમા કરવુ",
            "back": "પરત",
            "loading": "ચાર્જિંગ…",
            "resetOperations": "પુનઃપ્રારંભ કરો/બધી કામગીરી કાઢી નાખો.",
            "changesLoseConfirmation": "બધા ફેરફારો દૂર કરવામાં આવશે.",
            "changesLoseConfirmationHint": "શું તમે ખરેખર ચાલુ રાખવા માંગો છો?",
            "cancel": "રદ કરો",
            "continue": "ચાલુ રાખો",
            "undoTitle": "છેલ્લી ક્રિયા પૂર્વવત્ કરો",
            "redoTitle": "છેલ્લું ઓપરેશન ફરીથી કરો",
            "showImageTitle": "મૂળ છબી બતાવો",
            "zoomInTitle": "મોટું કરો",
            "zoomOutTitle": "વોર્ડ ઓફ",
            "toggleZoomMenuTitle": "ઝૂમ મેનૂ ટૉગલ કરો",
            "adjustTab": "એડજસ્ટ કરો",
            "finetuneTab": "રંગો",
            "filtersTab": "ફિલ્ટર્સ",
            "watermarkTab": "છબીઓ",
            "annotateTab": "દોરો",
            "resize": "માપ બદલો",
            "resizeTab": "કદ",
            "invalidImageError": "અમાન્ય છબી",
            "uploadImageError": "છબી લોડ કરતી વખતે એક ભૂલ આવી.",
            "areNotImages": "તેઓ છબીઓ નથી",
            "isNotImage": "તે એક છબી નથી",
            "toBeUploaded": "અપલોડ કરવાનું છે",
            "cropTool": "કાપવું",
            "original": "મૂળ",
            "custom": "વ્યક્તિગત કરેલ",
            "square": "ચોરસ",
            "landscape": "આડું",
            "portrait": "ઊભી",
            "ellipse": "વર્તુળ",
            "classicTv": "ક્લાસિક ટીવી",
            "cinemascope": "સિનેમાસ્કોપ",
            "arrowTool": "તીર",
            "blurTool": "અસ્પષ્ટતા",
            "brightnessTool": "ગ્લો",
            "contrastTool": "કોન્ટ્રાસ્ટ",
            "ellipseTool": "વર્તુળ",
            "unFlipX": "એક્સ અનફ્લિપ કરો",
            "flipX": "ફ્લિપ એક્સ",
            "unFlipY": "Y અનફ્લિપ કરો",
            "flipY": "Y ફ્લિપ કરો",
            "hsvTool": "MSV",
            "hue": "હ્યુ",
            "saturation": "સંતૃપ્તિ",
            "value": "વર્થ",
            "imageTool": "છબી",
            "importing": "આયાત કરી રહ્યું છે…",
            "addImage": "+ છબી ઉમેરો",
            "uploadImage": "છબી અપલોડ કરો",
            "fromGallery": "સ્ટીકરો",
            "lineTool": "રેખા",
            "penTool": "પેન્સિલ",
            "polygonTool": "બહુકોણ",
            "sides": "બાજુઓ",
            "rectangleTool": "લંબચોરસ",
            "cornerRadius": "ધાર ત્રિજ્યા",
            "resizeWidthTitle": "પિક્સેલમાં પહોળાઈ",
            "resizeHeightTitle": "પિક્સેલની ઊંચાઈ",
            "toggleRatioLockTitle": "ડાયમેન્શન લૉક ટૉગલ કરો",
            "reset": "પુનઃપ્રારંભ",
            "resetSize": "છબીના મૂળ પરિમાણોને પુનઃસ્થાપિત કરો",
            "rotateTool": "ફેરવો",
            "textTool": "ટેક્સ્ટ",
            "textSpacings": "ટેક્સ્ટ અંતર",
            "textAlignment": "ટેક્સ્ટ સંરેખણ",
            "fontFamily": "ફોન્ટ કુટુંબ",
            "size": "કદ",
            "letterSpacing": "અક્ષર અંતર",
            "lineHeight": "રેખા ઊંચાઇ",
            "warmthTool": "ગરમી",
            "addWatermark": "+ છબી ઉમેરો",
            "addWatermarkTitle": "વોટરમાર્ક પ્રકાર પસંદ કરો",
            "uploadWatermark": "છબી પસંદ કરો",
            "addWatermarkAsText": "ટેક્સ્ટ ઉમેરો",
            "padding": "સ્ટફ્ડ",
            "shadow": "શેડિંગ",
            "horizontal": "આડી",
            "vertical": "વર્ટિકલ",
            "blur": "અસ્પષ્ટતા",
            "opacity": "અસ્પષ્ટતા",
            "position": "પદ",
            "stroke": "જાડાઈ",
            "saveAsModalLabel": "વિગતો",
            "extension": "વિસ્તરણ",
            "nameIsRequired": "નામ જરૂરી છે.",
            "quality": "ગુણવત્તા",
            "imageDimensionsHoverTitle": "સાચવેલ છબીના પરિમાણો (પહોળાઈ x ઊંચાઈ)",
            "cropSizeLowerThanResizedWarning": "નોંધ કરો કે પસંદ કરેલ પાક વિસ્તાર જ્યારે માપ બદલવામાં આવે છે તેના કરતા નાનો હોય છે, જે ગુણવત્તામાં બગાડનું કારણ બની શકે છે.",
            "actualSize": "વર્તમાન કદ (100%)",
            "fitSize": "ફિટ કદ",
            "addImageTitle": "ઉમેરવા માટે એક છબી પસંદ કરો..."
        },
        "changing": "બદલાતી",
        "profile_picture_changed": "પ્રોફાઇલ ફોટો બદલ્યો",
        "loading_profile_picture": "પ્રોફાઇલ ફોટો લોડ કરી રહ્યાં છીએ",
        "change": "ફેરફાર",
        "session": "સત્ર",
        "system_operative": "ઓએસ",
        "navigator": "બ્રાઉઝર",
        "authorize": "અધિકૃત કરો",
        "deauthorize": "નામંજૂર કરો",
        "keep_tag_content": "શું તમે લેબલની સામગ્રી સાચવવા માંગો છો?",
        "move_content_to": "સામગ્રીને પર ખસેડો",
        "move_to": "ખસેડવું",
        "chat_request": "ચેટ વિનંતી",
        "accept_request": "વિનંતી સ્વીકારો",
        "go_to_chat": "ચેટ પર જાઓ",
        "applying": "અરજી",
        "time": "સમય",
        "adjust": "એડજસ્ટ કરો",
        "rotate": "ફેરવો",
        "start": "શરૂઆત",
        "end": "અંતિમ",
        "lang_name": "Gujarati"
    },
    "ha": {
        "name": "Suna",
        "name_example": "Paulo",
        "last_name": "Sunan mahaifa",
        "last_name_example": "Rayuma",
        "username": "Wadda take amfani",
        "username_example": "paulo_rayuma",
        "email": "Imel",
        "email_example": "wataam@yankin.com",
        "password": "Kalmar wucewa",
        "new_password": "Sabuwar kalmar sirri",
        "password_example": "*****************",
        "login": "Shiga",
        "register": "Yi rijista",
        "verification_code": "Lambar tabbaci",
        "verification_code_example": "xxx",
        "verify": "Tabbatar",
        "loading": "Cajin…",
        "please_wait": "Don Allah jira",
        "go_back": "Komawa",
        "recovery": "Farfadowa",
        "login_subtitle": "Shigar da bayanan ku kuma sami damar asusunku.",
        "forgot_password_link": "Shin kun manta kalmar sirrinku? Farfadowa.",
        "register_account_link": "Ba ku da asusu? Yi rajista!",
        "register_subtitle": "Shigar da bayananku kuma kuyi rijistar asusunku.",
        "login_link": "Shin kuna da asusun rajista? Shiga!",
        "verification_code_instruction": "Duba imel ɗin ku kuma shigar da lambar da aka aiko muku.",
        "forgot_password_subtitle": "Shigar da imel ɗin ku don dawo da kalmar wucewa ta ku.",
        "change_password_subtitle": "Shigar da sabon kalmar sirri.",
        "change_password_code_used": "Lambar dawo da kalmar wucewa ba ta aiki ko an riga an yi amfani da ita.",
        "no_chats": "Ba ku da tattaunawa.",
        "chats": "Taɗi",
        "groups": "Ƙungiyoyi",
        "rooms": "Dakuna",
        "save": "Ajiye",
        "download": "Zazzagewa",
        "downloading_image": "Zazzage hoto",
        "an_error_has_ocurred": "An sami kuskure",
        "saving_changes": "Ajiye canje-canje",
        "go_back_to_edit": "Gyara sake",
        "upload_warning_modal": "Loda gyaran zai maye gurbin ainihin hoton.",
        "upload_question_modal": "Kuna son kiyaye ainihin kuma ƙirƙirar kwafi?",
        "create_copy_and_keep": "Ƙirƙiri kwafi kuma ajiye",
        "upload": "Loda",
        "upload_changes": "Loda canje-canje",
        "changes_saved": "An adana canje-canje",
        "downloading_video": "Bidiyon yana saukewa.",
        "video_upload_warning_modal": "Loda gyaran zai maye gurbin ainihin bidiyon.",
        "results": "Sakamako",
        "members": "Membobi",
        "cancel": "Soke",
        "add_user": "Ƙara mai amfani",
        "no_messages": "Babu saƙonni",
        "message_placeholder": "Sako…",
        "group_doesnt_exists": "Babu ƙungiyar",
        "you_arent_group_member": "Kai ba memban kungiyar bane",
        "touch_to_see_more_info": "Danna don ganin ƙarin bayani.",
        "start_videocall": "Fara kiran bidiyo",
        "start_call": "Fara kira",
        "create_group": "Ƙirƙiri rukuni",
        "group_name": "Sunan rukuni",
        "add_members_to_create_group": "Ƙara membobi don ƙirƙirar ƙungiyar",
        "search": "Bincika",
        "creating": "Ƙirƙirar…",
        "dont_belong_anyone_group": "Ba ku cikin kowane rukuni.",
        "already_add_this_hashtag": "Kun riga kun ƙara wancan hashtag",
        "search_files_by_hashtags": "Bincika fayiloli ta hashtags",
        "enter_to_add": "Shigar don ƙarawa",
        "hashtags_most_used": "Mafi yawan amfani da hashtags",
        "can_add_it_giving_click": "Kuna iya ƙara su zuwa bincikenku ta danna su.",
        "searching_files": "Neman fayiloli",
        "no_files_found": "Ba a sami fayiloli ba",
        "image": "Hoto",
        "video": "Bidiyo",
        "audio": "Audio",
        "library": "kantin sayar da littattafai",
        "home": "Shafin gida",
        "block": "Toshe",
        "unblock": "Cire katanga",
        "has_blocked_you": "Ya hana ku",
        "accept": "Karba",
        "decline": "Karya",
        "cancel_request": "Soke bukatar",
        "has_been_expulsed": "an kore shi",
        "room_doesnt_exists": "Dakin babu",
        "you_arent_room_member": "Kai ba memban dakin bane",
        "create_room": "Ƙirƙiri ɗaki",
        "room_name": "Sunan dakin",
        "no_rooms_created": "Babu dakuna da aka ƙirƙira.",
        "dont_belong_to_anyone_room": "Ba ku cikin kowane ɗaki.",
        "enter_a_username": "Rubuta mai amfani",
        "no_results": "Babu sakamako",
        "create": "Ƙirƙiri",
        "no_content_uploaded": "Ba a ɗora wani abun ciki ba.",
        "new_subtag": "Sabuwar subtag",
        "update_info": "Sabunta bayanai",
        "updating": "Ana sabuntawa",
        "update": "Sabuntawa",
        "update_email": "Sabunta imel",
        "change_password": "Canza kalmar shiga",
        "current_password": "Kalmar shiga na halin yanzu",
        "select": "Zaɓi",
        "tap_to_upload_a_file": "Matsa don loda fayil",
        "edit_photo": "Gyara hoto",
        "preview": "Dubawa",
        "edit_video": "Gyara bidiyo",
        "delete_changes": "Share canje-canje",
        "uploading": "Ana saukewa",
        "session_deleted_or_expired": "An share ko ƙare zaman.",
        "edit_profile": "Gyara bayanin martaba",
        "devices": "Na'urori",
        "downloads": "Saukewa",
        "logout": "Fita",
        "offline": "Ba tare da haɗi ba",
        "images": "Hotuna",
        "videos": "Bidiyo",
        "audios": "Audios",
        "chat": "Taɗi",
        "session_unauthorized": "Zaman ku ba shi da izini.",
        "authorize_session": "Don samun dama ga wannan zaman, kuna buƙatar ba da izinin na'urar ku.",
        "authorize_instructions": "Bincika imel ɗin ku don hanyar haɗin izini ko je zuwa wani zama mai aiki kuma zaɓi ɗigogi uku a kusurwar dama ta sama, sannan je zuwa \"Na'urori\" kuma zaɓi \"Izinin.\"",
        "refresh_page_after_authorize": "Da zarar kun gama aiwatar da izini, sake sabunta shafin don samun damar zaman.",
        "check_your_inbox": "Duba imel ɗin ku",
        "send_authorization_email": "Aika imel ɗin izini",
        "call_finished": "Kira ya ƙare",
        "close": "Rufa",
        "waiting_stream": "Ana jiran watsawa",
        "request_camera_and_mic": "Muna buƙatar samun dama ga kyamarar ku da makirufo don ku iya yin kira.",
        "call_in_progress": "Kira yana ci gaba",
        "group": "Rukuni",
        "videocall": "Kiran bidiyo",
        "call": "Kira",
        "connecting": "Haɗawa",
        "call_cancelled": "An soke kiran",
        "calling": "Kira",
        "hangup": "Rataya",
        "reject": "Jefad da",
        "has_sent_a_file": "ya aika fayil.",
        "keep": "Ajiye",
        "keep_file": "Idan kun kunna ajiyar fayil, za a adana shi a cikin app. Wannan zai ba ka damar samun dama gare shi ba tare da buƙatar haɗin Intanet ba.",
        "not_keep_file": "Idan ba ka ajiye fayil ɗin ba, za a goge shi kuma ba za ka iya shiga ba tare da haɗin Intanet ba.",
        "file_stored_instruction": "Duk fayilolin da aka ajiye zasu bayyana tare da koren iyaka a cikin jerin fayilolin gama-gari. Hakanan zaka iya ganin su a cikin sashin",
        "stored": "Ajiye",
        "not_stored": "Ba a adana shi",
        "file_not_stored_instruction": "Ba za ku sami damar shiga wannan fayil ɗin ba tare da haɗin Intanet ba.",
        "device": "Na'ura",
        "file_visibility": "Ganuwa fayil",
        "visible_for_everyone": "Ganuwa ga kowa da kowa",
        "shared_link": "Mahada da aka raba",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Hashtags suna taimaka muku nemo fayilolinku.",
        "edit": "Gyara",
        "move": "Matsar",
        "rename": "Sake suna",
        "visibility": "Ganuwa",
        "delete": "Kawar da",
        "file_warn_delete": "Shin kun tabbata kuna son share fayil ɗin?",
        "no_subtags_availables": "Babu subtags samuwa",
        "owner": "Mahalicci",
        "expulsing": "Korar",
        "expulse": "Korar",
        "you_have_left_the_group": "Kun bar kungiyar",
        "coming_out": "Fitowa",
        "leave_group": "Bar kungiyar",
        "you_have_left_the_room": "Kun bar dakin.",
        "leave_room": "Bar dakin",
        "message_deleted": "Saƙon da aka goge",
        "has_been_error_deleting_message": "An sami kuskure yayin share saƙon.",
        "deleting": "Ana cirewa",
        "new_tag": "Sabuwar tag",
        "photoEditor": {
            "name": "Suna",
            "save": "Gama",
            "saveAs": "Ajiye azaman",
            "back": "Baya",
            "loading": "Cajin…",
            "resetOperations": "Sake saita/cire duk sabis.",
            "changesLoseConfirmation": "Za a cire duk canje-canje.",
            "changesLoseConfirmationHint": "Kun tabbata kuna son ci gaba?",
            "cancel": "Soke",
            "continue": "Ci gaba",
            "undoTitle": "Gyara aiki na ƙarshe",
            "redoTitle": "Maimaita aiki na ƙarshe",
            "showImageTitle": "Nuna hoton asali",
            "zoomInTitle": "Zuƙowa",
            "zoomOutTitle": "Zuƙowa waje",
            "toggleZoomMenuTitle": "Juya menu na zuƙowa",
            "adjustTab": "Daidaita",
            "finetuneTab": "Launuka",
            "filtersTab": "Tace",
            "watermarkTab": "Hotuna",
            "annotateTab": "Zana",
            "resize": "Maimaita girman",
            "resizeTab": "Girman",
            "invalidImageError": "Hoton mara inganci",
            "uploadImageError": "An sami kuskure yayin loda hoton.",
            "areNotImages": "ba hotuna bane",
            "isNotImage": "ba hoto ba ne",
            "toBeUploaded": "da za a yi uploaded",
            "cropTool": "Sare",
            "original": "Na asali",
            "custom": "Keɓaɓɓen",
            "square": "Dandalin",
            "landscape": "A kwance",
            "portrait": "A tsaye",
            "ellipse": "Da'irar",
            "classicTv": "Classic TV",
            "cinemascope": "Cinemascope",
            "arrowTool": "Kibiya",
            "blurTool": "Rushewa",
            "brightnessTool": "Haskaka",
            "contrastTool": "Kwatancen",
            "ellipseTool": "Da'irar",
            "unFlipX": "Cire X",
            "flipX": "Juya X",
            "unFlipY": "Sauke Y",
            "flipY": "Juya Y",
            "hsvTool": "MSV",
            "hue": "Hue",
            "saturation": "Jikewa",
            "value": "Daraja",
            "imageTool": "Hoto",
            "importing": "Ana shigo da shi…",
            "addImage": "+ Ƙara hoto",
            "uploadImage": "Loda hoto",
            "fromGallery": "Kwali",
            "lineTool": "Layi",
            "penTool": "Fensir",
            "polygonTool": "Polygon",
            "sides": "Gefe",
            "rectangleTool": "Rectangle",
            "cornerRadius": "Radius edge",
            "resizeWidthTitle": "Nisa a cikin pixels",
            "resizeHeightTitle": "Tsayin Pixel",
            "toggleRatioLockTitle": "Juya Girman Kulle",
            "reset": "Sake saiti",
            "resetSize": "Maido da girman hoton asali",
            "rotateTool": "Juyawa",
            "textTool": "Rubutu",
            "textSpacings": "Tazarar rubutu",
            "textAlignment": "Daidaita Rubutu",
            "fontFamily": "Iyalin Font",
            "size": "Girman",
            "letterSpacing": "Tazarar wasiƙa",
            "lineHeight": "Tsayin layi",
            "warmthTool": "Zafi",
            "addWatermark": "+ Ƙara hoto",
            "addWatermarkTitle": "Zaɓi nau'in alamar ruwa",
            "uploadWatermark": "Zaɓi alamar ruwa",
            "addWatermarkAsText": "Ƙara rubutu",
            "padding": "Cushe",
            "shadow": "Inuwa",
            "horizontal": "A kwance",
            "vertical": "A tsaye",
            "blur": "Rushewa",
            "opacity": "Bahaushe",
            "position": "Matsayi",
            "stroke": "Kauri",
            "saveAsModalLabel": "Cikakkun bayanai",
            "extension": "Tsawaita",
            "nameIsRequired": "Ana buƙatar sunan.",
            "quality": "Inganci",
            "imageDimensionsHoverTitle": "Girman hoto da aka ajiye (tsawon x nisa)",
            "cropSizeLowerThanResizedWarning": "Lura cewa yankin da aka zaɓa ya yi ƙasa da girman da aka yi amfani da shi, wanda zai iya rage inganci.",
            "actualSize": "Girman halin yanzu (100%)",
            "fitSize": "Daidaita girman",
            "addImageTitle": "Zaɓi hoto don ƙarawa…"
        },
        "changing": "Cambiando",
        "profile_picture_changed": "An canza hoton bayanin martaba.",
        "loading_profile_picture": "Ana loda hoton bayanin martaba",
        "change": "Canza",
        "session": "Zama",
        "system_operative": "OS",
        "navigator": "Browser",
        "authorize": "Yi izini",
        "deauthorize": "Rashin yarda",
        "keep_tag_content": "Kuna son adana abun ciki na alamar?",
        "move_content_to": "Matsar da abun ciki zuwa",
        "move_to": "Matsa zuwa",
        "chat_request": "Neman taɗi",
        "accept_request": "Karɓi roƙon",
        "go_to_chat": "Jeka hira",
        "applying": "Ana nema",
        "time": "Lokaci",
        "adjust": "Daidaita",
        "rotate": "Juyawa",
        "start": "Fara",
        "end": "Karshe",
        "lang_name": "Hausa"
    },
    "he": {
        "name": "שֵׁם",
        "name_example": "David",
        "last_name": "שם משפחה",
        "last_name_example": "Levy",
        "username": "מִשׁתַמֵשׁ",
        "username_example": "david_levy",
        "email": "אימייל",
        "email_example": "מִשׁתַמֵשׁ@תְחוּם.com",
        "password": "סיסמה",
        "new_password": "סיסמה חדשה",
        "password_example": "*****************",
        "login": "התחברות",
        "register": "הירשם",
        "verification_code": "קוד אימות",
        "verification_code_example": "xxx",
        "verify": "תאשר",
        "loading": "טְעִינָה…",
        "please_wait": "המתן בבקשה",
        "go_back": "תחזור",
        "recovery": "לְהַחלִים",
        "login_subtitle": "הזן את הפרטים שלך ועבור לחשבון שלך.",
        "forgot_password_link": "האם אתה שכחת את הסיסמה שלך? לְהַחלִים.",
        "register_account_link": "אין לך חשבון? הירשם!",
        "register_subtitle": "הכנס את פרטיך ורשום את חשבונך.",
        "login_link": "האם כבר יש לך חשבון? התחברות!",
        "verification_code_instruction": "בדוק את האימייל שלך והזן את הקוד שנשלח.",
        "forgot_password_subtitle": "הזן את האימייל שלך כדי לשחזר את הסיסמה שלך.",
        "change_password_subtitle": "הכנס סיסמא חדשה.",
        "change_password_code_used": "קוד שחזור הסיסמה שלך לא חוקי או שכבר נעשה בו שימוש.",
        "no_chats": "אין לך שיחות.",
        "chats": "שיחות",
        "groups": "קבוצות",
        "rooms": "חדרים",
        "save": "לִשְׁמוֹר",
        "download": "פְּרִיקָה",
        "downloading_image": "מוריד תמונה",
        "an_error_has_ocurred": "אירעה שגיאה",
        "saving_changes": "שמור שינויים",
        "go_back_to_edit": "ערוך שוב",
        "upload_warning_modal": "העלאת העריכה תחליף את התמונה המקורית.",
        "upload_question_modal": "האם ברצונך לשמור את המקור וליצור עותק?",
        "create_copy_and_keep": "צור עותק ושמור",
        "upload": "העלה",
        "upload_changes": "העלה שינויים",
        "changes_saved": "שינויים נשמרו",
        "downloading_video": "מוריד סרטון",
        "video_upload_warning_modal": "העלאת העריכה תחליף את הסרטון המקורי.",
        "results": "תוצאות",
        "members": "חברים",
        "cancel": "לְבַטֵל",
        "add_user": "הוסף משתמש",
        "no_messages": "אין הודעות",
        "message_placeholder": "הוֹדָעָה…",
        "group_doesnt_exists": "הקבוצה לא קיימת",
        "you_arent_group_member": "אתה לא חבר בקבוצה",
        "touch_to_see_more_info": "הקש כדי לראות מידע נוסף.",
        "start_videocall": "התחל שיחת וידאו",
        "start_call": "התחל שיחה",
        "create_group": "צור קבוצה",
        "group_name": "שם קבוצה",
        "add_members_to_create_group": "הוסף חברים כדי ליצור את הקבוצה",
        "search": "לחפש",
        "creating": "יצירה…",
        "dont_belong_anyone_group": "אתה לא שייך לשום קבוצה.",
        "already_add_this_hashtag": "כבר הוספת את ההאשטאג הזה",
        "search_files_by_hashtags": "חפש קבצים לפי תגי hashtag",
        "enter_to_add": "הכנס כדי להוסיף",
        "hashtags_most_used": "ההאשטאגים הנפוצים ביותר",
        "can_add_it_giving_click": "אתה יכול להוסיף אותם לחיפוש שלך על ידי לחיצה עליהם.",
        "searching_files": "חיפוש קבצים",
        "no_files_found": "לא נמצאו קבצים",
        "image": "תמונה",
        "video": "וִידֵאוֹ",
        "audio": "שֶׁמַע",
        "library": "חֲנוּת סְפָרִים",
        "home": "דף הבית",
        "block": "לַחסוֹם",
        "unblock": "כדי לבטל את הנעילה",
        "has_blocked_you": "הוא חסם אותך",
        "accept": "לְקַבֵּל",
        "decline": "יְרִידָה",
        "cancel_request": "בטל בקשה",
        "has_been_expulsed": "גורש",
        "room_doesnt_exists": "החדר לא קיים",
        "you_arent_room_member": "אתה לא חבר בחדר",
        "create_room": "צור חדר",
        "room_name": "שם חדר",
        "no_rooms_created": "לא נוצרו חדרים.",
        "dont_belong_to_anyone_room": "אתה לא שייך לאף חדר.",
        "enter_a_username": "כתוב משתמש",
        "no_results": "אין תוצאות",
        "create": "לִיצוֹר",
        "no_content_uploaded": "לא הועלה תוכן",
        "new_subtag": "תת תג חדש",
        "update_info": "לעדכן מידע",
        "updating": "עִדכּוּן",
        "update": "עדכון",
        "update_email": "עדכן אימייל",
        "change_password": "שנה סיסמא",
        "current_password": "סיסמה נוכחית",
        "select": "בחר",
        "tap_to_upload_a_file": "הקש כדי להעלות קובץ",
        "edit_photo": "ערוך תמונה",
        "preview": "תצוגה מקדימה",
        "edit_video": "ערוך סרטון",
        "delete_changes": "מחק שינויים",
        "uploading": "מעלה",
        "session_deleted_or_expired": "ההפעלה נמחקה או פג תוקף.",
        "edit_profile": "ערוך פרופיל",
        "devices": "מכשירים",
        "downloads": "הורדות",
        "logout": "להתנתק",
        "offline": "לא מקוון",
        "images": "תמונות",
        "videos": "סרטונים",
        "audios": "אודיו",
        "chat": "לְשׂוֹחֵחַ",
        "session_unauthorized": "הפגישה שלך אינה מורשית.",
        "authorize_session": "כדי לגשת להפעלה זו, עליך לאשר את המכשיר שלך.",
        "authorize_instructions": "בדוק באימייל שלך את קישור ההרשאה או עבור להפעלה פעילה ובחר את שלוש הנקודות בפינה השמאלית העליונה, ולאחר מכן עבור אל \"התקנים\" ובחר \"הרשאה\".",
        "refresh_page_after_authorize": "לאחר השלמת תהליך ההרשאה, רענן את הדף כדי לגשת להפעלה.",
        "check_your_inbox": "לבדוק את הדואר האלקטרוני שלך",
        "send_authorization_email": "שלח אימייל אישור",
        "call_finished": "השיחה הסתיימה",
        "close": "סגור",
        "waiting_stream": "ממתין לשידור",
        "request_camera_and_mic": "אנחנו צריכים גישה למצלמה ולמיקרופון שלך כדי שתוכל לבצע שיחות.",
        "call_in_progress": "שיחה בעיצומה",
        "group": "אֶשׁכּוֹל",
        "videocall": "שיחת וידאו",
        "call": "שִׂיחָה",
        "connecting": "מְקַשֵׁר",
        "call_cancelled": "השיחה בוטלה",
        "calling": "יִעוּד",
        "hangup": "לִתְלוֹת",
        "reject": "יְרִידָה",
        "has_sent_a_file": "שלח קובץ.",
        "keep": "לִשְׁמוֹר",
        "keep_file": "אם תאפשר את שמירת הקובץ, הוא יישמר באפליקציה. זה יאפשר את הנגישות שלו ללא צורך בחיבור לאינטרנט.",
        "not_keep_file": "אם תשבית את שמירת הקובץ, הוא יימחק ולא תוכל לגשת אליו ללא חיבור לאינטרנט.",
        "file_stored_instruction": "כל הקבצים המאוחסנים יופיעו עם גבול ירוק ברשימת הקבצים הנפוצים. אתה יכול גם לראות אותם במדור",
        "stored": "מְאוּחסָן",
        "not_stored": "אינו מאוחסן",
        "file_not_stored_instruction": "לא תוכל לגשת לקובץ זה ללא חיבור לאינטרנט.",
        "device": "התקן",
        "file_visibility": "נראות הקובץ",
        "visible_for_everyone": "גלוי לכולם",
        "shared_link": "קישור משותף",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Hashtags עוזרים לך למצוא את הקבצים שלך.",
        "edit": "לַעֲרוֹך",
        "move": "לָזוּז",
        "rename": "שנה שם",
        "visibility": "רְאוּת",
        "delete": "לְחַסֵל",
        "file_warn_delete": "האם אתה בטוח שברצונך למחוק את הקובץ?",
        "no_subtags_availables": "אין תגי משנה זמינים",
        "owner": "בורא",
        "expulsing": "מגרש",
        "expulse": "לְגַרֵשׁ",
        "you_have_left_the_group": "עזבת את הקבוצה",
        "coming_out": "יוצא",
        "leave_group": "לעזוב את הקבוצה",
        "you_have_left_the_room": "יצאת מהחדר.",
        "leave_room": "עזוב את החדר",
        "message_deleted": "הודעה שנמחקה",
        "has_been_error_deleting_message": "אירעה שגיאה בעת מחיקת ההודעה.",
        "deleting": "מוחק",
        "new_tag": "תווית חדשה",
        "photoEditor": {
            "name": "שֵׁם",
            "save": "סיים",
            "saveAs": "שמור כ",
            "back": "חזור",
            "loading": "טְעִינָה…",
            "resetOperations": "אפס/מחק את כל הפעולות.",
            "changesLoseConfirmation": "כל השינויים יוסרו.",
            "changesLoseConfirmationHint": "האם אתה בטוח שאתה רוצה להמשיך?",
            "cancel": "לְבַטֵל",
            "continue": "לְהַמשִׁיך",
            "undoTitle": "בטל את הפעולה האחרונה",
            "redoTitle": "בצע שוב את הפעולה האחרונה",
            "showImageTitle": "הצג תמונה מקורית",
            "zoomInTitle": "לְהִתְמַקֵד",
            "zoomOutTitle": "להקטין את התצוגה",
            "toggleZoomMenuTitle": "החלף את תפריט הזום",
            "adjustTab": "לְהַתְאִים",
            "finetuneTab": "צבעים",
            "filtersTab": "מסננים",
            "watermarkTab": "תמונות",
            "annotateTab": "לצייר",
            "resize": "שנה גודל",
            "resizeTab": "גודל",
            "invalidImageError": "תמונה לא חוקית",
            "uploadImageError": "אירעה שגיאה בעת טעינת התמונה.",
            "areNotImages": "הם לא תמונות",
            "isNotImage": "זו לא תמונה",
            "toBeUploaded": "לטעון",
            "cropTool": "גזירה",
            "original": "מְקוֹרִי",
            "custom": "מותאם אישית",
            "square": "כיכר",
            "landscape": "אופקי",
            "portrait": "אֲנָכִי",
            "ellipse": "מעגל",
            "classicTv": "טלוויזיה קלאסית",
            "cinemascope": "סינמסקופ",
            "arrowTool": "חֵץ",
            "blurTool": "לְטַשׁטֵשׁ",
            "brightnessTool": "לַהַט",
            "contrastTool": "בניגוד",
            "ellipseTool": "מעגל",
            "unFlipX": "הסר את X",
            "flipX": "היפוך X",
            "unFlipY": "הסר את Y",
            "flipY": "הפוך את Y",
            "hsvTool": "MSV",
            "hue": "צֶבַע",
            "saturation": "רִוּוּי",
            "value": "שִׁוּוּי",
            "imageTool": "תמונה",
            "importing": "מייבא…",
            "addImage": "+ הוסף תמונה",
            "uploadImage": "העלאת תמונה",
            "fromGallery": "מדבקות",
            "lineTool": "קַו",
            "penTool": "עִפָּרוֹן",
            "polygonTool": "מְצוּלָע",
            "sides": "הצדדים",
            "rectangleTool": "מַלבֵּן",
            "cornerRadius": "רדיוס קצה",
            "resizeWidthTitle": "רוחב בפיקסלים",
            "resizeHeightTitle": "גובה פיקסל",
            "toggleRatioLockTitle": "החלף את נעילת מימד",
            "reset": "איתחול",
            "resetSize": "שחזר את המידות המקוריות של התמונה",
            "rotateTool": "להתחלף",
            "textTool": "טֶקסט",
            "textSpacings": "מרווח טקסט",
            "textAlignment": "יישור טקסט",
            "fontFamily": "משפחת גופן",
            "size": "גודל",
            "letterSpacing": "ריווח אותיות",
            "lineHeight": "גובה קו",
            "warmthTool": "חוֹם",
            "addWatermark": "+ הוסף תמונה",
            "addWatermarkTitle": "בחר את סוג סימן המים",
            "uploadWatermark": "בחר תמונה",
            "addWatermarkAsText": "הוסף טקסט",
            "padding": "מְמוּלָא",
            "shadow": "צֵל",
            "horizontal": "אופקי",
            "vertical": "אֲנָכִי",
            "blur": "לְטַשׁטֵשׁ",
            "opacity": "אֲטִימוּת",
            "position": "עמדה",
            "stroke": "עוֹבִי",
            "saveAsModalLabel": "פרטים",
            "extension": "סיומת",
            "nameIsRequired": "השם נדרש.",
            "quality": "איכות",
            "imageDimensionsHoverTitle": "מידות תמונה שמורות (רוחב x גובה)",
            "cropSizeLowerThanResizedWarning": "שים לב ששטח החיתוך שנבחר קטן מהשינוי הגודל שהוחל, מה שעלול לגרום לירידה באיכות.",
            "actualSize": "גודל נוכחי (100%)",
            "fitSize": "התאם מימד",
            "addImageTitle": "בחר תמונה להוספה…"
        },
        "changing": "מִשְׁתַנֶה",
        "profile_picture_changed": "תמונת הפרופיל השתנתה",
        "loading_profile_picture": "טוען תמונת פרופיל",
        "change": "שינוי",
        "session": "מוֹשָׁב",
        "system_operative": "מערכת הפעלה",
        "navigator": "דפדפן",
        "authorize": "לְאַשֵׁר",
        "deauthorize": "לְהִתְכַּחֵשׁ",
        "keep_tag_content": "האם אתה רוצה לשמר את תוכן התג?",
        "move_content_to": "העבר תוכן אל",
        "move_to": "לעבור ל",
        "chat_request": "בקשת צ'אט",
        "accept_request": "קבל בקשה",
        "go_to_chat": "עבור לצ'אט",
        "applying": "מגיש בקשה",
        "time": "זְמַן",
        "adjust": "לְהַתְאִים",
        "rotate": "להתחלף",
        "start": "הַתחָלָה",
        "end": "סופי",
        "lang_name": "Hebrew"
    },
    "hi": {
        "name": "नाम",
        "name_example": "Ranjit",
        "last_name": "उपनाम",
        "last_name_example": "Devi",
        "username": "उपयोगकर्ता",
        "username_example": "ranjit_devi",
        "email": "ईमेल",
        "email_example": "उपयोगकर्ता@कार्यक्षेत्र.com",
        "password": "पासवर्ड",
        "new_password": "नया पासवर्ड",
        "password_example": "*****************",
        "login": "लॉग इन करें",
        "register": "पंजीकरण करवाना",
        "verification_code": "सत्यापन कोड",
        "verification_code_example": "xxx",
        "verify": "सत्यापित करें",
        "loading": "चार्ज…",
        "please_wait": "कृपया प्रतीक्षा करें",
        "go_back": "वापस जाओ",
        "recovery": "वापस पाना",
        "login_subtitle": "अपना विवरण दर्ज करें और अपने खाते तक पहुंचें।",
        "forgot_password_link": "क्या अपना पासवर्ड भूल गए हैं? वापस पाना।",
        "register_account_link": "आपके पास खाता नहीं है? अभिलेख!",
        "register_subtitle": "अपना विवरण दर्ज करें और अपना खाता पंजीकृत करें।",
        "login_link": "क्या पहले से आपका खाता है? पहुँच!",
        "verification_code_instruction": "अपना ईमेल जांचें और भेजा गया कोड दर्ज करें।",
        "forgot_password_subtitle": "अपना पासवर्ड पुनर्प्राप्त करने के लिए अपना ईमेल दर्ज करें।",
        "change_password_subtitle": "नया पारण शब्द भरे।",
        "change_password_code_used": "आपका पासवर्ड पुनर्प्राप्ति कोड अमान्य है या पहले ही उपयोग किया जा चुका है।",
        "no_chats": "आपके पास बातचीत नहीं है.",
        "chats": "बात चिट",
        "groups": "समूह",
        "rooms": "कमरा",
        "save": "रखना",
        "download": "स्राव होना",
        "downloading_image": "छवि डाउनलोड हो रही है",
        "an_error_has_ocurred": "एक गलती हुई है",
        "saving_changes": "बचत परिवर्तन",
        "go_back_to_edit": "पुनः संपादित करें",
        "upload_warning_modal": "संपादन अपलोड करने से मूल छवि प्रतिस्थापित हो जाएगी.",
        "upload_question_modal": "क्या आप मूल रखना चाहते हैं और एक प्रति बनाना चाहते हैं?",
        "create_copy_and_keep": "कॉपी बनाएं और रखें",
        "upload": "डालना",
        "upload_changes": "परिवर्तन अपलोड करें",
        "changes_saved": "बदलाव सुरक्षित किया गया",
        "downloading_video": "वीडियो डाउनलोड हो रहा है",
        "video_upload_warning_modal": "संपादन अपलोड करने से मूल वीडियो प्रतिस्थापित हो जाएगा।",
        "results": "परिणाम",
        "members": "सदस्यों",
        "cancel": "रद्द करना",
        "add_user": "उपयोगकर्ता जोड़ें",
        "no_messages": "कोई संदेश नहीं",
        "message_placeholder": "संदेश…",
        "group_doesnt_exists": "समूह मौजूद नहीं है",
        "you_arent_group_member": "आप समूह के सदस्य नहीं हैं",
        "touch_to_see_more_info": "अधिक विवरण देखने के लिए टैप करें.",
        "start_videocall": "वीडियो कॉल प्रारंभ करें",
        "start_call": "कॉल प्रारंभ करें",
        "create_group": "समूह बनाना",
        "group_name": "समूह नाम",
        "add_members_to_create_group": "समूह बनाने के लिए सदस्यों को जोड़ें",
        "search": "देखो के लिए",
        "creating": "बनाना…",
        "dont_belong_anyone_group": "आप किसी समूह से संबंधित नहीं हैं.",
        "already_add_this_hashtag": "आपने वह हैशटैग पहले ही जोड़ लिया है",
        "search_files_by_hashtags": "हैशटैग द्वारा फ़ाइलें खोजें",
        "enter_to_add": "जोड़ने के लिए दर्ज करें",
        "hashtags_most_used": "सबसे ज्यादा इस्तेमाल किये जाने वाले हैशटैग",
        "can_add_it_giving_click": "आप उन पर क्लिक करके उन्हें अपनी खोज में जोड़ सकते हैं।",
        "searching_files": "फ़ाइलें खोज रहे हैं",
        "no_files_found": "कोई फाईल नहीं मिली",
        "image": "छवि",
        "video": "वीडियो",
        "audio": "ऑडियो",
        "library": "किताबों का दुकान",
        "home": "होम पेज",
        "block": "अवरोध पैदा करना",
        "unblock": "खोलने के लिये",
        "has_blocked_you": "उसने तुम्हें ब्लॉक कर दिया है",
        "accept": "स्वीकार करना",
        "decline": "गिरावट",
        "cancel_request": "अनुरोध को रद्द करें",
        "has_been_expulsed": "निष्कासित कर दिया गया है",
        "room_doesnt_exists": "कमरा मौजूद नहीं है",
        "you_arent_room_member": "आप कमरे के सदस्य नहीं हैं",
        "create_room": "जगह बनाना",
        "room_name": "कमरे का नाम",
        "no_rooms_created": "कोई कमरा नहीं बनाया गया है.",
        "dont_belong_to_anyone_room": "आप किसी भी कमरे में नहीं हैं.",
        "enter_a_username": "एक उपयोगकर्ता लिखें",
        "no_results": "कोई परिणाम नहीं",
        "create": "बनाएं",
        "no_content_uploaded": "कोई सामग्री अपलोड नहीं की गई",
        "new_subtag": "नया उपटैग",
        "update_info": "जानकारी अपडेट करें",
        "updating": "अद्यतन करने",
        "update": "अद्यतन",
        "update_email": "ईमेल अपडेट करें",
        "change_password": "पासवर्ड बदलें",
        "current_password": "वर्तमान पासवर्ड",
        "select": "चुनना",
        "tap_to_upload_a_file": "फ़ाइल अपलोड करने के लिए टैप करें",
        "edit_photo": "फ़ोटो संपादित करें",
        "preview": "पूर्व दर्शन",
        "edit_video": "वीडियो संपादित करें",
        "delete_changes": "परिवर्तन हटाएँ",
        "uploading": "ऊपर जा रहा है",
        "session_deleted_or_expired": "सत्र हटा दिया गया या समाप्त हो गया",
        "edit_profile": "प्रोफ़ाइल संपादित करें",
        "devices": "उपकरण",
        "downloads": "डाउनलोड",
        "logout": "साइन ऑफ़",
        "offline": "बिना कनेक्शन के",
        "images": "इमेजिस",
        "videos": "वीडियो",
        "audios": "ऑडियो",
        "chat": "बात करना",
        "session_unauthorized": "आपका सत्र अधिकृत नहीं है.",
        "authorize_session": "इस सत्र तक पहुंचने के लिए, आपको अपने डिवाइस को अधिकृत करना होगा।",
        "authorize_instructions": "प्राधिकरण लिंक के लिए अपना ईमेल जांचें या किसी सक्रिय सत्र पर जाएं और ऊपरी दाएं कोने में तीन बिंदुओं का चयन करें, फिर \"डिवाइस\" पर जाएं और \"प्राधिकृत करें\" चुनें।",
        "refresh_page_after_authorize": "एक बार जब आप प्राधिकरण प्रक्रिया पूरी कर लें, तो सत्र तक पहुंचने के लिए पृष्ठ को ताज़ा करें।",
        "check_your_inbox": "अपने ईमेल की जाँच करें",
        "send_authorization_email": "प्राधिकरण ईमेल भेजें",
        "call_finished": "कॉल समाप्त",
        "close": "बंद करना",
        "waiting_stream": "प्रसारण की प्रतीक्षा की जा रही है",
        "request_camera_and_mic": "हमें आपके कैमरे और माइक्रोफ़ोन तक पहुंच की आवश्यकता है ताकि आप कॉल कर सकें।",
        "call_in_progress": "कॉल प्रगति पर है",
        "group": "झुंड",
        "videocall": "वीडियो कॉल",
        "call": "पुकारना",
        "connecting": "कनेक्ट",
        "call_cancelled": "कॉल रद्द कर दी गई",
        "calling": "कॉलिंग",
        "hangup": "लटकाना",
        "reject": "गिरावट",
        "has_sent_a_file": "एक फाइल भेजी है.",
        "keep": "रखना",
        "keep_file": "यदि आप फ़ाइल अवधारण सक्षम करते हैं, तो यह ऐप में सहेजा जाएगा। यह आपको इंटरनेट कनेक्शन की आवश्यकता के बिना इसे एक्सेस करने की अनुमति देगा।",
        "not_keep_file": "यदि आप फ़ाइल को सहेजना बंद कर देते हैं, तो यह हटा दी जाएगी और आप इंटरनेट कनेक्शन के बिना इसे एक्सेस नहीं कर पाएंगे।",
        "file_stored_instruction": "सभी संग्रहीत फ़ाइलें हरे बॉर्डर के साथ सामान्य फ़ाइल सूची में दिखाई देंगी। आप उन्हें अनुभाग में भी देख सकते हैं",
        "stored": "संग्रहित",
        "not_stored": "यह संग्रहित नहीं है",
        "file_not_stored_instruction": "आप इंटरनेट कनेक्शन के बिना इस फ़ाइल तक नहीं पहुंच पाएंगे.",
        "device": "उपकरण",
        "file_visibility": "फ़ाइल दृश्यता",
        "visible_for_everyone": "सभी के लिए दृश्यमान",
        "shared_link": "साझा किया गया लिंक",
        "hashtags": "हैशटैग",
        "hashtags_helps_to_search_your_file": "हैशटैग आपकी फ़ाइलें ढूंढने में आपकी सहायता करते हैं.",
        "edit": "संपादन करना",
        "move": "कदम",
        "rename": "नाम बदलें",
        "visibility": "दृश्यता",
        "delete": "हटाना",
        "file_warn_delete": "क्या आप वाकई फ़ाइल को हटाना चाहते हैं?",
        "no_subtags_availables": "कोई उपशीर्षक उपलब्ध नहीं है",
        "owner": "निर्माता",
        "expulsing": "निष्कासित",
        "expulse": "निष्कासित",
        "you_have_left_the_group": "आपने समूह छोड़ दिया है",
        "coming_out": "बाहर आ रहा है",
        "leave_group": "समूह छोड़ें",
        "you_have_left_the_room": "आप कमरा छोड़ चुके हैं.",
        "leave_room": "कमरे से बाहर चले जाओ",
        "message_deleted": "संदेश हटाये",
        "has_been_error_deleting_message": "संदेश हटाते समय एक त्रुटि उत्पन्न हुई.",
        "deleting": "निकाला जा रहा है",
        "new_tag": "नया टैग",
        "photoEditor": {
            "name": "नाम",
            "save": "खत्म करना",
            "saveAs": "के रूप रक्षित करें",
            "back": "पीछे",
            "loading": "चार्ज…",
            "resetOperations": "सभी परिचालन पुनः आरंभ/हटाएँ।",
            "changesLoseConfirmation": "सभी परिवर्तन खारिज कर दिये जायेंगे.",
            "changesLoseConfirmationHint": "क्या आप वाकई जारी रखना चाहते हैं?",
            "cancel": "रद्द करना",
            "continue": "जारी रखना",
            "undoTitle": "अंतिम ऑपरेशन पूर्ववत करें",
            "redoTitle": "अंतिम ऑपरेशन दोबारा करें",
            "showImageTitle": "मूल छवि दिखाएं",
            "zoomInTitle": "ज़ूम इन",
            "zoomOutTitle": "ज़ूम आउट",
            "toggleZoomMenuTitle": "ज़ूम मेनू टॉगल करें",
            "adjustTab": "समायोजित करना",
            "finetuneTab": "रंग की",
            "filtersTab": "फिल्टर",
            "watermarkTab": "इमेजिस",
            "annotateTab": "खींचना",
            "resize": "आकार",
            "resizeTab": "आकार",
            "invalidImageError": "अमान्य छवि",
            "uploadImageError": "छवि लोड करते समय एक त्रुटि उत्पन्न हुई.",
            "areNotImages": "वे छवियाँ नहीं हैं",
            "isNotImage": "यह कोई छवि नहीं है",
            "toBeUploaded": "अपलोड किया जाना है",
            "cropTool": "काटना",
            "original": "मूल",
            "custom": "निजीकृत",
            "square": "वर्ग",
            "landscape": "क्षैतिज",
            "portrait": "खड़ा",
            "ellipse": "घेरा",
            "classicTv": "क्लासिक टी.वी",
            "cinemascope": "सिनेमास्कोप",
            "arrowTool": "तीर",
            "blurTool": "कलंक",
            "brightnessTool": "चमकना",
            "contrastTool": "अंतर",
            "ellipseTool": "घेरा",
            "unFlipX": "एक्स को अनफ़्लिप करें",
            "flipX": "फ्लिप एक्स",
            "unFlipY": "Y को अनफ़्लिप करें",
            "flipY": "Y को पलटें",
            "hsvTool": "MSV",
            "hue": "रंग",
            "saturation": "परिपूर्णता",
            "value": "लायक",
            "imageTool": "छवि",
            "importing": "आयात कर रहा है…",
            "addImage": "+ छवि जोड़ें",
            "uploadImage": "तस्विर अपलोड करना",
            "fromGallery": "स्टिकर",
            "lineTool": "रेखा",
            "penTool": "पेंसिल",
            "polygonTool": "बहुभुज",
            "sides": "पक्षों",
            "rectangleTool": "आयत",
            "cornerRadius": "किनारे की त्रिज्या",
            "resizeWidthTitle": "पिक्सेल में चौड़ाई",
            "resizeHeightTitle": "पिक्सेल ऊंचाई",
            "toggleRatioLockTitle": "आयाम लॉक टॉगल करें",
            "reset": "पुनः आरंभ करें",
            "resetSize": "छवि के मूल आयाम पुनर्स्थापित करें",
            "rotateTool": "घुमाएँ",
            "textTool": "मूलपाठ",
            "textSpacings": "पाठ रिक्ति",
            "textAlignment": "पाठ्य संरेखण",
            "fontFamily": "फुहारा परिवार",
            "size": "आकार",
            "letterSpacing": "पत्र अंतराल",
            "lineHeight": "ऊंची लाईन",
            "warmthTool": "गर्मी",
            "addWatermark": "+ छवि जोड़ें",
            "addWatermarkTitle": "छवि प्रकार चुनें",
            "uploadWatermark": "छवि चुने",
            "addWatermarkAsText": "लेख जोड़ें",
            "padding": "भरवां",
            "shadow": "छाया",
            "horizontal": "क्षैतिज",
            "vertical": "खड़ा",
            "blur": "कलंक",
            "opacity": "अस्पष्टता",
            "position": "पद",
            "stroke": "मोटाई",
            "saveAsModalLabel": "विवरण",
            "extension": "विस्तार",
            "nameIsRequired": "नाम आवश्यक है.",
            "quality": "गुणवत्ता",
            "imageDimensionsHoverTitle": "सहेजी गई छवि आयाम (चौड़ाई x ऊँचाई)",
            "cropSizeLowerThanResizedWarning": "कृपया ध्यान दें कि चयनित फसल क्षेत्र लागू आकार से छोटा है, जिसके परिणामस्वरूप गुणवत्ता में कमी आ सकती है।",
            "actualSize": "वर्तमान आकार (100%)",
            "fitSize": "आयाम को अनुकूलित करें",
            "addImageTitle": "जोड़ने के लिए एक छवि चुनें…"
        },
        "changing": "बदलना",
        "profile_picture_changed": "प्रोफ़ाइल फ़ोटो बदल गई",
        "loading_profile_picture": "प्रोफ़ाइल फ़ोटो लोड हो रहा है",
        "change": "परिवर्तन",
        "session": "सत्र",
        "system_operative": "ओएस",
        "navigator": "ब्राउज़र",
        "authorize": "अधिकृत",
        "deauthorize": "अस्वीकृत करें",
        "keep_tag_content": "क्या आप लेबल की सामग्री को संरक्षित करना चाहते हैं?",
        "move_content_to": "सामग्री को यहां ले जाएं",
        "move_to": "करने के लिए कदम",
        "chat_request": "चैट अनुरोध",
        "accept_request": "अनुरोध स्वीकार करें",
        "go_to_chat": "चैट पर जाएँ",
        "applying": "को लागू करने",
        "time": "समय",
        "adjust": "समायोजित करना",
        "rotate": "घुमाएँ",
        "start": "शुरू",
        "end": "अंत",
        "lang_name": "Hindi"
    },
    "hu": {
        "name": "Név",
        "name_example": "László",
        "last_name": "Vezetéknév",
        "last_name_example": "Tóth",
        "username": "Felhasználó",
        "username_example": "laszlo_toth",
        "email": "E-mail cím",
        "email_example": "felhasznalo@domain.com",
        "password": "Jelszó",
        "new_password": "Új jelszó",
        "password_example": "*****************",
        "login": "Bejelentkezés a  oldalon",
        "register": "Regisztráció",
        "verification_code": "Hitelesítési kód",
        "verification_code_example": "xxx",
        "verify": "Ellenőrizze",
        "loading": "Betöltés…",
        "please_wait": "Kérjük, várjon",
        "go_back": "Vissza",
        "recovery": "Visszakeresés",
        "login_subtitle": "Adja meg adatait, és jelentkezzen be fiókjába.",
        "forgot_password_link": "Elfelejtette jelszavát? Szerezze vissza.",
        "register_account_link": "Nincs még fiókod? Regisztrálj!",
        "register_subtitle": "Adja meg adatait és regisztrálja fiókját.",
        "login_link": "Már van fiókod? Jelentkezz be!",
        "verification_code_instruction": "Ellenőrizze az e-mailjét, és adja meg a küldött kódot.",
        "forgot_password_subtitle": "Adja meg e-mail címét a jelszó lekérdezéséhez.",
        "change_password_subtitle": "Adjon meg egy új jelszót.",
        "change_password_code_used": "A jelszó visszaállításához használt kód érvénytelen vagy már használták.",
        "no_chats": "Nem folytatsz beszélgetéseket.",
        "chats": "Beszélgetések",
        "groups": "Csoportok",
        "rooms": "Szobák",
        "save": "Mentés",
        "download": "Letöltés",
        "downloading_image": "Kép letöltése",
        "an_error_has_ocurred": "Hiba történt",
        "saving_changes": "Változások mentése",
        "go_back_to_edit": "Vissza a szerkesztéshez",
        "upload_warning_modal": "A szerkesztés feltöltése az eredeti kép helyébe lép.",
        "upload_question_modal": "Szeretné megtartani az eredetit és készíteni egy másolatot?",
        "create_copy_and_keep": "Készítsen másolatot és tartsa meg",
        "upload": "Feltöltése",
        "upload_changes": "Módosítások feltöltése",
        "changes_saved": "Mentett módosítások",
        "downloading_video": "Videó letöltése",
        "video_upload_warning_modal": "Az eredeti videó helyébe a szerkesztés feltöltésekor az eredeti videó kerül.",
        "results": "Eredmények",
        "members": "Tagok",
        "cancel": "Cancel",
        "add_user": "Felhasználó hozzáadása",
        "no_messages": "Nincs üzenet",
        "message_placeholder": "Üzenet…",
        "group_doesnt_exists": "A csoport nem létezik",
        "you_arent_group_member": "Ön nem tagja a csoportnak",
        "touch_to_see_more_info": "Érintse meg a további információkért.",
        "start_videocall": "Videohívás indítása",
        "start_call": "Hívás megkezdése",
        "create_group": "Csoport létrehozása",
        "group_name": "A csoport neve",
        "add_members_to_create_group": "Tagok hozzáadása a csoport létrehozásához",
        "search": "Keresés",
        "creating": "A  létrehozásával…",
        "dont_belong_anyone_group": "Ön nem tartozik semmilyen csoporthoz.",
        "already_add_this_hashtag": "Ön már hozzáadta ezt a hashtaget",
        "search_files_by_hashtags": "Fájlok keresése hashtagek alapján",
        "enter_to_add": "Enter a hozzáadáshoz",
        "hashtags_most_used": "Leggyakrabban használt hashtagek",
        "can_add_it_giving_click": "A kereséshez hozzáadhatja őket, ha rájuk kattint.",
        "searching_files": "Fájlok keresése",
        "no_files_found": "Nem talált fájlok",
        "image": "Kép",
        "video": "Videó",
        "audio": "Audio",
        "library": "Könyvesbolt",
        "home": "Home",
        "block": "Blokk",
        "unblock": "A  oldja fel a blokkolást.",
        "has_blocked_you": "Blokkolta magát",
        "accept": "Elfogadom",
        "decline": "Visszautasítani",
        "cancel_request": "Kérés visszavonása",
        "has_been_expulsed": "kiutasították",
        "room_doesnt_exists": "A szoba nem létezik",
        "you_arent_room_member": "Ön nem tagja a szobának",
        "create_room": "Terem létrehozása",
        "room_name": "A szoba neve",
        "no_rooms_created": "Nincsenek létrehozott szobák.",
        "dont_belong_to_anyone_room": "Nem tartozol egyetlen szobához sem.",
        "enter_a_username": "Írjon egy felhasználói",
        "no_results": "Nincs eredmény",
        "create": "Hozzon létre",
        "no_content_uploaded": "Nincs feltöltött tartalom",
        "new_subtag": "Új alcímke",
        "update_info": "Információ frissítése",
        "updating": "A  frissítése",
        "update": "Frissítés",
        "update_email": "E-mail frissítése",
        "change_password": "Jelszó módosítása",
        "current_password": "Jelenlegi jelszó",
        "select": "Válassza",
        "tap_to_upload_a_file": "Érintse meg a fájl feltöltéséhez",
        "edit_photo": "Fotó szerkesztése",
        "preview": "Előnézet",
        "edit_video": "Videó szerkesztése",
        "delete_changes": "Változások törlése",
        "uploading": "A  feltöltése",
        "session_deleted_or_expired": "Munkamenet törölt vagy lejárt",
        "edit_profile": "Profil szerkesztése",
        "devices": "Készülékek",
        "downloads": "Letöltések",
        "logout": "Kijelentkezés",
        "offline": "Offline",
        "images": "Képek",
        "videos": "Videók",
        "audios": "Audios",
        "chat": "Chat",
        "session_unauthorized": "Az Ön munkamenete nem engedélyezett.",
        "authorize_session": "A munkamenet eléréséhez engedélyeznie kell a készüléket.",
        "authorize_instructions": "Kérjük, ellenőrizze e-mailjében az engedélyezési linket, vagy jelentkezzen be egy aktív munkamenetbe, és válassza a három pontot a jobb felső sarokban, majd menjen az \"Eszközök\" menüpontra, és válassza az \"Engedélyezés\" lehetőséget.",
        "refresh_page_after_authorize": "Miután befejezte az engedélyezési folyamatot, frissítse a bejelentkezési oldalt.",
        "check_your_inbox": "Ellenőrizze az e-mailjét",
        "send_authorization_email": "Engedélyező e-mail küldése",
        "call_finished": "A hívás befejeződött",
        "close": "Bezárás",
        "waiting_stream": "Átvitelre vár",
        "request_camera_and_mic": "A telefonáláshoz hozzáférésre van szükség a kamerához és a mikrofonhoz.",
        "call_in_progress": "Folyamatban lévő hívás",
        "group": "Csoport",
        "videocall": "Videohívás",
        "call": "Hívja fel",
        "connecting": "Csatlakozás",
        "call_cancelled": "Törölt hívás",
        "calling": "Hívás",
        "hangup": "Tedd le",
        "reject": "Visszautasítani",
        "has_sent_a_file": "küldött egy fájlt.",
        "keep": "Megőrzés",
        "keep_file": "Ha aktiválja a fájlok mentésének lehetőségét, azok az alkalmazásban maradnak. Ez lehetővé teszi, hogy internetkapcsolat nélkül is hozzáférjen hozzájuk.",
        "not_keep_file": "Ha kikapcsolja a fájlok mentésének lehetőségét, azok törlődnek, és internetkapcsolat nélkül nem érhetők el.",
        "file_stored_instruction": "Az összes tárolt fájl zöld keretben jelenik meg a megosztott fájlok listájában. Ezeket megtekintheti a",
        "stored": "Tárolva",
        "not_stored": "Nincs tárolva",
        "file_not_stored_instruction": "Internetkapcsolat nélkül nem tud hozzáférni ehhez a fájlhoz.",
        "device": "Készülék",
        "file_visibility": "Archívum láthatósága",
        "visible_for_everyone": "Mindenki által látható",
        "shared_link": "Megosztott link",
        "hashtags": "Hashtagek",
        "hashtags_helps_to_search_your_file": "A hashtagek segítenek a fájlok megtalálásában.",
        "edit": "Szerkeszteni",
        "move": "Mozgás",
        "rename": "Átnevezés",
        "visibility": "Láthatóság",
        "delete": "Törlés",
        "file_warn_delete": "Biztos, hogy törölni szeretné a fájlt?",
        "no_subtags_availables": "Nincsenek elérhető alcímkék",
        "owner": "Alkotó",
        "expulsing": "Kilökés",
        "expulse": "Expulzus",
        "you_have_left_the_group": "Elhagytad a csoportot",
        "coming_out": "Elhagyva",
        "leave_group": "Hagyja el a csoportot",
        "you_have_left_the_room": "Elhagytad a szobát.",
        "leave_room": "A szoba elhagyása",
        "message_deleted": "Üzenet törölve",
        "has_been_error_deleting_message": "Hiba történt az üzenet törlése közben",
        "deleting": "A  megszüntetése",
        "new_tag": "Új címke",
        "photoEditor": {
            "name": "Név",
            "save": "Vége",
            "saveAs": "Mentés mint",
            "back": "Vissza",
            "loading": "Betöltés…",
            "resetOperations": "Minden művelet alaphelyzetbe állítása/törlése.",
            "changesLoseConfirmation": "Minden módosítás törlésre kerül.",
            "changesLoseConfirmationHint": "Biztos, hogy folytatni akarod?",
            "cancel": "Törölje",
            "continue": "Folytatás",
            "undoTitle": "Az előző művelet visszavonása",
            "redoTitle": "Az utolsó művelet megismétlése",
            "showImageTitle": "Eredeti kép megjelenítése",
            "zoomInTitle": "Közelíts rá",
            "zoomOutTitle": "Kicsinyítsd ki",
            "toggleZoomMenuTitle": "Zoom menü kapcsolása",
            "adjustTab": "Állítsa be a",
            "finetuneTab": "Színek",
            "filtersTab": "Szűrők",
            "watermarkTab": "Képek",
            "annotateTab": "Rajzolás",
            "resize": "Méretváltoztatás",
            "resizeTab": "Méret",
            "invalidImageError": "Érvénytelen kép",
            "uploadImageError": "Hiba történt a kép betöltése közben.",
            "areNotImages": "ezek nem képek",
            "isNotImage": "nem egy kép",
            "toBeUploaded": "feltöltendő",
            "cropTool": "Vágás",
            "original": "Eredeti",
            "custom": "Személyre szabott",
            "square": "Négyzet",
            "landscape": "Vízszintes",
            "portrait": "Függőleges",
            "ellipse": "Kör",
            "classicTv": "Klasszikus TV",
            "cinemascope": "Cinemascope",
            "arrowTool": "Nyíl",
            "blurTool": "Elmosódás",
            "brightnessTool": "Fényerő",
            "contrastTool": "Kontraszt",
            "ellipseTool": "Kör",
            "unFlipX": "Unflip X",
            "flipX": "Flip X",
            "unFlipY": "Unflip Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "Nuance",
            "saturation": "Telítettség",
            "value": "Érték",
            "imageTool": "Kép",
            "importing": "Importálás…",
            "addImage": "+ Kép hozzáadása",
            "uploadImage": "Kép feltöltése",
            "fromGallery": "Matricák",
            "lineTool": "Line",
            "penTool": "Pencil",
            "polygonTool": "Poligon",
            "sides": "Oldalain",
            "rectangleTool": "Téglalap",
            "cornerRadius": "Sarok sugara",
            "resizeWidthTitle": "Szélesség pixelben",
            "resizeHeightTitle": "Magasság pixelben",
            "toggleRatioLockTitle": "Méretzár bekapcsolása",
            "reset": "Újraindítás",
            "resetSize": "A kép eredeti méretének visszaállítása",
            "rotateTool": "Forgassa",
            "textTool": "Szöveg",
            "textSpacings": "Szövegtávolságok",
            "textAlignment": "Szöveg igazításaBetűcsalád",
            "fontFamily": "Betűcsalád",
            "size": "Méret",
            "letterSpacing": "Betűköz",
            "lineHeight": "Vonalmagasság",
            "warmthTool": "Hő",
            "addWatermark": "+ Kép hozzáadása",
            "addWatermarkTitle": "Válassza ki a vízjel típusát",
            "uploadWatermark": "Kép kiválasztása",
            "addWatermarkAsText": "Szöveg hozzáadása",
            "padding": "Kitömés",
            "shadow": "Árnyék",
            "horizontal": "Vízszintes",
            "vertical": "Függőleges",
            "blur": "Elmosódás",
            "opacity": "Opacitás",
            "position": "Helyzet",
            "stroke": "Vastagság",
            "saveAsModalLabel": "Részletek",
            "extension": "Hosszabbítás",
            "nameIsRequired": "A név megadása kötelező.",
            "quality": "Minőség",
            "imageDimensionsHoverTitle": "A mentett képek mérete (szélesség x magasság)",
            "cropSizeLowerThanResizedWarning": "Vegye figyelembe, hogy a kiválasztott vágási terület kisebb, mint az alkalmazott méretváltoztatás, ami minőségromlással járhat.",
            "actualSize": "Jelenlegi méret (100%)",
            "fitSize": "Adapt dimenzió",
            "addImageTitle": "Válasszon ki egy képet a hozzáadáshoz…"
        },
        "changing": "Változó",
        "profile_picture_changed": "Profilkép megváltozott",
        "loading_profile_picture": "Profilkép betöltése",
        "change": "Változás",
        "session": "Ülésszak",
        "system_operative": "Operációs rendszer",
        "navigator": "Böngésző",
        "authorize": "Engedélyezze",
        "deauthorize": "Deauthorise",
        "keep_tag_content": "Szeretné megtartani a címke tartalmát?",
        "move_content_to": "A tartalom áthelyezése a",
        "move_to": "Mozgás",
        "chat_request": "Csevegő kérés",
        "accept_request": "Alkalmazás elfogadása",
        "go_to_chat": "Menj a csevegésre",
        "applying": "A  alkalmazásával",
        "time": "Idő",
        "adjust": "Állítsa be a",
        "rotate": "Forgassa el a",
        "start": "Start",
        "end": "End",
        "lang_name": "Hungarian"
    },
    "id": {
        "name": "Nama",
        "name_example": "Muhammad",
        "last_name": "Nama keluarga",
        "last_name_example": "Sari",
        "username": "Pengguna",
        "username_example": "muhammad_sari",
        "email": "Alamat email",
        "email_example": "pengguna@domain.com",
        "password": "Kata sandi",
        "new_password": "Kata sandi baru",
        "password_example": "*****************",
        "login": "Masuk",
        "register": "Daftar",
        "verification_code": "Kode verifikasi",
        "verification_code_example": "xxx",
        "verify": "Periksa",
        "loading": "Memuat…",
        "please_wait": "Silakan tunggu.",
        "go_back": "Kembali ke",
        "recovery": "Mengambil kembali",
        "login_subtitle": "Masukkan detail Anda dan masuk ke akun Anda.",
        "forgot_password_link": "Lupa kata sandi Anda? Ambil kembali.",
        "register_account_link": "Belum punya akun? Daftar!",
        "register_subtitle": "Masukkan detail Anda dan daftarkan akun Anda.",
        "login_link": "Sudah punya akun? Masuk!",
        "verification_code_instruction": "Periksa email Anda dan masukkan kode yang dikirimkan.",
        "forgot_password_subtitle": "Masukkan alamat email Anda untuk mendapatkan kata sandi.",
        "change_password_subtitle": "Masukkan kata sandi baru.",
        "change_password_code_used": "Kode untuk memulihkan kata sandi Anda tidak valid atau telah digunakan.",
        "no_chats": "Anda tidak memiliki percakapan.",
        "chats": "Pembicaraan",
        "groups": "Grup",
        "rooms": "Kamar",
        "save": "Simpan",
        "download": "Unduh",
        "downloading_image": "Mengunduh gambar",
        "an_error_has_ocurred": "Telah terjadi kesalahan",
        "saving_changes": "Menyimpan perubahan",
        "go_back_to_edit": "Kembali ke edit",
        "upload_warning_modal": "Apabila modifikasi diunggah, gambar aslinya akan diganti.",
        "upload_question_modal": "Apakah Anda ingin menyimpan yang asli dan membuat salinannya?",
        "create_copy_and_keep": "Buat salinan dan simpan",
        "upload": "Unggah",
        "upload_changes": "Unggah perubahan",
        "changes_saved": "Perubahan yang disimpan",
        "downloading_video": "Mengunduh video",
        "video_upload_warning_modal": "Video asli akan diganti ketika hasil edit diunggah.",
        "results": "Hasil",
        "members": "Anggota",
        "cancel": "Batal",
        "add_user": "Tambahkan pengguna",
        "no_messages": "Tidak ada pesan",
        "message_placeholder": "Pesan…",
        "group_doesnt_exists": "Grup tidak ada",
        "you_arent_group_member": "Anda bukan anggota grup",
        "touch_to_see_more_info": "Ketuk untuk melihat info lebih lanjut.",
        "start_videocall": "Memulai panggilan video",
        "start_call": "Memulai panggilan",
        "create_group": "Buat grup",
        "group_name": "Nama kelompok",
        "add_members_to_create_group": "Menambahkan anggota untuk membuat grup",
        "search": "Pencarian",
        "creating": "Mencipta…",
        "dont_belong_anyone_group": "Anda tidak termasuk dalam kelompok mana pun.",
        "already_add_this_hashtag": "Sudah menambahkan tagar ini",
        "search_files_by_hashtags": "Mencari file berdasarkan hashtag",
        "enter_to_add": "Masukkan untuk menambahkan",
        "hashtags_most_used": "Tagar yang paling banyak digunakan",
        "can_add_it_giving_click": "Anda dapat menambahkannya ke dalam pencarian Anda dengan mengekliknya.",
        "searching_files": "Mencari file",
        "no_files_found": "Tidak ada file yang ditemukan",
        "image": "Gambar",
        "video": "Video",
        "audio": "Audio",
        "library": "Toko Buku",
        "home": "Beranda",
        "block": "Blokir",
        "unblock": "Buka blokir",
        "has_blocked_you": "Dia telah memblokir Anda",
        "accept": "Terima",
        "decline": "Menolak",
        "cancel_request": "Batalkan permintaan",
        "has_been_expulsed": "telah diusir",
        "room_doesnt_exists": "Ruangan tidak ada",
        "you_arent_room_member": "Anda bukan anggota ruangan",
        "create_room": "Buat ruangan",
        "room_name": "Nama ruangan",
        "no_rooms_created": "Tidak ada ruangan yang dibuat.",
        "dont_belong_to_anyone_room": "Anda tidak termasuk dalam ruangan mana pun.",
        "enter_a_username": "Tulis pengguna",
        "no_results": "Tidak ada hasil",
        "create": "Membuat",
        "no_content_uploaded": "Tidak ada konten yang diupload",
        "new_subtag": "Subtag baru",
        "update_info": "Memperbarui informasi",
        "updating": "Memperbarui…",
        "update": "Memperbarui",
        "update_email": "Perbarui email",
        "change_password": "Mengubah kata sandi",
        "current_password": "Kata sandi saat ini",
        "select": "Pilih",
        "tap_to_upload_a_file": "Ketuk untuk mengunggah file",
        "edit_photo": "Edit foto",
        "preview": "Pratinjau",
        "edit_video": "Mengedit video",
        "delete_changes": "Menghapus perubahan",
        "uploading": "Mengupload",
        "session_deleted_or_expired": "Sesi dihapus atau kedaluwarsa",
        "edit_profile": "Edit profil",
        "devices": "Perangkat",
        "downloads": "Unduhan",
        "logout": "Log out",
        "offline": "Offline",
        "images": "Images",
        "videos": "Videos",
        "audios": "Audios",
        "chat": "Mengobrol",
        "session_unauthorized": "Sesi Anda tidak diotorisasi.",
        "authorize_session": "Untuk mengakses sesi ini, Anda harus mengotorisasi perangkat Anda.",
        "authorize_instructions": "Cari tautan otorisasi di email Anda atau buka sesi aktif dan pilih tiga titik di sudut kanan atas, lalu buka \"Perangkat\" dan pilih \"Otorisasi\".",
        "refresh_page_after_authorize": "Setelah Anda menyelesaikan proses otorisasi, refresh halaman login.",
        "check_your_inbox": "Periksa email Anda",
        "send_authorization_email": "Kirim email otorisasi",
        "call_finished": "Panggilan berakhir",
        "close": "Tutup",
        "waiting_stream": "Menunggu transmisi",
        "request_camera_and_mic": "Kami memerlukan akses ke kamera dan mikrofon Anda agar Anda dapat melakukan panggilan.",
        "call_in_progress": "Panggilan sedang berlangsung",
        "group": "Kelompok",
        "videocall": "Panggilan video",
        "call": "Panggilan",
        "connecting": "Menghubungkan",
        "call_cancelled": "Panggilan dibatalkan",
        "calling": "Memanggil",
        "hangup": "Menutup",
        "reject": "Menolak",
        "has_sent_a_file": "telah mengirim file.",
        "keep": "Mempertahankan",
        "keep_file": "Jika Anda mengaktifkan opsi untuk menyimpan file, maka file tersebut akan disimpan dalam aplikasi. Hal ini akan memungkinkan aksesibilitas tanpa memerlukan koneksi internet.",
        "not_keep_file": "Jika Anda menonaktifkan retensi file, file akan dihapus dan Anda tidak akan dapat mengaksesnya tanpa koneksi Internet.",
        "file_stored_instruction": "Semua file yang disimpan akan muncul dengan batas hijau di daftar file umum. Anda juga dapat melihatnya di daftar file",
        "stored": "Disimpan",
        "not_stored": "Tidak disimpan",
        "file_not_stored_instruction": "Anda tidak akan dapat mengakses file ini tanpa koneksi Internet.",
        "device": "Perangkat",
        "file_visibility": "Visibilitas arsip",
        "visible_for_everyone": "Dapat dilihat oleh semua orang",
        "shared_link": "Tautan bersama",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Tagar membantu dalam pencarian file Anda.",
        "edit": "Sunting",
        "move": "Pindah",
        "rename": "Ganti nama",
        "visibility": "Visibilitas",
        "delete": "Menghapus",
        "file_warn_delete": "Apakah Anda yakin ingin menghapus file tersebut?",
        "no_subtags_availables": "Tidak ada subtag yang tersedia",
        "owner": "Pencipta",
        "expulsing": "Expulsing",
        "expulse": "Mengusir",
        "you_have_left_the_group": "Anda telah keluar dari grup",
        "coming_out": "Meninggalkan",
        "leave_group": "Keluar dari grup",
        "you_have_left_the_room": "Anda telah meninggalkan ruangan.",
        "leave_room": "Meninggalkan ruangan",
        "message_deleted": "Pesan dihapus",
        "has_been_error_deleting_message": "Terjadi kesalahan saat menghapus pesan",
        "deleting": "Menghapus",
        "new_tag": "Tag baru",
        "photoEditor": {
            "name": "Nama",
            "save": "Akhir",
            "saveAs": "Simpan sebagai",
            "back": "Kembali",
            "loading": "Memuat…",
            "resetOperations": "Atur ulang/Hapus semua operasi.",
            "changesLoseConfirmation": "Semua perubahan akan dihapus.",
            "changesLoseConfirmationHint": "Apakah Anda yakin ingin melanjutkan?",
            "cancel": "Batal",
            "continue": "Lanjutkan",
            "undoTitle": "Mengurungkan operasi terakhir",
            "redoTitle": "Mengulang operasi terakhir",
            "showImageTitle": "Tampilkan gambar asli",
            "zoomInTitle": "Memperbesar",
            "zoomOutTitle": "Memperkecil",
            "toggleZoomMenuTitle": "Beralih menu zoom",
            "adjustTab": "Sesuaikan",
            "finetuneTab": "Warna",
            "filtersTab": "Filter",
            "watermarkTab": "Gambar",
            "annotateTab": "Menggambar",
            "resize": "Ubah ukuran",
            "resizeTab": "Ukuran",
            "invalidImageError": "Gambar tidak valid",
            "uploadImageError": "Terjadi kesalahan saat memuat gambar.",
            "areNotImages": "mereka bukan gambar",
            "isNotImage": "bukan gambar",
            "toBeUploaded": "untuk diunggah",
            "cropTool": "Potong",
            "original": "Asli",
            "custom": "Dipersonalisasi",
            "square": "Persegi",
            "landscape": "Horisontal",
            "portrait": "Vertikal",
            "ellipse": "Lingkaran",
            "classicTv": "TV klasik",
            "cinemascope": "Cinemascope",
            "arrowTool": "Panah",
            "blurTool": "Blur",
            "brightnessTool": "Kecerahan",
            "contrastTool": "Kontras",
            "ellipseTool": "Lingkaran",
            "unFlipX": "Unflip X",
            "flipX": "Flip X",
            "unFlipY": "Unflip Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "Nuansa",
            "saturation": "Kejenuhan",
            "value": "Nilai",
            "imageTool": "Gambar",
            "importing": "Mengimpor…",
            "addImage": "+ Tambahkan gambar",
            "uploadImage": "Unggah gambar",
            "fromGallery": "Stickers",
            "lineTool": "Garis",
            "penTool": "Pensil",
            "polygonTool": "Poligon",
            "sides": "Sisi",
            "rectangleTool": "Persegi panjang",
            "cornerRadius": "Jari-jari tepi",
            "resizeWidthTitle": "Lebar dalam piksel",
            "resizeHeightTitle": "Tinggi dalam piksel",
            "toggleRatioLockTitle": "Beralih kunci dimensi",
            "reset": "Mulai ulang",
            "resetSize": "Mengembalikan dimensi gambar asli",
            "rotateTool": "Berputar",
            "textTool": "Teks",
            "textSpacings": "Spasi teks",
            "textAlignment": "Perataan teks",
            "fontFamily": "Keluarga font",
            "size": "Ukuran",
            "letterSpacing": "Spasi huruf",
            "lineHeight": "Tinggi garis",
            "warmthTool": "Kehangatan",
            "addWatermark": "+ Tambahkan gambar",
            "addWatermarkTitle": "Pilih jenis tanda air",
            "uploadWatermark": "Pilih gambar",
            "addWatermarkAsText": "Menambahkan teks",
            "padding": "Mengisi",
            "shadow": "Bayangan",
            "horizontal": "Horisontal",
            "vertical": "Vertikal",
            "blur": "Blur",
            "opacity": "Opasitas",
            "position": "Posisi",
            "stroke": "Ketebalan",
            "saveAsModalLabel": "Detail",
            "extension": "Penyuluhan",
            "nameIsRequired": "Nama diperlukan.",
            "quality": "Kualitas",
            "imageDimensionsHoverTitle": "Dimensi gambar yang disimpan (lebar x tinggi)",
            "cropSizeLowerThanResizedWarning": "Perhatikan, bahwa area kroping yang dipilih lebih kecil daripada ukuran ulang yang diterapkan, yang dapat menyebabkan hilangnya kualitas.",
            "actualSize": "Ukuran saat ini (100%)",
            "fitSize": "Beradaptasi dimensi",
            "addImageTitle": "Pilih gambar yang ingin Anda tambahkan…"
        },
        "changing": "Mengubah",
        "profile_picture_changed": "Gambar profil diubah",
        "loading_profile_picture": "Memuat gambar profil",
        "change": "Mengubah",
        "session": "Sidang",
        "system_operative": "Sistem operasi",
        "navigator": "Browser",
        "authorize": "Mengesahkan",
        "deauthorize": "Batalkan otorisasi",
        "keep_tag_content": "Apakah Anda ingin mempertahankan konten tag?",
        "move_content_to": "Memindahkan konten ke",
        "move_to": "Pindah ke",
        "chat_request": "Permintaan obrolan",
        "accept_request": "Terima aplikasi",
        "go_to_chat": "Pergi ke obrolan",
        "applying": "Menerapkan",
        "time": "Waktu",
        "adjust": "Sesuaikan",
        "rotate": "Berputar",
        "start": "Mulai",
        "end": "Akhir",
        "lang_name": "Indonesian"
    },
    "ga": {
        "name": "Ainm",
        "name_example": "John",
        "last_name": "Sloinne",
        "last_name_example": "Murphy",
        "username": "Úsáideoir",
        "username_example": "john_murphy",
        "email": "Ríomhphost",
        "email_example": "usaideoir@fearainn.com",
        "password": "Pasfhocal",
        "new_password": "Pasfhocal nua",
        "password_example": "*****************",
        "login": "Logáil isteach",
        "register": "Clár",
        "verification_code": "Cód fíorúcháin",
        "verification_code_example": "xxx",
        "verify": "Fíoraigh",
        "loading": "Muirearú…",
        "please_wait": "Fán le do thoil",
        "go_back": "Fill ar ais",
        "recovery": "Ghnóthú",
        "login_subtitle": "Cuir isteach do shonraí agus rochtain a fháil ar do chuntas.",
        "forgot_password_link": "an ndearna tú dearmad ar do phasfhocal? Aisghabháil.",
        "register_account_link": "Níl cuntas agat? Taifead!",
        "register_subtitle": "Cuir isteach do shonraí agus cláraigh do chuntas.",
        "login_link": "An bhfuil cuntas agat cheana féin? Rochtain!",
        "verification_code_instruction": "Seiceáil do r-phost agus cuir isteach an cód a seoladh.",
        "forgot_password_subtitle": "Cuir isteach do ríomhphost chun do phasfhocal a ghnóthú.",
        "change_password_subtitle": "Cuir isteach pasfhocal nua.",
        "change_password_code_used": "Tá an cód chun do phasfhocal a aisghabháil neamhbhailí nó in úsáid cheana féin.",
        "no_chats": "Níl comhráite agat.",
        "chats": "Comhráite",
        "groups": "Grúpaí",
        "rooms": "Seomraí",
        "save": "Sábháil",
        "download": "Scaoileadh",
        "downloading_image": "Íomhá a íoslódáil",
        "an_error_has_ocurred": "Tharla earráid",
        "saving_changes": "Athruithe a shábháil",
        "go_back_to_edit": "Cuir in eagar arís",
        "upload_warning_modal": "Nuair a luchtaíonn tú an t-eagar, cuirfear an bhuníomhá in áit.",
        "upload_question_modal": "Ar mhaith leat an bunchóip a choinneáil agus cóip a chruthú?",
        "create_copy_and_keep": "Déan cóip agus sábháil é.",
        "upload": "Uaslódáil",
        "upload_changes": "Uaslódáil athruithe",
        "changes_saved": "Athruithe a shábháil",
        "downloading_video": "Físeán a íoslódáil",
        "video_upload_warning_modal": "Nuair a uaslódálann tú an t-eagar, tiocfaidh sé in ionad an bhunfhíseáin.",
        "results": "Torthaí",
        "members": "Baill",
        "cancel": "Cealaigh",
        "add_user": "Cuir úsáideoir leis",
        "no_messages": "Gan teachtaireachtaí",
        "message_placeholder": "Teachtaireacht…",
        "group_doesnt_exists": "Níl an grúpa ann",
        "you_arent_group_member": "Níl tú i do bhall den ghrúpa",
        "touch_to_see_more_info": "Tapáil chun tuilleadh eolais a fheiceáil.",
        "start_videocall": "Tosaigh glao físe",
        "start_call": "Tosaigh glao",
        "create_group": "Cruthaigh grúpa",
        "group_name": "Ainm an ghrúpa",
        "add_members_to_create_group": "Cuir baill leis chun an grúpa a chruthú",
        "search": "Cuardaigh le haghaidh",
        "creating": "Ag cruthú…",
        "dont_belong_anyone_group": "Ní bhaineann tú le haon ghrúpa.",
        "already_add_this_hashtag": "Chuir tú an hashtag sin leis cheana féin",
        "search_files_by_hashtags": "Cuardaigh comhaid de réir hashtags",
        "enter_to_add": "Cuir isteach chun cur leis",
        "hashtags_most_used": "hashtags is mó a úsáidtear",
        "can_add_it_giving_click": "Is féidir leat iad a chur le do chuardach trí chliceáil orthu.",
        "searching_files": "Cuardach comhaid",
        "no_files_found": "Níor aimsíodh aon chomhaid",
        "image": "Íomha",
        "video": "Físeán",
        "audio": "Fuaime",
        "library": "Siopa leabhar",
        "home": "Leathanach baile",
        "block": "Bloc",
        "unblock": "Dhíghlasáil",
        "has_blocked_you": "Chuir sé bac ort",
        "accept": "Glac",
        "decline": "Meath",
        "cancel_request": "Cealaigh iarratas",
        "has_been_expulsed": "Tá a dhíbirt",
        "room_doesnt_exists": "Níl an seomra ann",
        "you_arent_room_member": "Níl tú i do bhall den seomra",
        "create_room": "Cruthaigh seomra",
        "room_name": "Ainm an tseomra",
        "no_rooms_created": "Níl aon seomraí cruthaithe.",
        "dont_belong_to_anyone_room": "Ní bhaineann tú in aon seomra.",
        "enter_a_username": "Scríobh úsáideoir",
        "no_results": "Gan torthaí",
        "create": "Cruthaigh",
        "no_content_uploaded": "Níor uaslódáladh aon ábhar",
        "new_subtag": "Fochlib nua",
        "update_info": "Nuashonraigh faisnéis",
        "updating": "Ag nuashonrú",
        "update": "Nuashonrú",
        "update_email": "Nuashonraigh ríomhphost",
        "change_password": "Athraigh do phasfhocal",
        "current_password": "Pasfhocal reatha",
        "select": "Roghnaigh",
        "tap_to_upload_a_file": "Tapáil chun comhad a uaslódáil",
        "edit_photo": "Cuir grianghraf in eagar",
        "preview": "Réamhamharc",
        "edit_video": "Cuir físeán in eagar",
        "delete_changes": "Scrios athruithe",
        "uploading": "Ag dul suas",
        "session_deleted_or_expired": "Seisiún scriosta nó imithe in éag",
        "edit_profile": "Cuir próifíl in eagar",
        "devices": "Gléasanna",
        "downloads": "Íosluchtuithe",
        "logout": "Sínigh as",
        "offline": "Gan nasc",
        "images": "Íomhánna",
        "videos": "Físeáin",
        "audios": "Fuaimeanna",
        "chat": "Comhrá",
        "session_unauthorized": "Níl do sheisiún údaraithe.",
        "authorize_session": "Chun rochtain a fháil ar an seisiún seo, ní mór duit do ghléas a údarú.",
        "authorize_instructions": "Seiceáil do r-phost le haghaidh an nasc údaraithe nó téigh go dtí seisiún gníomhach agus roghnaigh na trí poncanna sa chúinne uachtarach ar dheis, ansin téigh go dtí \"Gléasanna\" agus roghnaigh \"Údaraigh.\"",
        "refresh_page_after_authorize": "Nuair a bheidh an próiseas údaraithe críochnaithe agat, athnuaigh an leathanach chun rochtain a fháil ar an seisiún.",
        "check_your_inbox": "Seiceáil ar do r-phost",
        "send_authorization_email": "Seol ríomhphost údaraithe",
        "call_finished": "Cuireadh deireadh leis an nglao",
        "close": "Dún",
        "waiting_stream": "Ag feitheamh le tarchur",
        "request_camera_and_mic": "Teastaíonn rochtain uainn ar do cheamara agus micreafón ionas gur féidir leat glaonna a dhéanamh.",
        "call_in_progress": "Glao ar siúl",
        "group": "Braisle",
        "videocall": "Glao físe",
        "call": "Glaoigh",
        "connecting": "Ag nascadh",
        "call_cancelled": "Glao curtha ar ceal",
        "calling": "Ag glaoch",
        "hangup": "Croch",
        "reject": "Meath",
        "has_sent_a_file": "tá comhad seolta aige.",
        "keep": "Coinnigh",
        "keep_file": "Má chumasaíonn tú caomhnú comhad, déanfar é a shábháil san fheidhmchlár. Ceadóidh sé seo a inrochtaineacht gan gá le nasc Idirlín.",
        "not_keep_file": "Má chasann tú as coinneáil comhad, scriosfar é agus ní bheidh tú in ann rochtain a fháil air gan nasc Idirlín.",
        "file_stored_instruction": "Taispeánfar gach comhad stóráilte le teorainn ghlas sa liosta comhad coitianta. Is féidir leat iad a fheiceáil sa rannóg freisin",
        "stored": "Stóráilte",
        "not_stored": "Ní stóráiltear é",
        "file_not_stored_instruction": "Ní bheidh tú in ann an comhad seo a rochtain gan nasc Idirlín.",
        "device": "Gléas",
        "file_visibility": "Infheictheacht Comhad",
        "visible_for_everyone": "Infheicthe do chách",
        "shared_link": "Nasc roinnte",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Cuidíonn Hashtags leat do chuid comhad a aimsiú.",
        "edit": "Cuir in eagar",
        "move": "Aistrigh",
        "rename": "Athainmnigh",
        "visibility": "Infheictheacht",
        "delete": "Deireadh a chur",
        "file_warn_delete": "An bhfuil tú cinnte gur mhaith leat an comhad a scriosadh?",
        "no_subtags_availables": "Níl aon fhochlibeanna ar fáil",
        "owner": "Cruthaitheoir",
        "expulsing": "Ag díbirt",
        "expulse": "Díbirt",
        "you_have_left_the_group": "D'fhág tú an grúpa",
        "coming_out": "Ag teacht amach",
        "leave_group": "Fág an grúpa",
        "you_have_left_the_room": "Tá an seomra fágtha agat.",
        "leave_room": "Fág an seomra",
        "message_deleted": "Teachtaireacht scriosta",
        "has_been_error_deleting_message": "Tharla earráid agus an teachtaireacht á scriosadh.",
        "deleting": "Ag baint",
        "new_tag": "Clib nua",
        "photoEditor": {
            "name": "Ainm",
            "save": "Críochnaigh",
            "saveAs": "Sábháil mar",
            "back": "Ar ais",
            "loading": "Muirearú…",
            "resetOperations": "Atosaigh/Cealaigh gach oibríocht.",
            "changesLoseConfirmation": "Bainfear gach athrú.",
            "changesLoseConfirmationHint": "An bhfuil tú cinnte gur mhaith leat leanúint ar aghaidh?",
            "cancel": "Cealaigh",
            "continue": "Leanúint",
            "undoTitle": "Cealaigh an oibríocht dheireanach",
            "redoTitle": "Déan an oibríocht dheireanach arís",
            "showImageTitle": "Taispeáin íomhá bunaidh",
            "zoomInTitle": "Zúmáil isteach",
            "zoomOutTitle": "Súmáil amach",
            "toggleZoomMenuTitle": "Scoránaigh roghchlár súmáil",
            "adjustTab": "Coigeartaigh",
            "finetuneTab": "Dathanna",
            "filtersTab": "Scagairí",
            "watermarkTab": "Íomhánna",
            "annotateTab": "Tarraing",
            "resize": "Athraigh méid",
            "resizeTab": "Méid",
            "invalidImageError": "Íomhá neamhbhailí",
            "uploadImageError": "Tharla earráid agus an íomhá á lódáil.",
            "areNotImages": "ní íomhánna iad",
            "isNotImage": "ní íomhá é",
            "toBeUploaded": "le huaslódáil",
            "cropTool": "Barraí",
            "original": "Bunaidh",
            "custom": "Pearsantaithe",
            "square": "Cearnóg",
            "landscape": "Cothrománach",
            "portrait": "Ingearach",
            "ellipse": "Ciorcal",
            "classicTv": "teilifís clasaiceach",
            "cinemascope": "Cinemascope",
            "arrowTool": "Saighead",
            "blurTool": "Doiléirigh",
            "brightnessTool": "Glow",
            "contrastTool": "Codarsnacht",
            "ellipseTool": "Ciorcal",
            "unFlipX": "Bain amach X",
            "flipX": "Smeach X",
            "unFlipY": "Bain amach Y",
            "flipY": "Smeach Y",
            "hsvTool": "MSV",
            "hue": "Lí",
            "saturation": "Sáithiú",
            "value": "Luach",
            "imageTool": "Íomha",
            "importing": "Iompórtáil…",
            "addImage": "+ Cuir íomhá leis",
            "uploadImage": "Uaslódáil íomhá",
            "fromGallery": "Greamáin",
            "lineTool": "Líne",
            "penTool": "Peann luaidhe",
            "polygonTool": "Polagán",
            "sides": "Taobhanna",
            "rectangleTool": "Dronuilleog",
            "cornerRadius": "Ga Imeall",
            "resizeWidthTitle": "Leithead i picteilíní",
            "resizeHeightTitle": "Airde picteilín",
            "toggleRatioLockTitle": "Scoránaigh Toise Lock",
            "reset": "Tosaigh arís",
            "resetSize": "Athchóirigh toisí na híomhá bunaidh.",
            "rotateTool": "Rothlaigh",
            "textTool": "Téacs",
            "textSpacings": "Spásáil téacs",
            "textAlignment": "Ailíniú Téacs",
            "fontFamily": "Teaghlaigh cló",
            "size": "Méid",
            "letterSpacing": "Spásáil litreacha",
            "lineHeight": "Airde líne",
            "warmthTool": "Teas",
            "addWatermark": "+ Cuir íomhá leis",
            "addWatermarkTitle": "Roghnaigh an cineál comhartha uisce",
            "uploadWatermark": "Roghnaigh íomhá",
            "addWatermarkAsText": "Cuir téacs leis",
            "padding": "Stuáil",
            "shadow": "Scáth",
            "horizontal": "Cothrománach",
            "vertical": "Ingearach",
            "blur": "Doiléirigh",
            "opacity": "Teimhneacht",
            "position": "Staid",
            "stroke": "Tiús",
            "saveAsModalLabel": "Sonraí",
            "extension": "Síneadh",
            "nameIsRequired": "Tá an t-ainm ag teastáil.",
            "quality": "Cáilíocht",
            "imageDimensionsHoverTitle": "Toisí na híomhá sábháilte (leithead x airde)",
            "cropSizeLowerThanResizedWarning": "Tabhair faoi deara le do thoil go bhfuil an limistéar barr roghnaithe níos lú ná an t-athrú méid a cuireadh i bhfeidhm, rud a d'fhéadfadh a bheith ina chúis le laghdú ar cháilíocht.",
            "actualSize": "Méid reatha (100%)",
            "fitSize": "Toise a oiriúnú",
            "addImageTitle": "Roghnaigh íomhá le cur leis…"
        },
        "changing": "Athrú",
        "profile_picture_changed": "Athraíodh an grianghraf próifíle.",
        "loading_profile_picture": "Grianghraf próifíle á lódáil",
        "change": "Athrú",
        "session": "Seisiún",
        "system_operative": "OS",
        "navigator": "Brabhsálaí",
        "authorize": "Údar",
        "deauthorize": "Deauthorize",
        "keep_tag_content": "Ar mhaith leat ábhar an lipéid a chaomhnú?",
        "move_content_to": "Bog ábhar go",
        "move_to": "Bog go dtí",
        "chat_request": "Iarratas comhrá",
        "accept_request": "Glac leis an iarratas",
        "go_to_chat": "Téigh chuig comhrá",
        "applying": "A chur i bhfeidhm",
        "time": "Am",
        "adjust": "Coigeartaigh",
        "rotate": "Rothlaigh",
        "start": "Tosaigh",
        "end": "Deireadh",
        "lang_name": "Irish"
    },
    "it": {
        "name": "Nome",
        "name_example": "Maria",
        "last_name": "Cognome",
        "last_name_example": "Rossi",
        "username": "Utente",
        "username_example": "maria_rossi",
        "email": "Indirizzo e-mail",
        "email_example": "utente@dominio.com",
        "password": "Password",
        "new_password": "Nuova password",
        "password_example": "*****************",
        "login": "Accesso",
        "register": "Registro",
        "verification_code": "Codice di verifica",
        "verification_code_example": "xxx",
        "verify": "Verifica",
        "loading": "Caricamento…",
        "please_wait": "Attendere prego",
        "go_back": "Torna a",
        "recovery": "Recuperare",
        "login_subtitle": "Inserite i vostri dati e accedete al vostro account.",
        "forgot_password_link": "Hai dimenticato la password? Recupera.",
        "register_account_link": "Non hai un account? Iscriviti!",
        "register_subtitle": "Inserite i vostri dati e registrate il vostro account.",
        "login_link": "Hai già un account? Accedi!",
        "verification_code_instruction": "Controllare l'e-mail e inserire il codice inviato.",
        "forgot_password_subtitle": "Immettere l'indirizzo e-mail per recuperare la password.",
        "change_password_subtitle": "Inserire una nuova password.",
        "change_password_code_used": "Il codice di recupero della password non è valido o è già stato utilizzato.",
        "no_chats": "Non ci sono conversazioni.",
        "chats": "Conversazioni",
        "groups": "Gruppi",
        "rooms": "Camere",
        "save": "Risparmiare",
        "download": "Scaricare",
        "downloading_image": "Scaricare l'immagine",
        "an_error_has_ocurred": "Si è verificato un errore",
        "saving_changes": "Salvataggio delle modifiche",
        "go_back_to_edit": "Torna a modificare",
        "upload_warning_modal": "Il caricamento della modifica sostituirà l'immagine originale.",
        "upload_question_modal": "Volete conservare l'originale e creare una copia?",
        "create_copy_and_keep": "Creare una copia e conservarla",
        "upload": "Caricare",
        "upload_changes": "Caricare le modifiche",
        "changes_saved": "Modifiche salvate",
        "downloading_video": "Scaricare il video",
        "video_upload_warning_modal": "Il video originale verrà sostituito quando la modifica verrà caricata.",
        "results": "Risultati",
        "members": "Membri",
        "cancel": "Annullare",
        "add_user": "Aggiungi utente",
        "no_messages": "Nessun messaggio",
        "message_placeholder": "Messaggio…",
        "group_doesnt_exists": "Il gruppo non esiste",
        "you_arent_group_member": "Non si è membri del gruppo",
        "touch_to_see_more_info": "Toccare per visualizzare ulteriori informazioni.",
        "start_videocall": "Avviare la videochiamata",
        "start_call": "Avviare la chiamata",
        "create_group": "Crea gruppo",
        "group_name": "Nome del gruppo",
        "add_members_to_create_group": "Aggiungere membri per creare il gruppo",
        "search": "Ricerca",
        "creating": "Creare…",
        "dont_belong_anyone_group": "Non appartenete a nessun gruppo.",
        "already_add_this_hashtag": "Hai già aggiunto l'hashtag",
        "search_files_by_hashtags": "Ricerca di file per hashtag",
        "enter_to_add": "Immettere per aggiungere",
        "hashtags_most_used": "Gli hashtag più utilizzati",
        "can_add_it_giving_click": "È possibile aggiungerli alla ricerca facendo clic su di essi.",
        "searching_files": "Ricerca di file",
        "no_files_found": "Nessun file trovato",
        "image": "Immagine",
        "video": "Video",
        "audio": "Audio",
        "library": "Libreria",
        "home": "Pagina iniziale",
        "block": "Blocco",
        "unblock": "Sbloccare",
        "has_blocked_you": "Ti ha bloccato",
        "accept": "Accettare",
        "decline": "Rifiuta",
        "cancel_request": "Annulla richiesta",
        "has_been_expulsed": "è stato espulso",
        "room_doesnt_exists": "La stanza non esiste",
        "you_arent_room_member": "Non sei un membro della stanza",
        "create_room": "Creare una stanza",
        "room_name": "Nome della stanza",
        "no_rooms_created": "Non sono state create stanze.",
        "dont_belong_to_anyone_room": "Non appartenete a nessuna stanza.",
        "enter_a_username": "Scrivere un utente",
        "no_results": "Nessun risultato",
        "create": "Creare",
        "no_content_uploaded": "Nessun contenuto caricato",
        "new_subtag": "Nuovo subtag",
        "update_info": "Aggiornamento delle informazioni",
        "updating": "Aggiornamento",
        "update": "Aggiornamento",
        "update_email": "Aggiornamento dell'e-mail",
        "change_password": "Modifica della password",
        "current_password": "Password attuale",
        "select": "Selezionare",
        "tap_to_upload_a_file": "Toccare per caricare un file",
        "edit_photo": "Modifica foto",
        "preview": "Anteprima",
        "edit_video": "Modifica del video",
        "delete_changes": "Cancellare le modifiche",
        "uploading": "Caricamento",
        "session_deleted_or_expired": "Sessione cancellata o scaduta",
        "edit_profile": "Modifica profilo",
        "devices": "Dispositivi",
        "downloads": "Scaricamenti",
        "logout": "Disconnettersi",
        "offline": "Non in linea",
        "images": "Immagini",
        "videos": "Video",
        "audios": "Audio",
        "chat": "Chat",
        "session_unauthorized": "La vostra sessione non è autorizzata.",
        "authorize_session": "Per accedere a questa sessione, il dispositivo deve essere autorizzato.",
        "authorize_instructions": "Cercate il link di autorizzazione nella vostra e-mail o collegatevi a una sessione attiva e selezionate i tre puntini in alto a destra, quindi andate su \"Dispositivi\" e selezionate \"Autorizza\".",
        "refresh_page_after_authorize": "Una volta completata la procedura di autorizzazione, aggiornare la pagina di login.",
        "check_your_inbox": "Controllare la posta elettronica",
        "send_authorization_email": "Inviare l'e-mail di autorizzazione",
        "call_finished": "Chiamata terminata",
        "close": "Chiudere",
        "waiting_stream": "In attesa di trasmissione",
        "request_camera_and_mic": "Abbiamo bisogno di accedere alla telecamera e al microfono per poter effettuare chiamate.",
        "call_in_progress": "Chiamata in corso",
        "group": "Gruppo",
        "videocall": "Videochiamata",
        "call": "Chiamata",
        "connecting": "Collegamento",
        "call_cancelled": "Chiamata annullata",
        "calling": "Chiamata",
        "hangup": "Riagganciare",
        "reject": "Rifiuta",
        "has_sent_a_file": "ha inviato un file.",
        "keep": "Conserva",
        "keep_file": "Se l'archiviazione dei file è abilitata, i file verranno salvati nell'applicazione. Ciò consente di accedervi senza bisogno di una connessione a Internet.",
        "not_keep_file": "Se la memorizzazione dei file è disattivata, i file vengono eliminati e non è possibile accedervi senza una connessione a Internet.",
        "file_stored_instruction": "Tutti i file memorizzati appariranno con un bordo verde nell'elenco dei file comuni. È inoltre possibile visualizzarli nella finestra",
        "stored": "Immagazzinato",
        "not_stored": "Non immagazzinato",
        "file_not_stored_instruction": "Non sarà possibile accedere a questo file senza una connessione a Internet.",
        "device": "Dispositivo",
        "file_visibility": "Visibilità dell'archivio",
        "visible_for_everyone": "Visibile a tutti",
        "shared_link": "Link condiviso",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Gli hashtag aiutano nella ricerca degli archivi.",
        "edit": "Modifica",
        "move": "Muoversi",
        "rename": "Rinominare",
        "visibility": "Visibilità",
        "delete": "Cancellare",
        "file_warn_delete": "Siete sicuri di voler eliminare il file?",
        "no_subtags_availables": "Non ci sono sotto-tag disponibili",
        "owner": "Creatore",
        "expulsing": "Espulsione",
        "expulse": "Espellere",
        "you_have_left_the_group": "Hai lasciato il gruppo",
        "coming_out": "Partenza",
        "leave_group": "Lasciare il gruppo",
        "you_have_left_the_room": "Avete lasciato la stanza.",
        "leave_room": "Lasciare la stanza",
        "message_deleted": "Messaggio cancellato",
        "has_been_error_deleting_message": "Si è verificato un errore durante l'eliminazione del messaggio",
        "deleting": "Eliminazione",
        "new_tag": "Nuova etichetta",
        "photoEditor": {
            "name": "Nome",
            "save": "Fine",
            "saveAs": "Salva con nome",
            "back": "Indietro",
            "loading": "Caricamento…",
            "resetOperations": "Azzeramento/Cancellazione di tutte le operazioni.",
            "changesLoseConfirmation": "Tutte le modifiche saranno cancellate.",
            "changesLoseConfirmationHint": "Sei sicuro di voler continuare?",
            "cancel": "Annullare",
            "continue": "Continuare",
            "undoTitle": "Annullamento dell'ultima operazione",
            "redoTitle": "Ripetere l'ultima operazione",
            "showImageTitle": "Mostra l'immagine originale",
            "zoomInTitle": "Zoom in",
            "zoomOutTitle": "Zoom out",
            "toggleZoomMenuTitle": "Alterna il menu dello zoom",
            "adjustTab": "Regolazione",
            "finetuneTab": "Colori",
            "filtersTab": "Filtri",
            "watermarkTab": "Immagini",
            "annotateTab": "Disegno",
            "resize": "Ridimensionare",
            "resizeTab": "Dimensione",
            "invalidImageError": "Immagine non valida",
            "uploadImageError": "Si è verificato un errore durante il caricamento dell'immagine.",
            "areNotImages": "non sono immagini",
            "isNotImage": "non è un'immagine",
            "toBeUploaded": "da caricare",
            "cropTool": "Tagliare",
            "original": "Originale",
            "custom": "Personalizzato",
            "square": "Quadrato",
            "landscape": "Orizzontale",
            "portrait": "Verticale",
            "ellipse": "Cerchio",
            "classicTv": "TV classica",
            "cinemascope": "Cinemascope",
            "arrowTool": "Freccia",
            "blurTool": "Sfocatura",
            "brightnessTool": "Luminosità",
            "contrastTool": "Contrasto",
            "ellipseTool": "Cerchio",
            "unFlipX": "Unflip X",
            "flipX": "Flip X",
            "unFlipY": "Unflip Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "Nuance",
            "saturation": "Saturazione",
            "value": "Valore",
            "imageTool": "Immagine",
            "importing": "Importare…",
            "addImage": "+ Aggiungi immagine",
            "uploadImage": "Carica immagine",
            "fromGallery": "Adesivi",
            "lineTool": "Linea",
            "penTool": "Matita",
            "polygonTool": "Poligono",
            "sides": "Lati",
            "rectangleTool": "Rettangolo",
            "cornerRadius": "Raggio del bordo",
            "resizeWidthTitle": "Larghezza in pixel",
            "resizeHeightTitle": "Altezza in pixel",
            "toggleRatioLockTitle": "Blocco delle dimensioni",
            "reset": "Riavvio",
            "resetSize": "Ripristino delle dimensioni originali dell'immagine",
            "rotateTool": "Ruotare",
            "textTool": "Testo",
            "textSpacings": "Spaziatura del testo",
            "textAlignment": "Allineamento del testo",
            "fontFamily": "Famiglia di caratteri",
            "size": "Dimensione",
            "letterSpacing": "Spaziatura delle lettere",
            "lineHeight": "Altezza della linea",
            "warmthTool": "Il calore",
            "addWatermark": "+ Aggiungi immagine",
            "addWatermarkTitle": "Scegliere il tipo di filigrana",
            "uploadWatermark": "Selezionare l'immagine",
            "addWatermarkAsText": "Aggiungi testo",
            "padding": "Riempimento",
            "shadow": "Ombra",
            "horizontal": "Orizzontale",
            "vertical": "Verticale",
            "blur": "Sfocatura",
            "opacity": "Opacità",
            "position": "Posizione",
            "stroke": "Spessore",
            "saveAsModalLabel": "Dettagli",
            "extension": "Estensione",
            "nameIsRequired": "Il nome è obbligatorio.",
            "quality": "Qualità",
            "imageDimensionsHoverTitle": "Dimensioni dell'immagine salvata (L x H)",
            "cropSizeLowerThanResizedWarning": "Si noti che l'area di ritaglio selezionata è più piccola della scala applicata, il che può causare una riduzione della qualità.",
            "actualSize": "Dimensione attuale (100%)",
            "fitSize": "Adattare la dimensione",
            "addImageTitle": "Selezionare un'immagine da aggiungere…"
        },
        "changing": "Cambiamento",
        "profile_picture_changed": "Immagine del profilo modificata",
        "loading_profile_picture": "Caricamento dell'immagine del profilo",
        "change": "Cambiamento",
        "session": "Sessione",
        "system_operative": "Sistema operativo",
        "navigator": "Browser",
        "authorize": "Autorizzare",
        "deauthorize": "Disautorizzare",
        "keep_tag_content": "Si vuole mantenere il contenuto del tag?",
        "move_content_to": "Spostare il contenuto in",
        "move_to": "Spostarsi a",
        "chat_request": "Richiesta di chat",
        "accept_request": "Accettare richiesta",
        "go_to_chat": "Vai alla chat",
        "applying": "Applicazione",
        "time": "Tempo",
        "adjust": "Regolazione",
        "rotate": "Ruotare",
        "start": "Inizio",
        "end": "Fine",
        "lang_name": "Italian"
    },
    "ja": {
        "name": "名称",
        "name_example": "Mariko",
        "last_name": "苗字",
        "last_name_example": "Sato",
        "username": "ユーザー",
        "username_example": "mariko_sato",
        "email": "Eメールアドレス",
        "email_example": "ユーザー@ドメイン.com",
        "password": "パスワード",
        "new_password": "新しいパスワード",
        "password_example": "*****************",
        "login": "ログイン",
        "register": "登録",
        "verification_code": "検証コード",
        "verification_code_example": "xxx",
        "verify": "確認",
        "loading": "ローディング…",
        "please_wait": "お待ちください",
        "go_back": "戻る",
        "recovery": "リカバリー",
        "login_subtitle": "お客様の情報を入力し、アカウントにログインしてください。",
        "forgot_password_link": "パスワードをお忘れですか？検索する",
        "register_account_link": "アカウントをお持ちでない方は、ご登録ください！",
        "register_subtitle": "詳細情報を入力し、アカウントを登録してください。",
        "login_link": "既にアカウントをお持ちの方はログインしてください！",
        "verification_code_instruction": "Eメールを確認し、送信されたコードを入力する。",
        "forgot_password_subtitle": "メールアドレスを入力してパスワードを取得してください。",
        "change_password_subtitle": "新しいパスワードを入力してください。",
        "change_password_code_used": "パスワードを回復するためのコードが無効であるか、すでに使用されています。",
        "no_chats": "会話がない。",
        "chats": "カンバセーションズ",
        "groups": "グループ",
        "rooms": "部屋",
        "save": "セーブ",
        "download": "ダウンロード",
        "downloading_image": "画像のダウンロード",
        "an_error_has_ocurred": "エラーが発生しました",
        "saving_changes": "変更の保存",
        "go_back_to_edit": "編集に戻る",
        "upload_warning_modal": "編集した画像をアップロードすると、元の画像が置き換えられます。",
        "upload_question_modal": "オリジナルを残してコピーを作りますか？",
        "create_copy_and_keep": "コピーを作成し、保管する",
        "upload": "アップロード",
        "upload_changes": "変更のアップロード",
        "changes_saved": "保存された変更",
        "downloading_video": "ビデオのダウンロード",
        "video_upload_warning_modal": "編集をアップロードすると、元のビデオが置き換えられます。",
        "results": "結果",
        "members": "メンバー",
        "cancel": "キャンセル",
        "add_user": "ユーザー追加",
        "no_messages": "メッセージなし",
        "message_placeholder": "メッセージ…",
        "group_doesnt_exists": "グループが存在しない",
        "you_arent_group_member": "あなたはグループのメンバーではありません",
        "touch_to_see_more_info": "タップすると詳細が表示されます。",
        "start_videocall": "ビデオ通話を開始する",
        "start_call": "通話開始",
        "create_group": "グループを作成する",
        "group_name": "グループ名",
        "add_members_to_create_group": "メンバーを追加してグループを作成",
        "search": "検索",
        "creating": "クリエイティング…",
        "dont_belong_anyone_group": "あなたはどのグループにも属していない。",
        "already_add_this_hashtag": "そのハッシュタグはすでに追加されています。",
        "search_files_by_hashtags": "ハッシュタグでファイルを検索",
        "enter_to_add": "を入力して追加する",
        "hashtags_most_used": "最も使われているハッシュタグ",
        "can_add_it_giving_click": "これらをクリックして検索に追加することができます。",
        "searching_files": "ファイルの検索",
        "no_files_found": "ファイルが見つかりません",
        "image": "画像",
        "video": "ビデオ",
        "audio": "オーディオ",
        "library": "書店",
        "home": "ホーム",
        "block": "ブロック",
        "unblock": "ブロック解除",
        "has_blocked_you": "彼はあなたをブロックした",
        "accept": "受け入れる",
        "decline": "不採用",
        "cancel_request": "リクエストのキャンセル",
        "has_been_expulsed": "は除名された",
        "room_doesnt_exists": "その部屋は存在しない",
        "you_arent_room_member": "あなたは部屋のメンバーではありません",
        "create_room": "部屋作り",
        "room_name": "部屋名",
        "no_rooms_created": "部屋が作られていない。",
        "dont_belong_to_anyone_room": "あなたはどの部屋にも属していない。",
        "enter_a_username": "ユーザーを書く",
        "no_results": "結果なし",
        "create": "作成する",
        "no_content_uploaded": "アップロードされたコンテンツがない",
        "new_subtag": "新しいサブタグ",
        "update_info": "情報更新",
        "updating": "アップデート",
        "update": "更新",
        "update_email": "Eメール更新",
        "change_password": "パスワードの変更",
        "current_password": "現在のパスワード",
        "select": "選択",
        "tap_to_upload_a_file": "タップしてファイルをアップロードする",
        "edit_photo": "写真を編集",
        "preview": "プレビュー",
        "edit_video": "ビデオを編集する",
        "delete_changes": "変更を削除する",
        "uploading": "アップロード",
        "session_deleted_or_expired": "セッションの削除または期限切れ",
        "edit_profile": "プロフィール編集",
        "devices": "デバイス",
        "downloads": "各種ダウンロード",
        "logout": "ログアウト",
        "offline": "オフライン",
        "images": "画像",
        "videos": "ビデオ",
        "audios": "オーディオス",
        "chat": "チャット",
        "session_unauthorized": "あなたのセッションは許可されていません。",
        "authorize_session": "このセッションにアクセスするには、デバイスが認証されていなければならない。",
        "authorize_instructions": "認証リンクをメールで検索するか、アクティブなセッションで、右上の3つの点を選択し、「デバイス」を選択し、「認証」を選択します。",
        "refresh_page_after_authorize": "認証プロセスが完了したら、ページを更新してログインしてください。",
        "check_your_inbox": "メールをチェックする",
        "send_authorization_email": "認証メールを送信する",
        "call_finished": "通話は終了しました",
        "close": "クローズ",
        "waiting_stream": "送信待ち",
        "request_camera_and_mic": "通話をするためには、カメラとマイクにアクセスできなければならない。",
        "call_in_progress": "進行中の通話",
        "group": "グループ",
        "videocall": "ビデオ通話",
        "call": "お電話",
        "connecting": "コネクティング",
        "call_cancelled": "通話はキャンセル",
        "calling": "通話",
        "hangup": "電話を切る",
        "reject": "拒否する",
        "has_sent_a_file": "がファイルを送信した。",
        "keep": "プリザーブ",
        "keep_file": "ファイル保持を有効にすると、ファイルはアプリケーションに保存されます。これにより、インターネットに接続しなくてもファイルにアクセスできるようになります。",
        "not_keep_file": "ファイルの保持が無効になっている場合、ファイルは削除され、インターネット接続なしではアクセスできなくなります。",
        "file_stored_instruction": "保存されたすべてのファイルは、共通ファイルリストに緑色の枠で表示されます。また",
        "stored": "にて保管",
        "not_stored": "保存されていない",
        "file_not_stored_instruction": "インターネットに接続されていないと、このファイルにはアクセスできません。",
        "device": "デバイス",
        "file_visibility": "アーカイブの可視性",
        "visible_for_everyone": "誰にでも見える",
        "shared_link": "共有リンク",
        "hashtags": "ハッシュタグ",
        "hashtags_helps_to_search_your_file": "ハッシュタグはファイルの検索に役立ちます。",
        "edit": "編集",
        "move": "ムーブ",
        "rename": "名前を変更",
        "visibility": "視認性",
        "delete": "削除する",
        "file_warn_delete": "本当にファイルを削除しますか？",
        "no_subtags_availables": "利用可能なサブタグはありません",
        "owner": "クリエイター",
        "expulsing": "追放",
        "expulse": "エクスパルス",
        "you_have_left_the_group": "あなたはグループを去った",
        "coming_out": "カミングアウト",
        "leave_group": "グループを離れる",
        "you_have_left_the_room": "あなたは部屋を出た。",
        "leave_room": "部屋を出る",
        "message_deleted": "メッセージは削除された",
        "has_been_error_deleting_message": "メッセージの削除エラー。",
        "deleting": "削除",
        "new_tag": "新タグ",
        "photoEditor": {
            "name": "名称",
            "save": "終了",
            "saveAs": "名前を付けて保存",
            "back": "バック",
            "loading": "ローディング…",
            "resetOperations": "すべての操作をリセット/削除します。",
            "changesLoseConfirmation": "すべての変更が削除されます。",
            "changesLoseConfirmationHint": "本当に続けるのか？",
            "cancel": "キャンセル",
            "continue": "続ける",
            "undoTitle": "最後の操作を元に戻す",
            "redoTitle": "前回の操作をやり直す",
            "showImageTitle": "オリジナル画像を表示する",
            "zoomInTitle": "ズームイン",
            "zoomOutTitle": "ズームアウト",
            "toggleZoomMenuTitle": "トグル・ズーム・メニュー",
            "adjustTab": "アジャスト",
            "finetuneTab": "カラーズ",
            "filtersTab": "フィルター類",
            "watermarkTab": "画像",
            "annotateTab": "ドローイング",
            "resize": "リサイズ",
            "resizeTab": "サイズ",
            "invalidImageError": "無効な画像",
            "uploadImageError": "画像の読み込み中にエラーが発生しました。",
            "areNotImages": "それらはイメージではない",
            "isNotImage": "はイメージではない",
            "toBeUploaded": "アップロードする",
            "cropTool": "カット",
            "original": "オリジナル",
            "custom": "パーソナライズド",
            "square": "正方形",
            "landscape": "ホリゾンタル",
            "portrait": "縦型",
            "ellipse": "サークル",
            "classicTv": "クラシックTV",
            "cinemascope": "シネマスコープ",
            "arrowTool": "アロー",
            "blurTool": "ブラー",
            "brightnessTool": "明るさ",
            "contrastTool": "コントラスト",
            "ellipseTool": "サークル",
            "unFlipX": "アンフリップX",
            "flipX": "フリップX",
            "unFlipY": "アンフリップY",
            "flipY": "フリップY",
            "hsvTool": "MSV",
            "hue": "ニュアンス",
            "saturation": "飽和",
            "value": "価値",
            "imageTool": "画像",
            "importing": "インポート…",
            "addImage": "+ 画像を追加",
            "uploadImage": "画像をアップロードする",
            "fromGallery": "ステッカー",
            "lineTool": "ライン",
            "penTool": "鉛筆",
            "polygonTool": "ポリゴン",
            "sides": "サイドズ",
            "rectangleTool": "長方形",
            "cornerRadius": "エッジの半径",
            "resizeWidthTitle": "ピクセル単位の幅",
            "resizeHeightTitle": "ピクセル単位の高さ",
            "toggleRatioLockTitle": "トグル寸法ロック",
            "reset": "リスタート",
            "resetSize": "画像の元の寸法に戻す。",
            "rotateTool": "回転させる",
            "textTool": "テキスト",
            "textSpacings": "テキストの間隔",
            "textAlignment": "テキストの配置",
            "fontFamily": "字体",
            "size": "サイズ",
            "letterSpacing": "文字間隔",
            "lineHeight": "ラインの高さ",
            "warmthTool": "暖かさ",
            "addWatermark": "+ 画像を追加",
            "addWatermarkTitle": "透かしの種類を選ぶ",
            "uploadWatermark": "画像を選択",
            "addWatermarkAsText": "テキストの追加",
            "padding": "詰め物",
            "shadow": "シャドウ",
            "horizontal": "ホリゾンタル",
            "vertical": "縦型",
            "blur": "ブラー",
            "opacity": "不透明度",
            "position": "ポジション",
            "stroke": "厚さ",
            "saveAsModalLabel": "詳細",
            "extension": "延長",
            "nameIsRequired": "名前は必須である。",
            "quality": "クオリティ",
            "imageDimensionsHoverTitle": "保存画像の寸法（幅×高さ）",
            "cropSizeLowerThanResizedWarning": "選択されたトリミング領域は、適用されたリサイズよりも小さいため、画質が低下する可能性があることに注意してください。",
            "actualSize": "現在のサイズ (100%)",
            "fitSize": "次元を適応させる",
            "addImageTitle": "追加する画像を選択する…"
        },
        "changing": "チェンジング",
        "profile_picture_changed": "プロフィール写真が変更されました",
        "loading_profile_picture": "プロフィール写真の読み込み",
        "change": "変更",
        "session": "セッション",
        "system_operative": "オペレーティングシステム",
        "navigator": "ブラウザ",
        "authorize": "オーソライズ",
        "deauthorize": "認可を取り消す",
        "keep_tag_content": "タグの内容を保持しますか？",
        "move_content_to": "コンテンツを以下に移動する",
        "move_to": "に移動する",
        "chat_request": "チャットリクエスト",
        "accept_request": "申請を受理する",
        "go_to_chat": "チャットへ",
        "applying": "適用する",
        "time": "天気",
        "adjust": "アジャスト",
        "rotate": "回転させる",
        "start": "スタート",
        "end": "終了",
        "lang_name": "Japanese"
    },
    "kn": {
        "name": "ಹೆಸರು",
        "name_example": "Kalu",
        "last_name": "ಕೊನೆಯ ಹೆಸರು",
        "last_name_example": "Das",
        "username": "ಬಳಕೆದಾರ",
        "username_example": "kalu_das",
        "email": "ಇಮೇಲ್",
        "email_example": "ಬಳಕೆದಾರ@ಡೊಮೇನ್.com",
        "password": "ಗುಪ್ತಪದ",
        "new_password": "ಹೊಸ ಪಾಸ್ವರ್ಡ್",
        "password_example": "*****************",
        "login": "ಲಾಗ್ ಇನ್ ಮಾಡಿ",
        "register": "ನೋಂದಣಿ",
        "verification_code": "ಪರಿಶೀಲನೆ ಕೋಡ್",
        "verification_code_example": "xxx",
        "verify": "ಪರಿಶೀಲಿಸಿ",
        "loading": "ಚಾರ್ಜ್ ಆಗುತ್ತಿದೆ…",
        "please_wait": "ದಯಮಾಡಿ ನಿರೀಕ್ಷಿಸಿ",
        "go_back": "ಹಿಂತಿರುಗಿ",
        "recovery": "ಚೇತರಿಕೆ",
        "login_subtitle": "ನಿಮ್ಮ ವಿವರಗಳನ್ನು ನಮೂದಿಸಿ ಮತ್ತು ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಪ್ರವೇಶಿಸಿ.",
        "forgot_password_link": "ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ನೀವು ಮರೆತಿದ್ದೀರಾ? ಗುಣಮುಖರಾಗಲು.",
        "register_account_link": "ನೀವು ಖಾತೆಯನ್ನು ಹೊಂದಿಲ್ಲವೇ? ನೋಂದಣಿ!",
        "register_subtitle": "ನಿಮ್ಮ ವಿವರಗಳನ್ನು ನಮೂದಿಸಿ ಮತ್ತು ನಿಮ್ಮ ಖಾತೆಯನ್ನು ನೋಂದಾಯಿಸಿ.",
        "login_link": "ನೀವು ಈಗಾಗಲೇ ಖಾತೆಯನ್ನು ಹೊಂದಿದ್ದೀರಾ? ಲಾಗ್ ಇನ್ ಮಾಡಿ!",
        "verification_code_instruction": "ನಿಮ್ಮ ಇಮೇಲ್ ಪರಿಶೀಲಿಸಿ ಮತ್ತು ಕಳುಹಿಸಿದ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ.",
        "forgot_password_subtitle": "ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ಮರುಪಡೆಯಲು ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ.",
        "change_password_subtitle": "ಹೊಸ ಗುಪ್ತಪದವನ್ನು ನಮೂದಿಸಿ.",
        "change_password_code_used": "ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ಮರುಪಡೆಯುವಿಕೆ ಕೋಡ್ ಅಮಾನ್ಯವಾಗಿದೆ ಅಥವಾ ಈಗಾಗಲೇ ಬಳಸಲಾಗಿದೆ.",
        "no_chats": "ನೀವು ಸಂಭಾಷಣೆಗಳನ್ನು ಹೊಂದಿಲ್ಲ.",
        "chats": "ಸಂಭಾಷಣೆಗಳು",
        "groups": "ಗುಂಪುಗಳು",
        "rooms": "ಕೊಠಡಿಗಳು",
        "save": "ಇರಿಸಿಕೊಳ್ಳಿ",
        "download": "ವಿಸರ್ಜನೆ",
        "downloading_image": "ಚಿತ್ರವನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಲಾಗುತ್ತಿದೆ",
        "an_error_has_ocurred": "ದೋಷ ಕಂಡುಬಂದಿದೆ",
        "saving_changes": "ಬದಲಾವಣೆಗಳನ್ನು ಉಳಿಸಲಾಗುತ್ತಿದೆ",
        "go_back_to_edit": "ಮತ್ತೆ ಸಂಪಾದಿಸಿ",
        "upload_warning_modal": "ಸಂಪಾದನೆಯನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡುವುದರಿಂದ ಮೂಲ ಚಿತ್ರವನ್ನು ಬದಲಾಯಿಸಲಾಗುತ್ತದೆ.",
        "upload_question_modal": "ನೀವು ಮೂಲವನ್ನು ಇರಿಸಿಕೊಳ್ಳಲು ಮತ್ತು ನಕಲನ್ನು ರಚಿಸಲು ಬಯಸುವಿರಾ?",
        "create_copy_and_keep": "ನಕಲನ್ನು ರಚಿಸಿ ಮತ್ತು ಇರಿಸಿ",
        "upload": "ಅಪ್ಲೋಡ್ ಮಾಡಿ",
        "upload_changes": "ಬದಲಾವಣೆಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
        "changes_saved": "ಬದಲಾವಣೆಗಳನ್ನು ಉಳಿಸಲಾಗಿದೆ",
        "downloading_video": "ವೀಡಿಯೊ ಡೌನ್‌ಲೋಡ್ ಮಾಡಲಾಗುತ್ತಿದೆ",
        "video_upload_warning_modal": "ಸಂಪಾದನೆಯನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡುವುದರಿಂದ ಮೂಲ ವೀಡಿಯೊವನ್ನು ಬದಲಾಯಿಸಲಾಗುತ್ತದೆ.",
        "results": "ಫಲಿತಾಂಶಗಳು",
        "members": "ಸದಸ್ಯರು",
        "cancel": "ರದ್ದುಮಾಡಿ",
        "add_user": "ಬಳಕೆದಾರರನ್ನು ಸೇರಿಸಿ",
        "no_messages": "ಯಾವುದೇ ಸಂದೇಶಗಳಿಲ್ಲ",
        "message_placeholder": "ಸಂದೇಶ…",
        "group_doesnt_exists": "ಗುಂಪು ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲ",
        "you_arent_group_member": "ನೀವು ಗುಂಪಿನ ಸದಸ್ಯರಲ್ಲ",
        "touch_to_see_more_info": "ಹೆಚ್ಚಿನ ಮಾಹಿತಿಯನ್ನು ನೋಡಲು ಟ್ಯಾಪ್ ಮಾಡಿ.",
        "start_videocall": "ವೀಡಿಯೊ ಕರೆಯನ್ನು ಪ್ರಾರಂಭಿಸಿ",
        "start_call": "ಕರೆಯನ್ನು ಪ್ರಾರಂಭಿಸಿ",
        "create_group": "ಗುಂಪನ್ನು ರಚಿಸಿ",
        "group_name": "ತಂಡದ ಹೆಸರು",
        "add_members_to_create_group": "ಗುಂಪನ್ನು ರಚಿಸಲು ಸದಸ್ಯರನ್ನು ಸೇರಿಸಿ",
        "search": "ಹುಡುಕು",
        "creating": "ರಚಿಸಲಾಗುತ್ತಿದೆ…",
        "dont_belong_anyone_group": "ನೀವು ಯಾವುದೇ ಗುಂಪಿಗೆ ಸೇರಿದವರಲ್ಲ.",
        "already_add_this_hashtag": "ನೀವು ಈಗಾಗಲೇ ಆ ಹ್ಯಾಶ್‌ಟ್ಯಾಗ್ ಸೇರಿಸಿರುವಿರಿ",
        "search_files_by_hashtags": "ಹ್ಯಾಶ್‌ಟ್ಯಾಗ್‌ಗಳ ಮೂಲಕ ಫೈಲ್‌ಗಳನ್ನು ಹುಡುಕಿ",
        "enter_to_add": "ಸೇರಿಸಲು ನಮೂದಿಸಿ",
        "hashtags_most_used": "ಹೆಚ್ಚು ಬಳಸಿದ ಹ್ಯಾಶ್‌ಟ್ಯಾಗ್‌ಗಳು",
        "can_add_it_giving_click": "ಇವುಗಳನ್ನು ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ ನಿಮ್ಮ ಹುಡುಕಾಟಕ್ಕೆ ಸೇರಿಸಬಹುದು",
        "searching_files": "ಫೈಲ್‌ಗಳನ್ನು ಹುಡುಕಲಾಗುತ್ತಿದೆ",
        "no_files_found": "ಯಾವುದೇ ಫೈಲ್‌ಗಳು ಕಂಡುಬಂದಿಲ್ಲ",
        "image": "ಚಿತ್ರ",
        "video": "ವೀಡಿಯೊ",
        "audio": "ಆಡಿಯೋ",
        "library": "ಪುಸ್ತಕದಂಗಡಿ",
        "home": "ಮುಖಪುಟ",
        "block": "ನಿರ್ಬಂಧಿಸಿ",
        "unblock": "ಅನ್ಲಾಕ್ ಮಾಡಲು",
        "has_blocked_you": "ಅವರು ನಿಮ್ಮನ್ನು ನಿರ್ಬಂಧಿಸಿದ್ದಾರೆ",
        "accept": "ಒಪ್ಪಿಕೊಳ್ಳಿ",
        "decline": "ನಿರಾಕರಿಸು",
        "cancel_request": "ಕೋರಿಕೆ ರದ್ದುಮಾಡು",
        "has_been_expulsed": "ಹೊರಹಾಕಲಾಗಿದೆ",
        "room_doesnt_exists": "ಕೊಠಡಿ ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲ",
        "you_arent_room_member": "ನೀವು ಕೋಣೆಯ ಸದಸ್ಯರಲ್ಲ",
        "create_room": "ಕೊಠಡಿ ರಚಿಸಿ",
        "room_name": "ಕೋಣೆಯ ಹೆಸರು",
        "no_rooms_created": "ಯಾವುದೇ ಕೊಠಡಿಗಳನ್ನು ರಚಿಸಲಾಗಿಲ್ಲ.",
        "dont_belong_to_anyone_room": "ನೀವು ಯಾವುದೇ ಕೋಣೆಗೆ ಸೇರಿದವರಲ್ಲ.",
        "enter_a_username": "ಬಳಕೆದಾರರನ್ನು ಬರೆಯಿರಿ",
        "no_results": "ಯಾವುದೇ ಫಲಿತಾಂಶಗಳಿಲ್ಲ",
        "create": "ರಚಿಸಿ",
        "no_content_uploaded": "ಯಾವುದೇ ವಿಷಯವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿಲ್ಲ",
        "new_subtag": "ಹೊಸ ಉಪಟ್ಯಾಗ್",
        "update_info": "ಮಾಹಿತಿಯನ್ನು ನವೀಕರಿಸಿ",
        "updating": "ನವೀಕರಿಸಲಾಗುತ್ತಿದೆ",
        "update": "ನವೀಕರಿಸಿ",
        "update_email": "ಇಮೇಲ್ ನವೀಕರಿಸಿ",
        "change_password": "ಗುಪ್ತಪದವನ್ನು ಬದಲಿಸಿ",
        "current_password": "ಪ್ರಸ್ತುತ ಗುಪ್ತಪದ",
        "select": "ಆಯ್ಕೆ ಮಾಡಿ",
        "tap_to_upload_a_file": "ಫೈಲ್ ಅನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಟ್ಯಾಪ್ ಮಾಡಿ",
        "edit_photo": "ಫೋಟೋ ಸಂಪಾದಿಸಿ",
        "preview": "ಮುನ್ನೋಟ",
        "edit_video": "ವೀಡಿಯೊ ಸಂಪಾದಿಸಿ",
        "delete_changes": "ಬದಲಾವಣೆಗಳನ್ನು ಅಳಿಸಿ",
        "uploading": "ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗುತ್ತಿದೆ",
        "session_deleted_or_expired": "ಸೆಷನ್ ಅಳಿಸಲಾಗಿದೆ ಅಥವಾ ಅವಧಿ ಮೀರಿದೆ",
        "edit_profile": "ಪ್ರೊಫೈಲ್ ಬದಲಿಸು",
        "devices": "ಸಾಧನಗಳು",
        "downloads": "ಡೌನ್ಲೋಡ್ಗಳು",
        "logout": "ಹೊರಹೋಗಿ",
        "offline": "ಸಂಪರ್ಕವಿಲ್ಲದೆ",
        "images": "ಚಿತ್ರಗಳು",
        "videos": "ವೀಡಿಯೊಗಳು",
        "audios": "ಆಡಿಯೋಗಳು",
        "chat": "ಚಾಟ್ ಮಾಡಿ",
        "session_unauthorized": "ನಿಮ್ಮ ಅಧಿವೇಶನವನ್ನು ಅಧಿಕೃತಗೊಳಿಸಲಾಗಿಲ್ಲ.",
        "authorize_session": "ಈ ಸೆಶನ್ ಅನ್ನು ಪ್ರವೇಶಿಸಲು, ನಿಮ್ಮ ಸಾಧನವನ್ನು ನೀವು ದೃಢೀಕರಿಸುವ ಅಗತ್ಯವಿದೆ.",
        "authorize_instructions": "ದೃಢೀಕರಣ ಲಿಂಕ್‌ಗಾಗಿ ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ಪರಿಶೀಲಿಸಿ ಅಥವಾ ಸಕ್ರಿಯ ಸೆಶನ್‌ಗೆ ಹೋಗಿ ಮತ್ತು ಮೇಲಿನ ಬಲ ಮೂಲೆಯಲ್ಲಿರುವ ಮೂರು ಚುಕ್ಕೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ, ನಂತರ \"ಸಾಧನಗಳು\" ಗೆ ಹೋಗಿ ಮತ್ತು \"ದೃಢೀಕರಿಸು\" ಆಯ್ಕೆಮಾಡಿ.",
        "refresh_page_after_authorize": "ಒಮ್ಮೆ ನೀವು ದೃಢೀಕರಣ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ ನಂತರ, ಲಾಗ್ ಇನ್ ಮಾಡಲು ಪುಟವನ್ನು ರಿಫ್ರೆಶ್ ಮಾಡಿ.",
        "check_your_inbox": "ನಿಮ್ಮ ಇಮೇಲ್ ಪರಿಶೀಲಿಸಿ",
        "send_authorization_email": "ದೃಢೀಕರಣ ಇಮೇಲ್ ಕಳುಹಿಸಿ",
        "call_finished": "ಕರೆ ಕೊನೆಗೊಂಡಿದೆ",
        "close": "ಮುಕ್ತಾಯ ಮಾಡು",
        "waiting_stream": "ಪ್ರಸಾರಕ್ಕಾಗಿ ಕಾಯಲಾಗುತ್ತಿದೆ",
        "request_camera_and_mic": "ನಿಮ್ಮ ಕ್ಯಾಮರಾ ಮತ್ತು ಮೈಕ್ರೊಫೋನ್‌ಗೆ ನಮಗೆ ಪ್ರವೇಶದ ಅಗತ್ಯವಿದೆ ಆದ್ದರಿಂದ ನೀವು ಕರೆಗಳನ್ನು ಮಾಡಬಹುದು.",
        "call_in_progress": "ಕರೆ ಪ್ರಗತಿಯಲ್ಲಿದೆ",
        "group": "ಕ್ಲಸ್ಟರ್",
        "videocall": "ವೀಡಿಯೊ ಕರೆ",
        "call": "ಕರೆ ಮಾಡಿ",
        "connecting": "ಸಂಪರ್ಕಿಸಲಾಗುತ್ತಿದೆ",
        "call_cancelled": "ಕರೆ ರದ್ದುಗೊಳಿಸಲಾಗಿದೆ",
        "calling": "ಕರೆ ಮಾಡಲಾಗುತ್ತಿದೆ",
        "hangup": "ಸ್ಥಗಿತಗೊಳಿಸಿ",
        "reject": "ನಿರಾಕರಿಸು",
        "has_sent_a_file": "ಕಡತವನ್ನು ಕಳುಹಿಸಿದ್ದಾರೆ.",
        "keep": "ಇರಿಸಿಕೊಳ್ಳಿ",
        "keep_file": "ನೀವು ಫೈಲ್ ಧಾರಣವನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿದರೆ, ಅದನ್ನು ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ಉಳಿಸಲಾಗುತ್ತದೆ. ಇದು ಇಂಟರ್ನೆಟ್ ಸಂಪರ್ಕದ ಅಗತ್ಯವಿಲ್ಲದೆ ಪ್ರವೇಶವನ್ನು ಅನುಮತಿಸುತ್ತದೆ.",
        "not_keep_file": "ನೀವು ಫೈಲ್ ಧಾರಣವನ್ನು ಆಫ್ ಮಾಡಿದರೆ, ಅದನ್ನು ಅಳಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ಇಂಟರ್ನೆಟ್ ಸಂಪರ್ಕವಿಲ್ಲದೆ ನೀವು ಅದನ್ನು ಪ್ರವೇಶಿಸಲು ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ.",
        "file_stored_instruction": "ಎಲ್ಲಾ ಸಂಗ್ರಹಿಸಿದ ಫೈಲ್‌ಗಳು ಸಾಮಾನ್ಯ ಫೈಲ್ ಪಟ್ಟಿಯಲ್ಲಿ ಹಸಿರು ಅಂಚುಗಳೊಂದಿಗೆ ಗೋಚರಿಸುತ್ತವೆ. ನೀವು ಅವುಗಳನ್ನು ವರ್ಗದಲ್ಲಿಯೂ ನೋಡಬಹುದು",
        "stored": "ಸಂಗ್ರಹಿಸಲಾಗಿದೆ",
        "not_stored": "ಅದನ್ನು ಸಂಗ್ರಹಿಸಲಾಗಿಲ್ಲ",
        "file_not_stored_instruction": "ಇಂಟರ್ನೆಟ್ ಸಂಪರ್ಕವಿಲ್ಲದೆ ಈ ಫೈಲ್ ಅನ್ನು ಪ್ರವೇಶಿಸಲು ನಿಮಗೆ ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ.",
        "device": "ಸಾಧನ",
        "file_visibility": "ಫೈಲ್ ಗೋಚರತೆ",
        "visible_for_everyone": "ಎಲ್ಲರಿಗೂ ಗೋಚರಿಸುತ್ತದೆ",
        "shared_link": "ಹಂಚಿಕೊಂಡ ಲಿಂಕ್",
        "hashtags": "ಹ್ಯಾಶ್‌ಟ್ಯಾಗ್‌ಗಳು",
        "hashtags_helps_to_search_your_file": "ನಿಮ್ಮ ಫೈಲ್‌ಗಳನ್ನು ಹುಡುಕಲು ಹ್ಯಾಶ್‌ಟ್ಯಾಗ್‌ಗಳು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತವೆ.",
        "edit": "ತಿದ್ದು",
        "move": "ಸರಿಸಿ",
        "rename": "ಮರುಹೆಸರಿಸು",
        "visibility": "ಗೋಚರತೆ",
        "delete": "ನಿವಾರಿಸು",
        "file_warn_delete": "ನೀವು ಫೈಲ್ ಅನ್ನು ಅಳಿಸಲು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
        "no_subtags_availables": "ಯಾವುದೇ ಉಪಟ್ಯಾಗ್‌ಗಳು ಲಭ್ಯವಿಲ್ಲ",
        "owner": "ಸೃಷ್ಟಿಕರ್ತ",
        "expulsing": "ಹೊರಹಾಕುವುದು",
        "expulse": "ಹೊರಹಾಕು",
        "you_have_left_the_group": "ನೀವು ಗುಂಪನ್ನು ತೊರೆದಿದ್ದೀರಿ",
        "coming_out": "ಹೊರಬರುತ್ತಿದೆ",
        "leave_group": "ಗುಂಪನ್ನು ತೊರೆಯಿರಿ",
        "you_have_left_the_room": "ನೀವು ಕೊಠಡಿಯನ್ನು ತೊರೆದಿದ್ದೀರಿ.",
        "leave_room": "ಕೊಠಡಿಯನ್ನು ಬಿಡಿ",
        "message_deleted": "ಅಳಿಸಲಾದ ಸಂದೇಶ",
        "has_been_error_deleting_message": "ಸಂದೇಶವನ್ನು ಅಳಿಸುವಾಗ ದೋಷ ಸಂಭವಿಸಿದೆ.",
        "deleting": "ತೆಗೆದುಹಾಕಲಾಗುತ್ತಿದೆ",
        "new_tag": "ಹೊಸ ಟ್ಯಾಗ್",
        "photoEditor": {
            "name": "ಹೆಸರು",
            "save": "ಮುಗಿಸು",
            "saveAs": "ವಿಭಿನ್ನವಾಗಿ ಉಳಿಸಿ",
            "back": "ಹಿಂದೆ",
            "loading": "ಚಾರ್ಜ್ ಆಗುತ್ತಿದೆ…",
            "resetOperations": "ಎಲ್ಲಾ ಕಾರ್ಯಾಚರಣೆಗಳನ್ನು ಮರುಪ್ರಾರಂಭಿಸಿ/ಅಳಿಸಿ.",
            "changesLoseConfirmation": "ಎಲ್ಲಾ ಬದಲಾವಣೆಗಳನ್ನು ತೆಗೆದುಹಾಕಲಾಗುತ್ತದೆ.",
            "changesLoseConfirmationHint": "ನೀವು ಮುಂದುವರಿಸಲು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
            "cancel": "ರದ್ದುಮಾಡಿ",
            "continue": "ಮುಂದುವರಿಸಿ",
            "undoTitle": "ಕೊನೆಯ ಕಾರ್ಯಾಚರಣೆಯನ್ನು ರದ್ದುಗೊಳಿಸಿ",
            "redoTitle": "ಕೊನೆಯ ಕಾರ್ಯಾಚರಣೆಯನ್ನು ಮತ್ತೆ ಮಾಡಿ",
            "showImageTitle": "ಮೂಲ ಚಿತ್ರವನ್ನು ತೋರಿಸಿ",
            "zoomInTitle": "ಇನ್ನು ಹತ್ತಿರವಾಗಿಸಿ",
            "zoomOutTitle": "ಜೂಮ್ ಔಟ್",
            "toggleZoomMenuTitle": "ಝೂಮ್ ಮೆನುವನ್ನು ಟಾಗಲ್ ಮಾಡಿ",
            "adjustTab": "ಸರಿಹೊಂದಿಸಿ",
            "finetuneTab": "ಬಣ್ಣಗಳು",
            "filtersTab": "ಶೋಧಕಗಳು",
            "watermarkTab": "ಚಿತ್ರಗಳು",
            "annotateTab": "ಎಳೆಯಿರಿ",
            "resize": "ಮರುಗಾತ್ರಗೊಳಿಸಿ",
            "resizeTab": "ಗಾತ್ರ",
            "invalidImageError": "ಅಮಾನ್ಯ ಚಿತ್ರ",
            "uploadImageError": "ಚಿತ್ರವನ್ನು ಲೋಡ್ ಮಾಡುವಾಗ ದೋಷ ಸಂಭವಿಸಿದೆ.",
            "areNotImages": "ಅವು ಚಿತ್ರಗಳಲ್ಲ",
            "isNotImage": "ಇದು ಚಿತ್ರವಲ್ಲ",
            "toBeUploaded": "ಅಪ್ಲೋಡ್ ಮಾಡಲು",
            "cropTool": "ಕತ್ತರಿಸಿ",
            "original": "ಮೂಲ",
            "custom": "ವೈಯಕ್ತೀಕರಿಸಲಾಗಿದೆ",
            "square": "ಚೌಕ",
            "landscape": "ಸಮತಲ",
            "portrait": "ಲಂಬವಾದ",
            "ellipse": "ವೃತ್ತ",
            "classicTv": "ಕ್ಲಾಸಿಕ್ ಟಿವಿ",
            "cinemascope": "ಸಿನಿಮಾಸ್ಕೋಪ್",
            "arrowTool": "ಬಾಣ",
            "blurTool": "ಮಸುಕು",
            "brightnessTool": "ಗ್ಲೋ",
            "contrastTool": "ಕಾಂಟ್ರಾಸ್ಟ್",
            "ellipseTool": "ವೃತ್ತ",
            "unFlipX": "ಅನ್‌ಫ್ಲಿಪ್ ಎಕ್ಸ್",
            "flipX": "ಫ್ಲಿಪ್ ಎಕ್ಸ್",
            "unFlipY": "ಅನ್‌ಫ್ಲಿಪ್ ವೈ",
            "flipY": "ಫ್ಲಿಪ್ ವೈ",
            "hsvTool": "MSV",
            "hue": "ವರ್ಣ",
            "saturation": "ಶುದ್ಧತ್ವ",
            "value": "ಮೌಲ್ಯ",
            "imageTool": "ಚಿತ್ರ",
            "importing": "ಆಮದು ಮಾಡಿಕೊಳ್ಳುತ್ತಿದೆ…",
            "addImage": "+ ಚಿತ್ರವನ್ನು ಸೇರಿಸಿ",
            "uploadImage": "ಚಿತ್ರವನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ",
            "fromGallery": "ಸ್ಟಿಕ್ಕರ್‌ಗಳು",
            "lineTool": "ಸಾಲು",
            "penTool": "ಪೆನ್ಸಿಲ್",
            "polygonTool": "ಬಹುಭುಜಾಕೃತಿ",
            "sides": "ಬದಿಗಳು",
            "rectangleTool": "ಆಯಾತ",
            "cornerRadius": "ಎಡ್ಜ್ ತ್ರಿಜ್ಯ",
            "resizeWidthTitle": "ಪಿಕ್ಸೆಲ್‌ಗಳಲ್ಲಿ ಅಗಲ",
            "resizeHeightTitle": "ಪಿಕ್ಸೆಲ್ ಎತ್ತರ",
            "toggleRatioLockTitle": "ಆಯಾಮ ಲಾಕ್ ಅನ್ನು ಟಾಗಲ್ ಮಾಡಿ",
            "reset": "ಮರುಹೊಂದಿಸಿ",
            "resetSize": "ಮೂಲ ಚಿತ್ರದ ಆಯಾಮಗಳಿಗೆ ಮರುಹೊಂದಿಸಿ",
            "rotateTool": "ತಿರುಗಿಸಿ",
            "textTool": "ಪಠ್ಯ",
            "textSpacings": "ಪಠ್ಯ ಅಂತರ",
            "textAlignment": "ಪಠ್ಯ ಜೋಡಣೆ",
            "fontFamily": "ಫಾಂಟ್ ಕುಟುಂಬ",
            "size": "ಗಾತ್ರ",
            "letterSpacing": "ಅಕ್ಷರದ ಅಂತರ",
            "lineHeight": "ಗೆರೆಯ ಎತ್ತರ",
            "warmthTool": "ಶಾಖ",
            "addWatermark": "+ ಚಿತ್ರವನ್ನು ಸೇರಿಸಿ",
            "addWatermarkTitle": "ವಾಟರ್‌ಮಾರ್ಕ್ ಪ್ರಕಾರವನ್ನು ಆರಿಸಿ",
            "uploadWatermark": "ಚಿತ್ರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
            "addWatermarkAsText": "ಪಠ್ಯವನ್ನು ಸೇರಿಸಿ",
            "padding": "ಸ್ಟಫ್ಡ್",
            "shadow": "ನೆರಳು",
            "horizontal": "ಸಮತಲ",
            "vertical": "ಲಂಬವಾದ",
            "blur": "ಮಸುಕು",
            "opacity": "ಅಪಾರದರ್ಶಕತೆ",
            "position": "ಸ್ಥಾನ",
            "stroke": "ದಪ್ಪ",
            "saveAsModalLabel": "ವಿವರಗಳು",
            "extension": "ವಿಸ್ತರಣೆ",
            "nameIsRequired": "ಹೆಸರು ಅಗತ್ಯವಿದೆ.",
            "quality": "ಗುಣಮಟ್ಟ",
            "imageDimensionsHoverTitle": "ಉಳಿಸಿದ ಚಿತ್ರದ ಆಯಾಮಗಳು (ಅಗಲ x ಎತ್ತರ)",
            "cropSizeLowerThanResizedWarning": "ಆಯ್ಕೆಮಾಡಿದ ಬೆಳೆ ಪ್ರದೇಶವು ಅನ್ವಯಿಸಲಾದ ಮರುಗಾತ್ರಕ್ಕಿಂತ ಚಿಕ್ಕದಾಗಿದೆ ಎಂಬುದನ್ನು ದಯವಿಟ್ಟು ಗಮನಿಸಿ, ಇದು ಗುಣಮಟ್ಟದಲ್ಲಿ ಇಳಿಕೆಗೆ ಕಾರಣವಾಗಬಹುದು.",
            "actualSize": "ಪ್ರಸ್ತುತ ಗಾತ್ರ (100%)",
            "fitSize": "ಆಯಾಮವನ್ನು ಹೊಂದಿಸಿ",
            "addImageTitle": "ಸೇರಿಸಲು ಚಿತ್ರವನ್ನು ಆಯ್ಕೆಮಾಡಿ…"
        },
        "changing": "ಬದಲಾಗುತ್ತಿದೆ",
        "profile_picture_changed": "ಪ್ರೊಫೈಲ್ ಫೋಟೋ ಬದಲಾಗಿದೆ",
        "loading_profile_picture": "ಪ್ರೊಫೈಲ್ ಫೋಟೋ ಲೋಡ್ ಆಗುತ್ತಿದೆ",
        "change": "ಬದಲಾವಣೆ",
        "session": "ಅಧಿವೇಶನ",
        "system_operative": "OS",
        "navigator": "ಬ್ರೌಸರ್",
        "authorize": "ಅಧಿಕಾರ ನೀಡಿ",
        "deauthorize": "ನಿರಾಕರಿಸು",
        "keep_tag_content": "ನೀವು ಟ್ಯಾಗ್‌ನ ವಿಷಯವನ್ನು ಸಂರಕ್ಷಿಸಲು ಬಯಸುವಿರಾ?",
        "move_content_to": "ವಿಷಯವನ್ನು ಸರಿಸಿ",
        "move_to": "ಗೆ ಸರಿಸಿ",
        "chat_request": "ಚಾಟ್ ವಿನಂತಿ",
        "accept_request": "ವಿನಂತಿಯನ್ನು ಸ್ವೀಕರಿಸಿ",
        "go_to_chat": "ಚಾಟ್‌ಗೆ ಹೋಗಿ",
        "applying": "ಅರ್ಜಿ ಸಲ್ಲಿಸಲಾಗುತ್ತಿದೆ",
        "time": "ಸಮಯ",
        "adjust": "ಹೊಂದಿಸಿ",
        "rotate": "ತಿರುಗಿಸಿ",
        "start": "ಪ್ರಾರ೦ಭ",
        "end": "ಅಂತ್ಯ",
        "lang_name": "Kannada"
    },
    "kk": {
        "name": "Аты",
        "name_example": "Elena",
        "last_name": "Тек",
        "last_name_example": "Kim",
        "username": "Пайдаланушы",
        "username_example": "elena_kim",
        "email": "Электрондық пошта",
        "email_example": "Пайдаланушы@Домен.com",
        "password": "Құпия сөз",
        "new_password": "Жаңа Құпия Сөз",
        "password_example": "*****************",
        "login": "Кіру",
        "register": "Тіркелу",
        "verification_code": "Тексеру коды",
        "verification_code_example": "xxx",
        "verify": "Тексеру",
        "loading": "Зарядталуда…",
        "please_wait": "Өтінемін күте тұрыңыз",
        "go_back": "Қайту",
        "recovery": "Қалпына келтіру",
        "login_subtitle": "Мәліметтеріңізді енгізіп, тіркелгіңізге кіріңіз.",
        "forgot_password_link": "Құпия сөзіңізді ұмытып қалдыңыз ба? Қалпына келтіру.",
        "register_account_link": "Сізде тіркелгі жоқ па? Жазба!",
        "register_subtitle": "Мәліметтеріңізді енгізіп, тіркелгіңізді тіркеңіз.",
        "login_link": "Сізде бұрыннан тіркелгі бар ма? Кіру!",
        "verification_code_instruction": "Электрондық поштаңызды тексеріңіз және жіберілген кодты енгізіңіз.",
        "forgot_password_subtitle": "Құпия сөзді қалпына келтіру үшін электрондық поштаңызды енгізіңіз.",
        "change_password_subtitle": "Жаңа құпия сөзді енгізіңіз.",
        "change_password_code_used": "Құпия сөзді қалпына келтіру коды жарамсыз немесе бұрыннан қолданылған.",
        "no_chats": "Сізде сөйлесулер жоқ.",
        "chats": "Әңгімелер",
        "groups": "Топтар",
        "rooms": "Бөлмелер",
        "save": "Сақтау",
        "download": "Жүктеп алу",
        "downloading_image": "Кескін жүктеп алынуда",
        "an_error_has_ocurred": "Қате орын алды",
        "saving_changes": "Өзгерістерді сақтау",
        "go_back_to_edit": "Өңдеу үшін оралыңыз",
        "upload_warning_modal": "Өңдеуді жүктеп салу бастапқы кескінді ауыстырады.",
        "upload_question_modal": "Түпнұсқаны сақтап, көшірмесін жасағыңыз келе ме?",
        "create_copy_and_keep": "Көшірме жасап, сақтаңыз",
        "upload": "Жүктеп салу",
        "upload_changes": "Өзгерістерді жүктеп салу",
        "changes_saved": "Өзгерістер сақталды",
        "downloading_video": "Бейне жүктеп алынуда",
        "video_upload_warning_modal": "Өңдеуді жүктеп салу бастапқы бейнені ауыстырады.",
        "results": "Нәтижелер",
        "members": "Мүшелер",
        "cancel": "Болдырмау",
        "add_user": "Пайдаланушы қосу",
        "no_messages": "Хабарламалар жоқ",
        "message_placeholder": "Хабар…",
        "group_doesnt_exists": "Топ жоқ",
        "you_arent_group_member": "Сіз топтың мүшесі емессіз",
        "touch_to_see_more_info": "Қосымша ақпаратты көру үшін түртіңіз.",
        "start_videocall": "Бейне қоңырауды бастаңыз",
        "start_call": "Қоңырауды бастау",
        "create_group": "Топ құру",
        "group_name": "Топ аты",
        "add_members_to_create_group": "Топты құру үшін мүшелерді қосыңыз",
        "search": "Іздеу",
        "creating": "Жасау…",
        "dont_belong_anyone_group": "Сіз ешбір топқа жатпайсыз.",
        "already_add_this_hashtag": "Сіз бұл хэштегті қосып қойғансыз",
        "search_files_by_hashtags": "Файлдарды хэштегтер бойынша іздеу",
        "enter_to_add": "Қосу үшін енгізіңіз",
        "hashtags_most_used": "Ең көп қолданылатын хэштегтер",
        "can_add_it_giving_click": "Оларды басу арқылы іздеуге қосуға болады",
        "searching_files": "Файлдарды іздеу",
        "no_files_found": "Ешқандай файл табылмады",
        "image": "Сурет",
        "video": "Бейне",
        "audio": "Аудио",
        "library": "Кітап дүкені",
        "home": "Негізгі бет",
        "block": "Блоктау",
        "unblock": "Блоктан шығару",
        "has_blocked_you": "Ол сені блоктады",
        "accept": "Қабылдау",
        "decline": "Бас тарту",
        "cancel_request": "Сұраныстан бас тарту",
        "has_been_expulsed": "шығарылды",
        "room_doesnt_exists": "Бөлме жоқ",
        "you_arent_room_member": "Сіз бөлменің мүшесі емессіз.",
        "create_room": "Бөлме жасау",
        "room_name": "Бөлме атауы",
        "no_rooms_created": "Құрылған бөлмелер жоқ.",
        "dont_belong_to_anyone_room": "Сіз ешбір бөлмеде емессіз.",
        "enter_a_username": "Пайдаланушыны жазыңыз",
        "no_results": "Нәтиже жоқ",
        "create": "Жасау",
        "no_content_uploaded": "Жүктеп салынған мазмұн жоқ",
        "new_subtag": "Жаңа қосалқы тег",
        "update_info": "Ақпаратты жаңарту",
        "updating": "Жаңарту",
        "update": "Жаңарту",
        "update_email": "Электрондық поштаны жаңарту",
        "change_password": "Құпия сөзді өзгерту",
        "current_password": "Ағымдағы құпия сөз",
        "select": "таңдаңыз",
        "tap_to_upload_a_file": "Файлды жүктеп салу үшін түртіңіз",
        "edit_photo": "Фотосуретті өңдеу",
        "preview": "Алдын ала қарау",
        "edit_video": "Бейнені өңдеу",
        "delete_changes": "Өзгерістерді жою",
        "uploading": "Жүктеп салынуда",
        "session_deleted_or_expired": "Сеанс жойылды немесе мерзімі аяқталды",
        "edit_profile": "Профильді өңдеу",
        "devices": "Құрылғылар",
        "downloads": "жүктеп алулар",
        "logout": "Шығу",
        "offline": "Офлайн",
        "images": "Суреттер",
        "videos": "Бейнелер",
        "audios": "Аудиолар",
        "chat": "Чат",
        "session_unauthorized": "Сеансыңыз рұқсат етілмеген.",
        "authorize_session": "Бұл сеансқа кіру үшін құрылғыға рұқсат беруіңіз керек.",
        "authorize_instructions": "Электрондық поштаңызда авторизация сілтемесі бар-жоғын тексеріңіз немесе белсенді сеансқа өтіп, жоғарғы оң жақ бұрыштағы үш нүктені таңдаңыз, содан кейін Құрылғылар бөліміне өтіп, Рұқсат етуді таңдаңыз.",
        "refresh_page_after_authorize": "Авторизациялау процесін аяқтағаннан кейін жүйеге кіру үшін бетті жаңартыңыз.",
        "check_your_inbox": "Электрондық поштаңызды тексеріңіз",
        "send_authorization_email": "Авторизация электрондық поштасын жіберіңіз",
        "call_finished": "Қоңырау аяқталды",
        "close": "Жабық",
        "waiting_stream": "Тасымалдауды күтуде",
        "request_camera_and_mic": "Қоңырау шалу үшін камера мен микрофонды пайдалануға рұқсат қажет.",
        "call_in_progress": "Қоңырау орындалуда",
        "group": "Кластер",
        "videocall": "Бейне қоңырау",
        "call": "Қоңырау",
        "connecting": "Қосылуда",
        "call_cancelled": "Қоңырау тоқтатылды",
        "calling": "Қоңырау шалу",
        "hangup": "Трубканы қою",
        "reject": "Бас тарту",
        "has_sent_a_file": "файлды жіберді.",
        "keep": "Сақтау",
        "keep_file": "Файлды сақтауды қоссаңыз, ол қолданбада сақталады. Бұл Интернетке қосылуды қажет етпей-ақ оған қол жеткізуге мүмкіндік береді.",
        "not_keep_file": "Файл қоймасын өшірсеңіз, ол жойылады және оған Интернет қосылымынсыз кіре алмайсыз.",
        "file_stored_instruction": "Барлық сақталған файлдар жалпы файлдар тізімінде жасыл жиекпен көрсетіледі. Сондай-ақ оларды бөлімде көруге болады",
        "stored": "Сақталған",
        "not_stored": "Ол сақталмайды",
        "file_not_stored_instruction": "Сіз бұл файлға интернет қосылымынсыз кіре алмайсыз.",
        "device": "Құрылғы",
        "file_visibility": "Файлдың көрінуі",
        "visible_for_everyone": "Барлығына көрінеді",
        "shared_link": "Ортақ сілтеме",
        "hashtags": "Хэштегтер",
        "hashtags_helps_to_search_your_file": "Хэштегтер файлдарды табуға көмектеседі.",
        "edit": "Өңдеу",
        "move": "Жылжыту",
        "rename": "Атын өзгерту",
        "visibility": "Көріну",
        "delete": "Жою",
        "file_warn_delete": "Файлды шынымен жойғыңыз келе ме?",
        "no_subtags_availables": "Қолжетімсіз қосалқы тегтер",
        "owner": "Жаратушы",
        "expulsing": "Шығу",
        "expulse": "Шығару",
        "you_have_left_the_group": "Сіз топтан шықтыңыз",
        "coming_out": "Шығу",
        "leave_group": "Топтан шығыңыз",
        "you_have_left_the_room": "Сіз бөлмеден шықтыңыз.",
        "leave_room": "Бөлмеден шығыңыз",
        "message_deleted": "Жойылған хабар",
        "has_been_error_deleting_message": "Хабарды жою кезінде қате орын алды",
        "deleting": "Жою",
        "new_tag": "Жаңа тег",
        "photoEditor": {
            "name": "Аты",
            "save": "Аяқтау",
            "saveAs": "Басқаша сақтау",
            "back": "Артқа",
            "loading": "Зарядталуда…",
            "resetOperations": "Қайта бастау/барлық әрекеттерден бас тарту.",
            "changesLoseConfirmation": "Барлық өзгертулер жойылады.",
            "changesLoseConfirmationHint": "Жалғастырғыңыз келетініне сенімдісіз бе?",
            "cancel": "Болдырмау",
            "continue": "Жалғастыру",
            "undoTitle": "Соңғы әрекетті болдырмау",
            "redoTitle": "Соңғы әрекетті қайталаңыз",
            "showImageTitle": "Түпнұсқа суретті көрсету",
            "zoomInTitle": "Үлкейту",
            "zoomOutTitle": "Кішірейту",
            "toggleZoomMenuTitle": "Масштабтау мәзірін ауыстыру",
            "adjustTab": "Реттеу",
            "finetuneTab": "Түстер",
            "filtersTab": "Сүзгілер",
            "watermarkTab": "Суреттер",
            "annotateTab": "Сурет салу",
            "resize": "Өлшемін өзгерту",
            "resizeTab": "Өлшем",
            "invalidImageError": "Жарамсыз сурет",
            "uploadImageError": "Кескінді жүктеу кезінде қате орын алды.",
            "areNotImages": "Олар сурет емес",
            "isNotImage": "бұл сурет емес",
            "toBeUploaded": "жүктеп салу керек",
            "cropTool": "Кесу",
            "original": "Түпнұсқа",
            "custom": "Жекелендірілген",
            "square": "Шаршы",
            "landscape": "Көлденең",
            "portrait": "Вертикалды",
            "ellipse": "Шеңбер",
            "classicTv": "классикалық теледидар",
            "cinemascope": "Кинемаскоп",
            "arrowTool": "Жебе",
            "blurTool": "Бұлыңғыр",
            "brightnessTool": "Жарқырау",
            "contrastTool": "Контраст",
            "ellipseTool": "Шеңбер",
            "unFlipX": "X айналдырыңыз",
            "flipX": "X аударыңыз",
            "unFlipY": "Y айналдырыңыз",
            "flipY": "Y аударыңыз",
            "hsvTool": "MSV",
            "hue": "Реңк",
            "saturation": "Қанықтылық",
            "value": "тұрарлық",
            "imageTool": "Сурет",
            "importing": "Импорттау…",
            "addImage": "+ Сурет қосу",
            "uploadImage": "Суретті жүктеп салу",
            "fromGallery": "Стикерлер",
            "lineTool": "Түзу",
            "penTool": "Қарындаш",
            "polygonTool": "Көпбұрыш",
            "sides": "Тараптар",
            "rectangleTool": "Төртбұрыш",
            "cornerRadius": "Жиек радиусы",
            "resizeWidthTitle": "Пиксельдегі ені",
            "resizeHeightTitle": "Пиксель биіктігі",
            "toggleRatioLockTitle": "Өлшем құлпын ауыстырып қосу",
            "reset": "Қайтадан қосу",
            "resetSize": "Кескіннің бастапқы өлшемдерін қалпына келтіріңіз.",
            "rotateTool": "Айналдыру",
            "textTool": "Мәтін",
            "textSpacings": "Мәтін аралығы",
            "textAlignment": "Мәтінді туралау",
            "fontFamily": "Қаріптер тобы",
            "size": "Өлшем",
            "letterSpacing": "Әріптер аралығы",
            "lineHeight": "Сызық биіктігі",
            "warmthTool": "Жылу",
            "addWatermark": "+ Сурет қосу",
            "addWatermarkTitle": "Су таңбасының түрін таңдаңыз",
            "uploadWatermark": "Суретті таңдаңыз",
            "addWatermarkAsText": "Мәтін қосу",
            "padding": "Толтырғыш",
            "shadow": "Көлеңке",
            "horizontal": "Көлденең",
            "vertical": "Вертикалды",
            "blur": "Бұлыңғыр",
            "opacity": "Мөлдірлік",
            "position": "Позиция",
            "stroke": "Қалыңдық",
            "saveAsModalLabel": "Егжей",
            "extension": "Кеңейтім",
            "nameIsRequired": "Аты қажет.",
            "quality": "Сапасы",
            "imageDimensionsHoverTitle": "Сақталған кескін өлшемдері (ені x биіктігі)",
            "cropSizeLowerThanResizedWarning": "Таңдалған өсу аймағы пайдаланылған өлшемнен кішірек екенін ескеріңіз, бұл сапаның төмендеуіне әкелуі мүмкін.",
            "actualSize": "Ағымдағы өлшем (100%)",
            "fitSize": "Өлшемді бейімдеу",
            "addImageTitle": "Қосылатын суретті таңдаңыз…"
        },
        "changing": "Өзгерту",
        "profile_picture_changed": "Профиль суреті өзгертілді",
        "loading_profile_picture": "Профиль фотосуреті жүктелуде",
        "change": "Өзгерту",
        "session": "Сеанс",
        "system_operative": "ОЖ",
        "navigator": "Браузер",
        "authorize": "Рұқсат ету",
        "deauthorize": "Бас тарту",
        "keep_tag_content": "Белгінің мазмұнын сақтағыңыз келе ме?",
        "move_content_to": "Мазмұнды келесіге жылжытыңыз",
        "move_to": "Жылжыту",
        "chat_request": "Чат сұрауы",
        "accept_request": "Өтінішті қабылдау",
        "go_to_chat": "Чатқа өтіңіз",
        "applying": "Өтініш беру",
        "time": "Уақыт",
        "adjust": "Реттеу",
        "rotate": "Айналдыру",
        "start": "Бастау",
        "end": "Финал",
        "lang_name": "Kazakh"
    },
    "lo": {
        "name": "ຊື່",
        "name_example": "Joy",
        "last_name": "ນາມ​ສະ​ກຸນ",
        "last_name_example": "Inthavong",
        "username": "ຜູ້ໃຊ້",
        "username_example": "joy_inthavog",
        "email": "ອີເມວ",
        "email_example": "ຜູ້ໃຊ້@ໂດເມນ.com",
        "password": "ລະຫັດຜ່ານ",
        "new_password": "ລະຫັດຜ່ານໃໝ່",
        "password_example": "*****************",
        "login": "ເຂົ້າ​ສູ່​ລະ​ບົບ",
        "register": "ລົງທະບຽນ",
        "verification_code": "ລະຫັດຢືນຢັນ",
        "verification_code_example": "xxx",
        "verify": "ຢືນຢັນ",
        "loading": "ກຳລັງສາກໄຟ…",
        "please_wait": "ກະ​ລຸ​ນາ​ລໍ​ຖ້າ",
        "go_back": "ກັບຄືນ",
        "recovery": "ຟື້ນຕົວ",
        "login_subtitle": "ໃສ່ລາຍລະອຽດຂອງທ່ານແລະເຂົ້າເຖິງບັນຊີຂອງທ່ານ.",
        "forgot_password_link": "ເຈົ້າລືມລະຫັດຜ່ານຂອງເຈົ້າບໍ? ຟື້ນຕົວ.",
        "register_account_link": "ເຈົ້າບໍ່ມີບັນຊີບໍ? ບັນທຶກ!",
        "register_subtitle": "ໃສ່ລາຍລະອຽດຂອງທ່ານແລະລົງທະບຽນບັນຊີຂອງທ່ານ.",
        "login_link": "ເຈົ້າມີບັນຊີຢູ່ແລ້ວບໍ? ເຂົ້າເຖິງ!",
        "verification_code_instruction": "ກວດເບິ່ງອີເມວຂອງທ່ານແລະໃສ່ລະຫັດທີ່ຖືກສົ່ງໄປຫາທ່ານ.",
        "forgot_password_subtitle": "ໃສ່ທີ່ຢູ່ອີເມວຂອງທ່ານເພື່ອກູ້ລະຫັດຜ່ານຂອງທ່ານ.",
        "change_password_subtitle": "ໃສ່ລະຫັດຜ່ານໃໝ່.",
        "change_password_code_used": "ລະຫັດກູ້ລະຫັດຜ່ານຂອງເຈົ້າບໍ່ຖືກຕ້ອງ ຫຼືຖືກໃຊ້ແລ້ວ.",
        "no_chats": "ທ່ານບໍ່ມີການສົນທະນາ.",
        "chats": "ການສົນທະນາ",
        "groups": "ກຸ່ມ",
        "rooms": "ຫ້ອງ",
        "save": "ຮັກສາ",
        "download": "ລົງຂາວ",
        "downloading_image": "ກຳລັງດາວໂຫຼດຮູບ",
        "an_error_has_ocurred": "ເກີດຄວາມຜິດພາດຂຶ້ນ",
        "saving_changes": "ບັນທຶກການປ່ຽນແປງ",
        "go_back_to_edit": "ແກ້ໄຂອີກຄັ້ງ",
        "upload_warning_modal": "ການອັບໂຫລດການແກ້ໄຂຈະປ່ຽນແທນຮູບຕົ້ນສະບັບ.",
        "upload_question_modal": "ທ່ານຕ້ອງການເກັບຮັກສາຕົ້ນສະບັບແລະເຮັດສໍາເນົາບໍ?",
        "create_copy_and_keep": "ເຮັດສໍາເນົາແລະເກັບຮັກສາ",
        "upload": "ອັບໂຫຼດ",
        "upload_changes": "ອັບໂຫຼດການປ່ຽນແປງ",
        "changes_saved": "ບັນທຶກການປ່ຽນແປງແລ້ວ",
        "downloading_video": "ກຳລັງດາວໂຫຼດວິດີໂອ",
        "video_upload_warning_modal": "ການອັບໂຫລດການແກ້ໄຂຈະປ່ຽນແທນວິດີໂອຕົ້ນສະບັບ.",
        "results": "ຜົນໄດ້ຮັບ",
        "members": "ສະມາຊິກ",
        "cancel": "ຍົກເລີກ",
        "add_user": "ເພີ່ມຜູ້ໃຊ້",
        "no_messages": "ບໍ່ມີຂໍ້ຄວາມ",
        "message_placeholder": "ຂໍ້ຄວາມ…",
        "group_doesnt_exists": "ບໍ່ມີກຸ່ມ",
        "you_arent_group_member": "ທ່ານບໍ່ແມ່ນສະມາຊິກຂອງກຸ່ມ",
        "touch_to_see_more_info": "ແຕະເພື່ອເບິ່ງຂໍ້ມູນເພີ່ມເຕີມ.",
        "start_videocall": "ເລີ່ມການໂທວິດີໂອ",
        "start_call": "ເລີ່ມການໂທ",
        "create_group": "ສ້າງກຸ່ມ",
        "group_name": "ຊື່ກຸ່ມ",
        "add_members_to_create_group": "ເພີ່ມສະມາຊິກເພື່ອສ້າງກຸ່ມ",
        "search": "ຊອກ​ຫາ",
        "creating": "ການສ້າງ…",
        "dont_belong_anyone_group": "ທ່ານບໍ່ໄດ້ຢູ່ໃນກຸ່ມໃດໆ.",
        "already_add_this_hashtag": "ທ່ານໄດ້ເພີ່ມ hashtag ນັ້ນແລ້ວ",
        "search_files_by_hashtags": "ຄົ້ນຫາໄຟລ໌ໂດຍ hashtags",
        "enter_to_add": "ກະລຸນາໃສ່ເພື່ອເພີ່ມ",
        "hashtags_most_used": "hashtags ທີ່ໃຊ້ຫຼາຍທີ່ສຸດ",
        "can_add_it_giving_click": "ທ່ານສາມາດເພີ່ມພວກມັນເຂົ້າໃນການຄົ້ນຫາຂອງທ່ານໂດຍການຄລິກໃສ່ພວກມັນ.",
        "searching_files": "ກຳລັງຊອກຫາໄຟລ໌",
        "no_files_found": "ບໍ່ພົບໄຟລ໌",
        "image": "ຮູບພາບ",
        "video": "ວິດີໂອ",
        "audio": "ສຽງ",
        "library": "ຮ້ານຂາຍປຶ້ມ",
        "home": "ຫນ້າທໍາອິດ",
        "block": "ຕັນ",
        "unblock": "ເພື່ອປົດລັອກ",
        "has_blocked_you": "ພຣະອົງໄດ້ສະກັດທ່ານ",
        "accept": "ຍອມ​ຮັບ",
        "decline": "ຫຼຸດລົງ",
        "cancel_request": "ຍົກເລີກການຮ້ອງຂໍ",
        "has_been_expulsed": "ໄດ້ຖືກຂັບໄລ່ອອກ",
        "room_doesnt_exists": "ບໍ່ມີຫ້ອງ",
        "you_arent_room_member": "ເຈົ້າບໍ່ແມ່ນສະມາຊິກຂອງຫ້ອງ",
        "create_room": "ສ້າງຫ້ອງ",
        "room_name": "ຊື່ຫ້ອງ",
        "no_rooms_created": "ບໍ່ມີຫ້ອງທີ່ສ້າງຂຶ້ນ.",
        "dont_belong_to_anyone_room": "ເຈົ້າບໍ່ໄດ້ຢູ່ໃນຫ້ອງໃດ.",
        "enter_a_username": "ຂຽນຜູ້ໃຊ້",
        "no_results": "ບໍ່ມີຜົນໄດ້ຮັບ",
        "create": "ສ້າງ",
        "no_content_uploaded": "ບໍ່ມີການອັບໂຫລດເນື້ອຫາ",
        "new_subtag": "ແທັກຍ່ອຍໃໝ່",
        "update_info": "ອັບເດດຂໍ້ມູນ",
        "updating": "ກຳລັງປັບປຸງ",
        "update": "ອັບເດດ",
        "update_email": "ອັບເດດອີເມວ",
        "change_password": "ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ",
        "current_password": "ລະ​ຫັດ​ປັດ​ຈຸ​ບັນ",
        "select": "ເລືອກ",
        "tap_to_upload_a_file": "ແຕະເພື່ອອັບໂຫລດໄຟລ໌",
        "edit_photo": "ແກ້ໄຂຮູບ",
        "preview": "ເບິ່ງຕົວຢ່າງ",
        "edit_video": "ແກ້ໄຂວິດີໂອ",
        "delete_changes": "ລຶບການປ່ຽນແປງ",
        "uploading": "ກຳລັງອັບໂຫລດ",
        "session_deleted_or_expired": "ເຊດຊັນຖືກລຶບ ຫຼືໝົດອາຍຸແລ້ວ.",
        "edit_profile": "ແກ້ໄຂໂປຣໄຟລ໌",
        "devices": "ອຸປະກອນ",
        "downloads": "ດາວໂຫຼດ",
        "logout": "ອອກຈາກລະບົບ",
        "offline": "ໂດຍບໍ່ມີການເຊື່ອມຕໍ່",
        "images": "ຮູບພາບ",
        "videos": "ວິດີໂອ",
        "audios": "ສຽງ",
        "chat": "ສົນທະນາ",
        "session_unauthorized": "ເຊດຊັນຂອງທ່ານບໍ່ໄດ້ຮັບອະນຸຍາດ.",
        "authorize_session": "ເພື່ອເຂົ້າເຖິງເຊດຊັນນີ້, ທ່ານຈໍາເປັນຕ້ອງອະນຸຍາດໃຫ້ອຸປະກອນຂອງທ່ານ.",
        "authorize_instructions": "ກວດເບິ່ງອີເມວຂອງທ່ານສໍາລັບການເຊື່ອມຕໍ່ການອະນຸຍາດຫຼືໄປຫາກອງປະຊຸມທີ່ມີການເຄື່ອນໄຫວແລະເລືອກສາມຈຸດໃນມຸມຂວາເທິງ, ຈາກນັ້ນໄປທີ່ \"ອຸປະກອນ\" ແລະເລືອກ \"ອະນຸຍາດ.\"",
        "refresh_page_after_authorize": "ເມື່ອທ່ານສຳເລັດຂັ້ນຕອນການອະນຸຍາດແລ້ວ, ໃຫ້ໂຫຼດໜ້ານີ້ຄືນໃໝ່ເພື່ອເຂົ້າເຖິງເຊດຊັນ.",
        "check_your_inbox": "ໃຫ້​ກວດ​ເບິ່ງ​ອີ​ເມວ​ຂອງ​ທ່ານ",
        "send_authorization_email": "ສົ່ງອີເມວການອະນຸຍາດ",
        "call_finished": "ສິ້ນສຸດການໂທ",
        "close": "ປິດ",
        "waiting_stream": "ລໍຖ້າການສົ່ງຕໍ່",
        "request_camera_and_mic": "ພວກເຮົາຕ້ອງການການເຂົ້າເຖິງກ້ອງຖ່າຍຮູບ ແລະໄມໂຄຣໂຟນຂອງທ່ານ ເພື່ອໃຫ້ທ່ານສາມາດໂທອອກໄດ້.",
        "call_in_progress": "ກຳລັງໂທຢູ່",
        "group": "ກຸ່ມ",
        "videocall": "ໂທວິດີໂອ",
        "call": "ໂທ",
        "connecting": "ກຳລັງເຊື່ອມຕໍ່",
        "call_cancelled": "ຍົກເລີກການໂທແລ້ວ",
        "calling": "ໂທ",
        "hangup": "ວາງສາຍ",
        "reject": "ປະຕິເສດ",
        "has_sent_a_file": "ໄດ້​ສົ່ງ​ໄຟລ​໌​.",
        "keep": "ຮັກສາ",
        "keep_file": "ຖ້າທ່ານເປີດໃຊ້ການເກັບຮັກສາໄຟລ໌, ມັນຈະຖືກບັນທຶກໄວ້ໃນແອັບຯ. ນີ້ຈະອະນຸຍາດໃຫ້ເຂົ້າເຖິງໄດ້ໂດຍບໍ່ຈໍາເປັນຕ້ອງມີການເຊື່ອມຕໍ່ອິນເຕີເນັດ.",
        "not_keep_file": "ຖ້າທ່ານປິດການເກັບຮັກສາໄຟລ໌, ມັນຈະຖືກລຶບອອກແລະທ່ານຈະບໍ່ສາມາດເຂົ້າເຖິງມັນໄດ້ໂດຍບໍ່ມີການເຊື່ອມຕໍ່ອິນເຕີເນັດ.",
        "file_stored_instruction": "ໄຟລ໌ທີ່ເກັບໄວ້ທັງໝົດຈະປາກົດດ້ວຍຂອບສີຂຽວໃນລາຍການໄຟລ໌ທົ່ວໄປ. ທ່ານຍັງສາມາດເບິ່ງພວກເຂົາຢູ່ໃນພາກ",
        "stored": "ເກັບຮັກສາໄວ້",
        "not_stored": "ມັນບໍ່ໄດ້ຖືກເກັບຮັກສາໄວ້",
        "file_not_stored_instruction": "ທ່ານຈະບໍ່ສາມາດເຂົ້າເຖິງໄຟລ໌ນີ້ໂດຍບໍ່ມີການເຊື່ອມຕໍ່ອິນເຕີເນັດ.",
        "device": "ອຸປະກອນ",
        "file_visibility": "ການເບິ່ງເຫັນໄຟລ໌",
        "visible_for_everyone": "ເບິ່ງເຫັນໄດ້ສໍາລັບທຸກຄົນ",
        "shared_link": "ລິ້ງທີ່ແບ່ງປັນ",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Hashtags ຊ່ວຍໃຫ້ທ່ານຊອກຫາໄຟລ໌ຂອງທ່ານ.",
        "edit": "ແກ້ໄຂ",
        "move": "ຍ້າຍ",
        "rename": "ປ່ຽນຊື່",
        "visibility": "ການເບິ່ງເຫັນ",
        "delete": "ກໍາຈັດ",
        "file_warn_delete": "ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບໄຟລ໌?",
        "no_subtags_availables": "ບໍ່ມີແທັກຍ່ອຍທີ່ມີຢູ່",
        "owner": "ເຈົ້າຂອງ",
        "expulsing": "ຂັບໄລ່",
        "expulse": "ຂັບໄລ່ອອກ",
        "you_have_left_the_group": "ທ່ານໄດ້ອອກຈາກກຸ່ມແລ້ວ",
        "coming_out": "ອອກມາ",
        "leave_group": "ອອກຈາກກຸ່ມ",
        "you_have_left_the_room": "ທ່ານໄດ້ອອກຈາກຫ້ອງ.",
        "leave_room": "ອອກຈາກຫ້ອງ",
        "message_deleted": "ຂໍ້ຄວາມທີ່ຖືກລົບ",
        "has_been_error_deleting_message": "ມີຄວາມຜິດພາດໃນການລຶບຂໍ້ຄວາມ.",
        "deleting": "ການຖອນ",
        "new_tag": "ແທັກໃໝ່",
        "photoEditor": {
            "name": "ຊື່",
            "save": "ຈົບ",
            "saveAs": "ບັນທຶກເປັນ",
            "back": "ກັບຄືນໄປບ່ອນ",
            "loading": "ກຳລັງສາກໄຟ…",
            "resetOperations": "ຣີສະຕາດ/ລຶບການດຳເນີນການທັງໝົດ.",
            "changesLoseConfirmation": "ການປ່ຽນແປງທັງໝົດຈະຖືກລຶບອອກ.",
            "changesLoseConfirmationHint": "ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການສືບຕໍ່?",
            "cancel": "ຍົກເລີກ",
            "continue": "ສືບຕໍ່",
            "undoTitle": "ຍົກເລີກການດຳເນີນການຫຼ້າສຸດ",
            "redoTitle": "ເຮັດຄືນການດຳເນີນການຫຼ້າສຸດ",
            "showImageTitle": "ສະແດງຮູບຕົ້ນສະບັບ",
            "zoomInTitle": "ຂະ​ຫຍາຍ​ເຂົ້າ",
            "zoomOutTitle": "ຊູມອອກ",
            "toggleZoomMenuTitle": "ສະຫຼັບເມນູຊູມ",
            "adjustTab": "ປັບ",
            "finetuneTab": "ສີ",
            "filtersTab": "ການກັ່ນຕອງ",
            "watermarkTab": "ຮູບພາບ",
            "annotateTab": "ແຕ້ມ",
            "resize": "ປັບຂະໜາດ",
            "resizeTab": "ຂະໜາດ",
            "invalidImageError": "ຮູບບໍ່ຖືກຕ້ອງ",
            "uploadImageError": "ເກີດຄວາມຜິດພາດຂຶ້ນໃນລະຫວ່າງການໂຫລດຮູບພາບ.",
            "areNotImages": "ພວກເຂົາບໍ່ແມ່ນຮູບພາບ",
            "isNotImage": "ມັນບໍ່ແມ່ນຮູບພາບ",
            "toBeUploaded": "ຈະຖືກອັບໂຫລດ",
            "cropTool": "ຕັດ",
            "original": "ຕົ້ນສະບັບ",
            "custom": "ປັບແຕ່ງສ່ວນຕົວ",
            "square": "ຮຽບຮ້ອຍ",
            "landscape": "ລວງນອນ",
            "portrait": "ຕັ້ງ",
            "ellipse": "ວົງ",
            "classicTv": "ໂທລະພາບຄລາສສິກ",
            "cinemascope": "Cinemascope",
            "arrowTool": "ລູກສອນ",
            "blurTool": "ມົວ",
            "brightnessTool": "ໂກລ",
            "contrastTool": "ກົງກັນຂ້າມ",
            "ellipseTool": "ວົງ",
            "unFlipX": "Unflip X",
            "flipX": "Flip X",
            "unFlipY": "Unflip Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "ເຫວ",
            "saturation": "ການອີ່ມຕົວ",
            "value": "ມູນຄ່າ",
            "imageTool": "ຮູບພາບ",
            "importing": "ການນໍາເຂົ້າ…",
            "addImage": "+ ເພີ່ມ​ຮູບ​ພາບ​",
            "uploadImage": "ອັບໂຫຼດຮູບ",
            "fromGallery": "ສະຕິກເກີ",
            "lineTool": "ສາຍ",
            "penTool": "ສໍ",
            "polygonTool": "ໂພລີກອນ",
            "sides": "ຂ້າງ",
            "rectangleTool": "ສີ່ຫຼ່ຽມ",
            "cornerRadius": "ຂອບ Radius",
            "resizeWidthTitle": "ຄວາມກວ້າງເປັນ pixels",
            "resizeHeightTitle": "ຄວາມສູງ Pixel",
            "toggleRatioLockTitle": "ສະຫຼັບການລັອກຂະໜາດ",
            "reset": "ເລີ່ມ​ຕົ້ນ​ໃຫມ່",
            "resetSize": "ຣີເຊັດເປັນຂະໜາດຮູບຕົ້ນສະບັບ",
            "rotateTool": "ໝຸນ",
            "textTool": "ຂໍ້​ຄວາມ",
            "textSpacings": "ໄລຍະຫ່າງຂໍ້ຄວາມ",
            "textAlignment": "ການຈັດຮຽງຂໍ້ຄວາມ",
            "fontFamily": "ຄອບຄົວຕົວອັກສອນ",
            "size": "ຂະໜາດ",
            "letterSpacing": "ໄລຍະຫ່າງຕົວອັກສອນ",
            "lineHeight": "ຄວາມສູງຂອງເສັ້ນ",
            "warmthTool": "ຄວາມຮ້ອນ",
            "addWatermark": "+ ເພີ່ມ​ຮູບ​ພາບ​",
            "addWatermarkTitle": "ເລືອກປະເພດຂອງ watermark ໄດ້",
            "uploadWatermark": "ເລືອກຮູບ",
            "addWatermarkAsText": "ເພີ່ມຂໍ້ຄວາມ",
            "padding": "padding",
            "shadow": "ຮົ່ມ",
            "horizontal": "ລວງນອນ",
            "vertical": "ຕັ້ງ",
            "blur": "ມົວ",
            "opacity": "ຄວາມມືດ",
            "position": "ຕໍາແໜ່ງ",
            "stroke": "ຄວາມຫນາ",
            "saveAsModalLabel": "ລາຍລະອຽດ",
            "extension": "ສ່ວນຂະຫຍາຍ",
            "nameIsRequired": "ຕ້ອງລະບຸຊື່.",
            "quality": "ຄຸນະພາບ",
            "imageDimensionsHoverTitle": "ຂະໜາດຮູບທີ່ບັນທຶກໄວ້ (ກວ້າງ x ສູງ)",
            "cropSizeLowerThanResizedWarning": "ກະ​ລຸ​ນາ​ສັງ​ເກດ​ວ່າ​ພື້ນ​ທີ່​ການ​ປູກ​ພືດ​ທີ່​ເລືອກ​ມີ​ຂະ​ຫນາດ​ນ້ອຍ​ກ​່​ວາ​ການ​ຕັ້ງ​ຄ່າ​ຂະ​ຫນາດ​ທີ່​ນໍາ​ໃຊ້​, ຊຶ່ງ​ອາດ​ຈະ​ເຮັດ​ໃຫ້​ມີ​ການ​ຫຼຸດ​ລົງ​ຂອງ​ຄຸນ​ນະ​ພາບ​.",
            "actualSize": "ຂະໜາດປັດຈຸບັນ (100%)",
            "fitSize": "ປັບຂະຫນາດ",
            "addImageTitle": "ເລືອກຮູບທີ່ຈະເພີ່ມ…"
        },
        "changing": "ການປ່ຽນແປງ",
        "profile_picture_changed": "ປ່ຽນຮູບໂປຣໄຟລ໌ແລ້ວ",
        "loading_profile_picture": "ກຳລັງໂຫຼດຮູບໂປຣໄຟລ໌",
        "change": "ປ່ຽນແປງ",
        "session": "ເຊດຊັນ",
        "system_operative": "OS",
        "navigator": "ຕົວທ່ອງເວັບ",
        "authorize": "ອະນຸຍາດ",
        "deauthorize": "ປະຕິເສດ",
        "keep_tag_content": "ທ່ານຕ້ອງການຮັກສາເນື້ອຫາຂອງແທັກບໍ?",
        "move_content_to": "ຍ້າຍເນື້ອຫາໄປ",
        "move_to": "ຍ້າຍ​ໄປ",
        "chat_request": "ຄຳຮ້ອງຂໍສົນທະນາ",
        "accept_request": "ຍອມຮັບການຮ້ອງຂໍ",
        "go_to_chat": "ໄປສົນທະນາ",
        "applying": "ກຳລັງນຳໃຊ້",
        "time": "ເວລາ",
        "adjust": "ປັບ",
        "rotate": "ໝຸນ",
        "start": "ເລີ່ມ",
        "end": "ສຸດທ້າຍ",
        "lang_name": "Laotian"
    },
    "lv": {
        "name": "Nosaukums",
        "name_example": "Janis",
        "last_name": "Uzvārds",
        "last_name_example": "Berzina",
        "username": "Lietotājs",
        "username_example": "janis_berzina",
        "email": "E-pasta adrese",
        "email_example": "lietotājs@domēns.com",
        "password": "Parole",
        "new_password": "Jauna parole",
        "password_example": "*****************",
        "login": "Ienākt",
        "register": "Reģistrācija",
        "verification_code": "Verifikācijas kods",
        "verification_code_example": "xxx",
        "verify": "Pārbaudiet",
        "loading": "Iekraušana…",
        "please_wait": "Lūdzu, uzgaidiet",
        "go_back": "Atgriezties pie",
        "recovery": "Atgūšana",
        "login_subtitle": "Ievadiet savu informāciju un autorizējieties savā kontā.",
        "forgot_password_link": "Aizmirsāt paroli? Atgūstiet to.",
        "register_account_link": "Jums nav konta? Reģistrējieties!",
        "register_subtitle": "Ievadiet savu informāciju un reģistrējiet kontu.",
        "login_link": "Jums jau ir konts? Piesakieties!",
        "verification_code_instruction": "Pārbaudiet savu e-pastu un ievadiet nosūtīto kodu.",
        "forgot_password_subtitle": "Ievadiet savu e-pasta adresi, lai atgūtu paroli.",
        "change_password_subtitle": "Ievadiet jaunu paroli.",
        "change_password_code_used": "Jūsu paroles atjaunošanas kods ir nederīgs vai jau ir izmantots.",
        "no_chats": "Nav tērzēšanas",
        "chats": "Sarunas",
        "groups": "Grupas",
        "rooms": "Istabas",
        "save": "Saglabāt",
        "download": "Lejupielādēt",
        "downloading_image": "Attēla lejupielāde",
        "an_error_has_ocurred": "Ir notikusi kļūda",
        "saving_changes": "Izmaiņu saglabāšana",
        "go_back_to_edit": "Atgriezties pie rediģēšanas",
        "upload_warning_modal": "Rediģētā attēla augšupielāde aizstās sākotnējo attēlu.",
        "upload_question_modal": "Vai vēlaties saglabāt oriģinālu un izveidot kopiju?",
        "create_copy_and_keep": "Izveidot kopiju un saglabāt",
        "upload": "Augšupielādēt",
        "upload_changes": "Augšupielādēt izmaiņas",
        "changes_saved": "Saglabātās izmaiņas",
        "downloading_video": "Video lejupielāde",
        "video_upload_warning_modal": "Kad augšupielādēsiet rediģēto videoklipu, tas aizstās sākotnējo videoklipu.",
        "results": "Rezultāti",
        "members": "Locekļi",
        "cancel": "Atcelt",
        "add_user": "Pievienot lietotāju",
        "no_messages": "Nav ziņojumu",
        "message_placeholder": "Ziņa…",
        "group_doesnt_exists": "El grupo no existe",
        "you_arent_group_member": "Jūs neesat grupas dalībnieks",
        "touch_to_see_more_info": "Pieskarieties, lai skatītu papildu informāciju.",
        "start_videocall": "Videozvana sākšana",
        "start_call": "Sākuma zvans",
        "create_group": "Izveidot grupu",
        "group_name": "Grupas nosaukums",
        "add_members_to_create_group": "Pievienot dalībniekus, lai izveidotu grupu",
        "search": "Meklēšana",
        "creating": "izveide…",
        "dont_belong_anyone_group": "Jūs nepiederat nevienai grupai.",
        "already_add_this_hashtag": "Jūs jau esat pievienojis šo hashtag",
        "search_files_by_hashtags": "Failu meklēšana pēc hashtagiem",
        "enter_to_add": "Ievadiet, lai pievienotu",
        "hashtags_most_used": "Visbiežāk izmantotie hashtagi",
        "can_add_it_giving_click": "Varat tos pievienot meklēšanai, noklikšķinot uz tiem.",
        "searching_files": "Failu meklēšana",
        "no_files_found": "Faili nav atrasti",
        "image": "Attēls",
        "video": "Video",
        "audio": "Audio",
        "library": "Grāmatnīca",
        "home": "Sākums",
        "block": "Bloķēt",
        "unblock": "Atbloķēt",
        "has_blocked_you": "Viņš jūs ir bloķējis",
        "accept": "Pieņemt",
        "decline": "Noraidīt",
        "cancel_request": "Atcelt pieprasījumu",
        "has_been_expulsed": "ir izraidīta",
        "room_doesnt_exists": "Istaba neeksistē",
        "you_arent_room_member": "Jūs neesat telpas dalībnieks",
        "create_room": "Izveidot istabu",
        "room_name": "Telpas nosaukums",
        "no_rooms_created": "Nav izveidotas nevienas telpas.",
        "dont_belong_to_anyone_room": "Jūs nepiederat nevienai istabai.",
        "enter_a_username": "Uzrakstiet lietotāja",
        "no_results": "Nav rezultātu",
        "create": "Izveidot",
        "no_content_uploaded": "Nav augšupielādēts saturs",
        "new_subtag": "Jauna apakšzīmīte",
        "update_info": "Informācijas atjaunināšana",
        "updating": "atjaunināšana",
        "update": "Atjaunināt",
        "update_email": "Atjaunināt e-pastu",
        "change_password": "Mainīt paroli",
        "current_password": "Pašreizējā parole",
        "select": "Atlasiet",
        "tap_to_upload_a_file": "Pieskarieties, lai augšupielādētu failu",
        "edit_photo": "Rediģēt fotoattēlu",
        "preview": "Priekšskatījums",
        "edit_video": "Rediģēt video",
        "delete_changes": "Izdzēst izmaiņas",
        "uploading": "augšupielāde",
        "session_deleted_or_expired": "Sesija dzēsta vai beidzies tās derīguma termiņš",
        "edit_profile": "Rediģēt profilu",
        "devices": "Ierīces",
        "downloads": "Lejupielādes",
        "logout": "Iziet no konta",
        "offline": "Bezsaistes režīmā",
        "images": "Attēli",
        "videos": "Videoklipi",
        "audios": "Audios",
        "chat": "Tērzēšanas",
        "session_unauthorized": "Jūsu sesija nav autorizēta.",
        "authorize_session": "Lai piekļūtu šai sesijai, ierīcei jābūt autorizētai.",
        "authorize_instructions": "Lūdzu, pārbaudiet savu e-pastu, lai atrastu autorizācijas saiti, vai arī dodieties uz aktīvu sesiju un izvēlieties trīs punktiņus augšējā labajā stūrī, pēc tam dodieties uz \"Ierīces\" un izvēlieties \"Autorizēt\".",
        "refresh_page_after_authorize": "Kad autorizācijas process ir pabeigts, atsvaidziniet pieteikšanās lapu.",
        "check_your_inbox": "Pārbaudiet savu e-pastu",
        "send_authorization_email": "Autorizācijas e-pasta nosūtīšana",
        "call_finished": "Zvans beidzies",
        "close": "Aizvērt",
        "waiting_stream": "Gaidām pārsūtīšanu",
        "request_camera_and_mic": "Mums ir nepieciešama piekļuve jūsu kamerai un mikrofonam, lai jūs varētu veikt zvanus.",
        "call_in_progress": "Notiekošais zvans",
        "group": "Grupa",
        "videocall": "Videozvans",
        "call": "Zvaniet uz",
        "connecting": "Savienošana",
        "call_cancelled": "Zvans atcelts",
        "calling": "Zvanot uz",
        "hangup": "Pakārt",
        "reject": "Noraidīt",
        "has_sent_a_file": "ir nosūtījis failu.",
        "keep": "Saglabāt",
        "keep_file": "Ja aktivizēsiet saglabāšanas opciju, fails tiks saglabāts programmā. Tas nodrošinās pieejamību bez interneta savienojuma.",
        "not_keep_file": "Ja deaktivizēsiet saglabāšanas opciju, fails tiks dzēsts, un tam nevarēsiet piekļūt bez interneta savienojuma.",
        "file_stored_instruction": "Visi saglabātie faili koplietojamo failu sarakstā tiks parādīti ar zaļu malu. Varat arī apskatīt",
        "stored": "Uzglabājas",
        "not_stored": "Nav saglabāts",
        "file_not_stored_instruction": "Bez interneta savienojuma nevarēsiet piekļūt šim failam.",
        "device": "Ierīce",
        "file_visibility": "Arhīva redzamība",
        "visible_for_everyone": "Redzams visiem",
        "shared_link": "Koplietojamā saite",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Hashtags palīdz meklēt failus.",
        "edit": "Rediģēt",
        "move": "Pārvietot",
        "rename": "Pārdēvēt",
        "visibility": "Redzamība",
        "delete": "Dzēst",
        "file_warn_delete": "Vai esat pārliecināts, ka vēlaties izdzēst failu?",
        "no_subtags_availables": "Nav pieejamu apakšzīmju",
        "owner": "Radītājs",
        "expulsing": "Izstumšana",
        "expulse": "Izdzēst",
        "you_have_left_the_group": "Jūs esat atstājis grupu",
        "coming_out": "Atstājot",
        "leave_group": "Iziet no grupas",
        "you_have_left_the_room": "Jūs esat izgājis no istabas.",
        "leave_room": "Telpas atstāšana",
        "message_deleted": "Ziņojums ir dzēsts",
        "has_been_error_deleting_message": "Ziņojuma dzēšanas laikā notika kļūda",
        "deleting": "dzēšana",
        "new_tag": "Jauna birka",
        "photoEditor": {
            "name": "Nosaukums",
            "save": "Izbeigt",
            "saveAs": "Saglabāt kā",
            "back": "Atpakaļ",
            "loading": "Iekraušana…",
            "resetOperations": "Atiestatīt/izdzēst visas operācijas.",
            "changesLoseConfirmation": "Visas izmaiņas tiks dzēstas.",
            "changesLoseConfirmationHint": "Vai esat pārliecināts, ka vēlaties turpināt?",
            "cancel": "Atcelt",
            "continue": "Turpināt uz",
            "undoTitle": "Atcelt pēdējo darbību",
            "redoTitle": "Atkārtot pēdējo darbību",
            "showImageTitle": "Rādīt sākotnējo attēlu",
            "zoomInTitle": "Pietuvināt",
            "zoomOutTitle": "Palielināt",
            "toggleZoomMenuTitle": "Pārslēgt tālummaiņas izvēlni",
            "adjustTab": "Noregulējiet",
            "finetuneTab": "Krāsas",
            "filtersTab": "Filtri",
            "watermarkTab": "Attēli",
            "annotateTab": "Zīmēšana",
            "resize": "Mainīt izmēru",
            "resizeTab": "Izmērs",
            "invalidImageError": "Nederīgs attēls",
            "uploadImageError": "Attēla ielādēšanas laikā notika kļūda.",
            "areNotImages": "tie nav attēli",
            "isNotImage": "nav attēls",
            "toBeUploaded": "kas jāaugšupielādē",
            "cropTool": "Izgriezt",
            "original": "Oriģinālais",
            "custom": "Personalizēts",
            "square": "Kvadrāts",
            "landscape": "Horizontālais",
            "portrait": "Vertikālais",
            "ellipse": "Aplis",
            "classicTv": "Klasiskais TV",
            "cinemascope": "Cinemascope",
            "arrowTool": "Arrow",
            "blurTool": "Blur",
            "brightnessTool": "Spilgtums",
            "contrastTool": "Kontrasts",
            "ellipseTool": "Aplis",
            "unFlipX": "Atcelt X",
            "flipX": "Flip X",
            "unFlipY": "Atcelt Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "Nuance",
            "saturation": "Piesātinājums",
            "value": "Vērtība",
            "imageTool": "Attēls",
            "importing": "Importēšana…",
            "addImage": "+ Pievienot attēlu",
            "uploadImage": "Augšupielādēt attēlu",
            "fromGallery": "Uzlīmes",
            "lineTool": "Līnija",
            "penTool": "Zīmulis",
            "polygonTool": "Poligons",
            "sides": "Sides",
            "rectangleTool": "Taisnstūris",
            "cornerRadius": "Malas rādiuss",
            "resizeWidthTitle": "Platums pikseļos",
            "resizeHeightTitle": "Augstums pikseļos",
            "toggleRatioLockTitle": "Pārslēgt dimensiju bloķēšanu",
            "reset": "Restartējiet",
            "resetSize": "Sākotnējo attēlu izmēru atjaunošana",
            "rotateTool": "Rotējiet",
            "textTool": "Teksts",
            "textSpacings": "Teksta atstatums",
            "textAlignment": "Teksta izlīdzināšana",
            "fontFamily": "Fontu ģimene",
            "size": "Izmērs",
            "letterSpacing": "Burtu atstarpes starp burtiem",
            "lineHeight": "Līnijas augstums",
            "warmthTool": "Siltums",
            "addWatermark": "+ Pievienot attēlu",
            "addWatermarkTitle": "Izvēlieties ūdenszīmes veidu",
            "uploadWatermark": "Atlasiet attēlu",
            "addWatermarkAsText": "Pievienot tekstu",
            "padding": "Polsterējums",
            "shadow": "Ēnu",
            "horizontal": "Horizontālais",
            "vertical": "Vertikālais",
            "blur": "Blur",
            "opacity": "Necaurspīdīgums",
            "position": "Pozīcija",
            "stroke": "Biezums",
            "saveAsModalLabel": "Sīkāka informācija",
            "extension": "Paplašinājums",
            "nameIsRequired": "Nosaukums ir obligāts.",
            "quality": "Kvalitāte",
            "imageDimensionsHoverTitle": "Saglabātā attēla izmēri (platums x augstums)",
            "cropSizeLowerThanResizedWarning": "Ņemiet vērā, ka izvēlētā apgriešanas zona ir mazāka par atbilstošo izmēra maiņu, kas var izraisīt kvalitātes pasliktināšanos.",
            "actualSize": "Pašreizējais izmērs (100%)",
            "fitSize": "Pielāgojiet dimensiju",
            "addImageTitle": "Atlasiet attēlu, ko pievienot…"
        },
        "changing": "Mainot",
        "profile_picture_changed": "Profila attēls mainīts",
        "loading_profile_picture": "Profila attēla ielādēšana",
        "change": "Mainīt",
        "session": "Sesija",
        "system_operative": "Operētājsistēma",
        "navigator": "Pārlūcējs",
        "authorize": "Autorizēt",
        "deauthorize": "Deautorizēt",
        "keep_tag_content": "Vai vēlaties saglabāt birkas saturu?",
        "move_content_to": "Pārvietot saturu uz",
        "move_to": "Pārvietot uz",
        "chat_request": "Tērzēšanas pieprasījums",
        "accept_request": "Pieņemt pieteikumu",
        "go_to_chat": "Iet uz tērzēšanu",
        "applying": "Piemērošana",
        "time": "Laiks",
        "adjust": "Noregulējiet",
        "rotate": "Rotējiet",
        "start": "Sākums",
        "end": "Galīgais",
        "lang_name": "Latvian"
    },
    "lt": {
        "name": "Pavadinimas",
        "name_example": "Jonas",
        "last_name": "Pavardė",
        "last_name_example": "Petrauskas",
        "username": "Vartotojas",
        "username_example": "jonas_petrauskas",
        "email": "El. pašto adresas",
        "email_example": "vartotojas@domeno.com",
        "password": "Slaptažodis",
        "new_password": "Naujas slaptažodis",
        "password_example": "*****************",
        "login": "Prisijungti",
        "register": "Registracija",
        "verification_code": "Patikros kodas",
        "verification_code_example": "xxx",
        "verify": "Patikrinkite",
        "loading": "Pakrovimas…",
        "please_wait": "Prašome palaukti",
        "go_back": "Grįžti į",
        "recovery": "Atkūrimas",
        "login_subtitle": "Įveskite savo duomenis ir prisijunkite prie paskyros.",
        "forgot_password_link": "Pamiršote slaptažodį? Gauti.",
        "register_account_link": "Neturite paskyros? Užsiregistruokite!",
        "register_subtitle": "Įveskite savo duomenis ir užregistruokite paskyrą.",
        "login_link": "Jau turite paskyrą? Prisijunkite!",
        "verification_code_instruction": "Patikrinkite savo el. paštą ir įveskite atsiųstą kodą.",
        "forgot_password_subtitle": "Įveskite savo el. pašto adresą, kad gautumėte slaptažodį.",
        "change_password_subtitle": "Įveskite naują slaptažodį.",
        "change_password_code_used": "Jūsų slaptažodžio atkūrimo kodas negalioja arba jau buvo panaudotas.",
        "no_chats": "Neturite pokalbių.",
        "chats": "Pokalbiai",
        "groups": "Grupės",
        "rooms": "Kambariai",
        "save": "Išsaugoti",
        "download": "Atsisiųsti",
        "downloading_image": "Vaizdo atsisiuntimas",
        "an_error_has_ocurred": "Įvyko klaida",
        "saving_changes": "Pakeitimų išsaugojimas",
        "go_back_to_edit": "Grįžti į redagavimą",
        "upload_warning_modal": "Įkėlus redaguotą vaizdą bus pakeistas originalus vaizdas.",
        "upload_question_modal": "Ar norite pasilikti originalą ir pasidaryti kopiją?",
        "create_copy_and_keep": "Sukurkite kopiją ir saugokite",
        "upload": "Įkelti",
        "upload_changes": "Įkelti pakeitimus",
        "changes_saved": "Išsaugoti pakeitimai",
        "downloading_video": "Vaizdo įrašo atsisiuntimas",
        "video_upload_warning_modal": "Jei įkeliate redaguotą vaizdo įrašą, originalus vaizdo įrašas bus pakeistas.",
        "results": "Rezultatai",
        "members": "Nariai",
        "cancel": "Atšaukti",
        "add_user": "Pridėti naudotoją",
        "no_messages": "Jokių pranešimų",
        "message_placeholder": "Žinutė…",
        "group_doesnt_exists": "Grupė neegzistuoja",
        "you_arent_group_member": "Nesate grupės narys",
        "touch_to_see_more_info": "Bakstelėkite , kad pamatytumėte daugiau informacijos.",
        "start_videocall": "Pradėti vaizdo skambutį",
        "start_call": "Pradėti skambutį",
        "create_group": "Sukurti grupę",
        "group_name": "Grupės pavadinimas",
        "add_members_to_create_group": "Pridėkite narių, kad sukurtumėte grupę",
        "search": "Paieška",
        "creating": "kūrimas…",
        "dont_belong_anyone_group": "Jūs nepriklausote jokiai grupei.",
        "already_add_this_hashtag": "Jau pridėjote šį hashtagą",
        "search_files_by_hashtags": "Failų paieška pagal hashtagus",
        "enter_to_add": "Įveskite, kad pridėtumėte",
        "hashtags_most_used": "Dažniausiai naudojami hashtagai",
        "can_add_it_giving_click": "Juos galite įtraukti į paiešką spustelėję ant jų.",
        "searching_files": "Failų paieška",
        "no_files_found": "Failų nerasta",
        "image": "Vaizdas",
        "video": "Vaizdo įrašas",
        "audio": "Garso",
        "library": "Knygynas",
        "home": "Pagrindinis",
        "block": "Blokas",
        "unblock": "Atblokuoti",
        "has_blocked_you": "Jis jus užblokavo",
        "accept": "Priimti",
        "decline": "Atmesti",
        "cancel_request": "Atšaukti užklausą",
        "has_been_expulsed": "buvo pašalintas",
        "room_doesnt_exists": "Kambarys neegzistuoja",
        "you_arent_room_member": "Nesate kambario narys",
        "create_room": "Sukurti kambarį",
        "room_name": "Kambario pavadinimas",
        "no_rooms_created": "Nėra sukurtų kambarių.",
        "dont_belong_to_anyone_room": "Nepriklausote jokiam kambariui.",
        "enter_a_username": "Parašykite naudotojo",
        "no_results": "Rezultatų nėra",
        "create": "Sukurti",
        "no_content_uploaded": "Nėra įkelto turinio",
        "new_subtag": "Nauja subžyma",
        "update_info": "Atnaujinti informaciją",
        "updating": "atnaujinimas",
        "update": "Atnaujinti",
        "update_email": "Atnaujinti el. paštą",
        "change_password": "Pakeisti slaptažodį",
        "current_password": "Dabartinis slaptažodis",
        "select": "Pasirinkite",
        "tap_to_upload_a_file": "Bakstelėkite , jei norite įkelti failą",
        "edit_photo": "Redaguoti nuotrauką",
        "preview": "Išankstinė peržiūra",
        "edit_video": "Redaguoti vaizdo įrašą",
        "delete_changes": "Ištrinti pakeitimus",
        "uploading": "Įkėlimas",
        "session_deleted_or_expired": "Ištrinta arba pasibaigusi sesija",
        "edit_profile": "Redaguoti profilį",
        "devices": "Prietaisai",
        "downloads": "Atsisiuntimai",
        "logout": "Atsijungti",
        "offline": "Neprisijungęs",
        "images": "Vaizdai",
        "videos": "Vaizdo įrašai",
        "audios": "Audios",
        "chat": "Pokalbiai",
        "session_unauthorized": "Jūsų sesija neautorizuota.",
        "authorize_session": "Kad galėtumėte naudotis šia sesija, turite autorizuoti savo prietaisą.",
        "authorize_instructions": "El. laiške ieškokite autorizavimo nuorodos arba eikite į aktyvią sesiją ir viršutiniame dešiniajame kampe pasirinkite tris taškus, tada eikite į Įrenginiai ir pasirinkite Autorizuoti.",
        "refresh_page_after_authorize": "Kai autorizavimo procesas bus baigtas, atnaujinkite prisijungimo puslapį.",
        "check_your_inbox": "Patikrinkite savo el. paštą",
        "send_authorization_email": "Siųsti autorizacijos el. laišką",
        "call_finished": "Skambutis baigtas",
        "close": "Uždaryti",
        "waiting_stream": "Laukiama, kol bus perduota",
        "request_camera_and_mic": "Mums reikia prieigos prie kameros ir mikrofono, kad galėtumėte skambinti.",
        "call_in_progress": "Vykdomas skambutis",
        "group": "Grupė",
        "videocall": "Vaizdo skambutis",
        "call": "Skambinkite",
        "connecting": "Prijungimas",
        "call_cancelled": "Skambutis atšauktas",
        "calling": "Skambutis",
        "hangup": "Pakabinti",
        "reject": "Atmesti",
        "has_sent_a_file": "išsiuntė failą.",
        "keep": "Išsaugoti",
        "keep_file": "Jei įjungsite failo išsaugojimo parinktį, failas bus išsaugotas programoje. Taip galėsite pasiekti failą be interneto ryšio.",
        "not_keep_file": "Jei išjungsite išsaugojimo parinktį, failas bus ištrintas ir negalėsite jo pasiekti be interneto ryšio.",
        "file_stored_instruction": "Bendrajame failų sąraše visi išsaugoti failai bus rodomi su žaliu apvadu. Juos taip pat galite peržiūrėti",
        "stored": "Saugoma",
        "not_stored": "Neišsaugota",
        "file_not_stored_instruction": "Šio failo negalėsite pasiekti be interneto ryšio.",
        "device": "Prietaisas",
        "file_visibility": "Archyvo matomumas",
        "visible_for_everyone": "Visiems matomas",
        "shared_link": "Bendrai naudojama nuoroda",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Žymos hashtags padeda ieškoti failų.",
        "edit": "Redaguoti",
        "move": "Perkelti",
        "rename": "Pervadinti",
        "visibility": "Matomumas",
        "delete": "Ištrinti",
        "file_warn_delete": "Ar tikrai norite ištrinti failą?",
        "no_subtags_availables": "Nėra subžymenų",
        "owner": "Kūrėjas",
        "expulsing": "Išvarymas",
        "expulse": "Išvaryti",
        "you_have_left_the_group": "Pasitraukėte iš grupės",
        "coming_out": "Palikimas",
        "leave_group": "Palikite grupę",
        "you_have_left_the_room": "Jūs išėjote iš kambario.",
        "leave_room": "Išėjimas iš kambario",
        "message_deleted": "Ištrinta žinutė",
        "has_been_error_deleting_message": "Trinant pranešimą įvyko klaida",
        "deleting": "Pašalinti",
        "new_tag": "Nauja žyma",
        "photoEditor": {
            "name": "Pavadinimas",
            "save": "Išsaugoti",
            "saveAs": "Išsaugoti kaip",
            "back": "Atgal",
            "loading": "Pakrovimas…",
            "resetOperations": "Atstatyti / ištrinti visas operacijas.",
            "changesLoseConfirmation": "Visi pakeitimai bus ištrinti.",
            "changesLoseConfirmationHint": "Ar tikrai norite tęsti?",
            "cancel": "Atšaukti",
            "continue": "Toliau",
            "undoTitle": "Paskutinės operacijos atšaukimas",
            "redoTitle": "Iš naujo atlikti paskutinę operaciją",
            "showImageTitle": "Rodyti originalų vaizdą",
            "zoomInTitle": "Padidinti mastelį",
            "zoomOutTitle": "Sumažinti mastelį",
            "toggleZoomMenuTitle": "Perjungti priartinimo meniu",
            "adjustTab": "Sureguliuokite",
            "finetuneTab": "Spalvos",
            "filtersTab": "Filtrai",
            "watermarkTab": "Vaizdai",
            "annotateTab": "Piešimas",
            "resize": "Pakeisti dydį",
            "resizeTab": "Dydis",
            "invalidImageError": "Neteisingas vaizdas",
            "uploadImageError": "Įkeliant vaizdą įvyko klaida.",
            "areNotImages": "jie nėra vaizdai",
            "isNotImage": "nėra atvaizdas",
            "toBeUploaded": "turi būti įkelta",
            "cropTool": "Iškirpti",
            "original": "Originalus",
            "custom": "Personalizuotas",
            "square": "Kvadratinis",
            "landscape": "Horizontalus",
            "portrait": "Vertikalus",
            "ellipse": "Apskritimas",
            "classicTv": "Klasikinė televizija",
            "cinemascope": "Cinemascope",
            "arrowTool": "Strėlė",
            "blurTool": "Neryškumas",
            "brightnessTool": "Ryškumas",
            "contrastTool": "Kontrastas",
            "ellipseTool": "Apskritimas",
            "unFlipX": "Atversti X",
            "flipX": "Apverskite X",
            "unFlipY": "Atversti Y",
            "flipY": "Apverskite Y",
            "hsvTool": "MSV",
            "hue": "Nuance",
            "saturation": "Sotumas",
            "value": "Vertė",
            "imageTool": "Vaizdas",
            "importing": "Importuoti…",
            "addImage": "+ Pridėti paveikslėlį",
            "uploadImage": "Įkelti paveikslėlį",
            "fromGallery": "Lipdukai",
            "lineTool": "Linija",
            "penTool": "Pieštukas",
            "polygonTool": "Poligonas",
            "sides": "Šoninės pusės",
            "rectangleTool": "Stačiakampis",
            "cornerRadius": "Kraštų spindulys",
            "resizeWidthTitle": "Plotis pikseliais",
            "resizeHeightTitle": "Aukštis pikseliais",
            "toggleRatioLockTitle": "Perjungti matmenų fiksavimą",
            "reset": "Iš naujo paleiskite",
            "resetSize": "Atkurti originalius vaizdo matmenis",
            "rotateTool": "Pasukite",
            "textTool": "Tekstas",
            "textSpacings": "Teksto tarpai",
            "textAlignment": "Teksto lygiuotė",
            "fontFamily": "Šriftų šeima",
            "size": "Dydis",
            "letterSpacing": "Atstumai tarp raidžių",
            "lineHeight": "Linijos aukštis",
            "warmthTool": "Šiluma",
            "addWatermark": "+ Pridėti paveikslėlį",
            "addWatermarkTitle": "Pasirinkite vandens ženklo tipą",
            "uploadWatermark": "Pasirinkite vaizdą",
            "addWatermarkAsText": "Pridėti tekstą",
            "padding": "Pripildymas",
            "shadow": "Šešėlis",
            "horizontal": "Horizontalus",
            "vertical": "Vertikalus",
            "blur": "Neryškumas",
            "opacity": "Neskaidrumas",
            "position": "Pozicija",
            "stroke": "Storis",
            "saveAsModalLabel": "Išsamiau",
            "extension": "Pratęsimas",
            "nameIsRequired": "Pavadinimas yra privalomas.",
            "quality": "Kokybė",
            "imageDimensionsHoverTitle": "Išsaugoto vaizdo matmenys (plotis x aukštis)",
            "cropSizeLowerThanResizedWarning": "Atkreipkite dėmesį, kad pasirinkta apkarpymo sritis yra mažesnė už taikomą dydžio keitimą, todėl gali sumažėti kokybė.",
            "actualSize": "Dabartinis dydis (100%)",
            "fitSize": "Pritaikyti matmenį",
            "addImageTitle": "Pasirinkite norimą pridėti paveikslėlį…"
        },
        "changing": "Keitimas",
        "profile_picture_changed": "Pakeista profilio nuotrauka",
        "loading_profile_picture": "Įkeliama profilio nuotrauka",
        "change": "Keisti",
        "session": "Sesija",
        "system_operative": "Operacinė sistema",
        "navigator": "Naršyklė",
        "authorize": "Autorizuoti",
        "deauthorize": "Deautorizacijos panaikinimas",
        "keep_tag_content": "Ar norite išsaugoti žymos turinį?",
        "move_content_to": "Perkelkite turinį į",
        "move_to": "Persikelti į",
        "chat_request": "Pokalbių užklausa",
        "accept_request": "Priimti paraišką",
        "go_to_chat": "Eikite į pokalbių svetainę",
        "applying": "Taikymas",
        "time": "Orai",
        "adjust": "Sureguliuokite",
        "rotate": "Pasukite",
        "start": "Pradėti",
        "end": "Pabaiga",
        "lang_name": "Lithuanian"
    },
    "mk": {
        "name": "Име",
        "name_example": "Nikola",
        "last_name": "Презиме",
        "last_name_example": "Stojanovski",
        "username": "Корисник",
        "username_example": "nikola_stojanovski",
        "email": "Е-пошта",
        "email_example": "корисник@домен.com",
        "password": "Лозинка",
        "new_password": "нова лозинка",
        "password_example": "*****************",
        "login": "Логирај Се",
        "register": "Регистрирајте се",
        "verification_code": "Код за верификација",
        "verification_code_example": "xxx",
        "verify": "Потврди",
        "loading": "Полнење…",
        "please_wait": "Ве молам почекајте",
        "go_back": "Врати се",
        "recovery": "Закрепнување",
        "login_subtitle": "Внесете ги вашите податоци и пристапете до вашата сметка.",
        "forgot_password_link": "Дали ја заборавивте лозинката? Закрепнете.",
        "register_account_link": "Немате сметка? Регистрирајте се!",
        "register_subtitle": "Внесете ги вашите податоци и регистрирајте ја вашата сметка.",
        "login_link": "Дали веќе имате сметка? Пристап!",
        "verification_code_instruction": "Проверете ја вашата е-пошта и внесете го испратениот код.",
        "forgot_password_subtitle": "Внесете ја вашата е-пошта за да ја вратите лозинката.",
        "change_password_subtitle": "Внесете нова лозинка.",
        "change_password_code_used": "Кодот за враќање на лозинката е неважечки или веќе е користен.",
        "no_chats": "Вие немате разговори.",
        "chats": "Разговори",
        "groups": "Групи",
        "rooms": "Соби",
        "save": "Зачувај",
        "download": "Испуштање",
        "downloading_image": "Се презема сликата",
        "an_error_has_ocurred": "Се појави грешка",
        "saving_changes": "Зачувување на промените",
        "go_back_to_edit": "Уреди повторно",
        "upload_warning_modal": "Уредувањето ќе ја замени оригиналната слика.",
        "upload_question_modal": "Дали сакате да го задржите оригиналот и да создадете копија?",
        "create_copy_and_keep": "Направете копија и зачувајте ја.",
        "upload": "Поставете",
        "upload_changes": "Поставете промени",
        "changes_saved": "Промените се зачувани",
        "downloading_video": "Се презема видео",
        "video_upload_warning_modal": "Поставувањето на уредувањето ќе го замени оригиналното видео.",
        "results": "Резултати",
        "members": "Членови",
        "cancel": "Откажи",
        "add_user": "Додадете корисник",
        "no_messages": "Нема пораки",
        "message_placeholder": "Порака…",
        "group_doesnt_exists": "Групата не постои",
        "you_arent_group_member": "Вие не сте член на групата",
        "touch_to_see_more_info": "Допрете за да видите повеќе информации.",
        "start_videocall": "Започнете видео повик",
        "start_call": "Започнете со повик",
        "create_group": "Креирај група",
        "group_name": "Име на групата",
        "add_members_to_create_group": "Додајте членови за да ја креирате групата.",
        "search": "Бара",
        "creating": "Создавање…",
        "dont_belong_anyone_group": "Вие не припаѓате на ниту една група.",
        "already_add_this_hashtag": "Веќе го додадовте тој хаштаг",
        "search_files_by_hashtags": "Пребарувајте датотеки со хаштагови",
        "enter_to_add": "Внесете за да додадете",
        "hashtags_most_used": "Најкористени хаштагови",
        "can_add_it_giving_click": "Можете да ги додадете во вашето пребарување со кликнување на нив",
        "searching_files": "Пребарување датотеки",
        "no_files_found": "Не се пронајдени датотеки",
        "image": "Слика",
        "video": "Видео",
        "audio": "Аудио",
        "library": "Книжарница",
        "home": "Почетна страница",
        "block": "Блокирај",
        "unblock": "отклучување",
        "has_blocked_you": "Ве блокираше",
        "accept": "Прифати",
        "decline": "Одбивање",
        "cancel_request": "Откажи барање",
        "has_been_expulsed": "е протеран",
        "room_doesnt_exists": "Собата не постои",
        "you_arent_room_member": "Вие не сте член на собата",
        "create_room": "Создадете соба",
        "room_name": "Име на собата",
        "no_rooms_created": "Нема создадени соби.",
        "dont_belong_to_anyone_room": "Вие не припаѓате во ниту една просторија.",
        "enter_a_username": "Напишете корисник",
        "no_results": "Нема резултати",
        "create": "Креирај",
        "no_content_uploaded": "Не е поставена содржина",
        "new_subtag": "Нова подознака",
        "update_info": "Ажурирајте ги информациите",
        "updating": "Ажурирање",
        "update": "Ажурирање",
        "update_email": "Ажурирајте ја е-поштата",
        "change_password": "Промени го пасвордот",
        "current_password": "Сегашна лозинка",
        "select": "Изберете",
        "tap_to_upload_a_file": "Допрете за да поставите датотека",
        "edit_photo": "Уредете фотографија",
        "preview": "Преглед",
        "edit_video": "Уреди видео",
        "delete_changes": "Избришете ги промените",
        "uploading": "Се прикачува",
        "session_deleted_or_expired": "Сесијата е избришана или истечена.",
        "edit_profile": "Уреди го профилот",
        "devices": "Уреди",
        "downloads": "преземања",
        "logout": "Одјави се",
        "offline": "Без врска",
        "images": "Слики",
        "videos": "Видеа",
        "audios": "Аудио",
        "chat": "Разговор",
        "session_unauthorized": "Вашата сесија не е овластена.",
        "authorize_session": "За да пристапите до оваа сесија, треба да го овластите вашиот уред.",
        "authorize_instructions": "Проверете ја вашата е-пошта за врската за овластување или одете на активна сесија и изберете ги трите точки во горниот десен агол, потоа одете во „Измени“ и изберете „Овласти“.",
        "refresh_page_after_authorize": "Откако ќе го завршите процесот на овластување, освежете ја страницата за да пристапите до сесијата.",
        "check_your_inbox": "Проверете ја вашата е-пошта",
        "send_authorization_email": "Испратете е-пошта за авторизација",
        "call_finished": "Повикот заврши",
        "close": "Затвори",
        "waiting_stream": "Се чека пренос",
        "request_camera_and_mic": "Ни треба пристап до камерата и микрофонот за да остваруваме повици.",
        "call_in_progress": "Повикот е во тек",
        "group": "Грозд",
        "videocall": "Видео повик",
        "call": "Јавете се",
        "connecting": "Поврзување",
        "call_cancelled": "Повикот е откажан",
        "calling": "Повикувајќи се",
        "hangup": "Спушти слушалка",
        "reject": "Одбивање",
        "has_sent_a_file": "испрати датотека.",
        "keep": "Задржи",
        "keep_file": "Ако овозможите зачувување на датотеки, тие ќе бидат зачувани во апликацијата. Ова ќе овозможи негова пристапност без потреба од интернет конекција.",
        "not_keep_file": "Ако го оневозможите зачувувањето на датотеката, таа ќе биде избришана и нема да можете да пристапите до неа без интернет конекција.",
        "file_stored_instruction": "Сите зачувани датотеки ќе се појават со зелена граница во списокот со споделени датотеки. Можете исто така да ги видите во делот",
        "stored": "Складирано",
        "not_stored": "Не се складира",
        "file_not_stored_instruction": "Нема да можете да пристапите до оваа датотека без интернет конекција.",
        "device": "Уред",
        "file_visibility": "Видливост на датотека",
        "visible_for_everyone": "Видливи за секого",
        "shared_link": "Споделена врска",
        "hashtags": "Хаштагови",
        "hashtags_helps_to_search_your_file": "Хаштаговите ви помагаат да ги пронајдете вашите датотеки.",
        "edit": "Уредување",
        "move": "Премести",
        "rename": "Преименувај",
        "visibility": "Видливост",
        "delete": "Елиминирајте",
        "file_warn_delete": "Дали сте сигурни дека сакате да ја избришете датотеката?",
        "no_subtags_availables": "Нема достапни подознаки",
        "owner": "Создател",
        "expulsing": "протерување",
        "expulse": "Избркајте",
        "you_have_left_the_group": "Ја напуштивте групата",
        "coming_out": "Излегува",
        "leave_group": "Напуштете ја групата",
        "you_have_left_the_room": "Ја напуштивте собата.",
        "leave_room": "Напуштете ја собата",
        "message_deleted": "Избришана порака",
        "has_been_error_deleting_message": "Се појави грешка при бришењето на пораката.",
        "deleting": "Бришење",
        "new_tag": "Нова ознака",
        "photoEditor": {
            "name": "Име",
            "save": "Зачувај",
            "saveAs": "Зачувај како",
            "back": "Назад",
            "loading": "Полнење…",
            "resetOperations": "Рестартирајте/Избришете ги сите операции.",
            "changesLoseConfirmation": "Сите промени ќе бидат отстранети.",
            "changesLoseConfirmationHint": "Дали сте сигурни дека сакате да продолжите?",
            "cancel": "Откажи",
            "continue": "Продолжи",
            "undoTitle": "Врати ја последната операција",
            "redoTitle": "Повторете ја последната операција",
            "showImageTitle": "Прикажи оригинална слика",
            "zoomInTitle": "Зумирај",
            "zoomOutTitle": "Одзумирај",
            "toggleZoomMenuTitle": "Вклучете го менито за зумирање",
            "adjustTab": "Прилагодете се",
            "finetuneTab": "Бои",
            "filtersTab": "Филтри",
            "watermarkTab": "Слики",
            "annotateTab": "Нацртај",
            "resize": "Променете ја големината",
            "resizeTab": "Големина",
            "invalidImageError": "Неважечка слика",
            "uploadImageError": "Се појави грешка при вчитување на сликата.",
            "areNotImages": "Тие не се слики",
            "isNotImage": "тоа не е слика",
            "toBeUploaded": "да се вчита",
            "cropTool": "Исечете",
            "original": "Оригинален",
            "custom": "Персонализирано",
            "square": "Плоштад",
            "landscape": "Хоризонтална",
            "portrait": "Вертикално",
            "ellipse": "Заокружете",
            "classicTv": "класична телевизија",
            "cinemascope": "Киноскоп",
            "arrowTool": "Стрелка",
            "blurTool": "Заматено",
            "brightnessTool": "Осветленост",
            "contrastTool": "Контраст",
            "ellipseTool": "Заокружете",
            "unFlipX": "Отфрли X",
            "flipX": "Превртете X",
            "unFlipY": "Отфрли Y",
            "flipY": "Превртете Y",
            "hsvTool": "MSV",
            "hue": "Нијанса",
            "saturation": "Заситеност",
            "value": "Вреди",
            "imageTool": "Слика",
            "importing": "Увозот…",
            "addImage": "+ Додај слика",
            "uploadImage": "Поставете слика",
            "fromGallery": "Налепници",
            "lineTool": "Линија",
            "penTool": "Молив",
            "polygonTool": "Многуаголник",
            "sides": "Страни",
            "rectangleTool": "Правоаголник",
            "cornerRadius": "Радиус на рабовите",
            "resizeWidthTitle": "Ширина во пиксели",
            "resizeHeightTitle": "Висина на пиксели",
            "toggleRatioLockTitle": "Вклучи заклучување со димензии",
            "reset": "Рестарт",
            "resetSize": "Ресетирајте ја сликата на нејзините оригинални димензии",
            "rotateTool": "Ротирај",
            "textTool": "Текст",
            "textSpacings": "Проред на текстот",
            "textAlignment": "Порамнување на текстот",
            "fontFamily": "Семејство на фонтови",
            "size": "Големина",
            "letterSpacing": "Растојание на буквите",
            "lineHeight": "Висина на линијата",
            "warmthTool": "Топлина",
            "addWatermark": "+ Додај слика",
            "addWatermarkTitle": "Изберете го типот на воден жиг",
            "uploadWatermark": "Изберете слика",
            "addWatermarkAsText": "Додадете текст",
            "padding": "Полнети",
            "shadow": "Сенка",
            "horizontal": "Хоризонтална",
            "vertical": "Вертикално",
            "blur": "Заматено",
            "opacity": "Непроѕирност",
            "position": "Позиција",
            "stroke": "Дебелина",
            "saveAsModalLabel": "Детали",
            "extension": "Продолжување",
            "nameIsRequired": "Името е задолжително.",
            "quality": "Квалитет",
            "imageDimensionsHoverTitle": "Димензии на зачувани слики (ширина x висина)",
            "cropSizeLowerThanResizedWarning": "Ве молиме имајте предвид дека избраната површина за сечење е помала од применетата промена на големината, што може да предизвика намалување на квалитетот.",
            "actualSize": "Тековна големина (100%)",
            "fitSize": "Прилагодете ја димензијата",
            "addImageTitle": "Изберете слика за додавање…"
        },
        "changing": "Се менува",
        "profile_picture_changed": "Фотографијата на профилот е променета",
        "loading_profile_picture": "Се вчитува фотографијата на профилот",
        "change": "Промена",
        "session": "Сесија",
        "system_operative": "ОС",
        "navigator": "Прелистувач",
        "authorize": "Овластете",
        "deauthorize": "Отфрли",
        "keep_tag_content": "Дали сакате да ја зачувате содржината на ознаката?",
        "move_content_to": "Преместете ја содржината во",
        "move_to": "Премести во",
        "chat_request": "Барање за разговор",
        "accept_request": "Прифатете го барањето",
        "go_to_chat": "Одете на разговор",
        "applying": "Пријавување",
        "time": "Време",
        "adjust": "Прилагодете се",
        "rotate": "Ротирај",
        "start": "старт",
        "end": "Крај",
        "lang_name": "Macedonian"
    },
    "ml": {
        "name": "പേര്",
        "name_example": "Mani",
        "last_name": "പേരിന്റെ അവസാന ഭാഗം",
        "last_name_example": "Kumar",
        "username": "ഉപയോക്താവ്",
        "username_example": "mani_kumar",
        "email": "ഇമെയിൽ",
        "email_example": "ഉപയോക്താവ്@ഡൊമെയ്ൻ.com",
        "password": "പാസ്വേഡ്",
        "new_password": "പുതിയ പാസ്വേഡ്",
        "password_example": "*****************",
        "login": "ലോഗിൻ",
        "register": "രജിസ്റ്റർ ചെയ്യുക",
        "verification_code": "പരിശോധിച്ചുറപ്പിക്കൽ കോഡ്",
        "verification_code_example": "xxx",
        "verify": "സ്ഥിരീകരിക്കുക",
        "loading": "ചാർജിംഗ്…",
        "please_wait": "കാത്തിരിക്കൂ",
        "go_back": "മടങ്ങുക",
        "recovery": "വീണ്ടെടുക്കുക",
        "login_subtitle": "നിങ്ങളുടെ വിശദാംശങ്ങൾ നൽകി നിങ്ങളുടെ അക്കൗണ്ട് ആക്‌സസ് ചെയ്യുക.",
        "forgot_password_link": "നിങ്ങളുടെ പാസ്‌വേഡ് മറന്നോ? വീണ്ടെടുക്കുക.",
        "register_account_link": "നിങ്ങൾക്ക് ഒരു അക്കൗണ്ട് ഇല്ലേ? രജിസ്റ്റർ ചെയ്യുക!",
        "register_subtitle": "നിങ്ങളുടെ വിശദാംശങ്ങൾ നൽകി നിങ്ങളുടെ അക്കൗണ്ട് രജിസ്റ്റർ ചെയ്യുക.",
        "login_link": "നിങ്ങൾക്ക് ഇതിനകം ഒരു അക്കൗണ്ട് ഉണ്ടോ? പ്രവേശനം!",
        "verification_code_instruction": "നിങ്ങളുടെ ഇമെയിൽ പരിശോധിച്ച് അയച്ച കോഡ് നൽകുക.",
        "forgot_password_subtitle": "നിങ്ങളുടെ പാസ്‌വേഡ് വീണ്ടെടുക്കാൻ നിങ്ങളുടെ ഇമെയിൽ നൽകുക.",
        "change_password_subtitle": "ഒരു പുതിയ പാസ്സ്വേർഡ് നൽകുക.",
        "change_password_code_used": "നിങ്ങളുടെ പാസ്‌വേഡ് വീണ്ടെടുക്കൽ കോഡ് അസാധുവാണ് അല്ലെങ്കിൽ ഇതിനകം ഉപയോഗിച്ചിട്ടുണ്ട്.",
        "no_chats": "നിങ്ങൾക്ക് സംഭാഷണങ്ങളില്ല.",
        "chats": "സംഭാഷണങ്ങൾ",
        "groups": "ഗ്രൂപ്പുകൾ",
        "rooms": "മുറികൾ",
        "save": "സൂക്ഷിക്കുക",
        "download": "ഡിസ്ചാർജ്",
        "downloading_image": "ചിത്രം ഡൗൺലോഡ് ചെയ്യുന്നു",
        "an_error_has_ocurred": "ഒരു പിശക് സംഭവിച്ചു",
        "saving_changes": "മാറ്റങ്ങൾ സംരക്ഷിക്കുന്നു",
        "go_back_to_edit": "വീണ്ടും തിരുത്തുക",
        "upload_warning_modal": "എഡിറ്റ് അപ്‌ലോഡ് ചെയ്യുന്നത് യഥാർത്ഥ ചിത്രത്തെ മാറ്റിസ്ഥാപിക്കും.",
        "upload_question_modal": "നിങ്ങൾക്ക് ഒറിജിനൽ സൂക്ഷിച്ച് ഒരു പകർപ്പ് സൃഷ്ടിക്കണോ?",
        "create_copy_and_keep": "പകർപ്പ് സൃഷ്ടിച്ച് സൂക്ഷിക്കുക",
        "upload": "അപ്‌ലോഡ് ചെയ്യുക",
        "upload_changes": "മാറ്റങ്ങൾ അപ്‌ലോഡ് ചെയ്യുക",
        "changes_saved": "മാറ്റങ്ങൾ സംരക്ഷിച്ചു",
        "downloading_video": "വീഡിയോ ഡൗൺലോഡ് ചെയ്യുന്നു",
        "video_upload_warning_modal": "എഡിറ്റ് അപ്‌ലോഡ് ചെയ്യുന്നത് യഥാർത്ഥ വീഡിയോയെ മാറ്റിസ്ഥാപിക്കും.",
        "results": "ഫലം",
        "members": "അംഗങ്ങൾ",
        "cancel": "റദ്ദാക്കുക",
        "add_user": "ഉപയോക്താവിനെ ചേർക്കുക",
        "no_messages": "സന്ദേശങ്ങളൊന്നുമില്ല",
        "message_placeholder": "സന്ദേശം…",
        "group_doesnt_exists": "ഗ്രൂപ്പ് നിലവിലില്ല",
        "you_arent_group_member": "നിങ്ങൾ ഗ്രൂപ്പിൽ അംഗമല്ല",
        "touch_to_see_more_info": "കൂടുതൽ വിവരങ്ങൾ കാണാൻ ടാപ്പ് ചെയ്യുക.",
        "start_videocall": "വീഡിയോ കോൾ ആരംഭിക്കുക",
        "start_call": "കോൾ ആരംഭിക്കുക",
        "create_group": "ഗ്രൂപ്പ് സൃഷ്ടിക്കുക",
        "group_name": "ഗ്രൂപ്പ് പേര്",
        "add_members_to_create_group": "ഗ്രൂപ്പ് സൃഷ്ടിക്കാൻ അംഗങ്ങളെ ചേർക്കുക",
        "search": "ഇതിനായി തിരയുന്നു",
        "creating": "ഉണ്ടാക്കുന്നു…",
        "dont_belong_anyone_group": "നിങ്ങൾ ഒരു ഗ്രൂപ്പിലും ഉൾപ്പെടുന്നില്ല.",
        "already_add_this_hashtag": "നിങ്ങൾ ഇതിനകം ആ ഹാഷ്‌ടാഗ് ചേർത്തിട്ടുണ്ട്",
        "search_files_by_hashtags": "ഹാഷ്‌ടാഗുകൾ ഉപയോഗിച്ച് ഫയലുകൾ തിരയുക",
        "enter_to_add": "ചേർക്കാൻ നൽകുക",
        "hashtags_most_used": "ഏറ്റവും കൂടുതൽ ഉപയോഗിക്കുന്ന ഹാഷ് ടാഗുകൾ",
        "can_add_it_giving_click": "അവയിൽ ക്ലിക്ക് ചെയ്‌ത് നിങ്ങളുടെ തിരയലിലേക്ക് അവയെ ചേർക്കാം.",
        "searching_files": "ഫയലുകൾ തിരയുന്നു",
        "no_files_found": "ഫയലുകളൊന്നും കണ്ടെത്തിയില്ല",
        "image": "ചിത്രം",
        "video": "വീഡിയോ",
        "audio": "ഓഡിയോ",
        "library": "പുസ്തകശാല",
        "home": "ഹോം പേജ്",
        "block": "തടയുക",
        "unblock": "തുറക്കാൻ",
        "has_blocked_you": "അവൻ നിങ്ങളെ തടഞ്ഞു",
        "accept": "സ്വീകരിക്കുക",
        "decline": "നിരസിക്കുക",
        "cancel_request": "അഭ്യർത്ഥന റദ്ദാക്കുക",
        "has_been_expulsed": "പുറത്താക്കിയിട്ടുണ്ട്",
        "room_doesnt_exists": "മുറി നിലവിലില്ല",
        "you_arent_room_member": "നിങ്ങൾ മുറിയിലെ അംഗമല്ല",
        "create_room": "മുറി ഉണ്ടാക്കുക",
        "room_name": "മുറിയുടെ പേര്",
        "no_rooms_created": "മുറികളൊന്നും സൃഷ്ടിച്ചിട്ടില്ല.",
        "dont_belong_to_anyone_room": "നിങ്ങൾ ഒരു മുറിയിലും ഉൾപ്പെടുന്നില്ല.",
        "enter_a_username": "ഒരു ഉപയോക്താവിനെ എഴുതുക",
        "no_results": "ഫലങ്ങളൊന്നുമില്ല",
        "create": "സൃഷ്ടിക്കാൻ",
        "no_content_uploaded": "ഉള്ളടക്കമൊന്നും അപ്‌ലോഡ് ചെയ്‌തിട്ടില്ല",
        "new_subtag": "പുതിയ ഉപടാഗ്",
        "update_info": "വിവരങ്ങൾ അപ്ഡേറ്റ് ചെയ്യുക",
        "updating": "അപ്ഡേറ്റ് ചെയ്യുന്നു",
        "update": "അപ്ഡേറ്റ് ചെയ്യുക",
        "update_email": "ഇമെയിൽ അപ്ഡേറ്റ് ചെയ്യുക",
        "change_password": "പാസ്വേഡ് മാറ്റുക",
        "current_password": "നിലവിലെ പാസ്വേഡ്",
        "select": "തിരഞ്ഞെടുക്കുക",
        "tap_to_upload_a_file": "ഒരു ഫയൽ അപ്‌ലോഡ് ചെയ്യാൻ ടാപ്പ് ചെയ്യുക",
        "edit_photo": "ഫോട്ടോ എഡിറ്റ് ചെയ്യുക",
        "preview": "പ്രിവ്യൂ",
        "edit_video": "വീഡിയോ എഡിറ്റ് ചെയ്യുക",
        "delete_changes": "മാറ്റങ്ങൾ ഇല്ലാതാക്കുക",
        "uploading": "മുകളിലേക്ക് പോകുന്നു",
        "session_deleted_or_expired": "സെഷൻ ഇല്ലാതാക്കി അല്ലെങ്കിൽ കാലഹരണപ്പെട്ടു",
        "edit_profile": "പ്രൊഫൈൽ എഡിറ്റ് ചെയ്യുക",
        "devices": "ഉപകരണങ്ങൾ",
        "downloads": "ഡൗൺലോഡുകൾ",
        "logout": "സൈൻ ഓഫ്",
        "offline": "കണക്ഷൻ ഇല്ലാതെ",
        "images": "ചിത്രങ്ങൾ",
        "videos": "വീഡിയോകൾ",
        "audios": "ഓഡിയോകൾ",
        "chat": "ചാറ്റ്",
        "session_unauthorized": "നിങ്ങളുടെ സെഷൻ അംഗീകൃതമല്ല.",
        "authorize_session": "ഈ സെഷൻ ആക്സസ് ചെയ്യുന്നതിന്, നിങ്ങളുടെ ഉപകരണത്തിന് അംഗീകാരം നൽകേണ്ടതുണ്ട്.",
        "authorize_instructions": "അംഗീകാര ലിങ്കിനായി നിങ്ങളുടെ ഇമെയിൽ പരിശോധിക്കുക അല്ലെങ്കിൽ ഒരു സജീവ സെഷനിലേക്ക് പോയി മുകളിൽ വലത് കോണിലുള്ള മൂന്ന് ഡോട്ടുകൾ തിരഞ്ഞെടുക്കുക, തുടർന്ന് \"ടൂളുകൾ\" എന്നതിലേക്ക് പോയി \"അംഗീകാരം നൽകുക\" തിരഞ്ഞെടുക്കുക.",
        "refresh_page_after_authorize": "നിങ്ങൾ അംഗീകാര പ്രക്രിയ പൂർത്തിയാക്കിക്കഴിഞ്ഞാൽ, സെഷനിലേക്ക് പ്രവേശിക്കാൻ പേജ് പുതുക്കുക.",
        "check_your_inbox": "നിങ്ങളുടെ ഇമെയിൽ പരിശോധിക്കുക",
        "send_authorization_email": "അംഗീകാര ഇമെയിൽ അയയ്ക്കുക",
        "call_finished": "കോൾ അവസാനിച്ചു",
        "close": "അടയ്ക്കുക",
        "waiting_stream": "സംപ്രേഷണത്തിനായി കാത്തിരിക്കുന്നു",
        "request_camera_and_mic": "ഞങ്ങൾക്ക് നിങ്ങളുടെ ക്യാമറയിലേക്കും മൈക്രോഫോണിലേക്കും ആക്‌സസ് ആവശ്യമാണ്, അതിനാൽ നിങ്ങൾക്ക് കോളുകൾ ചെയ്യാം.",
        "call_in_progress": "കോൾ പുരോഗമിക്കുന്നു",
        "group": "ക്ലസ്റ്റർ",
        "videocall": "വീഡിയോ കോൾ",
        "call": "വിളി",
        "connecting": "ബന്ധിപ്പിക്കുന്നു",
        "call_cancelled": "കോൾ റദ്ദാക്കി",
        "calling": "വിളിക്കുന്നു",
        "hangup": "തൂക്കിയിടുക",
        "reject": "നിരസിക്കുക",
        "has_sent_a_file": "ഒരു ഫയൽ അയച്ചു.",
        "keep": "സൂക്ഷിക്കുക",
        "keep_file": "ഫയൽ സേവിംഗ് പ്രവർത്തനക്ഷമമാക്കിയാൽ, അത് ആപ്പിൽ സംരക്ഷിക്കപ്പെടും. ഇത് ഇന്റർനെറ്റ് കണക്ഷന്റെ ആവശ്യമില്ലാതെ തന്നെ അതിന്റെ പ്രവേശനക്ഷമത അനുവദിക്കും.",
        "not_keep_file": "നിങ്ങൾ ഫയൽ സംഭരണം ഓഫാക്കിയാൽ, അത് ഇല്ലാതാക്കപ്പെടും, ഇന്റർനെറ്റ് കണക്ഷൻ ഇല്ലാതെ നിങ്ങൾക്ക് അത് ആക്സസ് ചെയ്യാൻ കഴിയില്ല.",
        "file_stored_instruction": "സംഭരിച്ചിരിക്കുന്ന എല്ലാ ഫയലുകളും പച്ച ബോർഡറുള്ള പൊതുവായ ഫയൽ ലിസ്റ്റിൽ ദൃശ്യമാകും. വിഭാഗത്തിലും കാണാം",
        "stored": "സംഭരിച്ചു",
        "not_stored": "ഇത് സൂക്ഷിച്ചിട്ടില്ല",
        "file_not_stored_instruction": "ഇന്റർനെറ്റ് കണക്ഷൻ ഇല്ലാതെ നിങ്ങൾക്ക് ഈ ഫയൽ ആക്സസ് ചെയ്യാൻ കഴിയില്ല.",
        "device": "ഉപകരണം",
        "file_visibility": "ഫയൽ ദൃശ്യപരത",
        "visible_for_everyone": "എല്ലാവർക്കും ദൃശ്യമാണ്",
        "shared_link": "പങ്കിട്ട ലിങ്ക്",
        "hashtags": "ഹാഷ് ടാഗുകൾ",
        "hashtags_helps_to_search_your_file": "നിങ്ങളുടെ ഫയലുകൾ കണ്ടെത്താൻ ഹാഷ് ടാഗുകൾ നിങ്ങളെ സഹായിക്കുന്നു.",
        "edit": "എഡിറ്റ് ചെയ്യുക",
        "move": "നീക്കുക",
        "rename": "പേരുമാറ്റുക",
        "visibility": "ദൃശ്യപരത",
        "delete": "ഇല്ലാതെയാക്കുവാൻ",
        "file_warn_delete": "ഫയൽ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
        "no_subtags_availables": "ഉപടാഗുകളൊന്നും ലഭ്യമല്ല",
        "owner": "സൃഷ്ടാവ്",
        "expulsing": "പുറത്താക്കുന്നു",
        "expulse": "പുറത്താക്കുക",
        "you_have_left_the_group": "നിങ്ങൾ ഗ്രൂപ്പ് വിട്ടു",
        "coming_out": "പുറത്ത് വരുക",
        "leave_group": "ഗ്രൂപ്പ് വിടുക",
        "you_have_left_the_room": "നിങ്ങൾ മുറി വിട്ടു.",
        "leave_room": "മുറി വിടുക",
        "message_deleted": "ഇല്ലാതാക്കിയ സന്ദേശം",
        "has_been_error_deleting_message": "സന്ദേശം ഇല്ലാതാക്കുന്നതിൽ ഒരു പിശക് സംഭവിച്ചു",
        "deleting": "നീക്കം ചെയ്യുന്നു",
        "new_tag": "പുതിയ ടാഗ്",
        "photoEditor": {
            "name": "പേര്",
            "save": "സൂക്ഷിക്കുക",
            "saveAs": "ആയി സംരക്ഷിക്കുക",
            "back": "തിരികെ",
            "loading": "ചാർജിംഗ്…",
            "resetOperations": "എല്ലാ പ്രവർത്തനങ്ങളും പുനരാരംഭിക്കുക / ഇല്ലാതാക്കുക.",
            "changesLoseConfirmation": "എല്ലാ മാറ്റങ്ങളും നീക്കം ചെയ്യപ്പെടും.",
            "changesLoseConfirmationHint": "നിങ്ങൾക്ക് തുടരണമെന്ന് തീർച്ചയാണോ?",
            "cancel": "റദ്ദാക്കുക",
            "continue": "തുടരുക",
            "undoTitle": "അവസാന പ്രവർത്തനം പഴയപടിയാക്കുക",
            "redoTitle": "അവസാന പ്രവർത്തനം വീണ്ടും ചെയ്യുക",
            "showImageTitle": "യഥാർത്ഥ ചിത്രം കാണിക്കുക",
            "zoomInTitle": "വലുതാക്കുക",
            "zoomOutTitle": "സൂം ഔട്ട് ചെയ്യുക",
            "toggleZoomMenuTitle": "സൂം മെനു ടോഗിൾ ചെയ്യുക",
            "adjustTab": "ക്രമീകരിക്കുക",
            "finetuneTab": "നിറങ്ങൾ",
            "filtersTab": "ഫിൽട്ടറുകൾ",
            "watermarkTab": "ചിത്രങ്ങൾ",
            "annotateTab": "വരയ്ക്കുക",
            "resize": "വലുപ്പം മാറ്റുക",
            "resizeTab": "വലിപ്പം",
            "invalidImageError": "അസാധുവായ ചിത്രം",
            "uploadImageError": "ചിത്രം ലോഡ് ചെയ്യുമ്പോൾ ഒരു പിശക് സംഭവിച്ചു.",
            "areNotImages": "അവ ചിത്രങ്ങളല്ല",
            "isNotImage": "അതൊരു ചിത്രമല്ല",
            "toBeUploaded": "അപ്ലോഡ് ചെയ്യേണ്ടത്",
            "cropTool": "മുറിക്കുക",
            "original": "ഒറിജിനൽ",
            "custom": "വ്യക്തിപരമാക്കിയത്",
            "square": "സമചതുരം",
            "landscape": "തിരശ്ചീനമായി",
            "portrait": "ലംബമായ",
            "ellipse": "വൃത്തം",
            "classicTv": "ക്ലാസിക് ടിവി",
            "cinemascope": "സിനിമാസ്കോപ്പ്",
            "arrowTool": "അമ്പ്",
            "blurTool": "മങ്ങിക്കുക",
            "brightnessTool": "തിളക്കം",
            "contrastTool": "കോൺട്രാസ്റ്റ്",
            "ellipseTool": "വൃത്തം",
            "unFlipX": "അൺഫ്ലിപ്പ് X",
            "flipX": "ഫ്ലിപ്പ് X",
            "unFlipY": "അൺഫ്ലിപ്പ് Y",
            "flipY": "ഫ്ലിപ്പ് Y",
            "hsvTool": "MSV",
            "hue": "നിറം",
            "saturation": "സാച്ചുറേഷൻ",
            "value": "മൂല്യം",
            "imageTool": "ചിത്രം",
            "importing": "ഇറക്കുമതി ചെയ്യുന്നു…",
            "addImage": "+ ചിത്രം ചേർക്കുക",
            "uploadImage": "ചിത്രം അപ്ലോഡ് ചെയ്യുക",
            "fromGallery": "സ്റ്റിക്കറുകൾ",
            "lineTool": "ലൈൻ",
            "penTool": "പെൻസിൽ",
            "polygonTool": "ബഹുഭുജം",
            "sides": "വശങ്ങൾ",
            "rectangleTool": "ദീർഘചതുരം",
            "cornerRadius": "എഡ്ജ് റേഡിയസ്",
            "resizeWidthTitle": "പിക്സലുകളിൽ വീതി",
            "resizeHeightTitle": "പിക്സൽ ഉയരം",
            "toggleRatioLockTitle": "ഡൈമൻഷൻ ലോക്ക് ടോഗിൾ ചെയ്യുക",
            "reset": "പുനഃസജ്ജമാക്കുക",
            "resetSize": "ചിത്രത്തിന്റെ യഥാർത്ഥ അളവുകൾ പുനഃസ്ഥാപിക്കുക",
            "rotateTool": "തിരിക്കുക",
            "textTool": "വാചകം",
            "textSpacings": "ടെക്സ്റ്റ് സ്പേസിംഗ്",
            "textAlignment": "ടെക്സ്റ്റ് വിന്യാസം",
            "fontFamily": "ഫോണ്ട് കുടുംബം",
            "size": "വലിപ്പം",
            "letterSpacing": "അക്ഷരങ്ങളുടെ വിടവ്",
            "lineHeight": "ലൈൻ ഉയരം",
            "warmthTool": "ചൂട്",
            "addWatermark": "+ ചിത്രം ചേർക്കുക",
            "addWatermarkTitle": "വാട്ടർമാർക്ക് തരം തിരഞ്ഞെടുക്കുക",
            "uploadWatermark": "ചിത്രം തിരഞ്ഞെടുക്കുക",
            "addWatermarkAsText": "ടെക്സ്റ്റ് ചേർക്കുക",
            "padding": "പാഡിംഗ്",
            "shadow": "തണല്",
            "horizontal": "തിരശ്ചീനമായി",
            "vertical": "ലംബമായ",
            "blur": "മങ്ങിക്കുക",
            "opacity": "അതാര്യത",
            "position": "സ്ഥാനം",
            "stroke": "കനം",
            "saveAsModalLabel": "വിശദാംശങ്ങൾ",
            "extension": "വിപുലീകരണം",
            "nameIsRequired": "പേര് ആവശ്യമാണ്.",
            "quality": "ഗുണമേന്മയുള്ള",
            "imageDimensionsHoverTitle": "സംരക്ഷിച്ച ചിത്രത്തിന്റെ അളവുകൾ (വീതി x ഉയരം)",
            "cropSizeLowerThanResizedWarning": "തിരഞ്ഞെടുത്ത ക്രോപ്പ് ഏരിയ പ്രയോഗിച്ച വലുപ്പത്തേക്കാൾ ചെറുതാണ്, ഇത് ഗുണനിലവാരം കുറയുന്നതിന് കാരണമായേക്കാം.",
            "actualSize": "നിലവിലെ വലുപ്പം (100%)",
            "fitSize": "അളവ് പൊരുത്തപ്പെടുത്തുക",
            "addImageTitle": "ചേർക്കാൻ ഒരു ചിത്രം തിരഞ്ഞെടുക്കുക…"
        },
        "changing": "മാറ്റുന്നതിൽ",
        "profile_picture_changed": "പ്രൊഫൈൽ ഫോട്ടോ മാറ്റി",
        "loading_profile_picture": "പ്രൊഫൈൽ ഫോട്ടോ ലോഡ് ചെയ്യുന്നു",
        "change": "മാറ്റുക",
        "session": "സെഷൻ",
        "system_operative": "ഒ.എസ്",
        "navigator": "ബ്രൗസർ",
        "authorize": "അധികാരപ്പെടുത്തുക",
        "deauthorize": "നിരസിക്കുക",
        "keep_tag_content": "ടാഗിന്റെ ഉള്ളടക്കം സംരക്ഷിക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
        "move_content_to": "ഇതിലേക്ക് ഉള്ളടക്കം നീക്കുക",
        "move_to": "ഇതിലേക്ക് നീങ്ങുക",
        "chat_request": "ചാറ്റ് അഭ്യർത്ഥന",
        "accept_request": "അഭ്യർത്ഥന സ്വീകരിക്കുക",
        "go_to_chat": "ചാറ്റിലേക്ക് പോകുക",
        "applying": "അപേക്ഷിക്കുന്നു",
        "time": "സമയം",
        "adjust": "ക്രമീകരിക്കുക",
        "rotate": "തിരിക്കുക",
        "start": "തുടക്കം",
        "end": "അവസാനം",
        "lang_name": "Malayalam"
    },
    "ms": {
        "name": "Nama",
        "name_example": "Pedro",
        "last_name": "Nama terakhir",
        "last_name_example": "Salinas",
        "username": "pengguna",
        "username_example": "pedro_salinas",
        "email": "E-mel",
        "email_example": "usuario@dominio.com",
        "password": "Kata laluan",
        "new_password": "Kata laluan baharu",
        "password_example": "*****************",
        "login": "Log masuk",
        "register": "Daftar",
        "verification_code": "Kod pengesahan",
        "verification_code_example": "xxx",
        "verify": "Sahkan",
        "loading": "Mengecas...",
        "please_wait": "Sila tunggu",
        "go_back": "Kembali",
        "recovery": "Pulih",
        "login_subtitle": "Masukkan butiran anda dan akses akaun anda.",
        "forgot_password_link": "Terlupa kata laluan anda? Pulih.",
        "register_account_link": "Anda tidak mempunyai akaun? Daftar!",
        "register_subtitle": "Masukkan butiran anda dan daftar akaun anda.",
        "login_link": "Adakah anda sudah mempunyai akaun? Log masuk!",
        "verification_code_instruction": "Semak e-mel anda dan masukkan kod yang dihantar.",
        "forgot_password_subtitle": "Masukkan e-mel anda untuk memulihkan kata laluan anda.",
        "change_password_subtitle": "Masukkan kata laluan baharu.",
        "change_password_code_used": "Kod untuk memulihkan kata laluan anda adalah tidak sah atau telah digunakan.",
        "no_chats": "Anda tidak mempunyai perbualan.",
        "chats": "Perbualan",
        "groups": "Kumpulan",
        "rooms": "bilik",
        "save": "Simpan",
        "download": "Pelepasan",
        "downloading_image": "Memuat turun imej",
        "an_error_has_ocurred": "Ralat telah berlaku",
        "saving_changes": "Menyimpan perubahan",
        "go_back_to_edit": "Edit lagi",
        "upload_warning_modal": "Memuat naik suntingan akan menggantikan imej asal.",
        "upload_question_modal": "Adakah anda mahu menyimpan yang asal dan membuat salinan?",
        "create_copy_and_keep": "Buat salinan dan simpan",
        "upload": "Meningkat",
        "upload_changes": "Muat naik perubahan",
        "changes_saved": "Perubahan disimpan",
        "downloading_video": "Memuat turun video",
        "video_upload_warning_modal": "Memuat naik pengeditan akan menggantikan video asal.",
        "results": "Keputusan",
        "members": "ahli",
        "cancel": "Batal",
        "add_user": "Tambah pengguna",
        "no_messages": "Tiada mesej",
        "message_placeholder": "Mesej...",
        "group_doesnt_exists": "Kumpulan itu tidak wujud",
        "you_arent_group_member": "Anda bukan ahli kumpulan",
        "touch_to_see_more_info": "Ketik untuk melihat maklumat lanjut.",
        "start_videocall": "Mulakan panggilan video",
        "start_call": "Mulakan panggilan",
        "create_group": "Buat kumpulan",
        "group_name": "Nama kumpulan",
        "add_members_to_create_group": "Tambah ahli untuk membuat kumpulan",
        "search": "carilah",
        "creating": "Mencipta...",
        "dont_belong_anyone_group": "Anda tidak tergolong dalam mana-mana kumpulan.",
        "already_add_this_hashtag": "Anda telah menambah hashtag itu",
        "search_files_by_hashtags": "Cari fail dengan hashtag",
        "enter_to_add": "Masukkan untuk menambah",
        "hashtags_most_used": "Hashtag yang paling banyak digunakan",
        "can_add_it_giving_click": "Anda boleh menambahkannya pada carian anda dengan mengklik padanya",
        "searching_files": "Mencari fail",
        "no_files_found": "Tiada fail ditemui",
        "image": "Gambar",
        "video": "Video",
        "audio": "Audio",
        "library": "Kedai buku",
        "home": "Mulakan",
        "block": "Sekat",
        "unblock": "Untuk membuka kunci",
        "has_blocked_you": "Dia telah menghalang anda",
        "accept": "Terima",
        "decline": "Tolak",
        "cancel_request": "Batalkan permintaan",
        "has_been_expulsed": "telah dibuang",
        "room_doesnt_exists": "Bilik itu tidak wujud",
        "you_arent_room_member": "Anda bukan ahli bilik",
        "create_room": "Buat bilik",
        "room_name": "Nama bilik",
        "no_rooms_created": "Tiada bilik dibuat.",
        "dont_belong_to_anyone_room": "Anda tidak tergolong dalam mana-mana bilik.",
        "enter_a_username": "Tulis pengguna",
        "no_results": "Tiada keputusan",
        "create": "Buat",
        "no_content_uploaded": "Tiada kandungan dimuat naik",
        "new_subtag": "Subteg baharu",
        "update_info": "Kemas kini maklumat",
        "updating": "Mengemas kini",
        "update": "Kemas kini",
        "update_email": "Kemas kini e-mel",
        "change_password": "Tukar kata laluan",
        "current_password": "Kata laluan semasa",
        "select": "Pilih",
        "tap_to_upload_a_file": "Ketik untuk memuat naik fail",
        "edit_photo": "Edit foto",
        "preview": "Pratonton",
        "edit_video": "Edit video",
        "delete_changes": "Padamkan perubahan",
        "uploading": "Pergi ke atas",
        "session_deleted_or_expired": "Sesi dipadamkan atau tamat tempoh",
        "edit_profile": "Sunting profil",
        "devices": "Peranti",
        "downloads": "muat turun",
        "logout": "Log keluar",
        "offline": "Luar talian",
        "images": "Imej",
        "videos": "Video",
        "audios": "Audio",
        "chat": "Sembang",
        "session_unauthorized": "Sesi anda tidak dibenarkan.",
        "authorize_session": "Untuk mengakses sesi ini, anda perlu membenarkan peranti anda.",
        "authorize_instructions": "Sila semak e-mel anda untuk mendapatkan pautan kebenaran atau pergi ke sesi aktif\n        dan pilih tiga titik di penjuru kanan sebelah atas, kemudian pergi ke \"Peranti\" dan pilih \"Izinkan\".",
        "refresh_page_after_authorize": "Setelah anda menyelesaikan proses kebenaran, sila muat semula halaman untuk mengakses sesi.",
        "check_your_inbox": "Semak e-mel anda",
        "send_authorization_email": "Hantar e-mel kebenaran",
        "call_finished": "Panggilan ditamatkan",
        "close": "tutup",
        "waiting_stream": "Menunggu penghantaran",
        "request_camera_and_mic": "Kami memerlukan akses kepada kamera dan mikrofon anda supaya anda boleh membuat panggilan.",
        "call_in_progress": "Panggilan sedang berjalan",
        "group": "Kluster",
        "videocall": "Panggilan video",
        "call": "Panggil",
        "connecting": "Menyambung",
        "call_cancelled": "Panggilan dibatalkan",
        "calling": "Memanggil",
        "hangup": "gantung",
        "reject": "Tolak",
        "has_sent_a_file": "telah menghantar fail.",
        "keep": "Simpan",
        "keep_file": "Jika anda mendayakan simpan fail, ia akan disimpan dalam aplikasi.\n        Ini akan membolehkan kebolehaksesannya tanpa memerlukan sambungan internet..",
        "not_keep_file": "Jika anda melumpuhkan penyimpanan fail, ia akan dipadamkan dan anda tidak akan dapat mengaksesnya tanpa sambungan internet.",
        "file_stored_instruction": "Semua fail yang disimpan akan muncul dengan sempadan hijau dalam senarai fail biasa.\n        Anda juga boleh melihatnya dalam bahagian",
        "stored": "Disimpan",
        "not_stored": "Ia tidak disimpan",
        "file_not_stored_instruction": "Anda tidak akan dapat mengakses fail ini tanpa sambungan internet.",
        "device": "Peranti",
        "file_visibility": "Keterlihatan Fail",
        "visible_for_everyone": "Kelihatan untuk semua orang",
        "shared_link": "Pautan dikongsi",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Hashtag membantu anda mencari fail anda.",
        "edit": "Sunting",
        "move": "Bergerak",
        "rename": "Namakan semula",
        "visibility": "Keterlihatan",
        "delete": "Hapuskan",
        "file_warn_delete": "Adakah anda pasti mahu memadamkan fail itu?",
        "no_subtags_availables": "Tiada subteg tersedia",
        "owner": "Pencipta",
        "expulsing": "mengusir",
        "expulse": "Usir",
        "you_have_left_the_group": "Anda telah meninggalkan kumpulan",
        "coming_out": "Keluar",
        "leave_group": "Tinggalkan kumpulan",
        "you_have_left_the_room": "Awak dah keluar dari bilik.",
        "leave_room": "Tinggalkan kumpulan",
        "message_deleted": "Mesej dipadamkan",
        "has_been_error_deleting_message": "Ralat berlaku semasa memadamkan mesej",
        "deleting": "Mengalih keluar",
        "new_tag": "Tag baharu",
        "photoEditor": {
            "name": "Nama",
            "save": "Selesai",
            "saveAs": "Simpan sebagai",
            "back": "belakang",
            "loading": "Mengecas...",
            "resetOperations": "Mulakan semula/Padam semua operasi.",
            "changesLoseConfirmation": "Semua perubahan akan dialih keluar.",
            "changesLoseConfirmationHint": "Adakah anda pasti mahu meneruskan?",
            "cancel": "Batal",
            "continue": "teruskan",
            "undoTitle": "Buat asal operasi terakhir",
            "redoTitle": "Buat semula operasi terakhir",
            "showImageTitle": "Tunjukkan imej asal",
            "zoomInTitle": "Mengezum masuk",
            "zoomOutTitle": "Wad off",
            "toggleZoomMenuTitle": "Togol menu zum",
            "adjustTab": "Melaraskan",
            "finetuneTab": "Warna",
            "filtersTab": "Penapis",
            "watermarkTab": "Imej",
            "annotateTab": "Lukis",
            "resize": "Ubah saiz",
            "resizeTab": "Saiz",
            "invalidImageError": "Imej tidak sah",
            "uploadImageError": "Ralat berlaku semasa memuatkan imej.",
            "areNotImages": "mereka bukan imej",
            "isNotImage": "ia bukan imej",
            "toBeUploaded": "untuk dimuat naik",
            "cropTool": "potong",
            "original": "Asal",
            "custom": "diperibadikan",
            "square": "Segi empat",
            "landscape": "Mendatar",
            "portrait": "Menegak",
            "ellipse": "Bulatan",
            "classicTv": "tv klasik",
            "cinemascope": "Cinemascope",
            "arrowTool": "anak panah",
            "blurTool": "Kabur",
            "brightnessTool": "Bersinar",
            "contrastTool": "Berbeza",
            "ellipseTool": "Bulatan",
            "unFlipX": "selak X",
            "flipX": "selak X",
            "unFlipY": "selak Y",
            "flipY": "selak Y",
            "hsvTool": "MSV",
            "hue": "Hue",
            "saturation": "Ketepuan",
            "value": "Berbaloi",
            "imageTool": "Gambar",
            "importing": "Mengimport...",
            "addImage": "+ Tambah imej",
            "uploadImage": "Muat naik imej",
            "fromGallery": "Pelekat",
            "lineTool": "Talian",
            "penTool": "Pensel",
            "polygonTool": "poligon",
            "sides": "Sisi",
            "rectangleTool": "segi empat tepat",
            "cornerRadius": "Jejari Tepi",
            "resizeWidthTitle": "Lebar dalam piksel",
            "resizeHeightTitle": "Tinggi dalam piksel",
            "toggleRatioLockTitle": "Togol kunci dimensi",
            "reset": "Mula semula",
            "resetSize": "Tetapkan semula kepada dimensi imej asal",
            "rotateTool": "Putar",
            "textTool": "Teks",
            "textSpacings": "Jarak teks",
            "textAlignment": "Penjajaran Teks",
            "fontFamily": "air pancut",
            "size": "Saiz",
            "letterSpacing": "Jarak huruf",
            "lineHeight": "Ketinggian garisan",
            "warmthTool": "Haba",
            "addWatermark": "tambah imej",
            "addWatermarkTitle": "Choose the watermark type",
            "uploadWatermark": "Pilih imej",
            "addWatermarkAsText": "Tambah teks",
            "padding": "Sumbat",
            "shadow": "teduh",
            "horizontal": "Mendatar",
            "vertical": "Menegak",
            "blur": "Kabur",
            "opacity": "Kelegapan",
            "position": "kedudukan",
            "stroke": "Ketebalan",
            "saveAsModalLabel": "Butiran",
            "extension": "Sambungan",
            "nameIsRequired": "Nama diperlukan.",
            "quality": "Kualiti",
            "imageDimensionsHoverTitle": "Dimensi imej yang disimpan guardadas (width x height)",
            "cropSizeLowerThanResizedWarning": "Sila ambil perhatian bahawa kawasan tanaman yang dipilih adalah lebih kecil daripada saiz semula yang digunakan, yang boleh menyebabkan penurunan kualiti.",
            "actualSize": "Saiz semasa(100%)",
            "fitSize": "Suaikan dimensi",
            "addImageTitle": "Pilih imej untuk ditambah..."
        },
        "changing": "Berubah",
        "profile_picture_changed": "Foto profil ditukar",
        "loading_profile_picture": "Memuatkan foto profil",
        "change": "Ubah",
        "session": "Sesi",
        "system_operative": "OS",
        "navigator": "pelayar",
        "authorize": "membenarkan",
        "deauthorize": "Ingkar",
        "keep_tag_content": "Adakah anda mahu menyimpan kandungan tag?",
        "move_content_to": "Alihkan kandungan ke",
        "move_to": "Berpindah ke",
        "chat_request": "Permintaan sembang",
        "accept_request": "Terima permintaan",
        "go_to_chat": "Pergi ke sembang",
        "applying": "Memohon",
        "time": "Masa",
        "adjust": "Melaraskan",
        "rotate": "Putar",
        "start": "Mulakan",
        "end": "Akhir",
        "lang_name": "Malayo"
    },
    "mr": {
        "name": "नाव",
        "name_example": "Pedro",
        "last_name": "आडनाव",
        "last_name_example": "Salinas",
        "username": "वापरकर्ता",
        "username_example": "pedro_salinas",
        "email": "ईमेल",
        "email_example": "usuario@dominio.com",
        "password": "पासवर्ड",
        "new_password": "नवीन पासवर्ड",
        "password_example": "*****************",
        "login": "लॉग इन करा",
        "register": "नोंदणी करा",
        "verification_code": "सत्यापन कोड",
        "verification_code_example": "xxx",
        "verify": "सत्यापित करा",
        "loading": "चार्ज होत आहे...",
        "please_wait": "कृपया थांबा",
        "go_back": "परत",
        "recovery": "पुनर्प्राप्त करा",
        "login_subtitle": "आपले तपशील प्रविष्ट करा आणि आपल्या खात्यात प्रवेश करा.",
        "forgot_password_link": "तुम्ही तुमचा पासवर्ड विसरलात का? पुनर्प्राप्त करा.",
        "register_account_link": "तुमच्याकडे खाते नाही? साइन अप करा!",
        "register_subtitle": "आपले तपशील प्रविष्ट करा आणि आपले खाते नोंदणी करा.",
        "login_link": "तुमच्याकडे आधीपासूनच खाते आहे का? लॉग इन करा",
        "verification_code_instruction": "तुमचा ईमेल तपासा आणि पाठवलेला कोड टाका.",
        "forgot_password_subtitle": "तुमचा पासवर्ड पुनर्प्राप्त करण्यासाठी तुमचा ईमेल एंटर करा.",
        "change_password_subtitle": "नवीन पासवर्ड टाका.",
        "change_password_code_used": "तुमचा पासवर्ड पुनर्प्राप्त करण्याचा कोड अवैध आहे किंवा आधीच वापरला गेला आहे.",
        "no_chats": "तुमच्यात संभाषणे नाहीत.",
        "chats": "संभाषणे",
        "groups": "गट",
        "rooms": "खोल्या",
        "save": "ठेवा",
        "download": "डिस्चार्ज",
        "downloading_image": "प्रतिमा डाउनलोड करत आहे",
        "an_error_has_ocurred": "त्रुटी आढळली आहे",
        "saving_changes": "बदल जतन करत आहे",
        "go_back_to_edit": "पुन्हा संपादित करा",
        "upload_warning_modal": "आवृत्ती अपलोड केल्याने मूळ प्रतिमेची जागा घेतली जाईल.",
        "upload_question_modal": "तुम्ही मूळ ठेवू इच्छिता आणि एक प्रत तयार करू इच्छिता?",
        "create_copy_and_keep": "कॉपी तयार करा आणि ठेवा",
        "upload": "वाढवा",
        "upload_changes": "बदल अपलोड करा",
        "changes_saved": "बदल जतन केले",
        "downloading_video": "व्हिडिओ डाउनलोड करत आहे",
        "video_upload_warning_modal": "संपादन अपलोड केल्याने मूळ व्हिडिओ पुनर्स्थित होईल.",
        "results": "परिणाम",
        "members": "सदस्य",
        "cancel": "रद्द करा",
        "add_user": "वापरकर्ता जोडा",
        "no_messages": "कोणतेही संदेश नाहीत",
        "message_placeholder": "संदेश...",
        "group_doesnt_exists": "गट अस्तित्वात नाही",
        "you_arent_group_member": "तुम्ही गटाचे सदस्य नाही",
        "touch_to_see_more_info": "अधिक माहिती पाहण्यासाठी टॅप करा.",
        "start_videocall": "व्हिडिओ कॉल सुरू करा",
        "start_call": "कॉल सुरू करा",
        "create_group": "गट तयार करा",
        "group_name": "गटाचे नाव",
        "add_members_to_create_group": "गट तयार करण्यासाठी सदस्य जोडा",
        "search": "पहा",
        "creating": "निर्माण करणे...",
        "dont_belong_anyone_group": "तुम्ही कोणत्याही गटाशी संबंधित नाही.",
        "already_add_this_hashtag": "तुम्ही तो हॅशटॅग आधीच जोडला आहे",
        "search_files_by_hashtags": "हॅशटॅगद्वारे फायली शोधा",
        "enter_to_add": "जोडण्यासाठी प्रविष्ट करा",
        "hashtags_most_used": "सर्वाधिक वापरलेले हॅशटॅग",
        "can_add_it_giving_click": "त्यावर क्लिक करून तुम्ही ते तुमच्या शोधात जोडू शकता",
        "searching_files": "फाइल्स शोधत आहे",
        "no_files_found": "कोणत्याही फायली आढळल्या नाहीत",
        "image": "प्रतिमा",
        "video": "व्हिडिओ",
        "audio": "ऑडिओ",
        "library": "पुस्तकांचे दुकान",
        "home": "सुरू करा",
        "block": "ब्लॉक करा",
        "unblock": "अनलॉक करण्यासाठी",
        "has_blocked_you": "त्याने तुम्हाला ब्लॉक केले आहे",
        "accept": "स्वीकारा",
        "decline": "नकार",
        "cancel_request": "विनंती रद्द",
        "has_been_expulsed": "निष्कासित केले आहे",
        "room_doesnt_exists": "खोली अस्तित्वात नाही",
        "you_arent_room_member": "तुम्ही खोलीचे सदस्य नाही",
        "create_room": "खोली तयार करा",
        "room_name": "खोलीचे नाव",
        "no_rooms_created": "खोल्या तयार केल्या नाहीत.",
        "dont_belong_to_anyone_room": "तुम्ही कोणत्याही खोलीतील नाही.",
        "enter_a_username": "वापरकर्ता लिहा",
        "no_results": "कोणतेही परिणाम नाहीत",
        "create": "तयार करा",
        "no_content_uploaded": "कोणतीही सामग्री अपलोड केलेली नाही",
        "new_subtag": "नवीन सबटॅग",
        "update_info": "माहिती अपडेट करा",
        "updating": "अपडेट करत आहे",
        "update": "अपडेट करा",
        "update_email": "ईमेल अपडेट करा",
        "change_password": "पासवर्ड बदला",
        "current_password": "सध्याचा गुप्त शब्द",
        "select": "निवडा",
        "tap_to_upload_a_file": "फाइल अपलोड करण्यासाठी टॅप करा",
        "edit_photo": "फोटो संपादित करा",
        "preview": "पूर्वावलोकन",
        "edit_video": "व्हिडिओ संपादित करा",
        "delete_changes": "बदल हटवा",
        "uploading": "वर जात आहे",
        "session_deleted_or_expired": "सत्र हटवले किंवा कालबाह्य झाले",
        "edit_profile": "प्रोफाईल संपादित करा",
        "devices": "उपकरणे",
        "downloads": "डाउनलोड",
        "logout": "साइन ऑफ करा",
        "offline": "कनेक्शनशिवाय",
        "images": "प्रतिमा",
        "videos": "व्हिडिओ",
        "audios": "ऑडिओ",
        "chat": "गप्पा",
        "session_unauthorized": "तुमचे सत्र अधिकृत नाही.",
        "authorize_session": "या सत्रात प्रवेश करण्यासाठी, तुम्हाला तुमचे डिव्हाइस अधिकृत करणे आवश्यक आहे.",
        "authorize_instructions": "कृपया अधिकृतता दुव्यासाठी तुमचा ईमेल तपासा किंवा सक्रिय सत्रावर जा\n        आणि वरच्या उजव्या कोपर्यात तीन ठिपके निवडा, नंतर \"डिव्हाइस\" वर जा आणि \"अधिकृत करा\" निवडा\".",
        "refresh_page_after_authorize": "एकदा तुम्ही अधिकृतता प्रक्रिया पूर्ण केल्यानंतर, कृपया लॉग इन करण्यासाठी पृष्ठ रिफ्रेश करा.",
        "check_your_inbox": "तुमचा ईमेल तपासा",
        "send_authorization_email": "अधिकृतता ईमेल पाठवा",
        "call_finished": "कॉल पूर्ण झाला'",
        "close": "बंद करा",
        "waiting_stream": "प्रवाहाची वाट पाहत आहे",
        "request_camera_and_mic": "आम्हाला तुमच्या कॅमेरा आणि मायक्रोफोनमध्ये प्रवेश हवा आहे जेणेकरून तुम्ही कॉल करू शकता.",
        "call_in_progress": "कॉल चालू आहे",
        "group": "क्लस्टर",
        "videocall": "व्हिडिओ कॉल",
        "call": "कॉल करा",
        "connecting": "जोडत आहे",
        "call_cancelled": "कॉल रद्द केला",
        "calling": "कॉल करत आहे",
        "hangup": "फाशी देणे",
        "reject": "नकार",
        "has_sent_a_file": "फाइल पाठवली आहे.",
        "keep": "ठेवा",
        "keep_file": "तुम्ही फाइल ठेवणे सक्षम केल्यास, ती अॅप्लिकेशनमध्ये सेव्ह केली जाईल.\n        हे इंटरनेट कनेक्शनची आवश्यकता नसताना त्याच्या प्रवेशास अनुमती देईल..",
        "not_keep_file": "तुम्ही फाइल ठेवणे अक्षम केल्यास, ती हटविली जाईल आणि तुम्ही इंटरनेट कनेक्शनशिवाय त्यात प्रवेश करू शकणार नाही.",
        "file_stored_instruction": "सर्व संग्रहित फायली सामान्य फाइल सूचीमध्ये हिरव्या बॉर्डरसह दिसतील.\n        तुम्ही त्यांना विभागात देखील पाहू शकता",
        "stored": "संग्रहित",
        "not_stored": "ते साठवले जात नाही",
        "file_not_stored_instruction": "तुम्ही इंटरनेट कनेक्शनशिवाय या फाईलमध्ये प्रवेश करू शकणार नाही.",
        "device": "डिव्हाइस",
        "file_visibility": "फाइल दृश्यमानता",
        "visible_for_everyone": "प्रत्येकासाठी दृश्यमान",
        "shared_link": "लिंक शेअर केली",
        "hashtags": "हॅशटॅग",
        "hashtags_helps_to_search_your_file": "हॅशटॅग तुम्हाला तुमच्या फाइल्स शोधण्यात मदत करतात.",
        "edit": "सुधारणे",
        "move": "हलवा",
        "rename": "नाव बदला",
        "visibility": "दृश्यमानता",
        "delete": "दूर करणे",
        "file_warn_delete": "तुम्हाला खात्री आहे की तुम्ही फाइल हटवू इच्छिता?",
        "no_subtags_availables": "कोणतेही सबटॅग उपलब्ध नाहीत",
        "owner": "निर्माता",
        "expulsing": "निष्कासित करणे",
        "expulse": "निष्कासित करणे",
        "you_have_left_the_group": "तुम्ही गट सोडला आहे",
        "coming_out": "बाहेर येत आहे",
        "leave_group": "गट सोडा",
        "you_have_left_the_room": "आपण खोली सोडली आहे.",
        "leave_room": "गट सोडा",
        "message_deleted": "मेसेज हटवला",
        "has_been_error_deleting_message": "संदेश हटवताना त्रुटी आली",
        "deleting": "काढत आहे",
        "new_tag": "नवीन टॅग",
        "photoEditor": {
            "name": "नाव",
            "save": "समाप्त करा",
            "saveAs": "म्हणून जतन करा",
            "back": "मागे",
            "loading": "चार्ज होत आहे...",
            "resetOperations": "सर्व ऑपरेशन्स रीस्टार्ट/हटवा.",
            "changesLoseConfirmation": "सर्व बदल काढले जातील.",
            "changesLoseConfirmationHint": "तुम्हाला खात्री आहे की तुम्ही पुढे सुरू ठेवू इच्छिता?",
            "cancel": "रद्द करा",
            "continue": "सुरू",
            "undoTitle": "शेवटचे ऑपरेशन पूर्ववत करा",
            "redoTitle": "शेवटचे ऑपरेशन पुन्हा करा",
            "showImageTitle": "मूळ प्रतिमा दर्शवा",
            "zoomInTitle": "प्रतिमेचे दृष्य रूप मोठे करा",
            "zoomOutTitle": "वार्ड ऑफ",
            "toggleZoomMenuTitle": "झूम मेनू टॉगल करा",
            "adjustTab": "समायोजित करा",
            "finetuneTab": "रंग",
            "filtersTab": "फिल्टर्स",
            "watermarkTab": "प्रतिमा",
            "annotateTab": "ड्रॉ",
            "resize": "आकार बदला",
            "resizeTab": "आकार",
            "invalidImageError": "अवैध प्रतिमा",
            "uploadImageError": "प्रतिमा लोड करताना एक त्रुटी आली.",
            "areNotImages": "त्या प्रतिमा नाहीत",
            "isNotImage": "ती प्रतिमा नाही",
            "toBeUploaded": "अपलोड करणे",
            "cropTool": "कट",
            "original": "मूळ",
            "custom": "वैयक्तिकृत",
            "square": "स्क्वेअर",
            "landscape": "क्षैतिज",
            "portrait": "उभ्या",
            "ellipse": "वर्तुळ",
            "classicTv": "क्लासिक टीव्ही",
            "cinemascope": "सिनेमास्कोप",
            "arrowTool": "बाण",
            "blurTool": "अस्पष्ट",
            "brightnessTool": "चमक",
            "contrastTool": "कॉन्ट्रास्ट",
            "ellipseTool": "वर्तुळ",
            "unFlipX": "फ्लिप एक्स",
            "flipX": "फ्लिप एक्स",
            "unFlipY": "Y फ्लिप करा",
            "flipY": "Y फ्लिप करा",
            "hsvTool": "MSV",
            "hue": "रंग",
            "saturation": "संपृक्तता",
            "value": "किंमत",
            "imageTool": "प्रतिमा",
            "importing": "आयात करत आहे...",
            "addImage": "+ प्रतिमा जोडा",
            "uploadImage": "प्रतिमा अपलोड करा",
            "fromGallery": "स्टिकर्स",
            "lineTool": "ओळ",
            "penTool": "पेन्सिल",
            "polygonTool": "बहुभुज",
            "sides": "बाजू",
            "rectangleTool": "आयत",
            "cornerRadius": "काठ त्रिज्या",
            "resizeWidthTitle": "पिक्सेलमध्ये रुंदी",
            "resizeHeightTitle": "पिक्सेल उंची",
            "toggleRatioLockTitle": "डायमेंशन लॉक टॉगल करा",
            "reset": "पुन्हा सुरू करा",
            "resetSize": "मूळ प्रतिमा परिमाणांवर रीसेट करा",
            "rotateTool": "फिरवा",
            "textTool": "मजकूर",
            "textSpacings": "मजकूर अंतर",
            "textAlignment": "मजकूर संरेखन",
            "fontFamily": "कारंजे",
            "size": "आकार",
            "letterSpacing": "अक्षर अंतर",
            "lineHeight": "रेषेची उंची",
            "warmthTool": "उष्णता",
            "addWatermark": "+ प्रतिमा जोडा",
            "addWatermarkTitle": "Choose the watermark type",
            "uploadWatermark": "प्रतिमा निवडा",
            "addWatermarkAsText": "मजकूर जोडा",
            "padding": "भरलेले",
            "shadow": "सावली",
            "horizontal": "क्षैतिज",
            "vertical": "उभ्या",
            "blur": "अस्पष्ट",
            "opacity": "अपारदर्शकता",
            "position": "पोझिशन",
            "stroke": "जाडी",
            "saveAsModalLabel": "तपशील",
            "extension": "विस्तार",
            "nameIsRequired": "नाव आवश्यक आहे.",
            "quality": "गुणवत्ता",
            "imageDimensionsHoverTitle": "जतन केलेली प्रतिमा परिमाणे (width x height)",
            "cropSizeLowerThanResizedWarning": "कृपया लक्षात घ्या की निवडलेले पीक क्षेत्र लागू केलेल्या आकारापेक्षा लहान आहे, ज्यामुळे गुणवत्तेत घट होऊ शकते.",
            "actualSize": "वर्तमान आकार (100%)",
            "fitSize": "परिमाण जुळवून घ्या",
            "addImageTitle": "जोडण्यासाठी प्रतिमा निवडा..."
        },
        "changing": "बदलतो",
        "profile_picture_changed": "प्रोफाइल फोटो बदलला",
        "loading_profile_picture": "प्रोफाइल फोटो लोड करत आहे",
        "change": "बदला",
        "session": "सत्र",
        "system_operative": "OS",
        "navigator": "ब्राउझर",
        "authorize": "अधिकृत करा",
        "deauthorize": "नाकारणे",
        "keep_tag_content": "तुम्हाला टॅगची सामग्री ठेवायची आहे का?",
        "move_content_to": "वर सामग्री हलवा",
        "move_to": "पुढे व्हा",
        "chat_request": "चॅट विनंती",
        "accept_request": "विनंती स्वीकारा",
        "go_to_chat": "चॅट वर जा",
        "applying": "अर्ज करत आहे",
        "time": "वेळ",
        "adjust": "समायोजित करा",
        "rotate": "फिरवा",
        "start": "सुरू करा",
        "end": "फायनल",
        "lang_name": "Marathi"
    },
    "nb": {
        "name": "Navn",
        "name_example": "Pedro",
        "last_name": "Etternavn",
        "last_name_example": "Salinas",
        "username": "Bruker",
        "username_example": "pedro_salinas",
        "email": "E-post",
        "email_example": "usuario@dominio.com",
        "password": "Passord",
        "new_password": "Nytt passord",
        "password_example": "*****************",
        "login": "Logg Inn",
        "register": "Registrere",
        "verification_code": "Bekreftelseskode",
        "verification_code_example": "xxx",
        "verify": "Bekrefte",
        "loading": "Lader...",
        "please_wait": "Vennligst vent",
        "go_back": "Komme tilbake",
        "recovery": "Gjenopprette",
        "login_subtitle": "Skriv inn opplysningene dine og få tilgang til kontoen din.",
        "forgot_password_link": "har du glemt passordet ditt? Gjenopprette.",
        "register_account_link": "Har du ikke en konto? Melde deg på!",
        "register_subtitle": "Skriv inn opplysningene dine og registrer kontoen din.",
        "login_link": "Har du allerede en konto? Logg Inn!",
        "verification_code_instruction": "Sjekk e-posten din og skriv inn koden som ble sendt.",
        "forgot_password_subtitle": "Skriv inn e-postadressen din for å gjenopprette passordet ditt.",
        "change_password_subtitle": "Skriv inn et nytt passord.",
        "change_password_code_used": "Koden for å gjenopprette passordet ditt er ugyldig eller har allerede blitt brukt.",
        "no_chats": "Du har ikke samtaler.",
        "chats": "Samtaler",
        "groups": "Grupper",
        "rooms": "Rom",
        "save": "Beholde",
        "download": "Utslipp",
        "downloading_image": "Laster ned bilde",
        "an_error_has_ocurred": "Det har oppstått en feil",
        "saving_changes": "Lagrer endringer",
        "go_back_to_edit": "Rediger igjen",
        "upload_warning_modal": "Opplasting av utgaven vil erstatte originalbildet.",
        "upload_question_modal": "Vil du beholde originalen og lage en kopi?",
        "create_copy_and_keep": "Lag kopi og behold",
        "upload": "Øke",
        "upload_changes": "Last opp endringer",
        "changes_saved": "Endringer lagret",
        "downloading_video": "Laster ned video",
        "video_upload_warning_modal": "Opplasting av redigeringen vil erstatte den originale videoen.",
        "results": "Resultater",
        "members": "Medlemmer",
        "cancel": "Avbryt",
        "add_user": "Legg til bruker",
        "no_messages": "Ingen meldinger",
        "message_placeholder": "Beskjed...",
        "group_doesnt_exists": "Gruppen eksisterer ikke",
        "you_arent_group_member": "Du er ikke medlem av gruppen",
        "touch_to_see_more_info": "Trykk for å se mer informasjon.",
        "start_videocall": "Start videosamtale",
        "start_call": "Start samtalen",
        "create_group": "Lag gruppe",
        "group_name": "Gruppenavn",
        "add_members_to_create_group": "Legg til medlemmer for å opprette gruppen",
        "search": "Se etter",
        "creating": "Oppretter...",
        "dont_belong_anyone_group": "Du tilhører ikke noen gruppe.",
        "already_add_this_hashtag": "Du har allerede lagt til hashtaggen",
        "search_files_by_hashtags": "Søk filer etter hashtags",
        "enter_to_add": "Enter for å legge til",
        "hashtags_most_used": "Mest brukte hashtags",
        "can_add_it_giving_click": "Du kan legge til disse i søket ditt ved å klikke på dem",
        "searching_files": "Søker etter filer",
        "no_files_found": "Ingen filer funnet",
        "image": "Bilde",
        "video": "Video",
        "audio": "Lyd",
        "library": "Bokhandel",
        "home": "Start",
        "block": "Blokkere",
        "unblock": "Å låse opp",
        "has_blocked_you": "Han har blokkert deg",
        "accept": "Aksepterer",
        "decline": "Avslå",
        "cancel_request": "Avbryt forespørsel",
        "has_been_expulsed": "har blitt utvist",
        "room_doesnt_exists": "Rommet finnes ikke",
        "you_arent_room_member": "Du er ikke medlem av rommet",
        "create_room": "Skap rom",
        "room_name": "Romnavn",
        "no_rooms_created": "Det er ingen rom opprettet.",
        "dont_belong_to_anyone_room": "Du hører ikke hjemme i noe rom.",
        "enter_a_username": "Skriv en bruker",
        "no_results": "Ingen resultater",
        "create": "Skape",
        "no_content_uploaded": "Ingen innhold lastet opp",
        "new_subtag": "Ny subtag",
        "update_info": "Oppdater informasjon",
        "updating": "Oppdaterer",
        "update": "Oppdater",
        "update_email": "Oppdater e-post",
        "change_password": "Bytt passord",
        "current_password": "Nåværende passord",
        "select": "Plukke ut",
        "tap_to_upload_a_file": "Trykk for å laste opp en fil",
        "edit_photo": "Rediger bilde",
        "preview": "Forhåndsvisning",
        "edit_video": "Redigere video",
        "delete_changes": "Slett endringer",
        "uploading": "Går opp",
        "session_deleted_or_expired": "Økt slettet eller utløpt",
        "edit_profile": "Rediger profil",
        "devices": "Enheter",
        "downloads": "Nedlastinger",
        "logout": "Logg av",
        "offline": "Uten tilkobling",
        "images": "Bilder",
        "videos": "Videoer",
        "audios": "Lyd",
        "chat": "Chat",
        "session_unauthorized": "Økten din er ikke autorisert.",
        "authorize_session": "For å få tilgang til denne økten må du autorisere enheten din.",
        "authorize_instructions": "Vennligst sjekk e-posten din for autorisasjonslenken eller gå til en aktiv økt\n        og velg de tre prikkene i øvre høyre hjørne, gå deretter til \"Enheter\" og velg \"Authorize\"\".",
        "refresh_page_after_authorize": "Når du har fullført autorisasjonsprosessen, vennligst oppdater siden for å få tilgang til økten.",
        "check_your_inbox": "Sjekk e-posten",
        "send_authorization_email": "Send autorisasjons-e-post",
        "call_finished": "Anrop avsluttet",
        "close": "Lukk",
        "waiting_stream": "Venter på overføring",
        "request_camera_and_mic": "Vi trenger tilgang til kameraet og mikrofonen din slik at du kan ringe.",
        "call_in_progress": "Samtale pågår",
        "group": "Klynge",
        "videocall": "Videosamtale",
        "call": "Anrop",
        "connecting": "Kobler til",
        "call_cancelled": "Samtalen avbrutt",
        "calling": "Ringer",
        "hangup": "Henge",
        "reject": "Avslå",
        "has_sent_a_file": "har sendt en fil.",
        "keep": "Beholde",
        "keep_file": "Hvis du aktiverer lagring av filen, vil den bli lagret i applikasjonen.\n        Dette vil tillate dens tilgjengelighet uten å kreve en internettforbindelse..",
        "not_keep_file": "Hvis du deaktiverer å beholde filen, vil den bli slettet, og du vil ikke få tilgang til den uten en internettforbindelse.",
        "file_stored_instruction": "Alle lagrede filer vil vises med en grønn kant i den vanlige fillisten.\n        Du kan også se dem i seksjonen",
        "stored": "Lagret",
        "not_stored": "Den er ikke lagret",
        "file_not_stored_instruction": "Du vil ikke få tilgang til denne filen uten en internettforbindelse.",
        "device": "Enhet",
        "file_visibility": "Filsynlighet",
        "visible_for_everyone": "Synlig for alle",
        "shared_link": "Delt lenke",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Hashtags hjelper deg å finne filene dine.",
        "edit": "Redigere",
        "move": "Bevege seg",
        "rename": "Gi nytt navn",
        "visibility": "Synlighet",
        "delete": "Eliminere",
        "file_warn_delete": "Er du sikker på at du vil slette filen?",
        "no_subtags_availables": "Ingen tilgjengelige underkoder",
        "owner": "Skaper",
        "expulsing": "Utvise",
        "expulse": "Utvise",
        "you_have_left_the_group": "Du har forlatt gruppen",
        "coming_out": "Kommer ut",
        "leave_group": "Gå ut av gruppen",
        "you_have_left_the_room": "Du har forlatt rommet.",
        "leave_room": "Gå ut av gruppen",
        "message_deleted": "Slettet melding",
        "has_been_error_deleting_message": "Det oppsto en feil da meldingen skulle slettes",
        "deleting": "Fjerner",
        "new_tag": "Ny tag",
        "photoEditor": {
            "name": "Navn",
            "save": "Bli ferdig",
            "saveAs": "Lagre som",
            "back": "Tilbake",
            "loading": "Lader...",
            "resetOperations": "Start på nytt/slett alle operasjoner.",
            "changesLoseConfirmation": "Alle endringer vil bli fjernet.",
            "changesLoseConfirmationHint": "Er du sikker på at du vil fortsette??",
            "cancel": "Avbryt",
            "continue": "Fortsette",
            "undoTitle": "Angre siste operasjon",
            "redoTitle": "Gjenta siste operasjon",
            "showImageTitle": "Vis originalbildet",
            "zoomInTitle": "Zoom inn",
            "zoomOutTitle": "Avverge",
            "toggleZoomMenuTitle": "Bytt zoom-meny",
            "adjustTab": "Justere",
            "finetuneTab": "Farger",
            "filtersTab": "Filtre",
            "watermarkTab": "Bilder",
            "annotateTab": "Tegne",
            "resize": "Endre størrelse",
            "resizeTab": "Størrelse",
            "invalidImageError": "Ugyldig bilde",
            "uploadImageError": "Det oppsto en feil under innlasting av bildet.",
            "areNotImages": "De er ikke bilder",
            "isNotImage": "det er ikke et bilde",
            "toBeUploaded": "som skal lastes opp",
            "cropTool": "Kutte opp",
            "original": "Opprinnelig",
            "custom": "Personlig tilpasset",
            "square": "Torget",
            "landscape": "Horisontal",
            "portrait": "Vertikal",
            "ellipse": "Sirkel",
            "classicTv": "klassisk tv",
            "cinemascope": "Kinoskop",
            "arrowTool": "Pil",
            "blurTool": "Uklarhet",
            "brightnessTool": "Gløde",
            "contrastTool": "Kontrast",
            "ellipseTool": "Sirkel",
            "unFlipX": "Vend X",
            "flipX": "'Vend X",
            "unFlipY": "'Vend Y",
            "flipY": "'Vend Y",
            "hsvTool": "MSV",
            "hue": "Hue",
            "saturation": "metning",
            "value": "Verdi",
            "imageTool": "Bilde",
            "importing": "Betydende...",
            "addImage": "+ Legg til bilde",
            "uploadImage": "Last opp bilde",
            "fromGallery": "Klistermerker",
            "lineTool": "Linje",
            "penTool": "Blyant",
            "polygonTool": "Polygon",
            "sides": "Sider",
            "rectangleTool": "Rektangel",
            "cornerRadius": "Kantradius",
            "resizeWidthTitle": "Bredde i piksler",
            "resizeHeightTitle": "Pikselhøyde",
            "toggleRatioLockTitle": "Slå på dimensjonslås",
            "reset": "Omstart",
            "resetSize": "Tilbakestill til originalbildedimensjoner",
            "rotateTool": "Rotere",
            "textTool": "Tekst",
            "textSpacings": "Tekstavstand",
            "textAlignment": "Tekstjustering",
            "fontFamily": "Fontene",
            "size": "Størrelse",
            "letterSpacing": "Avstand mellom bokstavene",
            "lineHeight": "Linjehøyde",
            "warmthTool": "Varme",
            "addWatermark": "+ Legg til bilde",
            "addWatermarkTitle": "Choose the watermark type",
            "uploadWatermark": "Velg bilde",
            "addWatermarkAsText": "Legg til tekst",
            "padding": "Fylt",
            "shadow": "Skygge",
            "horizontal": "Horisontal",
            "vertical": "Vertikal",
            "blur": "Uklarhet",
            "opacity": "Opasitet",
            "position": "Posisjon",
            "stroke": "Tykkelse",
            "saveAsModalLabel": "Detaljer",
            "extension": "Utvidelse",
            "nameIsRequired": "Navnet er obligatorisk.",
            "quality": "Kvalitet",
            "imageDimensionsHoverTitle": "Lagrede bildedimensjoner (width x height)",
            "cropSizeLowerThanResizedWarning": "Vær oppmerksom på at det valgte beskjæringsområdet er mindre enn den endrede størrelsen som ble brukt, noe som kan føre til redusert kvalitet.",
            "actualSize": "Nåværende størrelse (100%)",
            "fitSize": "Tilpass dimensjon",
            "addImageTitle": "Velg et bilde du vil legge til..."
        },
        "changing": "Endre",
        "profile_picture_changed": "Profilbildet er endret",
        "loading_profile_picture": "Laster inn profilbilde",
        "change": "Endring",
        "session": "Økt",
        "system_operative": "OS",
        "navigator": "Nettleser",
        "authorize": "Autorisere",
        "deauthorize": "Desavow",
        "keep_tag_content": "Du vil beholde innholdet i taggen?",
        "move_content_to": "Flytt innhold til",
        "move_to": "Flytte til",
        "chat_request": "Chatforespørsel",
        "accept_request": "Aksepter forespørsel",
        "go_to_chat": "Gå til chat",
        "applying": "Søker",
        "time": "Tid",
        "adjust": "Justere",
        "rotate": "Rotere",
        "start": "Start",
        "end": "Endelig",
        "lang_name": "Noruego"
    },
    "om": {
        "name": "Maqaa",
        "name_example": "Pedro",
        "last_name": "Maqaa akaakayyuu",
        "last_name_example": "Salinas",
        "username": "Fayyadamaa",
        "username_example": "pedro_salinas",
        "email": "Imeelii",
        "email_example": "usuario@dominio.com",
        "password": "Jecha iccitii",
        "new_password": "Jecha icciitii haaraa",
        "password_example": "*****************",
        "login": "Galmaa'i",
        "register": "Galmeessuu",
        "verification_code": "Koodii mirkaneessuu",
        "verification_code_example": "xxx",
        "verify": "Dhugoomsuu",
        "loading": "Chaarjii gochuu...",
        "please_wait": "Mee eegaa",
        "go_back": "Deebisuu",
        "recovery": "Haala duraaniitti deebi'uu",
        "login_subtitle": "Bal'ina keessan galchaa akkaawuntii keessan seena.",
        "forgot_password_link": "Jecha icciitii kee dagattee? Haala duraaniitti deebi'uu.",
        "register_account_link": "Akkaawuntii hin qabduu? Galmaa'i!",
        "register_subtitle": "Bal'ina keessan galchaa akkaawuntii keessan galmeessi.",
        "login_link": "Duraanis akkaawuntii qabduu? Sign in!",
        "verification_code_instruction": "Email keessan ilaalaa koodii ergame galchaa.",
        "forgot_password_subtitle": "Jecha icciitii keessan deebifachuuf email keessan galchaa.",
        "change_password_subtitle": "Jecha icciitii haaraa galchi.",
        "change_password_code_used": "Koodiin jecha icciitii keessan deebifachuuf gargaaru sirrii miti ykn duraan fayyadameera.",
        "no_chats": "Haasaa hin qabdu.",
        "chats": "Haasaa",
        "groups": "Gareewwan",
        "rooms": "Kutaawwan",
        "save": "Qabi",
        "download": "Baasuu",
        "downloading_image": "Fakkii buufachuu",
        "an_error_has_ocurred": "Dogoggorri uumameera",
        "saving_changes": "Jijjiirama qusachuu",
        "go_back_to_edit": "Ammas gulaali",
        "upload_warning_modal": "Gulaallii olkaa'uun fakkii jalqabaa bakka bu'a.",
        "upload_question_modal": "Orijinaala qabattee waraabbii uumuu barbaaddaa?",
        "create_copy_and_keep": "Koppii uumuu fi qabachuu",
        "upload": "Dabaluu",
        "upload_changes": "Jijjiirama olkaa'uu",
        "changes_saved": "Jijjiiramni qusatame",
        "downloading_video": "Viidiyoo buufachuu",
        "video_upload_warning_modal": "Gulaallii olkaa'uun viidiyoo jalqabaa bakka bu'a.",
        "results": "Bu'aawwan",
        "members": "Miseensota",
        "cancel": "Haquu",
        "add_user": "Fayyadamaa dabaluu",
        "no_messages": "Ergaa hin qabu",
        "message_placeholder": "Ergaa...",
        "group_doesnt_exists": "Gareen hin jiru",
        "you_arent_group_member": "Ati miseensa garee sanaa miti",
        "touch_to_see_more_info": "Odeeffannoo dabalataa ilaaluuf tuqi.",
        "start_videocall": "Bilbila viidiyoo jalqabaa",
        "start_call": "Bilbila jalqabaa",
        "create_group": "Garee uumama",
        "group_name": "Maqaa garee",
        "add_members_to_create_group": "Garee uumuuf miseensota dabaluu",
        "search": "Barbaadaa",
        "creating": "Uumuu...",
        "dont_belong_anyone_group": "Garee kamiyyuu keessa hin jirtu.",
        "already_add_this_hashtag": "Hashtag sana duraan itti dabalteetta",
        "search_files_by_hashtags": "Faayilota hashtag tiin barbaadi",
        "enter_to_add": "Dabaluuf galchi",
        "hashtags_most_used": "Hashtags baay'inaan itti fayyadaman",
        "can_add_it_giving_click": "Isaan kana cuqaasuun barbaacha keessanitti dabaluu dandeessu",
        "searching_files": "Faayilota barbaaduu",
        "no_files_found": "Faayilonni hin argamne",
        "image": "Bifa",
        "video": "Viidiyoo",
        "audio": "Sagalee",
        "library": "Mana kitaabaa",
        "home": "Eegaluu",
        "block": "Dhowwuu",
        "unblock": "Qulfii banuuf",
        "has_blocked_you": "Inni si ugguree jira",
        "accept": "Simachuu",
        "decline": "Gadi bu'uu",
        "cancel_request": "Gaaffii haquu",
        "has_been_expulsed": "ari�ameera",
        "room_doesnt_exists": "Kutaan sun hin jiru",
        "you_arent_room_member": "Ati miseensa kutaa sanaa miti",
        "create_room": "Kutaa uumuu",
        "room_name": "Maqaa kutaa",
        "no_rooms_created": "Kutaawwan uumaman hin jiran.",
        "dont_belong_to_anyone_room": "Kutaa kamiyyuu keessaa kan kee miti.",
        "enter_a_username": "Fayyadamaa barreessi",
        "no_results": "Bu'aan hin jiru",
        "create": "Uumuu",
        "no_content_uploaded": "Qabiyyeen hin olkaa'amne",
        "new_subtag": "Subtag haaraa",
        "update_info": "Odeeffannoo haaromsi",
        "updating": "Fooyyessuun",
        "update": "Odeeffannoo dhiyeenyaa",
        "update_email": "Imeelii haaromsi",
        "change_password": "Jecha icciitii jijjiiri",
        "current_password": "Jecha icciitii ammaa",
        "select": "Filuu",
        "tap_to_upload_a_file": "Faayila olkaa'uuf tuqi",
        "edit_photo": "Suuraa gulaaluu",
        "preview": "Dursee ilaaluu",
        "edit_video": "Viidiyoo gulaaluu",
        "delete_changes": "Jijjiirama haquu",
        "uploading": "Ol ba'uu",
        "session_deleted_or_expired": "Kutaan haqame ykn yeroon isaa darbe",
        "edit_profile": "Profaayilii gulaali",
        "devices": "Meeshaalee",
        "downloads": "buufachuuf",
        "logout": "Mallatteessaa",
        "offline": "Toora ala",
        "images": "Fakkiiwwan",
        "videos": "Viidiyoowwan",
        "audios": "Sagaleewwan",
        "chat": "Waliin dubbii",
        "session_unauthorized": "Kutaan kee hin hayyamamu.",
        "authorize_session": "Kutaa kana argachuuf meeshaa kee hayyamuu qabda.",
        "authorize_instructions": "Maaloo email keessan link hayyamaa ilaalaa ykn gara active session deemaa\n        akkasumas tuqaawwan sadan golee mirgaa gubbaa jiran filadhu, achiis gara \"Meeshaalee\" deemtee \"Hayyama\" filadhu\".",
        "refresh_page_after_authorize": "Adeemsa hayyamaa erga xumurtee booda, maaloo fuula haaromsi, turtii sana argachuuf.",
        "check_your_inbox": "Email keessan ilaalaa",
        "send_authorization_email": "Imeelii hayyamaa ergaa",
        "call_finished": "Bilbilli xumurame",
        "close": "Cufuu",
        "waiting_stream": "Dabarsa eegaa jira",
        "request_camera_and_mic": "Bilbila akka bilbiltan kaameeraa fi maaykiroofoonii keessan argachuu qabna.",
        "call_in_progress": "Bilbila adeemsifamaa jira",
        "group": "Tuuta",
        "videocall": "Bilbila viidiyoo",
        "call": "Waamuu",
        "connecting": "Walqunnamsiisuu",
        "call_cancelled": "Bilbilli haqame",
        "calling": "Bilbilaa",
        "hangup": "Fannisuu",
        "reject": "Gadi bu'uu",
        "has_sent_a_file": "faayila ergeera.",
        "keep": "Qabi",
        "keep_file": "Yoo faayilii eeguu dandeessifte, application keessatti ni saagama.\n        Kunis qunnamtii interneetii osoo hin barbaachisin dhaqqabummaa isaa ni taasisa.",
        "not_keep_file": "Yoo faayila sana qabachuu dhorkite ni haqama, interneetii malees argachuu hin dandeessan.",
        "file_stored_instruction": "Faayiloonni kuufaman hundi tarree faayilii waliigalaa keessatti daangaa magariisaa wajjin ni mul'atu.\n        Kutaa keessattis isaan arguu dandeessu",
        "stored": "Kuufama",
        "not_stored": "Hin kuufamu",
        "file_not_stored_instruction": "Faayila kana interneetii malee argachuu hin dandeessan.",
        "device": "Meeshaa",
        "file_visibility": "Mul'achuu Faayilii",
        "visible_for_everyone": "Nama hundaaf kan mul'atu",
        "shared_link": "Link qooddame",
        "hashtags": "Hashtags jedhu",
        "hashtags_helps_to_search_your_file": "Hashtags faayiloota keessan akka argattan isin gargaara.",
        "edit": "Gulaaluu",
        "move": "Socho'uu",
        "rename": "Maqaa jijjiiri",
        "visibility": "Mul�achuu",
        "delete": "Balleessuu",
        "file_warn_delete": "Faayilii sana haquu akka barbaaddu mirkaneeffatteettaa?",
        "no_subtags_availables": "Subtags hin jiru",
        "owner": "Uumaa",
        "expulsing": "ari�uu",
        "expulse": "Ari'uu",
        "you_have_left_the_group": "Garee keessaa baatee jirta",
        "coming_out": "Ba'aa jira",
        "leave_group": "Garee keessaa ba'aa",
        "you_have_left_the_room": "Kutaa keessaa baatee jirta.",
        "leave_room": "Garee keessaa ba'aa",
        "message_deleted": "Ergaa haqame",
        "has_been_error_deleting_message": "Ergaa sana haquu irratti dogongorri uumame",
        "deleting": "Buqqisuu",
        "new_tag": "Taagi haaraa",
        "photoEditor": {
            "name": "Maqaa",
            "save": "Xumuruu",
            "saveAs": "Akka qusadhu",
            "back": "Dugda",
            "loading": "Chaarjii gochuu...",
            "resetOperations": "Hojiiwwan hunda irra deebi'ii jalqabi/Haqi.",
            "changesLoseConfirmation": "Jijjiiramni hundi ni haqama.",
            "changesLoseConfirmationHint": "Itti fufuu akka barbaaddu mirkaneeffatteettaa?",
            "cancel": "Haquu",
            "continue": "Itti fufuu",
            "undoTitle": "Hojii isa dhumaa deebisi",
            "redoTitle": "Hojii isa dhumaa irra deebi'ii hojjedhu",
            "showImageTitle": "Fakkii jalqabaa agarsiisi",
            "zoomInTitle": "Zoom in godhaa",
            "zoomOutTitle": "Ward off godhaa",
            "toggleZoomMenuTitle": "Sajoo guddisuu jijjiiri",
            "adjustTab": "Sirreessuu",
            "finetuneTab": "Halluuwwan",
            "filtersTab": "Filters",
            "watermarkTab": "Fakkiiwwan",
            "annotateTab": "Kaasuu",
            "resize": "Saayizii jijjiiri",
            "resizeTab": "Hamma ga'u",
            "invalidImageError": "Fakkii sirrii hin taane",
            "uploadImageError": "Yeroo fakkii fe'u dogongorri uumame.",
            "areNotImages": "isaan fakkii miti",
            "isNotImage": "fakkii miti",
            "toBeUploaded": "akka olkaa'amuuf",
            "cropTool": "Kutuu",
            "original": "Kan jalqabaa",
            "custom": "Dhuunfaatti kan qophaa'e",
            "square": "Addababayii",
            "landscape": "Dalga-galii",
            "portrait": "Ol kan dhaabbatu",
            "ellipse": "Geengoo",
            "classicTv": "classic tv",
            "cinemascope": "Sinimaaskooppii",
            "arrowTool": "Hiddaa",
            "blurTool": "Jajjaboo",
            "brightnessTool": "Ifa kennuu",
            "contrastTool": "Contraste",
            "ellipseTool": "Geengoo",
            "unFlipX": "GaragalchuuX",
            "flipX": "GaragalchuuX",
            "unFlipY": "GaragalchuuY",
            "flipY": "GaragalchuuY",
            "hsvTool": "MSV",
            "hue": "Hue",
            "saturation": "Saturaashinii",
            "value": "Gatii",
            "imageTool": "Bifa",
            "importing": "Galchuu...",
            "addImage": "+ Fakkii itti dabali",
            "uploadImage": "Fakkii olkaa'i",
            "fromGallery": "Istikaara",
            "lineTool": "Sarara",
            "penTool": "Qubeessaa",
            "polygonTool": "poligooniin",
            "sides": "Cinaacha",
            "rectangleTool": "Rog-arfee",
            "cornerRadius": "Raadiyaasii qarqara",
            "resizeWidthTitle": "Bal'inni isaa piikseliidhaan",
            "resizeHeightTitle": "Piikselii olka'aa dha",
            "toggleRatioLockTitle": "Qulfii dimenshinii jijjiiri",
            "reset": "Irra deebi'ii jalqabi",
            "resetSize": "Gara safara fakkii jalqabaatti deebisi",
            "rotateTool": "Naanna'aa",
            "textTool": "Barreeffama",
            "textSpacings": "Addaan fageenya barruu",
            "textAlignment": "Hiriirsa Barruu",
            "fontFamily": "Burqaa",
            "size": "Hamma ga'u",
            "letterSpacing": "Addaan fageenya qubee",
            "lineHeight": "Olka�iinsa sararaa",
            "warmthTool": "Ho'a",
            "addWatermark": "+ Fakkii itti dabali",
            "addWatermarkTitle": "Choose the watermark type",
            "uploadWatermark": "Fakkii filadhu",
            "addWatermarkAsText": "Barruu itti dabali",
            "padding": "Stuffed ta'e",
            "shadow": "Gaaddisa",
            "horizontal": "Dalga-galii",
            "vertical": "Ol kan dhaabbatu",
            "blur": "Jajjaboo",
            "opacity": "'Opacity ta'uu",
            "position": "Gita hojii",
            "stroke": "Fulbaana",
            "saveAsModalLabel": "Gadifageenyaan",
            "extension": "Dheerachuu",
            "nameIsRequired": "Maqaan ni barbaachisa.",
            "quality": "Qulqullina",
            "imageDimensionsHoverTitle": "Dimshaashumatti Fakkii Qusatame (width x height)",
            "cropSizeLowerThanResizedWarning": "Hubadhaa, bal�inni midhaanii filatame jijjiirraa guddinaa hojiirra oole irraa xiqqaa ta�uu isaa, kunis qulqullina hir�isuu fiduu danda�a.",
            "actualSize": "Guddina ammaa(100%)",
            "fitSize": "Dimshaashumatti madaqsuu",
            "addImageTitle": "Fakkii itti dabaluuf filadhu..."
        },
        "changing": "Jijjiiramaa jira",
        "profile_picture_changed": "Suuraan piroofaayilii jijjiirame",
        "loading_profile_picture": "Suuraa piroofaayilii fe'amaa jira",
        "change": "Jijjiiruu",
        "session": "Yeroo",
        "system_operative": "OS",
        "navigator": "Browser jedhamuun beekama",
        "authorize": "Hayyama kennuu",
        "deauthorize": "Disavow godhaa",
        "keep_tag_content": "Qabiyyee mallattoo sanaa kunuunsuu barbaadda?",
        "move_content_to": "Qabiyyee gara",
        "move_to": "Gara...",
        "chat_request": "Gaaffii chat",
        "accept_request": "Gaaffii fudhadhu",
        "go_to_chat": "Gara chat deemaa",
        "applying": "Iyyachuu",
        "time": "Yeroo",
        "adjust": "Sirreessuu",
        "rotate": "Naanna'aa",
        "start": "Eegaluu",
        "end": "Xumura",
        "lang_name": "Oromo"
    },
    "fa": {
        "name": "نام",
        "name_example": "Pedro",
        "last_name": "نام خانوادگی",
        "last_name_example": "Salinas",
        "username": "کاربر",
        "username_example": "pedro_salinas",
        "email": "پست الکترونیک",
        "email_example": "usuario@dominio.com",
        "password": "کلمه عبور",
        "new_password": "رمز عبور جدید",
        "password_example": "*****************",
        "login": "وارد شدن",
        "register": "ثبت نام",
        "verification_code": "کد تایید",
        "verification_code_example": "xxx",
        "verify": "تأیید کنید",
        "loading": "شارژ کردن...",
        "please_wait": "لطفا صبر کنید",
        "go_back": "برگشت",
        "recovery": "بازیابی کنید",
        "login_subtitle": "مشخصات خود را وارد کنید و به حساب خود دسترسی پیدا کنید.",
        "forgot_password_link": "آیا رمز عبور خود را فراموش کرده اید؟ بازیابی کنید.",
        "register_account_link": "حساب کاربری ندارید؟ ثبت نام!",
        "register_subtitle": "مشخصات خود را وارد کنید و حساب خود را ثبت کنید.",
        "login_link": "آیا از قبل حساب دارید؟ وارد شدن!",
        "verification_code_instruction": "ایمیل خود را بررسی کنید و کد ارسال شده را وارد کنید.",
        "forgot_password_subtitle": "ایمیل خود را برای بازیابی رمز عبور وارد کنید.",
        "change_password_subtitle": "رمز عبور جدیدی وارد کنید.",
        "change_password_code_used": "کد بازیابی رمز عبور شما نامعتبر است یا قبلاً استفاده شده است.",
        "no_chats": "شما مکالمه ندارید.",
        "chats": "گفتگو",
        "groups": "گروه ها",
        "rooms": "اتاق ها",
        "save": "نگاه داشتن",
        "download": "تخلیه",
        "downloading_image": "در حال دانلود تصویر",
        "an_error_has_ocurred": "خطایی رخ داده است",
        "saving_changes": "ذخیره سازی تغییرات",
        "go_back_to_edit": "دوباره ویرایش کنید",
        "upload_warning_modal": "آپلود نسخه جایگزین تصویر اصلی می شود.",
        "upload_question_modal": "آیا می خواهید نسخه اصلی را نگه دارید و یک کپی ایجاد کنید؟",
        "create_copy_and_keep": "کپی ایجاد کنید و نگه دارید",
        "upload": "افزایش دادن",
        "upload_changes": "آپلود تغییرات",
        "changes_saved": "تغییرات ذخیره شد",
        "downloading_video": "در حال دانلود ویدیو",
        "video_upload_warning_modal": "آپلود ویرایش جایگزین ویدیوی اصلی خواهد شد.",
        "results": "نتایج",
        "members": "اعضا",
        "cancel": "لغو کنید",
        "add_user": "کاربر اضافه کنید",
        "no_messages": "هیچ پیامی وجود ندارد",
        "message_placeholder": "پیام...",
        "group_doesnt_exists": "گروه وجود ندارد",
        "you_arent_group_member": "شما عضو گروه نیستید",
        "touch_to_see_more_info": "برای دیدن اطلاعات بیشتر ضربه بزنید.",
        "start_videocall": "تماس تصویری را شروع کنید",
        "start_call": "تماس را شروع کنید",
        "create_group": "Crear group",
        "group_name": "اسم گروه",
        "add_members_to_create_group": "برای ایجاد گروه اعضا را اضافه کنید",
        "search": "جستجو کنید",
        "creating": "پدید آوردن...",
        "dont_belong_anyone_group": "شما به هیچ گروهی تعلق ندارید.",
        "already_add_this_hashtag": "شما قبلاً آن هشتگ را اضافه کرده اید",
        "search_files_by_hashtags": "جستجوی فایل ها بر اساس هشتگ",
        "enter_to_add": "برای افزودن وارد کنید",
        "hashtags_most_used": "هشتگ های پر استفاده",
        "can_add_it_giving_click": "شما می توانید با کلیک بر روی آنها به جستجوی خود اضافه کنید",
        "searching_files": "جستجو در فایل ها",
        "no_files_found": "فایلی پیدا نشد",
        "image": "تصویر",
        "video": "ویدئو",
        "audio": "سمعی",
        "library": "کتابفروشی",
        "home": "شروع کنید",
        "block": "مسدود کردن",
        "unblock": "برای باز کردن",
        "has_blocked_you": "او شما را بلاک کرده است",
        "accept": "تایید کنید",
        "decline": "کاهش می یابد",
        "has_been_expulsed": "اخراج شده است",
        "room_doesnt_exists": "اتاق وجود ندارد",
        "you_arent_room_member": "شما عضو اتاق نیستید",
        "create_room": "اتاق ایجاد کنید",
        "room_name": "اسم اتاق",
        "no_rooms_created": "اتاقی ایجاد نشده است.",
        "dont_belong_to_anyone_room": "تو به هیچ اتاقی تعلق نداری.",
        "enter_a_username": "یک کاربر بنویسید",
        "no_results": "هیچ نتیجه ای",
        "create": "ايجاد كردن",
        "no_content_uploaded": "هیچ محتوایی آپلود نشده است",
        "new_subtag": "برچسب فرعی جدید",
        "update_info": "به روز رسانی اطلاعات",
        "updating": "در حال بروز رسانی",
        "update": "به روز رسانی",
        "update_email": "ایمیل را به روز کنید",
        "change_password": "رمز عبور را تغییر دهید",
        "current_password": "رمز عبور فعلی",
        "select": "انتخاب کنید",
        "tap_to_upload_a_file": "برای آپلود فایل ضربه بزنید",
        "edit_photo": "ویرایش عکس",
        "preview": "پیش نمایش",
        "edit_video": "ویدئو را ویرایش کنید",
        "delete_changes": "حذف تغییرات",
        "uploading": "بالا رفتن",
        "session_deleted_or_expired": "جلسه حذف شده یا منقضی شده است",
        "edit_profile": "ویرایش نمایه",
        "devices": "دستگاه ها",
        "downloads": "دانلودها",
        "logout": "ثبت نام کنید",
        "offline": "بدون اتصال",
        "images": "تصاویر",
        "videos": "فیلم های",
        "audios": "فایل های صوتی",
        "chat": "چت کنید",
        "session_unauthorized": "جلسه شما مجاز نیست.",
        "authorize_session": "برای دسترسی به این جلسه، باید دستگاه خود را تأیید کنید.",
        "authorize_instructions": "لطفاً ایمیل خود را برای پیوند مجوز بررسی کنید یا به یک جلسه فعال بروید\n        و سه نقطه در گوشه بالا سمت راست را انتخاب کنید، سپس به Devices بروید و Authorize را انتخاب کنید\".",
        "refresh_page_after_authorize": "پس از تکمیل فرآیند مجوز، لطفاً صفحه را برای دسترسی به جلسه بازخوانی کنید.",
        "check_your_inbox": "ایمیلتان را چک کنید",
        "send_authorization_email": "ارسال ایمیل مجوز",
        "call_finished": "مکالمه تلفنی تمام شد",
        "close": "بستن",
        "waiting_stream": "در انتظار انتقال",
        "request_camera_and_mic": "ما به دوربین و میکروفون شما نیاز داریم تا بتوانید تماس بگیرید.",
        "call_in_progress": "تماس در حال انجام است",
        "group": "خوشه",
        "videocall": "تماس تصویری",
        "call": "زنگ زدن",
        "connecting": "برقراری ارتباط",
        "call_cancelled": "تماس لغو شد",
        "calling": "صدا زدن",
        "hangup": "آویزان شدن",
        "reject": "کاهش می یابد",
        "has_sent_a_file": "فایل ارسال کرده است.",
        "keep": "نگاه داشتن",
        "keep_file": "اگر نگه داشتن فایل را فعال کنید، در برنامه ذخیره می شود.\n        این امکان دسترسی به آن را بدون نیاز به اتصال به اینترنت فراهم می کند..",
        "not_keep_file": "اگر نگهداری فایل را غیرفعال کنید، حذف می شود و بدون اتصال به اینترنت نمی توانید به آن دسترسی داشته باشید..",
        "file_stored_instruction": "همه فایل های ذخیره شده با یک حاشیه سبز در لیست فایل های رایج ظاهر می شوند.\n        شما همچنین می توانید آنها را در بخش مشاهده کنید",
        "stored": "ذخیره شده",
        "not_stored": "ذخیره نمی شود",
        "file_not_stored_instruction": "بدون اتصال به اینترنت نمی توانید به این فایل دسترسی داشته باشید.",
        "device": "دستگاه",
        "file_visibility": "قابلیت مشاهده فایل",
        "visible_for_everyone": "برای همه قابل مشاهده است",
        "shared_link": "لینک مشترک",
        "hashtags": "هشتگ ها",
        "hashtags_helps_to_search_your_file": "هشتگ ها به شما کمک می کنند تا فایل های خود را پیدا کنید.",
        "edit": "ویرایش کنید",
        "move": "حرکت",
        "rename": "تغییر نام دهید",
        "visibility": "دید",
        "delete": "از بین بردن",
        "file_warn_delete": "آیا مطمئن هستید که می خواهید فایل را حذف کنید؟",
        "no_subtags_availables": "هیچ زیربرچسبی در دسترس نیست",
        "owner": "ایجاد کننده",
        "expulsing": "اخراج کردن",
        "expulse": "اخراج کردن",
        "you_have_left_the_group": "شما گروه را ترک کردید",
        "coming_out": "بیرون آمدن",
        "leave_group": "گروه رو ترک کن",
        "you_have_left_the_room": "شما از اتاق خارج شده اید.",
        "leave_room": "گروه را ترک کن",
        "message_deleted": "پیام حذف شده",
        "has_been_error_deleting_message": "هنگام حذف پیام خطایی روی داد",
        "deleting": "حذف کردن",
        "new_tag": "برچسب جدید",
        "photoEditor": {
            "name": "نام",
            "save": "پایان",
            "saveAs": "ذخیره به عنوان",
            "back": "بازگشت",
            "loading": "شارژ کردن...",
            "resetOperations": "راه اندازی مجدد / حذف تمام عملیات.",
            "changesLoseConfirmation": "همه تغییرات حذف خواهد شد.",
            "changesLoseConfirmationHint": "آیا مطمئن هستید که میخواهید ادامه دهید؟",
            "cancel": "لغو کنید",
            "continue": "ادامه هید",
            "undoTitle": "لغو آخرین عملیات",
            "redoTitle": "آخرین عملیات را دوباره انجام دهید",
            "showImageTitle": "نمایش تصویر اصلی",
            "zoomInTitle": "بزرگنمایی",
            "zoomOutTitle": "دور کن",
            "toggleZoomMenuTitle": "منوی بزرگنمایی را تغییر دهید",
            "adjustTab": "تنظیم کنید",
            "finetuneTab": "رنگ ها",
            "filtersTab": "فیلترها",
            "watermarkTab": "تصاویر",
            "annotateTab": "قرعه کشی",
            "resize": "تغییر اندازه",
            "resizeTab": "اندازه",
            "invalidImageError": "تصویر نامعتبر",
            "uploadImageError": "هنگام بارگیری تصویر خطایی روی داد.",
            "areNotImages": "آنها تصویر نیستند",
            "isNotImage": "این یک تصویر نیست",
            "toBeUploaded": "برای آپلود شدن",
            "cropTool": "برش",
            "original": "اصلی",
            "custom": "شخصی شده",
            "square": "مربع",
            "landscape": "افقی",
            "portrait": "عمودی",
            "ellipse": "دایره",
            "classicTv": "تلویزیون کلاسیک",
            "cinemascope": "سینماسکوپ",
            "arrowTool": "فلش",
            "blurTool": "محو کردن",
            "brightnessTool": "درخشش",
            "contrastTool": "تضاد",
            "ellipseTool": "دایره",
            "unFlipX": "تلنگر X",
            "flipX": "تلنگر X",
            "unFlipY": "تلنگر Y",
            "flipY": "تلنگر Y",
            "hsvTool": "MSV",
            "hue": "رنگ",
            "saturation": "اشباع",
            "value": "ارزش",
            "imageTool": "تصویر",
            "importing": "واردات...",
            "addImage": "+ تصویر اضافه کن",
            "uploadImage": "آپلود تصویر",
            "fromGallery": "استیکرها",
            "lineTool": "خط",
            "penTool": "مداد",
            "polygonTool": "چند ضلعی",
            "sides": "طرفین",
            "rectangleTool": "مستطیل",
            "cornerRadius": "شعاع لبه",
            "resizeWidthTitle": "عرض بر حسب پیکسل",
            "resizeHeightTitle": "ارتفاع پیکسل",
            "toggleRatioLockTitle": "قفل ابعاد را تغییر دهید",
            "reset": "راه اندازی مجدد",
            "resetSize": "بازنشانی به ابعاد تصویر اصلی",
            "rotateTool": "چرخش",
            "textTool": "متن",
            "textSpacings": "فاصله متن",
            "textAlignment": "تراز متن",
            "fontFamily": "آبنما",
            "size": "اندازه",
            "letterSpacing": "فاصله بین حروف",
            "lineHeight": "ارتفاع خط",
            "warmthTool": "حرارت",
            "addWatermark": "+ تصویر اضافه کن",
            "addWatermarkTitle": "Choose the watermark type",
            "uploadWatermark": "تصویر را انتخاب کنید",
            "addWatermarkAsText": "متن اضافه کنید",
            "padding": "پر شده",
            "shadow": "سایه",
            "horizontal": "افقی",
            "vertical": "عمودی",
            "blur": "محو کردن",
            "opacity": "کدورت",
            "position": "موقعیت",
            "stroke": "ضخامت",
            "saveAsModalLabel": "جزئیات",
            "extension": "افزونه",
            "nameIsRequired": "نام الزامی است.",
            "quality": "کیفیت",
            "imageDimensionsHoverTitle": "ابعاد تصویر ذخیره شده (width x height)",
            "cropSizeLowerThanResizedWarning": "لطفاً توجه داشته باشید که منطقه برش انتخابی کوچکتر از تغییر اندازه اعمال شده است، که می تواند باعث کاهش کیفیت شود.",
            "actualSize": "اندازه فعلی (100%)",
            "fitSize": "بعد را تطبیق دهید",
            "addImageTitle": "تصویری را برای افزودن انتخاب کنید..."
        },
        "changing": "Cambiando",
        "profile_picture_changed": "عکس پروفایل تغییر کرد",
        "loading_profile_picture": "در حال بارگیری عکس نمایه",
        "change": "تغییر دادن",
        "session": "جلسه",
        "system_operative": "سیستم عامل",
        "navigator": "مرورگر",
        "authorize": "مجاز کردن",
        "deauthorize": "رد کردن",
        "keep_tag_content": "آیا می خواهید محتوای تگ را حفظ کنید؟",
        "move_content_to": "انتقال محتوا به",
        "move_to": "حرکت به",
        "chat_request": "درخواست چت",
        "cancel_request": "ابطال درخواست",
        "accept_request": "قبول درخواست",
        "go_to_chat": "به چت بروید",
        "applying": "اعمال کردن",
        "time": "زمان",
        "adjust": "تنظیم کنید",
        "rotate": "چرخش",
        "start": "شروع کنید",
        "end": "نهایی",
        "lang_name": "Persa"
    },
    "pt": {
        "name": "Nome",
        "name_example": "Pedro",
        "last_name": "Sobrenome",
        "last_name_example": "Salinas",
        "username": "Do utilizador",
        "username_example": "pedro_salinas",
        "email": "E-mail",
        "email_example": "usuario@dominio.com",
        "password": "Senha",
        "new_password": "Nova Senha",
        "password_example": "*****************",
        "login": "Conecte-se",
        "register": "Registro",
        "verification_code": "C�digo de verifica��o",
        "verification_code_example": "xxx",
        "verify": "Verificar",
        "loading": "Carregando...",
        "please_wait": "Espere por favor",
        "go_back": "Retornar",
        "recovery": "Recuperar",
        "login_subtitle": "Insira seus dados e acesse sua conta.",
        "forgot_password_link": "Esqueceu sua senha? Recuperar.",
        "register_account_link": "Voc� n�o tem uma conta? Cadastre-se!",
        "register_subtitle": "Insira seus dados e registre sua conta.",
        "login_link": "Voc� j� tem uma conta? Entrar!",
        "verification_code_instruction": "Verifique seu e-mail e digite o c�digo enviado.",
        "forgot_password_subtitle": "Digite seu e-mail para recuperar sua senha.",
        "change_password_subtitle": "Insira uma nova senha.",
        "change_password_code_used": "O c�digo para recuperar seus dados � inv�lido ou nunca foi usado.",
        "no_chats": "Voc� n�o tem conversas.",
        "chats": "Conversas",
        "groups": "Grupos",
        "rooms": "quartos",
        "save": "Manter",
        "download": "Descarga",
        "downloading_image": "Baixando imagem",
        "an_error_has_ocurred": "ocorreu um erro",
        "saving_changes": "Salvando altera��es",
        "go_back_to_edit": "Edite novamente",
        "upload_warning_modal": "O upload da edi��o substituir� a imagem original.",
        "upload_question_modal": "Quer manter o original e criar uma c�pia?",
        "create_copy_and_keep": "Crie uma c�pia e guarde",
        "upload": "Aumentar",
        "upload_changes": "Carregar altera��es",
        "changes_saved": "Altera��es salvas",
        "downloading_video": "Baixando v�deo",
        "video_upload_warning_modal": "Enviar a edi��o substituir� o v�deo original.",
        "results": "Resultados",
        "members": "Membros",
        "cancel": "Cancelar",
        "add_user": "Adicionar usu�rio",
        "no_messages": "Sem mensagens",
        "message_placeholder": "Mensagem...",
        "group_doesnt_exists": "O grupo n�o existe",
        "you_arent_group_member": "Voc� n�o � membro do grupo",
        "touch_to_see_more_info": "Toque para ver mais informa��es.",
        "start_videocall": "Iniciar videochamada",
        "start_call": "Iniciar chamada",
        "create_group": "Criar grupo",
        "group_name": "Nome do grupo",
        "add_members_to_create_group": "Adicione membros para criar o grupo",
        "search": "Procurar",
        "creating": "Criando...",
        "dont_belong_anyone_group": "Voc� n�o pertence a nenhum grupo.",
        "already_add_this_hashtag": "Voc� j� adicionou essa hashtag",
        "search_files_by_hashtags": "Pesquise arquivos por hashtags",
        "enter_to_add": "Entre para adicionar",
        "hashtags_most_used": "Hashtags mais usadas",
        "can_add_it_giving_click": "Voc� pode adicion�-los � sua pesquisa clicando neles",
        "searching_files": "Pesquisando arquivos",
        "no_files_found": "Nenhum arquivo encontrado",
        "image": "Imagem",
        "video": "V�deo",
        "audio": "�udio",
        "library": "Livraria",
        "home": "Come�ar",
        "block": "Bloquear",
        "unblock": "Desbloquear",
        "has_blocked_you": "Ele bloqueou voc�",
        "accept": "Aceitar",
        "decline": "Decl�nio",
        "cancel_request": "Cancelar pedido",
        "has_been_expulsed": "foi expulso",
        "room_doesnt_exists": "A sala n�o existe",
        "you_arent_room_member": "Voc� n�o � um membro da sala",
        "create_room": "Criar quarto",
        "room_name": "Nome da sala",
        "no_rooms_created": "N�o h� salas criadas.",
        "dont_belong_to_anyone_room": "Voc� n�o pertence a nenhum quarto.",
        "enter_a_username": "Escreva um usu�rio",
        "no_results": "N�o h� resultados",
        "create": "Criar",
        "no_content_uploaded": "Nenhum conte�do enviado",
        "new_subtag": "Nova subtag",
        "update_info": "Atualizar informa��o",
        "updating": "Atualizando",
        "update": "Atualizar",
        "update_email": "Atualizar e-mail",
        "change_password": "Alterar a senha",
        "current_password": "Senha atual",
        "select": "Selecione",
        "tap_to_upload_a_file": "Toque para fazer upload de um arquivo",
        "edit_photo": "Editar foto",
        "preview": "Vista pr�via",
        "edit_video": "Editar v�deo",
        "delete_changes": "Excluir altera��es",
        "uploading": "Subindo",
        "session_deleted_or_expired": "Sess�o exclu�da ou expirada",
        "edit_profile": "Editar perfil",
        "devices": "Dispositivos",
        "downloads": "Baixar",
        "logout": "Sair",
        "offline": "Desligada",
        "images": "Imagens",
        "videos": "V�deos",
        "audios": "�udios",
        "chat": "Bater papo",
        "session_unauthorized": "Sua sess�o n�o est� autorizada.",
        "authorize_session": "Para acessar esta sess�o, voc� precisa autorizar seu dispositivo.",
        "authorize_instructions": "Por favor, verifique seu e-mail para obter o link de autoriza��o ou acesse uma sess�o ativa\n        e selecione os tr�s pontos no canto superior direito, depois v� em �Dispositivos� e escolha �Autorizar\".",
        "refresh_page_after_authorize": "Depois de concluir o processo de autoriza��o, atualize a p�gina para acessar a sess�o.",
        "check_your_inbox": "Verifique seu e-mail",
        "send_authorization_email": "Enviar email de autoriza��o",
        "call_finished": "Chamada finalizada",
        "close": "Fechar",
        "waiting_stream": "Aguardando transmiss�o",
        "request_camera_and_mic": "Precisamos de acesso � sua c�mera e microfone para que voc� possa fazer chamadas.",
        "call_in_progress": "Chamada em andamento",
        "group": "Conjunto",
        "videocall": "Video chamada",
        "call": "Chamar",
        "connecting": "Conectando",
        "call_cancelled": "Chamada cancelada",
        "calling": "Chamando",
        "hangup": "Pendurar",
        "reject": "Decl�nio",
        "has_sent_a_file": "Enviou um arquivo.",
        "keep": "Manter",
        "keep_file": "Se voc� ativar a manuten��o do arquivo, ele ser� salvo no aplicativo.\n        Isso permitir� sua acessibilidade sem a necessidade de conex�o com a internet..",
        "not_keep_file": "Se voc� desativar a manuten��o do arquivo, ele ser� exclu�do e voc� n�o poder� acess�-lo sem uma conex�o com a Internet.",
        "file_stored_instruction": "Todos os arquivos armazenados aparecer�o com uma borda verde na lista de arquivos comuns.\n        Voc� tamb�m pode v�-los na se��o",
        "stored": "Armazenado",
        "not_stored": "N�o est� armazenado",
        "file_not_stored_instruction": "Voc� n�o poder� acessar este arquivo sem uma conex�o com a Internet.",
        "device": "Dispositivo",
        "file_visibility": "Visibilidade do arquivo",
        "visible_for_everyone": "Vis�vel para todos",
        "shared_link": "Link compartilhado",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Hashtags ajudam voc� a encontrar seus arquivos.",
        "edit": "Editar",
        "move": "Mover",
        "rename": "Renomear",
        "visibility": "Visibilidade",
        "delete": "Eliminar",
        "file_warn_delete": "Tem certeza de que deseja excluir o arquivo?",
        "no_subtags_availables": "Nenhuma subtag dispon�vel",
        "owner": "O Criador",
        "expulsing": "Expulsando",
        "expulse": "Expulsar",
        "you_have_left_the_group": "Voc� saiu do grupo",
        "coming_out": "Saindo",
        "leave_group": "Sair do grupo",
        "you_have_left_the_room": "Voc� saiu da sala.",
        "leave_room": "Sair do grupo",
        "message_deleted": "Mensagem exclu�da",
        "has_been_error_deleting_message": "Ocorreu um erro ao excluir a mensagem",
        "deleting": "Removendo",
        "new_tag": "Nova etiqueta",
        "photoEditor": {
            "name": "Nome",
            "save": "Terminar",
            "saveAs": "Salvar como",
            "back": "Voltar",
            "loading": "Carregando...",
            "resetOperations": "Reinicie/exclua todas as opera��es.",
            "changesLoseConfirmation": "Todas as altera��es ser�o removidas.",
            "changesLoseConfirmationHint": "Voc� tem certeza que quer continuar??",
            "cancel": "Cancelar",
            "continue": "Continuar",
            "undoTitle": "Desfazer a �ltima opera��o",
            "redoTitle": "Refazer a �ltima opera��o",
            "showImageTitle": "Mostrar imagem original",
            "zoomInTitle": "Mais Zoom",
            "zoomOutTitle": "Afastar",
            "toggleZoomMenuTitle": "Alternar menu de zoom",
            "adjustTab": "Ajustar",
            "finetuneTab": "Cores",
            "filtersTab": "Filtros",
            "watermarkTab": "Imagens",
            "annotateTab": "Empate",
            "resize": "Redimensionar",
            "resizeTab": "Tamanho",
            "invalidImageError": "Imagem inv�lida",
            "uploadImageError": "Ocorreu um erro ao carregar a imagem.",
            "areNotImages": "n�o s�o imagens",
            "isNotImage": "n�o � uma imagem",
            "toBeUploaded": "para ser carregado",
            "cropTool": "Corte",
            "original": "Original",
            "custom": "Personalizado",
            "square": "Quadrado",
            "landscape": "Horizontal",
            "portrait": "Vertical",
            "ellipse": "C�rculo",
            "classicTv": "Televis�o cl�ssica",
            "cinemascope": "Cinemasc�pio",
            "arrowTool": "Seta",
            "blurTool": "Borr�o",
            "brightnessTool": "Brilho",
            "contrastTool": "Contraste",
            "ellipseTool": "C�rculo",
            "unFlipX": "Virar X",
            "flipX": "Virar X",
            "unFlipY": "Virar Y",
            "flipY": "Virar Y",
            "hsvTool": "MSV",
            "hue": "Matiz",
            "saturation": "Satura��o",
            "value": "Valor",
            "imageTool": "Imagem",
            "importing": "Importando...",
            "addImage": "+ Adicionar imagem",
            "uploadImage": "Enviar Imagem",
            "fromGallery": "Stickers",
            "lineTool": "Linha",
            "penTool": "L�pis",
            "polygonTool": "Pol�gono",
            "sides": "Lados",
            "rectangleTool": "Ret�ngulo",
            "cornerRadius": "Raio da borda",
            "resizeWidthTitle": "Largura em pixels",
            "resizeHeightTitle": "Alto em pixels",
            "toggleRatioLockTitle": "Alternar bloqueio de dimens�o",
            "reset": "Reiniciar",
            "resetSize": "Redefinir para as dimens�es originais da imagem",
            "rotateTool": "Girar",
            "textTool": "Texto",
            "textSpacings": "Espa�amento de texto",
            "textAlignment": "Alinhamento de Texto",
            "fontFamily": "Fonte",
            "size": "Tamanho",
            "letterSpacing": "Espa�amento entre letras",
            "lineHeight": "Altura da linha",
            "warmthTool": "Aquecer",
            "addWatermark": "+ Adicionar imagem",
            "addWatermarkTitle": "Choose the watermark type",
            "uploadWatermark": "Selecione a imagem",
            "addWatermarkAsText": "Adicione texto",
            "padding": "Recheado",
            "shadow": "Sombra",
            "horizontal": "Horizontal",
            "vertical": "Vertical",
            "blur": "Borr�o",
            "opacity": "Opacidade",
            "position": "Posi��o",
            "stroke": "Grossura",
            "saveAsModalLabel": "Detalhes",
            "extension": "Extens�o",
            "nameIsRequired": "O nome � obrigat�rio.",
            "quality": "Qualidade",
            "imageDimensionsHoverTitle": "Dimens�es da imagem salva (width x height)",
            "cropSizeLowerThanResizedWarning": "Observe que a �rea de corte selecionada � menor que o redimensionamento aplicado, o que pode causar uma diminui��o na qualidade.",
            "actualSize": "Tamanho atual(100%)",
            "fitSize": "Adaptar dimens�o",
            "addImageTitle": "Selecione uma imagem para adicionar..."
        },
        "changing": "Mudando",
        "profile_picture_changed": "Foto do perfil alterada",
        "loading_profile_picture": "Carregando foto do perfil",
        "change": "Mudar",
        "session": "Sess�o",
        "system_operative": "Sistema operativo",
        "navigator": "Navegador",
        "authorize": "Autorizar",
        "deauthorize": "Desautorizar",
        "keep_tag_content": "Quer manter o conte�do da tag?",
        "move_content_to": "Mover conte�do para",
        "move_to": "Mover para",
        "chat_request": "Solicita��o de bate-papo",
        "accept_request": "Aceitar pedido",
        "go_to_chat": "V� para o bate-papo",
        "applying": "Aplicando",
        "time": "Tempo",
        "adjust": "Ajustar",
        "rotate": "Girar",
        "start": "Come�ar",
        "end": "Final",
        "lang_name": "Portuguese"
    },
    "ro": {
        "name": "Nombre",
        "name_example": "Pedro",
        "last_name": "Apellido",
        "last_name_example": "Salinas",
        "username": "Usuario",
        "username_example": "pedro_salinas",
        "email": "Correo electrónico",
        "email_example": "usuario@dominio.com",
        "password": "Contraseña",
        "new_password": "Nueva contraseña",
        "password_example": "*****************",
        "login": "Iniciar Sesión",
        "register": "Registrarte",
        "verification_code": "Código de verificación",
        "verification_code_example": "xxx",
        "verify": "Verificar",
        "loading": "Cargando...",
        "please_wait": "Espere por favor",
        "go_back": "Volver",
        "recovery": "Recuperar",
        "login_subtitle": "Introduce tus datos y accede a tu cuenta.",
        "forgot_password_link": "¿Olvidaste tu contraseña? Recuperar.",
        "register_account_link": "¿No tienes cuenta? ¡Registrate!",
        "register_subtitle": "Introduce tus datos y registra tu cuenta.",
        "login_link": "¿Ya tienes cuenta? ¡Inicia sesión!",
        "verification_code_instruction": "Revisa tu correo electrónico e introduce el código enviado.",
        "forgot_password_subtitle": "Introduce tu correo electrónico para recuperar tu contraseña.",
        "change_password_subtitle": "Introduce una contraseña nueva.",
        "change_password_code_used": "El código para recuperar tu contraseña es inválido o ya fue usado.",
        "no_chats": "No tienes conversaciones.",
        "chats": "Conversaciones",
        "groups": "Grupos",
        "rooms": "Salas",
        "save": "Guardar",
        "download": "Descargar",
        "downloading_image": "Descargando imágen",
        "an_error_has_ocurred": "Ha ocurrido un error",
        "saving_changes": "Guardando cambios",
        "go_back_to_edit": "Volver a editar",
        "upload_warning_modal": "Al subir la edición se reemplazará la imágen original.",
        "upload_question_modal": "¿Quieres conservar la original y crear una copia?",
        "create_copy_and_keep": "Crear copia y conservar",
        "upload": "Subir",
        "upload_changes": "Subir cambios",
        "changes_saved": "Cambios guardados",
        "downloading_video": "Descargando vídeo",
        "video_upload_warning_modal": "Al subir la edición se reemplazará el vídeo original.",
        "results": "Resultados",
        "members": "Miembros",
        "cancel": "Cancelar",
        "add_user": "Agregar usuario",
        "no_messages": "No hay mensajes",
        "message_placeholder": "Mensaje...",
        "group_doesnt_exists": "El grupo no existe",
        "you_arent_group_member": "No eres miembro del grupo",
        "touch_to_see_more_info": "Toca para ver más info.",
        "start_videocall": "Iniciar videollamada",
        "start_call": "Iniciar llamada",
        "create_group": "Crear group",
        "group_name": "Nombre del grupo",
        "add_members_to_create_group": "Agrega miembros para crear el grupo",
        "search": "Buscar",
        "creating": "Creando...",
        "dont_belong_anyone_group": "No perteneces a ningún grupo.",
        "already_add_this_hashtag": "Ya has agregaste ese hashtag",
        "search_files_by_hashtags": "Buscar archivos por hashtags",
        "enter_to_add": "Enter para agregar",
        "hashtags_most_used": "Hashtags más usados",
        "can_add_it_giving_click": "Puedes agregar estos a tu búsqueda dándoles click",
        "searching_files": "Buscando archivos",
        "no_files_found": "No se encontraron archivos",
        "image": "Imágen",
        "video": "Vídeo",
        "audio": "Audio",
        "library": "Librería",
        "home": "Inicio",
        "block": "Bloquear",
        "unblock": "Desbloquear",
        "has_blocked_you": "Te ha bloqueado",
        "accept": "Aceptar",
        "decline": "Rechazar",
        "cancel_request": "Cancelar solicitud",
        "has_been_expulsed": "ha sido expulsado",
        "room_doesnt_exists": "La sala no existe",
        "you_arent_room_member": "No eres miembro de la sala",
        "create_room": "Crear sala",
        "room_name": "Nombre de la sala",
        "no_rooms_created": "No hay salas creadas.",
        "dont_belong_to_anyone_room": "No perteneces a ninguna sala.",
        "enter_a_username": "Escribe un usuario",
        "no_results": "No hay resultados",
        "create": "Crear",
        "no_content_uploaded": "No hay contenido subido",
        "new_subtag": "Nuevo subtag",
        "update_info": "Actualizar información",
        "updating": "Actualizando",
        "update": "Actualizar",
        "update_email": "Actualizar email",
        "change_password": "Cambiar contraseña",
        "current_password": "Contraseña actual",
        "select": "Seleccionar",
        "tap_to_upload_a_file": "Toca para cargar un archivo",
        "edit_photo": "Editar foto",
        "preview": "Vista previa",
        "edit_video": "Editar vídeo",
        "delete_changes": "Eliminar cambios",
        "uploading": "Subiendo",
        "session_deleted_or_expired": "Sesión elimada o expirada",
        "edit_profile": "Editar perfil",
        "devices": "Dispositivos",
        "downloads": "Descargas",
        "logout": "Cerrar sesión",
        "offline": "Sin conexión",
        "images": "Imágenes",
        "videos": "Vídeos",
        "audios": "Audios",
        "chat": "Chat",
        "session_unauthorized": "Tu sesión no está autorizada.",
        "authorize_session": "Para acceder a esta sesión, necesitas autorizar tu dispositivo.",
        "authorize_instructions": "Por favor, revisa tu correo electrónico para encontrar el enlace de autorización o dirígete a una sesión activa\n        y selecciona los tres puntos en la esquina superior derecha, luego ve a \"Dispositivos\" y elige \"Autorizar\".",
        "refresh_page_after_authorize": "Una vez que hayas completado el proceso de autorización, por favor refresca la página para acceder a la sesión.",
        "check_your_inbox": "Revisa tu correo electrónico",
        "send_authorization_email": "Enviar correo de autorización",
        "call_finished": "Llamada finalizada",
        "close": "Cerrar",
        "waiting_stream": "Esperando transmisión",
        "request_camera_and_mic": "Necesitamos acceder a tu cámara y micrófono para que pueda realizar llamadas.",
        "call_in_progress": "Llamada en curso",
        "group": "Grupo",
        "videocall": "Videollamada",
        "call": "Llamada",
        "connecting": "Conectando",
        "call_cancelled": "Llamada cancelada",
        "calling": "Llamando",
        "hangup": "Colgar",
        "reject": "Rechazar",
        "has_sent_a_file": "ha enviado un archivo.",
        "keep": "Conservar",
        "keep_file": "Sí habilitas conservar el archivo, este se guardará en la aplicación.\n        Esto permitirá su accesibilidad sin que requieras conexión a internet.",
        "not_keep_file": "Sí deshabilitas conservar el archivo, este se eliminará y no podrás acceder a él sin conexión a internet.",
        "file_stored_instruction": "Todos los archivos almacenados apareceran con un borde verde en la lista común de archivos.\n        También puedes verlos en la sección de",
        "stored": "Almacenado",
        "not_stored": "No está almacenado",
        "file_not_stored_instruction": "No podrás acceder a este archivo sin conexión a internet.",
        "device": "Dispositivo",
        "file_visibility": "Visibilidad del archivo",
        "visible_for_everyone": "Visible para todos",
        "shared_link": "Enlace compartido",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Los hashtags ayudan a la búsqueda de tus archivos.",
        "edit": "Editar",
        "move": "Mover",
        "rename": "Renombrar",
        "visibility": "Visibilidad",
        "delete": "Eliminar",
        "file_warn_delete": "¿Seguro que deseas eliminar el archivo?",
        "no_subtags_availables": "No hay subtags disponibles",
        "owner": "Creador",
        "expulsing": "Expulsando",
        "expulse": "Expulsar",
        "you_have_left_the_group": "Has salido del grupo",
        "coming_out": "Saliendo",
        "leave_group": "Salir del grupo",
        "you_have_left_the_room": "Has salido de la sala.",
        "leave_room": "Salir del grupo",
        "message_deleted": "Mensaje eliminado",
        "has_been_error_deleting_message": "Ha ocurrido un error eliminando el mensaje",
        "deleting": "Eliminando",
        "new_tag": "Nuevo tag",
        "photoEditor": {
            "name": "Nombre",
            "save": "Finalizar",
            "saveAs": "Guardar como",
            "back": "Atrás",
            "loading": "Cargando...",
            "resetOperations": "Reiniciar/Eliminar todas las operaciones.",
            "changesLoseConfirmation": "Todos los cambios serán eliminados.",
            "changesLoseConfirmationHint": "¿Estás seguro que quieres continuar?",
            "cancel": "Cancelar",
            "continue": "Continuar",
            "undoTitle": "Deshacer última operación",
            "redoTitle": "Rehacer última operación",
            "showImageTitle": "Mostrar imágen original",
            "zoomInTitle": "Acercar",
            "zoomOutTitle": "Alejar",
            "toggleZoomMenuTitle": "Alternar menú de zoom",
            "adjustTab": "Ajustar",
            "finetuneTab": "Colores",
            "filtersTab": "Filtros",
            "watermarkTab": "Imágenes",
            "annotateTab": "Dibujar",
            "resize": "Redimensionar",
            "resizeTab": "Tamaño",
            "invalidImageError": "Imagen inválida",
            "uploadImageError": "Ha ocurrido un error mientras se cargaba la imágen.",
            "areNotImages": "no son imágenes",
            "isNotImage": "no es una imágen",
            "toBeUploaded": "para ser subido",
            "cropTool": "Cortar",
            "original": "Original",
            "custom": "Personalizado",
            "square": "Cuadrado",
            "landscape": "Horizontal",
            "portrait": "Vertical",
            "ellipse": "Círuclo",
            "classicTv": "Tv clásica",
            "cinemascope": "Cinemascope",
            "arrowTool": "Flecha",
            "blurTool": "Desenfoque",
            "brightnessTool": "Brillo",
            "contrastTool": "Contraste",
            "ellipseTool": "Círuclo",
            "unFlipX": "Voltear X",
            "flipX": "Voltear X",
            "unFlipY": "Voltear Y",
            "flipY": "Voltear Y",
            "hsvTool": "MSV",
            "hue": "Matiz",
            "saturation": "Saturación",
            "value": "Valor",
            "imageTool": "Imagen",
            "importing": "Importando...",
            "addImage": "+ Agregar imagen",
            "uploadImage": "Subir imagen",
            "fromGallery": "Stickers",
            "lineTool": "Línea",
            "penTool": "Lápiz",
            "polygonTool": "Polígono",
            "sides": "Lados",
            "rectangleTool": "Rectángulo",
            "cornerRadius": "Radio de los bordes",
            "resizeWidthTitle": "Ancho en píxeles",
            "resizeHeightTitle": "Alto en píxeles",
            "toggleRatioLockTitle": "Alternar bloqueo de dimensión",
            "reset": "Reiniciar",
            "resetSize": "Reiniciar a las dimensiones originales de la imágen",
            "rotateTool": "Rotar",
            "textTool": "Texto",
            "textSpacings": "Espaciado de texto",
            "textAlignment": "Aliniación del texto",
            "fontFamily": "Fuente",
            "size": "Tamaño",
            "letterSpacing": "Espaciado de letras",
            "lineHeight": "Altura de la línea",
            "warmthTool": "Calor",
            "addWatermark": "+ Añadir imágen",
            "addWatermarkTitle": "Choose the watermark type",
            "uploadWatermark": "Seleccionar imágen",
            "addWatermarkAsText": "Agregar texto",
            "padding": "Relleno",
            "shadow": "Sombra",
            "horizontal": "Horizontal",
            "vertical": "Vertical",
            "blur": "Desenfoque",
            "opacity": "Opacidad",
            "position": "Posición",
            "stroke": "Grosor",
            "saveAsModalLabel": "Detalles",
            "extension": "Extensión",
            "nameIsRequired": "El nombre es requerido.",
            "quality": "Calidad",
            "imageDimensionsHoverTitle": "Dimensiones de la imágen guardadas (width x height)",
            "cropSizeLowerThanResizedWarning": "Tenga en cuenta que el área de recorte seleccionada es menor que el cambio de tamaño aplicado, lo que podría causar una disminución de la calidad.",
            "actualSize": "Tamaño actual (100%)",
            "fitSize": "Adaptar dimensión",
            "addImageTitle": "Selecciona una imágen para agregar..."
        },
        "changing": "Cambiando",
        "profile_picture_changed": "Foto de perfil cambiada",
        "loading_profile_picture": "Cargando foto de perfil",
        "change": "Cambiar",
        "session": "Sesión",
        "system_operative": "Sistema operativo",
        "navigator": "Navegador",
        "authorize": "Autorizar",
        "deauthorize": "Desautorizar",
        "keep_tag_content": "¿Quieres conservar el contenido del tag?",
        "move_content_to": "Mover contenido a",
        "move_to": "Mover a",
        "chat_request": "Solicitud de chat",
        "accept_request": "Aceptar solicitud",
        "go_to_chat": "Ir al chat",
        "applying": "Aplicando",
        "time": "Tiempo",
        "adjust": "Ajustar",
        "rotate": "Rotar",
        "start": "Inicio",
        "end": "Final",
        "lang_name": "Rumanian"
    },
    "sk": {
        "name": "Názov",
        "name_example": "Anna",
        "last_name": "Priezvisko",
        "last_name_example": "Varga",
        "username": "Používateľ",
        "username_example": "anna_varga",
        "email": "E-mailová adresa",
        "email_example": "používateľ@doména.com",
        "password": "Heslo",
        "new_password": "Nové heslo",
        "password_example": "*****************",
        "login": "Prihlásenie",
        "register": "Registrácia",
        "verification_code": "Overovací kód",
        "verification_code_example": "xxx",
        "verify": "Skontrolujte",
        "loading": "Načítavanie…",
        "please_wait": "Počkajte, prosím",
        "go_back": "Späť na",
        "recovery": "Recovery",
        "login_subtitle": "Zadajte svoje údaje a prihláste sa do svojho konta.",
        "forgot_password_link": "Zabudli ste heslo? Vyvolať.",
        "register_account_link": "Nemáte účet? Zaregistrujte sa na",
        "register_subtitle": "Zadajte svoje údaje a zaregistrujte si konto.",
        "login_link": "Už máte účet? Prihláste sa",
        "verification_code_instruction": "Skontrolujte svoju e-mailovú adresu a zadajte kód, ktorý vám bol zaslaný.",
        "forgot_password_subtitle": "Zadajte svoju e-mailovú adresu a získajte heslo.",
        "change_password_subtitle": "Zadajte nové heslo.",
        "change_password_code_used": "Kód na obnovenie hesla je neplatný alebo už bol použitý.",
        "no_chats": "Nevediete rozhovory.",
        "chats": "Konverzácie",
        "groups": "Skupiny",
        "rooms": "Izby",
        "save": "Uložiť",
        "download": "Stiahnite si",
        "downloading_image": "Preberanie obrázku",
        "an_error_has_ocurred": "Vyskytla sa chyba",
        "saving_changes": "Uloženie zmien",
        "go_back_to_edit": "Späť na úpravu",
        "upload_warning_modal": "Nahratím úpravy sa nahradí pôvodný obrázok.",
        "upload_question_modal": "Chcete si ponechať originál a vytvoriť kópiu?",
        "create_copy_and_keep": "Vytvorte kópiu a uchovávajte",
        "upload": "Nahrať",
        "upload_changes": "Nahranie zmien",
        "changes_saved": "Uložené zmeny",
        "downloading_video": "Preberanie videa",
        "video_upload_warning_modal": "Nahratím úpravy sa nahradí pôvodné video.",
        "results": "Výsledky",
        "members": "Členovia",
        "cancel": "Zrušiť",
        "add_user": "Pridať používateľa",
        "no_messages": "Žiadne správy",
        "message_placeholder": "Správa…",
        "group_doesnt_exists": "Skupina neexistuje",
        "you_arent_group_member": "Nie ste členom skupiny",
        "touch_to_see_more_info": "Ťuknutím na položku zobrazíte ďalšie informácie.",
        "start_videocall": "Spustenie videohovoru",
        "start_call": "Spustenie hovoru",
        "create_group": "Vytvorenie skupiny",
        "group_name": "Názov skupiny",
        "add_members_to_create_group": "Pridanie členov na vytvorenie skupiny",
        "search": "Vyhľadávanie",
        "creating": "Vytváranie…",
        "dont_belong_anyone_group": "Nepatríte do žiadnej skupiny.",
        "already_add_this_hashtag": "Tento hashtag ste už pridali",
        "search_files_by_hashtags": "Vyhľadávanie súborov podľa hashtagov",
        "enter_to_add": "Zadajte, ak chcete pridať",
        "hashtags_most_used": "Najpoužívanejšie hashtagy",
        "can_add_it_giving_click": "Môžete ich pridať do vyhľadávania kliknutím na ne.",
        "searching_files": "Vyhľadávanie súborov",
        "no_files_found": "Nenašli sa žiadne súbory",
        "image": "Obrázok",
        "video": "Video",
        "audio": "Audio",
        "library": "Kníhkupectvo",
        "home": "Domovská stránka",
        "block": "Block",
        "unblock": "Odblokovanie",
        "has_blocked_you": "Zablokoval vás",
        "accept": "Prijať",
        "decline": "Odmietnuť",
        "cancel_request": "Zrušiť žiadosť",
        "has_been_expulsed": "bol vylúčený",
        "room_doesnt_exists": "Miestnosť neexistuje",
        "you_arent_room_member": "Nie ste členom miestnosti",
        "create_room": "Vytvorenie miestnosti",
        "room_name": "Názov miestnosti",
        "no_rooms_created": "Neexistujú žiadne vytvorené miestnosti.",
        "dont_belong_to_anyone_room": "Nepatríte do žiadnej miestnosti.",
        "enter_a_username": "Napísať používateľa",
        "no_results": "Žiadne výsledky",
        "create": "Vytvoriť",
        "no_content_uploaded": "Žiadny nahraný obsah",
        "new_subtag": "Nová podznačka",
        "update_info": "Aktualizácia informácií",
        "updating": "Aktualizácia",
        "update": "Aktualizovať",
        "update_email": "Aktualizácia e-mailu",
        "change_password": "Zmena hesla",
        "current_password": "Aktuálne heslo",
        "select": "Vyberte",
        "tap_to_upload_a_file": "Ťuknutím na položku nahráte súbor",
        "edit_photo": "Upraviť fotografiu",
        "preview": "Náhľad",
        "edit_video": "Upraviť video",
        "delete_changes": "Odstránenie zmien",
        "uploading": "Nahrávanie",
        "session_deleted_or_expired": "Relácia vymazaná alebo vypršala",
        "edit_profile": "Upraviť profil",
        "devices": "Prístroje",
        "downloads": "Súbory na stiahnutie",
        "logout": "Odhlásenie",
        "offline": "Offline",
        "images": "Obrázky",
        "videos": "Videá",
        "audios": "Audiá",
        "chat": "Chat",
        "session_unauthorized": "Vaša relácia nie je autorizovaná.",
        "authorize_session": "Ak chcete získať prístup k tejto relácii, musíte svoje zariadenie autorizovať.",
        "authorize_instructions": "V e-maile vyhľadajte prepojenie na autorizáciu alebo prejdite do aktívnej relácie a vyberte tri bodky v pravom hornom rohu, potom prejdite do časti \"Zariadenia\" a vyberte možnosť \"Autorizovať\".",
        "refresh_page_after_authorize": "Po dokončení procesu autorizácie obnovte stránku a prihláste sa.",
        "check_your_inbox": "Skontrolujte svoj e-mail",
        "send_authorization_email": "Odoslať autorizačný e-mail",
        "call_finished": "Hovor ukončený",
        "close": "Zatvoriť",
        "waiting_stream": "Čaká sa na prenos",
        "request_camera_and_mic": "Potrebujeme prístup ku kamere a mikrofónu, aby ste mohli telefonovať.",
        "call_in_progress": "Prebiehajúci hovor",
        "group": "Skupina",
        "videocall": "Video hovor",
        "call": "Hovor",
        "connecting": "Pripájanie",
        "call_cancelled": "Hovor zrušený",
        "calling": "Volanie",
        "hangup": "Zavesiť",
        "reject": "Odmietnuť",
        "has_sent_a_file": "odoslal súbor.",
        "keep": "Zachovať",
        "keep_file": "Ak aktivujete uchovávanie súborov, súbor sa uloží v aplikácii. To umožní prístupnosť bez potreby pripojenia na internet.",
        "not_keep_file": "Ak uchovávanie súborov zakážete, súbor sa odstráni a bez internetového pripojenia k nemu nebudete mať prístup.",
        "file_stored_instruction": "Všetky uložené súbory sa zobrazia so zeleným rámčekom v zozname spoločných súborov. Môžete ich tiež zobraziť v",
        "stored": "Uložené",
        "not_stored": "Nie je uložený",
        "file_not_stored_instruction": "K tomuto súboru nebudete mať prístup bez pripojenia na internet.",
        "device": "Prístroj",
        "file_visibility": "Viditeľnosť v archíve",
        "visible_for_everyone": "Viditeľné pre všetkých",
        "shared_link": "Zdieľané prepojenie",
        "hashtags": "Hashtagy",
        "hashtags_helps_to_search_your_file": "Hashtagy pomáhajú nájsť vaše súbory.",
        "edit": "Upraviť",
        "move": "Presun",
        "rename": "Premenovať",
        "visibility": "Viditeľnosť",
        "delete": "Odstrániť",
        "file_warn_delete": "Ste si istí, že chcete odstrániť súbor?",
        "no_subtags_availables": "Nie sú k dispozícii žiadne čiastkové značky",
        "owner": "Tvorca",
        "expulsing": "Vylúčenie",
        "expulse": "Expulse",
        "you_have_left_the_group": "Opustili ste skupinu",
        "coming_out": "Odchod",
        "leave_group": "Opustite skupinu",
        "you_have_left_the_room": "Opustili ste miestnosť.",
        "leave_room": "Odchod z miestnosti",
        "message_deleted": "Správa vymazaná",
        "has_been_error_deleting_message": "Pri odstraňovaní správy došlo k chybe",
        "deleting": "Odstránenie",
        "new_tag": "Nový štítok",
        "photoEditor": {
            "name": "Názov",
            "save": "Uložiť",
            "saveAs": "Uložiť ako",
            "back": "Späť",
            "loading": "Načítavanie…",
            "resetOperations": "Obnovenie/odstránenie všetkých operácií.",
            "changesLoseConfirmation": "Všetky zmeny sa vymažú.",
            "changesLoseConfirmationHint": "Určite chcete pokračovať?",
            "cancel": "Zrušiť",
            "continue": "Pokračovať",
            "undoTitle": "Zrušenie poslednej operácie",
            "redoTitle": "Zopakovať poslednú operáciu",
            "showImageTitle": "Ukázať pôvodný obrázok",
            "zoomInTitle": "Priblíženie",
            "zoomOutTitle": "Oddialenie",
            "toggleZoomMenuTitle": "Prepínanie ponuky priblíženia",
            "adjustTab": "Nastavenie",
            "finetuneTab": "Farby",
            "filtersTab": "Filtre",
            "watermarkTab": "Obrázky",
            "annotateTab": "Kreslenie",
            "resize": "Zmena veľkosti",
            "resizeTab": "Veľkosť",
            "invalidImageError": "Neplatný obrázok",
            "uploadImageError": "Pri načítavaní obrázka došlo k chybe.",
            "areNotImages": "nie sú to obrázky",
            "isNotImage": "nie je obrazom",
            "toBeUploaded": "ktoré sa majú nahrať",
            "cropTool": "Rez",
            "original": "Originál",
            "custom": "Personalizované",
            "square": "Štvorec",
            "landscape": "Horizontálne",
            "portrait": "Vertikálne",
            "ellipse": "Kruh",
            "classicTv": "Klasická TV",
            "cinemascope": "Cinemascope",
            "arrowTool": "Šípky",
            "blurTool": "Rozmazané",
            "brightnessTool": "Jas",
            "contrastTool": "Kontrast",
            "ellipseTool": "Kruh",
            "unFlipX": "Odklopiť X",
            "flipX": "Flip X",
            "unFlipY": "Odklopiť Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "Nuance",
            "saturation": "Nasýtenie",
            "value": "Hodnota",
            "imageTool": "Obrázok",
            "importing": "Importovanie…",
            "addImage": "+ Pridať obrázok",
            "uploadImage": "Nahrať obrázok",
            "fromGallery": "Nálepky",
            "lineTool": "Riadok",
            "penTool": "Ceruzka",
            "polygonTool": "Polygón",
            "sides": "Strany",
            "rectangleTool": "Obdĺžnik",
            "cornerRadius": "Polomer okraja",
            "resizeWidthTitle": "Šírka v pixeloch",
            "resizeHeightTitle": "Výška v pixeloch",
            "toggleRatioLockTitle": "Prepínanie zámku rozmerov",
            "reset": "Reštart",
            "resetSize": "Obnovenie pôvodných rozmerov obrázka",
            "rotateTool": "Otáčajte",
            "textTool": "Text",
            "textSpacings": "Odstupy medzi textom",
            "textAlignment": "Zarovnanie textu",
            "fontFamily": "Rodina písiem",
            "size": "Veľkosť",
            "letterSpacing": "Rozstupy písmen",
            "lineHeight": "Výška čiary",
            "warmthTool": "Teplo",
            "addWatermark": "+ Pridať obrázok",
            "addWatermarkTitle": "Výber typu vodoznaku",
            "uploadWatermark": "Vybrať obrázok",
            "addWatermarkAsText": "Pridať text",
            "padding": "Vypchávky",
            "shadow": "Stín",
            "horizontal": "Horizontálne",
            "vertical": "Vertikálne",
            "blur": "Rozmazané",
            "opacity": "Nepriehľadnosť",
            "position": "Pozícia",
            "stroke": "Hrúbka",
            "saveAsModalLabel": "Podrobnosti",
            "extension": "Predĺženie",
            "nameIsRequired": "Názov je povinný.",
            "quality": "Kvalita",
            "imageDimensionsHoverTitle": "Rozmery uloženého obrázka (šírka x výška)",
            "cropSizeLowerThanResizedWarning": "Všimnite si, že vybraná oblasť orezania je menšia ako použitá zmena veľkosti, čo môže mať za následok zníženie kvality.",
            "actualSize": "Aktuálna veľkosť (100%)",
            "fitSize": "Prispôsobenie rozmerov",
            "addImageTitle": "Vyberte obrázok, ktorý chcete pridať…"
        },
        "changing": "Zmena",
        "profile_picture_changed": "Profilový obrázok sa zmenil",
        "loading_profile_picture": "Načítanie profilového obrázka",
        "change": "Zmena",
        "session": "Zasadnutie",
        "system_operative": "Operačný systém",
        "navigator": "Prehliadač",
        "authorize": "Autorizovať",
        "deauthorize": "Zrušenie autorizácie",
        "keep_tag_content": "Chcete zachovať obsah tagu?",
        "move_content_to": "Presunúť obsah do",
        "move_to": "Presun do",
        "chat_request": "Žiadosť o chat",
        "accept_request": "Prijať žiadosť",
        "go_to_chat": "Prejsť na chat",
        "applying": "Uplatňovanie",
        "time": "Čas",
        "adjust": "Nastavenie",
        "rotate": "Otáčajte",
        "start": "Štart",
        "end": "Koniec",
        "lang_name": "Slovak"
    },
    "sl": {
        "name": "Ime",
        "name_example": "Marija",
        "last_name": "Priimek",
        "last_name_example": "Novak",
        "username": "Uporabnik",
        "username_example": "marija_novak",
        "email": "E-poštni naslov",
        "email_example": "uporabnik@domena.com",
        "password": "Geslo",
        "new_password": "Novo geslo",
        "password_example": "*****************",
        "login": "Prijava",
        "register": "Register",
        "verification_code": "Koda za preverjanje",
        "verification_code_example": "xxx",
        "verify": "Preveri",
        "loading": "Nalaganje…",
        "please_wait": "Prosimo, počakajte.",
        "go_back": "Nazaj na",
        "recovery": "Izterjava",
        "login_subtitle": "Vnesite svoje podatke in se prijavite v svoj račun.",
        "forgot_password_link": "Ste pozabili geslo? Pridobite.",
        "register_account_link": "Nimate računa? Registracija",
        "register_subtitle": "Vnesite svoje podatke in registrirajte račun.",
        "login_link": "Že imate račun? Prijavite se!",
        "verification_code_instruction": "Preverite svoje e-poštno sporočilo in vnesite poslano kodo.",
        "forgot_password_subtitle": "Za pridobitev gesla vnesite svoj e-poštni naslov.",
        "change_password_subtitle": "Vnesite novo geslo.",
        "change_password_code_used": "Koda za obnovitev gesla je neveljavna ali je bila že uporabljena.",
        "no_chats": "Nimate pogovorov.",
        "chats": "Pogovori",
        "groups": "Skupine",
        "rooms": "Sobe",
        "save": "Shrani",
        "download": "Prenesi",
        "downloading_image": "Prenašanje slik",
        "an_error_has_ocurred": "Prišlo je do napake",
        "saving_changes": "Shranjevanje sprememb",
        "go_back_to_edit": "Nazaj na urejanje",
        "upload_warning_modal": "Z nalaganjem urejanja se nadomesti izvirna slika.",
        "upload_question_modal": "Ali želite ohraniti izvirnik in narediti kopijo?",
        "create_copy_and_keep": "Ustvarite kopijo in jo shranite",
        "upload": "Naloži",
        "upload_changes": "Naložite spremembe",
        "changes_saved": "Shranjene spremembe",
        "downloading_video": "Prenos videoposnetka",
        "video_upload_warning_modal": "Z nalaganjem priredbe boste nadomestili izvirni videoposnetek.",
        "results": "Rezultati",
        "members": "Člani",
        "cancel": "Prekliči",
        "add_user": "Dodajanje uporabnika",
        "no_messages": "Brez sporočil",
        "message_placeholder": "Sporočilo…",
        "group_doesnt_exists": "Skupina ne obstaja",
        "you_arent_group_member": "Niste član skupine",
        "touch_to_see_more_info": "Tapnite, da si ogledate več informacij.",
        "start_videocall": "Začetek videoklica",
        "start_call": "Začetek klica",
        "create_group": "Ustvarjanje skupine",
        "group_name": "Ime skupine",
        "add_members_to_create_group": "Dodajte člane in ustvarite skupino",
        "search": "Iskanje",
        "creating": "Ustvarjanje…",
        "dont_belong_anyone_group": "Ne pripadate nobeni skupini.",
        "already_add_this_hashtag": "Ta hashtag ste že dodali",
        "search_files_by_hashtags": "Iskanje datotek po hashtagih",
        "enter_to_add": "Vnesite za dodajanje",
        "hashtags_most_used": "Najpogosteje uporabljeni hashtagi",
        "can_add_it_giving_click": "Iskanju jih lahko dodate tako, da jih kliknete.",
        "searching_files": "Iskanje datotek",
        "no_files_found": "Ni najdenih datotek",
        "image": "Slika",
        "video": "Video",
        "audio": "Audio",
        "library": "Knjigarna",
        "home": "Domača stran",
        "block": "Block",
        "unblock": "Odblokiranje",
        "has_blocked_you": "Blokiral vas je",
        "accept": "Sprejmite",
        "decline": "Zavrnite",
        "cancel_request": "Prekliči zahtevo",
        "has_been_expulsed": "je bil izgnan",
        "room_doesnt_exists": "Soba ne obstaja",
        "you_arent_room_member": "Niste član sobe",
        "create_room": "Ustvarite sobo",
        "room_name": "Ime sobe",
        "no_rooms_created": "Ni ustvarjenih sob.",
        "dont_belong_to_anyone_room": "Ne pripadate nobeni sobi.",
        "enter_a_username": "Napišite uporabnika",
        "no_results": "Brez rezultatov",
        "create": "Ustvarite",
        "no_content_uploaded": "Ni naložene vsebine",
        "new_subtag": "Nova podoznaka",
        "update_info": "Posodobitev informacij",
        "updating": "Posodabljanje",
        "update": "Posodobitev",
        "update_email": "Posodobitev e-pošte",
        "change_password": "Sprememba gesla",
        "current_password": "Trenutno geslo",
        "select": "Seleccionar",
        "tap_to_upload_a_file": "Tapnite , če želite naložiti datoteko",
        "edit_photo": "Uredi fotografijo",
        "preview": "Predogled",
        "edit_video": "Urejanje videoposnetka",
        "delete_changes": "Brisanje sprememb",
        "uploading": "Nalaganje",
        "session_deleted_or_expired": "Seja je izbrisana ali se je iztekla",
        "edit_profile": "Uredi profil",
        "devices": "Naprave",
        "downloads": "Prenosi",
        "logout": "Odjavite se",
        "offline": "Offline",
        "images": "Slike",
        "videos": "Videoposnetki",
        "audios": "Avdioposnetki",
        "chat": "Klepet",
        "session_unauthorized": "Vaša seja ni odobrena.",
        "authorize_session": "Če želite dostopati do te seje, morate avtorizirati svojo napravo.",
        "authorize_instructions": "V e-poštnem sporočilu poiščite povezavo za avtorizacijo ali odprite aktivno sejo in izberite tri pike v zgornjem desnem kotu, nato pojdite na \"Naprave\" in izberite \"Avtoriziraj\".",
        "refresh_page_after_authorize": "Ko zaključite postopek avtorizacije, osvežite stran in se prijavite.",
        "check_your_inbox": "Preverite svojo e-pošto",
        "send_authorization_email": "Pošljite e-poštno sporočilo o pooblastilu",
        "call_finished": "Klic končan",
        "close": "Zapri",
        "waiting_stream": "Čakajoči tok",
        "request_camera_and_mic": "Potrebujemo dostop do kamere in mikrofona, da lahko opravljate klice.",
        "call_in_progress": "Klic v teku",
        "group": "Skupina",
        "videocall": "Video klic",
        "call": "Klic",
        "connecting": "Povezovanje",
        "call_cancelled": "Klic preklican",
        "calling": "Klicanje",
        "hangup": "Obesite",
        "reject": "Zavrnite",
        "has_sent_a_file": "je poslal datoteko.",
        "keep": "Ohranite",
        "keep_file": "Če aktivirate shranjevanje datotek, bodo datoteke shranjene v aplikaciji. To bo omogočilo dostopnost brez potrebe po internetni povezavi.",
        "not_keep_file": "Če onemogočite shranjevanje datotek, bodo vaše datoteke izbrisane in do njih ne boste mogli dostopati brez internetne povezave.",
        "file_stored_instruction": "Vse shranjene datoteke bodo na seznamu skupnih datotek prikazane z zelenim robom. Ogledate si jih lahko tudi v",
        "stored": "Hranjeno",
        "not_stored": "Ni shranjeno",
        "file_not_stored_instruction": "Brez internetne povezave do te datoteke ne boste mogli dostopati.",
        "device": "Naprava",
        "file_visibility": "Vidnost arhiva",
        "visible_for_everyone": "Vidno vsem",
        "shared_link": "Skupna povezava",
        "hashtags": "Hashtagi",
        "hashtags_helps_to_search_your_file": "Hashtagi pomagajo pri iskanju vaših datotek.",
        "edit": "Uredi",
        "move": "Premik",
        "rename": "Preimenovanje",
        "visibility": "Vidljivost",
        "delete": "Izbriši",
        "file_warn_delete": "Ali ste prepričani, da želite izbrisati datoteko?",
        "no_subtags_availables": "Podoznačk ni na voljo",
        "owner": "Ustvarjalec",
        "expulsing": "Izgon",
        "expulse": "Expulse",
        "you_have_left_the_group": "Zapustili ste skupino",
        "coming_out": "Zapuščanje",
        "leave_group": "Zapustite skupino",
        "you_have_left_the_room": "Zapustili ste sobo.",
        "leave_room": "Odhod iz sobe",
        "message_deleted": "Sporočilo izbrisano",
        "has_been_error_deleting_message": "Pri brisanju sporočila je prišlo do napake",
        "deleting": "Brisanje",
        "new_tag": "Nova etiketa",
        "photoEditor": {
            "name": "Ime",
            "save": "Shrani",
            "saveAs": "Shrani kot",
            "back": "Nazaj",
            "loading": "Nalaganje…",
            "resetOperations": "Ponastavitev/izbris vseh operacij.",
            "changesLoseConfirmation": "Vse spremembe bodo izbrisane.",
            "changesLoseConfirmationHint": "Ste prepričani, da želite nadaljevati?",
            "cancel": "Prekliči",
            "continue": "Nadaljujte",
            "undoTitle": "Preklic zadnje operacije",
            "redoTitle": "Ponovi zadnjo operacijo",
            "showImageTitle": "Prikaži izvirno sliko",
            "zoomInTitle": "Približanje",
            "zoomOutTitle": "Pomanjšanje",
            "toggleZoomMenuTitle": "Preklapljanje menija povečave",
            "adjustTab": "Prilagodite",
            "finetuneTab": "Barve",
            "filtersTab": "Filtri",
            "watermarkTab": "Slike",
            "annotateTab": "Risanje",
            "resize": "Spremeni velikost",
            "resizeTab": "Velikost",
            "invalidImageError": "Neveljavna slika",
            "uploadImageError": "Pri nalaganju slike je prišlo do napake.",
            "areNotImages": "niso slike",
            "isNotImage": "ni slika",
            "toBeUploaded": "ki jih je treba naložiti",
            "cropTool": "Izreži",
            "original": "Izvirnik",
            "custom": "Personalizirano",
            "square": "Kvadratni",
            "landscape": "Vodoravno",
            "portrait": "Navpično",
            "ellipse": "Krog",
            "classicTv": "Klasična televizija",
            "cinemascope": "Cinemascope",
            "arrowTool": "Puščica",
            "blurTool": "Blur",
            "brightnessTool": "Svetlost",
            "contrastTool": "Kontrast",
            "ellipseTool": "Krog",
            "unFlipX": "Odvijanje X",
            "flipX": "Flip X",
            "unFlipY": "Odvijanje Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "Hue",
            "saturation": "Nasičenost",
            "value": "Vrednost",
            "imageTool": "Slika",
            "importing": "Uvažanje…",
            "addImage": "+ Dodajanje slike",
            "uploadImage": "Naloži sliko",
            "fromGallery": "Samolepke",
            "lineTool": "Linija",
            "penTool": "Pero",
            "polygonTool": "Poligon",
            "sides": "Stranice",
            "rectangleTool": "Pravokotnik",
            "cornerRadius": "Polmer roba",
            "resizeWidthTitle": "Širina v pikslih",
            "resizeHeightTitle": "Višina v pikslih",
            "toggleRatioLockTitle": "Preklapljanje zaklepa dimenzije",
            "reset": "Ponovni zagon",
            "resetSize": "Ponastavitev na prvotne dimenzije slike",
            "rotateTool": "Vrtenje",
            "textTool": "Besedilo",
            "textSpacings": "Razmik med besedilom",
            "textAlignment": "Poravnava besedila",
            "fontFamily": "Družina pisav",
            "size": "Velikost",
            "letterSpacing": "Razmik med črkami",
            "lineHeight": "Višina črte",
            "warmthTool": "Toplota",
            "addWatermark": "+ Dodajanje slike",
            "addWatermarkTitle": "Izberite vrsto vodnega žiga",
            "uploadWatermark": "Izberite sliko",
            "addWatermarkAsText": "Dodajanje besedila",
            "padding": "Napolnitev",
            "shadow": "Shadow",
            "horizontal": "Vodoravno",
            "vertical": "Navpično",
            "blur": "Blur",
            "opacity": "Motnost",
            "position": "Stališče",
            "stroke": "Debelina",
            "saveAsModalLabel": "Podrobnosti",
            "extension": "Podaljšek",
            "nameIsRequired": "Ime je obvezno.",
            "quality": "Kakovost",
            "imageDimensionsHoverTitle": "Shranjene dimenzije slike (širina x višina)",
            "cropSizeLowerThanResizedWarning": "Upoštevajte, da je izbrano območje obrezovanja manjše od uporabljene velikosti, zaradi česar se lahko zmanjša kakovost.",
            "actualSize": "Trenutna velikost (100 %)",
            "fitSize": "Prilagodite dimenzijo",
            "addImageTitle": "Izberite sliko, ki jo želite dodati…"
        },
        "changing": "Spreminjanje",
        "profile_picture_changed": "Spremenjena profilna slika",
        "loading_profile_picture": "Nalaganje slike profila",
        "change": "Sprememba",
        "session": "Seja",
        "system_operative": "Operacijski sistem",
        "navigator": "Brskalnik",
        "authorize": "Dovoljenje",
        "deauthorize": "Deauthorise",
        "keep_tag_content": "Ali želite ohraniti vsebino oznake?",
        "move_content_to": "Premaknite vsebino v",
        "move_to": "Premaknite se v",
        "chat_request": "Prošnja za klepet",
        "accept_request": "Sprejeti vlogo",
        "go_to_chat": "Pojdi na klepet",
        "applying": "Uporaba",
        "time": "Čas",
        "adjust": "Prilagodite",
        "rotate": "Vrtenje",
        "start": "Začni",
        "end": "Konec",
        "lang_name": "Slovenian"
    },
    en: {
        // Formularios
        'name': 'Name',
        'name_example': 'Olivia',
        'last_name': 'Last name',
        'last_name_example': 'Smith',
        'username': 'User',
        'username_example': 'olivia_smith',
        'email': 'Email',
        'email_example': 'user@domain.com',
        'password': 'Password',
        'new_password': 'New password',
        'password_example': '*****************',
        'login': 'Log in',
        'register': 'Sign in',
        'verification_code': 'Verification code',
        'verification_code_example': 'xxx',
        'verify': 'Verify',
        'loading': 'Loading…',
        'please_wait': 'Please wait',
        'go_back': 'Go back',
        'recovery': 'Recovery',


        // [/login] page
        'login_subtitle': 'Enter your details and access your account.',
        'forgot_password_link': 'Did you forget your password? Get it back.',
        'register_account_link': 'You do not have an account? Sign up!',

        // [/register] page
        'register_subtitle': 'Enter your details and register your account.',
        'login_link': 'Do you already have an account? Log in!',

        // VerificationForm component
        'verification_code_instruction': 'Check your email and enter the code sent.',

        // [/forgot-password] page
        'forgot_password_subtitle': 'Enter your email to recover your password.',

        // [/change-password] page
        'change_password_subtitle': 'Enter a new password.',
        'change_password_code_used': 'The code to recover your password is invalid or has already been used.',


        'no_chats': "You don't have chats.",

        'chats': 'Chats',
        'groups': 'Groups',
        'rooms': 'Rooms',

        'save': 'Save',
        'download': 'Download',

        // EditImage component
        'downloading_image': 'Downloading image',
        'an_error_has_ocurred': 'An error has ocurred',
        'saving_changes': 'Saving changes',
        'go_back_to_edit': 'Go back to edit',
        'upload_warning_modal': 'Uploading the edit will replace the original image.',
        'upload_question_modal': 'Do you want to keep the original and create a copy?',
        'create_copy_and_keep': 'Create copy and keep',
        'upload': 'Upload',
        'upload_changes': 'Upload changes',

        // EditVideo
        'changes_saved': 'Changes saved',
        'downloading_video': 'Downloading video',
        'video_upload_warning_modal': 'Uploading the edit will replace the original video.',

        // AddMember
        'results': 'Results',

        // MemberList
        'members': 'Members',
        'cancel': 'Cancel',
        'add_user': 'Add user',

        // MessageList
        'no_messages': 'No messages',

        // SendMessageBox
        'message_placeholder': 'Message…',

        // GroupChat
        'group_doesnt_exists': "Group doesn't exist",
        'you_arent_group_member': "You aren't group member",
        'touch_to_see_more_info': 'Touch to see more info.',
        'start_videocall': 'Start videocall',
        'start_call': 'Start call',

        // GroupPage
        'create_group': 'Create group',
        'group_name': 'Group name',
        'add_members_to_create_group': 'Add members to create group',
        'search': 'Search',
        'creating': 'Creating…',
        'dont_belong_anyone_group': "Don't belong anyone group",

        // HashtagsPage
        'already_add_this_hashtag': 'Already add this hashtag',
        'search_files_by_hashtags': 'Search files by hashtags',
        'enter_to_add': 'Enter to add',
        'hashtags_most_used': 'Hashtags most used',
        'can_add_it_giving_click': 'Can add it giving click',
        'searching_files': 'Searching files',
        'no_files_found': 'No files found',
        'image': 'Image',
        'video': 'Video',
        'audio': 'Audio',
        'library': 'Library',

        // Home
        'home': 'Home',

        // PrivateChat
        'block': 'Block',
        'unblock': 'Unblock',
        'has_blocked_you': 'has blocked you',
        'accept': 'Accept',
        'decline': 'Decline',
        'cancel_request': 'Cancel request',

        // RoomChat
        'has_been_expulsed': 'has been expulsed.',
        'room_doesnt_exists': "Room doesn't exists",
        'you_arent_room_member': "you aren't room member",

        // RoomPage
        'create_room': 'Create room',
        'room_name': 'Room name',
        'no_rooms_created': 'No rooms created',
        'dont_belong_to_anyone_room': "Don't belong to anyone room",

        // SearchPage
        'enter_a_username': 'Enter a username',
        'no_results': 'No results',

        // TagPage
        'create': 'Create',
        'no_content_uploaded': 'No content uploaded',
        'new_subtag': 'New subtag',

        // UpdateProfilePage
        'update_info': 'Update info',
        'updating': 'Updating',
        'update': 'Update',
        'update_email': 'Update email',
        'change_password': 'Change password',
        'current_password': 'Current password',

        // UploadFile
        'select': 'Select',
        'tap_to_upload_a_file': 'Tap to upload a file',
        'edit_photo': 'Edit photo',
        'preview': 'Preview',
        'edit_video': 'Edit video',
        'delete_changes': 'Delete changes',
        'uploading': 'Uploading',

        // AuthTemplate
        'session_deleted_or_expired': "Session deleted or expired",
        'edit_profile': 'Edit profile',
        'devices': 'Devices',
        'downloads': 'Downloads',
        'logout': 'Log out',
        'offline': 'Offline',
        'images': 'Images',
        'videos': 'Videos',
        'audios': 'Audios',
        'library': 'Library',
        'chat': 'Chat',
        'session_unauthorized': 'Your session is not authorized.',
        'authorize_session': 'To access this session, you need to authorize your device.',

        'authorize_instructions': `Please check your email for the authorization link or go to an active session and select the three dots in the top right corner, then go to "Devices" and choose "Authorize."`,

        'refresh_page_after_authorize': 'Once you have completed the authorization process, please refresh the page to access the session.',
        'check_your_inbox': 'Check your inbox',
        'send_authorization_email': 'Send authorization email',

        // Call
        'call_finished': 'Call finished',
        'close': 'Close',
        'waiting_stream': 'Waiting stream',
        'request_camera_and_mic': 'We need access to your camera and microphone so you can make calls.',
        'call_in_progress': 'Call in progress',
        'group': 'Group',
        'videocall': 'Videocall',
        'call': 'Call',
        'connecting': 'Connecting',
        'call_cancelled': 'Call canceled',
        'calling': 'Calling',
        'hangup': 'Hang up',
        'reject': 'Reject',
        'has_sent_a_file': 'has sent a file.',
        'keep': 'Keep',

        // File
        'keep_file': `If you enable keep the file, it will be saved in the application. This will allow its accessibility without requiring an internet connection.`,

        'not_keep_file': `If you disable keeping the file, it will be deleted and you will not be able to access it without an internet connection.`,
        'file_stored_instruction': `All stored files will appear with a green border in the common file list. You can also see them in the section`,
        'stored': 'Stored',
        'not_stored': 'Not stored',
        'file_not_stored_instruction': 'You will not be able to access this file without an Internet connection',
        'device': 'Device',
        'file_visibility': 'File visibility',
        'visible_for_everyone': 'Visible for everyone',
        'shared_link': 'Shared link',
        'hashtags': 'Hashtags',
        'hashtags_helps_to_search_your_file': 'Hashtags helps to search your file',
        'edit': 'Edit',
        'move': 'Move',
        'rename': 'Rename',
        'visibility': 'Visibility',
        'delete': 'Delete',
        'file_warn_delete': 'Are you sure you want to delete the file?',
        'no_subtags_availables': 'No subtags availables',

        // GroupMember
        'owner': 'Owner',
        'expulsing': 'Expulsing',
        'expulse': 'Expulse',

        // LeaveGroupButton
        'you_have_left_the_group': 'You have left the group',
        'coming_out': 'Coming out',
        'leave_group': 'Leave group',

        // LeaveRoomButton
        'you_have_left_the_room': 'You have left the room',
        'leave_room': 'Leave room',

        // Message
        'message_deleted': 'Message deleted',
        'has_been_error_deleting_message': 'An error occurred deleting the message',
        'deleting': 'Deleting',

        // MultimediaManager
        'new_tag': 'New tag',


        // PhotoEditorPro
        photoEditor: {
            name: 'Name',
            save: 'Save',
            saveAs: 'Save as',
            back: 'Back',
            loading: 'Loading…',
            resetOperations: 'Restart/Delete all operations.',
            changesLoseConfirmation: 'All changes will be removed.',
            changesLoseConfirmationHint: 'Are you sure you want to continue?',
            cancel: 'Cancel',
            continue: 'Continue',
            undoTitle: 'Undo last operation',
            redoTitle: 'Redo last operation',
            showImageTitle: 'Show original image',
            zoomInTitle: 'Zoom in',
            zoomOutTitle: 'Zoom out',
            toggleZoomMenuTitle: 'Toggle zoom menu',
            adjustTab: 'Adjust',
            finetuneTab: 'Finetune',
            filtersTab: 'Filters',
            watermarkTab: 'Watermark',
            annotateTab: 'Annotate',
            resize: 'Resize',
            resizeTab: 'Size',
            invalidImageError: 'Invalid image',
            uploadImageError: 'An error occurred while loading the image.',
            areNotImages: 'are not images',
            isNotImage: 'is not image',
            toBeUploaded: 'to be uploaded',
            cropTool: 'Crop',
            original: 'Original',
            custom: 'Custom',
            square: 'Square',
            landscape: 'Landscape',
            portrait: 'Portrait',
            ellipse: 'Ellipse',
            classicTv: 'Classic TV',
            cinemascope: 'Cinemascope',
            arrowTool: 'Arrow',
            blurTool: 'Blur',
            brightnessTool: 'Brightness',
            contrastTool: 'Contrast',
            ellipseTool: 'Ellipse',
            unFlipX: 'Unflip X',
            flipX: 'Flip X',
            unFlipY: 'Unflip Y',
            flipY: 'Flip Y',
            hsvTool: 'HSV',
            hue: 'Hue',
            saturation: 'Saturation',
            value: 'Value',
            imageTool: 'Image',
            importing: 'Importing…',
            addImage: '+ add imagen',
            uploadImage: 'Upload image',
            fromGallery: 'Stickers',
            lineTool: 'Line',
            penTool: 'Pen',
            polygonTool: 'Polygon',
            sides: 'Sides',
            rectangleTool: 'Rectangle',
            cornerRadius: 'Corner radius',
            resizeWidthTitle: 'Pixel width',
            resizeHeightTitle: 'Pixel height',
            toggleRatioLockTitle: 'Toggle ratio lock',
            reset: 'Reset',
            resetSize: 'Reset to original image dimensions',
            rotateTool: 'Rotate',
            textTool: 'Text',
            textSpacings: 'Text spacings',
            textAlignment: 'Text aligment',
            fontFamily: 'Font family',
            size: 'Size',
            letterSpacing: 'Letter spacing',
            lineHeight: 'Line height',
            warmthTool: 'Warmth',
            addWatermark: '+ add watermark',
            addWatermarkTitle: 'Choose the type of watermark',
            uploadWatermark: 'Upload watermark',
            addWatermarkAsText: 'Add watermark as text',
            padding: 'Padding',
            shadow: 'Shadow',
            horizontal: 'Horizontal',
            vertical: 'Vertical',
            blur: 'Blur',
            opacity: 'Opacity',
            position: 'Position',
            stroke: 'Stroke',
            saveAsModalLabel: 'Details',
            extension: 'Extension',
            nameIsRequired: 'Name is required.',
            quality: 'Quality',
            imageDimensionsHoverTitle: 'Saved image dimensions (width x height)',
            cropSizeLowerThanResizedWarning:
                'Please note that the selected crop area is smaller than the resize applied, which could cause a decrease in quality.',
            actualSize: 'Current size (100%)',
            fitSize: 'Fit size',
            addImageTitle: 'Select an image to add…',
        },

        // ProfilePicture
        'changing': 'Changing',
        'profile_picture_changed': 'Profile picture changed',
        'loading_profile_picture': 'Loading profile picture',
        'change': 'Change',

        // Session
        'session': 'Session',
        'system_operative': 'Operative System',
        'navigator': 'Navigator',
        'authorize': 'Authorize',
        'deauthorize': 'Deauthorize',

        // Tag
        'keep_tag_content': 'Do you want to preserve the content of the tag?',
        'move_content_to': 'Move content to',
        'move_to': 'Move to',

        // UserResult
        'chat_request': 'Chat request',
        'cancel_request': 'Cancel request',
        'accept_request': 'Accept request',
        'go_to_chat': 'Go to chat',

        // Video Editor
        'applying': 'Applying',
        'time': 'Time',
        'adjust': 'Adjust',
        'rotate': 'Rotate',
        'start': 'Start',
        'end': 'End',
        "lang_name": "English"
    },
    "es": {
        "name": "Nombre",
        "name_example": "Pedro",
        "last_name": "Apellido",
        "last_name_example": "Salinas",
        "username": "Usuario",
        "username_example": "pedro_salinas",
        "email": "Correo electrónico",
        "email_example": "usuario@dominio.com",
        "password": "Contraseña",
        "new_password": "Nueva contraseña",
        "password_example": "*****************",
        "login": "Iniciar Sesión",
        "register": "Registrarte",
        "verification_code": "Código de verificación",
        "verification_code_example": "xxx",
        "verify": "Verificar",
        "loading": "Cargando…",
        "please_wait": "Espere por favor",
        "go_back": "Volver",
        "recovery": "Recuperar",
        "login_subtitle": "Introduce tus datos y accede a tu cuenta.",
        "forgot_password_link": "¿Olvidaste tu contraseña? Recupérala.",
        "register_account_link": "¿No tienes una cuenta? ¡Regístrate!",
        "register_subtitle": "Introduce tus datos y registra tu cuenta.",
        "login_link": "¿Ya tienes una cuenta? ¡Inicia sesión!",
        "verification_code_instruction": "Revisa tu correo electrónico e introduce el código enviado.",
        "forgot_password_subtitle": "Introduce tu correo electrónico para recuperar tu contraseña.",
        "change_password_subtitle": "Introduce una contraseña nueva.",
        "change_password_code_used": "El código para recuperar tu contraseña es inválido o ya fue usado.",
        "no_chats": "No tienes conversaciones.",
        "chats": "Conversaciones",
        "groups": "Grupos",
        "rooms": "Salas",
        "save": "Guardar",
        "download": "Descargar",
        "downloading_image": "Descargando imagen",
        "an_error_has_ocurred": "Ha ocurrido un error",
        "saving_changes": "Guardando cambios",
        "go_back_to_edit": "Volver a editar",
        "upload_warning_modal": "Al subir la edición se reemplazará la imagen original.",
        "upload_question_modal": "¿Quieres conservar la original y crear una copia?",
        "create_copy_and_keep": "Crear copia y conservar",
        "upload": "Subir",
        "upload_changes": "Subir cambios",
        "changes_saved": "Cambios guardados",
        "downloading_video": "Descargando vídeo",
        "video_upload_warning_modal": "Al subir la edición se reemplazará el vídeo original.",
        "results": "Resultados",
        "members": "Miembros",
        "cancel": "Cancelar",
        "add_user": "Agregar usuario",
        "no_messages": "No hay mensajes",
        "message_placeholder": "Mensaje…",
        "group_doesnt_exists": "El grupo no existe",
        "you_arent_group_member": "No eres miembro del grupo",
        "touch_to_see_more_info": "Toca para ver más info.",
        "start_videocall": "Iniciar videollamada",
        "start_call": "Iniciar llamada",
        "create_group": "Crear grupo",
        "group_name": "Nombre del grupo",
        "add_members_to_create_group": "Agrega miembros para crear el grupo",
        "search": "Buscar",
        "creating": "Creando…",
        "dont_belong_anyone_group": "No perteneces a ningún grupo.",
        "already_add_this_hashtag": "Ya has agregaste ese hashtag",
        "search_files_by_hashtags": "Buscar archivos por hashtags",
        "enter_to_add": "Enter para agregar",
        "hashtags_most_used": "Hashtags más usados",
        "can_add_it_giving_click": "Puedes agregar estos a tu búsqueda dándoles click",
        "searching_files": "Buscando archivos",
        "no_files_found": "No se encontraron archivos",
        "image": "Imagen",
        "video": "Vídeo",
        "audio": "Audio",
        "library": "Librería",
        "home": "Página principal",
        "block": "Bloquear",
        "unblock": "Desbloquear",
        "has_blocked_you": "Te ha bloqueado",
        "accept": "Aceptar",
        "decline": "Rechazar",
        "cancel_request": "Cancelar solicitud",
        "has_been_expulsed": "ha sido expulsado",
        "room_doesnt_exists": "La sala no existe",
        "you_arent_room_member": "No eres miembro de la sala",
        "create_room": "Crear sala",
        "room_name": "Nombre de la sala",
        "no_rooms_created": "No hay salas creadas.",
        "dont_belong_to_anyone_room": "No perteneces a ninguna sala.",
        "enter_a_username": "Escribe un usuario",
        "no_results": "No hay resultados",
        "create": "Crear",
        "no_content_uploaded": "No hay contenido subido",
        "new_subtag": "Nuevo subtag",
        "update_info": "Actualizar información",
        "updating": "Actualizando",
        "update": "Actualizar",
        "update_email": "Actualizar email",
        "change_password": "Cambiar contraseña",
        "current_password": "Contraseña actual",
        "select": "Seleccionar",
        "tap_to_upload_a_file": "Toca para cargar un archivo",
        "edit_photo": "Editar foto",
        "preview": "Vista previa",
        "edit_video": "Editar vídeo",
        "delete_changes": "Eliminar cambios",
        "uploading": "Subiendo",
        "session_deleted_or_expired": "Sesión eliminada o expirada",
        "edit_profile": "Editar perfil",
        "devices": "Dispositivos",
        "downloads": "Descargas",
        "logout": "Cerrar sesión",
        "offline": "Sin conexión",
        "images": "Imágenes",
        "videos": "Vídeos",
        "audios": "Audios",
        "chat": "Chat",
        "session_unauthorized": "Tu sesión no está autorizada.",
        "authorize_session": "Para acceder a esta sesión, necesitas autorizar tu dispositivo.",
        "authorize_instructions": "Por favor, revisa tu correo electrónico para encontrar el enlace de autorización o dirígete a una sesión activa\n        y selecciona los tres puntos en la esquina superior derecha, luego ve a \"Dispositivos\" y elige \"Autorizar\".",
        "refresh_page_after_authorize": "Una vez que hayas completado el proceso de autorización, por favor refresca la página para acceder a la sesión.",
        "check_your_inbox": "Revisa tu correo electrónico",
        "send_authorization_email": "Enviar correo de autorización",
        "call_finished": "Llamada finalizada",
        "close": "Cerrar",
        "waiting_stream": "Esperando transmisión",
        "request_camera_and_mic": "Necesitamos acceder a tu cámara y micrófono para que pueda realizar llamadas.",
        "call_in_progress": "Llamada en curso",
        "group": "Grupo",
        "videocall": "Videollamada",
        "call": "Llamada",
        "connecting": "Conectando",
        "call_cancelled": "Llamada cancelada",
        "calling": "Llamando",
        "hangup": "Colgar",
        "reject": "Rechazar",
        "has_sent_a_file": "ha enviado un archivo.",
        "keep": "Conservar",
        "keep_file": "Si habilitas conservar el archivo, este se guardará en la aplicación.\n        Esto permitirá su accesibilidad sin que requieras conexión a internet.",
        "not_keep_file": "Si deshabilitas conservar el archivo, este se eliminará y no podrás acceder a él sin conexión a internet.",
        "file_stored_instruction": "Todos los archivos almacenados aparecerán con un borde verde en la lista común de archivos.\n        También puedes verlos en la sección de",
        "stored": "Almacenado",
        "not_stored": "No está almacenado",
        "file_not_stored_instruction": "No podrás acceder a este archivo sin conexión a Internet.",
        "device": "Dispositivo",
        "file_visibility": "Visibilidad del archivo",
        "visible_for_everyone": "Visible para todos",
        "shared_link": "Enlace compartido",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Los hashtags ayudan a la búsqueda de tus archivos.",
        "edit": "Editar",
        "move": "Mover",
        "rename": "Renombrar",
        "visibility": "Visibilidad",
        "delete": "Eliminar",
        "file_warn_delete": "¿Seguro que deseas eliminar el archivo?",
        "no_subtags_availables": "No hay subtags disponibles",
        "owner": "Creador",
        "expulsing": "Expulsando",
        "expulse": "Expulsar",
        "you_have_left_the_group": "Has salido del grupo",
        "coming_out": "Saliendo",
        "leave_group": "Salir del grupo",
        "you_have_left_the_room": "Has salido de la sala.",
        "leave_room": "Salir de la sala",
        "message_deleted": "Mensaje eliminado",
        "has_been_error_deleting_message": "Ha ocurrido un error eliminando el mensaje",
        "deleting": "Eliminando",
        "new_tag": "Nuevo tag",
        "photoEditor": {
            "name": "Nombre",
            "save": "Finalizar",
            "saveAs": "Guardar como",
            "back": "Atrás",
            "loading": "Cargando…",
            "resetOperations": "Reiniciar/Eliminar todas las operaciones.",
            "changesLoseConfirmation": "Todos los cambios serán eliminados.",
            "changesLoseConfirmationHint": "¿Estás seguro que quieres continuar?",
            "cancel": "Cancelar",
            "continue": "Continuar",
            "undoTitle": "Deshacer última operación",
            "redoTitle": "Rehacer última operación",
            "showImageTitle": "Mostrar imagen original",
            "zoomInTitle": "Acercar",
            "zoomOutTitle": "Alejar",
            "toggleZoomMenuTitle": "Alternar menú de zoom",
            "adjustTab": "Ajustar",
            "finetuneTab": "Colores",
            "filtersTab": "Filtros",
            "watermarkTab": "Imágenes",
            "annotateTab": "Dibujar",
            "resize": "Redimensionar",
            "resizeTab": "Tamaño",
            "invalidImageError": "Imagen inválida",
            "uploadImageError": "Ha ocurrido un error mientras se cargaba la imagen.",
            "areNotImages": "no son imágenes",
            "isNotImage": "no es una imagen",
            "toBeUploaded": "para ser subido",
            "cropTool": "Cortar",
            "original": "Original",
            "custom": "Personalizado",
            "square": "Cuadrado",
            "landscape": "Horizontal",
            "portrait": "Vertical",
            "ellipse": "Círculo",
            "classicTv": "TV clásica",
            "cinemascope": "Cinemascopio",
            "arrowTool": "Flecha",
            "blurTool": "Desenfoque",
            "brightnessTool": "Brillo",
            "contrastTool": "Contraste",
            "ellipseTool": "Círculo",
            "unFlipX": "Desvoltear X",
            "flipX": "Voltear X",
            "unFlipY": "Desvoltear Y",
            "flipY": "Voltear Y",
            "hsvTool": "MSV",
            "hue": "Matiz",
            "saturation": "Saturación",
            "value": "Valor",
            "imageTool": "Imagen",
            "importing": "Importando…",
            "addImage": "+ Agregar imagen",
            "uploadImage": "Subir imagen",
            "fromGallery": "Stickers",
            "lineTool": "Línea",
            "penTool": "Lápiz",
            "polygonTool": "Polígono",
            "sides": "Lados",
            "rectangleTool": "Rectángulo",
            "cornerRadius": "Radio de los bordes",
            "resizeWidthTitle": "Ancho en píxeles",
            "resizeHeightTitle": "Alto en píxeles",
            "toggleRatioLockTitle": "Alternar bloqueo de dimensión",
            "reset": "Reiniciar",
            "resetSize": "Reiniciar a las dimensiones originales de la imagen",
            "rotateTool": "Rotar",
            "textTool": "Texto",
            "textSpacings": "Espaciado de texto",
            "textAlignment": "Alineación del texto",
            "fontFamily": "Fuente",
            "size": "Tamaño",
            "letterSpacing": "Espaciado de letras",
            "lineHeight": "Altura de la línea",
            "warmthTool": "Calor",
            "addWatermark": "+ Añadir imagen",
            "addWatermarkTitle": "Escoge el tipo de imagen",
            "uploadWatermark": "Seleccionar imagen",
            "addWatermarkAsText": "Agregar texto como imagen",
            "padding": "Relleno",
            "shadow": "Sombra",
            "horizontal": "Horizontal",
            "vertical": "Vertical",
            "blur": "Desenfoque",
            "opacity": "Opacidad",
            "position": "Posición",
            "stroke": "Grosor",
            "saveAsModalLabel": "Detalles",
            "extension": "Extensión",
            "nameIsRequired": "El nombre es requerido.",
            "quality": "Calidad",
            "imageDimensionsHoverTitle": "Dimensiones de la imagen guardadas (ancho x alto)",
            "cropSizeLowerThanResizedWarning": "Tenga en cuenta que el área de recorte seleccionada es menor que el cambio de tamaño aplicado, lo que podría causar una disminución de la calidad.",
            "actualSize": "Tamaño actual (100%)",
            "fitSize": "Adaptar dimensión",
            "addImageTitle": "Selecciona una imagen para agregar…"
        },
        "changing": "Cambiando",
        "profile_picture_changed": "Foto de perfil cambiada",
        "loading_profile_picture": "Cargando foto de perfil",
        "change": "Cambiar",
        "session": "Sesión",
        "system_operative": "Sistema operativo",
        "navigator": "Navegador",
        "authorize": "Autorizar",
        "deauthorize": "Desautorizar",
        "keep_tag_content": "¿Quieres conservar el contenido del tag?",
        "move_content_to": "Mover contenido a",
        "move_to": "Mover a",
        "chat_request": "Solicitud de chat",
        "accept_request": "Aceptar solicitud",
        "go_to_chat": "Ir al chat",
        "applying": "Aplicando",
        "time": "Tiempo",
        "adjust": "Ajustar",
        "rotate": "Rotar",
        "start": "Inicio",
        "end": "Final",
        "lang_name": "Español"
    },
    "sv": {
        "name": "Namn",
        "name_example": "Pedro",
        "last_name": "Efternamn",
        "last_name_example": "Salinas",
        "username": "Anv�ndare",
        "username_example": "pedro_salinas",
        "email": "E-post",
        "email_example": "usuario@dominio.com",
        "password": "L�senord",
        "new_password": "Nytt l�senord",
        "password_example": "*****************",
        "login": "Inloggning",
        "register": "Registrera",
        "verification_code": "Verifieringskod",
        "verification_code_example": "xxx",
        "verify": "Verifiera",
        "loading": "Laddar...",
        "please_wait": "V�nligen v�nta",
        "go_back": "�terv�nd",
        "recovery": "�terst�ll",
        "login_subtitle": "Ange dina uppgifter och logga in p� ditt konto",
        "forgot_password_link": "Har du gl�mt ditt l�senord?",
        "register_account_link": "Har du inget konto? Registrera dig!",
        "register_subtitle": "Ange dina uppgifter och registrera ditt konto",
        "login_link": "Har du redan ett konto? Logga in!",
        "verification_code_instruction": "Kontrollera din e-post och ange den skickade koden.",
        "forgot_password_subtitle": "Ange din e-postadress f�r att h�mta ditt l�senord.",
        "change_password_subtitle": "Ange ett nytt l�senord.",
        "change_password_code_used": "Koden f�r att h�mta ditt l�senord �r ogiltig eller har redan anv�nts.",
        "no_chats": "Du har inga chattar.",
        "chats": "Konversationer",
        "groups": "Grupper",
        "rooms": "Rum",
        "save": "Spara",
        "download": "Ladda ner",
        "downloading_image": "Laddar ner bild",
        "an_error_has_occurred": "Ett fel har intr�ffat",
        "saving_changes": "Spara �ndringar",
        "go_back_to_edit": "Tillbaka till redigering",
        "upload_warning_modal": "Uppladdning av redigeringen kommer att ers�tta originalbilden",
        "upload_question_modal": "Vill du beh�lla originalet och skapa en kopia?",
        "create_copy_and_keep": "Skapa kopia och beh�ll",
        "upload": "Ladda upp",
        "upload_changes": "Ladda upp �ndringar",
        "changes_saved": "�ndringar sparade",
        "downloading_video": "Laddar ner video",
        "video_upload_warning_modal": "Uppladdning av redigeringen kommer att ers�tta den ursprungliga videon.",
        "results": "Resultat",
        "members": "Medlemmar",
        "cancel": "Avbryt",
        "add_user": "L�gg till anv�ndare",
        "no_messages": "Inga meddelanden",
        "message_placeholder": "Meddelande...",
        "group_doesnt_exists": "Gruppen existerar inte",
        "you_arent_group_member": "Du �r inte medlem i gruppen",
        "touch_to_see_more_info": "Tryck f�r att se mer info",
        "start_videocall": "Starta videosamtal",
        "start_call": "Starta samtal",
        "create_group": "Skapa grupp",
        "group_name": "Namn p� gruppen",
        "add_members_to_create_group": "L�gg till medlemmar f�r att skapa gruppen",
        "search": "S�k",
        "creating": "Skapa...",
        "dont_belong_anyone_group": "Du tillh�r inte n�gon grupp.",
        "already_add_this_hashtag": "Du har redan lagt till den hashtaggen",
        "search_files_by_hashtags": "S�k efter filer med hashtags",
        "enter_to_add": "Ange f�r att l�gga till",
        "hashtags_most_used": "Mest anv�nda hashtags",
        "can_add_it_giving_click": "Du kan l�gga till dessa i din s�kning genom att klicka p� dem",
        "searching_files": "S�ker efter filer",
        "no_files_found": "Inga filer hittades",
        "image": "Bild",
        "video": "Video",
        "audio": "Ljud",
        "library": "Bibliotek",
        "home": "Hem",
        "block": "Blockera",
        "unblock": "Avblockera",
        "has_blocked_you": "Har blockerat dig",
        "accept": "Acceptera",
        "decline": "Avvisa",
        "cancel_request": "Avbryt f�rfr�gan",
        "has_been_expelled": "har blivit utvisad",
        "room_does_been_expelled": "has_been_expelled",
        "room_doesnt_exists": "Rummet existerar inte",
        "you_arent_room_member": "Du �r inte medlem i rummet",
        "create_room": "Skapa rum",
        "room_name": "Namn p� rummet",
        "no_rooms_created": "Inga rum skapade",
        "dont_belong_to_anyone_room": "Du tillh�r inte n�got rum",
        "enter_a_username": "Ange ett anv�ndarnamn",
        "no_results": "Inga resultat",
        "create": "Skapa",
        "no_content_uploaded": "Inget inneh�ll har laddats upp",
        "new_subtag": "Ny undertagg",
        "update_info": "Uppdatera information",
        "updating": "Uppdatering",
        "update": "Uppdatera",
        "update_email": "Uppdatera e-post",
        "change_password": "�ndra l�senord",
        "current_password": "Aktuellt l�senord",
        "select": "V�lj",
        "tap_to_upload_a_file": "Tryck f�r att ladda upp en fil",
        "edit_photo": "Redigera foto",
        "preview": "F�rhandsgranska",
        "edit_video": "Redigera video",
        "delete_changes": "Ta bort �ndringar",
        "uploading": "Uppladdning",
        "session_deleted_or_expired": "Sessionen har tagits bort eller l�pt ut",
        "edit_profile": "Redigera profil",
        "enheter": "Enheter",
        "logout": "Logga ut",
        "offline": "Ingen f�rbindelse",
        "images": "bilder",
        "videos": "Videor",
        "audios": "Audios",
        "chat": "Chatt",
        "session_unauthorized": "Din session �r obeh�rig",
        "authorize_session": "F�r att komma �t den h�r sessionen m�ste du auktorisera din enhet.",
        "authorize_instructions": "Kontrollera din e-post f�r auktoriseringsl�nken eller g� till en aktiv session och v�lj de tre punkterna i det �vre h�gra h�rnet, g� sedan till \"Enheter\" och v�lj \"Auktorisera\".\n        och v�lj de tre punkterna i det �vre h�gra h�rnet, g� sedan till \"Enheter\" och v�lj \"Auktorisera",
        "refresh_page_after_authorize": "N�r du har slutf�rt auktoriseringsprocessen, v�nligen uppdatera sidan f�r att komma �t sessionen",
        "check_your_inbox": "Kontrollera din e-post",
        "send_authorization_email": "Skicka e-post om auktorisering",
        "call_finished": "Samtal avslutat",
        "close": "Close",
        "waiting_stream": "V�ntar p� �verf�ring",
        "request_camera_and_mic": "Vi beh�ver tillg�ng till din kamera och mikrofon s� att du kan ringa samtal",
        "call_in_progress": "Samtal p�g�r",
        "grupp": "Grupp",
        "videocall": "Videosamtal",
        "samtal": "Samtal",
        "anslutande": "Anslutande",
        "call_cancelled": "Samtal avbrutet",
        "calling": "Kallelse",
        "hangup": "H�nga",
        "reject": "Nedg�ng",
        "has_sent_a_file": "har skickat en fil",
        "keep": "Beh�ll",
        "keep_file": "Om du aktiverar beh�ll filen kommer den att sparas i applikationen. Detta g�r det m�jligt att komma �t den utan att beh�va en internetanslutning",
        "not_keep_file": "Om du inaktiverar keep_file kommer filen att raderas och du kommer inte att kunna komma �t den utan internetanslutning",
        "file_stored_instruction": "Alla lagrade filer kommer att visas med en gr�n kant i den gemensamma fillistan.Du kan ocks� se dem i",
        "stored": "Lagrad",
        "not_stored": "Inte lagrad",
        "file_not_stored_instruction": "Du kommer inte att kunna komma �t den h�r filen utan en internetanslutning",
        "device": "Enhet",
        "file_visibility": "Filens synlighet",
        "visible_for_everyone": "Synlig f�r alla",
        "shared_link": "Delad l�nk",
        "hashtags": "Hashtags",
        "hashtags_helps_to_search_your_file": "Hashtags_helps_to_search_your_file",
        "edit": "Redigera",
        "move": "Flytta",
        "rename": "Byta namn",
        "visibility": "Synlighet",
        "delete": "Radera",
        "file_warn_delete": "�r du s�ker p� att du vill ta bort den h�r filen?",
        "no_subtags_availables": "Inga undertaggar tillg�ngliga",
        "owner": "Skapare",
        "expulsing": "utvisa",
        "expulse": "Utvisa",
        "you_have_left_the_group": "Du har l�mnat gruppen",
        "coming_out": "Kommer ut",
        "leave_group": "L�mna gruppen",
        "you_have_left_the_room": "Du har l�mnat rummet.",
        "leave_room": "L�mna gruppen",
        "message_deleted": "Raderat meddelande",
        "has_been_error_deleting_message": "Ett fel uppstod n�r meddelandet skulle raderas",
        "deleting": "Ta bort",
        "new_tag": "Ny tag",
        "photoEditor": {
            "name": "Namn",
            "save": "Avsluta",
            "saveAs": "Spara som",
            "back": "Tillbaka",
            "loading": "Laddar...",
            "resetOperations": "Starta om/ta bort alla operationer.",
            "changesLoseConfirmation": "Alla �ndringar kommer att tas bort.",
            "changesLoseConfirmationHint": "�r du s�ker p� att du vill forts�tta?",
            "cancel": "Annullera",
            "continue": "Forts�tta",
            "undoTitle": "�ngra den senaste �tg�rden",
            "redoTitle": "G�r om senaste operationen",
            "showImageTitle": "Visa originalbilden",
            "zoomInTitle": "Zooma in",
            "zoomOutTitle": "Avv�rja",
            "toggleZoomMenuTitle": "V�xla zoommeny",
            "adjustTab": "Justera",
            "finetuneTab": "F�rger",
            "filtersTab": "Filter",
            "watermarkTab": "Bilder",
            "annotateTab": "Dra",
            "resize": "�ndra storlek",
            "resizeTab": "Storlek",
            "invalidImageError": "Ogiltig bild",
            "uploadImageError": "Ett fel uppstod n�r bilden laddades.",
            "areNotImages": "De �r inte bilder",
            "isNotImage": "det �r inte en bild",
            "toBeUploaded": "ska laddas upp",
            "cropTool": "Sk�ra",
            "original": "Original",
            "custom": "Personlig",
            "square": "Fyrkant",
            "landscape": "Horisontell",
            "portrait": "Vertikal",
            "ellipse": "Cirkel",
            "classicTv": "klassisk tv",
            "cinemascope": "Cinemascope",
            "arrowTool": "Pil",
            "blurTool": "Fl�ck",
            "brightnessTool": "Gl�d",
            "contrastTool": "Kontrast",
            "ellipseTool": "Cirkel",
            "unFlipX": "Flip X",
            "flipX": "Flip X",
            "unFlipY": "Flip Y",
            "flipY": "Flip Y",
            "hsvTool": "MSV",
            "hue": "Nyans",
            "saturation": "M�ttnad",
            "value": "V�rde",
            "imageTool": "Bild",
            "importing": "Importerar...",
            "addImage": "+ L�gg till bild",
            "uploadImage": "Ladda upp bild",
            "fromGallery": "Klisterm�rken",
            "lineTool": "Linje",
            "penTool": "Penna",
            "polygonTool": "Polygon",
            "sides": "Sidor",
            "rectangleTool": "Rektangel",
            "cornerRadius": "Kantradie",
            "resizeWidthTitle": "Bredd i pixlar",
            "resizeHeightTitle": "Pixelh�jd",
            "toggleRatioLockTitle": "V�xla Dimension Lock",
            "reset": "Omstart",
            "resetSize": "�terst�ll till originalbildens m�tt",
            "rotateTool": "Rotera",
            "textTool": "Text",
            "textSpacings": "Textavst�nd",
            "textAlignment": "Textjustering",
            "fontFamily": "Font�n",
            "size": "Storlek",
            "letterSpacing": "Teckenavst�nd",
            "lineHeight": "Radavst�nd",
            "warmthTool": "V�rme",
            "addWatermark": "+ L�gg till bild",
            "addWatermarkTitle": "Choose the watermark type",
            "uploadWatermark": "V�lj bild",
            "addWatermarkAsText": "L�gg till text",
            "padding": "Fylld",
            "shadow": "Skugga",
            "horizontal": "Horisontell",
            "vertical": "Vertikal",
            "blur": "Fl�ck",
            "opacity": "Opacitet",
            "position": "Placera",
            "stroke": "Tjocklek",
            "saveAsModalLabel": "Detaljer",
            "extension": "F�rl�ngning",
            "nameIsRequired": "Namnet �r obligatoriskt.",
            "quality": "Kvalitet",
            "imageDimensionsHoverTitle": "Sparade bildm�tt (width x height)",
            "cropSizeLowerThanResizedWarning": "Observera att den valda besk�rningsarean �r mindre �n den �ndrade storleken som till�mpats, vilket kan orsaka f�rs�mrad kvalitet.",
            "actualSize": "Aktuell storlek (100%)",
            "fitSize": "Anpassa dimension",
            "addImageTitle": "V�lj en bild att l�gga till..."
        },
        "changing": "Skiftande",
        "profile_picture_changed": "Profilbilden har �ndrats",
        "loading_profile_picture": "Laddar profilfoto",
        "change": "F�r�ndra",
        "session": "Session",
        "system_operative": "OS",
        "navigator": "Webbl�sare",
        "authorize": "Godk�nna",
        "deauthorize": "Desavouer",
        "keep_tag_content": "Vill du beh�lla inneh�llet i taggen?",
        "move_content_to": "Flytta inneh�ll till",
        "move_to": "Flytta till",
        "chat_request": "Chattf�rfr�gan",
        "accept_request": "Acceptera f�rfr�gan",
        "go_to_chat": "G� till chatten",
        "applying": "Ans�ker",
        "time": "Tid",
        "adjust": "Justera",
        "rotate": "Rotera",
        "start": "Start",
        "end": "Slutlig",
        "lang_name": "Sueco"
    },
    "tl": {
        "name": "Pangalan",
        "name_example": "Mary",
        "last_name": "Huling pangalan",
        "last_name_example": "Reyes",
        "username": "Gumagamit",
        "username_example": "mary_reyes",
        "email": "Email",
        "email_example": "gumagamit@domain.com",
        "password": "Password",
        "new_password": "Bagong Password",
        "password_example": "*****************",
        "login": "Mag log in",
        "register": "Itala",
        "verification_code": "Verification code",
        "verification_code_example": "xxx",
        "verify": "I-verify",
        "loading": "Nagcha-charge…",
        "please_wait": "Mangyaring maghintay",
        "go_back": "Bumalik ka",
        "recovery": "Gumaling",
        "login_subtitle": "Ilagay ang iyong mga detalye at i-access ang iyong account.",
        "forgot_password_link": "Nakalimutan mo ba ang iyong password? Mabawi.",
        "register_account_link": "Wala kang account? Mag-sign up!",
        "register_subtitle": "Ilagay ang iyong mga detalye at irehistro ang iyong account.",
        "login_link": "Mayroon ka na bang isang account? Mag log in!",
        "verification_code_instruction": "Suriin ang iyong email at ilagay ang code na ipinadala.",
        "forgot_password_subtitle": "Ipasok ang iyong email upang mabawi ang iyong password.",
        "change_password_subtitle": "Maglagay ng bagong password.",
        "change_password_code_used": "Ang iyong password recovery code ay hindi wasto o nagamit na.",
        "no_chats": "Wala kang mga pag-uusap.",
        "chats": "Mga pag-uusap",
        "groups": "Mga grupo",
        "rooms": "Mga silid",
        "save": "I-save",
        "download": "Mag-download",
        "downloading_image": "Nagda-download ng larawan",
        "an_error_has_ocurred": "May nangyaring mali",
        "saving_changes": "Nagse-save ng mga pagbabago",
        "go_back_to_edit": "Edit ulit",
        "upload_warning_modal": "Papalitan ng pag-upload ng pag-edit ang orihinal na larawan.",
        "upload_question_modal": "Gusto mo bang panatilihin ang orihinal at gumawa ng kopya?",
        "create_copy_and_keep": "Gumawa ng kopya at i-save ito.",
        "upload": "Mag-upload",
        "upload_changes": "Mag-upload ng mga pagbabago",
        "changes_saved": "Nai-save ang mga Pagbabago",
        "downloading_video": "Nagda-download ng video",
        "video_upload_warning_modal": "Papalitan ng pag-upload ng pag-edit ang orihinal na video.",
        "results": "Mga resulta",
        "members": "Mga miyembro",
        "cancel": "Kanselahin",
        "add_user": "Magdagdag ng user",
        "no_messages": "Walang mga mensahe",
        "message_placeholder": "Mensahe…",
        "group_doesnt_exists": "Wala ang grupo",
        "you_arent_group_member": "Hindi ka miyembro ng grupo",
        "touch_to_see_more_info": "I-tap para makakita ng higit pang impormasyon.",
        "start_videocall": "Simulan ang video call",
        "start_call": "Simulan ang tawag",
        "create_group": "Gumawa ng grupo",
        "group_name": "Pangalan ng grupo",
        "add_members_to_create_group": "Magdagdag ng mga miyembro para gawin ang grupo.",
        "search": "Maghanap",
        "creating": "Lumilikha…",
        "dont_belong_anyone_group": "Hindi ka kabilang sa anumang grupo.",
        "already_add_this_hashtag": "Naidagdag mo na ang hashtag na ito",
        "search_files_by_hashtags": "Maghanap ng mga file sa pamamagitan ng mga hashtag",
        "enter_to_add": "Ipasok upang idagdag",
        "hashtags_most_used": "Karamihan sa mga ginagamit na hashtag",
        "can_add_it_giving_click": "Maaari mong idagdag ang mga hashtag na ito sa iyong paghahanap sa pamamagitan ng pag-click sa mga ito",
        "searching_files": "Naghahanap ng mga file",
        "no_files_found": "Walang nakitang mga file",
        "image": "Imahe",
        "video": "Video",
        "audio": "Audio",
        "library": "Tindahan ng libro",
        "home": "Homepage",
        "block": "I-block",
        "unblock": "Upang i-unlock",
        "has_blocked_you": "Hinarangan ka niya",
        "accept": "Tanggapin",
        "decline": "Tanggihan",
        "cancel_request": "Kanselahin ang kahilingan",
        "has_been_expulsed": "pinatalsik ka",
        "room_doesnt_exists": "Wala ang kwarto",
        "you_arent_room_member": "Hindi ka miyembro ng silid",
        "create_room": "Lumikha ng silid",
        "room_name": "Pangalan ng kwarto",
        "no_rooms_created": "Walang mga silid na nilikha.",
        "dont_belong_to_anyone_room": "Wala ka sa kahit anong kwarto.",
        "enter_a_username": "Sumulat ng isang gumagamit",
        "no_results": "Nang walang resulta",
        "create": "Lumikha",
        "no_content_uploaded": "Walang na-upload na nilalaman",
        "new_subtag": "Bagong subtag",
        "update_info": "I-update ang impormasyon",
        "updating": "Nag-a-update",
        "update": "Update",
        "update_email": "I-update ang email",
        "change_password": "Palitan ANG password",
        "current_password": "Kasalukuyang password",
        "select": "Pumili",
        "tap_to_upload_a_file": "I-tap para mag-upload ng file",
        "edit_photo": "I-edit ang larawan",
        "preview": "Silipin",
        "edit_video": "I-edit ang video",
        "delete_changes": "Tanggalin ang mga pagbabago",
        "uploading": "Nag-a-upload",
        "session_deleted_or_expired": "Ang session ay tinanggal o nag-expire na.",
        "edit_profile": "Baguhin ang profile",
        "devices": "Mga device",
        "downloads": "Mga download",
        "logout": "Mag-sign off",
        "offline": "Nang walang koneksyon",
        "images": "Mga imahe",
        "videos": "Mga video",
        "audios": "Mga Audio",
        "chat": "Chat",
        "session_unauthorized": "Ang iyong session ay hindi awtorisado.",
        "authorize_session": "Upang ma-access ang session na ito, kailangan mong pahintulutan ang iyong device.",
        "authorize_instructions": "Tingnan ang iyong email para sa link ng pahintulot o pumunta sa isang aktibong session at piliin ang tatlong tuldok sa kanang sulok sa itaas, pagkatapos ay pumunta sa \"Mga Device\" at piliin ang \"Pahintulutan\".",
        "refresh_page_after_authorize": "Kapag nakumpleto mo na ang proseso ng awtorisasyon, i-refresh ang page para ma-access ang session.",
        "check_your_inbox": "Suriin ang iyong e-mail",
        "send_authorization_email": "Magpadala ng email ng awtorisasyon",
        "call_finished": "natapos ang tawag",
        "close": "Isara",
        "waiting_stream": "Naghihintay ng stream",
        "request_camera_and_mic": "Kailangan namin ng access sa iyong camera at mikropono para makatawag.",
        "call_in_progress": "Kasalukuyang isinasagawa ang tawag",
        "group": "Cluster",
        "videocall": "Video call",
        "call": "Tumawag",
        "connecting": "Kumokonekta",
        "call_cancelled": "Kinansela ang tawag",
        "calling": "Tumatawag",
        "hangup": "Ibitin",
        "reject": "Tanggihan",
        "has_sent_a_file": "ay nagpadala ng file.",
        "keep": "Panatilihin",
        "keep_file": "Kung pinagana mo ang panatilihin ang file, mase-save ito sa app. Papayagan nito ang accessibility nito nang hindi nangangailangan ng koneksyon sa Internet.",
        "not_keep_file": "Kung i-off mo ang pagpapanatili ng file, tatanggalin ang mga ito at hindi mo maa-access ang mga ito nang walang koneksyon sa Internet.",
        "file_stored_instruction": "Lalabas ang lahat ng nakaimbak na file na may berdeng hangganan sa karaniwang listahan ng file. Maaari mo ring makita ang mga ito sa seksyon",
        "stored": "Nakaimbak",
        "not_stored": "Hindi ito nakaimbak",
        "file_not_stored_instruction": "Hindi mo maa-access ang file na ito nang walang koneksyon sa internet.",
        "device": "Device",
        "file_visibility": "Visibility ng File",
        "visible_for_everyone": "Nakikita ng lahat",
        "shared_link": "Nakabahaging link",
        "hashtags": "Mga tag",
        "hashtags_helps_to_search_your_file": "Tinutulungan ka ng mga hashtag na mahanap ang iyong mga file.",
        "edit": "I-edit",
        "move": "Ilipat",
        "rename": "Palitan ang pangalan",
        "visibility": "Visibility",
        "delete": "Tanggalin",
        "file_warn_delete": "Sigurado ka bang gusto mong tanggalin ang file?",
        "no_subtags_availables": "Walang available na mga subtag",
        "owner": "Tagapaglikha",
        "expulsing": "Nagpapatalsik",
        "expulse": "Expulse",
        "you_have_left_the_group": "Umalis ka na sa grupo",
        "coming_out": "Lumalabas",
        "leave_group": "Umalis sa grupo",
        "you_have_left_the_room": "Lumabas ka na ng kwarto.",
        "leave_room": "Umalis sa kwarto",
        "message_deleted": "Tinanggal na mensahe",
        "has_been_error_deleting_message": "Nagkaroon ng error sa pagtanggal ng mensahe.",
        "deleting": "Tinatanggal",
        "new_tag": "Bagong tag",
        "photoEditor": {
            "name": "Pangalan",
            "save": "Tapusin",
            "saveAs": "I-save bilang",
            "back": "Bumalik ka",
            "loading": "Nagcha-charge…",
            "resetOperations": "I-restart/Tanggalin ang lahat ng mga operasyon.",
            "changesLoseConfirmation": "Aalisin ang lahat ng pagbabago.",
            "changesLoseConfirmationHint": "Sigurado ka bang gusto mong magpatuloy?",
            "cancel": "Kanselahin",
            "continue": "Magpatuloy",
            "undoTitle": "I-undo ang huling operasyon",
            "redoTitle": "Gawin muli ang huling operasyon",
            "showImageTitle": "Ipakita ang orihinal na larawan",
            "zoomInTitle": "Palakihin",
            "zoomOutTitle": "Ward off",
            "toggleZoomMenuTitle": "I-toggle ang zoom menu",
            "adjustTab": "Ayusin",
            "finetuneTab": "Mga kulay",
            "filtersTab": "Mga filter",
            "watermarkTab": "Mga imahe",
            "annotateTab": "Gumuhit",
            "resize": "Baguhin ang laki",
            "resizeTab": "Sukat",
            "invalidImageError": "Di-wastong larawan",
            "uploadImageError": "Nagkaroon ng error habang nilo-load ang larawan.",
            "areNotImages": "Hindi sila mga imahe",
            "isNotImage": "hindi ito isang imahe",
            "toBeUploaded": "na i-upload",
            "cropTool": "I-crop",
            "original": "Orihinal",
            "custom": "Custom",
            "square": "Square",
            "landscape": "Pahalang",
            "portrait": "Patayo",
            "ellipse": "Bilog",
            "classicTv": "klasikong tv",
            "cinemascope": "Cinemascope",
            "arrowTool": "Palaso",
            "blurTool": "Malabo",
            "brightnessTool": "Liwanag",
            "contrastTool": "Contrast",
            "ellipseTool": "Bilog",
            "unFlipX": "I-unflip ang X",
            "flipX": "I-flip ang X",
            "unFlipY": "I-unflip ang Y",
            "flipY": "I-flip ang Y",
            "hsvTool": "MSV",
            "hue": "Hue",
            "saturation": "Saturation",
            "value": "Halaga",
            "imageTool": "Imahe",
            "importing": "Ini-import…",
            "addImage": "+ Magdagdag ng larawan",
            "uploadImage": "Mag-upload ng larawan",
            "fromGallery": "Mga sticker",
            "lineTool": "Linya",
            "penTool": "Lapis",
            "polygonTool": "polygon",
            "sides": "Mga gilid",
            "rectangleTool": "Parihaba",
            "cornerRadius": "Radius ng gilid",
            "resizeWidthTitle": "Lapad sa pixel",
            "resizeHeightTitle": "Taas ng pixel",
            "toggleRatioLockTitle": "I-toggle ang Lock ng Dimensyon",
            "reset": "I-restart",
            "resetSize": "I-reset sa orihinal na mga sukat ng larawan",
            "rotateTool": "Iikot",
            "textTool": "Text",
            "textSpacings": "Text spacing",
            "textAlignment": "Pag-align ng Teksto",
            "fontFamily": "Font",
            "size": "Sukat",
            "letterSpacing": "Puwang ng titik",
            "lineHeight": "Taas ng linya",
            "warmthTool": "Init",
            "addWatermark": "+ Magdagdag ng watermark",
            "addWatermarkTitle": "Magdagdag ng pamagat ng watermark",
            "uploadWatermark": "Mag-upload ng watermark",
            "addWatermarkAsText": "Magdagdag ng watermark bilang teksto",
            "padding": "Padding",
            "shadow": "Lilim",
            "horizontal": "Pahalang",
            "vertical": "Patayo",
            "blur": "Malabo",
            "opacity": "Opacity",
            "position": "Posisyon",
            "stroke": "Stroke",
            "saveAsModalLabel": "Mga Detalye",
            "extension": "Extension",
            "nameIsRequired": "Pangalan ang kailangan.",
            "quality": "Kalidad",
            "imageDimensionsHoverTitle": "Mga naka-save na sukat ng larawan (lapad x taas)",
            "cropSizeLowerThanResizedWarning": "Pakitandaan na ang napiling crop area ay mas maliit kaysa sa resize na inilapat, na maaaring magdulot ng pagbaba sa kalidad.",
            "actualSize": "Kasalukuyang laki (100%)",
            "fitSize": "Ayusin ang laki",
            "addImageTitle": "Pumili ng larawang idadagdag…"
        },
        "changing": "Nagbabago",
        "profile_picture_changed": "Nabago ang larawan sa profile",
        "loading_profile_picture": "Nilo-load ang larawan sa profile",
        "change": "Baguhin",
        "session": "Sesyon",
        "system_operative": "System operative",
        "navigator": "Browser",
        "authorize": "Pahintulutan",
        "deauthorize": "Alisin ang pahintulot",
        "keep_tag_content": "Gusto mo bang panatilihin ang nilalaman ng tag?",
        "move_content_to": "Ilipat ang nilalaman sa",
        "move_to": "Ilipat sa",
        "chat_request": "Kahilingan sa chat",
        "accept_request": "Tanggapin ang alok",
        "go_to_chat": "Pumunta sa chat",
        "applying": "Nag-aaplay",
        "time": "Oras",
        "adjust": "Ayusin",
        "rotate": "Iikot",
        "start": "Magsimula",
        "end": "Pangwakas",
        "lang_name": "Tagalog"
    },
    "uk": {
        "name": "Ім'я",
        "name_example": "Aleksandr",
        "last_name": "Прізвище",
        "last_name_example": "Melnyk",
        "username": "Користувач",
        "username_example": "aleksandr_melnyk",
        "email": "Електронна адреса",
        "email_example": "kористувач@домен.com",
        "password": "Пароль",
        "new_password": "Новий пароль",
        "password_example": "*****************",
        "login": "Логін",
        "register": "Реєстрація",
        "verification_code": "Код верифікації",
        "verification_code_example": "xxx",
        "verify": "Підтвердити",
        "loading": "Загрузка…",
        "please_wait": "Будь ласка, зачекайте",
        "go_back": "Повернутися до",
        "recovery": "Відновлення",
        "login_subtitle": "Введіть свої дані та увійдіть до свого облікового запису.",
        "forgot_password_link": "Забули пароль? Отримати.",
        "register_account_link": "Не маєте облікового запису? Зареєструйтеся!",
        "register_subtitle": "Введіть свої дані та зареєструйте акаунт.",
        "login_link": "Вже маєте акаунт? Увійдіть!",
        "verification_code_instruction": "Перевірте свою електронну пошту та введіть надісланий код.",
        "forgot_password_subtitle": "Введіть свою електронну адресу, щоб отримати пароль.",
        "change_password_subtitle": "Введіть новий пароль.",
        "change_password_code_used": "Код для відновлення пароля недійсний або вже був використаний.",
        "no_chats": "Ти не ведеш розмов.",
        "chats": "Розмови",
        "groups": "Групи",
        "rooms": "Кімнати",
        "save": "Зберегти",
        "download": "Завантажити",
        "downloading_image": "Завантажити зображення",
        "an_error_has_ocurred": "Виникла помилка",
        "saving_changes": "Збереження змін",
        "go_back_to_edit": "Повернутися до редагування",
        "upload_warning_modal": "Завантаживши редагування, ви заміните оригінальне зображення.",
        "upload_question_modal": "Ви хочете зберегти оригінал і створити копію?",
        "create_copy_and_keep": "Створіть копію та збережіть",
        "upload": "Завантажити",
        "upload_changes": "Завантажте зміни",
        "changes_saved": "Збережені зміни",
        "downloading_video": "Завантажити відео",
        "video_upload_warning_modal": "Завантаживши монтаж, ви заміните оригінальне відео.",
        "results": "Результати",
        "members": "Члени",
        "cancel": "Скасувати",
        "add_user": "Додати користувача",
        "no_messages": "Немає повідомлень",
        "message_placeholder": "Послання…",
        "group_doesnt_exists": "Група не існує",
        "you_arent_group_member": "Ви не є учасником групи",
        "touch_to_see_more_info": "Торкніться, щоб побачити більше інформації.",
        "start_videocall": "Почати відеодзвінок",
        "start_call": "Початок дзвінка",
        "create_group": "Створити групу",
        "group_name": "Назва групи",
        "add_members_to_create_group": "Додайте учасників для створення групи",
        "search": "Шукати",
        "creating": "Створюючи…",
        "dont_belong_anyone_group": "Ви не належите до жодної групи.",
        "already_add_this_hashtag": "Ви вже додали цей хештег",
        "search_files_by_hashtags": "Пошук файлів за хештегами",
        "enter_to_add": "Введіть для додавання",
        "hashtags_most_used": "Найуживаніші хештеги",
        "can_add_it_giving_click": "Ви можете додати їх до пошуку, натиснувши на них.",
        "searching_files": "Пошук файлів",
        "no_files_found": "Файлів не знайдено",
        "image": "Зображення",
        "video": "Відео",
        "audio": "Аудіо",
        "library": "Книгарня",
        "home": "Головна",
        "block": "Блокування",
        "unblock": "Розблокувати",
        "has_blocked_you": "Він заблокував вас",
        "accept": "Прийняти",
        "decline": "Відхилити",
        "cancel_request": "Скасувати запит",
        "has_been_expulsed": "було виключено.",
        "room_doesnt_exists": "Кімната не існує",
        "you_arent_room_member": "Ви не є учасником кімнати",
        "create_room": "Створити кімнату",
        "room_name": "Назва кімнати",
        "no_rooms_created": "Ніяких кімнат не створено.",
        "dont_belong_to_anyone_room": "Ви не належите до жодної кімнати.",
        "enter_a_username": "Написати користувача",
        "no_results": "Немає результатів",
        "create": "Створити",
        "no_content_uploaded": "Вміст не завантажено",
        "new_subtag": "Новий підтег",
        "update_info": "Оновлення інформації",
        "updating": "Оновлюємось",
        "update": "Оновлення",
        "update_email": "Оновити електронну адресу",
        "change_password": "Змінити пароль",
        "current_password": "Поточний пароль",
        "select": "Виберіть",
        "tap_to_upload_a_file": "Торкніться, щоб завантажити файл",
        "edit_photo": "Редагувати фото",
        "preview": "Попередній перегляд",
        "edit_video": "Редагувати відео",
        "delete_changes": "Видалити зміни",
        "uploading": "Завантажуємо",
        "session_deleted_or_expired": "Сеанс видалено або закінчився",
        "edit_profile": "Змінити профіль",
        "devices": "Пристрої",
        "downloads": "Завантаження",
        "logout": "Вийди з системи",
        "offline": "Офлайн",
        "images": "Образи",
        "videos": "Відеоролики",
        "audios": "Аудіо",
        "chat": "Чат",
        "session_unauthorized": "Ваш сеанс не авторизований.",
        "authorize_session": "Щоб отримати доступ до цього сеансу, вам потрібно авторизувати свій пристрій.",
        "authorize_instructions": "Будь ласка, перевірте свою електронну пошту, щоб отримати посилання для авторизації, або перейдіть до активного сеансу і натисніть три крапки у верхньому правому куті, потім перейдіть до розділу \"Пристрої\" і виберіть \"Авторизуватись\".",
        "refresh_page_after_authorize": "Після завершення процесу авторизації, будь ласка, оновіть сторінку, щоб увійти в систему.",
        "check_your_inbox": "Перевірте свою електронну пошту",
        "send_authorization_email": "Надішліть електронний лист для підтвердження",
        "call_finished": "Дзвінок завершено",
        "close": "Закрити",
        "waiting_stream": "Очікує передачі",
        "request_camera_and_mic": "Нам потрібен доступ до вашої камери та мікрофону, щоб ви могли здійснювати дзвінки.",
        "call_in_progress": "Виклик виконується",
        "group": "Група",
        "videocall": "Відеодзвінок",
        "call": "Дзвони",
        "connecting": "Під'єднання",
        "call_cancelled": "Дзвінок скасовано",
        "calling": "Викликаю",
        "hangup": "Клади слухавку",
        "reject": "Відхилити",
        "has_sent_a_file": "надіслав файл.",
        "keep": "Заповідник",
        "keep_file": "Якщо ви увімкнете збереження файлів, файл буде збережено в додатку. Це дозволить отримати доступ до нього без підключення до Інтернету.",
        "not_keep_file": "Якщо ви вимкнете збереження файлів, файл буде видалено, і ви не зможете отримати до нього доступ без підключення до Інтернету.",
        "file_stored_instruction": "Усі збережені файли з'являться в загальному списку файлів із зеленою рамкою. Ви також можете переглянути їх у розділі",
        "stored": "Зберігається",
        "not_stored": "Не зберігаються",
        "file_not_stored_instruction": "Ви не зможете отримати доступ до цього файлу без підключення до Інтернету.",
        "device": "Пристрій",
        "file_visibility": "Видимість архіву",
        "visible_for_everyone": "Видимий для всіх",
        "shared_link": "Спільне посилання",
        "hashtags": "Хештеги",
        "hashtags_helps_to_search_your_file": "Хештеги допомагають у пошуку файлів.",
        "edit": "Редагувати",
        "move": "Рухайся",
        "rename": "Перейменувати",
        "visibility": "Видимість",
        "delete": "Видалити",
        "file_warn_delete": "Ви впевнені, що хочете видалити файл?",
        "no_subtags_availables": "Підтеги не доступні",
        "owner": "Творець",
        "expulsing": "Вигнання",
        "expulse": "Вигнати",
        "you_have_left_the_group": "Ви вийшли з групи",
        "coming_out": "Виходь",
        "leave_group": "Вийти з групи",
        "you_have_left_the_room": "Ви вийшли з кімнати.",
        "leave_room": "Вихід з кімнати",
        "message_deleted": "Повідомлення видалено",
        "has_been_error_deleting_message": "Виникла помилка при видаленні повідомлення",
        "deleting": "Усуваючи",
        "new_tag": "Новий тег",
        "photoEditor": {
            "name": "Ім'я",
            "save": "Зберегти",
            "saveAs": "Зберегти як",
            "back": "Назад",
            "loading": "Загрузка…",
            "resetOperations": "Скинути/Видалити всі операції.",
            "changesLoseConfirmation": "Всі зміни будуть видалені.",
            "changesLoseConfirmationHint": "Ви впевнені, що хочете продовжувати?",
            "cancel": "Скасувати",
            "continue": "Продовжуйте",
            "undoTitle": "Скасування останньої операції",
            "redoTitle": "Повторити останню операцію",
            "showImageTitle": "Показати оригінальне зображення",
            "zoomInTitle": "Збільшити",
            "zoomOutTitle": "Зменшити",
            "toggleZoomMenuTitle": "Перемикання меню масштабування",
            "adjustTab": "Відрегулювати",
            "finetuneTab": "Кольори",
            "filtersTab": "Фільтри",
            "watermarkTab": "Образи",
            "annotateTab": "Малювання",
            "resize": "Змінити розмір",
            "resizeTab": "Розмір",
            "invalidImageError": "Недійсне зображення",
            "uploadImageError": "Під час завантаження зображення сталася помилка.",
            "areNotImages": "вони не є зображеннями",
            "isNotImage": "не є зображенням",
            "toBeUploaded": "які будуть завантажені",
            "cropTool": "Знято",
            "original": "Оригінал",
            "custom": "Персоналізований",
            "square": "Сквер",
            "landscape": "Горизонтальна",
            "portrait": "Вертикальний",
            "ellipse": "Коло",
            "classicTv": "Класичне ТБ",
            "cinemascope": "Сінемаскоп",
            "arrowTool": "Стріла",
            "blurTool": "Пятно",
            "brightnessTool": "Яскравість",
            "contrastTool": "Контраст",
            "ellipseTool": "Коло",
            "unFlipX": "Перевернути X",
            "flipX": "Переверніть X",
            "unFlipY": "Перевернути Y",
            "flipY": "Переверніть Y",
            "hsvTool": "MSV",
            "hue": "Відтінок",
            "saturation": "Насиченість",
            "value": "Цінність",
            "imageTool": "Зображення",
            "importing": "Імпортування…",
            "addImage": "+ Додати зображення",
            "uploadImage": "Завантажте зображення",
            "fromGallery": "Наклейки",
            "lineTool": "Лінія",
            "penTool": "Олівець",
            "polygonTool": "Полігон",
            "sides": "Боки",
            "rectangleTool": "Прямокутник",
            "cornerRadius": "Радіус краю",
            "resizeWidthTitle": "Ширина в пікселях",
            "resizeHeightTitle": "Висота в пікселях",
            "toggleRatioLockTitle": "Перемикач фіксатора розміру",
            "reset": "Перезавантажити",
            "resetSize": "Відновлення вихідних розмірів зображення",
            "rotateTool": "Поворот",
            "textTool": "Текст",
            "textSpacings": "Інтервал між текстами",
            "textAlignment": "Вирівнювання тексту",
            "fontFamily": "Сімейство шрифтів",
            "size": "Розмір",
            "letterSpacing": "Інтервал між літерами",
            "lineHeight": "Висота рядка",
            "warmthTool": "Тепло",
            "addWatermark": "+ Додати зображення",
            "addWatermarkTitle": "Виберіть тип водяного знаку",
            "uploadWatermark": "Виберіть зображення",
            "addWatermarkAsText": "Додати текст",
            "padding": "Наповнення",
            "shadow": "Тінь",
            "horizontal": "Горизонтальний",
            "vertical": "Вертикальний",
            "blur": "Розмиття",
            "opacity": "Непрозорість",
            "position": "Позиція",
            "stroke": "Товщина",
            "saveAsModalLabel": "Деталі",
            "extension": "Подовження",
            "nameIsRequired": "Ім'я обов'язкове.",
            "quality": "Якість",
            "imageDimensionsHoverTitle": "Збережені розміри зображення (ширина x висота)",
            "cropSizeLowerThanResizedWarning": "Зверніть увагу, що обрана область обрізання менша за застосований розмір, що може призвести до погіршення якості.",
            "actualSize": "Поточний розмір (100%)",
            "fitSize": "Адаптувати розмірність",
            "addImageTitle": "Виберіть зображення для додавання…"
        },
        "changing": "Змінюється",
        "profile_picture_changed": "Змінено зображення профілю",
        "loading_profile_picture": "Завантаження зображення профілю",
        "change": "Зміна",
        "session": "Сесія",
        "system_operative": "Операційна система",
        "navigator": "Браузер",
        "authorize": "Авторизуйтесь",
        "deauthorize": "Деавторизація",
        "keep_tag_content": "Ви хочете зберегти вміст тегу?",
        "move_content_to": "Перенесіть вміст до",
        "move_to": "Переходимо до",
        "chat_request": "Запит в чат",
        "accept_request": "Прийняти заявку",
        "go_to_chat": "Перейти до чату",
        "applying": "Подати заявку",
        "time": "Погода",
        "adjust": "Відрегулювати",
        "rotate": "Поворот",
        "start": "Старт",
        "end": "Кінець",
        "lang_name": "Ukrainian"
    }
}

export default langs