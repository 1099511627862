import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const chatListSlice = createSlice({
    name: 'chatList',
    initialState,
    reducers: {
        setChats: (state, { payload: chats }) => {
            return chats
        },
        addChat: (state, { payload: newChat }) => {
            let newList = state.filter(chat => chat.id !== newChat.id)
            newList.unshift(newChat)
            return newList
        },
        acceptChat: (state, { payload: chatId }) => {
            const chat = state.find(chat => chat.id === chatId)
            chat.accepted = true
        },
        removeChat: (state, { payload: chatId }) => {
            return state.filter(chat => chat.id !== chatId)
        },
        reset: () => initialState
    }

})
export const {
    setChats,
    addChat,
    removeChat,
    acceptChat,
    reset
} = chatListSlice.actions
export default chatListSlice.reducer