import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { reset } from "../../../features/multimediaSlice"
import { useAddSocketEvent } from "../../../components/SocketProvider"
import { createGroup, getGroups } from "../../../services/group"
import { addGroup, removeGroup, setGroups, updateGroup, updateLastMessageGroup, updatePositionList } from "../../../features/groupListSlice"
import { RiAddFill } from "react-icons/ri"
import Modal from "../../../components/Modal"
import Input from "../../../components/Input"
import Button from "../../../components/Button"
import { searchMember } from "../../../services/groupMember"
import { toast } from "react-hot-toast"
import Group from "../../../components/Group"
import { API_URL } from "../../../config"
import Loader from "../../../components/Loader"
import { editMessage } from "../../../features/groupSlice"
import LabelTitle from "../../../components/LabelTitle"
import Text from "../../../components/Text"
import langs from "../../../lang/langs"

const GroupPage = () => {
    const { token, groupList, lang } = useSelector(state => state)

    const [loading, setLoading] = useState(true)
    const [modalCreateGroup, setModalCreateGroup] = useState(false)
    const [members, setMembers] = useState([])
    const [groupName, setGroupName] = useState("")
    const [username, setUsername] = useState("")
    const [searchList, setSearchList] = useState([])
    const [creating, setCreating] = useState(false)
    // const [searching, setSearching] = useState(false)
    const dispatch = useDispatch()

    const handleSearchMember = (event) => {
        event.preventDefault()
        if (username.trim().length === 0) return
        searchMember({ token, username, memberIdsInList: members.map(user => user.id) }).then((res) => {
            setSearchList(res.data.payload.users)
        })
    }

    const handleAddMember = (member) => {
        const newList = [...members]
        newList.push(member)
        setMembers(newList)
        setSearchList([])
    }

    const handleDeleteChat = (memberId) => {
        const newList = members.filter(member => member.id !== memberId)
        setMembers(newList)
    }

    const handleCreateGroup = () => {
        createGroup({ token, name: groupName, membersIds: members.map(user => user.id) })
            .then((res) => {
                setModalCreateGroup(false)
                setGroupName("")
                setCreating(false)
                setMembers([])
                setSearchList([])
                setUsername("")
                toast.success(res.data.message)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    useAddSocketEvent('group:added', (group) => {
        dispatch(addGroup(group))
    })

    useAddSocketEvent('group:update', ({ memberId, message }) => {
        console.log(groupList)
        dispatch(updateLastMessageGroup({ memberId, message }))
        dispatch(updatePositionList({ memberId }))


    }, [groupList])

    useAddSocketEvent('group:member:expulse', member => {
        dispatch(removeGroup(member.id))
    })

    useEffect(() => {
        getGroups({ token }).then(chats => {
            dispatch(setGroups(chats.data.payload.groups))
            setLoading(false)
        })
        dispatch(reset())
    }, [dispatch, token])

    if (loading) return <Loader />

    return (
        <div className="flex flex-col gap-5 p-5 bg-white rounded">
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
                <button
                    className="flex items-center justify-center gap-2 text-gray-500 px-5 py-2 border rounded-lg"
                    onClick={() => setModalCreateGroup(true)}
                >
                    <RiAddFill /> {langs[lang]['create_group']}
                </button>
            </div>

            <Modal modalOpen={modalCreateGroup} set={() => setModalCreateGroup(false)}>
                <div className="flex flex-col gap-5 bg-white p-5 rounded w-[320px]">
                    <Input label={langs[lang]['group_name']} type="text" placeholder={langs[lang]['group_name']} onChange={({ target: { value } }) => setGroupName(value)} value={groupName} />

                    <LabelTitle>{langs[lang]['members']}</LabelTitle>
                    <div className="flex gap-2 flex-wrap">
                        {members.length > 0 ?
                            members.map(user => (
                                <div
                                    key={user.id}
                                    className="flex gap-1 items-center px-2 py-1 rounded-full cursor-pointer border border-[#4da2d9] text-[#4da2d9] hover:text-red-500 hover:border-red-500 transition-all"
                                    onClick={() => handleDeleteChat(user.id)}
                                >
                                    <img crossOrigin="anonymous" className="w-[20px] h-[20px] rounded-full" src={user.profilePicture ? `${API_URL}/users/${user.id}/profile/picture` : '/pp.png'} alt={user.username} />
                                    <Text>{user.username}</Text>
                                </div>
                            ))
                            :
                            <p className="text-gray-500 text-center">{langs[lang]['add_members_to_create_group']}</p>
                        }
                    </div>

                    <form className="flex gap-2" onSubmit={handleSearchMember}>
                        <Input
                            type="text"
                            placeholder={langs[lang]['username']}
                            value={username}
                            onChange={({ target: { value } }) => setUsername(value)}
                        />
                        <Button>{langs[lang]['search']}</Button>
                    </form>
                    {
                        searchList.length > 0 &&
                        <div className="flex flex-col gap-1 max-h-[120px] overflow-y-auto rounded p-1">
                            {
                                searchList.map(user => (
                                    <div key={user.username} className="flex items-center cursor-pointer gap-5" onClick={() => handleAddMember(user)}>
                                        <img crossOrigin="anonymous" className="w-[40px] h-[40px] rounded-full" src={user.profilePicture ? `${API_URL}/users/${user.id}/profile/picture` : '/pp.png'} alt={user.username} />
                                        <div className="flex flex-col gap-1">
                                            <p className="text-gray-500 capitalize">{user.firstName} {user.lastName}</p>
                                            <p className="text-gray-500">@{user.username}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    {members.length > 0 && groupName.trim().length > 0 && <Button onClick={handleCreateGroup} disabled={creating}>{creating ? langs[lang]['creating'] : langs[lang]['create_group']}</Button>}
                </div>
            </Modal>

            <div className="flex flex-col gap-5">
                {groupList.length > 0 ?
                    groupList.map(member => <Group key={member.id} member={member} />)
                    :
                    <Text>{langs[lang]['dont_belong_anyone_group']}</Text>
                }
            </div>
        </div>
    )
}

export default GroupPage