import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import Loader from "../../../components/Loader"
import { getPublicFileData } from "../../../services/file"
import { useSelector } from "react-redux"
import File from "../../../components/File"
import Button from "../../../components/Button"
import { toast } from "react-hot-toast"
import { API_URL } from "../../../config"
import axios from "axios"
import langs from "../../../lang/langs"

const DownloadFilePage = () => {
    const { fileId } = useParams()
    const [file, setFile] = useState({})
    const [fileSrc, setFileSrc] = useState(false)
    const [loading, setLoading] = useState(true)
    const [downloading, setDownloading] = useState(false)
    const [downloadProgress, setDownloadProgress] = useState(0.0)
    const { token, lang } = useSelector(state => state)
    const navigate = useNavigate()

    const handleDownoadFile = () => {
        if (!fileSrc) {
            setDownloading(true)
            axios.get(`${API_URL}/files/${fileId}`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                onDownloadProgress: ({ progress }) => setDownloadProgress((progress * 100).toFixed(2))
            })
                .then((response) => {
                    const url = URL.createObjectURL(response.data)

                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', file.name)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)

                    setFileSrc(url)
                    setDownloading(false)
                    setDownloadProgress(0.0)
                })
        } else {
            const link = document.createElement('a')
            link.href = fileSrc
            link.setAttribute('download', file.name)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }


    useEffect(() => {
        getPublicFileData({ token, fileId }).then(res => {
            if (!res.data.payload.file.public) {
                navigate('/images')
                return
            }
            setFile(res.data.payload.file)
            setLoading(false)
        }).catch((error) => {
            navigate('/images')
            toast.error(error.response.data.message)
        })
    }, [])

    if (loading) return <Loader text="Cargando archivo..." />

    return (
        <div className="bg-white p-5 rounded flex-auto flex justify-center items-center">
            <div className="flex w-[310px] flex-col gap-5">
                <div className="flex gap-1 items-center">
                    <img crossOrigin="anonymous" className="w-[40px] h-[40px] rounded-full" src={file.User.profilePicture ? `${API_URL}/users/${file.User.id}/profile/picture` : '/pp.png'} alt={file.User.username} />
                    <p className="text-gray-500">@{file.User.username}</p>
                </div>
                <File file={file} showOptions={false} onDownloadFinished={({ source }) => setFileSrc(source)} />
                {downloading &&
                    <div className="h-[20px] rounded-lg w-full bg-gray-100 overflow-hidden">
                        {/* #4da2d9 */}
                        <div className="flex items-center justify-center bg-[#4da2d9] text-xs font-medium text-white text-center p-0.5 leading-none h-full" style={{ width: `${downloadProgress}%` }}>{downloadProgress}%</div>
                    </div>
                }
                <Button height="sm" onClick={handleDownoadFile}>{fileSrc ? langs[lang]['save'] : langs[lang]['download']}</Button>
            </div>
        </div>
    )
}

export default DownloadFilePage