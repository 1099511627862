const TermsAndConditions = () => {
    return (
        <div className="p-5">
            <h1 className="text-xl font-bold">Your World Terms and Conditions</h1>
            <p className="font-bold">1. Acceptance of Terms</p>
            <p>By registering and using the "Your World" service, you agree to comply with and be bound by the following terms and conditions. If you do not agree to these terms, please do not use the service.</p>

            <p className="font-bold">2. Description of the Service</p>
            <p>Your World offers the following functionalities:</p>

            <ul className="list-disc pl-10">
                <li>Personal storage</li>
                <li>File sharing</li>
                <li>Chats</li>
                <li>Video calls</li>
                <li>Voice calls</li>
                <li>Group rooms</li>
            </ul>
            <p className="font-bold">3. User Registration and Account</p>
            To register with Your World, you must provide the following information:
            <ul className="list-disc pl-10">
                <li>First name</li>
                <li>Last name</li>
                <li>Username</li>
                <li>Email address</li>
                <li>Password</li>
            </ul>
            <p>There are no age or geographic restrictions for registering for the service.</p>

            <p className="font-bold">4. Privacy and Security</p>
            <p>User information and files are protected with MD5 encryption.</p>
            <p>User passwords are encrypted.</p>
            <p>We use encryption for stored files.</p>
            <p className="font-bold">5. Content and Use of the Service</p>
            <p>The following types of content are prohibited on Your World:</p>

            <ul className="list-disc pl-10">
                <li>Illegal material</li>
            </ul>
            <p>The following actions are prohibited when using the service:</p>

            <ul className="list-disc pl-10">
                <li>Misuse for spam attacks</li>
            </ul>
            <p className="font-bold">6. Intellectual Property</p>
            <p>
                Files uploaded by users remain the property of the users unless they are public in nature.
                If we receive copyright claims, we will cancel the user account if it is confirmed that the rights have been infringed or if the activity is illegal.
            </p>
            <p className="font-bold">7. Limitation of Liability</p>
            <p>
                Your World is not responsible for data loss, service interruptions, or other inconveniences that may arise from using the service.
            </p>

            <p className="font-bold">8. Service Modifications</p>
            <p>
                Your World reserves the right to modify these terms and conditions at any time. Users will be notified of any changes through login notices.
            </p>

            <p className="font-bold">9. Payments and Plans</p>
            <p>
                Your World offers a free plan allowing up to 10 GB of storage per user. We do not offer paid plans and there are no refund policies as the service is free.
            </p>

            <p className="font-bold">10. Account Termination and Suspension</p>
            <p>
                Your World reserves the right to suspend or terminate a user account under the following circumstances:
            </p>
            <ul className="list-disc pl-10">
                <li>Violation of these terms and conditions</li>
            </ul>
            <p className="font-bold">11. Contact</p>
            <p>
                For any questions or issues related to the service, users can contact us via email: <a className="text-blue-500" href="mailto:support@yourworld.es">support@yourworld.es</a>.
            </p>



            <p className="font-bold pt-10">Your World Privacy Policy</p>
            <p className="font-bold">Introduction</p>
            <p>
                At Your World, we are committed to protecting the privacy of our users. This privacy policy describes how we collect, use, and protect the personal information of our users.
            </p>

            <p className="font-bold">Information Collection</p>
            <p>
                We collect the following information from our users during registration:
            </p>
            <ul className="list-disc pl-10">
                <li> First name</li>
                <li>Last name</li>
                <li>Username</li>
                <li>Email address</li>
                <li>Password</li>
            </ul>
            <p className="font-bold">Use of Information</p>
            <p>We use the information to provide and improve our services.</p>
            <p>Passwords are encrypted to ensure user security.</p>
            <p className="font-bold">Information Protection</p>
            <p>We use MD5 encryption to protect stored information and files.</p>
            <p>We implement security measures to guard against unauthorized access.
                Changes to this Policy</p>
            <p>We may update this privacy policy from time to time. Users will be notified of any significant changes through login notices.</p>


        </div>
    )
}


export default TermsAndConditions