import axios from "axios";
import { API_URL } from "../config";

export const searchMember = ({ token, username, memberIdsInList }) => axios.post(`${API_URL}/groups/members/search`, {
    memberIdsInList, username
}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const leaveGroup = ({ token, groupId }) => axios.delete(`${API_URL}/groups/members/${groupId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const addGroupMember = ({ token, groupId, userId }) => axios.post(`${API_URL}/groups/members`, { groupId, userId }, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const expulseGroupMember = ({ token, memberId }) => axios.delete(`${API_URL}/groups/members/expulse/${memberId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})