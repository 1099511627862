import { useDispatch, useSelector } from "react-redux"
import { API_URL } from "../../config"
import { useState } from "react"
import { expulseGroupMember } from "../../services/groupMember"
import { toast } from "react-hot-toast"
import { removeGroupMember } from "../../features/groupSlice"
import langs from "../../lang/langs"

const GroupMember = ({ member }) => {
    const { groupChat, session, token, lang } = useSelector(state => state)
    const [requesting, setRequesting] = useState(false)

    const dispatch = useDispatch()

    const handleExpulseMember = () => {
        setRequesting(true)
        expulseGroupMember({ token, memberId: member.id }).then((res) => {
            dispatch(removeGroupMember(member.id))
            setRequesting(false)
        }).catch(({ response }) => {
            toast.error(response.data.message)
        })
    }
    return (
        <div className="flex items-center gap-5">
            <img crossOrigin="anonymous" className="w-[40px] h-[40px] rounded-full" src={member.User.profilePicture ? `${API_URL}/users/${member.User.id}/profile/picture` : '/pp.png'} alt={member.User.username} />
            <div className="flex flex-col gap-1">
                <p className="text-gray-500 capitalize">{member.User.firstName} {member.User.lastName}</p>
                <p className="text-gray-500">@{member.User.username}</p>
                {member.User.id === groupChat.user.id && <p className="text-gray-500 text-xs">{langs[lang]['owner']}</p>}
                {
                    groupChat.user.id === session.user.id && member.User.id !== session.user.id &&
                    <button className="self-start text-red-500 text-xs uppercase" onClick={handleExpulseMember}>
                        {requesting ? langs[lang]['expulsing'] : langs[lang]['expulse']}
                    </button>
                }
            </div>
        </div>
    )
}

export default GroupMember