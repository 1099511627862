import { useNavigate } from "react-router-dom"
import Button from "../../components/Button"
import { joinRoom } from "../../services/room"
import { useSelector } from "react-redux"
import { useState } from "react"
import { toast } from "react-hot-toast"
import { RiUserSharedLine } from "react-icons/ri"

const RoomItem = ({ room }) => {
    const [requesting, setRequesting] = useState(false)
    const navigate = useNavigate()

    const { token } = useSelector(state => state)
    const handleJoinRoom = (roomId) => {
        setRequesting(true)
        joinRoom({ token, roomId })
            .then((res) => {
                navigate(`/chat/rooms/${res.data.payload.room.roomId}`)
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
                setRequesting(false)
            })
    }
    return (
        <div className="flex items-center justify-between overflow-hidden px-5 py-2 gap-2 border rounded">
            <p className="text-gray-500 truncate">{room.name}</p>
            <Button className="self-start" spinner={requesting} disabled={requesting} height="sm" onClick={() => handleJoinRoom(room.id)}>{!requesting && <RiUserSharedLine />}</Button>
        </div>
    )
}

export default RoomItem