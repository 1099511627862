import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { reset } from "../../../features/multimediaSlice"
import { getChats } from "../../../services/chat"
import Chat from "../../../components/Chat"
import Input from "../../../components/Input"
import Button from "../../../components/Button"
import { searchUser } from "../../../services/user"
import { toast } from "react-hot-toast"
import { API_URL } from "../../../config"
import { RiCloseFill, RiSendPlane2Fill } from "react-icons/ri"
import UserResult from "../../../components/UserResult"
import Loader from "../../../components/Loader"
import Text from "../../../components/Text"
import langs from "../../../lang/langs"

const SearchPage = () => {
    const [searching, setSearching] = useState(false)
    const [users, setUsers] = useState([])
    const [username, setUsername] = useState("")
    const { token, session, lang } = useSelector(state => state)
    const dispatch = useDispatch()

    const search = (event) => {
        event.preventDefault()
        if (username.length > 0) {
            setSearching(true)
            searchUser({ token, username })
                .then(users => {
                    setUsers(users.data.payload)
                    setSearching(false)
                })
                .catch(({ response }) => {
                    toast.error(`${response.data.message} (${response.data.section || 'idk'})`)
                    setSearching(false)
                })
        } else {
            toast.error(langs[lang]['enter_a_username'])
        }
    }

    useEffect(() => {
        dispatch(reset())
    }, [dispatch])

    return (
        <div className="p-5 bg-white rounded">
            <div className="flex flex-col gap-5">
                <form className="flex gap-5" onSubmit={search}>
                    <Input className="flex-auto" type="text" onChange={({ target: { value } }) => setUsername(value)} placeholder={langs[lang]['username']} value={username} />
                    <Button>{langs[lang]['search']}</Button>
                </form>
                {
                    searching ?
                        <Loader />
                        :
                        users.length > 0 ?
                            users.map(user =>
                                <UserResult key={user.id} user={user} />
                            )
                            :
                            <Text>{langs[lang]['no_results']}</Text>
                }
            </div>
        </div>
    )
}

export default SearchPage