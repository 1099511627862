import { createSlice } from '@reduxjs/toolkit'

const initialState = null

export const profilePictureSlice = createSlice({
    name: 'profilePicture',
    initialState,
    reducers: {
        setProfilePicture: (state, { payload }) => payload,
        deleteProfilePicture: () => initialState
    }

})
export const { setProfilePicture, deleteProfilePicture } = profilePictureSlice.actions
export default profilePictureSlice.reducer