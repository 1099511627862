import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { Link, useParams } from "react-router-dom"
import Button from "../../components/Button"
import Input from "../../components/Input"
import { checkPasswordForgotHash } from "../../services/passwordForgotHash"
import { changePassword } from "../../services/user.js"
import Loader from "../../components/Loader"
import langs from "../../lang/langs"
import { useSelector } from "react-redux"
import SelectLenguage from "../../components/SelectLanguage/index.js"

const ChangePasswordPage = () => {
    const { hash } = useParams()
    const [hashValid, setHashValid] = useState(null)
    const [requesting, setRequesting] = useState(false)
    const [form, setForm] = useState({
        password: '',
        hash
    })
    const { lang } = useSelector(state => state)

    const handleInputChange = ({ target: { name, value } }) => setForm({ ...form, [name]: value })

    const handleSubmit = event => {
        event.preventDefault()
        setRequesting(true)
        changePassword({ data: form })
            .then((response) => {
                toast.success(response.data.message)
                setRequesting(false)
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
                setRequesting(false)
            })
    }

    useEffect(() => {
        checkPasswordForgotHash({ hash })
            .then(() => {
                setHashValid(true)
            })
            .catch(() => {
                setHashValid(false)
            })
    }, [hash])

    if (hashValid === null) return <Loader text={langs[lang]['please_wait']} />

    if (!hashValid) {
        return (
            <div className="bg-gray-300 flex min-h-screen items-center justify-center">
                <div className="flex flex-col w-[400px] gap-5 bg-white border border-1 border-gray-100 px-5 py-10 rounded">
                    <p className="text-3xl font-light text-center text-gray-500">YourWorld</p>
                    <p className="text-md text-center text-gray-500">{langs[lang]['change_password_code_used']}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="bg-gray-300 flex min-h-screen items-center justify-center">
            <div className="flex flex-col w-[400px] gap-5 bg-white border border-1 border-gray-100 px-5 py-10 rounded">
                <img crossOrigin="anonymous" src="/logo-app.png" className="w-[200px] mx-auto" alt='your world logo' />
                <p className="text-md text-center text-gray-500">{langs[lang]['change_password_subtitle']}</p>
                <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                    <Input
                        label={langs[lang]['new_password']}
                        type="password"
                        name="password"
                        placeholder={langs[lang]['password_example']}
                        onChange={handleInputChange}
                        value={form.password}
                    />
                    <Button suspense={+requesting} disabled={requesting}>{requesting ? langs[lang]['loading'] : langs[lang]['recovery']}</Button>
                    <Link to="/" className="text-gray-500 text-center">{langs[lang]['go_back']}</Link>
                </form>
                <SelectLenguage />
            </div>
        </div>
    )
}

export default ChangePasswordPage