import { useDispatch, useSelector } from "react-redux"
import { API_URL } from "../../config"
import { RiCheckFill, RiCloseFill, RiIndeterminateCircleLine } from "react-icons/ri"
import { toast } from "react-hot-toast"
import { acceptChatRequest, cancelChatRequest, rejectChatRequest } from "../../services/chat"
import { useNavigate } from "react-router-dom"
import { blockUser } from "../../services/blockUser"
import { acceptChat, removeChat } from "../../features/chatListSlice"
import { useContext } from "react"
import { SocketContext } from "../SocketProvider"
import langs from "../../lang/langs"

const Chat = ({ chat }) => {
    const { session, token, lang } = useSelector(state => state)
    const { socket } = useContext(SocketContext)
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const handleCancelChat = () => {
        dispatch(removeChat(chat.id))
        cancelChatRequest({ token, userId: chat.chatUserId, socketId: socket.id })
            .then(chat => {
                toast.success(chat.data.message)
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
            })
    }


    const handleAcceptChat = () => {
        dispatch(acceptChat(chat.id))
        acceptChatRequest({ token, userId: chat.userId, socketId: socket.id })
            .then(chat => {
                toast.success(chat.data.message)
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
            })
    }

    const handleBlockUser = () => {
        dispatch(removeChat(chat.id))
        blockUser({ token, userId: chat.userId, socketId: socket.id })
            .then(chat => {
                toast.success(chat.data.message)
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
            })
    }

    const handleRejectChat = () => {
        rejectChatRequest({ token, userId: chat.userId })
            .then(chat => {
                toast.success(chat.data.message)
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
            })
    }

    return (
        <div className="flex gap-5 bg-white rounded border px-5 py-2 items-center cursor-pointer" onClick={chat.accepted ? () => navigate(`/chat/private/${session.user.id === chat.user.id ? chat.chatUser.id : chat.user.id}`) : undefined}>
            {
                chat.user.id === session.user.id ?
                    <img crossOrigin="anonymous" className="w-[40px] h-[40px] rounded-full" src={chat.chatUser.profilePicture ? `${API_URL}/users/${chat.chatUser.id}/profile/picture` : '/pp.png'} alt={chat.chatUser.username} />
                    :
                    <img crossOrigin="anonymous" className="w-[40px] h-[40px] rounded-full" src={chat.user.profilePicture ? `${API_URL}/users/${chat.user.id}/profile/picture` : '/pp.png'} alt={chat.user.username} />
            }
            <div className="flex flex-col gap-1 overflow-hidden">
                <p className="text-gray-500">
                    {chat.user.id === session.user.id ? chat.chatUser.username : chat.user.username}
                </p>
                {chat.userId !== session.user.id && !chat.accepted ?
                    <>
                        <div className="flex gap-2">
                            <button className="flex items-center gap-1 text-[#4da2d9] font-semibold text-xs uppercase" onClick={handleAcceptChat}><RiCheckFill /> {langs[lang]['accept']}</button>
                            <button className="flex items-center gap-1 text-[#4da2d9] font-semibold text-xs uppercase" onClick={handleRejectChat}><RiCloseFill /> {langs[lang]['reject']}</button>
                        </div>
                        <button className="flex items-center gap-1 text-red-500 font-semibold text-xs uppercase" onClick={handleBlockUser}><RiIndeterminateCircleLine /> {langs[lang]['block']}</button>
                    </>
                    : chat.userId === session.user.id && !chat.accepted ?
                        <button className="flex items-center gap-1 text-[#4da2d9] font-semibold text-xs uppercase" onClick={handleCancelChat}><RiCloseFill /> {langs[lang]['cancel_request']}</button>
                        :
                        chat.Messages.length > 0 ? <p className="text-gray-500 truncate">
                            <span className="font-semibold">{chat.Messages[0].User.username}</span> {chat.Messages[0].MessageFiles.length > 0 ? <span className="font-semibold">{langs[lang]['has_sent_a_file']}</span> : chat.Messages[0].text}
                        </p>
                            :
                            <p className="text-gray-500">{langs[lang]['no_messages']}</p>
                }


            </div>
        </div>
    )
}

export default Chat