const Hashtag = ({ hashtag, onDelete = () => { }, onClick = () => { }, allowDelete = false }) => {

    return (
        <span
            className={`bg-blue-50 px-4 py-2 rounded-full text-blue-500 ${allowDelete && 'hover:bg-red-50 hover:text-red-400'} cursor-pointer`}
            onClick={() => {
                if (allowDelete) onDelete()
                else onClick()
            }}
            title={allowDelete ? `Eliminar #${hashtag}` : `#${hashtag}`}
        >
            #{hashtag}
        </span>
    )
}

export default Hashtag