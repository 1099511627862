import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { setProfilePicture } from "../../features/profilePictureSlice"
import { getProfilePicture, updateProfilePicture } from "../../services/user"
import langs from "../../lang/langs"

const ProfilePicture = ({ userId, allowUpdate = null, size = 'md', onClick = () => { }, online = true }) => {
    const { token, profilePicture, lang } = useSelector(state => state)
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    const styles = {
        sm: 'min-w-[20px] min-h-[20px] max-w-[20px] max-h-[20px]',
        md: 'min-w-[75px] min-h-[75px] max-w-[75px] max-h-[75px]',
        lg: 'min-w-[110px] min-h-[110px] max-w-[110px] max-h-[110px]',
    }

    const [inputFile, setInputFile] = useState('')

    const handlePictureSelected = ({ target }) => {
        const { files: [file] } = target
        let reader = new FileReader();
        reader.readAsDataURL(file)
        setInputFile(target.value)
        let result
        reader.onload = function (event) {
            result = event.target.result
        }

        toast.promise(updateProfilePicture({ token, file }), {
            loading: langs[lang]['changing'],
            success: () => {
                dispatch(setProfilePicture(result))
                return langs[lang]['profile_picture_changed']
            },
            error: ({ response }) => response.data.message
        })


    }

    useEffect(() => {
        if (!online) {
            setLoading(false)
        } else {
            getProfilePicture({ token, userId }).then(response => {
                if (response.data.size === 0) {
                    dispatch(setProfilePicture(null))
                    setLoading(false)
                    return
                }
                let reader = new window.FileReader()
                reader.readAsDataURL(response.data)
                reader.onload = function () {
                    const imageDataUrl = reader.result
                    dispatch(setProfilePicture(imageDataUrl))
                    setLoading(false)
                }
            })
        }
    }, [token, userId, dispatch])
    if (loading) {
        return <>{langs[lang]['loading_profile_picture']}</>
    }

    return (
        <div className={`flex relative overflow-hidden items-center justify-center bg-gray-100 ${styles[size]} rounded-full`} onClick={onClick}>
            {allowUpdate && <label className="uppercase absolute bg-[#0000006c] p-2 rounded-full text-white font-semibold text-xs">
                <input type="file" onChange={handlePictureSelected} value={inputFile} hidden />
                {langs[lang]['change']}
            </label>}
            <img crossOrigin="anonymous" className={`${styles[size]} object-cover`} src={profilePicture ? profilePicture : '/pp.png'} alt={`profile ${userId}`} />
        </div>
    )
}

export default ProfilePicture