const RotateOption = ({ optionName, imgSrc, degrees, active = false, onActive = () => { } }) => {
    return (
        <div className={`flex flex-col cursor-pointer bg-gray-50 border-2 ${active ? 'border-blue-500' : 'border-transparent' } rounded-lg overflow-hidden`} onClick={onActive}>
            <img
                style={{
                    height: 100,
                    width: 100,
                    objectFit: 'cover',
                    objectPosition: 'center'
                }}
                className={`${degrees}`}
                src={imgSrc}
                alt="Esto es un thumbnail"
            />
            <p className="text-gray-500 text-center py-2">{optionName}</p>
        </div>
    )
}

export default RotateOption