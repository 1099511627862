import File from "../File"
import { useLiveQuery } from "dexie-react-hooks"
import { db } from "../../db"
import langs from "../../lang/langs"
import { useSelector } from "react-redux"

const DownloadManager = () => {
    const { lang } = useSelector(state => state)

    const files = useLiveQuery(
        async () => {
            const data = await db.files
                .reverse()
                .toArray()
            return data
        }
    )
    return (
        <div className="flex flex-col gap-2 bg-white p-5 rounded-lg">
            <p className="text-gray-500">{langs[lang]['downloads']}</p>
            {files?.length > 0 &&
                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
                    {files?.map(file => <File key={file.id} file={{ ...file, id: file.fileId }} />)}
                </div>
            }
        </div>
    )
}

export default DownloadManager