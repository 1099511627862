import axios from "axios"
import { useRef, useState } from "react"
import { toast } from "react-hot-toast"
import { RiFileFill, RiMicFill, RiMore2Fill, RiVidiconFill } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { API_URL } from "../../config"
import { removeFile } from "../../features/multimediaSlice"
import { deleteFile } from "../../services/file"
import Modal from "../Modal"
import MoveFile from "./MoveFile"
import RenameFile from "./RenameFile"
import { Link } from "react-router-dom"
import FileVisibility from "./FileVisibility"
import DownloadFile from "./DownloadFile"
import { useLiveQuery } from "dexie-react-hooks"
import { db } from "../../db"
import useOnline from "../../hooks/useOnline"
import langs from "../../lang/langs"

const File = ({
    file: { id, name, mimetype, userId, tagId, public: isPublic, hashtags, createdAt, updatedAt },
    showOptions = true,
    onDownloadFinished = () => { }
}) => {
    const online = useOnline()

    const { lang } = useSelector(state => state)

    const multimedia = useRef()
    const [state, setState] = useState({
        loadingFile: false,
        loaded: false,
        source: null,
    })

    const [openMenu, setMenuOpen] = useState(false)
    const [openFileModal, setOpenFileModal] = useState(false)
    const [downloadFileModalOpen, setDownloadFileModalOpen] = useState(false)
    const [moveFileModalOpen, setMoveFileModalOpen] = useState(false)
    const [renameModalOpen, setRenameModalOpen] = useState(false)
    const [visibilityModalOpen, setVisibilityModalOpen] = useState(false)


    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const [downloading, setDownloading] = useState(false)
    const [progressPercentage, setProgressPercentage] = useState(0.0)

    const fileData = useLiveQuery(
        async () => {
            const [data] = await db.files
                .where({ fileId: id })
                .toArray();

            return data;
        },
        [id]
    )
    const type = mimetype.split('/')[0]

    const token = useSelector(({ token }) => token)
    const dispatch = useDispatch()

    const handleOpenFileModal = () => {
        if (fileData) {
            console.log('a')
            const url = URL.createObjectURL(fileData.data)
            if (type === 'image' || type === 'video' || type === 'audio') {
                setOpenFileModal(true)
            } else if (type === 'application' && mimetype.split('/')[1] === 'pdf') {
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('target', '_blank')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } else {
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', name)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
            return
        }

        if (!state.loaded) {
            setState({ ...state, loadingFile: true })
            axios.get(`${API_URL}/files/${id}`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                onDownloadProgress: ({ progress }) => setProgressPercentage(progress * 100)
            })
                .then((response) => {
                    const url = URL.createObjectURL(response.data)
                    onDownloadFinished({ source: url })
                    setState({ ...state, loadingFile: false, loaded: true, source: url })
                    setProgressPercentage(0.0)

                    if (type === 'image' || type === 'video' || type === 'audio') {
                        setOpenFileModal(true)
                    } else if (type === 'application' && mimetype.split('/')[1] === 'pdf') {
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('target', '_blank')
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    } else {
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', name)
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    }
                })
        } else {
            if (type === 'image' || type === 'video' || type === 'audio') {
                setOpenFileModal(true)
            } else if (type === 'application' && mimetype.split('/')[1] === 'pdf') {
                const link = document.createElement('a')
                link.href = state.source
                link.setAttribute('target', '_blank')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } else {
                const link = document.createElement('a')
                link.href = state.source
                link.setAttribute('download', name)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
        }
    }

    const handleCloseFileModal = () => {
        if (type === 'video' || type === 'audio') {
            multimedia.current.pause()
        }
        setOpenFileModal(false)
    }

    const openMoveFileModal = () => {
        setMenuOpen(false)
        setMoveFileModalOpen(true)
    }
    const openRenameModal = () => {
        setMenuOpen(false)
        setRenameModalOpen(true)
    }

    const handleConfirmation = () => {
        setMenuOpen(false)
        setDeleteConfirmation(true)
    }

    const openVisibilityModal = () => {
        setMenuOpen(false)
        setVisibilityModalOpen(true)
    }

    const openDownloadModal = () => {
        setMenuOpen(false)
        setDownloadFileModalOpen(true)
    }

    const handleDeleteFile = () => {
        deleteFile({ fileId: id, token })
            .then(() => {
                toast.success("Archivo eliminado.")
                dispatch(removeFile(id))
            })
            .catch(({ response }) => toast.error(response.data.message))
    }

    return (
        <div className={`flex flex-col items-center border ${!fileData ? 'border-gray-200' : 'border-green-500'} rounded-lg overflow-hidden`}>
            <div className="flex items-center justify-center w-full h-[120px] bg-gray-200 cursor-pointer" onClick={handleOpenFileModal}>
                {
                    state.loadingFile ?
                        <p className="text-center text-gray-800 font-semibold">{langs[lang]['loading']} <br /> {progressPercentage.toFixed(2)}%</p>
                        :
                        <>
                            {
                                type === 'image' ?
                                    <>
                                        {/* <RiImageFill className="text-[40px] text-gray-700" /> */}
                                        {!fileData && <img crossOrigin="anonymous" className="max-w-full max-h-full object-cover object-center" src={`${API_URL}/files/${id}/preview`} alt={name} />}
                                        {fileData && <img className="max-w-full max-h-full object-cover object-center" src={URL.createObjectURL(fileData.data)} alt={name} />}
                                    </>
                                    : type === 'video' ? <RiVidiconFill className="text-[40px] text-gray-700" />
                                        : type === 'audio' ? <RiMicFill className="text-[40px] text-gray-700" />
                                            : <RiFileFill className="text-[40px] text-gray-700" />
                            }
                        </>
                }
            </div>
            <Modal modalOpen={openFileModal} set={setOpenFileModal} onClose={handleCloseFileModal}>
                <div className="flex justify-center items-center relative w-full h-full max-h-full">
                    <div className="flex justify-between p-5 bg-[#00000070] absolute w-full top-0">
                        <p className="text-white">{name}</p>
                        <button className="text-red-300 font-semibold uppercase text-sm z-50" onClick={handleCloseFileModal}>Cerrar</button>
                    </div>
                    {
                        type === 'image' ?
                            <>
                                {!fileData && <img crossOrigin="anonymous" className="max-h-full" src={state.source} alt={name} />}
                                {fileData && <img className="max-h-full" src={URL.createObjectURL(fileData.data)} alt={name} />}
                            </>
                            : type === 'video' ?
                                <>
                                    {!fileData && <video crossOrigin="anonymous" className="max-h-full" ref={multimedia} src={state.source} controls></video>}
                                    {fileData && <video className="max-h-full" ref={multimedia} src={URL.createObjectURL(fileData.data)} controls></video>}
                                </>

                                :
                                <>
                                    {!fileData && <audio crossOrigin="anonymous" className="max-h-full" ref={multimedia} src={state.source} controls></audio>}
                                    {fileData && <audio className="max-h-full" ref={multimedia} src={URL.createObjectURL(fileData.data)} controls></audio>}
                                </>
                    }

                </div>
            </Modal>
            <div className="flex w-full justify-between">
                <div className="flex items-center p-2 gap-2 overflow-hidden">
                    <p className={`${!fileData ? 'text-gray-500' : 'text-green-500'} truncate`}>{name}</p>
                </div>
                {showOptions && <button className="text-gray-400 text-xl" onClick={() => setMenuOpen(!deleteConfirmation)}><RiMore2Fill /></button>}


                <Modal modalOpen={openMenu} set={setMenuOpen}>
                    <div className="flex flex-col bg-white rounded w-[310px] overflow-hidden">
                        <p className="text-center px-5 py-2 font-semibold">{name}</p>
                        {online && type === 'image' && <Link to={`/images/${id}/edit`} className="px-5 py-3 text-center text-gray-500 uppercase text-sm hover:bg-gray-100">{langs[lang]['edit']}</Link>}
                        {online && type === 'video' && <Link to={`/videos/${id}/edit`} className="px-5 py-3 text-center text-gray-500 uppercase text-sm hover:bg-gray-100">{langs[lang]['edit']}</Link>}
                        <button className="px-5 py-3 text-center text-gray-500 uppercase text-sm hover:bg-gray-100" onClick={openDownloadModal}>{langs[lang]['download']} {downloading && <span>{progressPercentage.toFixed(2)}%</span>}</button>
                        {online && <button className="px-5 py-3 text-gray-500 uppercase text-sm hover:bg-gray-100" onClick={openMoveFileModal}>{langs[lang]['move']}</button>}
                        {online && <button className="px-5 py-3 text-gray-500 uppercase text-sm hover:bg-gray-100" onClick={openRenameModal}>{langs[lang]['rename']}</button>}
                        {online && <button className="px-5 py-3 text-gray-500 uppercase text-sm hover:bg-gray-100" onClick={openVisibilityModal}>{langs[lang]['visibility']}</button>}
                        {online && <button className="px-5 py-3 text-red-500 uppercase text-sm hover:bg-gray-100" onClick={handleConfirmation}>{langs[lang]['delete']}</button>}
                    </div>
                </Modal>


                <Modal modalOpen={deleteConfirmation} set={setDeleteConfirmation}>
                    <div className="flex flex-col bg-white rounded w-[310px] gap-2 overflow-hidden">
                        <p className="text-center px-5 py-2 font-semibold">{name}</p>
                        <p className="text-center">{langs[lang]['file_warn_delete']}</p>
                        <button className="px-5 py-3 text-red-500 uppercase text-sm hover:bg-gray-100" onClick={handleDeleteFile}>{langs[lang]['delete']}</button>
                    </div>
                </Modal>

                <Modal modalOpen={downloadFileModalOpen} set={setDownloadFileModalOpen}>
                    <DownloadFile file={{ id, name, mimetype, userId, tagId, isPublic, hashtags, createdAt, updatedAt }} source={state.source} moveFileModalOpen={downloadFileModalOpen} />
                </Modal>


                <Modal modalOpen={moveFileModalOpen} set={setMoveFileModalOpen}>
                    <MoveFile file={{ id, name, tagId }} moveFileModalOpen={moveFileModalOpen} />
                </Modal>

                <Modal modalOpen={renameModalOpen} set={setRenameModalOpen}>
                    <RenameFile file={{ id, name, tagId }} setRenameModalOpen={setRenameModalOpen} />
                </Modal>

                <Modal modalOpen={visibilityModalOpen} set={setVisibilityModalOpen}>
                    <FileVisibility file={{ id, name, isPublic, hashtags }} />
                </Modal>

            </div>
        </div>
    )
}

export default File