import { useNavigate } from "react-router-dom"
import langs from "../../lang/langs"
import { useSelector } from "react-redux"

const Room = ({ member }) => {
    const { lang } = useSelector(state => state)
    const navigate = useNavigate()

    return (
        <div className="flex gap-5 bg-white rounded border px-5 py-2 items-center cursor-pointer" onClick={() => navigate(`/chat/rooms/${member.Room.id}`)}>
            <img crossOrigin="anonymous" className="w-[40px] h-[40px] rounded-full" src={'/group.png'} alt={member.Room.name} />
            <div className="flex flex-col gap-1 overflow-hidden">
                <p className="text-gray-500">
                    {member.Room.name}
                </p>
                {member.Room.Messages.length > 0 ? <p className="text-gray-500 truncate">
                    <span className="font-semibold">{member.Room.Messages[0].User.username}</span> {member.Room.Messages[0].MessageFiles.length > 0 ? <span className="font-semibold">{langs[lang]['has_sent_a_file']}</span> : member.Room.Messages[0].text}
                </p>
                    :
                    <p className="text-gray-500">{langs[lang]['no_messages']}</p>
                }
            </div>
        </div>
    )
}

export default Room