import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const groupListSlice = createSlice({
    name: 'groupList',
    initialState,
    reducers: {
        setGroups: (state, { payload: groups }) => {
            return groups
        },
        addGroup: (state, { payload: newGroup }) => {
            let newList = state.filter(group => group.id !== newGroup.id)
            newList.unshift(newGroup)
            return newList
        },
        updateLastMessageGroup: (state, { payload: { message, memberId } }) => {
            const memberSearch = state.find(member => member.id === memberId)
            memberSearch.Group.Messages = [message]
        },
        updatePositionList: (state, { payload: { memberId } }) => {
            const memberSearch = state.find(member => member.id === memberId)
            let newList = state.filter(group => group.id !== memberId)
            newList.unshift(memberSearch)
            return newList
        },
        removeGroup: (state, { payload: groupId }) => {
            return state.filter(group => group.id !== groupId)
        },
        reset: () => initialState
    }

})
export const {
    setGroups,
    addGroup,
    removeGroup,
    updateLastMessageGroup,
    updatePositionList,
    reset
} = groupListSlice.actions
export default groupListSlice.reducer