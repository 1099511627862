const Text = ({
    color = 'text-gray-500',
    size = 'text-md',
    align = 'text-left',
    truncate = false,
    children
}) => {
    return <p className={`${color} ${size} ${align} ${truncate && 'truncate'}`}>{children}</p>
}

export default Text