import { useState } from "react"
import Hashtag from "../Hashtag"
import { validateAllowedChars } from "../../utils/strings"
import { RiLinksLine } from "react-icons/ri"
import { PAGE_URL } from "../../config"
import { toast } from "react-hot-toast"
import { addHashtagToFile, removeHashtagToFile, updateFileVisibility } from "../../services/file"
import { useSelector } from "react-redux"
import langs from "../../lang/langs"

const FileVisibility = ({ file }) => {
    const [isPublic, setIsPublic] = useState(file.isPublic)
    const [hashtag, setHashtag] = useState("")
    const [hashtags, setHashtags] = useState(!file.hashtags || file.hashtags.length === 0 ? [] : file.hashtags.split(','))

    const { token, lang } = useSelector(state => state)

    const handleChangVisibility = (event) => {
        setIsPublic(event.target.checked)
        updateFileVisibility({ token, fileId: file.id, isPublic: event.target.checked })
    }

    const handleHashtagChange = (event) => {
        if (event.target.value.trim().replace(" ", "").length === 0 || validateAllowedChars(event.target.value)) {
            setHashtag(event.target.value)
        }
    }
    const handleAddHashtag = (event) => {
        event.preventDefault()
        if (hashtag.trim().length === 0) return
        const search = hashtags.find(htag => hashtag === htag)
        if (search) {
            toast.error('Ya has agregado ese hashtag')
            return
        }
        addHashtagToFile({ token, fileId: file.id, name: hashtag })

        let newList = [...hashtags]
        newList.push(hashtag)
        setHashtags(newList)
        setHashtag("")
    }
    const handleDeleteHashtags = (hashtag) => {
        const newList = hashtags.filter(text => hashtag !== text)
        setHashtags(newList)
        removeHashtagToFile({ token, fileId: file.id, name: hashtag })
    }
    const handleCopySharedLink = () => {
        navigator.clipboard.writeText(`${PAGE_URL}/download/file/${file.id}`).then(() => toast.success('¡Enlace copiado!'))
    }
    return (
        <div className="flex flex-col bg-white rounded w-[310px] overflow-hidden">
            <div className="flex flex-col gap-2 px-5 py-5 overflow-hidden">
                <p className="text-gray-500 text-center">{langs[lang]['file_visibility']}</p>
                <p className="text-gray-500 text-center truncate">{file.name}</p>
                <div className="flex gap-2 items-center justify-between bg-gray-50 px-5 py-2 rounded">
                    <label
                        className="text-gray-500 inline-block pl-[0.15rem] hover:cursor-pointer"
                        htmlFor="flexSwitchCheckDefault"
                    >{langs[lang]['visible_for_everyone']}</label>
                    <input
                        id="flexSwitchCheckDefault"
                        className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-blue-500 checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:bg-blue-200"
                        role="switch"
                        type="checkbox"
                        onChange={handleChangVisibility}
                        value={isPublic}
                        checked={isPublic}
                    />
                </div>
                {isPublic &&
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-col gap-2 bg-gray-50 rounded px-4 py-2">
                            <p className="text-gray-500">{langs[lang]['shared_link']}</p>
                            <div className="flex gap-1">
                                <div className="flex gap-1 items-center bg-blue-100 text-blue-500 hover:bg-blue-500 hover:text-white px-4 py-2 rounded overflow-hidden cursor-pointer" onClick={handleCopySharedLink}>
                                    <RiLinksLine className="text-blue-400" />
                                    <p className="font-semibold text-xs truncate">
                                        {PAGE_URL}/download/file/{file.id}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 bg-gray-50 rounded px-4 py-2">
                            <p className="text-gray-500">{langs[lang]['hashtags']}</p>
                            <div className="flex flex-wrap items-center gap-2">
                                {hashtags.map((hashtag, index) => <Hashtag key={index} hashtag={hashtag} allowDelete onDelete={() => handleDeleteHashtags(hashtag)} />)}
                            </div>
                            <form onSubmit={handleAddHashtag}>
                                <input
                                    className="outline-none bg-gray-50"
                                    type="text"
                                    name="hashtag"
                                    onChange={handleHashtagChange}
                                    placeholder={langs[lang]['enter_to_add']}
                                    value={hashtag}
                                />
                            </form>
                            <p className="text-xs text-gray-500">{langs[lang]['hashtags_helps_to_search_your_file']}</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default FileVisibility