const OptionButton = ({
    optionName,
    active = false,
    icon = <></>,
    onActive = () => { }
}) => {
    return (
        <button
            className={`flex flex-col gap-1 justify-center items-center ${active ? "bg-blue-50 text-blue-600" : "bg-gray-50 text-gray-500"
                } rounded px-4 py-2 uppercase text-xs`}
            onClick={onActive}
        >
            {icon}
            {optionName}
        </button>
    )
}

export default OptionButton