import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getIncomingCalls } from "../../services/call"
import { addIncomingCall, deleteIncomingCall, setIncomingCalls } from "../../features/incomingCallList"
import { toast } from "react-hot-toast"
import { SocketContext, useAddSocketEvent } from "../SocketProvider"
import { useContext } from "react"
import IncomingCall from "./IncomingCall"

const IncomingCallList = () => {
    const { token, incomingCallList } = useSelector(state => state)
    const { socket } = useContext(SocketContext)
    const dispatch = useDispatch()

    useAddSocketEvent('call:closed', (callId) => {
        dispatch(deleteIncomingCall(callId))
    })

    useAddSocketEvent('call:accept:in_another_window', (callId) => {
        dispatch(deleteIncomingCall(callId))
    })

    useAddSocketEvent('call:reject:in_another_window', (callId) => {
        dispatch(deleteIncomingCall(callId))
    })

    useAddSocketEvent('call:incoming', (call) => {
        socket.emit('call:join:room', { callId: call.Call.id })
        dispatch(addIncomingCall(call))
    })


    useEffect(() => {
        getIncomingCalls({ token }).then((res) => {
            res.data.payload.calls.forEach((call) => {
                socket.emit('call:join:room', { callId: call.Call.id })
            })
            dispatch(setIncomingCalls(res.data.payload.calls))
        }).catch(({ response }) => {
            toast.error(response.data)
        })
    }, [])

    if (incomingCallList.length === 0) return <></>

    return (
        <div className="flex flex-col items-center left-0 right-0 px-5 pb-5 gap-5 fixed z-50 bottom-0 w-full">
            {
                incomingCallList.map((call) => <IncomingCall key={call.id} call={call} />)
            }
        </div>
    )
}

export default IncomingCallList