import { useCallback, useContext, useEffect, useRef, useState } from "react"
import ReverseInfiniteScroll from "../../../components/ReverseInfiniteScroll"
import Message from "../../../components/Message"
import { useDispatch, useSelector } from "react-redux"
import { editGroupMessage, getGroupMessages } from "../../../services/message"
import { addNewMessageBlock, setMessages } from "../../../features/groupSlice"
import { SocketContext } from "../../../components/SocketProvider"
import langs from "../../../lang/langs"

const MessageList = () => {
    const [page, setPage] = useState(0)
    const [loadedInitData, setLoadedInitData] = useState(false)
    const [state, setState] = useState({
        page: null,
        endPagination: false,
        loadedMore: 0,
        distanseToBottom: 0,
        lastHeightScroll: 0
    })
    const refScrollElement = useRef()
    const prevHeight = useRef()

    const { socket } = useContext(SocketContext)
    const { groupChat, token, lang } = useSelector(state => state)
    const dispatch = useDispatch()

    const getMoreMessages = useCallback(() => {
        getGroupMessages({ token, groupId: groupChat.id, page: page }).then((res) => {
            dispatch(addNewMessageBlock(res.data.payload.messages))
            setPage(page - 1)
        })
    }, [page])

    const handleEditGroupMessage = ({ messageId, text }) => {
        editGroupMessage({ token, messageId, text, socketId: socket.id })
    }

    useEffect(() => {
        if (state.loadedMore) {
            refScrollElement.current.scrollTo(
                0,
                refScrollElement.current.scrollHeight - prevHeight.current
            )
            setState({
                ...state,
                loadedMore: false
            })
        } else {
            const distanceToBottom =
                prevHeight.current -
                refScrollElement.current.clientHeight -
                Math.floor(refScrollElement.current.scrollTop)

            if (distanceToBottom <= 5) {
                refScrollElement.current.scrollTo(
                    0,
                    refScrollElement.current.scrollHeight
                )
            }

            setState({
                ...state,
                lastHeightScroll: refScrollElement.current.scrollHeight
            })
            prevHeight.current = refScrollElement.current.scrollHeight
        }
    }, [groupChat.messages])

    useEffect(() => {
        refScrollElement.current.scrollTo(0, refScrollElement.current.scrollHeight)
        prevHeight.current = refScrollElement.current.scrollHeight
    }, [loadedInitData])


    useEffect(() => {
        getGroupMessages({ token, groupId: groupChat.id }).then((res) => {
            dispatch(setMessages(res.data.payload.messages))
            setPage(res.data.payload.totalPages - 1)
            setLoadedInitData(true)
        })
    }, [])

    return (
        <div className="flex-auto relative max-h-full">
            <div
                className="absolute left-0 top-0 w-full h-full overflow-y-auto overflow-x-hidden bg-gray-100"
                ref={refScrollElement}
            >
                {
                    loadedInitData && <ReverseInfiniteScroll
                        hasMore={page >= 0}
                        onLoadMore={() => {
                            setState({ ...state, loadedMore: true })
                            prevHeight.current = refScrollElement.current.scrollHeight
                        }}
                        fetchMore={getMoreMessages}
                        endMessage={
                            <p className="text-center font-semibold text-gray-500 my-5">
                                {langs[lang]['no_messages']}
                            </p>
                        }
                    >
                        <div className="flex flex-col p-5 gap-2">
                            {
                                groupChat.messages.map((message) => (
                                    <Message key={message.id} message={message} editMessageBehavior={handleEditGroupMessage} showUsername />
                                ))
                            }
                        </div>
                    </ReverseInfiniteScroll>
                }
            </div>
        </div>
    )
}
export default MessageList
