import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: 0,
    name: '',
    user: null,
    members: [],


    loading: true,
    messages: [],
    uploadProgress: {}
}

export const roomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        setRoom: (state, { payload: room }) => {
            state.id = room.id
            state.name = room.name
            state.user = room.User
            state.members = room.RoomMembers
        },

        setMessages: (state, { payload: messages }) => {
            state.messages = messages
        },

        addNewMessageBlock: (state, { payload: newMessages }) => {
            const newList = newMessages.concat(state.messages)
            state.messages = newList
        },

        addMessage: (state, { payload: newMessage }) => {
            const find = state.messages.find(message => message.id === newMessage.id)
            if (!find) {
                state.messages.push(newMessage)
            }
        },

        editMessage: (state, { payload: { id, text } }) => {
            const newMessages = state.messages.map(message => {
                if (message.id === id) {
                    message.text = text
                    message.updated = true
                }
                return message
            })
            state.messages = newMessages
        },
        deleteMessage: (state, { payload: messageId }) => {
            const newMessages = state.messages.filter(message => message.id !== messageId)
            state.messages = newMessages
        },
        addUploadProgress: (state, { payload: { id } }) => {
            state.uploadProgress[id] = 0
        },
        updateUploadProgress: (state, { payload: { id, progress } }) => {
            state.uploadProgress[id] = progress
        },
        resolveMessage: (state, { payload: { id, newId, files } }) => {
            const newMessages = state.messages.map(message => {
                if (message.id === id) {
                    message.id = newId
                    message.MessageFiles = files
                }
                return message
            })
            state.messages = newMessages
        },
        setLoading: (state, { payload }) => {
            state.loading = payload
        },
        removeRoomMember: (state, { payload: memberId }) => {
            const newMembers = state.members.filter(member => member.id !== memberId)
            state.members = newMembers
        },
        addMember: (state, { payload: member }) => {
            state.members.push(member)
        },
        resetRoom: () => initialState
    }

})
export const {
    setRoom,
    setMessages,
    addNewMessageBlock,
    addMessage,
    addUploadProgress,
    updateUploadProgress,
    editMessage,
    deleteMessage,
    setLoading,
    resetRoom,
    resolveMessage,
    removeRoomMember,
    addMember
} = roomSlice.actions
export default roomSlice.reducer