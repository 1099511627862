import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: null,
    userId: null,
    token: null,
    userAgent: '',
    authorized: false,
    user: {},
    noAds: false
}
export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setSessionData: (state, { payload }) => {
            state.id = payload.id
            state.userId = payload.userId
            state.userAgent = payload.userAgent
            state.authorized = payload.authorized
            state.user = payload.user
        },
        setVerified: (state) => {
            state.user.emailVerified = true
        },
        setNoAds: (state) => {
            state.noAds = !state.noAds
        },
        reset: () => initialState
    }

})
export const { setSessionData, setVerified, setNoAds, reset } = sessionSlice.actions
export default sessionSlice.reducer