import platform from "platform"
import { toast } from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { authorize, deleteSession } from "../../features/sessionsSlice"
import { deleteToken } from "../../features/tokenSlice"
import { authorizeSession, deleteSession as deleteSessionService } from "../../services/session"
import langs from "../../lang/langs"

const Session = ({ session }) => {
    const { token, lang } = useSelector((state) => state)
    const dispatch = useDispatch()
    const {
        product: deviceName,
        name: browser,
        os: {
            family: osName,
            version
        }
    } = platform.parse(session.userAgent)

    const handleAuthorize = () => {
        authorizeSession({ token, sessionId: session.id })
            .then(() => {
                toast.success('Sesión autorizada.')
            })
            .catch(({ response }) => toast.error(response.data.error))
        dispatch(authorize(session.id))
    }

    const handleDelete = () => {
        deleteSessionService({ token, sessionId: session.id })
            .then(() => {
                if (token === session.token) {
                    dispatch(deleteToken())
                }
                dispatch(deleteSession(session.id))
                toast.success('Sesión eliminada.')
            })
            .catch(({ response }) => toast.error(response.data.message))
    }
    return (
        <div className="flex flex-col gap-5 bg-white p-5 rounded">
            <p className="text-gray-500 uppercase">{langs[lang]['session']}</p>
            <div className="flex flex-col">
                {
                    deviceName &&
                    <div className="flex justify-between">
                        <p className="text-gray-500">{langs[lang]['device']}</p>
                        <p className="text-gray-500 font-semibold">{deviceName}</p>
                    </div>
                }
                <div className="flex justify-between">
                    <p className="text-gray-500">{langs[lang]['system_operative']}</p>
                    <p className="text-gray-500 font-semibold">{osName} {version}</p>
                </div>
                <div className="flex justify-between">
                    <p className="text-gray-500">{langs[lang]['navigator']}</p>
                    <p className="text-gray-500 font-semibold">{browser}</p>
                </div>
            </div>
            <div className="flex gap-5 justify-between">
                <button className="bg-gray-100 px-5 py-2 rounded uppercase text-xs font-semibold text-gray-500" onClick={handleAuthorize}>{session.authorized ? langs[lang]['deauthorize'] : langs[lang]['authorize']}</button>
                <button className="bg-gray-100 px-5 py-2 rounded uppercase text-xs font-semibold text-gray-500" onClick={handleDelete}>{langs[lang]['delete']}</button>
            </div>
        </div>
    )
}

export default Session