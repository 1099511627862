import { useEffect, useRef } from "react"
import { API_URL } from "../../config"
import { useSelector } from "react-redux"
import langs from "../../lang/langs"

const CallMember = ({ member }) => {
    const { session, call, lang } = useSelector(state => state)
    const videoStream = useRef()

    const handleVideoLoadedMetadata = () => {
        videoStream.current.play()
    }

    useEffect(() => {
        if (videoStream.current) {
            videoStream.current.srcObject = member.mediaStream
        }
    }, [member.mediaStream])


    return (
        <div className={`flex ${call.members.length > 2 ? 'w-auto' : 'w-full sm:w-[330px] mx-auto'} h-[230px] rounded overflow-hidden relative`}>
            <div className={`flex flex-col gap-5 absolute z-10 ${(member.media.video && member.mediaStream) ? 'left-3 top-3' : 'w-full h-full justify-center items-center'}`}>
                <img
                    crossOrigin="anonymous"
                    className={`${member.media.video && member.mediaStream ? 'w-[40px] h-[40px]' : 'w-[100px] h-[100px]'} rounded-full`}
                    src={member.User.profilePicture ? `${API_URL}/users/${member.User.id}/profile/picture` : '/pp.png'}
                    alt={member.userId}
                />
                {!member.mediaStream && <p className="text-white text-center text-xs">{langs[lang]['waiting_stream']}</p>}
            </div>


            {member.mediaStream &&
                <video
                    ref={videoStream}
                    className="absolute w-full"
                    onLoadedMetadata={handleVideoLoadedMetadata}
                    muted={(session.userId === member.userId || !member.media.audio)}
                    hidden={!member.media.video}
                ></video>
            }
        </div>
    )
}

export default CallMember