import { useState } from "react"
import { RiMore2Fill, RiPriceTag3Fill } from "react-icons/ri"
import { Link } from "react-router-dom"
import Modal from "../Modal"
import DeleteTag from "./DeleteTag"
import MoveTag from "./MoveTag"
import RenameTag from "./RenameTag"
import langs from "../../lang/langs"
import { useSelector } from "react-redux"

const Tag = ({ tag: { id, name, tagId } }) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
    const [moveTagModalOpen, setMoveTagModalOpen] = useState(false)
    const [renameModalOpen, setRenameModalOpen] = useState(false)
    const {lang} = useSelector(state => state)

    const showDeleteConfirmation = () => {
        setMenuOpen(false)
        setConfirmDeleteOpen(true)
    }

    const openMoveTagModal = () => {
        setMenuOpen(false)
        setMoveTagModalOpen(true)
    }

    const openRenameModal = () => {
        setMenuOpen(false)
        setRenameModalOpen(true)
    }

    return (
        <div className="flex relative items-center gap-2 justify-between border border-gray-200 px-5 py-2 rounded-lg">
            <div className="flex items-center gap-2 overflow-hidden">
                <RiPriceTag3Fill className="text-xl text-gray-500" />
                <Link to={`/tags/${id}`} className="text-gray-500 truncate">{name}</Link>
            </div>
            <button className="text-gray-400 text-xl" onClick={() => setMenuOpen(!menuOpen)}><RiMore2Fill /></button>

            <Modal modalOpen={menuOpen} set={setMenuOpen}>
                <div className="flex flex-col bg-white rounded w-[310px] overflow-hidden">
                    <p className="text-center px-5 py-2 font-semibold">{name}</p>
                    <button className="px-5 py-3 text-gray-500 uppercase text-sm hover:bg-gray-100" onClick={openMoveTagModal}>{langs[lang]['move']}</button>
                    <button className="px-5 py-3 text-gray-500 uppercase text-sm hover:bg-gray-100" onClick={openRenameModal}>{langs[lang]['rename']}</button>
                    <button className="px-5 py-3 text-red-500 uppercase text-sm hover:bg-gray-100" onClick={showDeleteConfirmation}>{langs[lang]['delete']}</button>
                </div>
            </Modal>

            <Modal modalOpen={confirmDeleteOpen} set={setConfirmDeleteOpen}>
                <DeleteTag tag={{ id, name }} />
            </Modal>

            <Modal modalOpen={moveTagModalOpen} set={setMoveTagModalOpen}>
                <MoveTag tag={{ id, name, tagId }} moveTagModalOpen={moveTagModalOpen} />
            </Modal>

            <Modal modalOpen={renameModalOpen} set={setRenameModalOpen}>
                <RenameTag tag={{ id, name }} setRenameModalOpen={setRenameModalOpen} />
            </Modal>
        </div>
    )
}

export default Tag