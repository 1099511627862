import axios from "axios";
import { API_URL } from "../config";

export const getChat = ({ token, userId, socketId }) => axios.get(`${API_URL}/chats/${userId}`, {
    headers: {
        'socketId': socketId,
        Authorization: `Bearer ${token}`
    },
})

export const getChats = ({ token }) => axios.get(`${API_URL}/chats`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const sendChatRequest = ({ token, userId, sessionId, socketId }) => axios.post(`${API_URL}/chats/request`, { userId, sessionId, socketId }, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const rejectChatRequest = ({ token, userId, socketId }) => axios.post(`${API_URL}/chats/request/reject`, { userId, socketId }, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const cancelChatRequest = ({ token, userId, socketId }) => axios.post(`${API_URL}/chats/request/cancel`, { userId, socketId }, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const acceptChatRequest = ({ token, userId, socketId }) => axios.post(`${API_URL}/chats/request/accept`, { userId, socketId }, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})