import axios from "axios";
import { API_URL } from "../config";

export const checkAvailability = ({ token, file }) => axios.post(`${API_URL}/files/check-availability`,
    { file },
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
)

export const getFileData = ({ token, fileId }) => axios.get(`${API_URL}/files/${fileId}/data`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
export const getPublicFileData = ({ token, fileId }) => axios.get(`${API_URL}/files/${fileId}/data/public`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const searchFileByHashtags = ({ token, hashtags }) => axios.post(`${API_URL}/files/hashtags/search`,
    { hashtags },
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
)

export const uploadFile = ({ token, fileName, file, tagId, callback }) => axios.post(`${API_URL}/files/${tagId || ''}`,
    { files: file, fileName },
    {
        onUploadProgress: (event) => callback(event),
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    }
)

export const uploadImageEdited = ({ token, file, conserve, fileId, callback }) => axios.post(`${API_URL}/files/${fileId}/edited`,
    { files: file, conserve },
    {
        onUploadProgress: (event) => callback(event),
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    }
)

export const addHashtagToFile = ({ token, fileId, name }) => axios.post(`${API_URL}/files/${fileId}/hashtags`,
    { name },
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
)
export const removeHashtagToFile = ({ token, fileId, name }) => axios.post(`${API_URL}/files/${fileId}/hashtags/delete`,
    { name },
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
)

export const updateFileVisibility = ({ token, fileId, isPublic }) => axios.patch(`${API_URL}/files/${fileId}/visibility`, { isPublic }, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const renameFile = ({ fileName, fileId, token }) => axios.patch(`${API_URL}/files/${fileId}/name`, { name: fileName }, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})


export const moveFile = ({ fileId, destTagId, token }) => axios.post(`${API_URL}/files/${fileId}/move/${destTagId || ''}`, {}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const deleteFile = ({ fileId, token }) => axios.delete(`${API_URL}/files/${fileId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})