import { useSelector } from "react-redux"
import GroupMember from "../../../components/GroupMember"
import AddMember from "./AddMember"
import { useState } from "react"
import LeaveGroupButton from "../../../components/LeaveGroupButton"
import langs from "../../../lang/langs"

const MembersList = () => {
    const { groupChat, session, lang } = useSelector(state => state)
    const [showAddMember, setShowAddMember] = useState(false)
    return (
        <div className="w-[320px] flex flex-col gap-2 bg-white rounded p-5">
            <p className="text-gray-500 font-semibold">{langs[lang]['members']}</p>

            {session.user.id === groupChat.user.id &&
                <button className={`${showAddMember ? 'text-red-500' : 'text-[#4da2d9]'} self-start`} onClick={() => setShowAddMember(!showAddMember)}>
                    {showAddMember ? langs[lang]['cancel'] : langs[lang]['add_user']}
                </button>
            }
            {showAddMember && session.user.id === groupChat.user.id ?
                <AddMember />
                :
                groupChat.members.map(member => <GroupMember key={member.id} member={member} />)
            }

            <LeaveGroupButton />
        </div>
    )
}

export default MembersList