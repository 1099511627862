import { useState } from "react"
import { toast } from "react-hot-toast"
import Button from "../../components/Button"
import Input from "../../components/Input"
import { verifyUser } from "../../services/user.js"
import langs from "../../lang/langs"
import { useSelector } from "react-redux"

const VerificationForm = ({ userId, callback }) => {
    const [requesting, setRequesting] = useState(false)
    const [form, setForm] = useState({
        userId,
        code: ''
    })
    const { lang } = useSelector(state => state)

    const handleInputChange = ({ target: { name, value } }) => setForm({ ...form, [name]: value })

    const handleSubmit = event => {
        event.preventDefault()
        setRequesting(true)
        verifyUser({ data: form })
            .then((response) => {
                toast.success(response.data.message)
                setRequesting(false)
                callback()
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setRequesting(false)
            })
    }
    return (
        <div className="flex flex-col w-[400px] gap-5 bg-white border border-1 border-gray-100 px-5 py-10 rounded">
            <img crossOrigin="anonymous" src="/logo-app.png" className="w-[200px] mx-auto" alt='your world logo' />
            <p className="text-md text-center text-gray-500">{langs[lang]['verification_code_instruction']}</p>
            <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                <Input
                    label={langs[lang]['verification_code']}
                    type="number"
                    name="code"
                    placeholder={langs[lang]['verification_code_example']}
                    onChange={handleInputChange}
                    value={form.verificationCode}
                />
                <Button disabled={requesting}>{requesting ? langs[lang]['loading'] : langs[lang]['verify']}</Button>
            </form>
        </div>
    )
}

export default VerificationForm