import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { RiArrowRightSLine, RiCloseFill } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../../components/Button"
import { contentCategoryEnums } from "../../../enums"
import { reset } from "../../../features/multimediaSlice"
import { checkAvailability, uploadFile } from "../../../services/file"
import { getTag } from "../../../services/tag"
import Loader from "../../../components/Loader"
import PhotoEditorPro from "../../../components/PhotoEditorPro"
import VideoEditor from "../../../components/VideoEditor"
import Text from "../../../components/Text"
import langs from "../../../lang/langs"



function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
function toBlob(canvas) {
    return new Promise((resolve) => {
        canvas.toBlob(resolve)
    })
}
const UploadFile = () => {
    const [loading, setLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [progressPercentage, setProgressPercentage] = useState(0.0)
    const { token, lang } = useSelector(state => state)
    const [input, setInput] = useState('')
    const [file, setFile] = useState(null)
    const [contentCategoryName, setContentCategoryName] = useState(null)
    const [tags, setTags] = useState([])
    const [pathTag, setPathTag] = useState([])
    const [uploadAvailable, setUploadAvailable] = useState(false)

    const [originalImg, setOriginalImg] = useState(null)
    const [image, setImage] = useState(null)
    const [showImageEditor, setShowImageEditor] = useState(false)
    const [showImagePreview, setShowImagePreview] = useState(false)


    const [originalVideo, setOriginalVideo] = useState(null)
    const [video, setVideo] = useState(null)
    const [showVideoEditor, setShowVideoEditor] = useState(false)
    const [showVideoPreview, setShowVideoPreview] = useState(false)

    const dispatch = useDispatch()

    const handleChange = ({ target }) => {
        const { files: [file] } = target
        setPathTag([])
        setInput(target.value)
        setFile(file)
        if (!file) {
            setContentCategoryName(null)
            setTags([])
            setLoading(false)
            setUploadAvailable(false)
            return
        }
        setLoading(true)
        checkAvailability({ token, file: file.name }).then((contentCategory) => {
            if (contentCategory.data.payload.contentCategoryName === 'image_category') {
                setOriginalImg({ url: URL.createObjectURL(file), blob: file })
                setImage({ url: URL.createObjectURL(file), blob: file })
            }

            if (contentCategory.data.payload.contentCategoryName === 'video_category') {
                const videoUrl = URL.createObjectURL(file)
                setOriginalVideo({ url: videoUrl, blob: file })
                setVideo({ url: videoUrl, blob: file })
            }

            setContentCategoryName(contentCategory.data.payload.contentCategoryName)
            setTags(contentCategory.data.payload.tags)
            setLoading(false)
            setUploadAvailable(true)
        }).catch(({ response }) => {
            setContentCategoryName(null)
            setTags([])
            setLoading(false)
            setUploadAvailable(false)

            toast.error(response.data.message)
        })
    }

    const deletePathTag = () => {
        setPathTag([])
        setUploadAvailable(false)
        checkAvailability({ token, file: file.name })
            .then((contentCategory) => {
                setContentCategoryName(contentCategory.data.payload.contentCategoryName)
                setTags(contentCategory.data.payload.tags)
                setLoading(false)
                setUploadAvailable(true)
            })
            .catch(({ response }) => toast.error(response.data.message))
    }
    const selectTag = (tag) => {
        let newList = [...pathTag]
        newList.push(tag)
        setPathTag(newList)
        setLoading(true)
        getTag({ tagId: tag.id, token })
            .then(tagData => {
                setTags(tagData.data.payload.subTags)
                setLoading(false)
            })
            .catch(({ response }) => toast.error(response.data.message))
    }

    const upload = () => {
        setUploading(true)

        uploadFile({
            fileName: file.name,
            file: video?.blob || image?.blob || file,
            token,
            tagId: pathTag.length > 0 ? pathTag[pathTag.length - 1].id : null,
            callback: ({ progress }) => setProgressPercentage(progress * 100)
        })
            .then(() => {
                toast.success('Archivo subido.')
                setPathTag([])
                setInput('')
                setTags([])
                setFile(null)
                setImage(null)
                setVideo(null)
                setUploading(false)
                setUploadAvailable(false)
                setContentCategoryName(null)
                setProgressPercentage(0.0)
            })
            .catch(({ response }) => toast.error(response.data.message))
    }

    useEffect(() => {
        dispatch(reset())
    }, [dispatch])

    return (
        <div className="flex flex-col gap-2 bg-white p-5 rounded-lg md:w-full md:mx-auto">
            <Text>{langs[lang]['select']}</Text>
            <label className="bg-gray-100 p-5 rounded-lg uppercase text-xs font-semibold text-center truncate overflow-hidden">
                <input type="file" onChange={handleChange} value={input} hidden />

                {input ? file.name : langs[lang]['tap_to_upload_a_file']}
            </label>



            {loading ? <Loader /> :
                <>
                    {contentCategoryName === 'image_category' &&
                        <div className="flex gap-2 items-center">
                            {file.type !== 'image/gif' &&
                                <button
                                    className={`self-start ${showImageEditor ? 'bg-[#4da2d9] text-white' : 'bg-gray-100 text-gray-500'} px-5 py-1 rounded-full`}
                                    onClick={() => {
                                        setShowImageEditor(!showImageEditor)
                                        setShowImagePreview(false)
                                    }}
                                >{langs[lang]['edit_photo']}</button>
                            }
                            <button
                                className={`self-start ${showImagePreview ? 'bg-[#4da2d9] text-white' : 'bg-gray-100 text-gray-500'} px-5 py-1 rounded-full`}
                                onClick={() => {
                                    setShowImagePreview(!showImagePreview)
                                    setShowImageEditor(false)
                                }}
                            >{langs[lang]['preview']}</button>
                        </div>
                    }
                    {contentCategoryName === 'video_category' &&
                        <div className="flex gap-2">
                            <button className="self-start bg-gray-100 px-5 py-1 text-gray-500 rounded-full" onClick={() => setShowVideoEditor(!showVideoEditor)}>{langs[lang]['edit_video']}</button>
                            {contentCategoryName === 'video_category' && originalVideo.url !== video.url &&
                                <button className="self-start bg-gray-100 px-5 py-1 text-red-500 rounded-full" onClick={() => setShowVideoEditor(!showVideoEditor)}>{langs[lang]['delete_changes']}</button>}
                        </div>
                    }
                    {/* {contentCategoryName === 'audio_category' && <button className="self-start bg-gray-100 px-5 py-1 text-gray-500 rounded-full">Editar duración</button>} */}

                    {contentCategoryName && showImageEditor &&
                        <PhotoEditorPro image={{ src: originalImg.url, name: file.name }} onSave={async (imageObject) => {
                            setImage({
                                blob: dataURLtoFile(imageObject.imageBase64, file.name),
                                url: URL.createObjectURL(await toBlob(imageObject.imageCanvas))
                            })
                            setShowImageEditor(false)
                            setShowImagePreview(true)
                        }} />
                    }

                    {contentCategoryName && showVideoEditor &&
                        <div className="flex justify-center">
                            <VideoEditor
                                video={video.blob}
                                videoSrc={video.url}
                                onVideoProcessEnd={({ video, videoUrl }) => {
                                    console.log(video)
                                    setVideo({
                                        blob: video,
                                        url: videoUrl
                                    })
                                }}
                            />
                        </div>
                    }

                    {contentCategoryName && showImagePreview &&
                        <div className="w-[420px] self-center">
                            <img crossOrigin="anonymous" src={image.url} alt="cropped preview" />
                        </div>
                    }



                    {contentCategoryName && <p className="text-gray-500">{contentCategoryEnums[contentCategoryName]?.name}</p>}

                    <div className="flex flex-col">
                        <div className="flex gap-2 items-center text-gray-500">
                            {pathTag.length > 0 && <button onClick={deletePathTag}><RiCloseFill /></button>}
                            {
                                pathTag.map((tag, index) => {
                                    return (
                                        <div key={'path_' + tag.id} className="flex gap-2 items-center">
                                            {index !== 0 && <RiArrowRightSLine className="text-md" />}
                                            <Text>
                                                {tag.name}
                                            </Text>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="flex flex-col gap-2">
                            {tags.length > 0 &&
                                tags.map((tag) => <button key={tag.id} className="px-5 py-2 border rounded-lg text-gray-500" onClick={() => selectTag(tag)}>{tag.name}</button>)
                            }
                        </div>
                    </div>
                    {uploading &&
                        <div className="h-[20px] rounded-lg w-full bg-gray-100 overflow-hidden">
                            {/* #4da2d9 */}
                            <div className="flex items-center justify-center bg-[#4da2d9] text-xs font-medium text-white text-center p-0.5 leading-none h-full" style={{ width: `${progressPercentage}%` }}>{progressPercentage.toFixed(2)}%</div>
                        </div>
                    }
                    {uploadAvailable && !showImageEditor && !showVideoEditor && <Button onClick={upload} disabled={uploading}>{uploading ? langs[lang]['uploading'] : langs[lang]['upload']}</Button>}
                </>}
        </div >
    )
}

export default UploadFile