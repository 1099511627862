import { useEffect, useState } from "react"
import { RiAddCircleLine, RiChat1Fill, RiFileFill, RiHashtag, RiImage2Fill, RiMicFill, RiMore2Fill, RiSearchLine, RiVidiconFill } from "react-icons/ri"
import { IoMdDownload } from 'react-icons/io'
import { useDispatch, useSelector } from "react-redux"
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom"
import Button from "../../components/Button"
import Modal from "../../components/Modal"
import ProfilePicture from "../../components/ProfilePicture"
import VerificationForm from "../../components/VerificationForm"
import { setNoAds, setSessionData, setVerified } from "../../features/sessionSlice"
import { deleteToken } from "../../features/tokenSlice"
import { deleteSession, getSession, sendAuthorizationEmail } from "../../services/session"
import { toast } from "react-hot-toast"
import Loader from "../../components/Loader"
import Call from "../../components/Call"
import IncomingCallList from "../../components/IncomingCallList"
import useOnline from "../../hooks/useOnline"
import { VscGlobe } from "react-icons/vsc";
import DownloadManager from "../../components/DownloadsManager"
import langs from "../../lang/langs"
import { BiCopyright } from "react-icons/bi"
import SelectLenguage from "../../components/SelectLanguage"

const AuthTemplate = ({ online }) => {
    // const online = useOnline()
    const [loading, setLoading] = useState(true)
    const [menuOpen, setMenuOpen] = useState(false)
    const [emailSended, setEmailSended] = useState(false)
    const [clicks, setClicks] = useState(0)
    const navigate = useNavigate()
    const { token, multimedia, lang } = useSelector((state) => state)
    const session = useSelector(({ session }) => session)
    const dispatch = useDispatch()

    const logout = () => {
        deleteSession({ token, sessionId: session.id }).catch(({ response }) => alert(response.data.message))
        dispatch(deleteToken())
    }

    const handleSendAuthorizationEmail = () => {
        setEmailSended(true)
        sendAuthorizationEmail({ token })
            .then((response) => {
                toast.success(response.data.message)
            })
            .catch(({ response }) => toast.error(response.data.message))
    }

    const handleProfileClick = () => {
        if (clicks + 1 === 100) {
            dispatch(setNoAds())
            setClicks(0)
            toast.success(session.noAds ? "Bordes con anuncios activados" : "¡Modo sin bordes activado!")
        } else {
            setClicks(clicks + 1)
        }
    }

    useEffect(() => {
        if (online) {
            getSession({ token })
                .then((response) => {
                    dispatch(setSessionData(response.data.payload))
                    setLoading(false)
                })
                .catch(({ response }) => {
                    if (response.status === 404 || response.status === 401) {
                        toast.error(langs[lang]['session_deleted_or_expired'])
                        dispatch(deleteToken())
                        return
                    }
                })
        } else {
            setLoading(false)
        }

    }, [token, dispatch, online])

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <Loader />
            </div>
        )
    }

    if (!session.user.emailVerified) return (
        <div className="bg-gray-300 flex min-h-screen items-center justify-center">
            <VerificationForm userId={session.user.id} callback={() => {
                dispatch(setVerified())
            }} />
        </div>
    )
    return (
        <>
            <div className={`flex flex-col ${!session.noAds && 'px-[20px]'} min-h-screen bg-gray-300`}>
                {!session.noAds && <div className="bg-banner-top bg-contain w-full h-[20px]"></div>}
                <div className="flex flex-auto p-1 flex-col gap-2">
                    <div className="flex flex-col px-5 pt-5 pb-2 bg-white justify-between rounded-lg">
                        <div className="flex items-center justify-between">
                            <img crossOrigin="anonymous" src="/logo-app.png" className="md:w-[200px] w-[30%] cursor-pointer" alt='your world logo' onClick={() => navigate('/images')} />
                            {online &&
                                <div className="flex gap-4">
                                    <Link to="/search" className="text-gray-400 text-xl"><RiSearchLine /></Link>
                                    <Link to="/upload-file" className="text-gray-400 text-xl"><RiAddCircleLine /></Link>
                                    <Link to="/hashtags" className="text-gray-400 text-xl"><RiHashtag /></Link>
                                    <Link to="/downloads" className="text-gray-400 text-xl"><IoMdDownload /></Link>
                                    <button className="text-gray-400 text-xl" onClick={() => setMenuOpen(!menuOpen)}><RiMore2Fill /></button>

                                    <Modal modalOpen={menuOpen} set={setMenuOpen}>
                                        <div className="flex flex-col bg-white rounded w-[310px] overflow-hidden">
                                            <Link to="/update-profile" className="px-5 py-3 text-center text-gray-500 uppercase text-sm hover:bg-gray-100" onClick={() => setMenuOpen(false)}>{langs[lang]['edit_profile']}</Link>
                                            <Link to="/sessions" className="px-5 py-3 text-center text-gray-500 uppercase text-sm hover:bg-gray-100" onClick={() => setMenuOpen(false)}>{langs[lang]['devices']}</Link>
                                            <Link to="/downloads" className="px-5 py-3 text-center text-gray-500 uppercase text-sm hover:bg-gray-100" onClick={() => setMenuOpen(false)}>{langs[lang]['downloads']}</Link>
                                            <button className="px-5 py-3 text-center text-red-500 uppercase text-sm hover:bg-gray-100" onClick={logout}>{langs[lang]['logout']}</button>
                                        </div>
                                    </Modal>
                                </div>
                            }
                            {
                                !online &&
                                <p className="flex gap-2 items-center uppercase text-xs text-gray-500">
                                    <VscGlobe className="text-lg" /> {langs[lang]['offline']}
                                </p>
                            }
                        </div>

                        <div className="flex flex-col items-center justify-center">
                            <ProfilePicture userId={session.user.id} onClick={handleProfileClick} online={online} />

                            <p className="text-gray-500 capitalize">{session.user.firstName} {session.user.lastName}</p>
                            <p className="text-gray-500 text-xs">@{session.user.username}</p>

                        </div>
                    </div>
                    {online &&
                        <div className="flex gap-1 flex-wrap">
                            <NavLink
                                to="/images"
                                className={({ isActive }) => `${isActive || multimedia.contentCategory.idName === 'image_category' ? 'border-[#4da2d9] text-[#4da2d9]' : 'border-white text-gray-500'} border flex text-sm items-center flex-auto justify-center gap-1 p-1 rounded-lg bg-white`}>
                                <RiImage2Fill className="text-lg" />
                                {langs[lang]['images']}
                            </NavLink>
                            <NavLink
                                to="/videos"
                                className={({ isActive }) => `${isActive || multimedia.contentCategory.idName === 'video_category' ? 'border-[#4da2d9] text-[#4da2d9]' : 'border-white text-gray-500'} border flex text-sm items-center flex-auto justify-center gap-1 p-1 rounded-lg bg-white`}>
                                <RiVidiconFill className="text-lg" />
                                {langs[lang]['videos']}
                            </NavLink>
                            <NavLink
                                to="/audios"
                                className={({ isActive }) => `${isActive || multimedia.contentCategory.idName === 'audio_category' ? 'border-[#4da2d9] text-[#4da2d9]' : 'border-white text-gray-500'} border flex text-sm items-center flex-auto justify-center gap-1 p-1 rounded-lg bg-white`}>
                                <RiMicFill className="text-lg" />
                                {langs[lang]['audios']}
                            </NavLink>
                            <NavLink
                                to="/library"
                                className={({ isActive }) => `${isActive || multimedia.contentCategory.idName === 'library_category' ? 'border-[#4da2d9] text-[#4da2d9]' : 'border-white text-gray-500'} border flex text-sm items-center flex-auto justify-center gap-1 p-1 rounded-lg bg-white`}>
                                <RiFileFill className="text-lg" />
                                {langs[lang]['library']}
                            </NavLink>
                            {online &&
                                <NavLink
                                    to="/chat/private"
                                    className={({ isActive }) => `${isActive ? 'border-[#4da2d9] text-[#4da2d9]' : 'border-white text-gray-500'} border flex text-sm items-center flex-auto justify-center gap-1 p-1 rounded-lg bg-white`}>
                                    <RiChat1Fill className="text-lg" />
                                    {langs[lang]['chat']}
                                </NavLink>
                            }
                        </div>
                    }
                    {
                        session.authorized ?
                            <>
                                <Call />
                                <IncomingCallList />
                                {online ? <Outlet /> : <DownloadManager />}
                            </>
                            :
                            <div className="bg-gray-300 flex flex-auto items-start justify-center">
                                <div className="flex flex-col w-[400px] gap-5 bg-white border border-1 border-gray-100 px-5 py-10 rounded-lg">
                                    <p className="text-md text-center text-gray-500">{langs[lang]['session_unauthorized']}</p>
                                    <p className="text-md text-center text-gray-500">{langs[lang]['authorize_session']}</p>
                                    <p className="text-md text-center text-gray-500">
                                        {langs[lang]['authorize_instructions']}
                                    </p>
                                    <p className="text-md text-center text-gray-500">
                                        {langs[lang]['refresh_page_after_authorize']}
                                    </p>
                                    <Button onClick={handleSendAuthorizationEmail} disabled={emailSended}>{emailSended ? langs[lang]['check_your_inbox'] : langs[lang]['send_authorization_email']}</Button>
                                    <Button onClick={logout}>{langs[lang]['logout']}</Button>
                                </div>
                            </div>
                    }
                    {!session.noAds && <div className="fixed top-0 left-0 bg-banner-left bg-contain w-[20px] h-full mt-auto"></div>}
                    {!session.noAds && <div className="fixed top-0 right-0 bg-banner-right bg-contain w-[20px] h-full mt-auto"></div>}
                    <footer className="flex justify-between px-5 py-3 bg-white rounded mt-auto">
                        <div className="flex gap-5 items-center">
                            <Link to="/terms-and-conditions" className="text-zinc-500">Terms and conditions</Link>
                            <SelectLenguage />
                        </div>
                        <p className="flex items-center gap-2 text-zinc-500 text-sm"><BiCopyright /> YourWorld {new Date().getFullYear()}</p>
                    </footer>
                </div>
                {!session.noAds && <div className="bg-banner-bottom w-full h-[20px] mt-auto bg-contain"></div>}
            </div>
        </>
    )
}

export default AuthTemplate