import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { reset } from "../../../features/multimediaSlice"
import Button from "../../../components/Button"
import { toast } from "react-hot-toast"
import Loader from "../../../components/Loader"
import Hashtag from "../../../components/Hashtag"
import { validateAllowedChars } from "../../../utils/strings"
import { getHashtagsMostUsed } from "../../../services/hashtags"
import { searchFileByHashtags } from "../../../services/file"
import { API_URL } from "../../../config"
import { useNavigate } from "react-router-dom"
import Text from "../../../components/Text"
import langs from "../../../lang/langs"

const HashtagsPage = () => {
    const [hashtag, setHashtag] = useState("")
    const [hashtags, setHashtags] = useState([])
    const [hashtagsMostUsed, setHashtagsMostUsed] = useState([])
    const [searching, setSearching] = useState(false)
    const [files, setFiles] = useState([])
    const { token, lang } = useSelector(state => state)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const search = () => {
        setSearching(true)
        searchFileByHashtags({ token, hashtags: hashtags.join(',') })
            .then(res => {
                setFiles(res.data.payload)
                setSearching(false)
            })
            .catch(() => {
                toast.error(langs[lang]['an_error_has_ocurred'])
                setSearching(false)
            })

    }

    const handleHashtagChange = (event) => {
        if (event.target.value.trim().replace(" ", "").length === 0 || validateAllowedChars(event.target.value)) {
            setHashtag(event.target.value)
        }
    }
    const handleAddHashtag = (event) => {
        event.preventDefault()
        if (hashtag.trim().length === 0) return

        const search = hashtags.find(htag => hashtag === htag)
        if (search) {
            toast.error(langs[lang]['already_add_this_hashtag'])
            return
        }

        let newList = [...hashtags]
        newList.push(hashtag)
        setHashtags(newList)
        setHashtag("")
    }
    const handleDeleteHashtags = (hashtag) => {
        const newList = hashtags.filter(text => hashtag !== text)
        setHashtags(newList)
    }

    const openFileDownload = (fileId) => {
        navigate(`/download/file/${fileId}`)
    }

    useEffect(() => {
        dispatch(reset())
        getHashtagsMostUsed({ token }).then((res) => {
            setHashtagsMostUsed(res.data.payload.hashtags)
        })
    }, [dispatch])

    return (
        <div className="p-5 bg-white rounded">
            <div className="flex flex-col gap-5">
                <p className="text-gray-500">{langs[lang]['search_files_by_hashtags']}</p>

                <div className="flex flex-col gap-5 p-4">
                    {hashtags.length > 0 &&
                        <div className="flex flex-wrap items-center gap-2">
                            {hashtags.map((hashtag, index) => <Hashtag key={index} hashtag={hashtag} allowDelete onDelete={() => handleDeleteHashtags(hashtag)} />)}
                        </div>
                    }
                    <form onSubmit={handleAddHashtag}>
                        <input
                            className="outline-none"
                            type="text"
                            name="hashtag"
                            onChange={handleHashtagChange}
                            placeholder={langs[lang]['enter_to_add']}
                            value={hashtag}
                        />
                    </form>
                    {hashtags.length > 0 && <Button className="self-start" height="sm" onClick={search}>{langs[lang]['search']}</Button>}
                </div>

                <div className="flex flex-col gap-2">
                    <p className="text-gray-500">{langs[lang]['hashtags_most_used']}</p>
                    <div className="flex gap-2 flex-nowrap overflow-x-auto overflow-y-hidden md:flex-wrap md:overflow-x-hidden">
                        {hashtagsMostUsed.map((hashtag, index) => <Hashtag key={index} hashtag={hashtag.name} onClick={() => {
                            const search = hashtags.find(htag => hashtag.name === htag)
                            if (search) {
                                toast.error(langs[lang]['already_add_this_hashtag'])
                                return
                            }

                            let newList = [...hashtags]
                            newList.push(hashtag.name)
                            setHashtags(newList)
                        }} />)}
                    </div>
                    <p className="text-xs text-gray-500">{langs[lang]['can_add_it_giving_click']}</p>
                </div>

                {
                    searching ?
                        <Loader text={langs[lang]['searching_files']} />
                        :
                        <div className="flex flex-col gap-5">
                            {files.length === 0 ?
                                <p className="text-gray-500">{langs[lang]['no_files_found']}</p>
                                :
                                files.map(file => (
                                    <div key={file.id} className="flex flex-col gap-2 p-4 bg-[#f9f9f9] rounded cursor-pointer" onClick={() => openFileDownload(file.id)}>
                                        <div className="flex gap-1 items-center text-[#4da2d9]">
                                            <img crossOrigin="anonymous" className="w-[20px] h-[20px] rounded-full" src={file.User.profilePicture ? `${API_URL}/users/${file.User.id}/profile/picture` : '/pp.png'} alt={file.User.username} />
                                            <Text>@{file.User.username}</Text>
                                        </div>
                                        <p className="text-gray-500">{file.name}</p>
                                        <span className="bg-blue-100 text-xs text-blue-500 rounded px-2 py-1 self-start">
                                            {
                                                file.mimetype.split('/')[0] === 'image' ? langs[lang]['image']
                                                    : file.mimetype.split('/')[0] === 'video' ? langs[lang]['video']
                                                        : file.mimetype.split('/')[0] === 'audio' ? langs[lang]['audio']
                                                            : langs[lang]['library']}
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                }
            </div>
        </div>
    )
}

export default HashtagsPage