import { RiCheckLine, RiCloseLine } from "react-icons/ri"
import { API_URL } from "../../config"
import { useContext } from "react"
import { SocketContext } from "../SocketProvider"
import { useDispatch, useSelector } from "react-redux"
import { resetCall, setCallId, setCallModal, setInCall } from "../../features/callSlice"
import { acceptCall, hangUpCall, rejectCall } from "../../services/call"
import { useState } from "react"
import { deleteIncomingCall } from "../../features/incomingCallList"
import { toast } from "react-hot-toast"
import Notification from "../Notification"
import langs from "../../lang/langs"

const IncomingCall = ({ call }) => {
    const { token, session, call: callSlice, lang } = useSelector(state => state)
    const { socket } = useContext(SocketContext)

    const dispatch = useDispatch()

    // Estados de los botones Aceptar/Rechazar
    const [accepted, setAccepted] = useState(false)
    const [acceptRequesting, setAcceptRequesting] = useState(false)

    const [rejected, setRejected] = useState(false)
    const [rejectRequesting, setRejectRequesting] = useState(false)

    const handleAcceptCall = () => {
        const action = () => {
            socket.emit('call:accept:in_another_window', { callId: call.Call.id })
            setAccepted(true)
            setAcceptRequesting(true)
            acceptCall({
                callId: call.Call.id,
                token,
                sessionId: session.id,
                memberId: call.id // id del miembro
            })
                .then(() => {
                    dispatch(setCallId(call.Call.id))
                    dispatch(setCallModal(true))
                    dispatch(setInCall(true))
                    dispatch(deleteIncomingCall(call.Call.id))
                })
                .catch(() => {
                    dispatch(deleteIncomingCall(call.Call.id))
                    toast.custom(t => <Notification text="Ha ocurrido un error al rechazar la llamada :(" />)
                })
        }
        if (callSlice.id) {
            hangUpCall({ token, callId: callSlice.id, sessionId: session.id })
                .then(() => {
                    dispatch(resetCall())
                    dispatch(setCallModal(true))
                    socket.emit('call:hangup', { callId: callSlice.id, userId: session.userId })
                    action()
                }).catch(() => {
                    dispatch(deleteIncomingCall(call.Call.id))
                    toast.custom(t => <Notification text="Ha ocurrido un error al rechazar la llamada :(" />)
                })
        } else {
            action()
        }
    }

    const handleRejectCall = () => {
        socket.emit('call:reject:in_another_window', { callId: call.Call.id })
        setRejected(true)
        setRejectRequesting(true)
        rejectCall({
            callId: call.Call.id,
            token,
            sessionId: session.id,
            memberId: call.id // id del miembro
        }).then(() => {
            dispatch(deleteIncomingCall(call.Call.id))
        }).catch(() => {
            dispatch(deleteIncomingCall(call.Call.id))
            toast.custom(t => <Notification text="Ha ocurrido un error al aceptar la llamada :(" />)
        })
    }


    return (
        <div className="flex w-full sm:max-w-[320px] justify-between gap-2 items-center bg-black p-2 rounded animate-slide-y-in">
            <div className="flex gap-5">
                <img
                    crossOrigin="anonymous"
                    className="w-[40px] h-[40px] rounded-full self-center"
                    src={call.Call.User.profilePicture ? `${API_URL}/users/${call.Call.User.id}/profile/picture` : '/pp.png'}
                    alt={call.Call.User.username}
                />
                <div className="flex flex-col">
                    <p className="text-white">@{call.Call.User.username}</p>
                    {call.Call.groupId && <p className="text-white">{call.Call.Group.name}</p>}
                    <p className="text-white">{call.Call.videoCall ? langs[lang]['videocall'] : langs[lang]['call']}</p>
                </div>
            </div>
            <div className="flex gap-2">
                {!rejected &&
                    <button
                        className="flex items-center justify-center w-[30px] h-[30px] rounded-full bg-green-700"
                        onClick={handleAcceptCall}
                        disabled={acceptRequesting}
                    >
                        <RiCheckLine className="text-white" />
                    </button>
                }
                {!accepted &&
                    <button
                        className="flex items-center justify-center w-[30px] h-[30px] rounded-full bg-red-700"
                        onClick={handleRejectCall}
                        disabled={rejectRequesting}
                    >
                        <RiCloseLine className="text-white" />
                    </button>
                }
            </div>
        </div>
    )
}

export default IncomingCall