import { useEffect, useState } from "react"
import Modal from "../../../components/Modal"
import { RiSearchLine } from "react-icons/ri"
import LabelTitle from "../../../components/LabelTitle"
import { getAllRooms, joinRoom } from "../../../services/room"
import { useSelector } from "react-redux"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import RoomItem from "../../../components/RoomItem"
import SearchRoom from "./SearchRoom"
import langs from "../../../lang/langs"

const FindRooms = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const [rooms, setRooms] = useState([])
    const [searchRoom, setSearchRoom] = useState(false)

    const navigate = useNavigate()
    const { token,lang } = useSelector(state => state)

    useEffect(() => {
        getAllRooms({ token })
            .then(res => {
                setRooms(res.data.payload.rooms)
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
            })
    }, [])

    return (
        <div className="flex flex-col">
            <button className="flex items-center justify-center gap-2 text-gray-500 px-5 py-2 border rounded-lg" onClick={() => setModalOpen(true)}><RiSearchLine /> {langs[lang]['search']}</button>
            <Modal modalOpen={modalOpen} set={setModalOpen}>
                <div className="w-[320px] bg-white flex flex-col gap-5 rounded p-5">
                    <LabelTitle>{langs[lang]['rooms']}</LabelTitle>
                    <button className={`${searchRoom ? 'text-red-500' : 'text-[#4da2d9]'} self-start`} onClick={() => setSearchRoom(!searchRoom)}>
                        {searchRoom ? langs[lang]['cancel'] : langs[lang]['search']}
                    </button>
                    {searchRoom ?
                        <SearchRoom />
                        :
                        <>
                            {
                                rooms.length > 0 ?
                                    rooms.map(room => (
                                        <RoomItem key={room.id} room={room} />
                                    ))
                                    :
                                    <LabelTitle>{langs[lang]['no_rooms_created']}</LabelTitle>
                            }
                        </>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default FindRooms