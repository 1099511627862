import { useDispatch, useSelector } from "react-redux"
import { resetCall } from "../../features/callSlice"
import { RiCloseFill } from "react-icons/ri"
import theme from "../../theme"
import langs from "../../lang/langs"

const CallFinished = () => {
    const { lang } = useSelector(state => state)
    const dispatch = useDispatch()

    const handleCloseCall = () => {
        dispatch(resetCall())
    }

    return (
        <div className="flex flex-col w-[250px] bg-white rounded overflow-hidden">
            {/* Info de la llamada */}
            <div className="flex flex-col gap-1 p-5">0
                <p className="text-gray-700 text-center text-lg">{langs[lang]['call_finished']}</p>
            </div>
            <div className="flex gap-2 bg-gray-100 p-5 justify-center overflow-hidden">
                <button
                    className={`bg-gray-300 text-gray-700 flex gap-2 px-5 text-xs uppercase h-[40px] items-center justify-center rounded-full`}
                    onClick={handleCloseCall}
                >
                    {langs[lang]['close']}
                </button>
            </div>
        </div>
    )
}

export default CallFinished