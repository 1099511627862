import axios from "axios";
import { API_URL } from "../config";

export const getRoom = ({ token, roomId }) => axios.get(`${API_URL}/rooms/${roomId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const getRooms = ({ token }) => axios.get(`${API_URL}/rooms`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const getAllRooms = ({ token }) => axios.get(`${API_URL}/rooms/all`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const searchRoom = ({ token, name }) => axios.get(`${API_URL}/rooms/search/${name}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const createRoom = ({ token, roomName }) => axios.post(`${API_URL}/rooms`, {
    name: roomName
}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const joinRoom = ({ token, roomId }) => axios.post(`${API_URL}/rooms/join`, {
    roomId
}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})