import { useState } from "react"
import { toast } from "react-hot-toast"
import { Link } from "react-router-dom"
import Button from "../../components/Button"
import Input from "../../components/Input"
import { forgotPassword } from "../../services/user.js"
import langs from "../../lang/langs"
import { useSelector } from "react-redux"
import SelectLenguage from "../../components/SelectLanguage/index.js"

const ForgotPasswordPage = () => {
    const [requesting, setRequesting] = useState(false)
    const [form, setForm] = useState({
        email: ''
    })
    const { lang } = useSelector(state => state)

    const handleInputChange = ({ target: { name, value } }) => setForm({ ...form, [name]: value })

    const handleSubmit = event => {
        event.preventDefault()
        setRequesting(true)
        forgotPassword({ data: form })
            .then((response) => {
                toast.success(response.data.message)
                setRequesting(false)
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
                setRequesting(false)
            })
    }
    return (
        <div className="bg-gray-300 flex min-h-screen items-center justify-center">
            <div className="flex flex-col w-[400px] gap-5 bg-white border border-1 border-gray-100 px-5 py-10 rounded">
                <img crossOrigin="anonymous" src="/logo-app.png" className="w-[200px] mx-auto" alt='your world logo' />
                <p className="text-md text-center text-gray-500">{langs[lang]['forgot_password_subtitle']}</p>
                <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                    <Input
                        label={langs[lang]['email']}
                        type="email"
                        name="email"
                        placeholder={langs[lang]['email_example']}
                        onChange={handleInputChange}
                        value={form.email}
                    />
                    <Button spinner={+requesting} disabled={requesting}>{!requesting && langs[lang]['recovery']}</Button>
                    <Link to="/" className="text-gray-500 text-center">{langs[lang]['go_back']}</Link>
                </form>
                <SelectLenguage />
            </div>
        </div>
    )
}

export default ForgotPasswordPage