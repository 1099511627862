import { createContext, useContext, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { io } from "socket.io-client"
import Peer from "peerjs"
import { SOCKET_URL } from "../../config"

export const PEER_CONFIG = {
    host: 'server-peerjs.yourworld.es',
    path: '/server',
    secure: true,
    debug: false,
    port: 443
}


export const SocketContext = createContext({
    socket: null,
    peer: null
})

export const useAddSocketEvent = (name, handler, dependencies = []) => {
    const { socket } = useContext(SocketContext)

    useEffect(() => {
        socket?.on(name, handler)

        return () => {
            socket?.off(name)
        }

    }, dependencies)

}

export const useSocketSubscribe = (events = []) => {
    const { socket } = useContext(SocketContext)

    useEffect(() => {
        events.map(([name, handler]) => socket?.on(name, handler))

        return () => {
            events.map(([name, handler]) => socket?.off(name, handler))
        }

    }, [])
}

const SocketProvider = ({ children }) => {
    const { token, session } = useSelector(state => state)

    const socket = useMemo(() => {
        return io(SOCKET_URL, {
            extraHeaders: {
                Authorization: `Bearer ${token}`,
                sessionId: session.id,
            },
            upgrade: false,
            reconnection: true
        })
    }, [session, token])

    const peer = useMemo(() => {
        return new Peer(socket.id, PEER_CONFIG)
    }, [socket])

    useEffect(() => {
        return () => {
            socket.removeAllListeners()
            socket.close()
        }
    }, [socket])


    return (
        <SocketContext.Provider value={{ socket, peer }}>
            {children}
        </SocketContext.Provider>
    )
}

export default SocketProvider