import { useState } from "react"
import { toast } from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Button from "../../components/Button"
import Input from "../../components/Input"
import { setToken } from "../../features/tokenSlice"
import { login } from "../../services/auth"
import langs from "../../lang/langs"
import SelectLenguage from "../../components/SelectLanguage"

const LoginPage = () => {
    const [requesting, setRequesting] = useState(false)
    const [form, setForm] = useState({
        email: '',
        password: ''
    })

    const language = 'es'
    const { lang } = useSelector(state => state)

    const dispatch = useDispatch()

    const handleInputChange = ({ target: { name, value } }) => setForm({ ...form, [name]: value })

    const handleSubmit = event => {
        event.preventDefault()
        setRequesting(true)
        login({ data: form })
            .then((response) => {
                toast.success(response.data.message)
                dispatch(setToken(response.data.payload.session.token))
                setRequesting(false)
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
                setRequesting(false)
            })
    }

    return (
        <div className="bg-gray-300 flex min-h-screen items-center justify-center">
            <div className="flex flex-col w-[400px] gap-5 bg-white border border-1 border-gray-100 px-5 py-10 rounded">
                <img crossOrigin="anonymous" src="/logo-app.png" className="w-[200px] mx-auto" alt='your world logo' />

                <p className="text-md text-center text-gray-500">{langs[lang]['login_subtitle']}</p>

                <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                    <Input
                        label={langs[lang]['email']}
                        type="email"
                        name="email"
                        placeholder={langs[lang]['email_example']}
                        onChange={handleInputChange}
                        value={form.email}
                    />
                    <Input
                        label={langs[lang]['password']}
                        type="password"
                        name="password"
                        placeholder={langs[lang]['password_example']}
                        onChange={handleInputChange}
                        value={form.password}
                    />
                    <Link to="/forgot-password" className="text-gray-500 text-center">{langs[lang]['forgot_password_link']}</Link>
                    <Button spinner={+requesting} disabled={requesting}>{!requesting && langs[lang]['login']}</Button>
                    <Link to="/register" className="text-gray-500 text-center">{langs[lang]['register_account_link']}</Link>
                </form>
                <SelectLenguage />
            </div>
        </div>
    )
}

export default LoginPage