import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BrowserRouter, Navigate, Route, Routes, useHref, useLocation } from "react-router-dom"
import AuthTemplate from "./pages/AuthTemplate"
import AudioPage from "./pages/AuthTemplate/Audio"
import ChatPage from "./pages/AuthTemplate/ChatPage"
import ImagesPage from "./pages/AuthTemplate/Images"
import LibraryPage from "./pages/AuthTemplate/Library"
import SessionsPage from "./pages/AuthTemplate/Sessions"
import TagPage from "./pages/AuthTemplate/TagPage"
import UpdateProfile from "./pages/AuthTemplate/UpdateProfile"
import UploadFile from "./pages/AuthTemplate/UploadFile"
import VideoPage from "./pages/AuthTemplate/Videos"
import ChangePasswordPage from "./pages/ChangePassword"
import ForgotPasswordPage from "./pages/ForgotPassword"
import LoginPage from "./pages/Login"
import RegisterPage from "./pages/Register"
import SearchPage from "./pages/AuthTemplate/SearchPage"
import PrivateChat from "./pages/AuthTemplate/PrivateChat"
import SocketProvider from "./components/SocketProvider"
import ChatTemplate from "./pages/AuthTemplate/ChatTemplate"
import GroupPage from "./pages/AuthTemplate/GroupPage"
import GroupChat from "./pages/AuthTemplate/GroupChat"
import RoomPage from "./pages/AuthTemplate/RoomPage"
import RoomChat from "./pages/AuthTemplate/RoomChat"
import EditImage from "./pages/AuthTemplate/EditImage"
import EditVideo from "./pages/AuthTemplate/EditVideo"
import HashtagsPage from "./pages/AuthTemplate/HashtagsPage"
import DownloadFilePage from "./pages/AuthTemplate/DownloadFilePage"
import DownloadsPage from "./pages/AuthTemplate/DownloadsPage"
import { Detector } from "react-detect-offline"
import { changeLang } from "./features/languageSlice"
import Loader from "./components/Loader"
import TermsAndConditions from "./pages/TermsAndConditions"


const AuthNoRequired = ({ children }) => {
    const token = useSelector(state => state.token)
    const location = useLocation()

    if (token) {
        return <Navigate to="/images" state={{ from: location }} replace />
    }
    return children
}

const AuthRequired = ({ children }) => {
    const token = useSelector(state => state.token)
    const location = useLocation()

    if (!token) {
        return <Navigate to="/" state={{ from: location }} replace />
    }
    return children
}

const ChatIndex = ({ children }) => {
    const location = useLocation()
    const href = useHref()

    if (href === '/chat' || href === '/chat/') {
        return <Navigate to="/chat/private" state={{ from: location }} replace />
    }
    return children
}

const App = () => {
    const { lang } = useSelector(state => state)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!lang) {
            const locale = Intl.DateTimeFormat().resolvedOptions().locale
            const languageData = locale.split("-");
            const languageCode = languageData[0]
            dispatch(changeLang(languageCode))
        }
        document.title = "YourWorld"
    }, [])
    if (!lang) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <Loader/>
            </div>
        )
    }
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <AuthNoRequired><LoginPage /></AuthNoRequired>
                } />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/register" element={
                    <AuthNoRequired><RegisterPage /></AuthNoRequired>
                } />
                <Route path="/forgot-password" element={
                    <AuthNoRequired><ForgotPasswordPage /></AuthNoRequired>
                } />
                <Route path="/change-password/:hash" element={
                    <AuthNoRequired><ChangePasswordPage /></AuthNoRequired>
                } />

                <Route element={
                    <AuthRequired>
                        <SocketProvider>
                            <Detector
                                render={({ online }) =>
                                    <AuthTemplate online={online} />}
                            />
                        </SocketProvider>
                    </AuthRequired>
                }>
                    <Route path="/images" element={<ImagesPage />} />
                    <Route path="/images/:imageId/edit" element={<EditImage />} />
                    <Route path="/videos" element={<VideoPage />} />
                    <Route path="/videos/:videoId/edit" element={<EditVideo />} />
                    <Route path="/audios" element={<AudioPage />} />
                    <Route path="/library" element={<LibraryPage />} />
                    <Route path="/tags/:tagId" element={<TagPage />} />
                    <Route path="/upload-file" element={<UploadFile />} />
                    <Route path="/chat" element={
                        <ChatIndex>
                            <ChatTemplate />
                        </ChatIndex>
                    }>
                        <Route path="private" element={<ChatPage />} />
                        <Route path="private/:userId" element={<PrivateChat />} />
                        <Route path="groups" element={<GroupPage />} />
                        <Route path="groups/:groupId" element={<GroupChat />} />
                        <Route path="rooms" element={<RoomPage />} />
                        <Route path="rooms/:roomId" element={<RoomChat />} />
                    </Route>
                    <Route path="/sessions" element={<SessionsPage />} />
                    <Route path="/update-profile" element={<UpdateProfile />} />
                    <Route path="/search" element={<SearchPage />} />
                    <Route path="/hashtags" element={<HashtagsPage />} />
                    <Route path="/download/file/:fileId" element={<DownloadFilePage />} />
                    <Route path="/downloads" element={<DownloadsPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )

}

export default App