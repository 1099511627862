import FilerobotImageEditor, {
    TABS,
} from 'react-filerobot-image-editor';
import langs from '../../lang/langs';
import { useSelector } from 'react-redux';

function toBlob(canvas) {
    return new Promise((resolve) => {
        canvas.toBlob(resolve)
    })
}


const PhotoEditorPro = ({ image, onSave }) => {
    const { lang } = useSelector(state => state)
    const translations = langs[lang]['photoEditor']
    return (
        <div className='h-[700px]'>
            {/* #4da2d9 */}
            <FilerobotImageEditor
                theme={{
                    palette: {
                        'accent-primary': '#4da2d9',
                        'accent-primary-active': '#4da2d9',
                        'icons-primary': '#4da2d9',
                        'icons-secondary': '#4da2d9',
                        'borders-secondary': '#4da2d9',
                        'borders-primary': '#4da2d9',
                    }
                }}

                source={image.src}
                onSave={async (editedImageObject, designState) => {
                    const url = URL.createObjectURL(await toBlob(editedImageObject.imageCanvas))
                    console.log(url)
                    if (onSave) onSave(editedImageObject, designState)
                }}
                annotationsCommon={{
                    fill: '#ff0000',
                }}
                Image={{
                    gallery: [
                        {
                            originalUrl: './stickers/corazon.png',
                            previewUrl: './stickers/corazon.png',
                        },
                        {
                            originalUrl: './stickers/estrella.png',
                            previewUrl: './stickers/estrella.png',
                        },
                        {
                            originalUrl: './stickers/logo-app.png',
                            previewUrl: './stickers/logo-app.png',
                        },
                    ]
                }}
                Watermark={{
                    gallery: [
                        './stickers/corazon.png',
                        './stickers/estrella.png',
                        './stickers/logo-app.png'
                    ],
                    textScalingRatio: 0.33,
                    imageScalingRatio: 0.33,
                }}
                savingPixelRatio={10}
                defaultSavedImageQuality={1}
                useZoomPresetsMenu={true}
                observePluginContainerSize={true}
                tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.FINETUNE, TABS.RESIZE, TABS.FILTERS]}
                defaultTabId={TABS.ADJUST}
                useBackendTranslations={true}
                translations={translations}
            />
        </div>
    );
}

export default PhotoEditorPro