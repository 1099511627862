import { useContext, useRef, useState } from "react"
import { RiCloseFill, RiDeleteBin6Fill, RiFileFill, RiSendPlane2Fill } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { v4 } from "uuid"
import { sendRoomMessage } from "../../../services/message"
import useAutosizeTextArea from "../../../hooks/useAutosizeTextArea"
import { SocketContext } from "../../../components/SocketProvider"
import { addMessage, addUploadProgress, updateUploadProgress } from "../../../features/roomSlice"
import RecordAudio from "../../../components/RecordAudio"
import AudioPlayer from "../../../components/AudioPlayer"
import langs from "../../../lang/langs"

const SendMessageBox = () => {
    const form = useRef()
    const textArea = useRef()

    const { socket } = useContext(SocketContext)

    const [state, setState] = useState({
        text: ""
    })
    const [text, setText] = useState("")
    const [file, setFile] = useState(null)
    const [inputFile, setInputFile] = useState('')
    const [recordingAudio, setRecordingAudio] = useState(false)
    const [audioRecorded, setAudioRecorded] = useState(null)

    const { roomChat, token, session, lang } = useSelector(state => state)
    const dispatch = useDispatch()

    const handleDeleteFile = () => {
        setFile(null)
    }

    const handleFileChange = ({ target }) => {
        const { files: [file] } = target
        setFile(file)
        setInputFile(target.value)
    }

    const handleTextChange = (event) => {
        setState({ ...state, text: event.target.value })
        setText(event.target.value)
    }

    const handleSkipLine = (event) => {
        if (event.which === 13 && !event.shiftKey) {
            handleSubmit(event)
        }
    }

    const handleSubmit = (event) => {
        event?.preventDefault()
        if (!recordingAudio && !file && state.text.trim().length === 0) return

        const tempMessageId = v4()
        dispatch(addUploadProgress({ id: tempMessageId }))
        const promise = sendRoomMessage({
            token,
            userId: session.user.id,
            text: state.text,
            roomId: roomChat.id,
            chatId: null,
            groupId: null,
            socketId: socket.id,
            handleUpload: ({ progress }) => {
                dispatch(updateUploadProgress({ id: tempMessageId, progress: progress * 100 }))
            },
            file: file || audioRecorded?.audio || null,
            voice: !!audioRecorded
        })

        const message = {
            id: tempMessageId,
            User: session.user,
            text: state.text,
            roomId: roomChat.id,
            chatId: null,
            groupId: null,
            MessageFiles: file ? [file] : audioRecorded ? [audioRecorded.audio] : [],
            promise,
            voice: !!audioRecorded,
            createdAt: new Date()
        }
        dispatch(addMessage(message))
        setState({ ...state, text: "" })
        setFile(null)
        setInputFile("")
        setRecordingAudio(false)
        setAudioRecorded(null)
    }

    useAutosizeTextArea(textArea.current, state.text)

    return (
        <div className="flex flex-col bg-white">
            {file &&
                <div className="flex flex-col p-2 overflow-hidden">
                    <div className="flex items-center gap-2">
                        <button className="text-red-500" onClick={handleDeleteFile}><RiCloseFill /></button>
                        <p className="text-gray-500">{file.name}</p>
                    </div>
                </div>
            }
            <div className="flex items-center gap-2 p-2">
                {!audioRecorded && !recordingAudio &&
                    <form ref={form} className="flex flex-auto items-center gap-2" onSubmit={handleSubmit}>
                        {!audioRecorded &&
                            <>
                                <label className="flex items-center justify-center min-w-[40px] min-h-[40px] text-blue-500">
                                    <RiFileFill />
                                    {<input type="file" name="file" value={inputFile} onChange={handleFileChange} hidden />}
                                </label>
                                <textarea
                                    ref={textArea}
                                    className="w-full min-h-[40px] h-auto resize-none px-5 py-2 rounded bg-gray-100 text-gray-500 outline-none"
                                    type="text"
                                    placeholder={langs[lang]['message_placeholder']}
                                    onChange={handleTextChange}
                                    rows={1}
                                    onKeyDown={handleSkipLine}
                                    value={state.text}
                                ></textarea>
                            </>
                        }
                        {(state.text.trim().length > 0 || file) &&
                            <button className={`flex items-center justify-center min-w-[40px] min-h-[40px] ${state.text.trim().length > 0 || file ? 'text-blue-500' : 'text-gray-500'}`}>
                                <RiSendPlane2Fill />
                            </button>
                        }
                    </form>
                }
                {audioRecorded &&
                    <AudioPlayer blob={audioRecorded.audioBlob} />
                }
                {(!file && state.text.trim().length === 0) &&
                    <div className="flex ml-auto">
                        {audioRecorded &&
                            <button

                                className={`flex items-center justify-center min-w-[40px] min-h-[40px] text-red-500`}
                                onClick={() => {
                                    setRecordingAudio(false)
                                    setAudioRecorded(null)
                                }}

                            >
                                <RiDeleteBin6Fill />
                            </button>
                        }

                        {!audioRecorded &&
                            <RecordAudio
                                onStartRecording={() => {
                                    setRecordingAudio(true)
                                }}
                                onStopRecording={(event) => {
                                    setAudioRecorded(event)
                                }}
                            />
                        }
                        {audioRecorded &&
                            <button
                                className={`flex items-center justify-center min-w-[40px] min-h-[40px] text-blue-500`}
                                onClick={handleSubmit}
                            >
                                <RiSendPlane2Fill />
                            </button>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default SendMessageBox