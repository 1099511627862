import axios from "axios";
import { API_URL } from "../config";

export const getTag = ({ tagId, token }) => axios.get(`${API_URL}/tags/${tagId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const getAvailableTags = ({ tagId, contentCategoryId, token, actualTagId = '' }) => axios.get(`${API_URL}/tags/${tagId}/${contentCategoryId}/${actualTagId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const getAvailableTagsForFiles = ({ contentCategoryId, token, actualTagId = '' }) => axios.get(`${API_URL}/tags/available-for-files/${contentCategoryId}/${actualTagId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const createTag = ({ tagName, token, contentCategoryId }) => axios.post(`${API_URL}/tags`, { name: tagName, contentCategoryId }, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const createSubtag = ({ tagName, tagId, token }) => axios.post(`${API_URL}/tags/subtag`, { name: tagName, tagId }, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const moveTag = ({ tagId, destTagId, token }) => axios.post(`${API_URL}/tags/${tagId}/move/${destTagId || ''}`, {}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const renameTag = ({ tagName, tagId, token }) => axios.patch(`${API_URL}/tags/${tagId}/name`, { name: tagName }, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const deleteTag = ({ tagId, token, keepContent, destTagId = null }) => axios.post(`${API_URL}/tags/${tagId}/delete`, {
    keepContent, destTagId
}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})