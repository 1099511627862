import axios from "axios";
import { API_URL } from "../config";

export const getSession = ({ token }) => axios.get(`${API_URL}/sessions`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const getSessions = ({ token }) => axios.get(`${API_URL}/sessions/list`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const authorizeSession = ({ token, sessionId }) => axios.post(`${API_URL}/sessions/authorize/${sessionId}`, {}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})


export const sendAuthorizationEmail = ({ token }) => axios.post(`${API_URL}/sessions/authorize/email`, { token })

export const deleteSession = ({ token, sessionId }) => axios.delete(`${API_URL}/sessions/${sessionId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})