import { useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { leaveGroup } from "../../services/groupMember"
import langs from "../../lang/langs"

const LeaveGroupButton = () => {
    const [requesting, setRequesting] = useState(false)
    const { token, groupChat, lang } = useSelector(state => state)
    const navigate = useNavigate()
    const handleLeaveGroup = () => {
        setRequesting(true)
        leaveGroup({ token, groupId: groupChat.id })
            .then(() => {
                toast.success(langs[lang]['you_have_left_the_group'])
                navigate('/chat/groups')
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
                setRequesting(false)
            })
    }
    return (
        <button
            className="text-red-500"
            onClick={handleLeaveGroup}
            disabled={requesting}
        >{requesting ? langs[lang]['coming_out'] : langs[lang]['leave_group']}</button>
    )
}

export default LeaveGroupButton