import axios from "axios";
import { API_URL } from "../config";

export const getRoomMessages = ({ token, roomId, page = null }) => axios.get(`${API_URL}/rooms/messages/${roomId}/${page === null ? '' : page}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const getGroupMessages = ({ token, groupId, page = null }) => axios.get(`${API_URL}/groups/messages/${groupId}/${page === null ? '' : page}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const getPrivateMessages = ({ token, chatId, page = null }) => axios.get(`${API_URL}/messages/${chatId}/${page === null ? '' : page}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const sendRoomMessage = ({ token, roomId, text, socketId, file, handleUpload, voice }) => axios.post(`${API_URL}/rooms/messages/${roomId}`, {
    text, socketId, file, voice
}, {
    onUploadProgress: (event) => handleUpload(event),
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
    }
})

export const sendGroupMessage = ({ token, groupId, text, socketId, file, handleUpload, voice }) => axios.post(`${API_URL}/groups/messages/${groupId}`, {
    text, socketId, file, voice
}, {
    onUploadProgress: (event) => handleUpload(event),
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
    }
})

export const sendPrivateMessage = ({ token, userId, chatId, text, socketId, file, handleUpload, voice }) => axios.post(`${API_URL}/messages/${userId}`, {
    chatId, text, socketId, file, voice
}, {
    onUploadProgress: (event) => handleUpload(event),
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
    }
})

export const editMessage = ({ token, messageId, text, socketId }) => axios.patch(`${API_URL}/messages/${messageId}`, { text, socketId }, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const editGroupMessage = ({ token, messageId, text, socketId }) => axios.patch(`${API_URL}/groups/messages/${messageId}`, {
    text, socketId
}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const editRoomMessage = ({ token, messageId, text, socketId }) => axios.patch(`${API_URL}/rooms/messages/${messageId}`, {
    text, socketId
}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const deleteMessage = ({ token, messageId, socketId }) => axios.post(`${API_URL}/messages/${messageId}/delete`, {
    socketId
}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})