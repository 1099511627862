import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { RiArrowRightSLine, RiCloseFill } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { removeFile } from "../../features/multimediaSlice"
import { moveFile } from "../../services/file"
import { getAvailableTagsForFiles } from "../../services/tag"
import Text from "../Text"
import Loader from "../Loader"
import langs from "../../lang/langs"

const MoveFile = ({ file: { id, name, tagId }, moveFileModalOpen }) => {
    const [availableTags, setAvailableTags] = useState([])
    const [pathToMove, setPathToMove] = useState([])

    const [requestingPath, setRequestingPath] = useState(false)

    const { token, multimedia, lang } = useSelector(state => state)
    const dispatch = useDispatch()

    const deletePathToMove = () => {
        setPathToMove([])
        getAvailableTagsForFiles({ contentCategoryId: multimedia.contentCategoryId, token }).then(response => {
            setAvailableTags(response.data.payload)
        })
    }

    const selectTag = tag => {
        setRequestingPath(true)
        let newList = [...pathToMove]
        newList.push(tag)
        setPathToMove(newList)
        getAvailableTagsForFiles({ contentCategoryId: multimedia.contentCategoryId, token, actualTagId: tag.id }).then(response => {
            setAvailableTags(response.data.payload)
            setRequestingPath(false)
        })
    }

    const handleMoveTag = () => {
        moveFile({ fileId: id, destTagId: pathToMove[pathToMove.length - 1]?.id, token })
            .then(() => {
                toast.success('Archivo movido.')
                dispatch(removeFile(id))
            }).catch(({ response }) => {
                toast.error(response.data.message)
            })
    }

    useEffect(() => {
        if (moveFileModalOpen) {
            setRequestingPath(true)
            getAvailableTagsForFiles({ contentCategoryId: multimedia.contentCategoryId, token })
                .then(response => {
                    setAvailableTags(response.data.payload)
                    setRequestingPath(false)
                })
                .catch(({ response }) => {
                    toast.error(response.data.message)
                })
        }

    }, [id, multimedia, token, moveFileModalOpen])

    return (
        <div className="flex flex-col bg-white rounded w-[310px] overflow-hidden">
            <div className="flex flex-col gap-2">
                <p className="text-center px-5 py-2 font-semibold">{name}</p>
                <p className="text-gray-500 text-center">{langs[lang]['move']}</p>
                <div className="flex flex-col gap-2 p-2">
                    <div className="flex flex-wrap gap-2 items-center">
                        {pathToMove.length > 0 && <button onClick={deletePathToMove}><Text><RiCloseFill /></Text></button>}
                        {pathToMove.length === 0 && <Text>{multimedia.contentCategory.name}</Text>}
                        {
                            pathToMove.map((tag, index) => {
                                return (
                                    <div key={'path_' + tag.id} className="flex gap-2 items-center">
                                        {index !== 0 && <RiArrowRightSLine className="text-md" />}
                                        <Text>
                                            {tag.name}
                                        </Text>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        requestingPath
                            ?
                            <Loader text={langs[lang]['loading']} />
                            :
                            <>
                                {
                                    availableTags.length > 0 ?
                                        <>
                                            {availableTags.map(tag =>
                                                <button key={'tag_' + tag.id} className="px-5 py-2 border rounded-lg" onClick={() => selectTag(tag)}>
                                                    <Text>{tag.name}</Text>
                                                </button>
                                            )}
                                        </>
                                        : <Text align="text-center" size="text-xs">{langs[lang]['no_subtags_availables']}</Text>
                                }
                            </>
                    }
                </div>
                {(pathToMove[pathToMove.length - 1]?.id || null) !== tagId && <button className="px-5 py-3 text-gray-500 uppercase text-sm hover:bg-gray-100" onClick={handleMoveTag}>{langs[lang]['move']}</button>}
            </div>
        </div >
    )
}

export default MoveFile