import { RiArrowDownSLine, RiArrowLeftSLine, RiInformationFill, RiMore2Fill, RiPhoneLine, RiVidiconLine } from "react-icons/ri"
import { Link, useNavigate, useParams } from "react-router-dom"
import SendMessageBox from "./SendMessageBox"
import { useDispatch, useSelector } from "react-redux"
import { useContext, useEffect, useState } from "react"
import MessageList from "./MessageList"
import { SocketContext, useAddSocketEvent } from "../../../components/SocketProvider"
import Loader from "../../../components/Loader"
import { getGroup } from "../../../services/group"
import { addMessage, deleteMessage, editMessage, resetGroup, setGroup, setLoading } from "../../../features/groupSlice"
import Modal from "../../../components/Modal"
import LeaveGroupButton from "../../../components/LeaveGroupButton"
import MembersList from "./MemberList"
import { toast } from "react-hot-toast"
import theme from "../../../theme"
import { setCall, setCallId, setCallModal, setInCall, setOutgoingCall } from "../../../features/callSlice"
import { callGroup } from "../../../services/call"
import langs from "../../../lang/langs"

const GroupChat = () => {
    const { groupId } = useParams()

    const { socket, peer } = useContext(SocketContext)

    const [membersModal, setMembersModal] = useState(false)

    const { token, groupChat, session, lang } = useSelector(state => state)
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const handleVideoCall = () => {
        dispatch(setCallModal(true))
        callGroup({
            token,
            userId: session.user.id,
            groupId: groupChat.id,
            socketId: socket.id,
            peerId: peer.id,
            sessionId: session.id,
            media: { video: true, audio: true },
            videoCall: true
        })
            .then(res => {
                socket.emit('call:start:room', { callId: res.data.payload.id })
                dispatch(setCallId(res.data.payload.id))
                dispatch(setInCall(true))
            })
            .catch(({ response }) => {
                toast.error(`${response.data.message}`)
                dispatch(setCallModal(false))
                dispatch(setOutgoingCall(false))
            })
    }

    const handleAudioCall = () => {
        dispatch(setCallModal(true))

        callGroup({
            token,
            groupId: groupChat.id,
            peerId: peer.id,
            sessionId: session.id,
            media: { video: false, audio: true },
            videoCall: false
        })
            .then(res => {
                socket.emit('call:start:room', { callId: res.data.payload.id })
                dispatch(setCallId(res.data.payload.id))
                dispatch(setInCall(true))
            })
            .catch(({ response }) => {
                toast.error(`${response.data.message}`)
                dispatch(setCallModal(false))
                dispatch(setOutgoingCall(false))
            })
    }


    useAddSocketEvent('group:message:edit', ({ message }) => {
        dispatch(editMessage(message))
    })

    useAddSocketEvent('message:group:new', (message) => {
        dispatch(addMessage(message))
    })

    useAddSocketEvent('group:message:delete', ({ messageId }) => {
        dispatch(deleteMessage(Number(messageId)))
    })

    useEffect(() => {
        socket?.emit('group:join', { groupId: groupChat.id })
    }, [groupChat.id, socket])

    useEffect(() => {
        dispatch(resetGroup())
        getGroup({ token, groupId })
            .then(res => {
                dispatch(setGroup(res.data.payload.group))
                dispatch(setLoading(false))
            })
            .catch(({ response }) => {
                if (response.status === 404) {
                    navigate('/chat/groups')
                    toast(() => (
                        <div>
                            <p className="text-gray-500">{langs[lang]['group_doesnt_exists']}</p>
                        </div>
                    ))
                } else if (response.status === 409 && response.data.payload.notMember) {
                    navigate('/chat/groups')
                    toast(() => (
                        <div>
                            <p className="text-gray-500">{langs[lang]['you_arent_group_member']}</p>
                        </div>
                    ))
                }
                else if (response.status === 500) {
                    navigate('/chat/groups')
                    toast.error(response.data.message)
                }
            })
    }, [token])

    return (
        <div className="flex-auto flex rounded overflow-hidden">
            {
                groupChat.loading ?
                    <Loader />
                    :
                    <div className="flex w-full flex-col bg-white">
                        {/* Header chat */}
                        <div className="flex gap-5 items-center border-b border-gray-200 px-5 py-3">
                            <Link to="/chat/groups" className="text-blue-500"><RiArrowLeftSLine /></Link>

                            <div className="flex items-center gap-2">
                                <button className="flex flex-col gap-1" onClick={() => setMembersModal(true)}>
                                    <p className="text-gray-500">{groupChat.name}</p>
                                    <p className="text-gray-500 text-xs">{langs[lang]['touch_to_see_more_info']}</p>
                                </button>
                                <Modal modalOpen={membersModal} set={setMembersModal}>
                                    <MembersList />
                                </Modal>
                            </div>
                            <div className="flex gap-4 ml-auto">
                                <button
                                    className={`${theme.primaryColor.text} text-xl`} title={langs[lang]['start_videocall']}
                                    onClick={handleVideoCall}
                                ><RiVidiconLine /></button>
                                <button
                                    className={`${theme.primaryColor.text} text-xl`} title={langs[lang]['start_call']}
                                    onClick={handleAudioCall}
                                ><RiPhoneLine /></button>
                            </div>
                        </div>
                        <MessageList />
                        <SendMessageBox />
                    </div>
            }
        </div>
    )
}

export default GroupChat