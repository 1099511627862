import { useRef, useState } from 'react'
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri'
import LabelTitle from '../LabelTitle'

const Input = (props) => {
    const inputRef = useRef({})
    const [showPassword, setShowPassword] = useState(false)
    return (
        <div className={`flex flex-col gap-2 ${props.className}`}>
            {
                props.label &&
                <LabelTitle>{props.label}</LabelTitle>
            }
            <div className="flex relative items-center">
                <input
                    ref={inputRef}
                    {...props}
                    className="w-[100%] h-[50px] px-5 rounded text-zinc-600 bg-slate-100 outline-none transition-all duration-100 active:scale-95"
                />
                {props.type === 'password' &&
                    <span
                        className="absolute right-3"
                        onClick={() => {
                            setShowPassword(!showPassword)
                            inputRef.current.type = !showPassword ? "text" : "password"
                        }}
                    >
                        {
                            !showPassword ? <RiEyeFill className="text-zinc-600" /> : <RiEyeOffFill className="text-zinc-600" />
                        }
                    </span>
                }
            </div>

        </div>
    )
}

export default Input