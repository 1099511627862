import axios from "axios";
import { API_URL } from "../config";

export const getCallData = ({ token, callId }) => axios.get(`${API_URL}/calls/${callId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const getIncomingCalls = ({ token }) => axios.get(`${API_URL}/calls/incoming`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const callUser = ({ token, userId, socketId, chatId, peerId, sessionId, media, videoCall }) => axios.post(`${API_URL}/chats/calls/${userId}`, {
    socketId, chatId, userId, peerId, sessionId, media, videoCall
}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const callGroup = ({ token, socketId, groupId, peerId, sessionId, media, videoCall }) => axios.post(`${API_URL}/calls/groups/${groupId}`, {
    socketId, peerId, sessionId, media, videoCall
}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const acceptCall = ({ token, callId, sessionId, memberId }) => axios.post(`${API_URL}/chats/calls/${callId}/accept`, {
    sessionId, memberId
}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const rejectCall = ({ token, callId, sessionId, memberId }) => axios.post(`${API_URL}/chats/calls/${callId}/reject`, {
    sessionId, memberId
}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const cancelCall = ({ token, userId, callId, sessionId }) => axios.post(`${API_URL}/chats/calls/${callId}/cancel`, {
    userId, sessionId
}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const hangUpCall = ({ token, callId, sessionId }) => axios.post(`${API_URL}/chats/calls/${callId}/hangup`, {
    sessionId
}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})