import { useSelector } from "react-redux"
import RoomMember from "../../../components/RoomMember"
import langs from "../../../lang/langs"

const MembersList = () => {
    const { roomChat, lang } = useSelector(state => state)

    return (
        <div className="w-[320px] flex flex-col gap-2 bg-white rounded p-5">
            <p className="text-gray-500 font-semibold">{langs[lang]['members']}</p>

            {roomChat.members.map(member => <RoomMember key={member.id} member={member} />)}

        </div>
    )
}

export default MembersList