import { useState } from "react"
import Input from "../../../components/Input"
import Button from "../../../components/Button"
import { RiSearch2Line } from "react-icons/ri"
import { searchMember } from "../../../services/groupMember"
import { useSelector } from "react-redux"
import { toast } from "react-hot-toast"
import MemberResult from "./MemberResult"
import langs from "../../../lang/langs"

const AddMember = () => {
    const [lastUsername, setLastUsername] = useState("")
    const [username, setUsername] = useState("")
    const [requesting, setRequesting] = useState(false)
    const [members, setMembers] = useState([])

    const { token, groupChat, lang } = useSelector(state => state)

    const handleSearchMember = (event) => {
        event.preventDefault()

        if (username.trim().length === 0 || username === lastUsername) return

        setLastUsername(username)
        setRequesting(true)
        const memberIdsInList = groupChat.members.map(member => member.userId)
        searchMember({ token, username, memberIdsInList }).then(res => {
            setMembers(res.data.payload.users)
            setRequesting(false)
        }).catch(({ response: res }) => {
            toast.error(res.data.message)
        })
    }

    const handleUserAdded = (userId) => {
        const newList = members.filter(user => user.id === userId)

        setMembers(newList)
    }

    return (
        <div className="flex flex-col gap-5">
            <form className="flex gap-2" onSubmit={handleSearchMember}>
                <Input type="text" placeholder={langs[lang]['username']} onChange={({ target: { value } }) => setUsername(value)} value={username} />
                <Button spinner={requesting} disabled={requesting}>{!requesting && <RiSearch2Line />}</Button>
            </form>
            {members.length > 0 &&
                <div className="flex flex-col gap-5">
                    <p className="text-gray-500">{members.length} {langs[lang]['results']}:</p>
                    <div className="flex flex-col gap-2">
                        {members.map(user => (
                            <MemberResult key={user.id} user={user} onSuccess={handleUserAdded} />
                        ))}
                    </div>
                </div>
            }
        </div>
    )
}

export default AddMember