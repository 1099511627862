export const contentCategoryEnums = {
    'image_category': {
        name: 'Imágenes',
        route: 'images',
        idName: 'image_category'
    },
    'video_category': {
        name: 'Vídeos',
        route: 'videos',
        idName: 'video_category'
    },
    'audio_category': {
        name: 'Audios',
        route: 'audios',
        idName: 'audios_category'
    },
    'library_category': {
        name: 'Librería',
        route: 'library',
        idName: 'library_category'
    }
}