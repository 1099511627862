import { useEffect } from "react"
import { useState } from "react"
import { BsFillMicFill } from "react-icons/bs"
import { RiStopFill } from "react-icons/ri"
import Text from '../Text'
import { toTimeString } from "../../utils"
import { useRef } from "react"

const mimeType = "audio/mp3"

const RecordAudio = ({ onStartRecording = () => { }, onStopRecording = () => { } }) => {
    const [startRecording, setStartRecording] = useState(false)
    const stream = useRef(null)
    const mediaRecorder = useRef(null)
    const [audioDuration, setAudioDuration] = useState(0)
    const [audioChunks, setAudioChunks] = useState([])
    const [audio, setAudio] = useState(null)

    const handleStartRecording = async () => {
        setAudioDuration(0)
        setStartRecording(true)
        onStartRecording({ recording: true })

        stream.current = await navigator.mediaDevices.getUserMedia({ video: false, audio: true })
        const media = new MediaRecorder(stream.current, { type: mimeType })

        mediaRecorder.current = media

        mediaRecorder.current.start()
        let localAudioChunks = []
        mediaRecorder.current.ondataavailable = (event) => {
            if (typeof event.data === "undefined") return
            if (event.data.size === 0) return
            localAudioChunks.push(event.data)
        }
        setAudioChunks(localAudioChunks)

    }

    const handleStopRecording = () => {
        setStartRecording(false)
        stream.current.getTracks().forEach(track => track.stop())
        stream.current = null

        mediaRecorder.current.stop()
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: mimeType })
            const audioUrl = URL.createObjectURL(audioBlob)

            onStopRecording({ recording: true, audio: new File(audioChunks, `voz_${Date.now()}.mp3`, { type: mimeType }), audioUrl: audioUrl, audioBlob })

            setAudio(audioUrl)
            setAudioChunks([])
        }
    }


    useEffect(() => {
        const timer = startRecording && setInterval(() => setAudioDuration(audioDuration + 1), 1000)
        return () => clearInterval(timer)
    }, [startRecording, audioDuration])

    return (
        <div className="flex gap-1 items-center">
            {startRecording && <Text>{toTimeString(audioDuration, false)}</Text>}
            {!startRecording ?
                <button
                    className={`flex items-center justify-center min-w-[40px] min-h-[40px] text-blue-500`}
                    onClick={handleStartRecording}
                >
                    <BsFillMicFill />
                </button>
                :

                <button
                    className={`flex items-center justify-center min-w-[40px] min-h-[40px] text-red-500`}
                    onClick={handleStopRecording}
                >
                    <RiStopFill />
                </button>
            }
        </div>
    )
}

export default RecordAudio