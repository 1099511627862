import { useEffect, useState } from "react"
import { API_URL } from "../../config"
import { useSelector } from "react-redux"
import Button from "../Button"
import { useLiveQuery } from "dexie-react-hooks"
import { db } from "../../db"
import axios from "axios"
import { Link } from "react-router-dom"
import langs from "../../lang/langs"

const DownloadFile = ({ file, source }) => {
    const fileData = useLiveQuery(
        async () => {
            const [data] = await db.files
                .where({ fileId: file.id })
                .toArray()
            return data
        },
        [file.id]
    )
    const [storedFile, setStoredFile] = useState(false)
    const [keepFile, setKeepFile] = useState(storedFile)
    const [downloading, setDownloading] = useState(false)
    const [progressPercentage, setProgressPercentage] = useState(0.0)

    const { token, lang } = useSelector(state => state)

    const handleChangeKeepFile = (event) => {
        if (event.target.checked) {
            setKeepFile(event.target.checked)
        } else {
            setKeepFile(event.target.checked)
            setStoredFile(false)
            if (fileData) db.files.delete(fileData?.id)
        }
    }

    const download = () => axios.get(`${API_URL}/files/${file.id}`, {
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${token}`
        },
        onDownloadProgress: ({ progress }) => setProgressPercentage(progress * 100)
    })

    const handleDownload = () => {
        setDownloading(true)
        download().then((response) => {
            const url = URL.createObjectURL(response.data)

            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', file.name)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

            setDownloading(false)
            setProgressPercentage(0.0)
        })
    }

    const handleDownloadAndKeepFile = () => {
        setDownloading(true)
        download().then(response => {
            db.files.add({
                fileId: file.id,
                name: file.name,
                mimetype: file.mimetype,
                userId: file.userId,
                tagId: file.tagId,
                public: file.isPublic,
                hashtags: file.hashtags,
                createdAt: file.createdAt,
                updatedAt: file.updatedAt,
                data: response.data
            })

            const url = URL.createObjectURL(response.data)

            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', file.name)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

            setDownloading(false)
            setProgressPercentage(0.0)
        })
    }

    const handleKeepFile = () => {
        setDownloading(true)
        download().then(response => {
            db.files.add({
                fileId: file.id,
                name: file.name,
                mimetype: file.mimetype,
                userId: file.userId,
                tagId: file.tagId,
                public: file.isPublic,
                hashtags: file.hashtags,
                createdAt: file.createdAt,
                updatedAt: file.updatedAt,
                data: response.data
            })
            setDownloading(false)
            setProgressPercentage(0.0)
        })
    }

    const handleSaveInDevice = () => {
        const url = URL.createObjectURL(fileData?.data)

        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file.name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    useEffect(() => {
        setStoredFile(fileData !== undefined)
        setKeepFile(fileData !== undefined)
    }, [fileData])

    return (
        <div className="flex flex-col bg-white rounded w-[310px] overflow-hidden">
            <div className="flex flex-col gap-2 px-5 py-5 overflow-hidden">
                <p className="text-gray-500 text-center">{langs[lang]['download']}</p>
                <p className="text-gray-500 text-center truncate">{file.name}</p>
                <div className="flex gap-2 items-center justify-between bg-gray-50 px-5 py-2 rounded">
                    <label
                        className="text-gray-500 inline-block pl-[0.15rem] hover:cursor-pointer"
                        htmlFor="flexSwitchCheckDefault"
                    >{langs[lang]['keep']}</label>
                    <input
                        id="flexSwitchCheckDefault"
                        className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-blue-500 checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:bg-blue-200"
                        role="switch"
                        type="checkbox"
                        onChange={handleChangeKeepFile}
                        value={keepFile}
                        checked={keepFile}
                    />
                </div>
                <div className="flex flex-col gap-2 bg-gray-50 rounded px-4 py-2">
                    <p className="text-gray-500 text-xs">
                        {!storedFile ? langs[lang]['keep_file'] : langs[lang]['not_keep_file']}
                    </p>
                </div>
                {keepFile &&
                    <div className="flex flex-col gap-2">
                        {storedFile ?
                            <div className="flex flex-col gap-2 bg-gray-50 rounded overflow-hidden">
                                <p className="text-center bg-green-500 px-4 py-2 text-white text-xs uppercase">{langs[lang]['stored']}</p>
                                <div className="px-4 pb-2">
                                    <p className="text-gray-500 text-xs">
                                        {langs[lang]['file_stored_instruction']} <Link to="/downloads" className="font-bold">{langs[lang]['downloads']}</Link>.
                                    </p>
                                </div>
                            </div>
                            :
                            <div className="flex flex-col gap-2 bg-gray-50 rounded overflow-hidden">
                                <p className="text-center bg-gray-100 text-gray-500 px-4 py-2 text-xs uppercase">{langs[lang]['not_stored']}</p>
                                <div className="px-4 pb-2">
                                    <p className="text-gray-500 text-xs">
                                        {langs[lang]['file_not_stored_instruction']}
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    !storedFile && keepFile ?
                        <>
                            <Button disabled={downloading} onClick={handleKeepFile} height="sm">{langs[lang]['keep']} {downloading && `${progressPercentage.toFixed(2)}%`}</Button>
                        </>
                        : !storedFile && !keepFile ?
                            <Button disabled={downloading} onClick={handleDownload} height="sm">{langs[lang]['download']} {downloading && `${progressPercentage.toFixed(2)}%`}</Button>
                            :
                            <Button disabled={downloading} onClick={handleSaveInDevice} height="sm">{langs[lang]['save']} ({langs[lang]['device']})</Button>
                }
            </div>
        </div>
    )
}

export default DownloadFile