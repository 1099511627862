import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { RiArrowRightSLine, RiCloseFill } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { removeTag } from "../../features/multimediaSlice"
import { getAvailableTags, moveTag } from "../../services/tag"
import Text from "../Text"
import langs from "../../lang/langs"

const MoveTag = ({ tag: { id, name, tagId }, moveTagModalOpen }) => {
    const [availableTags, setAvailableTags] = useState([])
    const [pathToMove, setPathToMove] = useState([])

    const { token, multimedia, lang } = useSelector(state => state)
    const dispatch = useDispatch()

    const deletePathToMove = () => {
        setPathToMove([])
        getAvailableTags({ tagId: id, contentCategoryId: multimedia.contentCategoryId, token }).then(response => {
            setAvailableTags(response.data.payload)
        })
    }

    const selectTag = tag => {
        let newList = [...pathToMove]
        newList.push(tag)
        setPathToMove(newList)
        getAvailableTags({ tagId: id, contentCategoryId: multimedia.contentCategoryId, token, actualTagId: tag.id }).then(response => {
            setAvailableTags(response.data.payload)
        })
    }

    const handleMoveTag = () => {
        moveTag({ tagId: id, destTagId: pathToMove[pathToMove.length - 1]?.id, token })
            .then(() => {
                toast.success('Tag movido.')
                dispatch(removeTag(id))
            })
            .catch(({ response }) => {
                toast.error(response.data.message)
            })
    }

    useEffect(() => {
        if (moveTagModalOpen) {
            getAvailableTags({ tagId: id, contentCategoryId: multimedia.contentCategoryId, token })
                .then(response => {
                    setAvailableTags(response.data.payload)
                })
                .catch(({ response }) => {
                    toast.error(response.data.message)
                })
        }

    }, [id, multimedia, token, moveTagModalOpen])

    return (
        <div className="flex flex-col bg-white rounded w-[310px] overflow-hidden">
            <div className="flex flex-col gap-2">
                <p className="text-center px-5 py-2 font-semibold">{name}</p>
                <p className="text-gray-500 text-center">{langs[lang]['move_to']}</p>
                <div className="flex flex-col gap-2 p-2">
                    <div className="flex flex-wrap gap-2 items-center">
                        {pathToMove.length > 0 && <button onClick={deletePathToMove}><RiCloseFill /></button>}
                        {pathToMove.length === 0 && <Text>{multimedia.contentCategory.name}</Text>}
                        {
                            pathToMove.map((tag, index) => {
                                return (
                                    <div key={'path_' + tag.id} className="flex gap-2 items-center">
                                        {index !== 0 && <RiArrowRightSLine className="text-md" />}
                                        <Text>
                                            {tag.name}
                                        </Text>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        availableTags.length > 0 &&
                        availableTags.map(tag =>
                            <button key={tag.id} className="px-5 py-2 border rounded-lg" onClick={() => selectTag(tag)}>{tag.name}</button>
                        )
                    }
                </div>
                {(pathToMove[pathToMove.length - 1]?.id || null) !== tagId && <button className="px-5 py-3 text-gray-500 uppercase text-sm hover:bg-gray-100" onClick={handleMoveTag}>{langs[lang]['move']}</button>}
            </div>
        </div >
    )
}

export default MoveTag