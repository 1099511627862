import { combineReducers } from "@reduxjs/toolkit"
import sessionReducer from "../features/sessionSlice"
import tokenReducer from '../features/tokenSlice'
import multimediaReducer from '../features/multimediaSlice'
import sessionsReducer from '../features/sessionsSlice'
import profilePictureReducer from '../features/profilePictureSlice'
import chatSlice from '../features/chatSlice'
import chatListSlice from '../features/chatListSlice'
import groupSlice from "../features/groupSlice"
import groupList from "../features/groupListSlice"
import roomChatReducer from "../features/roomSlice"
import roomListReducer from "../features/roomListSlice"
import callReducer from "../features/callSlice"
import incomingCallListReducer from "../features/incomingCallList"
import languageSlice from "../features/languageSlice"

const reducers = combineReducers({
    token: tokenReducer,
    session: sessionReducer,
    multimedia: multimediaReducer,
    sessions: sessionsReducer,
    profilePicture: profilePictureReducer,
    privateChat: chatSlice,
    chatList: chatListSlice,
    groupChat: groupSlice,
    groupList: groupList,
    roomChat: roomChatReducer,
    roomList: roomListReducer,
    call: callReducer,
    incomingCallList: incomingCallListReducer,
    lang: languageSlice
})

export default reducers