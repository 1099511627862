import Modal from "../Modal"
import { useAddSocketEvent } from "../SocketProvider"
import { useDispatch, useSelector } from "react-redux"
import InCall from "./InCall"
import CallFinished from "./CallFinished"
import {
    setCallFinished,
    setInCall,
    setIncomingCall,
    setMemberLeaveCall,
    setOutgoingCall,
    setPeerIdToMember,
    setUserAcceptCall
}from "../../features/callSlice"
import Loader from "../Loader"
import langs from "../../lang/langs"

const Call = () => {

    const { call, lang } = useSelector(state => state)
    const dispatch = useDispatch()

    useAddSocketEvent('call:setpeerid', ({ peerId, userId }) => {
        dispatch(setPeerIdToMember({ peerId, userId }))
    })

    useAddSocketEvent('call:accept', ({ userId }) => {
        dispatch(setUserAcceptCall({ userId }))
    })

    useAddSocketEvent('call:cancel', () => {
        dispatch(setIncomingCall(false))
        dispatch(setOutgoingCall(false))
        dispatch(setInCall(false))

        dispatch(setCallFinished(true))
    })

    useAddSocketEvent('call:closed', ({ callId }) => {
        if (callId !== call.id) return

        dispatch(setIncomingCall(false))
        dispatch(setOutgoingCall(false))
        dispatch(setInCall(false))

        dispatch(setCallFinished(true))
    })

    useAddSocketEvent('call:hangup', ({ userId, callId }) => {
        if (callId !== call.id) return

        call.members.forEach(member => {
            if (member.userId === userId) {
                member?.peerCall?.close()
            }
        })

        dispatch(setMemberLeaveCall({ userId }))
    }, [call])
    return (
        <Modal modalOpen={call.modal} set={() => { }}>
            {
                call.inCall ?
                    <InCall />
                    : call.callFinished ?
                        <CallFinished />
                        : <Loader text={langs[lang]['connecting']} textColor="text-white" />
            }
        </Modal>
    )
}

export default Call