import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const sessionSlice = createSlice({
    name: 'sessions',
    initialState,
    reducers: {
        setSessions: (state, { payload }) => payload,
        authorize: (state, { payload }) => {
            const sessions = state.map((session) => {
                if (session.id === payload) {
                    session.authorized = !session.authorized
                }
                return session
            })
            state = sessions
        },
        deleteSession: (state, { payload }) => state.filter((session) => session.id !== payload)
    }

})
export const {
    setSessions,
    authorize,
    deleteSession
} = sessionSlice.actions
export default sessionSlice.reducer