import axios from "axios";
import { API_URL } from "../config";

export const createUser = ({ data }) => axios.post(`${API_URL}/users`, data)
export const forgotPassword = ({ data }) => axios.post(`${API_URL}/users/forgot-password`, data)
export const changePassword = ({ data }) => axios.post(`${API_URL}/users/change-password`, data)
export const verifyUser = ({ data }) => axios.post(`${API_URL}/users/verification`, data)

export const updateProfilePicture = ({ token, file }) => axios.post(`${API_URL}/users/profile/picture`,
    { picture: file },
    {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    }
)

export const getProfilePicture = ({ userId, token }) => axios.get(`${API_URL}/users/${userId}/profile/picture`, {
    responseType: "blob",
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const updateUser = ({ token, data }) => axios.patch(`${API_URL}/users`, data, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const updateEmail = ({ token, data }) => axios.patch(`${API_URL}/users/email`, data, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})


export const updatePassword = ({ token, data }) => axios.patch(`${API_URL}/users/password`, data, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const searchUser = ({ token, username }) => axios.get(`${API_URL}/users/search/${username}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
export const deleteUser = ({ token }) => axios.delete(`${API_URL}/users/`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})