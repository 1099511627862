import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const roomListSlice = createSlice({
    name: 'roomList',
    initialState,
    reducers: {
        setRooms: (state, { payload: rooms }) => {
            return rooms
        },
        addRoom: (state, { payload: newGroup }) => {
            let newList = state.filter(group => group.id !== newGroup.id)
            newList.unshift(newGroup)
            return newList
        },
        updateLastMessageRoom: (state, { payload: { message, memberId } }) => {
            const memberSearch = state.find(member => member.id === memberId)
            memberSearch.Room.Messages = [message]
        },
        updatePositionList: (state, { payload: { memberId } }) => {
            const memberSearch = state.find(member => member.id === memberId)
            let newList = state.filter(member => member.id !== memberId)
            newList.unshift(memberSearch)
            return newList
        },
        removeRoom: (state, { payload: roomId }) => {
            return state.filter(room => room.id !== roomId)
        },
        reset: () => initialState
    }

})
export const {
    setRooms,
    addRoom,
    removeRoom,
    updateLastMessageRoom,
    updatePositionList,
    reset
} = roomListSlice.actions
export default roomListSlice.reducer