import { RiAddFill } from "react-icons/ri"
import Button from "../../../components/Button"
import { API_URL } from "../../../config"
import { useDispatch, useSelector } from "react-redux"
import { addGroupMember } from "../../../services/groupMember"
import { useState } from "react"
import { addMember } from "../../../features/groupSlice"
import { toast } from "react-hot-toast"

const MemberResult = ({ user, onSuccess }) => {
    const [requesting, setRequesting] = useState(false)

    const { token, groupChat } = useSelector(state => state)
    const dispatch = useDispatch()

    const handleAddMember = () => {
        setRequesting(true)
        addGroupMember({ token, groupId: groupChat.id, userId: user.id }).then(res => {
            dispatch(addMember(res.data.payload.member))
            setRequesting(false)
            onSuccess()
        }).catch(({ response: res }) => {
            setRequesting(false)
            toast.error(res.data.message)
        })
    }

    return (
        <div className="flex items-center cursor-pointer gap-1">
            <img crossOrigin="anonymous" className="w-[40px] h-[40px] rounded-full" src={user.profilePicture ? `${API_URL}/users/${user.id}/profile/picture` : '/pp.png'} alt={user.username} />
            <div className="flex flex-auto items-center justify-between">
                <p className="text-gray-500">@{user.username}</p>
                <Button spinner={requesting} disabled={requesting} height="sm" onClick={handleAddMember}>{!requesting && <RiAddFill />}</Button>
            </div>
        </div>
    )
}

export default MemberResult